.breadcrumb {
  padding: 1rem 0;
  &-item {
    position: relative;
    padding-left: 2.5rem;
    &:before {
      position: absolute;
      left: 0;
      top: 2px;
      content: url("../images/ico-home.svg");
    }
    +.breadcrumb-item {
      padding-left: 2.5rem;
      &:before {
        position: absolute;
        content: url("../images/chevron.svg");
        left: 5px;
        padding: 0;
        transform: rotate(270deg);
        float: unset;
      }
    }
  }
  &-item,
  a {
    color: $dark;
    font-size: map-get($font-sizes, 16);
    &.active {
      font-weight: $font-weight-bold;
      color: $primary;
    }
  }
}
