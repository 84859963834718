// Image

img,
svg {
	max-width: 100%;
}
a{
  img {
	border: 0;
  }
  &:hover, &:focus, &:active {
	img {
	  opacity: 0.8;
	}
  }
}
.item-page {
	img {
		border-radius: $border-radius;
	}
}

.img-slider-client {
	height: 130px;
	@include media-breakpoint-down(md) {
		height: 220px;
	}
}
