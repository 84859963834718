// surcharge des class bootstrap

/* =========  reprise des couleurs des boutons de base ici
*/

//$primary:       $blue;
//$secondary:     $gray-600;
//$success:       $green;
//$info:          $cyan;
//$warning:       $yellow;
//$danger:        $red;
//$light:         $gray-100;
//$dark:          $gray-800;

/* =========  mise en forme des nav
*/

.nav{
  .nav-link{
    line-height: 20px;
    border-radius: 0;
    padding: .5rem 0;
  }
}

/* =========  mise en forme des forms
*/

.form-control{
  font-size: map-get($font-sizes,16);
}

/* =========  mise en forme des boutons bootstrap
*/

.btn{
  font-size: map-get($font-sizes,16);
  font-weight: $font-weight-semi-bold;
  &:not(.btn-back-to-top){
    padding: 1rem 3rem;
  }
}

.btn-primary{
  @include button-variant($primary, $primary, $white, darken($primary, 15%), darken($primary, 15%));
}

.btn-white{
  //@include button-variant(background, border, text-color, background hover, border-hover);
  @include button-variant($white, $white, $dark, darken($white, 15%), darken($white, 15%));
  @include box-shadow(0 10px 20px 0 rgba($dark, .2));
}

.btn-secondary{

}

.btn-success{

}

.btn-info{

}

.btn-warning{

}

.btn-danger{

}

.btn-light{

}

.btn-dark{

}

.list-group{
  .list-group-item{
    background: none;
  }
}

.alert {
  color: $white;
}

.card {
  border-radius: $border-radius;
}
