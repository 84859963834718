.pre-footer {
  padding: 2.5rem 0;
}

.footer {
  padding-top: 4rem;
  padding-bottom: 3rem;
  @include media-breakpoint-down(lg) {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 30px 0;
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, auto);
      gap: 20px 0;
    }
  }
  .brand-logo {
    margin: unset;
  }
}
