/* ======= fonts à déclarer
*/

$font-01 : 'Titillium Web', sans-serif;
//$font-02 : 'Open Sans', sans-serif;
//$font-03 : 'Open Sans', sans-serif;

$font-family-base: $font-01;

/* ======= fonts weight
*/

/* variables de bootsrap à réutiliser
$font-weight-light: 300;
$font-weight-medium : 500;
*/

$font-weight-normal: 400;
$font-weight-semi-bold : 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 900;

/* ======= transitions
*/

$transition: .25s ease-in-out;

/* ======= dropdown
*/

$dropdown-min-width: 10rem;
$dropdown-border-radius: 0;
$dropdown-border-width: 0;
$dropdown-font-size: map-get($font-sizes,16);

/* ======= border-radius
*/

$border-radius: .8rem;
$border-radius-sm: .4rem;
$border-radius-lg: 1rem;

/* ======= shadow générique
*/

$box-shadow: 0 12px 20px 0 rgba($black,0.12);

/* ======= card
*/

$card-spacer-y:	20px;
$card-spacer-x:	30px;
$card-border-width: 0;

/* ======= spacers
*/

$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer / 4,
	2: $spacer / 2,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	n80:-80px,
	n100:-100px,
	n150:-150px
);

// renseigner à partir de combien lancer la boucle
$spacing-debut: 10;

// jusqu'à combien terminer la boucle
$spacing-fin: 150;

// permet de générer toutes les class spacing avec le responsive
@for $i from $spacing-debut through $spacing-fin {
  $spacers: map-merge(
	  (
		  $i: ($i/10)+rem
	  ),
	  $spacers
  );
}

/* ======= les gutters
*/

$grid-gutter-width: 1.5rem;
$gutters: (
	0: 0,
	1: $spacer * .25,
	2: $spacer * .5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	10: 1rem,
	20: 2rem,
	50: 5rem,
	60: 6rem,
    80: 8rem,
    85: 8.5rem,
);

/* ======= position absolute
*/

$position-values: (
	0: 0,
	10: 10%,
	20: 20%,
	40: 40%,
	50: 50%,
	90: 90%,
	100: 100%,
	150px:150px
);

$positions: ( static relative absolute fixed sticky );

/* ======= width
*/

/* ======= z-index
*/

$zindexs: (1 2 3 4 5);

/* ======= width
*/

$widths: (
	20: 20%,
	25: 25%,
	45: 45%,
	50: 50%,
	55: 55%,
	60: 60%,
	65: 65%,
	70: 70%,
	75: 75%,
	80: 80%,
	90: 90%,
	100: 100%,
	auto: auto,
	50px: 50px,
	60px: 60px,
	90px: 90px,
	100px: 100px,
	118px:118px,
	158px:158px,
	174px:174px,
	250px:250px
);

/* ======= utilities de bootstrap
*/

$utilities: ();
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
	(
		"height": (
				property: height,
				class: h,
				responsive:true,
				values: $widths
		),
		"width": (
				property: width,
				class: w,
				responsive:true,
				values: $widths
		),
		"position": (
				property: position,
				responsive:true,
				values: $positions
		),
		"z-index": (
				property: z-index,
				responsive: true,
				values: $zindexs
		),
		"font-size": (
				rfs: true,
				property: font-size,
				responsive: true,
				class: fs,
				values: $font-sizes
		),
		"font-weight": (
				property: font-weight,
				class: fw,
				values: (
					normal: $font-weight-normal,
					semi-bold: $font-weight-semi-bold,
					bold: $font-weight-bold,
				)
		),
		"line-height": (
				property: line-height,
				class: lh,
				values: $spacers
		),
		"rounded": (
				property: border-radius,
				class: rounded,
				responsive:true,
				values: (
					null: $border-radius,
					0: 0,
					1: $border-radius-sm,
					2: $border-radius,
					3: $border-radius-lg,
					circle: 50%,
				)
		),
	),
	$utilities
);

$alert-bg-scale: 0;
$alert-border-scale: 0;

/* =================== Taille des containers
 */

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
	//xxl: 1320px
);

$grid-small-gutter-horizontal-l: 3rem;
$grid-small-gutter-vertical-l: 3rem;
$grid-small-gutter-horizontal: $grid-small-gutter-horizontal-l;
$grid-small-gutter-vertical: $grid-small-gutter-vertical-l;
$grid-large-gutter-horizontal-l : 8.5rem;
$grid-large-gutter-vertical-l : 6rem;
$grid-large-gutter-horizontal : $grid-large-gutter-horizontal-l;
$grid-large-gutter-vertical : $grid-large-gutter-vertical-l;


/* ======= buttons
*/

$enable-shadows: true;
//$btn-box-shadow: 0 1px 1px rgba($black, .075);
$btn-box-shadow: 0 10px 20px 0 rgba($primary, .2);
