// Tous les types de titre par défaut

h1,
.h1{
  @include font($font-01, map-get($font-sizes,42), $font-weight-extra-bold, $primary);
}

h2,
.h2{
  @include font($font-01, map-get($font-sizes,42), $font-weight-bold, $secondary);
}

h1,
.h1,
h2,
.h2{
  line-height: 56px;
  margin-bottom: 2rem;
  @include media-breakpoint-down(lg) {
    font-size: map-get($font-sizes,30);
    line-height: 45px;
  }
}

h3,
.h3{
  @include font($font-01, map-get($font-sizes,22), $font-weight-bold, $gray-800);
  margin: 2rem 0;
}

h4,
.h4{
  @include font($font-01, map-get($font-sizes,18), $font-weight-bold, $gray-700);
  margin: 1.5rem 0;
}
