#header-top {
  background: $dark;
  color: $white;
  font-size: map-get($font-sizes, 15);
  padding: .7rem 0;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.header {
  &.bg-white {
    .wsmenucontainer:not(.wsoffcanvasopener) {
      .menu-home {
        .brand-logo,
        .brand-logo.active {
          background: center / cover no-repeat url("../images/logo-color.png");

          img {
            opacity: 0;
          }
        }
      }
    }

    .site-logo {
      img {
        &.brand-logo {
          display: none;
        }

        &.brand-logo-color {
          display: block;
        }
      }
    }
  }

  .site-logo {
    text-align: center;

    img {
      margin: auto;
      max-width: 140px;

      &.brand-logo {
        display: block;
      }

      &.brand-logo-color {
        display: none;
      }
    }
  }
}

.menu-principal {
  .brand-logo {
    display: block;
    margin: auto;

    img {
      max-width: 140px;
    }
  }
}

.inner-page {
  .header {
    background: $white;
    border-bottom: 1px solid $light;

    .wsmenucontainer:not(.wsoffcanvasopener) {
      .menu-home {
        .brand-logo {
          background: center / cover no-repeat url("../images/logo-color.png");

          img {
            opacity: 0 !important;
          }
        }
      }

      .menu-principal {
        .menu {
          > .nav-item {
            color: $dark;

            .dropdown-menu {
              .current {
                a {
                  color: $primary !important;
                }
              }
            }

            a {
              color: $dark !important;
            }
          }
        }
      }
    }

    .site-logo {
      img {
        &.brand-logo {
          display: none;
        }

        &.brand-logo-color {
          display: block;
        }
      }
    }
  }
}

.homepage {
  .bg-white {
    &.header {
      border-bottom: 1px solid $light;
    }

    .wsmenucontainer:not(.wsoffcanvasopener) {
      .menu-principal {
        .menu {
          > .nav-item {
            color: $dark;

            a {
              color: $dark !important;
            }

            &.deeper::after {
              content: url("../images/chevron.svg");
            }
          }
        }
      }
    }
  }

  .menu-principal {
    .menu {
      > .nav-item {
        color: $white !important;

        > a {
          color: $white !important;
        }

        &.deeper::after {
          content: url("../images/chevron-white.svg");
        }

        &:not(.deeper) {
          .nav-link {
            color: inherit;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .item-page .page-header {
    @include vw-full();
  }
}