@font-face {
	font-family: "icons";
	src: url('../fonts/icons.eot');
	src: url('../fonts/icons.eot?#iefix') format('eot'),
		url('../fonts/icons.woff2') format('woff2'),
		url('../fonts/icons.woff') format('woff'),
		url('../fonts/icons.ttf') format('truetype'),
		url('../fonts/icons.svg#icons') format('svg');
}

.ico:before {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.ico-chevron:before {
	content: "\E001";
}

.ico-ico-2500-utilisateurs:before {
	content: "\E002";
}

.ico-ico-accompagnement-permanent:before {
	content: "\E003";
}

.ico-ico-assurer-compatibilite:before {
	content: "\E004";
}

.ico-ico-assurer-conformite-tachygraphe:before {
	content: "\E005";
}

.ico-ico-assurer-securite-chauffeurs:before {
	content: "\E006";
}

.ico-ico-augmenter-productivite:before {
	content: "\E007";
}

.ico-ico-augmenter-securite-conducteur:before {
	content: "\E008";
}

.ico-ico-car:before {
	content: "\E009";
}

.ico-ico-certification-iso:before {
	content: "\E00A";
}

.ico-ico-communication-transparente:before {
	content: "\E00B";
}

.ico-ico-conduite-eco:before {
	content: "\E00C";
}

.ico-ico-consacrer-moins-temps:before {
	content: "\E00D";
}

.ico-ico-controler-deplacements:before {
	content: "\E00E";
}

.ico-ico-creer-planification:before {
	content: "\E00F";
}

.ico-ico-diminuer-consommation-depenses:before {
	content: "\E010";
}

.ico-ico-eviter-vols-materiels:before {
	content: "\E011";
}

.ico-ico-fb:before {
	content: "\E012";
}

.ico-ico-file:before {
	content: "\E013";
}

.ico-ico-gearbox:before {
	content: "\E014";
}

.ico-ico-gerer-flotte-poids-lourds:before {
	content: "\E015";
}

.ico-ico-gerer-utilisations-actifs:before {
	content: "\E016";
}

.ico-ico-home:before {
	content: "\E017";
}

.ico-ico-identification-condu:before {
	content: "\E018";
}

.ico-ico-linkedin:before {
	content: "\E019";
}

.ico-ico-localiser-actifs:before {
	content: "\E01A";
}

.ico-ico-maintenir-materiel-etat:before {
	content: "\E01B";
}

.ico-ico-map:before {
	content: "\E01C";
}

.ico-ico-optimiser-satisfaction:before {
	content: "\E01D";
}

.ico-ico-prix:before {
	content: "\E01E";
}

.ico-ico-rd-innovante:before {
	content: "\E01F";
}

.ico-ico-reduire-couts:before {
	content: "\E020";
}

.ico-ico-reduire-impact-envi:before {
	content: "\E021";
}

.ico-ico-report:before {
	content: "\E022";
}

.ico-ico-secure:before {
	content: "\E023";
}

.ico-ico-service-client:before {
	content: "\E024";
}

.ico-ico-solutions-metiers-adaptees:before {
	content: "\E025";
}

.ico-ico-truck:before {
	content: "\E026";
}

.ico-ico-users:before {
	content: "\E027";
}

.ico-logo-skazy:before {
	content: "\E028";
}
