@charset "UTF-8";
/* ======= uikit overlay couleur
*/
/* ======= couleur des liens par défault
*/
/* ======= font size
*/
/* Pour faire l'appel de la font désiré :
  map-get($font-size,10);
 */
/* ======= fonts à déclarer
*/
/* ======= fonts weight
*/
/* variables de bootsrap à réutiliser
$font-weight-light: 300;
$font-weight-medium : 500;
*/
/* ======= transitions
*/
/* ======= dropdown
*/
/* ======= border-radius
*/
/* ======= shadow générique
*/
/* ======= card
*/
/* ======= spacers
*/
/* ======= les gutters
*/
/* ======= position absolute
*/
/* ======= width
*/
/* ======= z-index
*/
/* ======= width
*/
/* ======= utilities de bootstrap
*/
/* =================== Taille des containers
 */
/* ======= buttons
*/
/* ====== obligatoire
 */
/* ====== optionnel
 */
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700;900&display=swap");
:root {
  --bs-blue: #007bff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #28a745;
  --bs-teal: #20c997;
  --bs-cyan: #65afe2;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #333333;
  --bs-gray-100: #F2F2F2;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #333333;
  --bs-gray-900: #0C0C0C;
  --bs-primary: #B1C903;
  --bs-secondary: #315659;
  --bs-gray-100: #F2F2F2;
  --bs-success: #28a745;
  --bs-info: #65afe2;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #F2F2F2;
  --bs-dark: #333333;
  --bs-mobile: #dee2e6;
  --bs-body: #fff;
  --bs-prefooter: #253031;
  --bs-footer: #315659;
  --bs-error: #dee2e6;
  --bs-white: #fff;
  --bs-orange: #F97B24;
  --bs-bleu: #11A2F8;
  --bs-gray900: #0C0C0C;
  --bs-primary-rgb: 177, 201, 3;
  --bs-secondary-rgb: 49, 86, 89;
  --bs-gray-100-rgb: 242, 242, 242;
  --bs-success-rgb: 40, 167, 69;
  --bs-info-rgb: 101, 175, 226;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 242, 242, 242;
  --bs-dark-rgb: 51, 51, 51;
  --bs-mobile-rgb: 222, 226, 230;
  --bs-body-rgb: 255, 255, 255;
  --bs-prefooter-rgb: 37, 48, 49;
  --bs-footer-rgb: 49, 86, 89;
  --bs-error-rgb: 222, 226, 230;
  --bs-white-rgb: 255, 255, 255;
  --bs-orange-rgb: 249, 123, 36;
  --bs-bleu-rgb: 17, 162, 248;
  --bs-gray900-rgb: 12, 12, 12;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 12, 12, 12;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Titillium Web, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #0C0C0C;
  --bs-body-bg: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #B1C903;
  text-decoration: underline; }
  a:hover {
    color: #8ea102; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #0C0C0C;
  border-radius: 0.4rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.12) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-10 {
  top: 10% !important; }

.top-20 {
  top: 20% !important; }

.top-40 {
  top: 40% !important; }

.top-50 {
  top: 50% !important; }

.top-90 {
  top: 90% !important; }

.top-100 {
  top: 100% !important; }

.top-150px {
  top: 150px !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-10 {
  bottom: 10% !important; }

.bottom-20 {
  bottom: 20% !important; }

.bottom-40 {
  bottom: 40% !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-90 {
  bottom: 90% !important; }

.bottom-100 {
  bottom: 100% !important; }

.bottom-150px {
  bottom: 150px !important; }

.start-0 {
  left: 0 !important; }

.start-10 {
  left: 10% !important; }

.start-20 {
  left: 20% !important; }

.start-40 {
  left: 40% !important; }

.start-50 {
  left: 50% !important; }

.start-90 {
  left: 90% !important; }

.start-100 {
  left: 100% !important; }

.start-150px {
  left: 150px !important; }

.end-0 {
  right: 0 !important; }

.end-10 {
  right: 10% !important; }

.end-20 {
  right: 20% !important; }

.end-40 {
  right: 40% !important; }

.end-50 {
  right: 50% !important; }

.end-90 {
  right: 90% !important; }

.end-100 {
  right: 100% !important; }

.end-150px {
  right: 150px !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #B1C903 !important; }

.border-secondary {
  border-color: #315659 !important; }

.border-gray-100 {
  border-color: #F2F2F2 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #65afe2 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #F2F2F2 !important; }

.border-dark {
  border-color: #333333 !important; }

.border-mobile {
  border-color: #dee2e6 !important; }

.border-body {
  border-color: #fff !important; }

.border-prefooter {
  border-color: #253031 !important; }

.border-footer {
  border-color: #315659 !important; }

.border-error {
  border-color: #dee2e6 !important; }

.border-white {
  border-color: #fff !important; }

.border-orange {
  border-color: #F97B24 !important; }

.border-bleu {
  border-color: #11A2F8 !important; }

.border-gray900 {
  border-color: #0C0C0C !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-20 {
  width: 20% !important; }

.w-25 {
  width: 25% !important; }

.w-45 {
  width: 45% !important; }

.w-50 {
  width: 50% !important; }

.w-55 {
  width: 55% !important; }

.w-60 {
  width: 60% !important; }

.w-65 {
  width: 65% !important; }

.w-70 {
  width: 70% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-50px {
  width: 50px !important; }

.w-60px {
  width: 60px !important; }

.w-90px {
  width: 90px !important; }

.w-100px {
  width: 100px !important; }

.w-118px {
  width: 118px !important; }

.w-158px {
  width: 158px !important; }

.w-174px {
  width: 174px !important; }

.w-250px {
  width: 250px !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-20 {
  height: 20% !important; }

.h-25 {
  height: 25% !important; }

.h-45 {
  height: 45% !important; }

.h-50 {
  height: 50% !important; }

.h-55 {
  height: 55% !important; }

.h-60 {
  height: 60% !important; }

.h-65 {
  height: 65% !important; }

.h-70 {
  height: 70% !important; }

.h-75 {
  height: 75% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-50px {
  height: 50px !important; }

.h-60px {
  height: 60px !important; }

.h-90px {
  height: 90px !important; }

.h-100px {
  height: 100px !important; }

.h-118px {
  height: 118px !important; }

.h-158px {
  height: 158px !important; }

.h-174px {
  height: 174px !important; }

.h-250px {
  height: 250px !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-150 {
  gap: 15rem !important; }

.gap-149 {
  gap: 14.9rem !important; }

.gap-148 {
  gap: 14.8rem !important; }

.gap-147 {
  gap: 14.7rem !important; }

.gap-146 {
  gap: 14.6rem !important; }

.gap-145 {
  gap: 14.5rem !important; }

.gap-144 {
  gap: 14.4rem !important; }

.gap-143 {
  gap: 14.3rem !important; }

.gap-142 {
  gap: 14.2rem !important; }

.gap-141 {
  gap: 14.1rem !important; }

.gap-140 {
  gap: 14rem !important; }

.gap-139 {
  gap: 13.9rem !important; }

.gap-138 {
  gap: 13.8rem !important; }

.gap-137 {
  gap: 13.7rem !important; }

.gap-136 {
  gap: 13.6rem !important; }

.gap-135 {
  gap: 13.5rem !important; }

.gap-134 {
  gap: 13.4rem !important; }

.gap-133 {
  gap: 13.3rem !important; }

.gap-132 {
  gap: 13.2rem !important; }

.gap-131 {
  gap: 13.1rem !important; }

.gap-130 {
  gap: 13rem !important; }

.gap-129 {
  gap: 12.9rem !important; }

.gap-128 {
  gap: 12.8rem !important; }

.gap-127 {
  gap: 12.7rem !important; }

.gap-126 {
  gap: 12.6rem !important; }

.gap-125 {
  gap: 12.5rem !important; }

.gap-124 {
  gap: 12.4rem !important; }

.gap-123 {
  gap: 12.3rem !important; }

.gap-122 {
  gap: 12.2rem !important; }

.gap-121 {
  gap: 12.1rem !important; }

.gap-120 {
  gap: 12rem !important; }

.gap-119 {
  gap: 11.9rem !important; }

.gap-118 {
  gap: 11.8rem !important; }

.gap-117 {
  gap: 11.7rem !important; }

.gap-116 {
  gap: 11.6rem !important; }

.gap-115 {
  gap: 11.5rem !important; }

.gap-114 {
  gap: 11.4rem !important; }

.gap-113 {
  gap: 11.3rem !important; }

.gap-112 {
  gap: 11.2rem !important; }

.gap-111 {
  gap: 11.1rem !important; }

.gap-110 {
  gap: 11rem !important; }

.gap-109 {
  gap: 10.9rem !important; }

.gap-108 {
  gap: 10.8rem !important; }

.gap-107 {
  gap: 10.7rem !important; }

.gap-106 {
  gap: 10.6rem !important; }

.gap-105 {
  gap: 10.5rem !important; }

.gap-104 {
  gap: 10.4rem !important; }

.gap-103 {
  gap: 10.3rem !important; }

.gap-102 {
  gap: 10.2rem !important; }

.gap-101 {
  gap: 10.1rem !important; }

.gap-100 {
  gap: 10rem !important; }

.gap-99 {
  gap: 9.9rem !important; }

.gap-98 {
  gap: 9.8rem !important; }

.gap-97 {
  gap: 9.7rem !important; }

.gap-96 {
  gap: 9.6rem !important; }

.gap-95 {
  gap: 9.5rem !important; }

.gap-94 {
  gap: 9.4rem !important; }

.gap-93 {
  gap: 9.3rem !important; }

.gap-92 {
  gap: 9.2rem !important; }

.gap-91 {
  gap: 9.1rem !important; }

.gap-90 {
  gap: 9rem !important; }

.gap-89 {
  gap: 8.9rem !important; }

.gap-88 {
  gap: 8.8rem !important; }

.gap-87 {
  gap: 8.7rem !important; }

.gap-86 {
  gap: 8.6rem !important; }

.gap-85 {
  gap: 8.5rem !important; }

.gap-84 {
  gap: 8.4rem !important; }

.gap-83 {
  gap: 8.3rem !important; }

.gap-82 {
  gap: 8.2rem !important; }

.gap-81 {
  gap: 8.1rem !important; }

.gap-80 {
  gap: 8rem !important; }

.gap-79 {
  gap: 7.9rem !important; }

.gap-78 {
  gap: 7.8rem !important; }

.gap-77 {
  gap: 7.7rem !important; }

.gap-76 {
  gap: 7.6rem !important; }

.gap-75 {
  gap: 7.5rem !important; }

.gap-74 {
  gap: 7.4rem !important; }

.gap-73 {
  gap: 7.3rem !important; }

.gap-72 {
  gap: 7.2rem !important; }

.gap-71 {
  gap: 7.1rem !important; }

.gap-70 {
  gap: 7rem !important; }

.gap-69 {
  gap: 6.9rem !important; }

.gap-68 {
  gap: 6.8rem !important; }

.gap-67 {
  gap: 6.7rem !important; }

.gap-66 {
  gap: 6.6rem !important; }

.gap-65 {
  gap: 6.5rem !important; }

.gap-64 {
  gap: 6.4rem !important; }

.gap-63 {
  gap: 6.3rem !important; }

.gap-62 {
  gap: 6.2rem !important; }

.gap-61 {
  gap: 6.1rem !important; }

.gap-60 {
  gap: 6rem !important; }

.gap-59 {
  gap: 5.9rem !important; }

.gap-58 {
  gap: 5.8rem !important; }

.gap-57 {
  gap: 5.7rem !important; }

.gap-56 {
  gap: 5.6rem !important; }

.gap-55 {
  gap: 5.5rem !important; }

.gap-54 {
  gap: 5.4rem !important; }

.gap-53 {
  gap: 5.3rem !important; }

.gap-52 {
  gap: 5.2rem !important; }

.gap-51 {
  gap: 5.1rem !important; }

.gap-50 {
  gap: 5rem !important; }

.gap-49 {
  gap: 4.9rem !important; }

.gap-48 {
  gap: 4.8rem !important; }

.gap-47 {
  gap: 4.7rem !important; }

.gap-46 {
  gap: 4.6rem !important; }

.gap-45 {
  gap: 4.5rem !important; }

.gap-44 {
  gap: 4.4rem !important; }

.gap-43 {
  gap: 4.3rem !important; }

.gap-42 {
  gap: 4.2rem !important; }

.gap-41 {
  gap: 4.1rem !important; }

.gap-40 {
  gap: 4rem !important; }

.gap-39 {
  gap: 3.9rem !important; }

.gap-38 {
  gap: 3.8rem !important; }

.gap-37 {
  gap: 3.7rem !important; }

.gap-36 {
  gap: 3.6rem !important; }

.gap-35 {
  gap: 3.5rem !important; }

.gap-34 {
  gap: 3.4rem !important; }

.gap-33 {
  gap: 3.3rem !important; }

.gap-32 {
  gap: 3.2rem !important; }

.gap-31 {
  gap: 3.1rem !important; }

.gap-30 {
  gap: 3rem !important; }

.gap-29 {
  gap: 2.9rem !important; }

.gap-28 {
  gap: 2.8rem !important; }

.gap-27 {
  gap: 2.7rem !important; }

.gap-26 {
  gap: 2.6rem !important; }

.gap-25 {
  gap: 2.5rem !important; }

.gap-24 {
  gap: 2.4rem !important; }

.gap-23 {
  gap: 2.3rem !important; }

.gap-22 {
  gap: 2.2rem !important; }

.gap-21 {
  gap: 2.1rem !important; }

.gap-20 {
  gap: 2rem !important; }

.gap-19 {
  gap: 1.9rem !important; }

.gap-18 {
  gap: 1.8rem !important; }

.gap-17 {
  gap: 1.7rem !important; }

.gap-16 {
  gap: 1.6rem !important; }

.gap-15 {
  gap: 1.5rem !important; }

.gap-14 {
  gap: 1.4rem !important; }

.gap-13 {
  gap: 1.3rem !important; }

.gap-12 {
  gap: 1.2rem !important; }

.gap-11 {
  gap: 1.1rem !important; }

.gap-10 {
  gap: 1rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.gap-n80 {
  gap: -80px !important; }

.gap-n100 {
  gap: -100px !important; }

.gap-n150 {
  gap: -150px !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-150 {
  margin: 15rem !important; }

.m-149 {
  margin: 14.9rem !important; }

.m-148 {
  margin: 14.8rem !important; }

.m-147 {
  margin: 14.7rem !important; }

.m-146 {
  margin: 14.6rem !important; }

.m-145 {
  margin: 14.5rem !important; }

.m-144 {
  margin: 14.4rem !important; }

.m-143 {
  margin: 14.3rem !important; }

.m-142 {
  margin: 14.2rem !important; }

.m-141 {
  margin: 14.1rem !important; }

.m-140 {
  margin: 14rem !important; }

.m-139 {
  margin: 13.9rem !important; }

.m-138 {
  margin: 13.8rem !important; }

.m-137 {
  margin: 13.7rem !important; }

.m-136 {
  margin: 13.6rem !important; }

.m-135 {
  margin: 13.5rem !important; }

.m-134 {
  margin: 13.4rem !important; }

.m-133 {
  margin: 13.3rem !important; }

.m-132 {
  margin: 13.2rem !important; }

.m-131 {
  margin: 13.1rem !important; }

.m-130 {
  margin: 13rem !important; }

.m-129 {
  margin: 12.9rem !important; }

.m-128 {
  margin: 12.8rem !important; }

.m-127 {
  margin: 12.7rem !important; }

.m-126 {
  margin: 12.6rem !important; }

.m-125 {
  margin: 12.5rem !important; }

.m-124 {
  margin: 12.4rem !important; }

.m-123 {
  margin: 12.3rem !important; }

.m-122 {
  margin: 12.2rem !important; }

.m-121 {
  margin: 12.1rem !important; }

.m-120 {
  margin: 12rem !important; }

.m-119 {
  margin: 11.9rem !important; }

.m-118 {
  margin: 11.8rem !important; }

.m-117 {
  margin: 11.7rem !important; }

.m-116 {
  margin: 11.6rem !important; }

.m-115 {
  margin: 11.5rem !important; }

.m-114 {
  margin: 11.4rem !important; }

.m-113 {
  margin: 11.3rem !important; }

.m-112 {
  margin: 11.2rem !important; }

.m-111 {
  margin: 11.1rem !important; }

.m-110 {
  margin: 11rem !important; }

.m-109 {
  margin: 10.9rem !important; }

.m-108 {
  margin: 10.8rem !important; }

.m-107 {
  margin: 10.7rem !important; }

.m-106 {
  margin: 10.6rem !important; }

.m-105 {
  margin: 10.5rem !important; }

.m-104 {
  margin: 10.4rem !important; }

.m-103 {
  margin: 10.3rem !important; }

.m-102 {
  margin: 10.2rem !important; }

.m-101 {
  margin: 10.1rem !important; }

.m-100 {
  margin: 10rem !important; }

.m-99 {
  margin: 9.9rem !important; }

.m-98 {
  margin: 9.8rem !important; }

.m-97 {
  margin: 9.7rem !important; }

.m-96 {
  margin: 9.6rem !important; }

.m-95 {
  margin: 9.5rem !important; }

.m-94 {
  margin: 9.4rem !important; }

.m-93 {
  margin: 9.3rem !important; }

.m-92 {
  margin: 9.2rem !important; }

.m-91 {
  margin: 9.1rem !important; }

.m-90 {
  margin: 9rem !important; }

.m-89 {
  margin: 8.9rem !important; }

.m-88 {
  margin: 8.8rem !important; }

.m-87 {
  margin: 8.7rem !important; }

.m-86 {
  margin: 8.6rem !important; }

.m-85 {
  margin: 8.5rem !important; }

.m-84 {
  margin: 8.4rem !important; }

.m-83 {
  margin: 8.3rem !important; }

.m-82 {
  margin: 8.2rem !important; }

.m-81 {
  margin: 8.1rem !important; }

.m-80 {
  margin: 8rem !important; }

.m-79 {
  margin: 7.9rem !important; }

.m-78 {
  margin: 7.8rem !important; }

.m-77 {
  margin: 7.7rem !important; }

.m-76 {
  margin: 7.6rem !important; }

.m-75 {
  margin: 7.5rem !important; }

.m-74 {
  margin: 7.4rem !important; }

.m-73 {
  margin: 7.3rem !important; }

.m-72 {
  margin: 7.2rem !important; }

.m-71 {
  margin: 7.1rem !important; }

.m-70 {
  margin: 7rem !important; }

.m-69 {
  margin: 6.9rem !important; }

.m-68 {
  margin: 6.8rem !important; }

.m-67 {
  margin: 6.7rem !important; }

.m-66 {
  margin: 6.6rem !important; }

.m-65 {
  margin: 6.5rem !important; }

.m-64 {
  margin: 6.4rem !important; }

.m-63 {
  margin: 6.3rem !important; }

.m-62 {
  margin: 6.2rem !important; }

.m-61 {
  margin: 6.1rem !important; }

.m-60 {
  margin: 6rem !important; }

.m-59 {
  margin: 5.9rem !important; }

.m-58 {
  margin: 5.8rem !important; }

.m-57 {
  margin: 5.7rem !important; }

.m-56 {
  margin: 5.6rem !important; }

.m-55 {
  margin: 5.5rem !important; }

.m-54 {
  margin: 5.4rem !important; }

.m-53 {
  margin: 5.3rem !important; }

.m-52 {
  margin: 5.2rem !important; }

.m-51 {
  margin: 5.1rem !important; }

.m-50 {
  margin: 5rem !important; }

.m-49 {
  margin: 4.9rem !important; }

.m-48 {
  margin: 4.8rem !important; }

.m-47 {
  margin: 4.7rem !important; }

.m-46 {
  margin: 4.6rem !important; }

.m-45 {
  margin: 4.5rem !important; }

.m-44 {
  margin: 4.4rem !important; }

.m-43 {
  margin: 4.3rem !important; }

.m-42 {
  margin: 4.2rem !important; }

.m-41 {
  margin: 4.1rem !important; }

.m-40 {
  margin: 4rem !important; }

.m-39 {
  margin: 3.9rem !important; }

.m-38 {
  margin: 3.8rem !important; }

.m-37 {
  margin: 3.7rem !important; }

.m-36 {
  margin: 3.6rem !important; }

.m-35 {
  margin: 3.5rem !important; }

.m-34 {
  margin: 3.4rem !important; }

.m-33 {
  margin: 3.3rem !important; }

.m-32 {
  margin: 3.2rem !important; }

.m-31 {
  margin: 3.1rem !important; }

.m-30 {
  margin: 3rem !important; }

.m-29 {
  margin: 2.9rem !important; }

.m-28 {
  margin: 2.8rem !important; }

.m-27 {
  margin: 2.7rem !important; }

.m-26 {
  margin: 2.6rem !important; }

.m-25 {
  margin: 2.5rem !important; }

.m-24 {
  margin: 2.4rem !important; }

.m-23 {
  margin: 2.3rem !important; }

.m-22 {
  margin: 2.2rem !important; }

.m-21 {
  margin: 2.1rem !important; }

.m-20 {
  margin: 2rem !important; }

.m-19 {
  margin: 1.9rem !important; }

.m-18 {
  margin: 1.8rem !important; }

.m-17 {
  margin: 1.7rem !important; }

.m-16 {
  margin: 1.6rem !important; }

.m-15 {
  margin: 1.5rem !important; }

.m-14 {
  margin: 1.4rem !important; }

.m-13 {
  margin: 1.3rem !important; }

.m-12 {
  margin: 1.2rem !important; }

.m-11 {
  margin: 1.1rem !important; }

.m-10 {
  margin: 1rem !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-n80 {
  margin: -80px !important; }

.m-n100 {
  margin: -100px !important; }

.m-n150 {
  margin: -150px !important; }

.m-auto {
  margin: auto !important; }

.mx-150 {
  margin-right: 15rem !important;
  margin-left: 15rem !important; }

.mx-149 {
  margin-right: 14.9rem !important;
  margin-left: 14.9rem !important; }

.mx-148 {
  margin-right: 14.8rem !important;
  margin-left: 14.8rem !important; }

.mx-147 {
  margin-right: 14.7rem !important;
  margin-left: 14.7rem !important; }

.mx-146 {
  margin-right: 14.6rem !important;
  margin-left: 14.6rem !important; }

.mx-145 {
  margin-right: 14.5rem !important;
  margin-left: 14.5rem !important; }

.mx-144 {
  margin-right: 14.4rem !important;
  margin-left: 14.4rem !important; }

.mx-143 {
  margin-right: 14.3rem !important;
  margin-left: 14.3rem !important; }

.mx-142 {
  margin-right: 14.2rem !important;
  margin-left: 14.2rem !important; }

.mx-141 {
  margin-right: 14.1rem !important;
  margin-left: 14.1rem !important; }

.mx-140 {
  margin-right: 14rem !important;
  margin-left: 14rem !important; }

.mx-139 {
  margin-right: 13.9rem !important;
  margin-left: 13.9rem !important; }

.mx-138 {
  margin-right: 13.8rem !important;
  margin-left: 13.8rem !important; }

.mx-137 {
  margin-right: 13.7rem !important;
  margin-left: 13.7rem !important; }

.mx-136 {
  margin-right: 13.6rem !important;
  margin-left: 13.6rem !important; }

.mx-135 {
  margin-right: 13.5rem !important;
  margin-left: 13.5rem !important; }

.mx-134 {
  margin-right: 13.4rem !important;
  margin-left: 13.4rem !important; }

.mx-133 {
  margin-right: 13.3rem !important;
  margin-left: 13.3rem !important; }

.mx-132 {
  margin-right: 13.2rem !important;
  margin-left: 13.2rem !important; }

.mx-131 {
  margin-right: 13.1rem !important;
  margin-left: 13.1rem !important; }

.mx-130 {
  margin-right: 13rem !important;
  margin-left: 13rem !important; }

.mx-129 {
  margin-right: 12.9rem !important;
  margin-left: 12.9rem !important; }

.mx-128 {
  margin-right: 12.8rem !important;
  margin-left: 12.8rem !important; }

.mx-127 {
  margin-right: 12.7rem !important;
  margin-left: 12.7rem !important; }

.mx-126 {
  margin-right: 12.6rem !important;
  margin-left: 12.6rem !important; }

.mx-125 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important; }

.mx-124 {
  margin-right: 12.4rem !important;
  margin-left: 12.4rem !important; }

.mx-123 {
  margin-right: 12.3rem !important;
  margin-left: 12.3rem !important; }

.mx-122 {
  margin-right: 12.2rem !important;
  margin-left: 12.2rem !important; }

.mx-121 {
  margin-right: 12.1rem !important;
  margin-left: 12.1rem !important; }

.mx-120 {
  margin-right: 12rem !important;
  margin-left: 12rem !important; }

.mx-119 {
  margin-right: 11.9rem !important;
  margin-left: 11.9rem !important; }

.mx-118 {
  margin-right: 11.8rem !important;
  margin-left: 11.8rem !important; }

.mx-117 {
  margin-right: 11.7rem !important;
  margin-left: 11.7rem !important; }

.mx-116 {
  margin-right: 11.6rem !important;
  margin-left: 11.6rem !important; }

.mx-115 {
  margin-right: 11.5rem !important;
  margin-left: 11.5rem !important; }

.mx-114 {
  margin-right: 11.4rem !important;
  margin-left: 11.4rem !important; }

.mx-113 {
  margin-right: 11.3rem !important;
  margin-left: 11.3rem !important; }

.mx-112 {
  margin-right: 11.2rem !important;
  margin-left: 11.2rem !important; }

.mx-111 {
  margin-right: 11.1rem !important;
  margin-left: 11.1rem !important; }

.mx-110 {
  margin-right: 11rem !important;
  margin-left: 11rem !important; }

.mx-109 {
  margin-right: 10.9rem !important;
  margin-left: 10.9rem !important; }

.mx-108 {
  margin-right: 10.8rem !important;
  margin-left: 10.8rem !important; }

.mx-107 {
  margin-right: 10.7rem !important;
  margin-left: 10.7rem !important; }

.mx-106 {
  margin-right: 10.6rem !important;
  margin-left: 10.6rem !important; }

.mx-105 {
  margin-right: 10.5rem !important;
  margin-left: 10.5rem !important; }

.mx-104 {
  margin-right: 10.4rem !important;
  margin-left: 10.4rem !important; }

.mx-103 {
  margin-right: 10.3rem !important;
  margin-left: 10.3rem !important; }

.mx-102 {
  margin-right: 10.2rem !important;
  margin-left: 10.2rem !important; }

.mx-101 {
  margin-right: 10.1rem !important;
  margin-left: 10.1rem !important; }

.mx-100 {
  margin-right: 10rem !important;
  margin-left: 10rem !important; }

.mx-99 {
  margin-right: 9.9rem !important;
  margin-left: 9.9rem !important; }

.mx-98 {
  margin-right: 9.8rem !important;
  margin-left: 9.8rem !important; }

.mx-97 {
  margin-right: 9.7rem !important;
  margin-left: 9.7rem !important; }

.mx-96 {
  margin-right: 9.6rem !important;
  margin-left: 9.6rem !important; }

.mx-95 {
  margin-right: 9.5rem !important;
  margin-left: 9.5rem !important; }

.mx-94 {
  margin-right: 9.4rem !important;
  margin-left: 9.4rem !important; }

.mx-93 {
  margin-right: 9.3rem !important;
  margin-left: 9.3rem !important; }

.mx-92 {
  margin-right: 9.2rem !important;
  margin-left: 9.2rem !important; }

.mx-91 {
  margin-right: 9.1rem !important;
  margin-left: 9.1rem !important; }

.mx-90 {
  margin-right: 9rem !important;
  margin-left: 9rem !important; }

.mx-89 {
  margin-right: 8.9rem !important;
  margin-left: 8.9rem !important; }

.mx-88 {
  margin-right: 8.8rem !important;
  margin-left: 8.8rem !important; }

.mx-87 {
  margin-right: 8.7rem !important;
  margin-left: 8.7rem !important; }

.mx-86 {
  margin-right: 8.6rem !important;
  margin-left: 8.6rem !important; }

.mx-85 {
  margin-right: 8.5rem !important;
  margin-left: 8.5rem !important; }

.mx-84 {
  margin-right: 8.4rem !important;
  margin-left: 8.4rem !important; }

.mx-83 {
  margin-right: 8.3rem !important;
  margin-left: 8.3rem !important; }

.mx-82 {
  margin-right: 8.2rem !important;
  margin-left: 8.2rem !important; }

.mx-81 {
  margin-right: 8.1rem !important;
  margin-left: 8.1rem !important; }

.mx-80 {
  margin-right: 8rem !important;
  margin-left: 8rem !important; }

.mx-79 {
  margin-right: 7.9rem !important;
  margin-left: 7.9rem !important; }

.mx-78 {
  margin-right: 7.8rem !important;
  margin-left: 7.8rem !important; }

.mx-77 {
  margin-right: 7.7rem !important;
  margin-left: 7.7rem !important; }

.mx-76 {
  margin-right: 7.6rem !important;
  margin-left: 7.6rem !important; }

.mx-75 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important; }

.mx-74 {
  margin-right: 7.4rem !important;
  margin-left: 7.4rem !important; }

.mx-73 {
  margin-right: 7.3rem !important;
  margin-left: 7.3rem !important; }

.mx-72 {
  margin-right: 7.2rem !important;
  margin-left: 7.2rem !important; }

.mx-71 {
  margin-right: 7.1rem !important;
  margin-left: 7.1rem !important; }

.mx-70 {
  margin-right: 7rem !important;
  margin-left: 7rem !important; }

.mx-69 {
  margin-right: 6.9rem !important;
  margin-left: 6.9rem !important; }

.mx-68 {
  margin-right: 6.8rem !important;
  margin-left: 6.8rem !important; }

.mx-67 {
  margin-right: 6.7rem !important;
  margin-left: 6.7rem !important; }

.mx-66 {
  margin-right: 6.6rem !important;
  margin-left: 6.6rem !important; }

.mx-65 {
  margin-right: 6.5rem !important;
  margin-left: 6.5rem !important; }

.mx-64 {
  margin-right: 6.4rem !important;
  margin-left: 6.4rem !important; }

.mx-63 {
  margin-right: 6.3rem !important;
  margin-left: 6.3rem !important; }

.mx-62 {
  margin-right: 6.2rem !important;
  margin-left: 6.2rem !important; }

.mx-61 {
  margin-right: 6.1rem !important;
  margin-left: 6.1rem !important; }

.mx-60 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.mx-59 {
  margin-right: 5.9rem !important;
  margin-left: 5.9rem !important; }

.mx-58 {
  margin-right: 5.8rem !important;
  margin-left: 5.8rem !important; }

.mx-57 {
  margin-right: 5.7rem !important;
  margin-left: 5.7rem !important; }

.mx-56 {
  margin-right: 5.6rem !important;
  margin-left: 5.6rem !important; }

.mx-55 {
  margin-right: 5.5rem !important;
  margin-left: 5.5rem !important; }

.mx-54 {
  margin-right: 5.4rem !important;
  margin-left: 5.4rem !important; }

.mx-53 {
  margin-right: 5.3rem !important;
  margin-left: 5.3rem !important; }

.mx-52 {
  margin-right: 5.2rem !important;
  margin-left: 5.2rem !important; }

.mx-51 {
  margin-right: 5.1rem !important;
  margin-left: 5.1rem !important; }

.mx-50 {
  margin-right: 5rem !important;
  margin-left: 5rem !important; }

.mx-49 {
  margin-right: 4.9rem !important;
  margin-left: 4.9rem !important; }

.mx-48 {
  margin-right: 4.8rem !important;
  margin-left: 4.8rem !important; }

.mx-47 {
  margin-right: 4.7rem !important;
  margin-left: 4.7rem !important; }

.mx-46 {
  margin-right: 4.6rem !important;
  margin-left: 4.6rem !important; }

.mx-45 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important; }

.mx-44 {
  margin-right: 4.4rem !important;
  margin-left: 4.4rem !important; }

.mx-43 {
  margin-right: 4.3rem !important;
  margin-left: 4.3rem !important; }

.mx-42 {
  margin-right: 4.2rem !important;
  margin-left: 4.2rem !important; }

.mx-41 {
  margin-right: 4.1rem !important;
  margin-left: 4.1rem !important; }

.mx-40 {
  margin-right: 4rem !important;
  margin-left: 4rem !important; }

.mx-39 {
  margin-right: 3.9rem !important;
  margin-left: 3.9rem !important; }

.mx-38 {
  margin-right: 3.8rem !important;
  margin-left: 3.8rem !important; }

.mx-37 {
  margin-right: 3.7rem !important;
  margin-left: 3.7rem !important; }

.mx-36 {
  margin-right: 3.6rem !important;
  margin-left: 3.6rem !important; }

.mx-35 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important; }

.mx-34 {
  margin-right: 3.4rem !important;
  margin-left: 3.4rem !important; }

.mx-33 {
  margin-right: 3.3rem !important;
  margin-left: 3.3rem !important; }

.mx-32 {
  margin-right: 3.2rem !important;
  margin-left: 3.2rem !important; }

.mx-31 {
  margin-right: 3.1rem !important;
  margin-left: 3.1rem !important; }

.mx-30 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-29 {
  margin-right: 2.9rem !important;
  margin-left: 2.9rem !important; }

.mx-28 {
  margin-right: 2.8rem !important;
  margin-left: 2.8rem !important; }

.mx-27 {
  margin-right: 2.7rem !important;
  margin-left: 2.7rem !important; }

.mx-26 {
  margin-right: 2.6rem !important;
  margin-left: 2.6rem !important; }

.mx-25 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important; }

.mx-24 {
  margin-right: 2.4rem !important;
  margin-left: 2.4rem !important; }

.mx-23 {
  margin-right: 2.3rem !important;
  margin-left: 2.3rem !important; }

.mx-22 {
  margin-right: 2.2rem !important;
  margin-left: 2.2rem !important; }

.mx-21 {
  margin-right: 2.1rem !important;
  margin-left: 2.1rem !important; }

.mx-20 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.mx-19 {
  margin-right: 1.9rem !important;
  margin-left: 1.9rem !important; }

.mx-18 {
  margin-right: 1.8rem !important;
  margin-left: 1.8rem !important; }

.mx-17 {
  margin-right: 1.7rem !important;
  margin-left: 1.7rem !important; }

.mx-16 {
  margin-right: 1.6rem !important;
  margin-left: 1.6rem !important; }

.mx-15 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-14 {
  margin-right: 1.4rem !important;
  margin-left: 1.4rem !important; }

.mx-13 {
  margin-right: 1.3rem !important;
  margin-left: 1.3rem !important; }

.mx-12 {
  margin-right: 1.2rem !important;
  margin-left: 1.2rem !important; }

.mx-11 {
  margin-right: 1.1rem !important;
  margin-left: 1.1rem !important; }

.mx-10 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-n80 {
  margin-right: -80px !important;
  margin-left: -80px !important; }

.mx-n100 {
  margin-right: -100px !important;
  margin-left: -100px !important; }

.mx-n150 {
  margin-right: -150px !important;
  margin-left: -150px !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-150 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important; }

.my-149 {
  margin-top: 14.9rem !important;
  margin-bottom: 14.9rem !important; }

.my-148 {
  margin-top: 14.8rem !important;
  margin-bottom: 14.8rem !important; }

.my-147 {
  margin-top: 14.7rem !important;
  margin-bottom: 14.7rem !important; }

.my-146 {
  margin-top: 14.6rem !important;
  margin-bottom: 14.6rem !important; }

.my-145 {
  margin-top: 14.5rem !important;
  margin-bottom: 14.5rem !important; }

.my-144 {
  margin-top: 14.4rem !important;
  margin-bottom: 14.4rem !important; }

.my-143 {
  margin-top: 14.3rem !important;
  margin-bottom: 14.3rem !important; }

.my-142 {
  margin-top: 14.2rem !important;
  margin-bottom: 14.2rem !important; }

.my-141 {
  margin-top: 14.1rem !important;
  margin-bottom: 14.1rem !important; }

.my-140 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important; }

.my-139 {
  margin-top: 13.9rem !important;
  margin-bottom: 13.9rem !important; }

.my-138 {
  margin-top: 13.8rem !important;
  margin-bottom: 13.8rem !important; }

.my-137 {
  margin-top: 13.7rem !important;
  margin-bottom: 13.7rem !important; }

.my-136 {
  margin-top: 13.6rem !important;
  margin-bottom: 13.6rem !important; }

.my-135 {
  margin-top: 13.5rem !important;
  margin-bottom: 13.5rem !important; }

.my-134 {
  margin-top: 13.4rem !important;
  margin-bottom: 13.4rem !important; }

.my-133 {
  margin-top: 13.3rem !important;
  margin-bottom: 13.3rem !important; }

.my-132 {
  margin-top: 13.2rem !important;
  margin-bottom: 13.2rem !important; }

.my-131 {
  margin-top: 13.1rem !important;
  margin-bottom: 13.1rem !important; }

.my-130 {
  margin-top: 13rem !important;
  margin-bottom: 13rem !important; }

.my-129 {
  margin-top: 12.9rem !important;
  margin-bottom: 12.9rem !important; }

.my-128 {
  margin-top: 12.8rem !important;
  margin-bottom: 12.8rem !important; }

.my-127 {
  margin-top: 12.7rem !important;
  margin-bottom: 12.7rem !important; }

.my-126 {
  margin-top: 12.6rem !important;
  margin-bottom: 12.6rem !important; }

.my-125 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important; }

.my-124 {
  margin-top: 12.4rem !important;
  margin-bottom: 12.4rem !important; }

.my-123 {
  margin-top: 12.3rem !important;
  margin-bottom: 12.3rem !important; }

.my-122 {
  margin-top: 12.2rem !important;
  margin-bottom: 12.2rem !important; }

.my-121 {
  margin-top: 12.1rem !important;
  margin-bottom: 12.1rem !important; }

.my-120 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important; }

.my-119 {
  margin-top: 11.9rem !important;
  margin-bottom: 11.9rem !important; }

.my-118 {
  margin-top: 11.8rem !important;
  margin-bottom: 11.8rem !important; }

.my-117 {
  margin-top: 11.7rem !important;
  margin-bottom: 11.7rem !important; }

.my-116 {
  margin-top: 11.6rem !important;
  margin-bottom: 11.6rem !important; }

.my-115 {
  margin-top: 11.5rem !important;
  margin-bottom: 11.5rem !important; }

.my-114 {
  margin-top: 11.4rem !important;
  margin-bottom: 11.4rem !important; }

.my-113 {
  margin-top: 11.3rem !important;
  margin-bottom: 11.3rem !important; }

.my-112 {
  margin-top: 11.2rem !important;
  margin-bottom: 11.2rem !important; }

.my-111 {
  margin-top: 11.1rem !important;
  margin-bottom: 11.1rem !important; }

.my-110 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important; }

.my-109 {
  margin-top: 10.9rem !important;
  margin-bottom: 10.9rem !important; }

.my-108 {
  margin-top: 10.8rem !important;
  margin-bottom: 10.8rem !important; }

.my-107 {
  margin-top: 10.7rem !important;
  margin-bottom: 10.7rem !important; }

.my-106 {
  margin-top: 10.6rem !important;
  margin-bottom: 10.6rem !important; }

.my-105 {
  margin-top: 10.5rem !important;
  margin-bottom: 10.5rem !important; }

.my-104 {
  margin-top: 10.4rem !important;
  margin-bottom: 10.4rem !important; }

.my-103 {
  margin-top: 10.3rem !important;
  margin-bottom: 10.3rem !important; }

.my-102 {
  margin-top: 10.2rem !important;
  margin-bottom: 10.2rem !important; }

.my-101 {
  margin-top: 10.1rem !important;
  margin-bottom: 10.1rem !important; }

.my-100 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important; }

.my-99 {
  margin-top: 9.9rem !important;
  margin-bottom: 9.9rem !important; }

.my-98 {
  margin-top: 9.8rem !important;
  margin-bottom: 9.8rem !important; }

.my-97 {
  margin-top: 9.7rem !important;
  margin-bottom: 9.7rem !important; }

.my-96 {
  margin-top: 9.6rem !important;
  margin-bottom: 9.6rem !important; }

.my-95 {
  margin-top: 9.5rem !important;
  margin-bottom: 9.5rem !important; }

.my-94 {
  margin-top: 9.4rem !important;
  margin-bottom: 9.4rem !important; }

.my-93 {
  margin-top: 9.3rem !important;
  margin-bottom: 9.3rem !important; }

.my-92 {
  margin-top: 9.2rem !important;
  margin-bottom: 9.2rem !important; }

.my-91 {
  margin-top: 9.1rem !important;
  margin-bottom: 9.1rem !important; }

.my-90 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important; }

.my-89 {
  margin-top: 8.9rem !important;
  margin-bottom: 8.9rem !important; }

.my-88 {
  margin-top: 8.8rem !important;
  margin-bottom: 8.8rem !important; }

.my-87 {
  margin-top: 8.7rem !important;
  margin-bottom: 8.7rem !important; }

.my-86 {
  margin-top: 8.6rem !important;
  margin-bottom: 8.6rem !important; }

.my-85 {
  margin-top: 8.5rem !important;
  margin-bottom: 8.5rem !important; }

.my-84 {
  margin-top: 8.4rem !important;
  margin-bottom: 8.4rem !important; }

.my-83 {
  margin-top: 8.3rem !important;
  margin-bottom: 8.3rem !important; }

.my-82 {
  margin-top: 8.2rem !important;
  margin-bottom: 8.2rem !important; }

.my-81 {
  margin-top: 8.1rem !important;
  margin-bottom: 8.1rem !important; }

.my-80 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important; }

.my-79 {
  margin-top: 7.9rem !important;
  margin-bottom: 7.9rem !important; }

.my-78 {
  margin-top: 7.8rem !important;
  margin-bottom: 7.8rem !important; }

.my-77 {
  margin-top: 7.7rem !important;
  margin-bottom: 7.7rem !important; }

.my-76 {
  margin-top: 7.6rem !important;
  margin-bottom: 7.6rem !important; }

.my-75 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important; }

.my-74 {
  margin-top: 7.4rem !important;
  margin-bottom: 7.4rem !important; }

.my-73 {
  margin-top: 7.3rem !important;
  margin-bottom: 7.3rem !important; }

.my-72 {
  margin-top: 7.2rem !important;
  margin-bottom: 7.2rem !important; }

.my-71 {
  margin-top: 7.1rem !important;
  margin-bottom: 7.1rem !important; }

.my-70 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important; }

.my-69 {
  margin-top: 6.9rem !important;
  margin-bottom: 6.9rem !important; }

.my-68 {
  margin-top: 6.8rem !important;
  margin-bottom: 6.8rem !important; }

.my-67 {
  margin-top: 6.7rem !important;
  margin-bottom: 6.7rem !important; }

.my-66 {
  margin-top: 6.6rem !important;
  margin-bottom: 6.6rem !important; }

.my-65 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important; }

.my-64 {
  margin-top: 6.4rem !important;
  margin-bottom: 6.4rem !important; }

.my-63 {
  margin-top: 6.3rem !important;
  margin-bottom: 6.3rem !important; }

.my-62 {
  margin-top: 6.2rem !important;
  margin-bottom: 6.2rem !important; }

.my-61 {
  margin-top: 6.1rem !important;
  margin-bottom: 6.1rem !important; }

.my-60 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-59 {
  margin-top: 5.9rem !important;
  margin-bottom: 5.9rem !important; }

.my-58 {
  margin-top: 5.8rem !important;
  margin-bottom: 5.8rem !important; }

.my-57 {
  margin-top: 5.7rem !important;
  margin-bottom: 5.7rem !important; }

.my-56 {
  margin-top: 5.6rem !important;
  margin-bottom: 5.6rem !important; }

.my-55 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important; }

.my-54 {
  margin-top: 5.4rem !important;
  margin-bottom: 5.4rem !important; }

.my-53 {
  margin-top: 5.3rem !important;
  margin-bottom: 5.3rem !important; }

.my-52 {
  margin-top: 5.2rem !important;
  margin-bottom: 5.2rem !important; }

.my-51 {
  margin-top: 5.1rem !important;
  margin-bottom: 5.1rem !important; }

.my-50 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.my-49 {
  margin-top: 4.9rem !important;
  margin-bottom: 4.9rem !important; }

.my-48 {
  margin-top: 4.8rem !important;
  margin-bottom: 4.8rem !important; }

.my-47 {
  margin-top: 4.7rem !important;
  margin-bottom: 4.7rem !important; }

.my-46 {
  margin-top: 4.6rem !important;
  margin-bottom: 4.6rem !important; }

.my-45 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important; }

.my-44 {
  margin-top: 4.4rem !important;
  margin-bottom: 4.4rem !important; }

.my-43 {
  margin-top: 4.3rem !important;
  margin-bottom: 4.3rem !important; }

.my-42 {
  margin-top: 4.2rem !important;
  margin-bottom: 4.2rem !important; }

.my-41 {
  margin-top: 4.1rem !important;
  margin-bottom: 4.1rem !important; }

.my-40 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.my-39 {
  margin-top: 3.9rem !important;
  margin-bottom: 3.9rem !important; }

.my-38 {
  margin-top: 3.8rem !important;
  margin-bottom: 3.8rem !important; }

.my-37 {
  margin-top: 3.7rem !important;
  margin-bottom: 3.7rem !important; }

.my-36 {
  margin-top: 3.6rem !important;
  margin-bottom: 3.6rem !important; }

.my-35 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important; }

.my-34 {
  margin-top: 3.4rem !important;
  margin-bottom: 3.4rem !important; }

.my-33 {
  margin-top: 3.3rem !important;
  margin-bottom: 3.3rem !important; }

.my-32 {
  margin-top: 3.2rem !important;
  margin-bottom: 3.2rem !important; }

.my-31 {
  margin-top: 3.1rem !important;
  margin-bottom: 3.1rem !important; }

.my-30 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-29 {
  margin-top: 2.9rem !important;
  margin-bottom: 2.9rem !important; }

.my-28 {
  margin-top: 2.8rem !important;
  margin-bottom: 2.8rem !important; }

.my-27 {
  margin-top: 2.7rem !important;
  margin-bottom: 2.7rem !important; }

.my-26 {
  margin-top: 2.6rem !important;
  margin-bottom: 2.6rem !important; }

.my-25 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.my-24 {
  margin-top: 2.4rem !important;
  margin-bottom: 2.4rem !important; }

.my-23 {
  margin-top: 2.3rem !important;
  margin-bottom: 2.3rem !important; }

.my-22 {
  margin-top: 2.2rem !important;
  margin-bottom: 2.2rem !important; }

.my-21 {
  margin-top: 2.1rem !important;
  margin-bottom: 2.1rem !important; }

.my-20 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.my-19 {
  margin-top: 1.9rem !important;
  margin-bottom: 1.9rem !important; }

.my-18 {
  margin-top: 1.8rem !important;
  margin-bottom: 1.8rem !important; }

.my-17 {
  margin-top: 1.7rem !important;
  margin-bottom: 1.7rem !important; }

.my-16 {
  margin-top: 1.6rem !important;
  margin-bottom: 1.6rem !important; }

.my-15 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-14 {
  margin-top: 1.4rem !important;
  margin-bottom: 1.4rem !important; }

.my-13 {
  margin-top: 1.3rem !important;
  margin-bottom: 1.3rem !important; }

.my-12 {
  margin-top: 1.2rem !important;
  margin-bottom: 1.2rem !important; }

.my-11 {
  margin-top: 1.1rem !important;
  margin-bottom: 1.1rem !important; }

.my-10 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-n80 {
  margin-top: -80px !important;
  margin-bottom: -80px !important; }

.my-n100 {
  margin-top: -100px !important;
  margin-bottom: -100px !important; }

.my-n150 {
  margin-top: -150px !important;
  margin-bottom: -150px !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-150 {
  margin-top: 15rem !important; }

.mt-149 {
  margin-top: 14.9rem !important; }

.mt-148 {
  margin-top: 14.8rem !important; }

.mt-147 {
  margin-top: 14.7rem !important; }

.mt-146 {
  margin-top: 14.6rem !important; }

.mt-145 {
  margin-top: 14.5rem !important; }

.mt-144 {
  margin-top: 14.4rem !important; }

.mt-143 {
  margin-top: 14.3rem !important; }

.mt-142 {
  margin-top: 14.2rem !important; }

.mt-141 {
  margin-top: 14.1rem !important; }

.mt-140 {
  margin-top: 14rem !important; }

.mt-139 {
  margin-top: 13.9rem !important; }

.mt-138 {
  margin-top: 13.8rem !important; }

.mt-137 {
  margin-top: 13.7rem !important; }

.mt-136 {
  margin-top: 13.6rem !important; }

.mt-135 {
  margin-top: 13.5rem !important; }

.mt-134 {
  margin-top: 13.4rem !important; }

.mt-133 {
  margin-top: 13.3rem !important; }

.mt-132 {
  margin-top: 13.2rem !important; }

.mt-131 {
  margin-top: 13.1rem !important; }

.mt-130 {
  margin-top: 13rem !important; }

.mt-129 {
  margin-top: 12.9rem !important; }

.mt-128 {
  margin-top: 12.8rem !important; }

.mt-127 {
  margin-top: 12.7rem !important; }

.mt-126 {
  margin-top: 12.6rem !important; }

.mt-125 {
  margin-top: 12.5rem !important; }

.mt-124 {
  margin-top: 12.4rem !important; }

.mt-123 {
  margin-top: 12.3rem !important; }

.mt-122 {
  margin-top: 12.2rem !important; }

.mt-121 {
  margin-top: 12.1rem !important; }

.mt-120 {
  margin-top: 12rem !important; }

.mt-119 {
  margin-top: 11.9rem !important; }

.mt-118 {
  margin-top: 11.8rem !important; }

.mt-117 {
  margin-top: 11.7rem !important; }

.mt-116 {
  margin-top: 11.6rem !important; }

.mt-115 {
  margin-top: 11.5rem !important; }

.mt-114 {
  margin-top: 11.4rem !important; }

.mt-113 {
  margin-top: 11.3rem !important; }

.mt-112 {
  margin-top: 11.2rem !important; }

.mt-111 {
  margin-top: 11.1rem !important; }

.mt-110 {
  margin-top: 11rem !important; }

.mt-109 {
  margin-top: 10.9rem !important; }

.mt-108 {
  margin-top: 10.8rem !important; }

.mt-107 {
  margin-top: 10.7rem !important; }

.mt-106 {
  margin-top: 10.6rem !important; }

.mt-105 {
  margin-top: 10.5rem !important; }

.mt-104 {
  margin-top: 10.4rem !important; }

.mt-103 {
  margin-top: 10.3rem !important; }

.mt-102 {
  margin-top: 10.2rem !important; }

.mt-101 {
  margin-top: 10.1rem !important; }

.mt-100 {
  margin-top: 10rem !important; }

.mt-99 {
  margin-top: 9.9rem !important; }

.mt-98 {
  margin-top: 9.8rem !important; }

.mt-97 {
  margin-top: 9.7rem !important; }

.mt-96 {
  margin-top: 9.6rem !important; }

.mt-95 {
  margin-top: 9.5rem !important; }

.mt-94 {
  margin-top: 9.4rem !important; }

.mt-93 {
  margin-top: 9.3rem !important; }

.mt-92 {
  margin-top: 9.2rem !important; }

.mt-91 {
  margin-top: 9.1rem !important; }

.mt-90 {
  margin-top: 9rem !important; }

.mt-89 {
  margin-top: 8.9rem !important; }

.mt-88 {
  margin-top: 8.8rem !important; }

.mt-87 {
  margin-top: 8.7rem !important; }

.mt-86 {
  margin-top: 8.6rem !important; }

.mt-85 {
  margin-top: 8.5rem !important; }

.mt-84 {
  margin-top: 8.4rem !important; }

.mt-83 {
  margin-top: 8.3rem !important; }

.mt-82 {
  margin-top: 8.2rem !important; }

.mt-81 {
  margin-top: 8.1rem !important; }

.mt-80 {
  margin-top: 8rem !important; }

.mt-79 {
  margin-top: 7.9rem !important; }

.mt-78 {
  margin-top: 7.8rem !important; }

.mt-77 {
  margin-top: 7.7rem !important; }

.mt-76 {
  margin-top: 7.6rem !important; }

.mt-75 {
  margin-top: 7.5rem !important; }

.mt-74 {
  margin-top: 7.4rem !important; }

.mt-73 {
  margin-top: 7.3rem !important; }

.mt-72 {
  margin-top: 7.2rem !important; }

.mt-71 {
  margin-top: 7.1rem !important; }

.mt-70 {
  margin-top: 7rem !important; }

.mt-69 {
  margin-top: 6.9rem !important; }

.mt-68 {
  margin-top: 6.8rem !important; }

.mt-67 {
  margin-top: 6.7rem !important; }

.mt-66 {
  margin-top: 6.6rem !important; }

.mt-65 {
  margin-top: 6.5rem !important; }

.mt-64 {
  margin-top: 6.4rem !important; }

.mt-63 {
  margin-top: 6.3rem !important; }

.mt-62 {
  margin-top: 6.2rem !important; }

.mt-61 {
  margin-top: 6.1rem !important; }

.mt-60 {
  margin-top: 6rem !important; }

.mt-59 {
  margin-top: 5.9rem !important; }

.mt-58 {
  margin-top: 5.8rem !important; }

.mt-57 {
  margin-top: 5.7rem !important; }

.mt-56 {
  margin-top: 5.6rem !important; }

.mt-55 {
  margin-top: 5.5rem !important; }

.mt-54 {
  margin-top: 5.4rem !important; }

.mt-53 {
  margin-top: 5.3rem !important; }

.mt-52 {
  margin-top: 5.2rem !important; }

.mt-51 {
  margin-top: 5.1rem !important; }

.mt-50 {
  margin-top: 5rem !important; }

.mt-49 {
  margin-top: 4.9rem !important; }

.mt-48 {
  margin-top: 4.8rem !important; }

.mt-47 {
  margin-top: 4.7rem !important; }

.mt-46 {
  margin-top: 4.6rem !important; }

.mt-45 {
  margin-top: 4.5rem !important; }

.mt-44 {
  margin-top: 4.4rem !important; }

.mt-43 {
  margin-top: 4.3rem !important; }

.mt-42 {
  margin-top: 4.2rem !important; }

.mt-41 {
  margin-top: 4.1rem !important; }

.mt-40 {
  margin-top: 4rem !important; }

.mt-39 {
  margin-top: 3.9rem !important; }

.mt-38 {
  margin-top: 3.8rem !important; }

.mt-37 {
  margin-top: 3.7rem !important; }

.mt-36 {
  margin-top: 3.6rem !important; }

.mt-35 {
  margin-top: 3.5rem !important; }

.mt-34 {
  margin-top: 3.4rem !important; }

.mt-33 {
  margin-top: 3.3rem !important; }

.mt-32 {
  margin-top: 3.2rem !important; }

.mt-31 {
  margin-top: 3.1rem !important; }

.mt-30 {
  margin-top: 3rem !important; }

.mt-29 {
  margin-top: 2.9rem !important; }

.mt-28 {
  margin-top: 2.8rem !important; }

.mt-27 {
  margin-top: 2.7rem !important; }

.mt-26 {
  margin-top: 2.6rem !important; }

.mt-25 {
  margin-top: 2.5rem !important; }

.mt-24 {
  margin-top: 2.4rem !important; }

.mt-23 {
  margin-top: 2.3rem !important; }

.mt-22 {
  margin-top: 2.2rem !important; }

.mt-21 {
  margin-top: 2.1rem !important; }

.mt-20 {
  margin-top: 2rem !important; }

.mt-19 {
  margin-top: 1.9rem !important; }

.mt-18 {
  margin-top: 1.8rem !important; }

.mt-17 {
  margin-top: 1.7rem !important; }

.mt-16 {
  margin-top: 1.6rem !important; }

.mt-15 {
  margin-top: 1.5rem !important; }

.mt-14 {
  margin-top: 1.4rem !important; }

.mt-13 {
  margin-top: 1.3rem !important; }

.mt-12 {
  margin-top: 1.2rem !important; }

.mt-11 {
  margin-top: 1.1rem !important; }

.mt-10 {
  margin-top: 1rem !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-n80 {
  margin-top: -80px !important; }

.mt-n100 {
  margin-top: -100px !important; }

.mt-n150 {
  margin-top: -150px !important; }

.mt-auto {
  margin-top: auto !important; }

.me-150 {
  margin-right: 15rem !important; }

.me-149 {
  margin-right: 14.9rem !important; }

.me-148 {
  margin-right: 14.8rem !important; }

.me-147 {
  margin-right: 14.7rem !important; }

.me-146 {
  margin-right: 14.6rem !important; }

.me-145 {
  margin-right: 14.5rem !important; }

.me-144 {
  margin-right: 14.4rem !important; }

.me-143 {
  margin-right: 14.3rem !important; }

.me-142 {
  margin-right: 14.2rem !important; }

.me-141 {
  margin-right: 14.1rem !important; }

.me-140 {
  margin-right: 14rem !important; }

.me-139 {
  margin-right: 13.9rem !important; }

.me-138 {
  margin-right: 13.8rem !important; }

.me-137 {
  margin-right: 13.7rem !important; }

.me-136 {
  margin-right: 13.6rem !important; }

.me-135 {
  margin-right: 13.5rem !important; }

.me-134 {
  margin-right: 13.4rem !important; }

.me-133 {
  margin-right: 13.3rem !important; }

.me-132 {
  margin-right: 13.2rem !important; }

.me-131 {
  margin-right: 13.1rem !important; }

.me-130 {
  margin-right: 13rem !important; }

.me-129 {
  margin-right: 12.9rem !important; }

.me-128 {
  margin-right: 12.8rem !important; }

.me-127 {
  margin-right: 12.7rem !important; }

.me-126 {
  margin-right: 12.6rem !important; }

.me-125 {
  margin-right: 12.5rem !important; }

.me-124 {
  margin-right: 12.4rem !important; }

.me-123 {
  margin-right: 12.3rem !important; }

.me-122 {
  margin-right: 12.2rem !important; }

.me-121 {
  margin-right: 12.1rem !important; }

.me-120 {
  margin-right: 12rem !important; }

.me-119 {
  margin-right: 11.9rem !important; }

.me-118 {
  margin-right: 11.8rem !important; }

.me-117 {
  margin-right: 11.7rem !important; }

.me-116 {
  margin-right: 11.6rem !important; }

.me-115 {
  margin-right: 11.5rem !important; }

.me-114 {
  margin-right: 11.4rem !important; }

.me-113 {
  margin-right: 11.3rem !important; }

.me-112 {
  margin-right: 11.2rem !important; }

.me-111 {
  margin-right: 11.1rem !important; }

.me-110 {
  margin-right: 11rem !important; }

.me-109 {
  margin-right: 10.9rem !important; }

.me-108 {
  margin-right: 10.8rem !important; }

.me-107 {
  margin-right: 10.7rem !important; }

.me-106 {
  margin-right: 10.6rem !important; }

.me-105 {
  margin-right: 10.5rem !important; }

.me-104 {
  margin-right: 10.4rem !important; }

.me-103 {
  margin-right: 10.3rem !important; }

.me-102 {
  margin-right: 10.2rem !important; }

.me-101 {
  margin-right: 10.1rem !important; }

.me-100 {
  margin-right: 10rem !important; }

.me-99 {
  margin-right: 9.9rem !important; }

.me-98 {
  margin-right: 9.8rem !important; }

.me-97 {
  margin-right: 9.7rem !important; }

.me-96 {
  margin-right: 9.6rem !important; }

.me-95 {
  margin-right: 9.5rem !important; }

.me-94 {
  margin-right: 9.4rem !important; }

.me-93 {
  margin-right: 9.3rem !important; }

.me-92 {
  margin-right: 9.2rem !important; }

.me-91 {
  margin-right: 9.1rem !important; }

.me-90 {
  margin-right: 9rem !important; }

.me-89 {
  margin-right: 8.9rem !important; }

.me-88 {
  margin-right: 8.8rem !important; }

.me-87 {
  margin-right: 8.7rem !important; }

.me-86 {
  margin-right: 8.6rem !important; }

.me-85 {
  margin-right: 8.5rem !important; }

.me-84 {
  margin-right: 8.4rem !important; }

.me-83 {
  margin-right: 8.3rem !important; }

.me-82 {
  margin-right: 8.2rem !important; }

.me-81 {
  margin-right: 8.1rem !important; }

.me-80 {
  margin-right: 8rem !important; }

.me-79 {
  margin-right: 7.9rem !important; }

.me-78 {
  margin-right: 7.8rem !important; }

.me-77 {
  margin-right: 7.7rem !important; }

.me-76 {
  margin-right: 7.6rem !important; }

.me-75 {
  margin-right: 7.5rem !important; }

.me-74 {
  margin-right: 7.4rem !important; }

.me-73 {
  margin-right: 7.3rem !important; }

.me-72 {
  margin-right: 7.2rem !important; }

.me-71 {
  margin-right: 7.1rem !important; }

.me-70 {
  margin-right: 7rem !important; }

.me-69 {
  margin-right: 6.9rem !important; }

.me-68 {
  margin-right: 6.8rem !important; }

.me-67 {
  margin-right: 6.7rem !important; }

.me-66 {
  margin-right: 6.6rem !important; }

.me-65 {
  margin-right: 6.5rem !important; }

.me-64 {
  margin-right: 6.4rem !important; }

.me-63 {
  margin-right: 6.3rem !important; }

.me-62 {
  margin-right: 6.2rem !important; }

.me-61 {
  margin-right: 6.1rem !important; }

.me-60 {
  margin-right: 6rem !important; }

.me-59 {
  margin-right: 5.9rem !important; }

.me-58 {
  margin-right: 5.8rem !important; }

.me-57 {
  margin-right: 5.7rem !important; }

.me-56 {
  margin-right: 5.6rem !important; }

.me-55 {
  margin-right: 5.5rem !important; }

.me-54 {
  margin-right: 5.4rem !important; }

.me-53 {
  margin-right: 5.3rem !important; }

.me-52 {
  margin-right: 5.2rem !important; }

.me-51 {
  margin-right: 5.1rem !important; }

.me-50 {
  margin-right: 5rem !important; }

.me-49 {
  margin-right: 4.9rem !important; }

.me-48 {
  margin-right: 4.8rem !important; }

.me-47 {
  margin-right: 4.7rem !important; }

.me-46 {
  margin-right: 4.6rem !important; }

.me-45 {
  margin-right: 4.5rem !important; }

.me-44 {
  margin-right: 4.4rem !important; }

.me-43 {
  margin-right: 4.3rem !important; }

.me-42 {
  margin-right: 4.2rem !important; }

.me-41 {
  margin-right: 4.1rem !important; }

.me-40 {
  margin-right: 4rem !important; }

.me-39 {
  margin-right: 3.9rem !important; }

.me-38 {
  margin-right: 3.8rem !important; }

.me-37 {
  margin-right: 3.7rem !important; }

.me-36 {
  margin-right: 3.6rem !important; }

.me-35 {
  margin-right: 3.5rem !important; }

.me-34 {
  margin-right: 3.4rem !important; }

.me-33 {
  margin-right: 3.3rem !important; }

.me-32 {
  margin-right: 3.2rem !important; }

.me-31 {
  margin-right: 3.1rem !important; }

.me-30 {
  margin-right: 3rem !important; }

.me-29 {
  margin-right: 2.9rem !important; }

.me-28 {
  margin-right: 2.8rem !important; }

.me-27 {
  margin-right: 2.7rem !important; }

.me-26 {
  margin-right: 2.6rem !important; }

.me-25 {
  margin-right: 2.5rem !important; }

.me-24 {
  margin-right: 2.4rem !important; }

.me-23 {
  margin-right: 2.3rem !important; }

.me-22 {
  margin-right: 2.2rem !important; }

.me-21 {
  margin-right: 2.1rem !important; }

.me-20 {
  margin-right: 2rem !important; }

.me-19 {
  margin-right: 1.9rem !important; }

.me-18 {
  margin-right: 1.8rem !important; }

.me-17 {
  margin-right: 1.7rem !important; }

.me-16 {
  margin-right: 1.6rem !important; }

.me-15 {
  margin-right: 1.5rem !important; }

.me-14 {
  margin-right: 1.4rem !important; }

.me-13 {
  margin-right: 1.3rem !important; }

.me-12 {
  margin-right: 1.2rem !important; }

.me-11 {
  margin-right: 1.1rem !important; }

.me-10 {
  margin-right: 1rem !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-n80 {
  margin-right: -80px !important; }

.me-n100 {
  margin-right: -100px !important; }

.me-n150 {
  margin-right: -150px !important; }

.me-auto {
  margin-right: auto !important; }

.mb-150 {
  margin-bottom: 15rem !important; }

.mb-149 {
  margin-bottom: 14.9rem !important; }

.mb-148 {
  margin-bottom: 14.8rem !important; }

.mb-147 {
  margin-bottom: 14.7rem !important; }

.mb-146 {
  margin-bottom: 14.6rem !important; }

.mb-145 {
  margin-bottom: 14.5rem !important; }

.mb-144 {
  margin-bottom: 14.4rem !important; }

.mb-143 {
  margin-bottom: 14.3rem !important; }

.mb-142 {
  margin-bottom: 14.2rem !important; }

.mb-141 {
  margin-bottom: 14.1rem !important; }

.mb-140 {
  margin-bottom: 14rem !important; }

.mb-139 {
  margin-bottom: 13.9rem !important; }

.mb-138 {
  margin-bottom: 13.8rem !important; }

.mb-137 {
  margin-bottom: 13.7rem !important; }

.mb-136 {
  margin-bottom: 13.6rem !important; }

.mb-135 {
  margin-bottom: 13.5rem !important; }

.mb-134 {
  margin-bottom: 13.4rem !important; }

.mb-133 {
  margin-bottom: 13.3rem !important; }

.mb-132 {
  margin-bottom: 13.2rem !important; }

.mb-131 {
  margin-bottom: 13.1rem !important; }

.mb-130 {
  margin-bottom: 13rem !important; }

.mb-129 {
  margin-bottom: 12.9rem !important; }

.mb-128 {
  margin-bottom: 12.8rem !important; }

.mb-127 {
  margin-bottom: 12.7rem !important; }

.mb-126 {
  margin-bottom: 12.6rem !important; }

.mb-125 {
  margin-bottom: 12.5rem !important; }

.mb-124 {
  margin-bottom: 12.4rem !important; }

.mb-123 {
  margin-bottom: 12.3rem !important; }

.mb-122 {
  margin-bottom: 12.2rem !important; }

.mb-121 {
  margin-bottom: 12.1rem !important; }

.mb-120 {
  margin-bottom: 12rem !important; }

.mb-119 {
  margin-bottom: 11.9rem !important; }

.mb-118 {
  margin-bottom: 11.8rem !important; }

.mb-117 {
  margin-bottom: 11.7rem !important; }

.mb-116 {
  margin-bottom: 11.6rem !important; }

.mb-115 {
  margin-bottom: 11.5rem !important; }

.mb-114 {
  margin-bottom: 11.4rem !important; }

.mb-113 {
  margin-bottom: 11.3rem !important; }

.mb-112 {
  margin-bottom: 11.2rem !important; }

.mb-111 {
  margin-bottom: 11.1rem !important; }

.mb-110 {
  margin-bottom: 11rem !important; }

.mb-109 {
  margin-bottom: 10.9rem !important; }

.mb-108 {
  margin-bottom: 10.8rem !important; }

.mb-107 {
  margin-bottom: 10.7rem !important; }

.mb-106 {
  margin-bottom: 10.6rem !important; }

.mb-105 {
  margin-bottom: 10.5rem !important; }

.mb-104 {
  margin-bottom: 10.4rem !important; }

.mb-103 {
  margin-bottom: 10.3rem !important; }

.mb-102 {
  margin-bottom: 10.2rem !important; }

.mb-101 {
  margin-bottom: 10.1rem !important; }

.mb-100 {
  margin-bottom: 10rem !important; }

.mb-99 {
  margin-bottom: 9.9rem !important; }

.mb-98 {
  margin-bottom: 9.8rem !important; }

.mb-97 {
  margin-bottom: 9.7rem !important; }

.mb-96 {
  margin-bottom: 9.6rem !important; }

.mb-95 {
  margin-bottom: 9.5rem !important; }

.mb-94 {
  margin-bottom: 9.4rem !important; }

.mb-93 {
  margin-bottom: 9.3rem !important; }

.mb-92 {
  margin-bottom: 9.2rem !important; }

.mb-91 {
  margin-bottom: 9.1rem !important; }

.mb-90 {
  margin-bottom: 9rem !important; }

.mb-89 {
  margin-bottom: 8.9rem !important; }

.mb-88 {
  margin-bottom: 8.8rem !important; }

.mb-87 {
  margin-bottom: 8.7rem !important; }

.mb-86 {
  margin-bottom: 8.6rem !important; }

.mb-85 {
  margin-bottom: 8.5rem !important; }

.mb-84 {
  margin-bottom: 8.4rem !important; }

.mb-83 {
  margin-bottom: 8.3rem !important; }

.mb-82 {
  margin-bottom: 8.2rem !important; }

.mb-81 {
  margin-bottom: 8.1rem !important; }

.mb-80 {
  margin-bottom: 8rem !important; }

.mb-79 {
  margin-bottom: 7.9rem !important; }

.mb-78 {
  margin-bottom: 7.8rem !important; }

.mb-77 {
  margin-bottom: 7.7rem !important; }

.mb-76 {
  margin-bottom: 7.6rem !important; }

.mb-75 {
  margin-bottom: 7.5rem !important; }

.mb-74 {
  margin-bottom: 7.4rem !important; }

.mb-73 {
  margin-bottom: 7.3rem !important; }

.mb-72 {
  margin-bottom: 7.2rem !important; }

.mb-71 {
  margin-bottom: 7.1rem !important; }

.mb-70 {
  margin-bottom: 7rem !important; }

.mb-69 {
  margin-bottom: 6.9rem !important; }

.mb-68 {
  margin-bottom: 6.8rem !important; }

.mb-67 {
  margin-bottom: 6.7rem !important; }

.mb-66 {
  margin-bottom: 6.6rem !important; }

.mb-65 {
  margin-bottom: 6.5rem !important; }

.mb-64 {
  margin-bottom: 6.4rem !important; }

.mb-63 {
  margin-bottom: 6.3rem !important; }

.mb-62 {
  margin-bottom: 6.2rem !important; }

.mb-61 {
  margin-bottom: 6.1rem !important; }

.mb-60 {
  margin-bottom: 6rem !important; }

.mb-59 {
  margin-bottom: 5.9rem !important; }

.mb-58 {
  margin-bottom: 5.8rem !important; }

.mb-57 {
  margin-bottom: 5.7rem !important; }

.mb-56 {
  margin-bottom: 5.6rem !important; }

.mb-55 {
  margin-bottom: 5.5rem !important; }

.mb-54 {
  margin-bottom: 5.4rem !important; }

.mb-53 {
  margin-bottom: 5.3rem !important; }

.mb-52 {
  margin-bottom: 5.2rem !important; }

.mb-51 {
  margin-bottom: 5.1rem !important; }

.mb-50 {
  margin-bottom: 5rem !important; }

.mb-49 {
  margin-bottom: 4.9rem !important; }

.mb-48 {
  margin-bottom: 4.8rem !important; }

.mb-47 {
  margin-bottom: 4.7rem !important; }

.mb-46 {
  margin-bottom: 4.6rem !important; }

.mb-45 {
  margin-bottom: 4.5rem !important; }

.mb-44 {
  margin-bottom: 4.4rem !important; }

.mb-43 {
  margin-bottom: 4.3rem !important; }

.mb-42 {
  margin-bottom: 4.2rem !important; }

.mb-41 {
  margin-bottom: 4.1rem !important; }

.mb-40 {
  margin-bottom: 4rem !important; }

.mb-39 {
  margin-bottom: 3.9rem !important; }

.mb-38 {
  margin-bottom: 3.8rem !important; }

.mb-37 {
  margin-bottom: 3.7rem !important; }

.mb-36 {
  margin-bottom: 3.6rem !important; }

.mb-35 {
  margin-bottom: 3.5rem !important; }

.mb-34 {
  margin-bottom: 3.4rem !important; }

.mb-33 {
  margin-bottom: 3.3rem !important; }

.mb-32 {
  margin-bottom: 3.2rem !important; }

.mb-31 {
  margin-bottom: 3.1rem !important; }

.mb-30 {
  margin-bottom: 3rem !important; }

.mb-29 {
  margin-bottom: 2.9rem !important; }

.mb-28 {
  margin-bottom: 2.8rem !important; }

.mb-27 {
  margin-bottom: 2.7rem !important; }

.mb-26 {
  margin-bottom: 2.6rem !important; }

.mb-25 {
  margin-bottom: 2.5rem !important; }

.mb-24 {
  margin-bottom: 2.4rem !important; }

.mb-23 {
  margin-bottom: 2.3rem !important; }

.mb-22 {
  margin-bottom: 2.2rem !important; }

.mb-21 {
  margin-bottom: 2.1rem !important; }

.mb-20 {
  margin-bottom: 2rem !important; }

.mb-19 {
  margin-bottom: 1.9rem !important; }

.mb-18 {
  margin-bottom: 1.8rem !important; }

.mb-17 {
  margin-bottom: 1.7rem !important; }

.mb-16 {
  margin-bottom: 1.6rem !important; }

.mb-15 {
  margin-bottom: 1.5rem !important; }

.mb-14 {
  margin-bottom: 1.4rem !important; }

.mb-13 {
  margin-bottom: 1.3rem !important; }

.mb-12 {
  margin-bottom: 1.2rem !important; }

.mb-11 {
  margin-bottom: 1.1rem !important; }

.mb-10 {
  margin-bottom: 1rem !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-n80 {
  margin-bottom: -80px !important; }

.mb-n100 {
  margin-bottom: -100px !important; }

.mb-n150 {
  margin-bottom: -150px !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-150 {
  margin-left: 15rem !important; }

.ms-149 {
  margin-left: 14.9rem !important; }

.ms-148 {
  margin-left: 14.8rem !important; }

.ms-147 {
  margin-left: 14.7rem !important; }

.ms-146 {
  margin-left: 14.6rem !important; }

.ms-145 {
  margin-left: 14.5rem !important; }

.ms-144 {
  margin-left: 14.4rem !important; }

.ms-143 {
  margin-left: 14.3rem !important; }

.ms-142 {
  margin-left: 14.2rem !important; }

.ms-141 {
  margin-left: 14.1rem !important; }

.ms-140 {
  margin-left: 14rem !important; }

.ms-139 {
  margin-left: 13.9rem !important; }

.ms-138 {
  margin-left: 13.8rem !important; }

.ms-137 {
  margin-left: 13.7rem !important; }

.ms-136 {
  margin-left: 13.6rem !important; }

.ms-135 {
  margin-left: 13.5rem !important; }

.ms-134 {
  margin-left: 13.4rem !important; }

.ms-133 {
  margin-left: 13.3rem !important; }

.ms-132 {
  margin-left: 13.2rem !important; }

.ms-131 {
  margin-left: 13.1rem !important; }

.ms-130 {
  margin-left: 13rem !important; }

.ms-129 {
  margin-left: 12.9rem !important; }

.ms-128 {
  margin-left: 12.8rem !important; }

.ms-127 {
  margin-left: 12.7rem !important; }

.ms-126 {
  margin-left: 12.6rem !important; }

.ms-125 {
  margin-left: 12.5rem !important; }

.ms-124 {
  margin-left: 12.4rem !important; }

.ms-123 {
  margin-left: 12.3rem !important; }

.ms-122 {
  margin-left: 12.2rem !important; }

.ms-121 {
  margin-left: 12.1rem !important; }

.ms-120 {
  margin-left: 12rem !important; }

.ms-119 {
  margin-left: 11.9rem !important; }

.ms-118 {
  margin-left: 11.8rem !important; }

.ms-117 {
  margin-left: 11.7rem !important; }

.ms-116 {
  margin-left: 11.6rem !important; }

.ms-115 {
  margin-left: 11.5rem !important; }

.ms-114 {
  margin-left: 11.4rem !important; }

.ms-113 {
  margin-left: 11.3rem !important; }

.ms-112 {
  margin-left: 11.2rem !important; }

.ms-111 {
  margin-left: 11.1rem !important; }

.ms-110 {
  margin-left: 11rem !important; }

.ms-109 {
  margin-left: 10.9rem !important; }

.ms-108 {
  margin-left: 10.8rem !important; }

.ms-107 {
  margin-left: 10.7rem !important; }

.ms-106 {
  margin-left: 10.6rem !important; }

.ms-105 {
  margin-left: 10.5rem !important; }

.ms-104 {
  margin-left: 10.4rem !important; }

.ms-103 {
  margin-left: 10.3rem !important; }

.ms-102 {
  margin-left: 10.2rem !important; }

.ms-101 {
  margin-left: 10.1rem !important; }

.ms-100 {
  margin-left: 10rem !important; }

.ms-99 {
  margin-left: 9.9rem !important; }

.ms-98 {
  margin-left: 9.8rem !important; }

.ms-97 {
  margin-left: 9.7rem !important; }

.ms-96 {
  margin-left: 9.6rem !important; }

.ms-95 {
  margin-left: 9.5rem !important; }

.ms-94 {
  margin-left: 9.4rem !important; }

.ms-93 {
  margin-left: 9.3rem !important; }

.ms-92 {
  margin-left: 9.2rem !important; }

.ms-91 {
  margin-left: 9.1rem !important; }

.ms-90 {
  margin-left: 9rem !important; }

.ms-89 {
  margin-left: 8.9rem !important; }

.ms-88 {
  margin-left: 8.8rem !important; }

.ms-87 {
  margin-left: 8.7rem !important; }

.ms-86 {
  margin-left: 8.6rem !important; }

.ms-85 {
  margin-left: 8.5rem !important; }

.ms-84 {
  margin-left: 8.4rem !important; }

.ms-83 {
  margin-left: 8.3rem !important; }

.ms-82 {
  margin-left: 8.2rem !important; }

.ms-81 {
  margin-left: 8.1rem !important; }

.ms-80 {
  margin-left: 8rem !important; }

.ms-79 {
  margin-left: 7.9rem !important; }

.ms-78 {
  margin-left: 7.8rem !important; }

.ms-77 {
  margin-left: 7.7rem !important; }

.ms-76 {
  margin-left: 7.6rem !important; }

.ms-75 {
  margin-left: 7.5rem !important; }

.ms-74 {
  margin-left: 7.4rem !important; }

.ms-73 {
  margin-left: 7.3rem !important; }

.ms-72 {
  margin-left: 7.2rem !important; }

.ms-71 {
  margin-left: 7.1rem !important; }

.ms-70 {
  margin-left: 7rem !important; }

.ms-69 {
  margin-left: 6.9rem !important; }

.ms-68 {
  margin-left: 6.8rem !important; }

.ms-67 {
  margin-left: 6.7rem !important; }

.ms-66 {
  margin-left: 6.6rem !important; }

.ms-65 {
  margin-left: 6.5rem !important; }

.ms-64 {
  margin-left: 6.4rem !important; }

.ms-63 {
  margin-left: 6.3rem !important; }

.ms-62 {
  margin-left: 6.2rem !important; }

.ms-61 {
  margin-left: 6.1rem !important; }

.ms-60 {
  margin-left: 6rem !important; }

.ms-59 {
  margin-left: 5.9rem !important; }

.ms-58 {
  margin-left: 5.8rem !important; }

.ms-57 {
  margin-left: 5.7rem !important; }

.ms-56 {
  margin-left: 5.6rem !important; }

.ms-55 {
  margin-left: 5.5rem !important; }

.ms-54 {
  margin-left: 5.4rem !important; }

.ms-53 {
  margin-left: 5.3rem !important; }

.ms-52 {
  margin-left: 5.2rem !important; }

.ms-51 {
  margin-left: 5.1rem !important; }

.ms-50 {
  margin-left: 5rem !important; }

.ms-49 {
  margin-left: 4.9rem !important; }

.ms-48 {
  margin-left: 4.8rem !important; }

.ms-47 {
  margin-left: 4.7rem !important; }

.ms-46 {
  margin-left: 4.6rem !important; }

.ms-45 {
  margin-left: 4.5rem !important; }

.ms-44 {
  margin-left: 4.4rem !important; }

.ms-43 {
  margin-left: 4.3rem !important; }

.ms-42 {
  margin-left: 4.2rem !important; }

.ms-41 {
  margin-left: 4.1rem !important; }

.ms-40 {
  margin-left: 4rem !important; }

.ms-39 {
  margin-left: 3.9rem !important; }

.ms-38 {
  margin-left: 3.8rem !important; }

.ms-37 {
  margin-left: 3.7rem !important; }

.ms-36 {
  margin-left: 3.6rem !important; }

.ms-35 {
  margin-left: 3.5rem !important; }

.ms-34 {
  margin-left: 3.4rem !important; }

.ms-33 {
  margin-left: 3.3rem !important; }

.ms-32 {
  margin-left: 3.2rem !important; }

.ms-31 {
  margin-left: 3.1rem !important; }

.ms-30 {
  margin-left: 3rem !important; }

.ms-29 {
  margin-left: 2.9rem !important; }

.ms-28 {
  margin-left: 2.8rem !important; }

.ms-27 {
  margin-left: 2.7rem !important; }

.ms-26 {
  margin-left: 2.6rem !important; }

.ms-25 {
  margin-left: 2.5rem !important; }

.ms-24 {
  margin-left: 2.4rem !important; }

.ms-23 {
  margin-left: 2.3rem !important; }

.ms-22 {
  margin-left: 2.2rem !important; }

.ms-21 {
  margin-left: 2.1rem !important; }

.ms-20 {
  margin-left: 2rem !important; }

.ms-19 {
  margin-left: 1.9rem !important; }

.ms-18 {
  margin-left: 1.8rem !important; }

.ms-17 {
  margin-left: 1.7rem !important; }

.ms-16 {
  margin-left: 1.6rem !important; }

.ms-15 {
  margin-left: 1.5rem !important; }

.ms-14 {
  margin-left: 1.4rem !important; }

.ms-13 {
  margin-left: 1.3rem !important; }

.ms-12 {
  margin-left: 1.2rem !important; }

.ms-11 {
  margin-left: 1.1rem !important; }

.ms-10 {
  margin-left: 1rem !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-n80 {
  margin-left: -80px !important; }

.ms-n100 {
  margin-left: -100px !important; }

.ms-n150 {
  margin-left: -150px !important; }

.ms-auto {
  margin-left: auto !important; }

.p-150 {
  padding: 15rem !important; }

.p-149 {
  padding: 14.9rem !important; }

.p-148 {
  padding: 14.8rem !important; }

.p-147 {
  padding: 14.7rem !important; }

.p-146 {
  padding: 14.6rem !important; }

.p-145 {
  padding: 14.5rem !important; }

.p-144 {
  padding: 14.4rem !important; }

.p-143 {
  padding: 14.3rem !important; }

.p-142 {
  padding: 14.2rem !important; }

.p-141 {
  padding: 14.1rem !important; }

.p-140 {
  padding: 14rem !important; }

.p-139 {
  padding: 13.9rem !important; }

.p-138 {
  padding: 13.8rem !important; }

.p-137 {
  padding: 13.7rem !important; }

.p-136 {
  padding: 13.6rem !important; }

.p-135 {
  padding: 13.5rem !important; }

.p-134 {
  padding: 13.4rem !important; }

.p-133 {
  padding: 13.3rem !important; }

.p-132 {
  padding: 13.2rem !important; }

.p-131 {
  padding: 13.1rem !important; }

.p-130 {
  padding: 13rem !important; }

.p-129 {
  padding: 12.9rem !important; }

.p-128 {
  padding: 12.8rem !important; }

.p-127 {
  padding: 12.7rem !important; }

.p-126 {
  padding: 12.6rem !important; }

.p-125 {
  padding: 12.5rem !important; }

.p-124 {
  padding: 12.4rem !important; }

.p-123 {
  padding: 12.3rem !important; }

.p-122 {
  padding: 12.2rem !important; }

.p-121 {
  padding: 12.1rem !important; }

.p-120 {
  padding: 12rem !important; }

.p-119 {
  padding: 11.9rem !important; }

.p-118 {
  padding: 11.8rem !important; }

.p-117 {
  padding: 11.7rem !important; }

.p-116 {
  padding: 11.6rem !important; }

.p-115 {
  padding: 11.5rem !important; }

.p-114 {
  padding: 11.4rem !important; }

.p-113 {
  padding: 11.3rem !important; }

.p-112 {
  padding: 11.2rem !important; }

.p-111 {
  padding: 11.1rem !important; }

.p-110 {
  padding: 11rem !important; }

.p-109 {
  padding: 10.9rem !important; }

.p-108 {
  padding: 10.8rem !important; }

.p-107 {
  padding: 10.7rem !important; }

.p-106 {
  padding: 10.6rem !important; }

.p-105 {
  padding: 10.5rem !important; }

.p-104 {
  padding: 10.4rem !important; }

.p-103 {
  padding: 10.3rem !important; }

.p-102 {
  padding: 10.2rem !important; }

.p-101 {
  padding: 10.1rem !important; }

.p-100 {
  padding: 10rem !important; }

.p-99 {
  padding: 9.9rem !important; }

.p-98 {
  padding: 9.8rem !important; }

.p-97 {
  padding: 9.7rem !important; }

.p-96 {
  padding: 9.6rem !important; }

.p-95 {
  padding: 9.5rem !important; }

.p-94 {
  padding: 9.4rem !important; }

.p-93 {
  padding: 9.3rem !important; }

.p-92 {
  padding: 9.2rem !important; }

.p-91 {
  padding: 9.1rem !important; }

.p-90 {
  padding: 9rem !important; }

.p-89 {
  padding: 8.9rem !important; }

.p-88 {
  padding: 8.8rem !important; }

.p-87 {
  padding: 8.7rem !important; }

.p-86 {
  padding: 8.6rem !important; }

.p-85 {
  padding: 8.5rem !important; }

.p-84 {
  padding: 8.4rem !important; }

.p-83 {
  padding: 8.3rem !important; }

.p-82 {
  padding: 8.2rem !important; }

.p-81 {
  padding: 8.1rem !important; }

.p-80 {
  padding: 8rem !important; }

.p-79 {
  padding: 7.9rem !important; }

.p-78 {
  padding: 7.8rem !important; }

.p-77 {
  padding: 7.7rem !important; }

.p-76 {
  padding: 7.6rem !important; }

.p-75 {
  padding: 7.5rem !important; }

.p-74 {
  padding: 7.4rem !important; }

.p-73 {
  padding: 7.3rem !important; }

.p-72 {
  padding: 7.2rem !important; }

.p-71 {
  padding: 7.1rem !important; }

.p-70 {
  padding: 7rem !important; }

.p-69 {
  padding: 6.9rem !important; }

.p-68 {
  padding: 6.8rem !important; }

.p-67 {
  padding: 6.7rem !important; }

.p-66 {
  padding: 6.6rem !important; }

.p-65 {
  padding: 6.5rem !important; }

.p-64 {
  padding: 6.4rem !important; }

.p-63 {
  padding: 6.3rem !important; }

.p-62 {
  padding: 6.2rem !important; }

.p-61 {
  padding: 6.1rem !important; }

.p-60 {
  padding: 6rem !important; }

.p-59 {
  padding: 5.9rem !important; }

.p-58 {
  padding: 5.8rem !important; }

.p-57 {
  padding: 5.7rem !important; }

.p-56 {
  padding: 5.6rem !important; }

.p-55 {
  padding: 5.5rem !important; }

.p-54 {
  padding: 5.4rem !important; }

.p-53 {
  padding: 5.3rem !important; }

.p-52 {
  padding: 5.2rem !important; }

.p-51 {
  padding: 5.1rem !important; }

.p-50 {
  padding: 5rem !important; }

.p-49 {
  padding: 4.9rem !important; }

.p-48 {
  padding: 4.8rem !important; }

.p-47 {
  padding: 4.7rem !important; }

.p-46 {
  padding: 4.6rem !important; }

.p-45 {
  padding: 4.5rem !important; }

.p-44 {
  padding: 4.4rem !important; }

.p-43 {
  padding: 4.3rem !important; }

.p-42 {
  padding: 4.2rem !important; }

.p-41 {
  padding: 4.1rem !important; }

.p-40 {
  padding: 4rem !important; }

.p-39 {
  padding: 3.9rem !important; }

.p-38 {
  padding: 3.8rem !important; }

.p-37 {
  padding: 3.7rem !important; }

.p-36 {
  padding: 3.6rem !important; }

.p-35 {
  padding: 3.5rem !important; }

.p-34 {
  padding: 3.4rem !important; }

.p-33 {
  padding: 3.3rem !important; }

.p-32 {
  padding: 3.2rem !important; }

.p-31 {
  padding: 3.1rem !important; }

.p-30 {
  padding: 3rem !important; }

.p-29 {
  padding: 2.9rem !important; }

.p-28 {
  padding: 2.8rem !important; }

.p-27 {
  padding: 2.7rem !important; }

.p-26 {
  padding: 2.6rem !important; }

.p-25 {
  padding: 2.5rem !important; }

.p-24 {
  padding: 2.4rem !important; }

.p-23 {
  padding: 2.3rem !important; }

.p-22 {
  padding: 2.2rem !important; }

.p-21 {
  padding: 2.1rem !important; }

.p-20 {
  padding: 2rem !important; }

.p-19 {
  padding: 1.9rem !important; }

.p-18 {
  padding: 1.8rem !important; }

.p-17 {
  padding: 1.7rem !important; }

.p-16 {
  padding: 1.6rem !important; }

.p-15 {
  padding: 1.5rem !important; }

.p-14 {
  padding: 1.4rem !important; }

.p-13 {
  padding: 1.3rem !important; }

.p-12 {
  padding: 1.2rem !important; }

.p-11 {
  padding: 1.1rem !important; }

.p-10 {
  padding: 1rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.p-n80 {
  padding: -80px !important; }

.p-n100 {
  padding: -100px !important; }

.p-n150 {
  padding: -150px !important; }

.px-150 {
  padding-right: 15rem !important;
  padding-left: 15rem !important; }

.px-149 {
  padding-right: 14.9rem !important;
  padding-left: 14.9rem !important; }

.px-148 {
  padding-right: 14.8rem !important;
  padding-left: 14.8rem !important; }

.px-147 {
  padding-right: 14.7rem !important;
  padding-left: 14.7rem !important; }

.px-146 {
  padding-right: 14.6rem !important;
  padding-left: 14.6rem !important; }

.px-145 {
  padding-right: 14.5rem !important;
  padding-left: 14.5rem !important; }

.px-144 {
  padding-right: 14.4rem !important;
  padding-left: 14.4rem !important; }

.px-143 {
  padding-right: 14.3rem !important;
  padding-left: 14.3rem !important; }

.px-142 {
  padding-right: 14.2rem !important;
  padding-left: 14.2rem !important; }

.px-141 {
  padding-right: 14.1rem !important;
  padding-left: 14.1rem !important; }

.px-140 {
  padding-right: 14rem !important;
  padding-left: 14rem !important; }

.px-139 {
  padding-right: 13.9rem !important;
  padding-left: 13.9rem !important; }

.px-138 {
  padding-right: 13.8rem !important;
  padding-left: 13.8rem !important; }

.px-137 {
  padding-right: 13.7rem !important;
  padding-left: 13.7rem !important; }

.px-136 {
  padding-right: 13.6rem !important;
  padding-left: 13.6rem !important; }

.px-135 {
  padding-right: 13.5rem !important;
  padding-left: 13.5rem !important; }

.px-134 {
  padding-right: 13.4rem !important;
  padding-left: 13.4rem !important; }

.px-133 {
  padding-right: 13.3rem !important;
  padding-left: 13.3rem !important; }

.px-132 {
  padding-right: 13.2rem !important;
  padding-left: 13.2rem !important; }

.px-131 {
  padding-right: 13.1rem !important;
  padding-left: 13.1rem !important; }

.px-130 {
  padding-right: 13rem !important;
  padding-left: 13rem !important; }

.px-129 {
  padding-right: 12.9rem !important;
  padding-left: 12.9rem !important; }

.px-128 {
  padding-right: 12.8rem !important;
  padding-left: 12.8rem !important; }

.px-127 {
  padding-right: 12.7rem !important;
  padding-left: 12.7rem !important; }

.px-126 {
  padding-right: 12.6rem !important;
  padding-left: 12.6rem !important; }

.px-125 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important; }

.px-124 {
  padding-right: 12.4rem !important;
  padding-left: 12.4rem !important; }

.px-123 {
  padding-right: 12.3rem !important;
  padding-left: 12.3rem !important; }

.px-122 {
  padding-right: 12.2rem !important;
  padding-left: 12.2rem !important; }

.px-121 {
  padding-right: 12.1rem !important;
  padding-left: 12.1rem !important; }

.px-120 {
  padding-right: 12rem !important;
  padding-left: 12rem !important; }

.px-119 {
  padding-right: 11.9rem !important;
  padding-left: 11.9rem !important; }

.px-118 {
  padding-right: 11.8rem !important;
  padding-left: 11.8rem !important; }

.px-117 {
  padding-right: 11.7rem !important;
  padding-left: 11.7rem !important; }

.px-116 {
  padding-right: 11.6rem !important;
  padding-left: 11.6rem !important; }

.px-115 {
  padding-right: 11.5rem !important;
  padding-left: 11.5rem !important; }

.px-114 {
  padding-right: 11.4rem !important;
  padding-left: 11.4rem !important; }

.px-113 {
  padding-right: 11.3rem !important;
  padding-left: 11.3rem !important; }

.px-112 {
  padding-right: 11.2rem !important;
  padding-left: 11.2rem !important; }

.px-111 {
  padding-right: 11.1rem !important;
  padding-left: 11.1rem !important; }

.px-110 {
  padding-right: 11rem !important;
  padding-left: 11rem !important; }

.px-109 {
  padding-right: 10.9rem !important;
  padding-left: 10.9rem !important; }

.px-108 {
  padding-right: 10.8rem !important;
  padding-left: 10.8rem !important; }

.px-107 {
  padding-right: 10.7rem !important;
  padding-left: 10.7rem !important; }

.px-106 {
  padding-right: 10.6rem !important;
  padding-left: 10.6rem !important; }

.px-105 {
  padding-right: 10.5rem !important;
  padding-left: 10.5rem !important; }

.px-104 {
  padding-right: 10.4rem !important;
  padding-left: 10.4rem !important; }

.px-103 {
  padding-right: 10.3rem !important;
  padding-left: 10.3rem !important; }

.px-102 {
  padding-right: 10.2rem !important;
  padding-left: 10.2rem !important; }

.px-101 {
  padding-right: 10.1rem !important;
  padding-left: 10.1rem !important; }

.px-100 {
  padding-right: 10rem !important;
  padding-left: 10rem !important; }

.px-99 {
  padding-right: 9.9rem !important;
  padding-left: 9.9rem !important; }

.px-98 {
  padding-right: 9.8rem !important;
  padding-left: 9.8rem !important; }

.px-97 {
  padding-right: 9.7rem !important;
  padding-left: 9.7rem !important; }

.px-96 {
  padding-right: 9.6rem !important;
  padding-left: 9.6rem !important; }

.px-95 {
  padding-right: 9.5rem !important;
  padding-left: 9.5rem !important; }

.px-94 {
  padding-right: 9.4rem !important;
  padding-left: 9.4rem !important; }

.px-93 {
  padding-right: 9.3rem !important;
  padding-left: 9.3rem !important; }

.px-92 {
  padding-right: 9.2rem !important;
  padding-left: 9.2rem !important; }

.px-91 {
  padding-right: 9.1rem !important;
  padding-left: 9.1rem !important; }

.px-90 {
  padding-right: 9rem !important;
  padding-left: 9rem !important; }

.px-89 {
  padding-right: 8.9rem !important;
  padding-left: 8.9rem !important; }

.px-88 {
  padding-right: 8.8rem !important;
  padding-left: 8.8rem !important; }

.px-87 {
  padding-right: 8.7rem !important;
  padding-left: 8.7rem !important; }

.px-86 {
  padding-right: 8.6rem !important;
  padding-left: 8.6rem !important; }

.px-85 {
  padding-right: 8.5rem !important;
  padding-left: 8.5rem !important; }

.px-84 {
  padding-right: 8.4rem !important;
  padding-left: 8.4rem !important; }

.px-83 {
  padding-right: 8.3rem !important;
  padding-left: 8.3rem !important; }

.px-82 {
  padding-right: 8.2rem !important;
  padding-left: 8.2rem !important; }

.px-81 {
  padding-right: 8.1rem !important;
  padding-left: 8.1rem !important; }

.px-80 {
  padding-right: 8rem !important;
  padding-left: 8rem !important; }

.px-79 {
  padding-right: 7.9rem !important;
  padding-left: 7.9rem !important; }

.px-78 {
  padding-right: 7.8rem !important;
  padding-left: 7.8rem !important; }

.px-77 {
  padding-right: 7.7rem !important;
  padding-left: 7.7rem !important; }

.px-76 {
  padding-right: 7.6rem !important;
  padding-left: 7.6rem !important; }

.px-75 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important; }

.px-74 {
  padding-right: 7.4rem !important;
  padding-left: 7.4rem !important; }

.px-73 {
  padding-right: 7.3rem !important;
  padding-left: 7.3rem !important; }

.px-72 {
  padding-right: 7.2rem !important;
  padding-left: 7.2rem !important; }

.px-71 {
  padding-right: 7.1rem !important;
  padding-left: 7.1rem !important; }

.px-70 {
  padding-right: 7rem !important;
  padding-left: 7rem !important; }

.px-69 {
  padding-right: 6.9rem !important;
  padding-left: 6.9rem !important; }

.px-68 {
  padding-right: 6.8rem !important;
  padding-left: 6.8rem !important; }

.px-67 {
  padding-right: 6.7rem !important;
  padding-left: 6.7rem !important; }

.px-66 {
  padding-right: 6.6rem !important;
  padding-left: 6.6rem !important; }

.px-65 {
  padding-right: 6.5rem !important;
  padding-left: 6.5rem !important; }

.px-64 {
  padding-right: 6.4rem !important;
  padding-left: 6.4rem !important; }

.px-63 {
  padding-right: 6.3rem !important;
  padding-left: 6.3rem !important; }

.px-62 {
  padding-right: 6.2rem !important;
  padding-left: 6.2rem !important; }

.px-61 {
  padding-right: 6.1rem !important;
  padding-left: 6.1rem !important; }

.px-60 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.px-59 {
  padding-right: 5.9rem !important;
  padding-left: 5.9rem !important; }

.px-58 {
  padding-right: 5.8rem !important;
  padding-left: 5.8rem !important; }

.px-57 {
  padding-right: 5.7rem !important;
  padding-left: 5.7rem !important; }

.px-56 {
  padding-right: 5.6rem !important;
  padding-left: 5.6rem !important; }

.px-55 {
  padding-right: 5.5rem !important;
  padding-left: 5.5rem !important; }

.px-54 {
  padding-right: 5.4rem !important;
  padding-left: 5.4rem !important; }

.px-53 {
  padding-right: 5.3rem !important;
  padding-left: 5.3rem !important; }

.px-52 {
  padding-right: 5.2rem !important;
  padding-left: 5.2rem !important; }

.px-51 {
  padding-right: 5.1rem !important;
  padding-left: 5.1rem !important; }

.px-50 {
  padding-right: 5rem !important;
  padding-left: 5rem !important; }

.px-49 {
  padding-right: 4.9rem !important;
  padding-left: 4.9rem !important; }

.px-48 {
  padding-right: 4.8rem !important;
  padding-left: 4.8rem !important; }

.px-47 {
  padding-right: 4.7rem !important;
  padding-left: 4.7rem !important; }

.px-46 {
  padding-right: 4.6rem !important;
  padding-left: 4.6rem !important; }

.px-45 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important; }

.px-44 {
  padding-right: 4.4rem !important;
  padding-left: 4.4rem !important; }

.px-43 {
  padding-right: 4.3rem !important;
  padding-left: 4.3rem !important; }

.px-42 {
  padding-right: 4.2rem !important;
  padding-left: 4.2rem !important; }

.px-41 {
  padding-right: 4.1rem !important;
  padding-left: 4.1rem !important; }

.px-40 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.px-39 {
  padding-right: 3.9rem !important;
  padding-left: 3.9rem !important; }

.px-38 {
  padding-right: 3.8rem !important;
  padding-left: 3.8rem !important; }

.px-37 {
  padding-right: 3.7rem !important;
  padding-left: 3.7rem !important; }

.px-36 {
  padding-right: 3.6rem !important;
  padding-left: 3.6rem !important; }

.px-35 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important; }

.px-34 {
  padding-right: 3.4rem !important;
  padding-left: 3.4rem !important; }

.px-33 {
  padding-right: 3.3rem !important;
  padding-left: 3.3rem !important; }

.px-32 {
  padding-right: 3.2rem !important;
  padding-left: 3.2rem !important; }

.px-31 {
  padding-right: 3.1rem !important;
  padding-left: 3.1rem !important; }

.px-30 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-29 {
  padding-right: 2.9rem !important;
  padding-left: 2.9rem !important; }

.px-28 {
  padding-right: 2.8rem !important;
  padding-left: 2.8rem !important; }

.px-27 {
  padding-right: 2.7rem !important;
  padding-left: 2.7rem !important; }

.px-26 {
  padding-right: 2.6rem !important;
  padding-left: 2.6rem !important; }

.px-25 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important; }

.px-24 {
  padding-right: 2.4rem !important;
  padding-left: 2.4rem !important; }

.px-23 {
  padding-right: 2.3rem !important;
  padding-left: 2.3rem !important; }

.px-22 {
  padding-right: 2.2rem !important;
  padding-left: 2.2rem !important; }

.px-21 {
  padding-right: 2.1rem !important;
  padding-left: 2.1rem !important; }

.px-20 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.px-19 {
  padding-right: 1.9rem !important;
  padding-left: 1.9rem !important; }

.px-18 {
  padding-right: 1.8rem !important;
  padding-left: 1.8rem !important; }

.px-17 {
  padding-right: 1.7rem !important;
  padding-left: 1.7rem !important; }

.px-16 {
  padding-right: 1.6rem !important;
  padding-left: 1.6rem !important; }

.px-15 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-14 {
  padding-right: 1.4rem !important;
  padding-left: 1.4rem !important; }

.px-13 {
  padding-right: 1.3rem !important;
  padding-left: 1.3rem !important; }

.px-12 {
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important; }

.px-11 {
  padding-right: 1.1rem !important;
  padding-left: 1.1rem !important; }

.px-10 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-n80 {
  padding-right: -80px !important;
  padding-left: -80px !important; }

.px-n100 {
  padding-right: -100px !important;
  padding-left: -100px !important; }

.px-n150 {
  padding-right: -150px !important;
  padding-left: -150px !important; }

.py-150 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important; }

.py-149 {
  padding-top: 14.9rem !important;
  padding-bottom: 14.9rem !important; }

.py-148 {
  padding-top: 14.8rem !important;
  padding-bottom: 14.8rem !important; }

.py-147 {
  padding-top: 14.7rem !important;
  padding-bottom: 14.7rem !important; }

.py-146 {
  padding-top: 14.6rem !important;
  padding-bottom: 14.6rem !important; }

.py-145 {
  padding-top: 14.5rem !important;
  padding-bottom: 14.5rem !important; }

.py-144 {
  padding-top: 14.4rem !important;
  padding-bottom: 14.4rem !important; }

.py-143 {
  padding-top: 14.3rem !important;
  padding-bottom: 14.3rem !important; }

.py-142 {
  padding-top: 14.2rem !important;
  padding-bottom: 14.2rem !important; }

.py-141 {
  padding-top: 14.1rem !important;
  padding-bottom: 14.1rem !important; }

.py-140 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important; }

.py-139 {
  padding-top: 13.9rem !important;
  padding-bottom: 13.9rem !important; }

.py-138 {
  padding-top: 13.8rem !important;
  padding-bottom: 13.8rem !important; }

.py-137 {
  padding-top: 13.7rem !important;
  padding-bottom: 13.7rem !important; }

.py-136 {
  padding-top: 13.6rem !important;
  padding-bottom: 13.6rem !important; }

.py-135 {
  padding-top: 13.5rem !important;
  padding-bottom: 13.5rem !important; }

.py-134 {
  padding-top: 13.4rem !important;
  padding-bottom: 13.4rem !important; }

.py-133 {
  padding-top: 13.3rem !important;
  padding-bottom: 13.3rem !important; }

.py-132 {
  padding-top: 13.2rem !important;
  padding-bottom: 13.2rem !important; }

.py-131 {
  padding-top: 13.1rem !important;
  padding-bottom: 13.1rem !important; }

.py-130 {
  padding-top: 13rem !important;
  padding-bottom: 13rem !important; }

.py-129 {
  padding-top: 12.9rem !important;
  padding-bottom: 12.9rem !important; }

.py-128 {
  padding-top: 12.8rem !important;
  padding-bottom: 12.8rem !important; }

.py-127 {
  padding-top: 12.7rem !important;
  padding-bottom: 12.7rem !important; }

.py-126 {
  padding-top: 12.6rem !important;
  padding-bottom: 12.6rem !important; }

.py-125 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important; }

.py-124 {
  padding-top: 12.4rem !important;
  padding-bottom: 12.4rem !important; }

.py-123 {
  padding-top: 12.3rem !important;
  padding-bottom: 12.3rem !important; }

.py-122 {
  padding-top: 12.2rem !important;
  padding-bottom: 12.2rem !important; }

.py-121 {
  padding-top: 12.1rem !important;
  padding-bottom: 12.1rem !important; }

.py-120 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important; }

.py-119 {
  padding-top: 11.9rem !important;
  padding-bottom: 11.9rem !important; }

.py-118 {
  padding-top: 11.8rem !important;
  padding-bottom: 11.8rem !important; }

.py-117 {
  padding-top: 11.7rem !important;
  padding-bottom: 11.7rem !important; }

.py-116 {
  padding-top: 11.6rem !important;
  padding-bottom: 11.6rem !important; }

.py-115 {
  padding-top: 11.5rem !important;
  padding-bottom: 11.5rem !important; }

.py-114 {
  padding-top: 11.4rem !important;
  padding-bottom: 11.4rem !important; }

.py-113 {
  padding-top: 11.3rem !important;
  padding-bottom: 11.3rem !important; }

.py-112 {
  padding-top: 11.2rem !important;
  padding-bottom: 11.2rem !important; }

.py-111 {
  padding-top: 11.1rem !important;
  padding-bottom: 11.1rem !important; }

.py-110 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important; }

.py-109 {
  padding-top: 10.9rem !important;
  padding-bottom: 10.9rem !important; }

.py-108 {
  padding-top: 10.8rem !important;
  padding-bottom: 10.8rem !important; }

.py-107 {
  padding-top: 10.7rem !important;
  padding-bottom: 10.7rem !important; }

.py-106 {
  padding-top: 10.6rem !important;
  padding-bottom: 10.6rem !important; }

.py-105 {
  padding-top: 10.5rem !important;
  padding-bottom: 10.5rem !important; }

.py-104 {
  padding-top: 10.4rem !important;
  padding-bottom: 10.4rem !important; }

.py-103 {
  padding-top: 10.3rem !important;
  padding-bottom: 10.3rem !important; }

.py-102 {
  padding-top: 10.2rem !important;
  padding-bottom: 10.2rem !important; }

.py-101 {
  padding-top: 10.1rem !important;
  padding-bottom: 10.1rem !important; }

.py-100 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important; }

.py-99 {
  padding-top: 9.9rem !important;
  padding-bottom: 9.9rem !important; }

.py-98 {
  padding-top: 9.8rem !important;
  padding-bottom: 9.8rem !important; }

.py-97 {
  padding-top: 9.7rem !important;
  padding-bottom: 9.7rem !important; }

.py-96 {
  padding-top: 9.6rem !important;
  padding-bottom: 9.6rem !important; }

.py-95 {
  padding-top: 9.5rem !important;
  padding-bottom: 9.5rem !important; }

.py-94 {
  padding-top: 9.4rem !important;
  padding-bottom: 9.4rem !important; }

.py-93 {
  padding-top: 9.3rem !important;
  padding-bottom: 9.3rem !important; }

.py-92 {
  padding-top: 9.2rem !important;
  padding-bottom: 9.2rem !important; }

.py-91 {
  padding-top: 9.1rem !important;
  padding-bottom: 9.1rem !important; }

.py-90 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important; }

.py-89 {
  padding-top: 8.9rem !important;
  padding-bottom: 8.9rem !important; }

.py-88 {
  padding-top: 8.8rem !important;
  padding-bottom: 8.8rem !important; }

.py-87 {
  padding-top: 8.7rem !important;
  padding-bottom: 8.7rem !important; }

.py-86 {
  padding-top: 8.6rem !important;
  padding-bottom: 8.6rem !important; }

.py-85 {
  padding-top: 8.5rem !important;
  padding-bottom: 8.5rem !important; }

.py-84 {
  padding-top: 8.4rem !important;
  padding-bottom: 8.4rem !important; }

.py-83 {
  padding-top: 8.3rem !important;
  padding-bottom: 8.3rem !important; }

.py-82 {
  padding-top: 8.2rem !important;
  padding-bottom: 8.2rem !important; }

.py-81 {
  padding-top: 8.1rem !important;
  padding-bottom: 8.1rem !important; }

.py-80 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important; }

.py-79 {
  padding-top: 7.9rem !important;
  padding-bottom: 7.9rem !important; }

.py-78 {
  padding-top: 7.8rem !important;
  padding-bottom: 7.8rem !important; }

.py-77 {
  padding-top: 7.7rem !important;
  padding-bottom: 7.7rem !important; }

.py-76 {
  padding-top: 7.6rem !important;
  padding-bottom: 7.6rem !important; }

.py-75 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important; }

.py-74 {
  padding-top: 7.4rem !important;
  padding-bottom: 7.4rem !important; }

.py-73 {
  padding-top: 7.3rem !important;
  padding-bottom: 7.3rem !important; }

.py-72 {
  padding-top: 7.2rem !important;
  padding-bottom: 7.2rem !important; }

.py-71 {
  padding-top: 7.1rem !important;
  padding-bottom: 7.1rem !important; }

.py-70 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important; }

.py-69 {
  padding-top: 6.9rem !important;
  padding-bottom: 6.9rem !important; }

.py-68 {
  padding-top: 6.8rem !important;
  padding-bottom: 6.8rem !important; }

.py-67 {
  padding-top: 6.7rem !important;
  padding-bottom: 6.7rem !important; }

.py-66 {
  padding-top: 6.6rem !important;
  padding-bottom: 6.6rem !important; }

.py-65 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important; }

.py-64 {
  padding-top: 6.4rem !important;
  padding-bottom: 6.4rem !important; }

.py-63 {
  padding-top: 6.3rem !important;
  padding-bottom: 6.3rem !important; }

.py-62 {
  padding-top: 6.2rem !important;
  padding-bottom: 6.2rem !important; }

.py-61 {
  padding-top: 6.1rem !important;
  padding-bottom: 6.1rem !important; }

.py-60 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.py-59 {
  padding-top: 5.9rem !important;
  padding-bottom: 5.9rem !important; }

.py-58 {
  padding-top: 5.8rem !important;
  padding-bottom: 5.8rem !important; }

.py-57 {
  padding-top: 5.7rem !important;
  padding-bottom: 5.7rem !important; }

.py-56 {
  padding-top: 5.6rem !important;
  padding-bottom: 5.6rem !important; }

.py-55 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important; }

.py-54 {
  padding-top: 5.4rem !important;
  padding-bottom: 5.4rem !important; }

.py-53 {
  padding-top: 5.3rem !important;
  padding-bottom: 5.3rem !important; }

.py-52 {
  padding-top: 5.2rem !important;
  padding-bottom: 5.2rem !important; }

.py-51 {
  padding-top: 5.1rem !important;
  padding-bottom: 5.1rem !important; }

.py-50 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.py-49 {
  padding-top: 4.9rem !important;
  padding-bottom: 4.9rem !important; }

.py-48 {
  padding-top: 4.8rem !important;
  padding-bottom: 4.8rem !important; }

.py-47 {
  padding-top: 4.7rem !important;
  padding-bottom: 4.7rem !important; }

.py-46 {
  padding-top: 4.6rem !important;
  padding-bottom: 4.6rem !important; }

.py-45 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important; }

.py-44 {
  padding-top: 4.4rem !important;
  padding-bottom: 4.4rem !important; }

.py-43 {
  padding-top: 4.3rem !important;
  padding-bottom: 4.3rem !important; }

.py-42 {
  padding-top: 4.2rem !important;
  padding-bottom: 4.2rem !important; }

.py-41 {
  padding-top: 4.1rem !important;
  padding-bottom: 4.1rem !important; }

.py-40 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.py-39 {
  padding-top: 3.9rem !important;
  padding-bottom: 3.9rem !important; }

.py-38 {
  padding-top: 3.8rem !important;
  padding-bottom: 3.8rem !important; }

.py-37 {
  padding-top: 3.7rem !important;
  padding-bottom: 3.7rem !important; }

.py-36 {
  padding-top: 3.6rem !important;
  padding-bottom: 3.6rem !important; }

.py-35 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important; }

.py-34 {
  padding-top: 3.4rem !important;
  padding-bottom: 3.4rem !important; }

.py-33 {
  padding-top: 3.3rem !important;
  padding-bottom: 3.3rem !important; }

.py-32 {
  padding-top: 3.2rem !important;
  padding-bottom: 3.2rem !important; }

.py-31 {
  padding-top: 3.1rem !important;
  padding-bottom: 3.1rem !important; }

.py-30 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-29 {
  padding-top: 2.9rem !important;
  padding-bottom: 2.9rem !important; }

.py-28 {
  padding-top: 2.8rem !important;
  padding-bottom: 2.8rem !important; }

.py-27 {
  padding-top: 2.7rem !important;
  padding-bottom: 2.7rem !important; }

.py-26 {
  padding-top: 2.6rem !important;
  padding-bottom: 2.6rem !important; }

.py-25 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.py-24 {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important; }

.py-23 {
  padding-top: 2.3rem !important;
  padding-bottom: 2.3rem !important; }

.py-22 {
  padding-top: 2.2rem !important;
  padding-bottom: 2.2rem !important; }

.py-21 {
  padding-top: 2.1rem !important;
  padding-bottom: 2.1rem !important; }

.py-20 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-19 {
  padding-top: 1.9rem !important;
  padding-bottom: 1.9rem !important; }

.py-18 {
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important; }

.py-17 {
  padding-top: 1.7rem !important;
  padding-bottom: 1.7rem !important; }

.py-16 {
  padding-top: 1.6rem !important;
  padding-bottom: 1.6rem !important; }

.py-15 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-14 {
  padding-top: 1.4rem !important;
  padding-bottom: 1.4rem !important; }

.py-13 {
  padding-top: 1.3rem !important;
  padding-bottom: 1.3rem !important; }

.py-12 {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important; }

.py-11 {
  padding-top: 1.1rem !important;
  padding-bottom: 1.1rem !important; }

.py-10 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-n80 {
  padding-top: -80px !important;
  padding-bottom: -80px !important; }

.py-n100 {
  padding-top: -100px !important;
  padding-bottom: -100px !important; }

.py-n150 {
  padding-top: -150px !important;
  padding-bottom: -150px !important; }

.pt-150 {
  padding-top: 15rem !important; }

.pt-149 {
  padding-top: 14.9rem !important; }

.pt-148 {
  padding-top: 14.8rem !important; }

.pt-147 {
  padding-top: 14.7rem !important; }

.pt-146 {
  padding-top: 14.6rem !important; }

.pt-145 {
  padding-top: 14.5rem !important; }

.pt-144 {
  padding-top: 14.4rem !important; }

.pt-143 {
  padding-top: 14.3rem !important; }

.pt-142 {
  padding-top: 14.2rem !important; }

.pt-141 {
  padding-top: 14.1rem !important; }

.pt-140 {
  padding-top: 14rem !important; }

.pt-139 {
  padding-top: 13.9rem !important; }

.pt-138 {
  padding-top: 13.8rem !important; }

.pt-137 {
  padding-top: 13.7rem !important; }

.pt-136 {
  padding-top: 13.6rem !important; }

.pt-135 {
  padding-top: 13.5rem !important; }

.pt-134 {
  padding-top: 13.4rem !important; }

.pt-133 {
  padding-top: 13.3rem !important; }

.pt-132 {
  padding-top: 13.2rem !important; }

.pt-131 {
  padding-top: 13.1rem !important; }

.pt-130 {
  padding-top: 13rem !important; }

.pt-129 {
  padding-top: 12.9rem !important; }

.pt-128 {
  padding-top: 12.8rem !important; }

.pt-127 {
  padding-top: 12.7rem !important; }

.pt-126 {
  padding-top: 12.6rem !important; }

.pt-125 {
  padding-top: 12.5rem !important; }

.pt-124 {
  padding-top: 12.4rem !important; }

.pt-123 {
  padding-top: 12.3rem !important; }

.pt-122 {
  padding-top: 12.2rem !important; }

.pt-121 {
  padding-top: 12.1rem !important; }

.pt-120 {
  padding-top: 12rem !important; }

.pt-119 {
  padding-top: 11.9rem !important; }

.pt-118 {
  padding-top: 11.8rem !important; }

.pt-117 {
  padding-top: 11.7rem !important; }

.pt-116 {
  padding-top: 11.6rem !important; }

.pt-115 {
  padding-top: 11.5rem !important; }

.pt-114 {
  padding-top: 11.4rem !important; }

.pt-113 {
  padding-top: 11.3rem !important; }

.pt-112 {
  padding-top: 11.2rem !important; }

.pt-111 {
  padding-top: 11.1rem !important; }

.pt-110 {
  padding-top: 11rem !important; }

.pt-109 {
  padding-top: 10.9rem !important; }

.pt-108 {
  padding-top: 10.8rem !important; }

.pt-107 {
  padding-top: 10.7rem !important; }

.pt-106 {
  padding-top: 10.6rem !important; }

.pt-105 {
  padding-top: 10.5rem !important; }

.pt-104 {
  padding-top: 10.4rem !important; }

.pt-103 {
  padding-top: 10.3rem !important; }

.pt-102 {
  padding-top: 10.2rem !important; }

.pt-101 {
  padding-top: 10.1rem !important; }

.pt-100 {
  padding-top: 10rem !important; }

.pt-99 {
  padding-top: 9.9rem !important; }

.pt-98 {
  padding-top: 9.8rem !important; }

.pt-97 {
  padding-top: 9.7rem !important; }

.pt-96 {
  padding-top: 9.6rem !important; }

.pt-95 {
  padding-top: 9.5rem !important; }

.pt-94 {
  padding-top: 9.4rem !important; }

.pt-93 {
  padding-top: 9.3rem !important; }

.pt-92 {
  padding-top: 9.2rem !important; }

.pt-91 {
  padding-top: 9.1rem !important; }

.pt-90 {
  padding-top: 9rem !important; }

.pt-89 {
  padding-top: 8.9rem !important; }

.pt-88 {
  padding-top: 8.8rem !important; }

.pt-87 {
  padding-top: 8.7rem !important; }

.pt-86 {
  padding-top: 8.6rem !important; }

.pt-85 {
  padding-top: 8.5rem !important; }

.pt-84 {
  padding-top: 8.4rem !important; }

.pt-83 {
  padding-top: 8.3rem !important; }

.pt-82 {
  padding-top: 8.2rem !important; }

.pt-81 {
  padding-top: 8.1rem !important; }

.pt-80 {
  padding-top: 8rem !important; }

.pt-79 {
  padding-top: 7.9rem !important; }

.pt-78 {
  padding-top: 7.8rem !important; }

.pt-77 {
  padding-top: 7.7rem !important; }

.pt-76 {
  padding-top: 7.6rem !important; }

.pt-75 {
  padding-top: 7.5rem !important; }

.pt-74 {
  padding-top: 7.4rem !important; }

.pt-73 {
  padding-top: 7.3rem !important; }

.pt-72 {
  padding-top: 7.2rem !important; }

.pt-71 {
  padding-top: 7.1rem !important; }

.pt-70 {
  padding-top: 7rem !important; }

.pt-69 {
  padding-top: 6.9rem !important; }

.pt-68 {
  padding-top: 6.8rem !important; }

.pt-67 {
  padding-top: 6.7rem !important; }

.pt-66 {
  padding-top: 6.6rem !important; }

.pt-65 {
  padding-top: 6.5rem !important; }

.pt-64 {
  padding-top: 6.4rem !important; }

.pt-63 {
  padding-top: 6.3rem !important; }

.pt-62 {
  padding-top: 6.2rem !important; }

.pt-61 {
  padding-top: 6.1rem !important; }

.pt-60 {
  padding-top: 6rem !important; }

.pt-59 {
  padding-top: 5.9rem !important; }

.pt-58 {
  padding-top: 5.8rem !important; }

.pt-57 {
  padding-top: 5.7rem !important; }

.pt-56 {
  padding-top: 5.6rem !important; }

.pt-55 {
  padding-top: 5.5rem !important; }

.pt-54 {
  padding-top: 5.4rem !important; }

.pt-53 {
  padding-top: 5.3rem !important; }

.pt-52 {
  padding-top: 5.2rem !important; }

.pt-51 {
  padding-top: 5.1rem !important; }

.pt-50 {
  padding-top: 5rem !important; }

.pt-49 {
  padding-top: 4.9rem !important; }

.pt-48 {
  padding-top: 4.8rem !important; }

.pt-47 {
  padding-top: 4.7rem !important; }

.pt-46 {
  padding-top: 4.6rem !important; }

.pt-45 {
  padding-top: 4.5rem !important; }

.pt-44 {
  padding-top: 4.4rem !important; }

.pt-43 {
  padding-top: 4.3rem !important; }

.pt-42 {
  padding-top: 4.2rem !important; }

.pt-41 {
  padding-top: 4.1rem !important; }

.pt-40 {
  padding-top: 4rem !important; }

.pt-39 {
  padding-top: 3.9rem !important; }

.pt-38 {
  padding-top: 3.8rem !important; }

.pt-37 {
  padding-top: 3.7rem !important; }

.pt-36 {
  padding-top: 3.6rem !important; }

.pt-35 {
  padding-top: 3.5rem !important; }

.pt-34 {
  padding-top: 3.4rem !important; }

.pt-33 {
  padding-top: 3.3rem !important; }

.pt-32 {
  padding-top: 3.2rem !important; }

.pt-31 {
  padding-top: 3.1rem !important; }

.pt-30 {
  padding-top: 3rem !important; }

.pt-29 {
  padding-top: 2.9rem !important; }

.pt-28 {
  padding-top: 2.8rem !important; }

.pt-27 {
  padding-top: 2.7rem !important; }

.pt-26 {
  padding-top: 2.6rem !important; }

.pt-25 {
  padding-top: 2.5rem !important; }

.pt-24 {
  padding-top: 2.4rem !important; }

.pt-23 {
  padding-top: 2.3rem !important; }

.pt-22 {
  padding-top: 2.2rem !important; }

.pt-21 {
  padding-top: 2.1rem !important; }

.pt-20 {
  padding-top: 2rem !important; }

.pt-19 {
  padding-top: 1.9rem !important; }

.pt-18 {
  padding-top: 1.8rem !important; }

.pt-17 {
  padding-top: 1.7rem !important; }

.pt-16 {
  padding-top: 1.6rem !important; }

.pt-15 {
  padding-top: 1.5rem !important; }

.pt-14 {
  padding-top: 1.4rem !important; }

.pt-13 {
  padding-top: 1.3rem !important; }

.pt-12 {
  padding-top: 1.2rem !important; }

.pt-11 {
  padding-top: 1.1rem !important; }

.pt-10 {
  padding-top: 1rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pt-n80 {
  padding-top: -80px !important; }

.pt-n100 {
  padding-top: -100px !important; }

.pt-n150 {
  padding-top: -150px !important; }

.pe-150 {
  padding-right: 15rem !important; }

.pe-149 {
  padding-right: 14.9rem !important; }

.pe-148 {
  padding-right: 14.8rem !important; }

.pe-147 {
  padding-right: 14.7rem !important; }

.pe-146 {
  padding-right: 14.6rem !important; }

.pe-145 {
  padding-right: 14.5rem !important; }

.pe-144 {
  padding-right: 14.4rem !important; }

.pe-143 {
  padding-right: 14.3rem !important; }

.pe-142 {
  padding-right: 14.2rem !important; }

.pe-141 {
  padding-right: 14.1rem !important; }

.pe-140 {
  padding-right: 14rem !important; }

.pe-139 {
  padding-right: 13.9rem !important; }

.pe-138 {
  padding-right: 13.8rem !important; }

.pe-137 {
  padding-right: 13.7rem !important; }

.pe-136 {
  padding-right: 13.6rem !important; }

.pe-135 {
  padding-right: 13.5rem !important; }

.pe-134 {
  padding-right: 13.4rem !important; }

.pe-133 {
  padding-right: 13.3rem !important; }

.pe-132 {
  padding-right: 13.2rem !important; }

.pe-131 {
  padding-right: 13.1rem !important; }

.pe-130 {
  padding-right: 13rem !important; }

.pe-129 {
  padding-right: 12.9rem !important; }

.pe-128 {
  padding-right: 12.8rem !important; }

.pe-127 {
  padding-right: 12.7rem !important; }

.pe-126 {
  padding-right: 12.6rem !important; }

.pe-125 {
  padding-right: 12.5rem !important; }

.pe-124 {
  padding-right: 12.4rem !important; }

.pe-123 {
  padding-right: 12.3rem !important; }

.pe-122 {
  padding-right: 12.2rem !important; }

.pe-121 {
  padding-right: 12.1rem !important; }

.pe-120 {
  padding-right: 12rem !important; }

.pe-119 {
  padding-right: 11.9rem !important; }

.pe-118 {
  padding-right: 11.8rem !important; }

.pe-117 {
  padding-right: 11.7rem !important; }

.pe-116 {
  padding-right: 11.6rem !important; }

.pe-115 {
  padding-right: 11.5rem !important; }

.pe-114 {
  padding-right: 11.4rem !important; }

.pe-113 {
  padding-right: 11.3rem !important; }

.pe-112 {
  padding-right: 11.2rem !important; }

.pe-111 {
  padding-right: 11.1rem !important; }

.pe-110 {
  padding-right: 11rem !important; }

.pe-109 {
  padding-right: 10.9rem !important; }

.pe-108 {
  padding-right: 10.8rem !important; }

.pe-107 {
  padding-right: 10.7rem !important; }

.pe-106 {
  padding-right: 10.6rem !important; }

.pe-105 {
  padding-right: 10.5rem !important; }

.pe-104 {
  padding-right: 10.4rem !important; }

.pe-103 {
  padding-right: 10.3rem !important; }

.pe-102 {
  padding-right: 10.2rem !important; }

.pe-101 {
  padding-right: 10.1rem !important; }

.pe-100 {
  padding-right: 10rem !important; }

.pe-99 {
  padding-right: 9.9rem !important; }

.pe-98 {
  padding-right: 9.8rem !important; }

.pe-97 {
  padding-right: 9.7rem !important; }

.pe-96 {
  padding-right: 9.6rem !important; }

.pe-95 {
  padding-right: 9.5rem !important; }

.pe-94 {
  padding-right: 9.4rem !important; }

.pe-93 {
  padding-right: 9.3rem !important; }

.pe-92 {
  padding-right: 9.2rem !important; }

.pe-91 {
  padding-right: 9.1rem !important; }

.pe-90 {
  padding-right: 9rem !important; }

.pe-89 {
  padding-right: 8.9rem !important; }

.pe-88 {
  padding-right: 8.8rem !important; }

.pe-87 {
  padding-right: 8.7rem !important; }

.pe-86 {
  padding-right: 8.6rem !important; }

.pe-85 {
  padding-right: 8.5rem !important; }

.pe-84 {
  padding-right: 8.4rem !important; }

.pe-83 {
  padding-right: 8.3rem !important; }

.pe-82 {
  padding-right: 8.2rem !important; }

.pe-81 {
  padding-right: 8.1rem !important; }

.pe-80 {
  padding-right: 8rem !important; }

.pe-79 {
  padding-right: 7.9rem !important; }

.pe-78 {
  padding-right: 7.8rem !important; }

.pe-77 {
  padding-right: 7.7rem !important; }

.pe-76 {
  padding-right: 7.6rem !important; }

.pe-75 {
  padding-right: 7.5rem !important; }

.pe-74 {
  padding-right: 7.4rem !important; }

.pe-73 {
  padding-right: 7.3rem !important; }

.pe-72 {
  padding-right: 7.2rem !important; }

.pe-71 {
  padding-right: 7.1rem !important; }

.pe-70 {
  padding-right: 7rem !important; }

.pe-69 {
  padding-right: 6.9rem !important; }

.pe-68 {
  padding-right: 6.8rem !important; }

.pe-67 {
  padding-right: 6.7rem !important; }

.pe-66 {
  padding-right: 6.6rem !important; }

.pe-65 {
  padding-right: 6.5rem !important; }

.pe-64 {
  padding-right: 6.4rem !important; }

.pe-63 {
  padding-right: 6.3rem !important; }

.pe-62 {
  padding-right: 6.2rem !important; }

.pe-61 {
  padding-right: 6.1rem !important; }

.pe-60 {
  padding-right: 6rem !important; }

.pe-59 {
  padding-right: 5.9rem !important; }

.pe-58 {
  padding-right: 5.8rem !important; }

.pe-57 {
  padding-right: 5.7rem !important; }

.pe-56 {
  padding-right: 5.6rem !important; }

.pe-55 {
  padding-right: 5.5rem !important; }

.pe-54 {
  padding-right: 5.4rem !important; }

.pe-53 {
  padding-right: 5.3rem !important; }

.pe-52 {
  padding-right: 5.2rem !important; }

.pe-51 {
  padding-right: 5.1rem !important; }

.pe-50 {
  padding-right: 5rem !important; }

.pe-49 {
  padding-right: 4.9rem !important; }

.pe-48 {
  padding-right: 4.8rem !important; }

.pe-47 {
  padding-right: 4.7rem !important; }

.pe-46 {
  padding-right: 4.6rem !important; }

.pe-45 {
  padding-right: 4.5rem !important; }

.pe-44 {
  padding-right: 4.4rem !important; }

.pe-43 {
  padding-right: 4.3rem !important; }

.pe-42 {
  padding-right: 4.2rem !important; }

.pe-41 {
  padding-right: 4.1rem !important; }

.pe-40 {
  padding-right: 4rem !important; }

.pe-39 {
  padding-right: 3.9rem !important; }

.pe-38 {
  padding-right: 3.8rem !important; }

.pe-37 {
  padding-right: 3.7rem !important; }

.pe-36 {
  padding-right: 3.6rem !important; }

.pe-35 {
  padding-right: 3.5rem !important; }

.pe-34 {
  padding-right: 3.4rem !important; }

.pe-33 {
  padding-right: 3.3rem !important; }

.pe-32 {
  padding-right: 3.2rem !important; }

.pe-31 {
  padding-right: 3.1rem !important; }

.pe-30 {
  padding-right: 3rem !important; }

.pe-29 {
  padding-right: 2.9rem !important; }

.pe-28 {
  padding-right: 2.8rem !important; }

.pe-27 {
  padding-right: 2.7rem !important; }

.pe-26 {
  padding-right: 2.6rem !important; }

.pe-25 {
  padding-right: 2.5rem !important; }

.pe-24 {
  padding-right: 2.4rem !important; }

.pe-23 {
  padding-right: 2.3rem !important; }

.pe-22 {
  padding-right: 2.2rem !important; }

.pe-21 {
  padding-right: 2.1rem !important; }

.pe-20 {
  padding-right: 2rem !important; }

.pe-19 {
  padding-right: 1.9rem !important; }

.pe-18 {
  padding-right: 1.8rem !important; }

.pe-17 {
  padding-right: 1.7rem !important; }

.pe-16 {
  padding-right: 1.6rem !important; }

.pe-15 {
  padding-right: 1.5rem !important; }

.pe-14 {
  padding-right: 1.4rem !important; }

.pe-13 {
  padding-right: 1.3rem !important; }

.pe-12 {
  padding-right: 1.2rem !important; }

.pe-11 {
  padding-right: 1.1rem !important; }

.pe-10 {
  padding-right: 1rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pe-n80 {
  padding-right: -80px !important; }

.pe-n100 {
  padding-right: -100px !important; }

.pe-n150 {
  padding-right: -150px !important; }

.pb-150 {
  padding-bottom: 15rem !important; }

.pb-149 {
  padding-bottom: 14.9rem !important; }

.pb-148 {
  padding-bottom: 14.8rem !important; }

.pb-147 {
  padding-bottom: 14.7rem !important; }

.pb-146 {
  padding-bottom: 14.6rem !important; }

.pb-145 {
  padding-bottom: 14.5rem !important; }

.pb-144 {
  padding-bottom: 14.4rem !important; }

.pb-143 {
  padding-bottom: 14.3rem !important; }

.pb-142 {
  padding-bottom: 14.2rem !important; }

.pb-141 {
  padding-bottom: 14.1rem !important; }

.pb-140 {
  padding-bottom: 14rem !important; }

.pb-139 {
  padding-bottom: 13.9rem !important; }

.pb-138 {
  padding-bottom: 13.8rem !important; }

.pb-137 {
  padding-bottom: 13.7rem !important; }

.pb-136 {
  padding-bottom: 13.6rem !important; }

.pb-135 {
  padding-bottom: 13.5rem !important; }

.pb-134 {
  padding-bottom: 13.4rem !important; }

.pb-133 {
  padding-bottom: 13.3rem !important; }

.pb-132 {
  padding-bottom: 13.2rem !important; }

.pb-131 {
  padding-bottom: 13.1rem !important; }

.pb-130 {
  padding-bottom: 13rem !important; }

.pb-129 {
  padding-bottom: 12.9rem !important; }

.pb-128 {
  padding-bottom: 12.8rem !important; }

.pb-127 {
  padding-bottom: 12.7rem !important; }

.pb-126 {
  padding-bottom: 12.6rem !important; }

.pb-125 {
  padding-bottom: 12.5rem !important; }

.pb-124 {
  padding-bottom: 12.4rem !important; }

.pb-123 {
  padding-bottom: 12.3rem !important; }

.pb-122 {
  padding-bottom: 12.2rem !important; }

.pb-121 {
  padding-bottom: 12.1rem !important; }

.pb-120 {
  padding-bottom: 12rem !important; }

.pb-119 {
  padding-bottom: 11.9rem !important; }

.pb-118 {
  padding-bottom: 11.8rem !important; }

.pb-117 {
  padding-bottom: 11.7rem !important; }

.pb-116 {
  padding-bottom: 11.6rem !important; }

.pb-115 {
  padding-bottom: 11.5rem !important; }

.pb-114 {
  padding-bottom: 11.4rem !important; }

.pb-113 {
  padding-bottom: 11.3rem !important; }

.pb-112 {
  padding-bottom: 11.2rem !important; }

.pb-111 {
  padding-bottom: 11.1rem !important; }

.pb-110 {
  padding-bottom: 11rem !important; }

.pb-109 {
  padding-bottom: 10.9rem !important; }

.pb-108 {
  padding-bottom: 10.8rem !important; }

.pb-107 {
  padding-bottom: 10.7rem !important; }

.pb-106 {
  padding-bottom: 10.6rem !important; }

.pb-105 {
  padding-bottom: 10.5rem !important; }

.pb-104 {
  padding-bottom: 10.4rem !important; }

.pb-103 {
  padding-bottom: 10.3rem !important; }

.pb-102 {
  padding-bottom: 10.2rem !important; }

.pb-101 {
  padding-bottom: 10.1rem !important; }

.pb-100 {
  padding-bottom: 10rem !important; }

.pb-99 {
  padding-bottom: 9.9rem !important; }

.pb-98 {
  padding-bottom: 9.8rem !important; }

.pb-97 {
  padding-bottom: 9.7rem !important; }

.pb-96 {
  padding-bottom: 9.6rem !important; }

.pb-95 {
  padding-bottom: 9.5rem !important; }

.pb-94 {
  padding-bottom: 9.4rem !important; }

.pb-93 {
  padding-bottom: 9.3rem !important; }

.pb-92 {
  padding-bottom: 9.2rem !important; }

.pb-91 {
  padding-bottom: 9.1rem !important; }

.pb-90 {
  padding-bottom: 9rem !important; }

.pb-89 {
  padding-bottom: 8.9rem !important; }

.pb-88 {
  padding-bottom: 8.8rem !important; }

.pb-87 {
  padding-bottom: 8.7rem !important; }

.pb-86 {
  padding-bottom: 8.6rem !important; }

.pb-85 {
  padding-bottom: 8.5rem !important; }

.pb-84 {
  padding-bottom: 8.4rem !important; }

.pb-83 {
  padding-bottom: 8.3rem !important; }

.pb-82 {
  padding-bottom: 8.2rem !important; }

.pb-81 {
  padding-bottom: 8.1rem !important; }

.pb-80 {
  padding-bottom: 8rem !important; }

.pb-79 {
  padding-bottom: 7.9rem !important; }

.pb-78 {
  padding-bottom: 7.8rem !important; }

.pb-77 {
  padding-bottom: 7.7rem !important; }

.pb-76 {
  padding-bottom: 7.6rem !important; }

.pb-75 {
  padding-bottom: 7.5rem !important; }

.pb-74 {
  padding-bottom: 7.4rem !important; }

.pb-73 {
  padding-bottom: 7.3rem !important; }

.pb-72 {
  padding-bottom: 7.2rem !important; }

.pb-71 {
  padding-bottom: 7.1rem !important; }

.pb-70 {
  padding-bottom: 7rem !important; }

.pb-69 {
  padding-bottom: 6.9rem !important; }

.pb-68 {
  padding-bottom: 6.8rem !important; }

.pb-67 {
  padding-bottom: 6.7rem !important; }

.pb-66 {
  padding-bottom: 6.6rem !important; }

.pb-65 {
  padding-bottom: 6.5rem !important; }

.pb-64 {
  padding-bottom: 6.4rem !important; }

.pb-63 {
  padding-bottom: 6.3rem !important; }

.pb-62 {
  padding-bottom: 6.2rem !important; }

.pb-61 {
  padding-bottom: 6.1rem !important; }

.pb-60 {
  padding-bottom: 6rem !important; }

.pb-59 {
  padding-bottom: 5.9rem !important; }

.pb-58 {
  padding-bottom: 5.8rem !important; }

.pb-57 {
  padding-bottom: 5.7rem !important; }

.pb-56 {
  padding-bottom: 5.6rem !important; }

.pb-55 {
  padding-bottom: 5.5rem !important; }

.pb-54 {
  padding-bottom: 5.4rem !important; }

.pb-53 {
  padding-bottom: 5.3rem !important; }

.pb-52 {
  padding-bottom: 5.2rem !important; }

.pb-51 {
  padding-bottom: 5.1rem !important; }

.pb-50 {
  padding-bottom: 5rem !important; }

.pb-49 {
  padding-bottom: 4.9rem !important; }

.pb-48 {
  padding-bottom: 4.8rem !important; }

.pb-47 {
  padding-bottom: 4.7rem !important; }

.pb-46 {
  padding-bottom: 4.6rem !important; }

.pb-45 {
  padding-bottom: 4.5rem !important; }

.pb-44 {
  padding-bottom: 4.4rem !important; }

.pb-43 {
  padding-bottom: 4.3rem !important; }

.pb-42 {
  padding-bottom: 4.2rem !important; }

.pb-41 {
  padding-bottom: 4.1rem !important; }

.pb-40 {
  padding-bottom: 4rem !important; }

.pb-39 {
  padding-bottom: 3.9rem !important; }

.pb-38 {
  padding-bottom: 3.8rem !important; }

.pb-37 {
  padding-bottom: 3.7rem !important; }

.pb-36 {
  padding-bottom: 3.6rem !important; }

.pb-35 {
  padding-bottom: 3.5rem !important; }

.pb-34 {
  padding-bottom: 3.4rem !important; }

.pb-33 {
  padding-bottom: 3.3rem !important; }

.pb-32 {
  padding-bottom: 3.2rem !important; }

.pb-31 {
  padding-bottom: 3.1rem !important; }

.pb-30 {
  padding-bottom: 3rem !important; }

.pb-29 {
  padding-bottom: 2.9rem !important; }

.pb-28 {
  padding-bottom: 2.8rem !important; }

.pb-27 {
  padding-bottom: 2.7rem !important; }

.pb-26 {
  padding-bottom: 2.6rem !important; }

.pb-25 {
  padding-bottom: 2.5rem !important; }

.pb-24 {
  padding-bottom: 2.4rem !important; }

.pb-23 {
  padding-bottom: 2.3rem !important; }

.pb-22 {
  padding-bottom: 2.2rem !important; }

.pb-21 {
  padding-bottom: 2.1rem !important; }

.pb-20 {
  padding-bottom: 2rem !important; }

.pb-19 {
  padding-bottom: 1.9rem !important; }

.pb-18 {
  padding-bottom: 1.8rem !important; }

.pb-17 {
  padding-bottom: 1.7rem !important; }

.pb-16 {
  padding-bottom: 1.6rem !important; }

.pb-15 {
  padding-bottom: 1.5rem !important; }

.pb-14 {
  padding-bottom: 1.4rem !important; }

.pb-13 {
  padding-bottom: 1.3rem !important; }

.pb-12 {
  padding-bottom: 1.2rem !important; }

.pb-11 {
  padding-bottom: 1.1rem !important; }

.pb-10 {
  padding-bottom: 1rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pb-n80 {
  padding-bottom: -80px !important; }

.pb-n100 {
  padding-bottom: -100px !important; }

.pb-n150 {
  padding-bottom: -150px !important; }

.ps-150 {
  padding-left: 15rem !important; }

.ps-149 {
  padding-left: 14.9rem !important; }

.ps-148 {
  padding-left: 14.8rem !important; }

.ps-147 {
  padding-left: 14.7rem !important; }

.ps-146 {
  padding-left: 14.6rem !important; }

.ps-145 {
  padding-left: 14.5rem !important; }

.ps-144 {
  padding-left: 14.4rem !important; }

.ps-143 {
  padding-left: 14.3rem !important; }

.ps-142 {
  padding-left: 14.2rem !important; }

.ps-141 {
  padding-left: 14.1rem !important; }

.ps-140 {
  padding-left: 14rem !important; }

.ps-139 {
  padding-left: 13.9rem !important; }

.ps-138 {
  padding-left: 13.8rem !important; }

.ps-137 {
  padding-left: 13.7rem !important; }

.ps-136 {
  padding-left: 13.6rem !important; }

.ps-135 {
  padding-left: 13.5rem !important; }

.ps-134 {
  padding-left: 13.4rem !important; }

.ps-133 {
  padding-left: 13.3rem !important; }

.ps-132 {
  padding-left: 13.2rem !important; }

.ps-131 {
  padding-left: 13.1rem !important; }

.ps-130 {
  padding-left: 13rem !important; }

.ps-129 {
  padding-left: 12.9rem !important; }

.ps-128 {
  padding-left: 12.8rem !important; }

.ps-127 {
  padding-left: 12.7rem !important; }

.ps-126 {
  padding-left: 12.6rem !important; }

.ps-125 {
  padding-left: 12.5rem !important; }

.ps-124 {
  padding-left: 12.4rem !important; }

.ps-123 {
  padding-left: 12.3rem !important; }

.ps-122 {
  padding-left: 12.2rem !important; }

.ps-121 {
  padding-left: 12.1rem !important; }

.ps-120 {
  padding-left: 12rem !important; }

.ps-119 {
  padding-left: 11.9rem !important; }

.ps-118 {
  padding-left: 11.8rem !important; }

.ps-117 {
  padding-left: 11.7rem !important; }

.ps-116 {
  padding-left: 11.6rem !important; }

.ps-115 {
  padding-left: 11.5rem !important; }

.ps-114 {
  padding-left: 11.4rem !important; }

.ps-113 {
  padding-left: 11.3rem !important; }

.ps-112 {
  padding-left: 11.2rem !important; }

.ps-111 {
  padding-left: 11.1rem !important; }

.ps-110 {
  padding-left: 11rem !important; }

.ps-109 {
  padding-left: 10.9rem !important; }

.ps-108 {
  padding-left: 10.8rem !important; }

.ps-107 {
  padding-left: 10.7rem !important; }

.ps-106 {
  padding-left: 10.6rem !important; }

.ps-105 {
  padding-left: 10.5rem !important; }

.ps-104 {
  padding-left: 10.4rem !important; }

.ps-103 {
  padding-left: 10.3rem !important; }

.ps-102 {
  padding-left: 10.2rem !important; }

.ps-101 {
  padding-left: 10.1rem !important; }

.ps-100 {
  padding-left: 10rem !important; }

.ps-99 {
  padding-left: 9.9rem !important; }

.ps-98 {
  padding-left: 9.8rem !important; }

.ps-97 {
  padding-left: 9.7rem !important; }

.ps-96 {
  padding-left: 9.6rem !important; }

.ps-95 {
  padding-left: 9.5rem !important; }

.ps-94 {
  padding-left: 9.4rem !important; }

.ps-93 {
  padding-left: 9.3rem !important; }

.ps-92 {
  padding-left: 9.2rem !important; }

.ps-91 {
  padding-left: 9.1rem !important; }

.ps-90 {
  padding-left: 9rem !important; }

.ps-89 {
  padding-left: 8.9rem !important; }

.ps-88 {
  padding-left: 8.8rem !important; }

.ps-87 {
  padding-left: 8.7rem !important; }

.ps-86 {
  padding-left: 8.6rem !important; }

.ps-85 {
  padding-left: 8.5rem !important; }

.ps-84 {
  padding-left: 8.4rem !important; }

.ps-83 {
  padding-left: 8.3rem !important; }

.ps-82 {
  padding-left: 8.2rem !important; }

.ps-81 {
  padding-left: 8.1rem !important; }

.ps-80 {
  padding-left: 8rem !important; }

.ps-79 {
  padding-left: 7.9rem !important; }

.ps-78 {
  padding-left: 7.8rem !important; }

.ps-77 {
  padding-left: 7.7rem !important; }

.ps-76 {
  padding-left: 7.6rem !important; }

.ps-75 {
  padding-left: 7.5rem !important; }

.ps-74 {
  padding-left: 7.4rem !important; }

.ps-73 {
  padding-left: 7.3rem !important; }

.ps-72 {
  padding-left: 7.2rem !important; }

.ps-71 {
  padding-left: 7.1rem !important; }

.ps-70 {
  padding-left: 7rem !important; }

.ps-69 {
  padding-left: 6.9rem !important; }

.ps-68 {
  padding-left: 6.8rem !important; }

.ps-67 {
  padding-left: 6.7rem !important; }

.ps-66 {
  padding-left: 6.6rem !important; }

.ps-65 {
  padding-left: 6.5rem !important; }

.ps-64 {
  padding-left: 6.4rem !important; }

.ps-63 {
  padding-left: 6.3rem !important; }

.ps-62 {
  padding-left: 6.2rem !important; }

.ps-61 {
  padding-left: 6.1rem !important; }

.ps-60 {
  padding-left: 6rem !important; }

.ps-59 {
  padding-left: 5.9rem !important; }

.ps-58 {
  padding-left: 5.8rem !important; }

.ps-57 {
  padding-left: 5.7rem !important; }

.ps-56 {
  padding-left: 5.6rem !important; }

.ps-55 {
  padding-left: 5.5rem !important; }

.ps-54 {
  padding-left: 5.4rem !important; }

.ps-53 {
  padding-left: 5.3rem !important; }

.ps-52 {
  padding-left: 5.2rem !important; }

.ps-51 {
  padding-left: 5.1rem !important; }

.ps-50 {
  padding-left: 5rem !important; }

.ps-49 {
  padding-left: 4.9rem !important; }

.ps-48 {
  padding-left: 4.8rem !important; }

.ps-47 {
  padding-left: 4.7rem !important; }

.ps-46 {
  padding-left: 4.6rem !important; }

.ps-45 {
  padding-left: 4.5rem !important; }

.ps-44 {
  padding-left: 4.4rem !important; }

.ps-43 {
  padding-left: 4.3rem !important; }

.ps-42 {
  padding-left: 4.2rem !important; }

.ps-41 {
  padding-left: 4.1rem !important; }

.ps-40 {
  padding-left: 4rem !important; }

.ps-39 {
  padding-left: 3.9rem !important; }

.ps-38 {
  padding-left: 3.8rem !important; }

.ps-37 {
  padding-left: 3.7rem !important; }

.ps-36 {
  padding-left: 3.6rem !important; }

.ps-35 {
  padding-left: 3.5rem !important; }

.ps-34 {
  padding-left: 3.4rem !important; }

.ps-33 {
  padding-left: 3.3rem !important; }

.ps-32 {
  padding-left: 3.2rem !important; }

.ps-31 {
  padding-left: 3.1rem !important; }

.ps-30 {
  padding-left: 3rem !important; }

.ps-29 {
  padding-left: 2.9rem !important; }

.ps-28 {
  padding-left: 2.8rem !important; }

.ps-27 {
  padding-left: 2.7rem !important; }

.ps-26 {
  padding-left: 2.6rem !important; }

.ps-25 {
  padding-left: 2.5rem !important; }

.ps-24 {
  padding-left: 2.4rem !important; }

.ps-23 {
  padding-left: 2.3rem !important; }

.ps-22 {
  padding-left: 2.2rem !important; }

.ps-21 {
  padding-left: 2.1rem !important; }

.ps-20 {
  padding-left: 2rem !important; }

.ps-19 {
  padding-left: 1.9rem !important; }

.ps-18 {
  padding-left: 1.8rem !important; }

.ps-17 {
  padding-left: 1.7rem !important; }

.ps-16 {
  padding-left: 1.6rem !important; }

.ps-15 {
  padding-left: 1.5rem !important; }

.ps-14 {
  padding-left: 1.4rem !important; }

.ps-13 {
  padding-left: 1.3rem !important; }

.ps-12 {
  padding-left: 1.2rem !important; }

.ps-11 {
  padding-left: 1.1rem !important; }

.ps-10 {
  padding-left: 1rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.ps-n80 {
  padding-left: -80px !important; }

.ps-n100 {
  padding-left: -100px !important; }

.ps-n150 {
  padding-left: -150px !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-45 {
  font-size: 4.5rem !important; }

.fs-44 {
  font-size: 4.4rem !important; }

.fs-43 {
  font-size: 4.3rem !important; }

.fs-42 {
  font-size: 4.2rem !important; }

.fs-41 {
  font-size: 4.1rem !important; }

.fs-40 {
  font-size: 4rem !important; }

.fs-39 {
  font-size: 3.9rem !important; }

.fs-38 {
  font-size: 3.8rem !important; }

.fs-37 {
  font-size: 3.7rem !important; }

.fs-36 {
  font-size: 3.6rem !important; }

.fs-35 {
  font-size: 3.5rem !important; }

.fs-34 {
  font-size: 3.4rem !important; }

.fs-33 {
  font-size: 3.3rem !important; }

.fs-32 {
  font-size: 3.2rem !important; }

.fs-31 {
  font-size: 3.1rem !important; }

.fs-30 {
  font-size: 3rem !important; }

.fs-29 {
  font-size: 2.9rem !important; }

.fs-28 {
  font-size: 2.8rem !important; }

.fs-27 {
  font-size: 2.7rem !important; }

.fs-26 {
  font-size: 2.6rem !important; }

.fs-25 {
  font-size: 2.5rem !important; }

.fs-24 {
  font-size: 2.4rem !important; }

.fs-23 {
  font-size: 2.3rem !important; }

.fs-22 {
  font-size: 2.2rem !important; }

.fs-21 {
  font-size: 2.1rem !important; }

.fs-20 {
  font-size: 2rem !important; }

.fs-19 {
  font-size: 1.9rem !important; }

.fs-18 {
  font-size: 1.8rem !important; }

.fs-17 {
  font-size: 1.7rem !important; }

.fs-16 {
  font-size: 1.6rem !important; }

.fs-15 {
  font-size: 1.5rem !important; }

.fs-14 {
  font-size: 1.4rem !important; }

.fs-13 {
  font-size: 1.3rem !important; }

.fs-12 {
  font-size: 1.2rem !important; }

.fs-11 {
  font-size: 1.1rem !important; }

.fs-10 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-semi-bold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.lh-150 {
  line-height: 15rem !important; }

.lh-149 {
  line-height: 14.9rem !important; }

.lh-148 {
  line-height: 14.8rem !important; }

.lh-147 {
  line-height: 14.7rem !important; }

.lh-146 {
  line-height: 14.6rem !important; }

.lh-145 {
  line-height: 14.5rem !important; }

.lh-144 {
  line-height: 14.4rem !important; }

.lh-143 {
  line-height: 14.3rem !important; }

.lh-142 {
  line-height: 14.2rem !important; }

.lh-141 {
  line-height: 14.1rem !important; }

.lh-140 {
  line-height: 14rem !important; }

.lh-139 {
  line-height: 13.9rem !important; }

.lh-138 {
  line-height: 13.8rem !important; }

.lh-137 {
  line-height: 13.7rem !important; }

.lh-136 {
  line-height: 13.6rem !important; }

.lh-135 {
  line-height: 13.5rem !important; }

.lh-134 {
  line-height: 13.4rem !important; }

.lh-133 {
  line-height: 13.3rem !important; }

.lh-132 {
  line-height: 13.2rem !important; }

.lh-131 {
  line-height: 13.1rem !important; }

.lh-130 {
  line-height: 13rem !important; }

.lh-129 {
  line-height: 12.9rem !important; }

.lh-128 {
  line-height: 12.8rem !important; }

.lh-127 {
  line-height: 12.7rem !important; }

.lh-126 {
  line-height: 12.6rem !important; }

.lh-125 {
  line-height: 12.5rem !important; }

.lh-124 {
  line-height: 12.4rem !important; }

.lh-123 {
  line-height: 12.3rem !important; }

.lh-122 {
  line-height: 12.2rem !important; }

.lh-121 {
  line-height: 12.1rem !important; }

.lh-120 {
  line-height: 12rem !important; }

.lh-119 {
  line-height: 11.9rem !important; }

.lh-118 {
  line-height: 11.8rem !important; }

.lh-117 {
  line-height: 11.7rem !important; }

.lh-116 {
  line-height: 11.6rem !important; }

.lh-115 {
  line-height: 11.5rem !important; }

.lh-114 {
  line-height: 11.4rem !important; }

.lh-113 {
  line-height: 11.3rem !important; }

.lh-112 {
  line-height: 11.2rem !important; }

.lh-111 {
  line-height: 11.1rem !important; }

.lh-110 {
  line-height: 11rem !important; }

.lh-109 {
  line-height: 10.9rem !important; }

.lh-108 {
  line-height: 10.8rem !important; }

.lh-107 {
  line-height: 10.7rem !important; }

.lh-106 {
  line-height: 10.6rem !important; }

.lh-105 {
  line-height: 10.5rem !important; }

.lh-104 {
  line-height: 10.4rem !important; }

.lh-103 {
  line-height: 10.3rem !important; }

.lh-102 {
  line-height: 10.2rem !important; }

.lh-101 {
  line-height: 10.1rem !important; }

.lh-100 {
  line-height: 10rem !important; }

.lh-99 {
  line-height: 9.9rem !important; }

.lh-98 {
  line-height: 9.8rem !important; }

.lh-97 {
  line-height: 9.7rem !important; }

.lh-96 {
  line-height: 9.6rem !important; }

.lh-95 {
  line-height: 9.5rem !important; }

.lh-94 {
  line-height: 9.4rem !important; }

.lh-93 {
  line-height: 9.3rem !important; }

.lh-92 {
  line-height: 9.2rem !important; }

.lh-91 {
  line-height: 9.1rem !important; }

.lh-90 {
  line-height: 9rem !important; }

.lh-89 {
  line-height: 8.9rem !important; }

.lh-88 {
  line-height: 8.8rem !important; }

.lh-87 {
  line-height: 8.7rem !important; }

.lh-86 {
  line-height: 8.6rem !important; }

.lh-85 {
  line-height: 8.5rem !important; }

.lh-84 {
  line-height: 8.4rem !important; }

.lh-83 {
  line-height: 8.3rem !important; }

.lh-82 {
  line-height: 8.2rem !important; }

.lh-81 {
  line-height: 8.1rem !important; }

.lh-80 {
  line-height: 8rem !important; }

.lh-79 {
  line-height: 7.9rem !important; }

.lh-78 {
  line-height: 7.8rem !important; }

.lh-77 {
  line-height: 7.7rem !important; }

.lh-76 {
  line-height: 7.6rem !important; }

.lh-75 {
  line-height: 7.5rem !important; }

.lh-74 {
  line-height: 7.4rem !important; }

.lh-73 {
  line-height: 7.3rem !important; }

.lh-72 {
  line-height: 7.2rem !important; }

.lh-71 {
  line-height: 7.1rem !important; }

.lh-70 {
  line-height: 7rem !important; }

.lh-69 {
  line-height: 6.9rem !important; }

.lh-68 {
  line-height: 6.8rem !important; }

.lh-67 {
  line-height: 6.7rem !important; }

.lh-66 {
  line-height: 6.6rem !important; }

.lh-65 {
  line-height: 6.5rem !important; }

.lh-64 {
  line-height: 6.4rem !important; }

.lh-63 {
  line-height: 6.3rem !important; }

.lh-62 {
  line-height: 6.2rem !important; }

.lh-61 {
  line-height: 6.1rem !important; }

.lh-60 {
  line-height: 6rem !important; }

.lh-59 {
  line-height: 5.9rem !important; }

.lh-58 {
  line-height: 5.8rem !important; }

.lh-57 {
  line-height: 5.7rem !important; }

.lh-56 {
  line-height: 5.6rem !important; }

.lh-55 {
  line-height: 5.5rem !important; }

.lh-54 {
  line-height: 5.4rem !important; }

.lh-53 {
  line-height: 5.3rem !important; }

.lh-52 {
  line-height: 5.2rem !important; }

.lh-51 {
  line-height: 5.1rem !important; }

.lh-50 {
  line-height: 5rem !important; }

.lh-49 {
  line-height: 4.9rem !important; }

.lh-48 {
  line-height: 4.8rem !important; }

.lh-47 {
  line-height: 4.7rem !important; }

.lh-46 {
  line-height: 4.6rem !important; }

.lh-45 {
  line-height: 4.5rem !important; }

.lh-44 {
  line-height: 4.4rem !important; }

.lh-43 {
  line-height: 4.3rem !important; }

.lh-42 {
  line-height: 4.2rem !important; }

.lh-41 {
  line-height: 4.1rem !important; }

.lh-40 {
  line-height: 4rem !important; }

.lh-39 {
  line-height: 3.9rem !important; }

.lh-38 {
  line-height: 3.8rem !important; }

.lh-37 {
  line-height: 3.7rem !important; }

.lh-36 {
  line-height: 3.6rem !important; }

.lh-35 {
  line-height: 3.5rem !important; }

.lh-34 {
  line-height: 3.4rem !important; }

.lh-33 {
  line-height: 3.3rem !important; }

.lh-32 {
  line-height: 3.2rem !important; }

.lh-31 {
  line-height: 3.1rem !important; }

.lh-30 {
  line-height: 3rem !important; }

.lh-29 {
  line-height: 2.9rem !important; }

.lh-28 {
  line-height: 2.8rem !important; }

.lh-27 {
  line-height: 2.7rem !important; }

.lh-26 {
  line-height: 2.6rem !important; }

.lh-25 {
  line-height: 2.5rem !important; }

.lh-24 {
  line-height: 2.4rem !important; }

.lh-23 {
  line-height: 2.3rem !important; }

.lh-22 {
  line-height: 2.2rem !important; }

.lh-21 {
  line-height: 2.1rem !important; }

.lh-20 {
  line-height: 2rem !important; }

.lh-19 {
  line-height: 1.9rem !important; }

.lh-18 {
  line-height: 1.8rem !important; }

.lh-17 {
  line-height: 1.7rem !important; }

.lh-16 {
  line-height: 1.6rem !important; }

.lh-15 {
  line-height: 1.5rem !important; }

.lh-14 {
  line-height: 1.4rem !important; }

.lh-13 {
  line-height: 1.3rem !important; }

.lh-12 {
  line-height: 1.2rem !important; }

.lh-11 {
  line-height: 1.1rem !important; }

.lh-10 {
  line-height: 1rem !important; }

.lh-0 {
  line-height: 0 !important; }

.lh-1 {
  line-height: 0.25rem !important; }

.lh-2 {
  line-height: 0.5rem !important; }

.lh-3 {
  line-height: 1rem !important; }

.lh-4 {
  line-height: 1.5rem !important; }

.lh-5 {
  line-height: 3rem !important; }

.lh-n80 {
  line-height: -80px !important; }

.lh-n100 {
  line-height: -100px !important; }

.lh-n150 {
  line-height: -150px !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-100-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-mobile {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-mobile-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-prefooter {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-prefooter-rgb), var(--bs-text-opacity)) !important; }

.text-footer {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-footer-rgb), var(--bs-text-opacity)) !important; }

.text-error {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-error-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important; }

.text-bleu {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-bleu-rgb), var(--bs-text-opacity)) !important; }

.text-gray900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray900-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-mobile {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-mobile-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-prefooter {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-prefooter-rgb), var(--bs-bg-opacity)) !important; }

.bg-footer {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-footer-rgb), var(--bs-bg-opacity)) !important; }

.bg-error {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-error-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important; }

.bg-bleu {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-bleu-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray900-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.8rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.4rem !important; }

.rounded-2 {
  border-radius: 0.8rem !important; }

.rounded-3 {
  border-radius: 1rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-top {
  border-top-left-radius: 0.8rem !important;
  border-top-right-radius: 0.8rem !important; }

.rounded-end {
  border-top-right-radius: 0.8rem !important;
  border-bottom-right-radius: 0.8rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.8rem !important;
  border-bottom-left-radius: 0.8rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.8rem !important;
  border-top-left-radius: 0.8rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .position-sm-static {
    position: static !important; }
  .position-sm-relative {
    position: relative !important; }
  .position-sm-absolute {
    position: absolute !important; }
  .position-sm-fixed {
    position: fixed !important; }
  .position-sm-sticky {
    position: sticky !important; }
  .w-sm-20 {
    width: 20% !important; }
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-45 {
    width: 45% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-55 {
    width: 55% !important; }
  .w-sm-60 {
    width: 60% !important; }
  .w-sm-65 {
    width: 65% !important; }
  .w-sm-70 {
    width: 70% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-80 {
    width: 80% !important; }
  .w-sm-90 {
    width: 90% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .w-sm-50px {
    width: 50px !important; }
  .w-sm-60px {
    width: 60px !important; }
  .w-sm-90px {
    width: 90px !important; }
  .w-sm-100px {
    width: 100px !important; }
  .w-sm-118px {
    width: 118px !important; }
  .w-sm-158px {
    width: 158px !important; }
  .w-sm-174px {
    width: 174px !important; }
  .w-sm-250px {
    width: 250px !important; }
  .h-sm-20 {
    height: 20% !important; }
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-45 {
    height: 45% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-55 {
    height: 55% !important; }
  .h-sm-60 {
    height: 60% !important; }
  .h-sm-65 {
    height: 65% !important; }
  .h-sm-70 {
    height: 70% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-80 {
    height: 80% !important; }
  .h-sm-90 {
    height: 90% !important; }
  .h-sm-100 {
    height: 100% !important; }
  .h-sm-auto {
    height: auto !important; }
  .h-sm-50px {
    height: 50px !important; }
  .h-sm-60px {
    height: 60px !important; }
  .h-sm-90px {
    height: 90px !important; }
  .h-sm-100px {
    height: 100px !important; }
  .h-sm-118px {
    height: 118px !important; }
  .h-sm-158px {
    height: 158px !important; }
  .h-sm-174px {
    height: 174px !important; }
  .h-sm-250px {
    height: 250px !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-150 {
    gap: 15rem !important; }
  .gap-sm-149 {
    gap: 14.9rem !important; }
  .gap-sm-148 {
    gap: 14.8rem !important; }
  .gap-sm-147 {
    gap: 14.7rem !important; }
  .gap-sm-146 {
    gap: 14.6rem !important; }
  .gap-sm-145 {
    gap: 14.5rem !important; }
  .gap-sm-144 {
    gap: 14.4rem !important; }
  .gap-sm-143 {
    gap: 14.3rem !important; }
  .gap-sm-142 {
    gap: 14.2rem !important; }
  .gap-sm-141 {
    gap: 14.1rem !important; }
  .gap-sm-140 {
    gap: 14rem !important; }
  .gap-sm-139 {
    gap: 13.9rem !important; }
  .gap-sm-138 {
    gap: 13.8rem !important; }
  .gap-sm-137 {
    gap: 13.7rem !important; }
  .gap-sm-136 {
    gap: 13.6rem !important; }
  .gap-sm-135 {
    gap: 13.5rem !important; }
  .gap-sm-134 {
    gap: 13.4rem !important; }
  .gap-sm-133 {
    gap: 13.3rem !important; }
  .gap-sm-132 {
    gap: 13.2rem !important; }
  .gap-sm-131 {
    gap: 13.1rem !important; }
  .gap-sm-130 {
    gap: 13rem !important; }
  .gap-sm-129 {
    gap: 12.9rem !important; }
  .gap-sm-128 {
    gap: 12.8rem !important; }
  .gap-sm-127 {
    gap: 12.7rem !important; }
  .gap-sm-126 {
    gap: 12.6rem !important; }
  .gap-sm-125 {
    gap: 12.5rem !important; }
  .gap-sm-124 {
    gap: 12.4rem !important; }
  .gap-sm-123 {
    gap: 12.3rem !important; }
  .gap-sm-122 {
    gap: 12.2rem !important; }
  .gap-sm-121 {
    gap: 12.1rem !important; }
  .gap-sm-120 {
    gap: 12rem !important; }
  .gap-sm-119 {
    gap: 11.9rem !important; }
  .gap-sm-118 {
    gap: 11.8rem !important; }
  .gap-sm-117 {
    gap: 11.7rem !important; }
  .gap-sm-116 {
    gap: 11.6rem !important; }
  .gap-sm-115 {
    gap: 11.5rem !important; }
  .gap-sm-114 {
    gap: 11.4rem !important; }
  .gap-sm-113 {
    gap: 11.3rem !important; }
  .gap-sm-112 {
    gap: 11.2rem !important; }
  .gap-sm-111 {
    gap: 11.1rem !important; }
  .gap-sm-110 {
    gap: 11rem !important; }
  .gap-sm-109 {
    gap: 10.9rem !important; }
  .gap-sm-108 {
    gap: 10.8rem !important; }
  .gap-sm-107 {
    gap: 10.7rem !important; }
  .gap-sm-106 {
    gap: 10.6rem !important; }
  .gap-sm-105 {
    gap: 10.5rem !important; }
  .gap-sm-104 {
    gap: 10.4rem !important; }
  .gap-sm-103 {
    gap: 10.3rem !important; }
  .gap-sm-102 {
    gap: 10.2rem !important; }
  .gap-sm-101 {
    gap: 10.1rem !important; }
  .gap-sm-100 {
    gap: 10rem !important; }
  .gap-sm-99 {
    gap: 9.9rem !important; }
  .gap-sm-98 {
    gap: 9.8rem !important; }
  .gap-sm-97 {
    gap: 9.7rem !important; }
  .gap-sm-96 {
    gap: 9.6rem !important; }
  .gap-sm-95 {
    gap: 9.5rem !important; }
  .gap-sm-94 {
    gap: 9.4rem !important; }
  .gap-sm-93 {
    gap: 9.3rem !important; }
  .gap-sm-92 {
    gap: 9.2rem !important; }
  .gap-sm-91 {
    gap: 9.1rem !important; }
  .gap-sm-90 {
    gap: 9rem !important; }
  .gap-sm-89 {
    gap: 8.9rem !important; }
  .gap-sm-88 {
    gap: 8.8rem !important; }
  .gap-sm-87 {
    gap: 8.7rem !important; }
  .gap-sm-86 {
    gap: 8.6rem !important; }
  .gap-sm-85 {
    gap: 8.5rem !important; }
  .gap-sm-84 {
    gap: 8.4rem !important; }
  .gap-sm-83 {
    gap: 8.3rem !important; }
  .gap-sm-82 {
    gap: 8.2rem !important; }
  .gap-sm-81 {
    gap: 8.1rem !important; }
  .gap-sm-80 {
    gap: 8rem !important; }
  .gap-sm-79 {
    gap: 7.9rem !important; }
  .gap-sm-78 {
    gap: 7.8rem !important; }
  .gap-sm-77 {
    gap: 7.7rem !important; }
  .gap-sm-76 {
    gap: 7.6rem !important; }
  .gap-sm-75 {
    gap: 7.5rem !important; }
  .gap-sm-74 {
    gap: 7.4rem !important; }
  .gap-sm-73 {
    gap: 7.3rem !important; }
  .gap-sm-72 {
    gap: 7.2rem !important; }
  .gap-sm-71 {
    gap: 7.1rem !important; }
  .gap-sm-70 {
    gap: 7rem !important; }
  .gap-sm-69 {
    gap: 6.9rem !important; }
  .gap-sm-68 {
    gap: 6.8rem !important; }
  .gap-sm-67 {
    gap: 6.7rem !important; }
  .gap-sm-66 {
    gap: 6.6rem !important; }
  .gap-sm-65 {
    gap: 6.5rem !important; }
  .gap-sm-64 {
    gap: 6.4rem !important; }
  .gap-sm-63 {
    gap: 6.3rem !important; }
  .gap-sm-62 {
    gap: 6.2rem !important; }
  .gap-sm-61 {
    gap: 6.1rem !important; }
  .gap-sm-60 {
    gap: 6rem !important; }
  .gap-sm-59 {
    gap: 5.9rem !important; }
  .gap-sm-58 {
    gap: 5.8rem !important; }
  .gap-sm-57 {
    gap: 5.7rem !important; }
  .gap-sm-56 {
    gap: 5.6rem !important; }
  .gap-sm-55 {
    gap: 5.5rem !important; }
  .gap-sm-54 {
    gap: 5.4rem !important; }
  .gap-sm-53 {
    gap: 5.3rem !important; }
  .gap-sm-52 {
    gap: 5.2rem !important; }
  .gap-sm-51 {
    gap: 5.1rem !important; }
  .gap-sm-50 {
    gap: 5rem !important; }
  .gap-sm-49 {
    gap: 4.9rem !important; }
  .gap-sm-48 {
    gap: 4.8rem !important; }
  .gap-sm-47 {
    gap: 4.7rem !important; }
  .gap-sm-46 {
    gap: 4.6rem !important; }
  .gap-sm-45 {
    gap: 4.5rem !important; }
  .gap-sm-44 {
    gap: 4.4rem !important; }
  .gap-sm-43 {
    gap: 4.3rem !important; }
  .gap-sm-42 {
    gap: 4.2rem !important; }
  .gap-sm-41 {
    gap: 4.1rem !important; }
  .gap-sm-40 {
    gap: 4rem !important; }
  .gap-sm-39 {
    gap: 3.9rem !important; }
  .gap-sm-38 {
    gap: 3.8rem !important; }
  .gap-sm-37 {
    gap: 3.7rem !important; }
  .gap-sm-36 {
    gap: 3.6rem !important; }
  .gap-sm-35 {
    gap: 3.5rem !important; }
  .gap-sm-34 {
    gap: 3.4rem !important; }
  .gap-sm-33 {
    gap: 3.3rem !important; }
  .gap-sm-32 {
    gap: 3.2rem !important; }
  .gap-sm-31 {
    gap: 3.1rem !important; }
  .gap-sm-30 {
    gap: 3rem !important; }
  .gap-sm-29 {
    gap: 2.9rem !important; }
  .gap-sm-28 {
    gap: 2.8rem !important; }
  .gap-sm-27 {
    gap: 2.7rem !important; }
  .gap-sm-26 {
    gap: 2.6rem !important; }
  .gap-sm-25 {
    gap: 2.5rem !important; }
  .gap-sm-24 {
    gap: 2.4rem !important; }
  .gap-sm-23 {
    gap: 2.3rem !important; }
  .gap-sm-22 {
    gap: 2.2rem !important; }
  .gap-sm-21 {
    gap: 2.1rem !important; }
  .gap-sm-20 {
    gap: 2rem !important; }
  .gap-sm-19 {
    gap: 1.9rem !important; }
  .gap-sm-18 {
    gap: 1.8rem !important; }
  .gap-sm-17 {
    gap: 1.7rem !important; }
  .gap-sm-16 {
    gap: 1.6rem !important; }
  .gap-sm-15 {
    gap: 1.5rem !important; }
  .gap-sm-14 {
    gap: 1.4rem !important; }
  .gap-sm-13 {
    gap: 1.3rem !important; }
  .gap-sm-12 {
    gap: 1.2rem !important; }
  .gap-sm-11 {
    gap: 1.1rem !important; }
  .gap-sm-10 {
    gap: 1rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .gap-sm-n80 {
    gap: -80px !important; }
  .gap-sm-n100 {
    gap: -100px !important; }
  .gap-sm-n150 {
    gap: -150px !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-150 {
    margin: 15rem !important; }
  .m-sm-149 {
    margin: 14.9rem !important; }
  .m-sm-148 {
    margin: 14.8rem !important; }
  .m-sm-147 {
    margin: 14.7rem !important; }
  .m-sm-146 {
    margin: 14.6rem !important; }
  .m-sm-145 {
    margin: 14.5rem !important; }
  .m-sm-144 {
    margin: 14.4rem !important; }
  .m-sm-143 {
    margin: 14.3rem !important; }
  .m-sm-142 {
    margin: 14.2rem !important; }
  .m-sm-141 {
    margin: 14.1rem !important; }
  .m-sm-140 {
    margin: 14rem !important; }
  .m-sm-139 {
    margin: 13.9rem !important; }
  .m-sm-138 {
    margin: 13.8rem !important; }
  .m-sm-137 {
    margin: 13.7rem !important; }
  .m-sm-136 {
    margin: 13.6rem !important; }
  .m-sm-135 {
    margin: 13.5rem !important; }
  .m-sm-134 {
    margin: 13.4rem !important; }
  .m-sm-133 {
    margin: 13.3rem !important; }
  .m-sm-132 {
    margin: 13.2rem !important; }
  .m-sm-131 {
    margin: 13.1rem !important; }
  .m-sm-130 {
    margin: 13rem !important; }
  .m-sm-129 {
    margin: 12.9rem !important; }
  .m-sm-128 {
    margin: 12.8rem !important; }
  .m-sm-127 {
    margin: 12.7rem !important; }
  .m-sm-126 {
    margin: 12.6rem !important; }
  .m-sm-125 {
    margin: 12.5rem !important; }
  .m-sm-124 {
    margin: 12.4rem !important; }
  .m-sm-123 {
    margin: 12.3rem !important; }
  .m-sm-122 {
    margin: 12.2rem !important; }
  .m-sm-121 {
    margin: 12.1rem !important; }
  .m-sm-120 {
    margin: 12rem !important; }
  .m-sm-119 {
    margin: 11.9rem !important; }
  .m-sm-118 {
    margin: 11.8rem !important; }
  .m-sm-117 {
    margin: 11.7rem !important; }
  .m-sm-116 {
    margin: 11.6rem !important; }
  .m-sm-115 {
    margin: 11.5rem !important; }
  .m-sm-114 {
    margin: 11.4rem !important; }
  .m-sm-113 {
    margin: 11.3rem !important; }
  .m-sm-112 {
    margin: 11.2rem !important; }
  .m-sm-111 {
    margin: 11.1rem !important; }
  .m-sm-110 {
    margin: 11rem !important; }
  .m-sm-109 {
    margin: 10.9rem !important; }
  .m-sm-108 {
    margin: 10.8rem !important; }
  .m-sm-107 {
    margin: 10.7rem !important; }
  .m-sm-106 {
    margin: 10.6rem !important; }
  .m-sm-105 {
    margin: 10.5rem !important; }
  .m-sm-104 {
    margin: 10.4rem !important; }
  .m-sm-103 {
    margin: 10.3rem !important; }
  .m-sm-102 {
    margin: 10.2rem !important; }
  .m-sm-101 {
    margin: 10.1rem !important; }
  .m-sm-100 {
    margin: 10rem !important; }
  .m-sm-99 {
    margin: 9.9rem !important; }
  .m-sm-98 {
    margin: 9.8rem !important; }
  .m-sm-97 {
    margin: 9.7rem !important; }
  .m-sm-96 {
    margin: 9.6rem !important; }
  .m-sm-95 {
    margin: 9.5rem !important; }
  .m-sm-94 {
    margin: 9.4rem !important; }
  .m-sm-93 {
    margin: 9.3rem !important; }
  .m-sm-92 {
    margin: 9.2rem !important; }
  .m-sm-91 {
    margin: 9.1rem !important; }
  .m-sm-90 {
    margin: 9rem !important; }
  .m-sm-89 {
    margin: 8.9rem !important; }
  .m-sm-88 {
    margin: 8.8rem !important; }
  .m-sm-87 {
    margin: 8.7rem !important; }
  .m-sm-86 {
    margin: 8.6rem !important; }
  .m-sm-85 {
    margin: 8.5rem !important; }
  .m-sm-84 {
    margin: 8.4rem !important; }
  .m-sm-83 {
    margin: 8.3rem !important; }
  .m-sm-82 {
    margin: 8.2rem !important; }
  .m-sm-81 {
    margin: 8.1rem !important; }
  .m-sm-80 {
    margin: 8rem !important; }
  .m-sm-79 {
    margin: 7.9rem !important; }
  .m-sm-78 {
    margin: 7.8rem !important; }
  .m-sm-77 {
    margin: 7.7rem !important; }
  .m-sm-76 {
    margin: 7.6rem !important; }
  .m-sm-75 {
    margin: 7.5rem !important; }
  .m-sm-74 {
    margin: 7.4rem !important; }
  .m-sm-73 {
    margin: 7.3rem !important; }
  .m-sm-72 {
    margin: 7.2rem !important; }
  .m-sm-71 {
    margin: 7.1rem !important; }
  .m-sm-70 {
    margin: 7rem !important; }
  .m-sm-69 {
    margin: 6.9rem !important; }
  .m-sm-68 {
    margin: 6.8rem !important; }
  .m-sm-67 {
    margin: 6.7rem !important; }
  .m-sm-66 {
    margin: 6.6rem !important; }
  .m-sm-65 {
    margin: 6.5rem !important; }
  .m-sm-64 {
    margin: 6.4rem !important; }
  .m-sm-63 {
    margin: 6.3rem !important; }
  .m-sm-62 {
    margin: 6.2rem !important; }
  .m-sm-61 {
    margin: 6.1rem !important; }
  .m-sm-60 {
    margin: 6rem !important; }
  .m-sm-59 {
    margin: 5.9rem !important; }
  .m-sm-58 {
    margin: 5.8rem !important; }
  .m-sm-57 {
    margin: 5.7rem !important; }
  .m-sm-56 {
    margin: 5.6rem !important; }
  .m-sm-55 {
    margin: 5.5rem !important; }
  .m-sm-54 {
    margin: 5.4rem !important; }
  .m-sm-53 {
    margin: 5.3rem !important; }
  .m-sm-52 {
    margin: 5.2rem !important; }
  .m-sm-51 {
    margin: 5.1rem !important; }
  .m-sm-50 {
    margin: 5rem !important; }
  .m-sm-49 {
    margin: 4.9rem !important; }
  .m-sm-48 {
    margin: 4.8rem !important; }
  .m-sm-47 {
    margin: 4.7rem !important; }
  .m-sm-46 {
    margin: 4.6rem !important; }
  .m-sm-45 {
    margin: 4.5rem !important; }
  .m-sm-44 {
    margin: 4.4rem !important; }
  .m-sm-43 {
    margin: 4.3rem !important; }
  .m-sm-42 {
    margin: 4.2rem !important; }
  .m-sm-41 {
    margin: 4.1rem !important; }
  .m-sm-40 {
    margin: 4rem !important; }
  .m-sm-39 {
    margin: 3.9rem !important; }
  .m-sm-38 {
    margin: 3.8rem !important; }
  .m-sm-37 {
    margin: 3.7rem !important; }
  .m-sm-36 {
    margin: 3.6rem !important; }
  .m-sm-35 {
    margin: 3.5rem !important; }
  .m-sm-34 {
    margin: 3.4rem !important; }
  .m-sm-33 {
    margin: 3.3rem !important; }
  .m-sm-32 {
    margin: 3.2rem !important; }
  .m-sm-31 {
    margin: 3.1rem !important; }
  .m-sm-30 {
    margin: 3rem !important; }
  .m-sm-29 {
    margin: 2.9rem !important; }
  .m-sm-28 {
    margin: 2.8rem !important; }
  .m-sm-27 {
    margin: 2.7rem !important; }
  .m-sm-26 {
    margin: 2.6rem !important; }
  .m-sm-25 {
    margin: 2.5rem !important; }
  .m-sm-24 {
    margin: 2.4rem !important; }
  .m-sm-23 {
    margin: 2.3rem !important; }
  .m-sm-22 {
    margin: 2.2rem !important; }
  .m-sm-21 {
    margin: 2.1rem !important; }
  .m-sm-20 {
    margin: 2rem !important; }
  .m-sm-19 {
    margin: 1.9rem !important; }
  .m-sm-18 {
    margin: 1.8rem !important; }
  .m-sm-17 {
    margin: 1.7rem !important; }
  .m-sm-16 {
    margin: 1.6rem !important; }
  .m-sm-15 {
    margin: 1.5rem !important; }
  .m-sm-14 {
    margin: 1.4rem !important; }
  .m-sm-13 {
    margin: 1.3rem !important; }
  .m-sm-12 {
    margin: 1.2rem !important; }
  .m-sm-11 {
    margin: 1.1rem !important; }
  .m-sm-10 {
    margin: 1rem !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-n80 {
    margin: -80px !important; }
  .m-sm-n100 {
    margin: -100px !important; }
  .m-sm-n150 {
    margin: -150px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-150 {
    margin-right: 15rem !important;
    margin-left: 15rem !important; }
  .mx-sm-149 {
    margin-right: 14.9rem !important;
    margin-left: 14.9rem !important; }
  .mx-sm-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-sm-147 {
    margin-right: 14.7rem !important;
    margin-left: 14.7rem !important; }
  .mx-sm-146 {
    margin-right: 14.6rem !important;
    margin-left: 14.6rem !important; }
  .mx-sm-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-sm-144 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important; }
  .mx-sm-143 {
    margin-right: 14.3rem !important;
    margin-left: 14.3rem !important; }
  .mx-sm-142 {
    margin-right: 14.2rem !important;
    margin-left: 14.2rem !important; }
  .mx-sm-141 {
    margin-right: 14.1rem !important;
    margin-left: 14.1rem !important; }
  .mx-sm-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-sm-139 {
    margin-right: 13.9rem !important;
    margin-left: 13.9rem !important; }
  .mx-sm-138 {
    margin-right: 13.8rem !important;
    margin-left: 13.8rem !important; }
  .mx-sm-137 {
    margin-right: 13.7rem !important;
    margin-left: 13.7rem !important; }
  .mx-sm-136 {
    margin-right: 13.6rem !important;
    margin-left: 13.6rem !important; }
  .mx-sm-135 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important; }
  .mx-sm-134 {
    margin-right: 13.4rem !important;
    margin-left: 13.4rem !important; }
  .mx-sm-133 {
    margin-right: 13.3rem !important;
    margin-left: 13.3rem !important; }
  .mx-sm-132 {
    margin-right: 13.2rem !important;
    margin-left: 13.2rem !important; }
  .mx-sm-131 {
    margin-right: 13.1rem !important;
    margin-left: 13.1rem !important; }
  .mx-sm-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-sm-129 {
    margin-right: 12.9rem !important;
    margin-left: 12.9rem !important; }
  .mx-sm-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-sm-127 {
    margin-right: 12.7rem !important;
    margin-left: 12.7rem !important; }
  .mx-sm-126 {
    margin-right: 12.6rem !important;
    margin-left: 12.6rem !important; }
  .mx-sm-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-sm-124 {
    margin-right: 12.4rem !important;
    margin-left: 12.4rem !important; }
  .mx-sm-123 {
    margin-right: 12.3rem !important;
    margin-left: 12.3rem !important; }
  .mx-sm-122 {
    margin-right: 12.2rem !important;
    margin-left: 12.2rem !important; }
  .mx-sm-121 {
    margin-right: 12.1rem !important;
    margin-left: 12.1rem !important; }
  .mx-sm-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-sm-119 {
    margin-right: 11.9rem !important;
    margin-left: 11.9rem !important; }
  .mx-sm-118 {
    margin-right: 11.8rem !important;
    margin-left: 11.8rem !important; }
  .mx-sm-117 {
    margin-right: 11.7rem !important;
    margin-left: 11.7rem !important; }
  .mx-sm-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-sm-115 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-sm-114 {
    margin-right: 11.4rem !important;
    margin-left: 11.4rem !important; }
  .mx-sm-113 {
    margin-right: 11.3rem !important;
    margin-left: 11.3rem !important; }
  .mx-sm-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-sm-111 {
    margin-right: 11.1rem !important;
    margin-left: 11.1rem !important; }
  .mx-sm-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-sm-109 {
    margin-right: 10.9rem !important;
    margin-left: 10.9rem !important; }
  .mx-sm-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-sm-107 {
    margin-right: 10.7rem !important;
    margin-left: 10.7rem !important; }
  .mx-sm-106 {
    margin-right: 10.6rem !important;
    margin-left: 10.6rem !important; }
  .mx-sm-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-sm-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-sm-103 {
    margin-right: 10.3rem !important;
    margin-left: 10.3rem !important; }
  .mx-sm-102 {
    margin-right: 10.2rem !important;
    margin-left: 10.2rem !important; }
  .mx-sm-101 {
    margin-right: 10.1rem !important;
    margin-left: 10.1rem !important; }
  .mx-sm-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-sm-99 {
    margin-right: 9.9rem !important;
    margin-left: 9.9rem !important; }
  .mx-sm-98 {
    margin-right: 9.8rem !important;
    margin-left: 9.8rem !important; }
  .mx-sm-97 {
    margin-right: 9.7rem !important;
    margin-left: 9.7rem !important; }
  .mx-sm-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-sm-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-sm-94 {
    margin-right: 9.4rem !important;
    margin-left: 9.4rem !important; }
  .mx-sm-93 {
    margin-right: 9.3rem !important;
    margin-left: 9.3rem !important; }
  .mx-sm-92 {
    margin-right: 9.2rem !important;
    margin-left: 9.2rem !important; }
  .mx-sm-91 {
    margin-right: 9.1rem !important;
    margin-left: 9.1rem !important; }
  .mx-sm-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-sm-89 {
    margin-right: 8.9rem !important;
    margin-left: 8.9rem !important; }
  .mx-sm-88 {
    margin-right: 8.8rem !important;
    margin-left: 8.8rem !important; }
  .mx-sm-87 {
    margin-right: 8.7rem !important;
    margin-left: 8.7rem !important; }
  .mx-sm-86 {
    margin-right: 8.6rem !important;
    margin-left: 8.6rem !important; }
  .mx-sm-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-sm-84 {
    margin-right: 8.4rem !important;
    margin-left: 8.4rem !important; }
  .mx-sm-83 {
    margin-right: 8.3rem !important;
    margin-left: 8.3rem !important; }
  .mx-sm-82 {
    margin-right: 8.2rem !important;
    margin-left: 8.2rem !important; }
  .mx-sm-81 {
    margin-right: 8.1rem !important;
    margin-left: 8.1rem !important; }
  .mx-sm-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-sm-79 {
    margin-right: 7.9rem !important;
    margin-left: 7.9rem !important; }
  .mx-sm-78 {
    margin-right: 7.8rem !important;
    margin-left: 7.8rem !important; }
  .mx-sm-77 {
    margin-right: 7.7rem !important;
    margin-left: 7.7rem !important; }
  .mx-sm-76 {
    margin-right: 7.6rem !important;
    margin-left: 7.6rem !important; }
  .mx-sm-75 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-sm-74 {
    margin-right: 7.4rem !important;
    margin-left: 7.4rem !important; }
  .mx-sm-73 {
    margin-right: 7.3rem !important;
    margin-left: 7.3rem !important; }
  .mx-sm-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-sm-71 {
    margin-right: 7.1rem !important;
    margin-left: 7.1rem !important; }
  .mx-sm-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-sm-69 {
    margin-right: 6.9rem !important;
    margin-left: 6.9rem !important; }
  .mx-sm-68 {
    margin-right: 6.8rem !important;
    margin-left: 6.8rem !important; }
  .mx-sm-67 {
    margin-right: 6.7rem !important;
    margin-left: 6.7rem !important; }
  .mx-sm-66 {
    margin-right: 6.6rem !important;
    margin-left: 6.6rem !important; }
  .mx-sm-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-sm-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-sm-63 {
    margin-right: 6.3rem !important;
    margin-left: 6.3rem !important; }
  .mx-sm-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-sm-61 {
    margin-right: 6.1rem !important;
    margin-left: 6.1rem !important; }
  .mx-sm-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-sm-59 {
    margin-right: 5.9rem !important;
    margin-left: 5.9rem !important; }
  .mx-sm-58 {
    margin-right: 5.8rem !important;
    margin-left: 5.8rem !important; }
  .mx-sm-57 {
    margin-right: 5.7rem !important;
    margin-left: 5.7rem !important; }
  .mx-sm-56 {
    margin-right: 5.6rem !important;
    margin-left: 5.6rem !important; }
  .mx-sm-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-sm-54 {
    margin-right: 5.4rem !important;
    margin-left: 5.4rem !important; }
  .mx-sm-53 {
    margin-right: 5.3rem !important;
    margin-left: 5.3rem !important; }
  .mx-sm-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-sm-51 {
    margin-right: 5.1rem !important;
    margin-left: 5.1rem !important; }
  .mx-sm-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-sm-49 {
    margin-right: 4.9rem !important;
    margin-left: 4.9rem !important; }
  .mx-sm-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-sm-47 {
    margin-right: 4.7rem !important;
    margin-left: 4.7rem !important; }
  .mx-sm-46 {
    margin-right: 4.6rem !important;
    margin-left: 4.6rem !important; }
  .mx-sm-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-sm-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-sm-43 {
    margin-right: 4.3rem !important;
    margin-left: 4.3rem !important; }
  .mx-sm-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-sm-41 {
    margin-right: 4.1rem !important;
    margin-left: 4.1rem !important; }
  .mx-sm-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-sm-39 {
    margin-right: 3.9rem !important;
    margin-left: 3.9rem !important; }
  .mx-sm-38 {
    margin-right: 3.8rem !important;
    margin-left: 3.8rem !important; }
  .mx-sm-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-sm-36 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important; }
  .mx-sm-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-sm-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-sm-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-sm-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-sm-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-sm-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-sm-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-sm-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-sm-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-sm-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-sm-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-sm-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-sm-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-sm-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-sm-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-sm-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-sm-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-sm-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-sm-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-sm-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-sm-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-sm-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-sm-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-sm-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important; }
  .mx-sm-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important; }
  .mx-sm-n150 {
    margin-right: -150px !important;
    margin-left: -150px !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-150 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important; }
  .my-sm-149 {
    margin-top: 14.9rem !important;
    margin-bottom: 14.9rem !important; }
  .my-sm-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-sm-147 {
    margin-top: 14.7rem !important;
    margin-bottom: 14.7rem !important; }
  .my-sm-146 {
    margin-top: 14.6rem !important;
    margin-bottom: 14.6rem !important; }
  .my-sm-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-sm-144 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important; }
  .my-sm-143 {
    margin-top: 14.3rem !important;
    margin-bottom: 14.3rem !important; }
  .my-sm-142 {
    margin-top: 14.2rem !important;
    margin-bottom: 14.2rem !important; }
  .my-sm-141 {
    margin-top: 14.1rem !important;
    margin-bottom: 14.1rem !important; }
  .my-sm-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-sm-139 {
    margin-top: 13.9rem !important;
    margin-bottom: 13.9rem !important; }
  .my-sm-138 {
    margin-top: 13.8rem !important;
    margin-bottom: 13.8rem !important; }
  .my-sm-137 {
    margin-top: 13.7rem !important;
    margin-bottom: 13.7rem !important; }
  .my-sm-136 {
    margin-top: 13.6rem !important;
    margin-bottom: 13.6rem !important; }
  .my-sm-135 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important; }
  .my-sm-134 {
    margin-top: 13.4rem !important;
    margin-bottom: 13.4rem !important; }
  .my-sm-133 {
    margin-top: 13.3rem !important;
    margin-bottom: 13.3rem !important; }
  .my-sm-132 {
    margin-top: 13.2rem !important;
    margin-bottom: 13.2rem !important; }
  .my-sm-131 {
    margin-top: 13.1rem !important;
    margin-bottom: 13.1rem !important; }
  .my-sm-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-sm-129 {
    margin-top: 12.9rem !important;
    margin-bottom: 12.9rem !important; }
  .my-sm-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-sm-127 {
    margin-top: 12.7rem !important;
    margin-bottom: 12.7rem !important; }
  .my-sm-126 {
    margin-top: 12.6rem !important;
    margin-bottom: 12.6rem !important; }
  .my-sm-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-sm-124 {
    margin-top: 12.4rem !important;
    margin-bottom: 12.4rem !important; }
  .my-sm-123 {
    margin-top: 12.3rem !important;
    margin-bottom: 12.3rem !important; }
  .my-sm-122 {
    margin-top: 12.2rem !important;
    margin-bottom: 12.2rem !important; }
  .my-sm-121 {
    margin-top: 12.1rem !important;
    margin-bottom: 12.1rem !important; }
  .my-sm-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-sm-119 {
    margin-top: 11.9rem !important;
    margin-bottom: 11.9rem !important; }
  .my-sm-118 {
    margin-top: 11.8rem !important;
    margin-bottom: 11.8rem !important; }
  .my-sm-117 {
    margin-top: 11.7rem !important;
    margin-bottom: 11.7rem !important; }
  .my-sm-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-sm-115 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-sm-114 {
    margin-top: 11.4rem !important;
    margin-bottom: 11.4rem !important; }
  .my-sm-113 {
    margin-top: 11.3rem !important;
    margin-bottom: 11.3rem !important; }
  .my-sm-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-sm-111 {
    margin-top: 11.1rem !important;
    margin-bottom: 11.1rem !important; }
  .my-sm-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-sm-109 {
    margin-top: 10.9rem !important;
    margin-bottom: 10.9rem !important; }
  .my-sm-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-sm-107 {
    margin-top: 10.7rem !important;
    margin-bottom: 10.7rem !important; }
  .my-sm-106 {
    margin-top: 10.6rem !important;
    margin-bottom: 10.6rem !important; }
  .my-sm-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-sm-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-sm-103 {
    margin-top: 10.3rem !important;
    margin-bottom: 10.3rem !important; }
  .my-sm-102 {
    margin-top: 10.2rem !important;
    margin-bottom: 10.2rem !important; }
  .my-sm-101 {
    margin-top: 10.1rem !important;
    margin-bottom: 10.1rem !important; }
  .my-sm-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-sm-99 {
    margin-top: 9.9rem !important;
    margin-bottom: 9.9rem !important; }
  .my-sm-98 {
    margin-top: 9.8rem !important;
    margin-bottom: 9.8rem !important; }
  .my-sm-97 {
    margin-top: 9.7rem !important;
    margin-bottom: 9.7rem !important; }
  .my-sm-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-sm-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-sm-94 {
    margin-top: 9.4rem !important;
    margin-bottom: 9.4rem !important; }
  .my-sm-93 {
    margin-top: 9.3rem !important;
    margin-bottom: 9.3rem !important; }
  .my-sm-92 {
    margin-top: 9.2rem !important;
    margin-bottom: 9.2rem !important; }
  .my-sm-91 {
    margin-top: 9.1rem !important;
    margin-bottom: 9.1rem !important; }
  .my-sm-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-sm-89 {
    margin-top: 8.9rem !important;
    margin-bottom: 8.9rem !important; }
  .my-sm-88 {
    margin-top: 8.8rem !important;
    margin-bottom: 8.8rem !important; }
  .my-sm-87 {
    margin-top: 8.7rem !important;
    margin-bottom: 8.7rem !important; }
  .my-sm-86 {
    margin-top: 8.6rem !important;
    margin-bottom: 8.6rem !important; }
  .my-sm-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-sm-84 {
    margin-top: 8.4rem !important;
    margin-bottom: 8.4rem !important; }
  .my-sm-83 {
    margin-top: 8.3rem !important;
    margin-bottom: 8.3rem !important; }
  .my-sm-82 {
    margin-top: 8.2rem !important;
    margin-bottom: 8.2rem !important; }
  .my-sm-81 {
    margin-top: 8.1rem !important;
    margin-bottom: 8.1rem !important; }
  .my-sm-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-sm-79 {
    margin-top: 7.9rem !important;
    margin-bottom: 7.9rem !important; }
  .my-sm-78 {
    margin-top: 7.8rem !important;
    margin-bottom: 7.8rem !important; }
  .my-sm-77 {
    margin-top: 7.7rem !important;
    margin-bottom: 7.7rem !important; }
  .my-sm-76 {
    margin-top: 7.6rem !important;
    margin-bottom: 7.6rem !important; }
  .my-sm-75 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-sm-74 {
    margin-top: 7.4rem !important;
    margin-bottom: 7.4rem !important; }
  .my-sm-73 {
    margin-top: 7.3rem !important;
    margin-bottom: 7.3rem !important; }
  .my-sm-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-sm-71 {
    margin-top: 7.1rem !important;
    margin-bottom: 7.1rem !important; }
  .my-sm-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-sm-69 {
    margin-top: 6.9rem !important;
    margin-bottom: 6.9rem !important; }
  .my-sm-68 {
    margin-top: 6.8rem !important;
    margin-bottom: 6.8rem !important; }
  .my-sm-67 {
    margin-top: 6.7rem !important;
    margin-bottom: 6.7rem !important; }
  .my-sm-66 {
    margin-top: 6.6rem !important;
    margin-bottom: 6.6rem !important; }
  .my-sm-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-sm-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-sm-63 {
    margin-top: 6.3rem !important;
    margin-bottom: 6.3rem !important; }
  .my-sm-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-sm-61 {
    margin-top: 6.1rem !important;
    margin-bottom: 6.1rem !important; }
  .my-sm-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-sm-59 {
    margin-top: 5.9rem !important;
    margin-bottom: 5.9rem !important; }
  .my-sm-58 {
    margin-top: 5.8rem !important;
    margin-bottom: 5.8rem !important; }
  .my-sm-57 {
    margin-top: 5.7rem !important;
    margin-bottom: 5.7rem !important; }
  .my-sm-56 {
    margin-top: 5.6rem !important;
    margin-bottom: 5.6rem !important; }
  .my-sm-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-sm-54 {
    margin-top: 5.4rem !important;
    margin-bottom: 5.4rem !important; }
  .my-sm-53 {
    margin-top: 5.3rem !important;
    margin-bottom: 5.3rem !important; }
  .my-sm-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-sm-51 {
    margin-top: 5.1rem !important;
    margin-bottom: 5.1rem !important; }
  .my-sm-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-sm-49 {
    margin-top: 4.9rem !important;
    margin-bottom: 4.9rem !important; }
  .my-sm-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-sm-47 {
    margin-top: 4.7rem !important;
    margin-bottom: 4.7rem !important; }
  .my-sm-46 {
    margin-top: 4.6rem !important;
    margin-bottom: 4.6rem !important; }
  .my-sm-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-sm-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-sm-43 {
    margin-top: 4.3rem !important;
    margin-bottom: 4.3rem !important; }
  .my-sm-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-sm-41 {
    margin-top: 4.1rem !important;
    margin-bottom: 4.1rem !important; }
  .my-sm-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-sm-39 {
    margin-top: 3.9rem !important;
    margin-bottom: 3.9rem !important; }
  .my-sm-38 {
    margin-top: 3.8rem !important;
    margin-bottom: 3.8rem !important; }
  .my-sm-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-sm-36 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important; }
  .my-sm-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-sm-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-sm-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-sm-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-sm-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-sm-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-sm-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-sm-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-sm-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-sm-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-sm-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-sm-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-sm-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-sm-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-sm-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-sm-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-sm-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-sm-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-sm-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-sm-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-sm-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-sm-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-sm-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-sm-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .my-sm-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .my-sm-n150 {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-150 {
    margin-top: 15rem !important; }
  .mt-sm-149 {
    margin-top: 14.9rem !important; }
  .mt-sm-148 {
    margin-top: 14.8rem !important; }
  .mt-sm-147 {
    margin-top: 14.7rem !important; }
  .mt-sm-146 {
    margin-top: 14.6rem !important; }
  .mt-sm-145 {
    margin-top: 14.5rem !important; }
  .mt-sm-144 {
    margin-top: 14.4rem !important; }
  .mt-sm-143 {
    margin-top: 14.3rem !important; }
  .mt-sm-142 {
    margin-top: 14.2rem !important; }
  .mt-sm-141 {
    margin-top: 14.1rem !important; }
  .mt-sm-140 {
    margin-top: 14rem !important; }
  .mt-sm-139 {
    margin-top: 13.9rem !important; }
  .mt-sm-138 {
    margin-top: 13.8rem !important; }
  .mt-sm-137 {
    margin-top: 13.7rem !important; }
  .mt-sm-136 {
    margin-top: 13.6rem !important; }
  .mt-sm-135 {
    margin-top: 13.5rem !important; }
  .mt-sm-134 {
    margin-top: 13.4rem !important; }
  .mt-sm-133 {
    margin-top: 13.3rem !important; }
  .mt-sm-132 {
    margin-top: 13.2rem !important; }
  .mt-sm-131 {
    margin-top: 13.1rem !important; }
  .mt-sm-130 {
    margin-top: 13rem !important; }
  .mt-sm-129 {
    margin-top: 12.9rem !important; }
  .mt-sm-128 {
    margin-top: 12.8rem !important; }
  .mt-sm-127 {
    margin-top: 12.7rem !important; }
  .mt-sm-126 {
    margin-top: 12.6rem !important; }
  .mt-sm-125 {
    margin-top: 12.5rem !important; }
  .mt-sm-124 {
    margin-top: 12.4rem !important; }
  .mt-sm-123 {
    margin-top: 12.3rem !important; }
  .mt-sm-122 {
    margin-top: 12.2rem !important; }
  .mt-sm-121 {
    margin-top: 12.1rem !important; }
  .mt-sm-120 {
    margin-top: 12rem !important; }
  .mt-sm-119 {
    margin-top: 11.9rem !important; }
  .mt-sm-118 {
    margin-top: 11.8rem !important; }
  .mt-sm-117 {
    margin-top: 11.7rem !important; }
  .mt-sm-116 {
    margin-top: 11.6rem !important; }
  .mt-sm-115 {
    margin-top: 11.5rem !important; }
  .mt-sm-114 {
    margin-top: 11.4rem !important; }
  .mt-sm-113 {
    margin-top: 11.3rem !important; }
  .mt-sm-112 {
    margin-top: 11.2rem !important; }
  .mt-sm-111 {
    margin-top: 11.1rem !important; }
  .mt-sm-110 {
    margin-top: 11rem !important; }
  .mt-sm-109 {
    margin-top: 10.9rem !important; }
  .mt-sm-108 {
    margin-top: 10.8rem !important; }
  .mt-sm-107 {
    margin-top: 10.7rem !important; }
  .mt-sm-106 {
    margin-top: 10.6rem !important; }
  .mt-sm-105 {
    margin-top: 10.5rem !important; }
  .mt-sm-104 {
    margin-top: 10.4rem !important; }
  .mt-sm-103 {
    margin-top: 10.3rem !important; }
  .mt-sm-102 {
    margin-top: 10.2rem !important; }
  .mt-sm-101 {
    margin-top: 10.1rem !important; }
  .mt-sm-100 {
    margin-top: 10rem !important; }
  .mt-sm-99 {
    margin-top: 9.9rem !important; }
  .mt-sm-98 {
    margin-top: 9.8rem !important; }
  .mt-sm-97 {
    margin-top: 9.7rem !important; }
  .mt-sm-96 {
    margin-top: 9.6rem !important; }
  .mt-sm-95 {
    margin-top: 9.5rem !important; }
  .mt-sm-94 {
    margin-top: 9.4rem !important; }
  .mt-sm-93 {
    margin-top: 9.3rem !important; }
  .mt-sm-92 {
    margin-top: 9.2rem !important; }
  .mt-sm-91 {
    margin-top: 9.1rem !important; }
  .mt-sm-90 {
    margin-top: 9rem !important; }
  .mt-sm-89 {
    margin-top: 8.9rem !important; }
  .mt-sm-88 {
    margin-top: 8.8rem !important; }
  .mt-sm-87 {
    margin-top: 8.7rem !important; }
  .mt-sm-86 {
    margin-top: 8.6rem !important; }
  .mt-sm-85 {
    margin-top: 8.5rem !important; }
  .mt-sm-84 {
    margin-top: 8.4rem !important; }
  .mt-sm-83 {
    margin-top: 8.3rem !important; }
  .mt-sm-82 {
    margin-top: 8.2rem !important; }
  .mt-sm-81 {
    margin-top: 8.1rem !important; }
  .mt-sm-80 {
    margin-top: 8rem !important; }
  .mt-sm-79 {
    margin-top: 7.9rem !important; }
  .mt-sm-78 {
    margin-top: 7.8rem !important; }
  .mt-sm-77 {
    margin-top: 7.7rem !important; }
  .mt-sm-76 {
    margin-top: 7.6rem !important; }
  .mt-sm-75 {
    margin-top: 7.5rem !important; }
  .mt-sm-74 {
    margin-top: 7.4rem !important; }
  .mt-sm-73 {
    margin-top: 7.3rem !important; }
  .mt-sm-72 {
    margin-top: 7.2rem !important; }
  .mt-sm-71 {
    margin-top: 7.1rem !important; }
  .mt-sm-70 {
    margin-top: 7rem !important; }
  .mt-sm-69 {
    margin-top: 6.9rem !important; }
  .mt-sm-68 {
    margin-top: 6.8rem !important; }
  .mt-sm-67 {
    margin-top: 6.7rem !important; }
  .mt-sm-66 {
    margin-top: 6.6rem !important; }
  .mt-sm-65 {
    margin-top: 6.5rem !important; }
  .mt-sm-64 {
    margin-top: 6.4rem !important; }
  .mt-sm-63 {
    margin-top: 6.3rem !important; }
  .mt-sm-62 {
    margin-top: 6.2rem !important; }
  .mt-sm-61 {
    margin-top: 6.1rem !important; }
  .mt-sm-60 {
    margin-top: 6rem !important; }
  .mt-sm-59 {
    margin-top: 5.9rem !important; }
  .mt-sm-58 {
    margin-top: 5.8rem !important; }
  .mt-sm-57 {
    margin-top: 5.7rem !important; }
  .mt-sm-56 {
    margin-top: 5.6rem !important; }
  .mt-sm-55 {
    margin-top: 5.5rem !important; }
  .mt-sm-54 {
    margin-top: 5.4rem !important; }
  .mt-sm-53 {
    margin-top: 5.3rem !important; }
  .mt-sm-52 {
    margin-top: 5.2rem !important; }
  .mt-sm-51 {
    margin-top: 5.1rem !important; }
  .mt-sm-50 {
    margin-top: 5rem !important; }
  .mt-sm-49 {
    margin-top: 4.9rem !important; }
  .mt-sm-48 {
    margin-top: 4.8rem !important; }
  .mt-sm-47 {
    margin-top: 4.7rem !important; }
  .mt-sm-46 {
    margin-top: 4.6rem !important; }
  .mt-sm-45 {
    margin-top: 4.5rem !important; }
  .mt-sm-44 {
    margin-top: 4.4rem !important; }
  .mt-sm-43 {
    margin-top: 4.3rem !important; }
  .mt-sm-42 {
    margin-top: 4.2rem !important; }
  .mt-sm-41 {
    margin-top: 4.1rem !important; }
  .mt-sm-40 {
    margin-top: 4rem !important; }
  .mt-sm-39 {
    margin-top: 3.9rem !important; }
  .mt-sm-38 {
    margin-top: 3.8rem !important; }
  .mt-sm-37 {
    margin-top: 3.7rem !important; }
  .mt-sm-36 {
    margin-top: 3.6rem !important; }
  .mt-sm-35 {
    margin-top: 3.5rem !important; }
  .mt-sm-34 {
    margin-top: 3.4rem !important; }
  .mt-sm-33 {
    margin-top: 3.3rem !important; }
  .mt-sm-32 {
    margin-top: 3.2rem !important; }
  .mt-sm-31 {
    margin-top: 3.1rem !important; }
  .mt-sm-30 {
    margin-top: 3rem !important; }
  .mt-sm-29 {
    margin-top: 2.9rem !important; }
  .mt-sm-28 {
    margin-top: 2.8rem !important; }
  .mt-sm-27 {
    margin-top: 2.7rem !important; }
  .mt-sm-26 {
    margin-top: 2.6rem !important; }
  .mt-sm-25 {
    margin-top: 2.5rem !important; }
  .mt-sm-24 {
    margin-top: 2.4rem !important; }
  .mt-sm-23 {
    margin-top: 2.3rem !important; }
  .mt-sm-22 {
    margin-top: 2.2rem !important; }
  .mt-sm-21 {
    margin-top: 2.1rem !important; }
  .mt-sm-20 {
    margin-top: 2rem !important; }
  .mt-sm-19 {
    margin-top: 1.9rem !important; }
  .mt-sm-18 {
    margin-top: 1.8rem !important; }
  .mt-sm-17 {
    margin-top: 1.7rem !important; }
  .mt-sm-16 {
    margin-top: 1.6rem !important; }
  .mt-sm-15 {
    margin-top: 1.5rem !important; }
  .mt-sm-14 {
    margin-top: 1.4rem !important; }
  .mt-sm-13 {
    margin-top: 1.3rem !important; }
  .mt-sm-12 {
    margin-top: 1.2rem !important; }
  .mt-sm-11 {
    margin-top: 1.1rem !important; }
  .mt-sm-10 {
    margin-top: 1rem !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-n80 {
    margin-top: -80px !important; }
  .mt-sm-n100 {
    margin-top: -100px !important; }
  .mt-sm-n150 {
    margin-top: -150px !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-150 {
    margin-right: 15rem !important; }
  .me-sm-149 {
    margin-right: 14.9rem !important; }
  .me-sm-148 {
    margin-right: 14.8rem !important; }
  .me-sm-147 {
    margin-right: 14.7rem !important; }
  .me-sm-146 {
    margin-right: 14.6rem !important; }
  .me-sm-145 {
    margin-right: 14.5rem !important; }
  .me-sm-144 {
    margin-right: 14.4rem !important; }
  .me-sm-143 {
    margin-right: 14.3rem !important; }
  .me-sm-142 {
    margin-right: 14.2rem !important; }
  .me-sm-141 {
    margin-right: 14.1rem !important; }
  .me-sm-140 {
    margin-right: 14rem !important; }
  .me-sm-139 {
    margin-right: 13.9rem !important; }
  .me-sm-138 {
    margin-right: 13.8rem !important; }
  .me-sm-137 {
    margin-right: 13.7rem !important; }
  .me-sm-136 {
    margin-right: 13.6rem !important; }
  .me-sm-135 {
    margin-right: 13.5rem !important; }
  .me-sm-134 {
    margin-right: 13.4rem !important; }
  .me-sm-133 {
    margin-right: 13.3rem !important; }
  .me-sm-132 {
    margin-right: 13.2rem !important; }
  .me-sm-131 {
    margin-right: 13.1rem !important; }
  .me-sm-130 {
    margin-right: 13rem !important; }
  .me-sm-129 {
    margin-right: 12.9rem !important; }
  .me-sm-128 {
    margin-right: 12.8rem !important; }
  .me-sm-127 {
    margin-right: 12.7rem !important; }
  .me-sm-126 {
    margin-right: 12.6rem !important; }
  .me-sm-125 {
    margin-right: 12.5rem !important; }
  .me-sm-124 {
    margin-right: 12.4rem !important; }
  .me-sm-123 {
    margin-right: 12.3rem !important; }
  .me-sm-122 {
    margin-right: 12.2rem !important; }
  .me-sm-121 {
    margin-right: 12.1rem !important; }
  .me-sm-120 {
    margin-right: 12rem !important; }
  .me-sm-119 {
    margin-right: 11.9rem !important; }
  .me-sm-118 {
    margin-right: 11.8rem !important; }
  .me-sm-117 {
    margin-right: 11.7rem !important; }
  .me-sm-116 {
    margin-right: 11.6rem !important; }
  .me-sm-115 {
    margin-right: 11.5rem !important; }
  .me-sm-114 {
    margin-right: 11.4rem !important; }
  .me-sm-113 {
    margin-right: 11.3rem !important; }
  .me-sm-112 {
    margin-right: 11.2rem !important; }
  .me-sm-111 {
    margin-right: 11.1rem !important; }
  .me-sm-110 {
    margin-right: 11rem !important; }
  .me-sm-109 {
    margin-right: 10.9rem !important; }
  .me-sm-108 {
    margin-right: 10.8rem !important; }
  .me-sm-107 {
    margin-right: 10.7rem !important; }
  .me-sm-106 {
    margin-right: 10.6rem !important; }
  .me-sm-105 {
    margin-right: 10.5rem !important; }
  .me-sm-104 {
    margin-right: 10.4rem !important; }
  .me-sm-103 {
    margin-right: 10.3rem !important; }
  .me-sm-102 {
    margin-right: 10.2rem !important; }
  .me-sm-101 {
    margin-right: 10.1rem !important; }
  .me-sm-100 {
    margin-right: 10rem !important; }
  .me-sm-99 {
    margin-right: 9.9rem !important; }
  .me-sm-98 {
    margin-right: 9.8rem !important; }
  .me-sm-97 {
    margin-right: 9.7rem !important; }
  .me-sm-96 {
    margin-right: 9.6rem !important; }
  .me-sm-95 {
    margin-right: 9.5rem !important; }
  .me-sm-94 {
    margin-right: 9.4rem !important; }
  .me-sm-93 {
    margin-right: 9.3rem !important; }
  .me-sm-92 {
    margin-right: 9.2rem !important; }
  .me-sm-91 {
    margin-right: 9.1rem !important; }
  .me-sm-90 {
    margin-right: 9rem !important; }
  .me-sm-89 {
    margin-right: 8.9rem !important; }
  .me-sm-88 {
    margin-right: 8.8rem !important; }
  .me-sm-87 {
    margin-right: 8.7rem !important; }
  .me-sm-86 {
    margin-right: 8.6rem !important; }
  .me-sm-85 {
    margin-right: 8.5rem !important; }
  .me-sm-84 {
    margin-right: 8.4rem !important; }
  .me-sm-83 {
    margin-right: 8.3rem !important; }
  .me-sm-82 {
    margin-right: 8.2rem !important; }
  .me-sm-81 {
    margin-right: 8.1rem !important; }
  .me-sm-80 {
    margin-right: 8rem !important; }
  .me-sm-79 {
    margin-right: 7.9rem !important; }
  .me-sm-78 {
    margin-right: 7.8rem !important; }
  .me-sm-77 {
    margin-right: 7.7rem !important; }
  .me-sm-76 {
    margin-right: 7.6rem !important; }
  .me-sm-75 {
    margin-right: 7.5rem !important; }
  .me-sm-74 {
    margin-right: 7.4rem !important; }
  .me-sm-73 {
    margin-right: 7.3rem !important; }
  .me-sm-72 {
    margin-right: 7.2rem !important; }
  .me-sm-71 {
    margin-right: 7.1rem !important; }
  .me-sm-70 {
    margin-right: 7rem !important; }
  .me-sm-69 {
    margin-right: 6.9rem !important; }
  .me-sm-68 {
    margin-right: 6.8rem !important; }
  .me-sm-67 {
    margin-right: 6.7rem !important; }
  .me-sm-66 {
    margin-right: 6.6rem !important; }
  .me-sm-65 {
    margin-right: 6.5rem !important; }
  .me-sm-64 {
    margin-right: 6.4rem !important; }
  .me-sm-63 {
    margin-right: 6.3rem !important; }
  .me-sm-62 {
    margin-right: 6.2rem !important; }
  .me-sm-61 {
    margin-right: 6.1rem !important; }
  .me-sm-60 {
    margin-right: 6rem !important; }
  .me-sm-59 {
    margin-right: 5.9rem !important; }
  .me-sm-58 {
    margin-right: 5.8rem !important; }
  .me-sm-57 {
    margin-right: 5.7rem !important; }
  .me-sm-56 {
    margin-right: 5.6rem !important; }
  .me-sm-55 {
    margin-right: 5.5rem !important; }
  .me-sm-54 {
    margin-right: 5.4rem !important; }
  .me-sm-53 {
    margin-right: 5.3rem !important; }
  .me-sm-52 {
    margin-right: 5.2rem !important; }
  .me-sm-51 {
    margin-right: 5.1rem !important; }
  .me-sm-50 {
    margin-right: 5rem !important; }
  .me-sm-49 {
    margin-right: 4.9rem !important; }
  .me-sm-48 {
    margin-right: 4.8rem !important; }
  .me-sm-47 {
    margin-right: 4.7rem !important; }
  .me-sm-46 {
    margin-right: 4.6rem !important; }
  .me-sm-45 {
    margin-right: 4.5rem !important; }
  .me-sm-44 {
    margin-right: 4.4rem !important; }
  .me-sm-43 {
    margin-right: 4.3rem !important; }
  .me-sm-42 {
    margin-right: 4.2rem !important; }
  .me-sm-41 {
    margin-right: 4.1rem !important; }
  .me-sm-40 {
    margin-right: 4rem !important; }
  .me-sm-39 {
    margin-right: 3.9rem !important; }
  .me-sm-38 {
    margin-right: 3.8rem !important; }
  .me-sm-37 {
    margin-right: 3.7rem !important; }
  .me-sm-36 {
    margin-right: 3.6rem !important; }
  .me-sm-35 {
    margin-right: 3.5rem !important; }
  .me-sm-34 {
    margin-right: 3.4rem !important; }
  .me-sm-33 {
    margin-right: 3.3rem !important; }
  .me-sm-32 {
    margin-right: 3.2rem !important; }
  .me-sm-31 {
    margin-right: 3.1rem !important; }
  .me-sm-30 {
    margin-right: 3rem !important; }
  .me-sm-29 {
    margin-right: 2.9rem !important; }
  .me-sm-28 {
    margin-right: 2.8rem !important; }
  .me-sm-27 {
    margin-right: 2.7rem !important; }
  .me-sm-26 {
    margin-right: 2.6rem !important; }
  .me-sm-25 {
    margin-right: 2.5rem !important; }
  .me-sm-24 {
    margin-right: 2.4rem !important; }
  .me-sm-23 {
    margin-right: 2.3rem !important; }
  .me-sm-22 {
    margin-right: 2.2rem !important; }
  .me-sm-21 {
    margin-right: 2.1rem !important; }
  .me-sm-20 {
    margin-right: 2rem !important; }
  .me-sm-19 {
    margin-right: 1.9rem !important; }
  .me-sm-18 {
    margin-right: 1.8rem !important; }
  .me-sm-17 {
    margin-right: 1.7rem !important; }
  .me-sm-16 {
    margin-right: 1.6rem !important; }
  .me-sm-15 {
    margin-right: 1.5rem !important; }
  .me-sm-14 {
    margin-right: 1.4rem !important; }
  .me-sm-13 {
    margin-right: 1.3rem !important; }
  .me-sm-12 {
    margin-right: 1.2rem !important; }
  .me-sm-11 {
    margin-right: 1.1rem !important; }
  .me-sm-10 {
    margin-right: 1rem !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-n80 {
    margin-right: -80px !important; }
  .me-sm-n100 {
    margin-right: -100px !important; }
  .me-sm-n150 {
    margin-right: -150px !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-150 {
    margin-bottom: 15rem !important; }
  .mb-sm-149 {
    margin-bottom: 14.9rem !important; }
  .mb-sm-148 {
    margin-bottom: 14.8rem !important; }
  .mb-sm-147 {
    margin-bottom: 14.7rem !important; }
  .mb-sm-146 {
    margin-bottom: 14.6rem !important; }
  .mb-sm-145 {
    margin-bottom: 14.5rem !important; }
  .mb-sm-144 {
    margin-bottom: 14.4rem !important; }
  .mb-sm-143 {
    margin-bottom: 14.3rem !important; }
  .mb-sm-142 {
    margin-bottom: 14.2rem !important; }
  .mb-sm-141 {
    margin-bottom: 14.1rem !important; }
  .mb-sm-140 {
    margin-bottom: 14rem !important; }
  .mb-sm-139 {
    margin-bottom: 13.9rem !important; }
  .mb-sm-138 {
    margin-bottom: 13.8rem !important; }
  .mb-sm-137 {
    margin-bottom: 13.7rem !important; }
  .mb-sm-136 {
    margin-bottom: 13.6rem !important; }
  .mb-sm-135 {
    margin-bottom: 13.5rem !important; }
  .mb-sm-134 {
    margin-bottom: 13.4rem !important; }
  .mb-sm-133 {
    margin-bottom: 13.3rem !important; }
  .mb-sm-132 {
    margin-bottom: 13.2rem !important; }
  .mb-sm-131 {
    margin-bottom: 13.1rem !important; }
  .mb-sm-130 {
    margin-bottom: 13rem !important; }
  .mb-sm-129 {
    margin-bottom: 12.9rem !important; }
  .mb-sm-128 {
    margin-bottom: 12.8rem !important; }
  .mb-sm-127 {
    margin-bottom: 12.7rem !important; }
  .mb-sm-126 {
    margin-bottom: 12.6rem !important; }
  .mb-sm-125 {
    margin-bottom: 12.5rem !important; }
  .mb-sm-124 {
    margin-bottom: 12.4rem !important; }
  .mb-sm-123 {
    margin-bottom: 12.3rem !important; }
  .mb-sm-122 {
    margin-bottom: 12.2rem !important; }
  .mb-sm-121 {
    margin-bottom: 12.1rem !important; }
  .mb-sm-120 {
    margin-bottom: 12rem !important; }
  .mb-sm-119 {
    margin-bottom: 11.9rem !important; }
  .mb-sm-118 {
    margin-bottom: 11.8rem !important; }
  .mb-sm-117 {
    margin-bottom: 11.7rem !important; }
  .mb-sm-116 {
    margin-bottom: 11.6rem !important; }
  .mb-sm-115 {
    margin-bottom: 11.5rem !important; }
  .mb-sm-114 {
    margin-bottom: 11.4rem !important; }
  .mb-sm-113 {
    margin-bottom: 11.3rem !important; }
  .mb-sm-112 {
    margin-bottom: 11.2rem !important; }
  .mb-sm-111 {
    margin-bottom: 11.1rem !important; }
  .mb-sm-110 {
    margin-bottom: 11rem !important; }
  .mb-sm-109 {
    margin-bottom: 10.9rem !important; }
  .mb-sm-108 {
    margin-bottom: 10.8rem !important; }
  .mb-sm-107 {
    margin-bottom: 10.7rem !important; }
  .mb-sm-106 {
    margin-bottom: 10.6rem !important; }
  .mb-sm-105 {
    margin-bottom: 10.5rem !important; }
  .mb-sm-104 {
    margin-bottom: 10.4rem !important; }
  .mb-sm-103 {
    margin-bottom: 10.3rem !important; }
  .mb-sm-102 {
    margin-bottom: 10.2rem !important; }
  .mb-sm-101 {
    margin-bottom: 10.1rem !important; }
  .mb-sm-100 {
    margin-bottom: 10rem !important; }
  .mb-sm-99 {
    margin-bottom: 9.9rem !important; }
  .mb-sm-98 {
    margin-bottom: 9.8rem !important; }
  .mb-sm-97 {
    margin-bottom: 9.7rem !important; }
  .mb-sm-96 {
    margin-bottom: 9.6rem !important; }
  .mb-sm-95 {
    margin-bottom: 9.5rem !important; }
  .mb-sm-94 {
    margin-bottom: 9.4rem !important; }
  .mb-sm-93 {
    margin-bottom: 9.3rem !important; }
  .mb-sm-92 {
    margin-bottom: 9.2rem !important; }
  .mb-sm-91 {
    margin-bottom: 9.1rem !important; }
  .mb-sm-90 {
    margin-bottom: 9rem !important; }
  .mb-sm-89 {
    margin-bottom: 8.9rem !important; }
  .mb-sm-88 {
    margin-bottom: 8.8rem !important; }
  .mb-sm-87 {
    margin-bottom: 8.7rem !important; }
  .mb-sm-86 {
    margin-bottom: 8.6rem !important; }
  .mb-sm-85 {
    margin-bottom: 8.5rem !important; }
  .mb-sm-84 {
    margin-bottom: 8.4rem !important; }
  .mb-sm-83 {
    margin-bottom: 8.3rem !important; }
  .mb-sm-82 {
    margin-bottom: 8.2rem !important; }
  .mb-sm-81 {
    margin-bottom: 8.1rem !important; }
  .mb-sm-80 {
    margin-bottom: 8rem !important; }
  .mb-sm-79 {
    margin-bottom: 7.9rem !important; }
  .mb-sm-78 {
    margin-bottom: 7.8rem !important; }
  .mb-sm-77 {
    margin-bottom: 7.7rem !important; }
  .mb-sm-76 {
    margin-bottom: 7.6rem !important; }
  .mb-sm-75 {
    margin-bottom: 7.5rem !important; }
  .mb-sm-74 {
    margin-bottom: 7.4rem !important; }
  .mb-sm-73 {
    margin-bottom: 7.3rem !important; }
  .mb-sm-72 {
    margin-bottom: 7.2rem !important; }
  .mb-sm-71 {
    margin-bottom: 7.1rem !important; }
  .mb-sm-70 {
    margin-bottom: 7rem !important; }
  .mb-sm-69 {
    margin-bottom: 6.9rem !important; }
  .mb-sm-68 {
    margin-bottom: 6.8rem !important; }
  .mb-sm-67 {
    margin-bottom: 6.7rem !important; }
  .mb-sm-66 {
    margin-bottom: 6.6rem !important; }
  .mb-sm-65 {
    margin-bottom: 6.5rem !important; }
  .mb-sm-64 {
    margin-bottom: 6.4rem !important; }
  .mb-sm-63 {
    margin-bottom: 6.3rem !important; }
  .mb-sm-62 {
    margin-bottom: 6.2rem !important; }
  .mb-sm-61 {
    margin-bottom: 6.1rem !important; }
  .mb-sm-60 {
    margin-bottom: 6rem !important; }
  .mb-sm-59 {
    margin-bottom: 5.9rem !important; }
  .mb-sm-58 {
    margin-bottom: 5.8rem !important; }
  .mb-sm-57 {
    margin-bottom: 5.7rem !important; }
  .mb-sm-56 {
    margin-bottom: 5.6rem !important; }
  .mb-sm-55 {
    margin-bottom: 5.5rem !important; }
  .mb-sm-54 {
    margin-bottom: 5.4rem !important; }
  .mb-sm-53 {
    margin-bottom: 5.3rem !important; }
  .mb-sm-52 {
    margin-bottom: 5.2rem !important; }
  .mb-sm-51 {
    margin-bottom: 5.1rem !important; }
  .mb-sm-50 {
    margin-bottom: 5rem !important; }
  .mb-sm-49 {
    margin-bottom: 4.9rem !important; }
  .mb-sm-48 {
    margin-bottom: 4.8rem !important; }
  .mb-sm-47 {
    margin-bottom: 4.7rem !important; }
  .mb-sm-46 {
    margin-bottom: 4.6rem !important; }
  .mb-sm-45 {
    margin-bottom: 4.5rem !important; }
  .mb-sm-44 {
    margin-bottom: 4.4rem !important; }
  .mb-sm-43 {
    margin-bottom: 4.3rem !important; }
  .mb-sm-42 {
    margin-bottom: 4.2rem !important; }
  .mb-sm-41 {
    margin-bottom: 4.1rem !important; }
  .mb-sm-40 {
    margin-bottom: 4rem !important; }
  .mb-sm-39 {
    margin-bottom: 3.9rem !important; }
  .mb-sm-38 {
    margin-bottom: 3.8rem !important; }
  .mb-sm-37 {
    margin-bottom: 3.7rem !important; }
  .mb-sm-36 {
    margin-bottom: 3.6rem !important; }
  .mb-sm-35 {
    margin-bottom: 3.5rem !important; }
  .mb-sm-34 {
    margin-bottom: 3.4rem !important; }
  .mb-sm-33 {
    margin-bottom: 3.3rem !important; }
  .mb-sm-32 {
    margin-bottom: 3.2rem !important; }
  .mb-sm-31 {
    margin-bottom: 3.1rem !important; }
  .mb-sm-30 {
    margin-bottom: 3rem !important; }
  .mb-sm-29 {
    margin-bottom: 2.9rem !important; }
  .mb-sm-28 {
    margin-bottom: 2.8rem !important; }
  .mb-sm-27 {
    margin-bottom: 2.7rem !important; }
  .mb-sm-26 {
    margin-bottom: 2.6rem !important; }
  .mb-sm-25 {
    margin-bottom: 2.5rem !important; }
  .mb-sm-24 {
    margin-bottom: 2.4rem !important; }
  .mb-sm-23 {
    margin-bottom: 2.3rem !important; }
  .mb-sm-22 {
    margin-bottom: 2.2rem !important; }
  .mb-sm-21 {
    margin-bottom: 2.1rem !important; }
  .mb-sm-20 {
    margin-bottom: 2rem !important; }
  .mb-sm-19 {
    margin-bottom: 1.9rem !important; }
  .mb-sm-18 {
    margin-bottom: 1.8rem !important; }
  .mb-sm-17 {
    margin-bottom: 1.7rem !important; }
  .mb-sm-16 {
    margin-bottom: 1.6rem !important; }
  .mb-sm-15 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-14 {
    margin-bottom: 1.4rem !important; }
  .mb-sm-13 {
    margin-bottom: 1.3rem !important; }
  .mb-sm-12 {
    margin-bottom: 1.2rem !important; }
  .mb-sm-11 {
    margin-bottom: 1.1rem !important; }
  .mb-sm-10 {
    margin-bottom: 1rem !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-n80 {
    margin-bottom: -80px !important; }
  .mb-sm-n100 {
    margin-bottom: -100px !important; }
  .mb-sm-n150 {
    margin-bottom: -150px !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-150 {
    margin-left: 15rem !important; }
  .ms-sm-149 {
    margin-left: 14.9rem !important; }
  .ms-sm-148 {
    margin-left: 14.8rem !important; }
  .ms-sm-147 {
    margin-left: 14.7rem !important; }
  .ms-sm-146 {
    margin-left: 14.6rem !important; }
  .ms-sm-145 {
    margin-left: 14.5rem !important; }
  .ms-sm-144 {
    margin-left: 14.4rem !important; }
  .ms-sm-143 {
    margin-left: 14.3rem !important; }
  .ms-sm-142 {
    margin-left: 14.2rem !important; }
  .ms-sm-141 {
    margin-left: 14.1rem !important; }
  .ms-sm-140 {
    margin-left: 14rem !important; }
  .ms-sm-139 {
    margin-left: 13.9rem !important; }
  .ms-sm-138 {
    margin-left: 13.8rem !important; }
  .ms-sm-137 {
    margin-left: 13.7rem !important; }
  .ms-sm-136 {
    margin-left: 13.6rem !important; }
  .ms-sm-135 {
    margin-left: 13.5rem !important; }
  .ms-sm-134 {
    margin-left: 13.4rem !important; }
  .ms-sm-133 {
    margin-left: 13.3rem !important; }
  .ms-sm-132 {
    margin-left: 13.2rem !important; }
  .ms-sm-131 {
    margin-left: 13.1rem !important; }
  .ms-sm-130 {
    margin-left: 13rem !important; }
  .ms-sm-129 {
    margin-left: 12.9rem !important; }
  .ms-sm-128 {
    margin-left: 12.8rem !important; }
  .ms-sm-127 {
    margin-left: 12.7rem !important; }
  .ms-sm-126 {
    margin-left: 12.6rem !important; }
  .ms-sm-125 {
    margin-left: 12.5rem !important; }
  .ms-sm-124 {
    margin-left: 12.4rem !important; }
  .ms-sm-123 {
    margin-left: 12.3rem !important; }
  .ms-sm-122 {
    margin-left: 12.2rem !important; }
  .ms-sm-121 {
    margin-left: 12.1rem !important; }
  .ms-sm-120 {
    margin-left: 12rem !important; }
  .ms-sm-119 {
    margin-left: 11.9rem !important; }
  .ms-sm-118 {
    margin-left: 11.8rem !important; }
  .ms-sm-117 {
    margin-left: 11.7rem !important; }
  .ms-sm-116 {
    margin-left: 11.6rem !important; }
  .ms-sm-115 {
    margin-left: 11.5rem !important; }
  .ms-sm-114 {
    margin-left: 11.4rem !important; }
  .ms-sm-113 {
    margin-left: 11.3rem !important; }
  .ms-sm-112 {
    margin-left: 11.2rem !important; }
  .ms-sm-111 {
    margin-left: 11.1rem !important; }
  .ms-sm-110 {
    margin-left: 11rem !important; }
  .ms-sm-109 {
    margin-left: 10.9rem !important; }
  .ms-sm-108 {
    margin-left: 10.8rem !important; }
  .ms-sm-107 {
    margin-left: 10.7rem !important; }
  .ms-sm-106 {
    margin-left: 10.6rem !important; }
  .ms-sm-105 {
    margin-left: 10.5rem !important; }
  .ms-sm-104 {
    margin-left: 10.4rem !important; }
  .ms-sm-103 {
    margin-left: 10.3rem !important; }
  .ms-sm-102 {
    margin-left: 10.2rem !important; }
  .ms-sm-101 {
    margin-left: 10.1rem !important; }
  .ms-sm-100 {
    margin-left: 10rem !important; }
  .ms-sm-99 {
    margin-left: 9.9rem !important; }
  .ms-sm-98 {
    margin-left: 9.8rem !important; }
  .ms-sm-97 {
    margin-left: 9.7rem !important; }
  .ms-sm-96 {
    margin-left: 9.6rem !important; }
  .ms-sm-95 {
    margin-left: 9.5rem !important; }
  .ms-sm-94 {
    margin-left: 9.4rem !important; }
  .ms-sm-93 {
    margin-left: 9.3rem !important; }
  .ms-sm-92 {
    margin-left: 9.2rem !important; }
  .ms-sm-91 {
    margin-left: 9.1rem !important; }
  .ms-sm-90 {
    margin-left: 9rem !important; }
  .ms-sm-89 {
    margin-left: 8.9rem !important; }
  .ms-sm-88 {
    margin-left: 8.8rem !important; }
  .ms-sm-87 {
    margin-left: 8.7rem !important; }
  .ms-sm-86 {
    margin-left: 8.6rem !important; }
  .ms-sm-85 {
    margin-left: 8.5rem !important; }
  .ms-sm-84 {
    margin-left: 8.4rem !important; }
  .ms-sm-83 {
    margin-left: 8.3rem !important; }
  .ms-sm-82 {
    margin-left: 8.2rem !important; }
  .ms-sm-81 {
    margin-left: 8.1rem !important; }
  .ms-sm-80 {
    margin-left: 8rem !important; }
  .ms-sm-79 {
    margin-left: 7.9rem !important; }
  .ms-sm-78 {
    margin-left: 7.8rem !important; }
  .ms-sm-77 {
    margin-left: 7.7rem !important; }
  .ms-sm-76 {
    margin-left: 7.6rem !important; }
  .ms-sm-75 {
    margin-left: 7.5rem !important; }
  .ms-sm-74 {
    margin-left: 7.4rem !important; }
  .ms-sm-73 {
    margin-left: 7.3rem !important; }
  .ms-sm-72 {
    margin-left: 7.2rem !important; }
  .ms-sm-71 {
    margin-left: 7.1rem !important; }
  .ms-sm-70 {
    margin-left: 7rem !important; }
  .ms-sm-69 {
    margin-left: 6.9rem !important; }
  .ms-sm-68 {
    margin-left: 6.8rem !important; }
  .ms-sm-67 {
    margin-left: 6.7rem !important; }
  .ms-sm-66 {
    margin-left: 6.6rem !important; }
  .ms-sm-65 {
    margin-left: 6.5rem !important; }
  .ms-sm-64 {
    margin-left: 6.4rem !important; }
  .ms-sm-63 {
    margin-left: 6.3rem !important; }
  .ms-sm-62 {
    margin-left: 6.2rem !important; }
  .ms-sm-61 {
    margin-left: 6.1rem !important; }
  .ms-sm-60 {
    margin-left: 6rem !important; }
  .ms-sm-59 {
    margin-left: 5.9rem !important; }
  .ms-sm-58 {
    margin-left: 5.8rem !important; }
  .ms-sm-57 {
    margin-left: 5.7rem !important; }
  .ms-sm-56 {
    margin-left: 5.6rem !important; }
  .ms-sm-55 {
    margin-left: 5.5rem !important; }
  .ms-sm-54 {
    margin-left: 5.4rem !important; }
  .ms-sm-53 {
    margin-left: 5.3rem !important; }
  .ms-sm-52 {
    margin-left: 5.2rem !important; }
  .ms-sm-51 {
    margin-left: 5.1rem !important; }
  .ms-sm-50 {
    margin-left: 5rem !important; }
  .ms-sm-49 {
    margin-left: 4.9rem !important; }
  .ms-sm-48 {
    margin-left: 4.8rem !important; }
  .ms-sm-47 {
    margin-left: 4.7rem !important; }
  .ms-sm-46 {
    margin-left: 4.6rem !important; }
  .ms-sm-45 {
    margin-left: 4.5rem !important; }
  .ms-sm-44 {
    margin-left: 4.4rem !important; }
  .ms-sm-43 {
    margin-left: 4.3rem !important; }
  .ms-sm-42 {
    margin-left: 4.2rem !important; }
  .ms-sm-41 {
    margin-left: 4.1rem !important; }
  .ms-sm-40 {
    margin-left: 4rem !important; }
  .ms-sm-39 {
    margin-left: 3.9rem !important; }
  .ms-sm-38 {
    margin-left: 3.8rem !important; }
  .ms-sm-37 {
    margin-left: 3.7rem !important; }
  .ms-sm-36 {
    margin-left: 3.6rem !important; }
  .ms-sm-35 {
    margin-left: 3.5rem !important; }
  .ms-sm-34 {
    margin-left: 3.4rem !important; }
  .ms-sm-33 {
    margin-left: 3.3rem !important; }
  .ms-sm-32 {
    margin-left: 3.2rem !important; }
  .ms-sm-31 {
    margin-left: 3.1rem !important; }
  .ms-sm-30 {
    margin-left: 3rem !important; }
  .ms-sm-29 {
    margin-left: 2.9rem !important; }
  .ms-sm-28 {
    margin-left: 2.8rem !important; }
  .ms-sm-27 {
    margin-left: 2.7rem !important; }
  .ms-sm-26 {
    margin-left: 2.6rem !important; }
  .ms-sm-25 {
    margin-left: 2.5rem !important; }
  .ms-sm-24 {
    margin-left: 2.4rem !important; }
  .ms-sm-23 {
    margin-left: 2.3rem !important; }
  .ms-sm-22 {
    margin-left: 2.2rem !important; }
  .ms-sm-21 {
    margin-left: 2.1rem !important; }
  .ms-sm-20 {
    margin-left: 2rem !important; }
  .ms-sm-19 {
    margin-left: 1.9rem !important; }
  .ms-sm-18 {
    margin-left: 1.8rem !important; }
  .ms-sm-17 {
    margin-left: 1.7rem !important; }
  .ms-sm-16 {
    margin-left: 1.6rem !important; }
  .ms-sm-15 {
    margin-left: 1.5rem !important; }
  .ms-sm-14 {
    margin-left: 1.4rem !important; }
  .ms-sm-13 {
    margin-left: 1.3rem !important; }
  .ms-sm-12 {
    margin-left: 1.2rem !important; }
  .ms-sm-11 {
    margin-left: 1.1rem !important; }
  .ms-sm-10 {
    margin-left: 1rem !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-n80 {
    margin-left: -80px !important; }
  .ms-sm-n100 {
    margin-left: -100px !important; }
  .ms-sm-n150 {
    margin-left: -150px !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-150 {
    padding: 15rem !important; }
  .p-sm-149 {
    padding: 14.9rem !important; }
  .p-sm-148 {
    padding: 14.8rem !important; }
  .p-sm-147 {
    padding: 14.7rem !important; }
  .p-sm-146 {
    padding: 14.6rem !important; }
  .p-sm-145 {
    padding: 14.5rem !important; }
  .p-sm-144 {
    padding: 14.4rem !important; }
  .p-sm-143 {
    padding: 14.3rem !important; }
  .p-sm-142 {
    padding: 14.2rem !important; }
  .p-sm-141 {
    padding: 14.1rem !important; }
  .p-sm-140 {
    padding: 14rem !important; }
  .p-sm-139 {
    padding: 13.9rem !important; }
  .p-sm-138 {
    padding: 13.8rem !important; }
  .p-sm-137 {
    padding: 13.7rem !important; }
  .p-sm-136 {
    padding: 13.6rem !important; }
  .p-sm-135 {
    padding: 13.5rem !important; }
  .p-sm-134 {
    padding: 13.4rem !important; }
  .p-sm-133 {
    padding: 13.3rem !important; }
  .p-sm-132 {
    padding: 13.2rem !important; }
  .p-sm-131 {
    padding: 13.1rem !important; }
  .p-sm-130 {
    padding: 13rem !important; }
  .p-sm-129 {
    padding: 12.9rem !important; }
  .p-sm-128 {
    padding: 12.8rem !important; }
  .p-sm-127 {
    padding: 12.7rem !important; }
  .p-sm-126 {
    padding: 12.6rem !important; }
  .p-sm-125 {
    padding: 12.5rem !important; }
  .p-sm-124 {
    padding: 12.4rem !important; }
  .p-sm-123 {
    padding: 12.3rem !important; }
  .p-sm-122 {
    padding: 12.2rem !important; }
  .p-sm-121 {
    padding: 12.1rem !important; }
  .p-sm-120 {
    padding: 12rem !important; }
  .p-sm-119 {
    padding: 11.9rem !important; }
  .p-sm-118 {
    padding: 11.8rem !important; }
  .p-sm-117 {
    padding: 11.7rem !important; }
  .p-sm-116 {
    padding: 11.6rem !important; }
  .p-sm-115 {
    padding: 11.5rem !important; }
  .p-sm-114 {
    padding: 11.4rem !important; }
  .p-sm-113 {
    padding: 11.3rem !important; }
  .p-sm-112 {
    padding: 11.2rem !important; }
  .p-sm-111 {
    padding: 11.1rem !important; }
  .p-sm-110 {
    padding: 11rem !important; }
  .p-sm-109 {
    padding: 10.9rem !important; }
  .p-sm-108 {
    padding: 10.8rem !important; }
  .p-sm-107 {
    padding: 10.7rem !important; }
  .p-sm-106 {
    padding: 10.6rem !important; }
  .p-sm-105 {
    padding: 10.5rem !important; }
  .p-sm-104 {
    padding: 10.4rem !important; }
  .p-sm-103 {
    padding: 10.3rem !important; }
  .p-sm-102 {
    padding: 10.2rem !important; }
  .p-sm-101 {
    padding: 10.1rem !important; }
  .p-sm-100 {
    padding: 10rem !important; }
  .p-sm-99 {
    padding: 9.9rem !important; }
  .p-sm-98 {
    padding: 9.8rem !important; }
  .p-sm-97 {
    padding: 9.7rem !important; }
  .p-sm-96 {
    padding: 9.6rem !important; }
  .p-sm-95 {
    padding: 9.5rem !important; }
  .p-sm-94 {
    padding: 9.4rem !important; }
  .p-sm-93 {
    padding: 9.3rem !important; }
  .p-sm-92 {
    padding: 9.2rem !important; }
  .p-sm-91 {
    padding: 9.1rem !important; }
  .p-sm-90 {
    padding: 9rem !important; }
  .p-sm-89 {
    padding: 8.9rem !important; }
  .p-sm-88 {
    padding: 8.8rem !important; }
  .p-sm-87 {
    padding: 8.7rem !important; }
  .p-sm-86 {
    padding: 8.6rem !important; }
  .p-sm-85 {
    padding: 8.5rem !important; }
  .p-sm-84 {
    padding: 8.4rem !important; }
  .p-sm-83 {
    padding: 8.3rem !important; }
  .p-sm-82 {
    padding: 8.2rem !important; }
  .p-sm-81 {
    padding: 8.1rem !important; }
  .p-sm-80 {
    padding: 8rem !important; }
  .p-sm-79 {
    padding: 7.9rem !important; }
  .p-sm-78 {
    padding: 7.8rem !important; }
  .p-sm-77 {
    padding: 7.7rem !important; }
  .p-sm-76 {
    padding: 7.6rem !important; }
  .p-sm-75 {
    padding: 7.5rem !important; }
  .p-sm-74 {
    padding: 7.4rem !important; }
  .p-sm-73 {
    padding: 7.3rem !important; }
  .p-sm-72 {
    padding: 7.2rem !important; }
  .p-sm-71 {
    padding: 7.1rem !important; }
  .p-sm-70 {
    padding: 7rem !important; }
  .p-sm-69 {
    padding: 6.9rem !important; }
  .p-sm-68 {
    padding: 6.8rem !important; }
  .p-sm-67 {
    padding: 6.7rem !important; }
  .p-sm-66 {
    padding: 6.6rem !important; }
  .p-sm-65 {
    padding: 6.5rem !important; }
  .p-sm-64 {
    padding: 6.4rem !important; }
  .p-sm-63 {
    padding: 6.3rem !important; }
  .p-sm-62 {
    padding: 6.2rem !important; }
  .p-sm-61 {
    padding: 6.1rem !important; }
  .p-sm-60 {
    padding: 6rem !important; }
  .p-sm-59 {
    padding: 5.9rem !important; }
  .p-sm-58 {
    padding: 5.8rem !important; }
  .p-sm-57 {
    padding: 5.7rem !important; }
  .p-sm-56 {
    padding: 5.6rem !important; }
  .p-sm-55 {
    padding: 5.5rem !important; }
  .p-sm-54 {
    padding: 5.4rem !important; }
  .p-sm-53 {
    padding: 5.3rem !important; }
  .p-sm-52 {
    padding: 5.2rem !important; }
  .p-sm-51 {
    padding: 5.1rem !important; }
  .p-sm-50 {
    padding: 5rem !important; }
  .p-sm-49 {
    padding: 4.9rem !important; }
  .p-sm-48 {
    padding: 4.8rem !important; }
  .p-sm-47 {
    padding: 4.7rem !important; }
  .p-sm-46 {
    padding: 4.6rem !important; }
  .p-sm-45 {
    padding: 4.5rem !important; }
  .p-sm-44 {
    padding: 4.4rem !important; }
  .p-sm-43 {
    padding: 4.3rem !important; }
  .p-sm-42 {
    padding: 4.2rem !important; }
  .p-sm-41 {
    padding: 4.1rem !important; }
  .p-sm-40 {
    padding: 4rem !important; }
  .p-sm-39 {
    padding: 3.9rem !important; }
  .p-sm-38 {
    padding: 3.8rem !important; }
  .p-sm-37 {
    padding: 3.7rem !important; }
  .p-sm-36 {
    padding: 3.6rem !important; }
  .p-sm-35 {
    padding: 3.5rem !important; }
  .p-sm-34 {
    padding: 3.4rem !important; }
  .p-sm-33 {
    padding: 3.3rem !important; }
  .p-sm-32 {
    padding: 3.2rem !important; }
  .p-sm-31 {
    padding: 3.1rem !important; }
  .p-sm-30 {
    padding: 3rem !important; }
  .p-sm-29 {
    padding: 2.9rem !important; }
  .p-sm-28 {
    padding: 2.8rem !important; }
  .p-sm-27 {
    padding: 2.7rem !important; }
  .p-sm-26 {
    padding: 2.6rem !important; }
  .p-sm-25 {
    padding: 2.5rem !important; }
  .p-sm-24 {
    padding: 2.4rem !important; }
  .p-sm-23 {
    padding: 2.3rem !important; }
  .p-sm-22 {
    padding: 2.2rem !important; }
  .p-sm-21 {
    padding: 2.1rem !important; }
  .p-sm-20 {
    padding: 2rem !important; }
  .p-sm-19 {
    padding: 1.9rem !important; }
  .p-sm-18 {
    padding: 1.8rem !important; }
  .p-sm-17 {
    padding: 1.7rem !important; }
  .p-sm-16 {
    padding: 1.6rem !important; }
  .p-sm-15 {
    padding: 1.5rem !important; }
  .p-sm-14 {
    padding: 1.4rem !important; }
  .p-sm-13 {
    padding: 1.3rem !important; }
  .p-sm-12 {
    padding: 1.2rem !important; }
  .p-sm-11 {
    padding: 1.1rem !important; }
  .p-sm-10 {
    padding: 1rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .p-sm-n80 {
    padding: -80px !important; }
  .p-sm-n100 {
    padding: -100px !important; }
  .p-sm-n150 {
    padding: -150px !important; }
  .px-sm-150 {
    padding-right: 15rem !important;
    padding-left: 15rem !important; }
  .px-sm-149 {
    padding-right: 14.9rem !important;
    padding-left: 14.9rem !important; }
  .px-sm-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-sm-147 {
    padding-right: 14.7rem !important;
    padding-left: 14.7rem !important; }
  .px-sm-146 {
    padding-right: 14.6rem !important;
    padding-left: 14.6rem !important; }
  .px-sm-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-sm-144 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important; }
  .px-sm-143 {
    padding-right: 14.3rem !important;
    padding-left: 14.3rem !important; }
  .px-sm-142 {
    padding-right: 14.2rem !important;
    padding-left: 14.2rem !important; }
  .px-sm-141 {
    padding-right: 14.1rem !important;
    padding-left: 14.1rem !important; }
  .px-sm-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-sm-139 {
    padding-right: 13.9rem !important;
    padding-left: 13.9rem !important; }
  .px-sm-138 {
    padding-right: 13.8rem !important;
    padding-left: 13.8rem !important; }
  .px-sm-137 {
    padding-right: 13.7rem !important;
    padding-left: 13.7rem !important; }
  .px-sm-136 {
    padding-right: 13.6rem !important;
    padding-left: 13.6rem !important; }
  .px-sm-135 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important; }
  .px-sm-134 {
    padding-right: 13.4rem !important;
    padding-left: 13.4rem !important; }
  .px-sm-133 {
    padding-right: 13.3rem !important;
    padding-left: 13.3rem !important; }
  .px-sm-132 {
    padding-right: 13.2rem !important;
    padding-left: 13.2rem !important; }
  .px-sm-131 {
    padding-right: 13.1rem !important;
    padding-left: 13.1rem !important; }
  .px-sm-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-sm-129 {
    padding-right: 12.9rem !important;
    padding-left: 12.9rem !important; }
  .px-sm-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-sm-127 {
    padding-right: 12.7rem !important;
    padding-left: 12.7rem !important; }
  .px-sm-126 {
    padding-right: 12.6rem !important;
    padding-left: 12.6rem !important; }
  .px-sm-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-sm-124 {
    padding-right: 12.4rem !important;
    padding-left: 12.4rem !important; }
  .px-sm-123 {
    padding-right: 12.3rem !important;
    padding-left: 12.3rem !important; }
  .px-sm-122 {
    padding-right: 12.2rem !important;
    padding-left: 12.2rem !important; }
  .px-sm-121 {
    padding-right: 12.1rem !important;
    padding-left: 12.1rem !important; }
  .px-sm-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-sm-119 {
    padding-right: 11.9rem !important;
    padding-left: 11.9rem !important; }
  .px-sm-118 {
    padding-right: 11.8rem !important;
    padding-left: 11.8rem !important; }
  .px-sm-117 {
    padding-right: 11.7rem !important;
    padding-left: 11.7rem !important; }
  .px-sm-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-sm-115 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .px-sm-114 {
    padding-right: 11.4rem !important;
    padding-left: 11.4rem !important; }
  .px-sm-113 {
    padding-right: 11.3rem !important;
    padding-left: 11.3rem !important; }
  .px-sm-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .px-sm-111 {
    padding-right: 11.1rem !important;
    padding-left: 11.1rem !important; }
  .px-sm-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-sm-109 {
    padding-right: 10.9rem !important;
    padding-left: 10.9rem !important; }
  .px-sm-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-sm-107 {
    padding-right: 10.7rem !important;
    padding-left: 10.7rem !important; }
  .px-sm-106 {
    padding-right: 10.6rem !important;
    padding-left: 10.6rem !important; }
  .px-sm-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-sm-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-sm-103 {
    padding-right: 10.3rem !important;
    padding-left: 10.3rem !important; }
  .px-sm-102 {
    padding-right: 10.2rem !important;
    padding-left: 10.2rem !important; }
  .px-sm-101 {
    padding-right: 10.1rem !important;
    padding-left: 10.1rem !important; }
  .px-sm-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-sm-99 {
    padding-right: 9.9rem !important;
    padding-left: 9.9rem !important; }
  .px-sm-98 {
    padding-right: 9.8rem !important;
    padding-left: 9.8rem !important; }
  .px-sm-97 {
    padding-right: 9.7rem !important;
    padding-left: 9.7rem !important; }
  .px-sm-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-sm-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-sm-94 {
    padding-right: 9.4rem !important;
    padding-left: 9.4rem !important; }
  .px-sm-93 {
    padding-right: 9.3rem !important;
    padding-left: 9.3rem !important; }
  .px-sm-92 {
    padding-right: 9.2rem !important;
    padding-left: 9.2rem !important; }
  .px-sm-91 {
    padding-right: 9.1rem !important;
    padding-left: 9.1rem !important; }
  .px-sm-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-sm-89 {
    padding-right: 8.9rem !important;
    padding-left: 8.9rem !important; }
  .px-sm-88 {
    padding-right: 8.8rem !important;
    padding-left: 8.8rem !important; }
  .px-sm-87 {
    padding-right: 8.7rem !important;
    padding-left: 8.7rem !important; }
  .px-sm-86 {
    padding-right: 8.6rem !important;
    padding-left: 8.6rem !important; }
  .px-sm-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-sm-84 {
    padding-right: 8.4rem !important;
    padding-left: 8.4rem !important; }
  .px-sm-83 {
    padding-right: 8.3rem !important;
    padding-left: 8.3rem !important; }
  .px-sm-82 {
    padding-right: 8.2rem !important;
    padding-left: 8.2rem !important; }
  .px-sm-81 {
    padding-right: 8.1rem !important;
    padding-left: 8.1rem !important; }
  .px-sm-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-sm-79 {
    padding-right: 7.9rem !important;
    padding-left: 7.9rem !important; }
  .px-sm-78 {
    padding-right: 7.8rem !important;
    padding-left: 7.8rem !important; }
  .px-sm-77 {
    padding-right: 7.7rem !important;
    padding-left: 7.7rem !important; }
  .px-sm-76 {
    padding-right: 7.6rem !important;
    padding-left: 7.6rem !important; }
  .px-sm-75 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-sm-74 {
    padding-right: 7.4rem !important;
    padding-left: 7.4rem !important; }
  .px-sm-73 {
    padding-right: 7.3rem !important;
    padding-left: 7.3rem !important; }
  .px-sm-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-sm-71 {
    padding-right: 7.1rem !important;
    padding-left: 7.1rem !important; }
  .px-sm-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-sm-69 {
    padding-right: 6.9rem !important;
    padding-left: 6.9rem !important; }
  .px-sm-68 {
    padding-right: 6.8rem !important;
    padding-left: 6.8rem !important; }
  .px-sm-67 {
    padding-right: 6.7rem !important;
    padding-left: 6.7rem !important; }
  .px-sm-66 {
    padding-right: 6.6rem !important;
    padding-left: 6.6rem !important; }
  .px-sm-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-sm-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-sm-63 {
    padding-right: 6.3rem !important;
    padding-left: 6.3rem !important; }
  .px-sm-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-sm-61 {
    padding-right: 6.1rem !important;
    padding-left: 6.1rem !important; }
  .px-sm-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-sm-59 {
    padding-right: 5.9rem !important;
    padding-left: 5.9rem !important; }
  .px-sm-58 {
    padding-right: 5.8rem !important;
    padding-left: 5.8rem !important; }
  .px-sm-57 {
    padding-right: 5.7rem !important;
    padding-left: 5.7rem !important; }
  .px-sm-56 {
    padding-right: 5.6rem !important;
    padding-left: 5.6rem !important; }
  .px-sm-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-sm-54 {
    padding-right: 5.4rem !important;
    padding-left: 5.4rem !important; }
  .px-sm-53 {
    padding-right: 5.3rem !important;
    padding-left: 5.3rem !important; }
  .px-sm-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-sm-51 {
    padding-right: 5.1rem !important;
    padding-left: 5.1rem !important; }
  .px-sm-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-sm-49 {
    padding-right: 4.9rem !important;
    padding-left: 4.9rem !important; }
  .px-sm-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-sm-47 {
    padding-right: 4.7rem !important;
    padding-left: 4.7rem !important; }
  .px-sm-46 {
    padding-right: 4.6rem !important;
    padding-left: 4.6rem !important; }
  .px-sm-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-sm-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-sm-43 {
    padding-right: 4.3rem !important;
    padding-left: 4.3rem !important; }
  .px-sm-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-sm-41 {
    padding-right: 4.1rem !important;
    padding-left: 4.1rem !important; }
  .px-sm-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-sm-39 {
    padding-right: 3.9rem !important;
    padding-left: 3.9rem !important; }
  .px-sm-38 {
    padding-right: 3.8rem !important;
    padding-left: 3.8rem !important; }
  .px-sm-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-sm-36 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important; }
  .px-sm-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-sm-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-sm-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-sm-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-sm-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-sm-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-sm-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-sm-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-sm-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-sm-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-sm-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-sm-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-sm-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-sm-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-sm-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-sm-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-sm-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-sm-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-sm-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-sm-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-sm-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-sm-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-sm-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-sm-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-n80 {
    padding-right: -80px !important;
    padding-left: -80px !important; }
  .px-sm-n100 {
    padding-right: -100px !important;
    padding-left: -100px !important; }
  .px-sm-n150 {
    padding-right: -150px !important;
    padding-left: -150px !important; }
  .py-sm-150 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important; }
  .py-sm-149 {
    padding-top: 14.9rem !important;
    padding-bottom: 14.9rem !important; }
  .py-sm-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-sm-147 {
    padding-top: 14.7rem !important;
    padding-bottom: 14.7rem !important; }
  .py-sm-146 {
    padding-top: 14.6rem !important;
    padding-bottom: 14.6rem !important; }
  .py-sm-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-sm-144 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important; }
  .py-sm-143 {
    padding-top: 14.3rem !important;
    padding-bottom: 14.3rem !important; }
  .py-sm-142 {
    padding-top: 14.2rem !important;
    padding-bottom: 14.2rem !important; }
  .py-sm-141 {
    padding-top: 14.1rem !important;
    padding-bottom: 14.1rem !important; }
  .py-sm-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-sm-139 {
    padding-top: 13.9rem !important;
    padding-bottom: 13.9rem !important; }
  .py-sm-138 {
    padding-top: 13.8rem !important;
    padding-bottom: 13.8rem !important; }
  .py-sm-137 {
    padding-top: 13.7rem !important;
    padding-bottom: 13.7rem !important; }
  .py-sm-136 {
    padding-top: 13.6rem !important;
    padding-bottom: 13.6rem !important; }
  .py-sm-135 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important; }
  .py-sm-134 {
    padding-top: 13.4rem !important;
    padding-bottom: 13.4rem !important; }
  .py-sm-133 {
    padding-top: 13.3rem !important;
    padding-bottom: 13.3rem !important; }
  .py-sm-132 {
    padding-top: 13.2rem !important;
    padding-bottom: 13.2rem !important; }
  .py-sm-131 {
    padding-top: 13.1rem !important;
    padding-bottom: 13.1rem !important; }
  .py-sm-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-sm-129 {
    padding-top: 12.9rem !important;
    padding-bottom: 12.9rem !important; }
  .py-sm-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-sm-127 {
    padding-top: 12.7rem !important;
    padding-bottom: 12.7rem !important; }
  .py-sm-126 {
    padding-top: 12.6rem !important;
    padding-bottom: 12.6rem !important; }
  .py-sm-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-sm-124 {
    padding-top: 12.4rem !important;
    padding-bottom: 12.4rem !important; }
  .py-sm-123 {
    padding-top: 12.3rem !important;
    padding-bottom: 12.3rem !important; }
  .py-sm-122 {
    padding-top: 12.2rem !important;
    padding-bottom: 12.2rem !important; }
  .py-sm-121 {
    padding-top: 12.1rem !important;
    padding-bottom: 12.1rem !important; }
  .py-sm-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-sm-119 {
    padding-top: 11.9rem !important;
    padding-bottom: 11.9rem !important; }
  .py-sm-118 {
    padding-top: 11.8rem !important;
    padding-bottom: 11.8rem !important; }
  .py-sm-117 {
    padding-top: 11.7rem !important;
    padding-bottom: 11.7rem !important; }
  .py-sm-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-sm-115 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .py-sm-114 {
    padding-top: 11.4rem !important;
    padding-bottom: 11.4rem !important; }
  .py-sm-113 {
    padding-top: 11.3rem !important;
    padding-bottom: 11.3rem !important; }
  .py-sm-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .py-sm-111 {
    padding-top: 11.1rem !important;
    padding-bottom: 11.1rem !important; }
  .py-sm-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-sm-109 {
    padding-top: 10.9rem !important;
    padding-bottom: 10.9rem !important; }
  .py-sm-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-sm-107 {
    padding-top: 10.7rem !important;
    padding-bottom: 10.7rem !important; }
  .py-sm-106 {
    padding-top: 10.6rem !important;
    padding-bottom: 10.6rem !important; }
  .py-sm-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-sm-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-sm-103 {
    padding-top: 10.3rem !important;
    padding-bottom: 10.3rem !important; }
  .py-sm-102 {
    padding-top: 10.2rem !important;
    padding-bottom: 10.2rem !important; }
  .py-sm-101 {
    padding-top: 10.1rem !important;
    padding-bottom: 10.1rem !important; }
  .py-sm-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-sm-99 {
    padding-top: 9.9rem !important;
    padding-bottom: 9.9rem !important; }
  .py-sm-98 {
    padding-top: 9.8rem !important;
    padding-bottom: 9.8rem !important; }
  .py-sm-97 {
    padding-top: 9.7rem !important;
    padding-bottom: 9.7rem !important; }
  .py-sm-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-sm-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-sm-94 {
    padding-top: 9.4rem !important;
    padding-bottom: 9.4rem !important; }
  .py-sm-93 {
    padding-top: 9.3rem !important;
    padding-bottom: 9.3rem !important; }
  .py-sm-92 {
    padding-top: 9.2rem !important;
    padding-bottom: 9.2rem !important; }
  .py-sm-91 {
    padding-top: 9.1rem !important;
    padding-bottom: 9.1rem !important; }
  .py-sm-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-sm-89 {
    padding-top: 8.9rem !important;
    padding-bottom: 8.9rem !important; }
  .py-sm-88 {
    padding-top: 8.8rem !important;
    padding-bottom: 8.8rem !important; }
  .py-sm-87 {
    padding-top: 8.7rem !important;
    padding-bottom: 8.7rem !important; }
  .py-sm-86 {
    padding-top: 8.6rem !important;
    padding-bottom: 8.6rem !important; }
  .py-sm-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-sm-84 {
    padding-top: 8.4rem !important;
    padding-bottom: 8.4rem !important; }
  .py-sm-83 {
    padding-top: 8.3rem !important;
    padding-bottom: 8.3rem !important; }
  .py-sm-82 {
    padding-top: 8.2rem !important;
    padding-bottom: 8.2rem !important; }
  .py-sm-81 {
    padding-top: 8.1rem !important;
    padding-bottom: 8.1rem !important; }
  .py-sm-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-sm-79 {
    padding-top: 7.9rem !important;
    padding-bottom: 7.9rem !important; }
  .py-sm-78 {
    padding-top: 7.8rem !important;
    padding-bottom: 7.8rem !important; }
  .py-sm-77 {
    padding-top: 7.7rem !important;
    padding-bottom: 7.7rem !important; }
  .py-sm-76 {
    padding-top: 7.6rem !important;
    padding-bottom: 7.6rem !important; }
  .py-sm-75 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-sm-74 {
    padding-top: 7.4rem !important;
    padding-bottom: 7.4rem !important; }
  .py-sm-73 {
    padding-top: 7.3rem !important;
    padding-bottom: 7.3rem !important; }
  .py-sm-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-sm-71 {
    padding-top: 7.1rem !important;
    padding-bottom: 7.1rem !important; }
  .py-sm-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-sm-69 {
    padding-top: 6.9rem !important;
    padding-bottom: 6.9rem !important; }
  .py-sm-68 {
    padding-top: 6.8rem !important;
    padding-bottom: 6.8rem !important; }
  .py-sm-67 {
    padding-top: 6.7rem !important;
    padding-bottom: 6.7rem !important; }
  .py-sm-66 {
    padding-top: 6.6rem !important;
    padding-bottom: 6.6rem !important; }
  .py-sm-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-sm-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-sm-63 {
    padding-top: 6.3rem !important;
    padding-bottom: 6.3rem !important; }
  .py-sm-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-sm-61 {
    padding-top: 6.1rem !important;
    padding-bottom: 6.1rem !important; }
  .py-sm-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-sm-59 {
    padding-top: 5.9rem !important;
    padding-bottom: 5.9rem !important; }
  .py-sm-58 {
    padding-top: 5.8rem !important;
    padding-bottom: 5.8rem !important; }
  .py-sm-57 {
    padding-top: 5.7rem !important;
    padding-bottom: 5.7rem !important; }
  .py-sm-56 {
    padding-top: 5.6rem !important;
    padding-bottom: 5.6rem !important; }
  .py-sm-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-sm-54 {
    padding-top: 5.4rem !important;
    padding-bottom: 5.4rem !important; }
  .py-sm-53 {
    padding-top: 5.3rem !important;
    padding-bottom: 5.3rem !important; }
  .py-sm-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-sm-51 {
    padding-top: 5.1rem !important;
    padding-bottom: 5.1rem !important; }
  .py-sm-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-sm-49 {
    padding-top: 4.9rem !important;
    padding-bottom: 4.9rem !important; }
  .py-sm-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-sm-47 {
    padding-top: 4.7rem !important;
    padding-bottom: 4.7rem !important; }
  .py-sm-46 {
    padding-top: 4.6rem !important;
    padding-bottom: 4.6rem !important; }
  .py-sm-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-sm-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-sm-43 {
    padding-top: 4.3rem !important;
    padding-bottom: 4.3rem !important; }
  .py-sm-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-sm-41 {
    padding-top: 4.1rem !important;
    padding-bottom: 4.1rem !important; }
  .py-sm-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-sm-39 {
    padding-top: 3.9rem !important;
    padding-bottom: 3.9rem !important; }
  .py-sm-38 {
    padding-top: 3.8rem !important;
    padding-bottom: 3.8rem !important; }
  .py-sm-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-sm-36 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important; }
  .py-sm-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-sm-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-sm-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-sm-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-sm-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-sm-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-sm-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-sm-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-sm-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-sm-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-sm-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-sm-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-sm-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-sm-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-sm-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-sm-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-sm-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-sm-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-sm-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-sm-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-sm-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-sm-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-sm-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-sm-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-n80 {
    padding-top: -80px !important;
    padding-bottom: -80px !important; }
  .py-sm-n100 {
    padding-top: -100px !important;
    padding-bottom: -100px !important; }
  .py-sm-n150 {
    padding-top: -150px !important;
    padding-bottom: -150px !important; }
  .pt-sm-150 {
    padding-top: 15rem !important; }
  .pt-sm-149 {
    padding-top: 14.9rem !important; }
  .pt-sm-148 {
    padding-top: 14.8rem !important; }
  .pt-sm-147 {
    padding-top: 14.7rem !important; }
  .pt-sm-146 {
    padding-top: 14.6rem !important; }
  .pt-sm-145 {
    padding-top: 14.5rem !important; }
  .pt-sm-144 {
    padding-top: 14.4rem !important; }
  .pt-sm-143 {
    padding-top: 14.3rem !important; }
  .pt-sm-142 {
    padding-top: 14.2rem !important; }
  .pt-sm-141 {
    padding-top: 14.1rem !important; }
  .pt-sm-140 {
    padding-top: 14rem !important; }
  .pt-sm-139 {
    padding-top: 13.9rem !important; }
  .pt-sm-138 {
    padding-top: 13.8rem !important; }
  .pt-sm-137 {
    padding-top: 13.7rem !important; }
  .pt-sm-136 {
    padding-top: 13.6rem !important; }
  .pt-sm-135 {
    padding-top: 13.5rem !important; }
  .pt-sm-134 {
    padding-top: 13.4rem !important; }
  .pt-sm-133 {
    padding-top: 13.3rem !important; }
  .pt-sm-132 {
    padding-top: 13.2rem !important; }
  .pt-sm-131 {
    padding-top: 13.1rem !important; }
  .pt-sm-130 {
    padding-top: 13rem !important; }
  .pt-sm-129 {
    padding-top: 12.9rem !important; }
  .pt-sm-128 {
    padding-top: 12.8rem !important; }
  .pt-sm-127 {
    padding-top: 12.7rem !important; }
  .pt-sm-126 {
    padding-top: 12.6rem !important; }
  .pt-sm-125 {
    padding-top: 12.5rem !important; }
  .pt-sm-124 {
    padding-top: 12.4rem !important; }
  .pt-sm-123 {
    padding-top: 12.3rem !important; }
  .pt-sm-122 {
    padding-top: 12.2rem !important; }
  .pt-sm-121 {
    padding-top: 12.1rem !important; }
  .pt-sm-120 {
    padding-top: 12rem !important; }
  .pt-sm-119 {
    padding-top: 11.9rem !important; }
  .pt-sm-118 {
    padding-top: 11.8rem !important; }
  .pt-sm-117 {
    padding-top: 11.7rem !important; }
  .pt-sm-116 {
    padding-top: 11.6rem !important; }
  .pt-sm-115 {
    padding-top: 11.5rem !important; }
  .pt-sm-114 {
    padding-top: 11.4rem !important; }
  .pt-sm-113 {
    padding-top: 11.3rem !important; }
  .pt-sm-112 {
    padding-top: 11.2rem !important; }
  .pt-sm-111 {
    padding-top: 11.1rem !important; }
  .pt-sm-110 {
    padding-top: 11rem !important; }
  .pt-sm-109 {
    padding-top: 10.9rem !important; }
  .pt-sm-108 {
    padding-top: 10.8rem !important; }
  .pt-sm-107 {
    padding-top: 10.7rem !important; }
  .pt-sm-106 {
    padding-top: 10.6rem !important; }
  .pt-sm-105 {
    padding-top: 10.5rem !important; }
  .pt-sm-104 {
    padding-top: 10.4rem !important; }
  .pt-sm-103 {
    padding-top: 10.3rem !important; }
  .pt-sm-102 {
    padding-top: 10.2rem !important; }
  .pt-sm-101 {
    padding-top: 10.1rem !important; }
  .pt-sm-100 {
    padding-top: 10rem !important; }
  .pt-sm-99 {
    padding-top: 9.9rem !important; }
  .pt-sm-98 {
    padding-top: 9.8rem !important; }
  .pt-sm-97 {
    padding-top: 9.7rem !important; }
  .pt-sm-96 {
    padding-top: 9.6rem !important; }
  .pt-sm-95 {
    padding-top: 9.5rem !important; }
  .pt-sm-94 {
    padding-top: 9.4rem !important; }
  .pt-sm-93 {
    padding-top: 9.3rem !important; }
  .pt-sm-92 {
    padding-top: 9.2rem !important; }
  .pt-sm-91 {
    padding-top: 9.1rem !important; }
  .pt-sm-90 {
    padding-top: 9rem !important; }
  .pt-sm-89 {
    padding-top: 8.9rem !important; }
  .pt-sm-88 {
    padding-top: 8.8rem !important; }
  .pt-sm-87 {
    padding-top: 8.7rem !important; }
  .pt-sm-86 {
    padding-top: 8.6rem !important; }
  .pt-sm-85 {
    padding-top: 8.5rem !important; }
  .pt-sm-84 {
    padding-top: 8.4rem !important; }
  .pt-sm-83 {
    padding-top: 8.3rem !important; }
  .pt-sm-82 {
    padding-top: 8.2rem !important; }
  .pt-sm-81 {
    padding-top: 8.1rem !important; }
  .pt-sm-80 {
    padding-top: 8rem !important; }
  .pt-sm-79 {
    padding-top: 7.9rem !important; }
  .pt-sm-78 {
    padding-top: 7.8rem !important; }
  .pt-sm-77 {
    padding-top: 7.7rem !important; }
  .pt-sm-76 {
    padding-top: 7.6rem !important; }
  .pt-sm-75 {
    padding-top: 7.5rem !important; }
  .pt-sm-74 {
    padding-top: 7.4rem !important; }
  .pt-sm-73 {
    padding-top: 7.3rem !important; }
  .pt-sm-72 {
    padding-top: 7.2rem !important; }
  .pt-sm-71 {
    padding-top: 7.1rem !important; }
  .pt-sm-70 {
    padding-top: 7rem !important; }
  .pt-sm-69 {
    padding-top: 6.9rem !important; }
  .pt-sm-68 {
    padding-top: 6.8rem !important; }
  .pt-sm-67 {
    padding-top: 6.7rem !important; }
  .pt-sm-66 {
    padding-top: 6.6rem !important; }
  .pt-sm-65 {
    padding-top: 6.5rem !important; }
  .pt-sm-64 {
    padding-top: 6.4rem !important; }
  .pt-sm-63 {
    padding-top: 6.3rem !important; }
  .pt-sm-62 {
    padding-top: 6.2rem !important; }
  .pt-sm-61 {
    padding-top: 6.1rem !important; }
  .pt-sm-60 {
    padding-top: 6rem !important; }
  .pt-sm-59 {
    padding-top: 5.9rem !important; }
  .pt-sm-58 {
    padding-top: 5.8rem !important; }
  .pt-sm-57 {
    padding-top: 5.7rem !important; }
  .pt-sm-56 {
    padding-top: 5.6rem !important; }
  .pt-sm-55 {
    padding-top: 5.5rem !important; }
  .pt-sm-54 {
    padding-top: 5.4rem !important; }
  .pt-sm-53 {
    padding-top: 5.3rem !important; }
  .pt-sm-52 {
    padding-top: 5.2rem !important; }
  .pt-sm-51 {
    padding-top: 5.1rem !important; }
  .pt-sm-50 {
    padding-top: 5rem !important; }
  .pt-sm-49 {
    padding-top: 4.9rem !important; }
  .pt-sm-48 {
    padding-top: 4.8rem !important; }
  .pt-sm-47 {
    padding-top: 4.7rem !important; }
  .pt-sm-46 {
    padding-top: 4.6rem !important; }
  .pt-sm-45 {
    padding-top: 4.5rem !important; }
  .pt-sm-44 {
    padding-top: 4.4rem !important; }
  .pt-sm-43 {
    padding-top: 4.3rem !important; }
  .pt-sm-42 {
    padding-top: 4.2rem !important; }
  .pt-sm-41 {
    padding-top: 4.1rem !important; }
  .pt-sm-40 {
    padding-top: 4rem !important; }
  .pt-sm-39 {
    padding-top: 3.9rem !important; }
  .pt-sm-38 {
    padding-top: 3.8rem !important; }
  .pt-sm-37 {
    padding-top: 3.7rem !important; }
  .pt-sm-36 {
    padding-top: 3.6rem !important; }
  .pt-sm-35 {
    padding-top: 3.5rem !important; }
  .pt-sm-34 {
    padding-top: 3.4rem !important; }
  .pt-sm-33 {
    padding-top: 3.3rem !important; }
  .pt-sm-32 {
    padding-top: 3.2rem !important; }
  .pt-sm-31 {
    padding-top: 3.1rem !important; }
  .pt-sm-30 {
    padding-top: 3rem !important; }
  .pt-sm-29 {
    padding-top: 2.9rem !important; }
  .pt-sm-28 {
    padding-top: 2.8rem !important; }
  .pt-sm-27 {
    padding-top: 2.7rem !important; }
  .pt-sm-26 {
    padding-top: 2.6rem !important; }
  .pt-sm-25 {
    padding-top: 2.5rem !important; }
  .pt-sm-24 {
    padding-top: 2.4rem !important; }
  .pt-sm-23 {
    padding-top: 2.3rem !important; }
  .pt-sm-22 {
    padding-top: 2.2rem !important; }
  .pt-sm-21 {
    padding-top: 2.1rem !important; }
  .pt-sm-20 {
    padding-top: 2rem !important; }
  .pt-sm-19 {
    padding-top: 1.9rem !important; }
  .pt-sm-18 {
    padding-top: 1.8rem !important; }
  .pt-sm-17 {
    padding-top: 1.7rem !important; }
  .pt-sm-16 {
    padding-top: 1.6rem !important; }
  .pt-sm-15 {
    padding-top: 1.5rem !important; }
  .pt-sm-14 {
    padding-top: 1.4rem !important; }
  .pt-sm-13 {
    padding-top: 1.3rem !important; }
  .pt-sm-12 {
    padding-top: 1.2rem !important; }
  .pt-sm-11 {
    padding-top: 1.1rem !important; }
  .pt-sm-10 {
    padding-top: 1rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pt-sm-n80 {
    padding-top: -80px !important; }
  .pt-sm-n100 {
    padding-top: -100px !important; }
  .pt-sm-n150 {
    padding-top: -150px !important; }
  .pe-sm-150 {
    padding-right: 15rem !important; }
  .pe-sm-149 {
    padding-right: 14.9rem !important; }
  .pe-sm-148 {
    padding-right: 14.8rem !important; }
  .pe-sm-147 {
    padding-right: 14.7rem !important; }
  .pe-sm-146 {
    padding-right: 14.6rem !important; }
  .pe-sm-145 {
    padding-right: 14.5rem !important; }
  .pe-sm-144 {
    padding-right: 14.4rem !important; }
  .pe-sm-143 {
    padding-right: 14.3rem !important; }
  .pe-sm-142 {
    padding-right: 14.2rem !important; }
  .pe-sm-141 {
    padding-right: 14.1rem !important; }
  .pe-sm-140 {
    padding-right: 14rem !important; }
  .pe-sm-139 {
    padding-right: 13.9rem !important; }
  .pe-sm-138 {
    padding-right: 13.8rem !important; }
  .pe-sm-137 {
    padding-right: 13.7rem !important; }
  .pe-sm-136 {
    padding-right: 13.6rem !important; }
  .pe-sm-135 {
    padding-right: 13.5rem !important; }
  .pe-sm-134 {
    padding-right: 13.4rem !important; }
  .pe-sm-133 {
    padding-right: 13.3rem !important; }
  .pe-sm-132 {
    padding-right: 13.2rem !important; }
  .pe-sm-131 {
    padding-right: 13.1rem !important; }
  .pe-sm-130 {
    padding-right: 13rem !important; }
  .pe-sm-129 {
    padding-right: 12.9rem !important; }
  .pe-sm-128 {
    padding-right: 12.8rem !important; }
  .pe-sm-127 {
    padding-right: 12.7rem !important; }
  .pe-sm-126 {
    padding-right: 12.6rem !important; }
  .pe-sm-125 {
    padding-right: 12.5rem !important; }
  .pe-sm-124 {
    padding-right: 12.4rem !important; }
  .pe-sm-123 {
    padding-right: 12.3rem !important; }
  .pe-sm-122 {
    padding-right: 12.2rem !important; }
  .pe-sm-121 {
    padding-right: 12.1rem !important; }
  .pe-sm-120 {
    padding-right: 12rem !important; }
  .pe-sm-119 {
    padding-right: 11.9rem !important; }
  .pe-sm-118 {
    padding-right: 11.8rem !important; }
  .pe-sm-117 {
    padding-right: 11.7rem !important; }
  .pe-sm-116 {
    padding-right: 11.6rem !important; }
  .pe-sm-115 {
    padding-right: 11.5rem !important; }
  .pe-sm-114 {
    padding-right: 11.4rem !important; }
  .pe-sm-113 {
    padding-right: 11.3rem !important; }
  .pe-sm-112 {
    padding-right: 11.2rem !important; }
  .pe-sm-111 {
    padding-right: 11.1rem !important; }
  .pe-sm-110 {
    padding-right: 11rem !important; }
  .pe-sm-109 {
    padding-right: 10.9rem !important; }
  .pe-sm-108 {
    padding-right: 10.8rem !important; }
  .pe-sm-107 {
    padding-right: 10.7rem !important; }
  .pe-sm-106 {
    padding-right: 10.6rem !important; }
  .pe-sm-105 {
    padding-right: 10.5rem !important; }
  .pe-sm-104 {
    padding-right: 10.4rem !important; }
  .pe-sm-103 {
    padding-right: 10.3rem !important; }
  .pe-sm-102 {
    padding-right: 10.2rem !important; }
  .pe-sm-101 {
    padding-right: 10.1rem !important; }
  .pe-sm-100 {
    padding-right: 10rem !important; }
  .pe-sm-99 {
    padding-right: 9.9rem !important; }
  .pe-sm-98 {
    padding-right: 9.8rem !important; }
  .pe-sm-97 {
    padding-right: 9.7rem !important; }
  .pe-sm-96 {
    padding-right: 9.6rem !important; }
  .pe-sm-95 {
    padding-right: 9.5rem !important; }
  .pe-sm-94 {
    padding-right: 9.4rem !important; }
  .pe-sm-93 {
    padding-right: 9.3rem !important; }
  .pe-sm-92 {
    padding-right: 9.2rem !important; }
  .pe-sm-91 {
    padding-right: 9.1rem !important; }
  .pe-sm-90 {
    padding-right: 9rem !important; }
  .pe-sm-89 {
    padding-right: 8.9rem !important; }
  .pe-sm-88 {
    padding-right: 8.8rem !important; }
  .pe-sm-87 {
    padding-right: 8.7rem !important; }
  .pe-sm-86 {
    padding-right: 8.6rem !important; }
  .pe-sm-85 {
    padding-right: 8.5rem !important; }
  .pe-sm-84 {
    padding-right: 8.4rem !important; }
  .pe-sm-83 {
    padding-right: 8.3rem !important; }
  .pe-sm-82 {
    padding-right: 8.2rem !important; }
  .pe-sm-81 {
    padding-right: 8.1rem !important; }
  .pe-sm-80 {
    padding-right: 8rem !important; }
  .pe-sm-79 {
    padding-right: 7.9rem !important; }
  .pe-sm-78 {
    padding-right: 7.8rem !important; }
  .pe-sm-77 {
    padding-right: 7.7rem !important; }
  .pe-sm-76 {
    padding-right: 7.6rem !important; }
  .pe-sm-75 {
    padding-right: 7.5rem !important; }
  .pe-sm-74 {
    padding-right: 7.4rem !important; }
  .pe-sm-73 {
    padding-right: 7.3rem !important; }
  .pe-sm-72 {
    padding-right: 7.2rem !important; }
  .pe-sm-71 {
    padding-right: 7.1rem !important; }
  .pe-sm-70 {
    padding-right: 7rem !important; }
  .pe-sm-69 {
    padding-right: 6.9rem !important; }
  .pe-sm-68 {
    padding-right: 6.8rem !important; }
  .pe-sm-67 {
    padding-right: 6.7rem !important; }
  .pe-sm-66 {
    padding-right: 6.6rem !important; }
  .pe-sm-65 {
    padding-right: 6.5rem !important; }
  .pe-sm-64 {
    padding-right: 6.4rem !important; }
  .pe-sm-63 {
    padding-right: 6.3rem !important; }
  .pe-sm-62 {
    padding-right: 6.2rem !important; }
  .pe-sm-61 {
    padding-right: 6.1rem !important; }
  .pe-sm-60 {
    padding-right: 6rem !important; }
  .pe-sm-59 {
    padding-right: 5.9rem !important; }
  .pe-sm-58 {
    padding-right: 5.8rem !important; }
  .pe-sm-57 {
    padding-right: 5.7rem !important; }
  .pe-sm-56 {
    padding-right: 5.6rem !important; }
  .pe-sm-55 {
    padding-right: 5.5rem !important; }
  .pe-sm-54 {
    padding-right: 5.4rem !important; }
  .pe-sm-53 {
    padding-right: 5.3rem !important; }
  .pe-sm-52 {
    padding-right: 5.2rem !important; }
  .pe-sm-51 {
    padding-right: 5.1rem !important; }
  .pe-sm-50 {
    padding-right: 5rem !important; }
  .pe-sm-49 {
    padding-right: 4.9rem !important; }
  .pe-sm-48 {
    padding-right: 4.8rem !important; }
  .pe-sm-47 {
    padding-right: 4.7rem !important; }
  .pe-sm-46 {
    padding-right: 4.6rem !important; }
  .pe-sm-45 {
    padding-right: 4.5rem !important; }
  .pe-sm-44 {
    padding-right: 4.4rem !important; }
  .pe-sm-43 {
    padding-right: 4.3rem !important; }
  .pe-sm-42 {
    padding-right: 4.2rem !important; }
  .pe-sm-41 {
    padding-right: 4.1rem !important; }
  .pe-sm-40 {
    padding-right: 4rem !important; }
  .pe-sm-39 {
    padding-right: 3.9rem !important; }
  .pe-sm-38 {
    padding-right: 3.8rem !important; }
  .pe-sm-37 {
    padding-right: 3.7rem !important; }
  .pe-sm-36 {
    padding-right: 3.6rem !important; }
  .pe-sm-35 {
    padding-right: 3.5rem !important; }
  .pe-sm-34 {
    padding-right: 3.4rem !important; }
  .pe-sm-33 {
    padding-right: 3.3rem !important; }
  .pe-sm-32 {
    padding-right: 3.2rem !important; }
  .pe-sm-31 {
    padding-right: 3.1rem !important; }
  .pe-sm-30 {
    padding-right: 3rem !important; }
  .pe-sm-29 {
    padding-right: 2.9rem !important; }
  .pe-sm-28 {
    padding-right: 2.8rem !important; }
  .pe-sm-27 {
    padding-right: 2.7rem !important; }
  .pe-sm-26 {
    padding-right: 2.6rem !important; }
  .pe-sm-25 {
    padding-right: 2.5rem !important; }
  .pe-sm-24 {
    padding-right: 2.4rem !important; }
  .pe-sm-23 {
    padding-right: 2.3rem !important; }
  .pe-sm-22 {
    padding-right: 2.2rem !important; }
  .pe-sm-21 {
    padding-right: 2.1rem !important; }
  .pe-sm-20 {
    padding-right: 2rem !important; }
  .pe-sm-19 {
    padding-right: 1.9rem !important; }
  .pe-sm-18 {
    padding-right: 1.8rem !important; }
  .pe-sm-17 {
    padding-right: 1.7rem !important; }
  .pe-sm-16 {
    padding-right: 1.6rem !important; }
  .pe-sm-15 {
    padding-right: 1.5rem !important; }
  .pe-sm-14 {
    padding-right: 1.4rem !important; }
  .pe-sm-13 {
    padding-right: 1.3rem !important; }
  .pe-sm-12 {
    padding-right: 1.2rem !important; }
  .pe-sm-11 {
    padding-right: 1.1rem !important; }
  .pe-sm-10 {
    padding-right: 1rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pe-sm-n80 {
    padding-right: -80px !important; }
  .pe-sm-n100 {
    padding-right: -100px !important; }
  .pe-sm-n150 {
    padding-right: -150px !important; }
  .pb-sm-150 {
    padding-bottom: 15rem !important; }
  .pb-sm-149 {
    padding-bottom: 14.9rem !important; }
  .pb-sm-148 {
    padding-bottom: 14.8rem !important; }
  .pb-sm-147 {
    padding-bottom: 14.7rem !important; }
  .pb-sm-146 {
    padding-bottom: 14.6rem !important; }
  .pb-sm-145 {
    padding-bottom: 14.5rem !important; }
  .pb-sm-144 {
    padding-bottom: 14.4rem !important; }
  .pb-sm-143 {
    padding-bottom: 14.3rem !important; }
  .pb-sm-142 {
    padding-bottom: 14.2rem !important; }
  .pb-sm-141 {
    padding-bottom: 14.1rem !important; }
  .pb-sm-140 {
    padding-bottom: 14rem !important; }
  .pb-sm-139 {
    padding-bottom: 13.9rem !important; }
  .pb-sm-138 {
    padding-bottom: 13.8rem !important; }
  .pb-sm-137 {
    padding-bottom: 13.7rem !important; }
  .pb-sm-136 {
    padding-bottom: 13.6rem !important; }
  .pb-sm-135 {
    padding-bottom: 13.5rem !important; }
  .pb-sm-134 {
    padding-bottom: 13.4rem !important; }
  .pb-sm-133 {
    padding-bottom: 13.3rem !important; }
  .pb-sm-132 {
    padding-bottom: 13.2rem !important; }
  .pb-sm-131 {
    padding-bottom: 13.1rem !important; }
  .pb-sm-130 {
    padding-bottom: 13rem !important; }
  .pb-sm-129 {
    padding-bottom: 12.9rem !important; }
  .pb-sm-128 {
    padding-bottom: 12.8rem !important; }
  .pb-sm-127 {
    padding-bottom: 12.7rem !important; }
  .pb-sm-126 {
    padding-bottom: 12.6rem !important; }
  .pb-sm-125 {
    padding-bottom: 12.5rem !important; }
  .pb-sm-124 {
    padding-bottom: 12.4rem !important; }
  .pb-sm-123 {
    padding-bottom: 12.3rem !important; }
  .pb-sm-122 {
    padding-bottom: 12.2rem !important; }
  .pb-sm-121 {
    padding-bottom: 12.1rem !important; }
  .pb-sm-120 {
    padding-bottom: 12rem !important; }
  .pb-sm-119 {
    padding-bottom: 11.9rem !important; }
  .pb-sm-118 {
    padding-bottom: 11.8rem !important; }
  .pb-sm-117 {
    padding-bottom: 11.7rem !important; }
  .pb-sm-116 {
    padding-bottom: 11.6rem !important; }
  .pb-sm-115 {
    padding-bottom: 11.5rem !important; }
  .pb-sm-114 {
    padding-bottom: 11.4rem !important; }
  .pb-sm-113 {
    padding-bottom: 11.3rem !important; }
  .pb-sm-112 {
    padding-bottom: 11.2rem !important; }
  .pb-sm-111 {
    padding-bottom: 11.1rem !important; }
  .pb-sm-110 {
    padding-bottom: 11rem !important; }
  .pb-sm-109 {
    padding-bottom: 10.9rem !important; }
  .pb-sm-108 {
    padding-bottom: 10.8rem !important; }
  .pb-sm-107 {
    padding-bottom: 10.7rem !important; }
  .pb-sm-106 {
    padding-bottom: 10.6rem !important; }
  .pb-sm-105 {
    padding-bottom: 10.5rem !important; }
  .pb-sm-104 {
    padding-bottom: 10.4rem !important; }
  .pb-sm-103 {
    padding-bottom: 10.3rem !important; }
  .pb-sm-102 {
    padding-bottom: 10.2rem !important; }
  .pb-sm-101 {
    padding-bottom: 10.1rem !important; }
  .pb-sm-100 {
    padding-bottom: 10rem !important; }
  .pb-sm-99 {
    padding-bottom: 9.9rem !important; }
  .pb-sm-98 {
    padding-bottom: 9.8rem !important; }
  .pb-sm-97 {
    padding-bottom: 9.7rem !important; }
  .pb-sm-96 {
    padding-bottom: 9.6rem !important; }
  .pb-sm-95 {
    padding-bottom: 9.5rem !important; }
  .pb-sm-94 {
    padding-bottom: 9.4rem !important; }
  .pb-sm-93 {
    padding-bottom: 9.3rem !important; }
  .pb-sm-92 {
    padding-bottom: 9.2rem !important; }
  .pb-sm-91 {
    padding-bottom: 9.1rem !important; }
  .pb-sm-90 {
    padding-bottom: 9rem !important; }
  .pb-sm-89 {
    padding-bottom: 8.9rem !important; }
  .pb-sm-88 {
    padding-bottom: 8.8rem !important; }
  .pb-sm-87 {
    padding-bottom: 8.7rem !important; }
  .pb-sm-86 {
    padding-bottom: 8.6rem !important; }
  .pb-sm-85 {
    padding-bottom: 8.5rem !important; }
  .pb-sm-84 {
    padding-bottom: 8.4rem !important; }
  .pb-sm-83 {
    padding-bottom: 8.3rem !important; }
  .pb-sm-82 {
    padding-bottom: 8.2rem !important; }
  .pb-sm-81 {
    padding-bottom: 8.1rem !important; }
  .pb-sm-80 {
    padding-bottom: 8rem !important; }
  .pb-sm-79 {
    padding-bottom: 7.9rem !important; }
  .pb-sm-78 {
    padding-bottom: 7.8rem !important; }
  .pb-sm-77 {
    padding-bottom: 7.7rem !important; }
  .pb-sm-76 {
    padding-bottom: 7.6rem !important; }
  .pb-sm-75 {
    padding-bottom: 7.5rem !important; }
  .pb-sm-74 {
    padding-bottom: 7.4rem !important; }
  .pb-sm-73 {
    padding-bottom: 7.3rem !important; }
  .pb-sm-72 {
    padding-bottom: 7.2rem !important; }
  .pb-sm-71 {
    padding-bottom: 7.1rem !important; }
  .pb-sm-70 {
    padding-bottom: 7rem !important; }
  .pb-sm-69 {
    padding-bottom: 6.9rem !important; }
  .pb-sm-68 {
    padding-bottom: 6.8rem !important; }
  .pb-sm-67 {
    padding-bottom: 6.7rem !important; }
  .pb-sm-66 {
    padding-bottom: 6.6rem !important; }
  .pb-sm-65 {
    padding-bottom: 6.5rem !important; }
  .pb-sm-64 {
    padding-bottom: 6.4rem !important; }
  .pb-sm-63 {
    padding-bottom: 6.3rem !important; }
  .pb-sm-62 {
    padding-bottom: 6.2rem !important; }
  .pb-sm-61 {
    padding-bottom: 6.1rem !important; }
  .pb-sm-60 {
    padding-bottom: 6rem !important; }
  .pb-sm-59 {
    padding-bottom: 5.9rem !important; }
  .pb-sm-58 {
    padding-bottom: 5.8rem !important; }
  .pb-sm-57 {
    padding-bottom: 5.7rem !important; }
  .pb-sm-56 {
    padding-bottom: 5.6rem !important; }
  .pb-sm-55 {
    padding-bottom: 5.5rem !important; }
  .pb-sm-54 {
    padding-bottom: 5.4rem !important; }
  .pb-sm-53 {
    padding-bottom: 5.3rem !important; }
  .pb-sm-52 {
    padding-bottom: 5.2rem !important; }
  .pb-sm-51 {
    padding-bottom: 5.1rem !important; }
  .pb-sm-50 {
    padding-bottom: 5rem !important; }
  .pb-sm-49 {
    padding-bottom: 4.9rem !important; }
  .pb-sm-48 {
    padding-bottom: 4.8rem !important; }
  .pb-sm-47 {
    padding-bottom: 4.7rem !important; }
  .pb-sm-46 {
    padding-bottom: 4.6rem !important; }
  .pb-sm-45 {
    padding-bottom: 4.5rem !important; }
  .pb-sm-44 {
    padding-bottom: 4.4rem !important; }
  .pb-sm-43 {
    padding-bottom: 4.3rem !important; }
  .pb-sm-42 {
    padding-bottom: 4.2rem !important; }
  .pb-sm-41 {
    padding-bottom: 4.1rem !important; }
  .pb-sm-40 {
    padding-bottom: 4rem !important; }
  .pb-sm-39 {
    padding-bottom: 3.9rem !important; }
  .pb-sm-38 {
    padding-bottom: 3.8rem !important; }
  .pb-sm-37 {
    padding-bottom: 3.7rem !important; }
  .pb-sm-36 {
    padding-bottom: 3.6rem !important; }
  .pb-sm-35 {
    padding-bottom: 3.5rem !important; }
  .pb-sm-34 {
    padding-bottom: 3.4rem !important; }
  .pb-sm-33 {
    padding-bottom: 3.3rem !important; }
  .pb-sm-32 {
    padding-bottom: 3.2rem !important; }
  .pb-sm-31 {
    padding-bottom: 3.1rem !important; }
  .pb-sm-30 {
    padding-bottom: 3rem !important; }
  .pb-sm-29 {
    padding-bottom: 2.9rem !important; }
  .pb-sm-28 {
    padding-bottom: 2.8rem !important; }
  .pb-sm-27 {
    padding-bottom: 2.7rem !important; }
  .pb-sm-26 {
    padding-bottom: 2.6rem !important; }
  .pb-sm-25 {
    padding-bottom: 2.5rem !important; }
  .pb-sm-24 {
    padding-bottom: 2.4rem !important; }
  .pb-sm-23 {
    padding-bottom: 2.3rem !important; }
  .pb-sm-22 {
    padding-bottom: 2.2rem !important; }
  .pb-sm-21 {
    padding-bottom: 2.1rem !important; }
  .pb-sm-20 {
    padding-bottom: 2rem !important; }
  .pb-sm-19 {
    padding-bottom: 1.9rem !important; }
  .pb-sm-18 {
    padding-bottom: 1.8rem !important; }
  .pb-sm-17 {
    padding-bottom: 1.7rem !important; }
  .pb-sm-16 {
    padding-bottom: 1.6rem !important; }
  .pb-sm-15 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-14 {
    padding-bottom: 1.4rem !important; }
  .pb-sm-13 {
    padding-bottom: 1.3rem !important; }
  .pb-sm-12 {
    padding-bottom: 1.2rem !important; }
  .pb-sm-11 {
    padding-bottom: 1.1rem !important; }
  .pb-sm-10 {
    padding-bottom: 1rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pb-sm-n80 {
    padding-bottom: -80px !important; }
  .pb-sm-n100 {
    padding-bottom: -100px !important; }
  .pb-sm-n150 {
    padding-bottom: -150px !important; }
  .ps-sm-150 {
    padding-left: 15rem !important; }
  .ps-sm-149 {
    padding-left: 14.9rem !important; }
  .ps-sm-148 {
    padding-left: 14.8rem !important; }
  .ps-sm-147 {
    padding-left: 14.7rem !important; }
  .ps-sm-146 {
    padding-left: 14.6rem !important; }
  .ps-sm-145 {
    padding-left: 14.5rem !important; }
  .ps-sm-144 {
    padding-left: 14.4rem !important; }
  .ps-sm-143 {
    padding-left: 14.3rem !important; }
  .ps-sm-142 {
    padding-left: 14.2rem !important; }
  .ps-sm-141 {
    padding-left: 14.1rem !important; }
  .ps-sm-140 {
    padding-left: 14rem !important; }
  .ps-sm-139 {
    padding-left: 13.9rem !important; }
  .ps-sm-138 {
    padding-left: 13.8rem !important; }
  .ps-sm-137 {
    padding-left: 13.7rem !important; }
  .ps-sm-136 {
    padding-left: 13.6rem !important; }
  .ps-sm-135 {
    padding-left: 13.5rem !important; }
  .ps-sm-134 {
    padding-left: 13.4rem !important; }
  .ps-sm-133 {
    padding-left: 13.3rem !important; }
  .ps-sm-132 {
    padding-left: 13.2rem !important; }
  .ps-sm-131 {
    padding-left: 13.1rem !important; }
  .ps-sm-130 {
    padding-left: 13rem !important; }
  .ps-sm-129 {
    padding-left: 12.9rem !important; }
  .ps-sm-128 {
    padding-left: 12.8rem !important; }
  .ps-sm-127 {
    padding-left: 12.7rem !important; }
  .ps-sm-126 {
    padding-left: 12.6rem !important; }
  .ps-sm-125 {
    padding-left: 12.5rem !important; }
  .ps-sm-124 {
    padding-left: 12.4rem !important; }
  .ps-sm-123 {
    padding-left: 12.3rem !important; }
  .ps-sm-122 {
    padding-left: 12.2rem !important; }
  .ps-sm-121 {
    padding-left: 12.1rem !important; }
  .ps-sm-120 {
    padding-left: 12rem !important; }
  .ps-sm-119 {
    padding-left: 11.9rem !important; }
  .ps-sm-118 {
    padding-left: 11.8rem !important; }
  .ps-sm-117 {
    padding-left: 11.7rem !important; }
  .ps-sm-116 {
    padding-left: 11.6rem !important; }
  .ps-sm-115 {
    padding-left: 11.5rem !important; }
  .ps-sm-114 {
    padding-left: 11.4rem !important; }
  .ps-sm-113 {
    padding-left: 11.3rem !important; }
  .ps-sm-112 {
    padding-left: 11.2rem !important; }
  .ps-sm-111 {
    padding-left: 11.1rem !important; }
  .ps-sm-110 {
    padding-left: 11rem !important; }
  .ps-sm-109 {
    padding-left: 10.9rem !important; }
  .ps-sm-108 {
    padding-left: 10.8rem !important; }
  .ps-sm-107 {
    padding-left: 10.7rem !important; }
  .ps-sm-106 {
    padding-left: 10.6rem !important; }
  .ps-sm-105 {
    padding-left: 10.5rem !important; }
  .ps-sm-104 {
    padding-left: 10.4rem !important; }
  .ps-sm-103 {
    padding-left: 10.3rem !important; }
  .ps-sm-102 {
    padding-left: 10.2rem !important; }
  .ps-sm-101 {
    padding-left: 10.1rem !important; }
  .ps-sm-100 {
    padding-left: 10rem !important; }
  .ps-sm-99 {
    padding-left: 9.9rem !important; }
  .ps-sm-98 {
    padding-left: 9.8rem !important; }
  .ps-sm-97 {
    padding-left: 9.7rem !important; }
  .ps-sm-96 {
    padding-left: 9.6rem !important; }
  .ps-sm-95 {
    padding-left: 9.5rem !important; }
  .ps-sm-94 {
    padding-left: 9.4rem !important; }
  .ps-sm-93 {
    padding-left: 9.3rem !important; }
  .ps-sm-92 {
    padding-left: 9.2rem !important; }
  .ps-sm-91 {
    padding-left: 9.1rem !important; }
  .ps-sm-90 {
    padding-left: 9rem !important; }
  .ps-sm-89 {
    padding-left: 8.9rem !important; }
  .ps-sm-88 {
    padding-left: 8.8rem !important; }
  .ps-sm-87 {
    padding-left: 8.7rem !important; }
  .ps-sm-86 {
    padding-left: 8.6rem !important; }
  .ps-sm-85 {
    padding-left: 8.5rem !important; }
  .ps-sm-84 {
    padding-left: 8.4rem !important; }
  .ps-sm-83 {
    padding-left: 8.3rem !important; }
  .ps-sm-82 {
    padding-left: 8.2rem !important; }
  .ps-sm-81 {
    padding-left: 8.1rem !important; }
  .ps-sm-80 {
    padding-left: 8rem !important; }
  .ps-sm-79 {
    padding-left: 7.9rem !important; }
  .ps-sm-78 {
    padding-left: 7.8rem !important; }
  .ps-sm-77 {
    padding-left: 7.7rem !important; }
  .ps-sm-76 {
    padding-left: 7.6rem !important; }
  .ps-sm-75 {
    padding-left: 7.5rem !important; }
  .ps-sm-74 {
    padding-left: 7.4rem !important; }
  .ps-sm-73 {
    padding-left: 7.3rem !important; }
  .ps-sm-72 {
    padding-left: 7.2rem !important; }
  .ps-sm-71 {
    padding-left: 7.1rem !important; }
  .ps-sm-70 {
    padding-left: 7rem !important; }
  .ps-sm-69 {
    padding-left: 6.9rem !important; }
  .ps-sm-68 {
    padding-left: 6.8rem !important; }
  .ps-sm-67 {
    padding-left: 6.7rem !important; }
  .ps-sm-66 {
    padding-left: 6.6rem !important; }
  .ps-sm-65 {
    padding-left: 6.5rem !important; }
  .ps-sm-64 {
    padding-left: 6.4rem !important; }
  .ps-sm-63 {
    padding-left: 6.3rem !important; }
  .ps-sm-62 {
    padding-left: 6.2rem !important; }
  .ps-sm-61 {
    padding-left: 6.1rem !important; }
  .ps-sm-60 {
    padding-left: 6rem !important; }
  .ps-sm-59 {
    padding-left: 5.9rem !important; }
  .ps-sm-58 {
    padding-left: 5.8rem !important; }
  .ps-sm-57 {
    padding-left: 5.7rem !important; }
  .ps-sm-56 {
    padding-left: 5.6rem !important; }
  .ps-sm-55 {
    padding-left: 5.5rem !important; }
  .ps-sm-54 {
    padding-left: 5.4rem !important; }
  .ps-sm-53 {
    padding-left: 5.3rem !important; }
  .ps-sm-52 {
    padding-left: 5.2rem !important; }
  .ps-sm-51 {
    padding-left: 5.1rem !important; }
  .ps-sm-50 {
    padding-left: 5rem !important; }
  .ps-sm-49 {
    padding-left: 4.9rem !important; }
  .ps-sm-48 {
    padding-left: 4.8rem !important; }
  .ps-sm-47 {
    padding-left: 4.7rem !important; }
  .ps-sm-46 {
    padding-left: 4.6rem !important; }
  .ps-sm-45 {
    padding-left: 4.5rem !important; }
  .ps-sm-44 {
    padding-left: 4.4rem !important; }
  .ps-sm-43 {
    padding-left: 4.3rem !important; }
  .ps-sm-42 {
    padding-left: 4.2rem !important; }
  .ps-sm-41 {
    padding-left: 4.1rem !important; }
  .ps-sm-40 {
    padding-left: 4rem !important; }
  .ps-sm-39 {
    padding-left: 3.9rem !important; }
  .ps-sm-38 {
    padding-left: 3.8rem !important; }
  .ps-sm-37 {
    padding-left: 3.7rem !important; }
  .ps-sm-36 {
    padding-left: 3.6rem !important; }
  .ps-sm-35 {
    padding-left: 3.5rem !important; }
  .ps-sm-34 {
    padding-left: 3.4rem !important; }
  .ps-sm-33 {
    padding-left: 3.3rem !important; }
  .ps-sm-32 {
    padding-left: 3.2rem !important; }
  .ps-sm-31 {
    padding-left: 3.1rem !important; }
  .ps-sm-30 {
    padding-left: 3rem !important; }
  .ps-sm-29 {
    padding-left: 2.9rem !important; }
  .ps-sm-28 {
    padding-left: 2.8rem !important; }
  .ps-sm-27 {
    padding-left: 2.7rem !important; }
  .ps-sm-26 {
    padding-left: 2.6rem !important; }
  .ps-sm-25 {
    padding-left: 2.5rem !important; }
  .ps-sm-24 {
    padding-left: 2.4rem !important; }
  .ps-sm-23 {
    padding-left: 2.3rem !important; }
  .ps-sm-22 {
    padding-left: 2.2rem !important; }
  .ps-sm-21 {
    padding-left: 2.1rem !important; }
  .ps-sm-20 {
    padding-left: 2rem !important; }
  .ps-sm-19 {
    padding-left: 1.9rem !important; }
  .ps-sm-18 {
    padding-left: 1.8rem !important; }
  .ps-sm-17 {
    padding-left: 1.7rem !important; }
  .ps-sm-16 {
    padding-left: 1.6rem !important; }
  .ps-sm-15 {
    padding-left: 1.5rem !important; }
  .ps-sm-14 {
    padding-left: 1.4rem !important; }
  .ps-sm-13 {
    padding-left: 1.3rem !important; }
  .ps-sm-12 {
    padding-left: 1.2rem !important; }
  .ps-sm-11 {
    padding-left: 1.1rem !important; }
  .ps-sm-10 {
    padding-left: 1rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .ps-sm-n80 {
    padding-left: -80px !important; }
  .ps-sm-n100 {
    padding-left: -100px !important; }
  .ps-sm-n150 {
    padding-left: -150px !important; }
  .fs-sm-45 {
    font-size: 4.5rem !important; }
  .fs-sm-44 {
    font-size: 4.4rem !important; }
  .fs-sm-43 {
    font-size: 4.3rem !important; }
  .fs-sm-42 {
    font-size: 4.2rem !important; }
  .fs-sm-41 {
    font-size: 4.1rem !important; }
  .fs-sm-40 {
    font-size: 4rem !important; }
  .fs-sm-39 {
    font-size: 3.9rem !important; }
  .fs-sm-38 {
    font-size: 3.8rem !important; }
  .fs-sm-37 {
    font-size: 3.7rem !important; }
  .fs-sm-36 {
    font-size: 3.6rem !important; }
  .fs-sm-35 {
    font-size: 3.5rem !important; }
  .fs-sm-34 {
    font-size: 3.4rem !important; }
  .fs-sm-33 {
    font-size: 3.3rem !important; }
  .fs-sm-32 {
    font-size: 3.2rem !important; }
  .fs-sm-31 {
    font-size: 3.1rem !important; }
  .fs-sm-30 {
    font-size: 3rem !important; }
  .fs-sm-29 {
    font-size: 2.9rem !important; }
  .fs-sm-28 {
    font-size: 2.8rem !important; }
  .fs-sm-27 {
    font-size: 2.7rem !important; }
  .fs-sm-26 {
    font-size: 2.6rem !important; }
  .fs-sm-25 {
    font-size: 2.5rem !important; }
  .fs-sm-24 {
    font-size: 2.4rem !important; }
  .fs-sm-23 {
    font-size: 2.3rem !important; }
  .fs-sm-22 {
    font-size: 2.2rem !important; }
  .fs-sm-21 {
    font-size: 2.1rem !important; }
  .fs-sm-20 {
    font-size: 2rem !important; }
  .fs-sm-19 {
    font-size: 1.9rem !important; }
  .fs-sm-18 {
    font-size: 1.8rem !important; }
  .fs-sm-17 {
    font-size: 1.7rem !important; }
  .fs-sm-16 {
    font-size: 1.6rem !important; }
  .fs-sm-15 {
    font-size: 1.5rem !important; }
  .fs-sm-14 {
    font-size: 1.4rem !important; }
  .fs-sm-13 {
    font-size: 1.3rem !important; }
  .fs-sm-12 {
    font-size: 1.2rem !important; }
  .fs-sm-11 {
    font-size: 1.1rem !important; }
  .fs-sm-10 {
    font-size: 1rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .rounded-sm {
    border-radius: 0.8rem !important; }
  .rounded-sm-0 {
    border-radius: 0 !important; }
  .rounded-sm-1 {
    border-radius: 0.4rem !important; }
  .rounded-sm-2 {
    border-radius: 0.8rem !important; }
  .rounded-sm-3 {
    border-radius: 1rem !important; }
  .rounded-sm-circle {
    border-radius: 50% !important; }
  .z-index-sm-1 {
    z-index: 1 !important; }
  .z-index-sm-2 {
    z-index: 2 !important; }
  .z-index-sm-3 {
    z-index: 3 !important; }
  .z-index-sm-4 {
    z-index: 4 !important; }
  .z-index-sm-5 {
    z-index: 5 !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .position-md-static {
    position: static !important; }
  .position-md-relative {
    position: relative !important; }
  .position-md-absolute {
    position: absolute !important; }
  .position-md-fixed {
    position: fixed !important; }
  .position-md-sticky {
    position: sticky !important; }
  .w-md-20 {
    width: 20% !important; }
  .w-md-25 {
    width: 25% !important; }
  .w-md-45 {
    width: 45% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-55 {
    width: 55% !important; }
  .w-md-60 {
    width: 60% !important; }
  .w-md-65 {
    width: 65% !important; }
  .w-md-70 {
    width: 70% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-80 {
    width: 80% !important; }
  .w-md-90 {
    width: 90% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .w-md-50px {
    width: 50px !important; }
  .w-md-60px {
    width: 60px !important; }
  .w-md-90px {
    width: 90px !important; }
  .w-md-100px {
    width: 100px !important; }
  .w-md-118px {
    width: 118px !important; }
  .w-md-158px {
    width: 158px !important; }
  .w-md-174px {
    width: 174px !important; }
  .w-md-250px {
    width: 250px !important; }
  .h-md-20 {
    height: 20% !important; }
  .h-md-25 {
    height: 25% !important; }
  .h-md-45 {
    height: 45% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-55 {
    height: 55% !important; }
  .h-md-60 {
    height: 60% !important; }
  .h-md-65 {
    height: 65% !important; }
  .h-md-70 {
    height: 70% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-80 {
    height: 80% !important; }
  .h-md-90 {
    height: 90% !important; }
  .h-md-100 {
    height: 100% !important; }
  .h-md-auto {
    height: auto !important; }
  .h-md-50px {
    height: 50px !important; }
  .h-md-60px {
    height: 60px !important; }
  .h-md-90px {
    height: 90px !important; }
  .h-md-100px {
    height: 100px !important; }
  .h-md-118px {
    height: 118px !important; }
  .h-md-158px {
    height: 158px !important; }
  .h-md-174px {
    height: 174px !important; }
  .h-md-250px {
    height: 250px !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-150 {
    gap: 15rem !important; }
  .gap-md-149 {
    gap: 14.9rem !important; }
  .gap-md-148 {
    gap: 14.8rem !important; }
  .gap-md-147 {
    gap: 14.7rem !important; }
  .gap-md-146 {
    gap: 14.6rem !important; }
  .gap-md-145 {
    gap: 14.5rem !important; }
  .gap-md-144 {
    gap: 14.4rem !important; }
  .gap-md-143 {
    gap: 14.3rem !important; }
  .gap-md-142 {
    gap: 14.2rem !important; }
  .gap-md-141 {
    gap: 14.1rem !important; }
  .gap-md-140 {
    gap: 14rem !important; }
  .gap-md-139 {
    gap: 13.9rem !important; }
  .gap-md-138 {
    gap: 13.8rem !important; }
  .gap-md-137 {
    gap: 13.7rem !important; }
  .gap-md-136 {
    gap: 13.6rem !important; }
  .gap-md-135 {
    gap: 13.5rem !important; }
  .gap-md-134 {
    gap: 13.4rem !important; }
  .gap-md-133 {
    gap: 13.3rem !important; }
  .gap-md-132 {
    gap: 13.2rem !important; }
  .gap-md-131 {
    gap: 13.1rem !important; }
  .gap-md-130 {
    gap: 13rem !important; }
  .gap-md-129 {
    gap: 12.9rem !important; }
  .gap-md-128 {
    gap: 12.8rem !important; }
  .gap-md-127 {
    gap: 12.7rem !important; }
  .gap-md-126 {
    gap: 12.6rem !important; }
  .gap-md-125 {
    gap: 12.5rem !important; }
  .gap-md-124 {
    gap: 12.4rem !important; }
  .gap-md-123 {
    gap: 12.3rem !important; }
  .gap-md-122 {
    gap: 12.2rem !important; }
  .gap-md-121 {
    gap: 12.1rem !important; }
  .gap-md-120 {
    gap: 12rem !important; }
  .gap-md-119 {
    gap: 11.9rem !important; }
  .gap-md-118 {
    gap: 11.8rem !important; }
  .gap-md-117 {
    gap: 11.7rem !important; }
  .gap-md-116 {
    gap: 11.6rem !important; }
  .gap-md-115 {
    gap: 11.5rem !important; }
  .gap-md-114 {
    gap: 11.4rem !important; }
  .gap-md-113 {
    gap: 11.3rem !important; }
  .gap-md-112 {
    gap: 11.2rem !important; }
  .gap-md-111 {
    gap: 11.1rem !important; }
  .gap-md-110 {
    gap: 11rem !important; }
  .gap-md-109 {
    gap: 10.9rem !important; }
  .gap-md-108 {
    gap: 10.8rem !important; }
  .gap-md-107 {
    gap: 10.7rem !important; }
  .gap-md-106 {
    gap: 10.6rem !important; }
  .gap-md-105 {
    gap: 10.5rem !important; }
  .gap-md-104 {
    gap: 10.4rem !important; }
  .gap-md-103 {
    gap: 10.3rem !important; }
  .gap-md-102 {
    gap: 10.2rem !important; }
  .gap-md-101 {
    gap: 10.1rem !important; }
  .gap-md-100 {
    gap: 10rem !important; }
  .gap-md-99 {
    gap: 9.9rem !important; }
  .gap-md-98 {
    gap: 9.8rem !important; }
  .gap-md-97 {
    gap: 9.7rem !important; }
  .gap-md-96 {
    gap: 9.6rem !important; }
  .gap-md-95 {
    gap: 9.5rem !important; }
  .gap-md-94 {
    gap: 9.4rem !important; }
  .gap-md-93 {
    gap: 9.3rem !important; }
  .gap-md-92 {
    gap: 9.2rem !important; }
  .gap-md-91 {
    gap: 9.1rem !important; }
  .gap-md-90 {
    gap: 9rem !important; }
  .gap-md-89 {
    gap: 8.9rem !important; }
  .gap-md-88 {
    gap: 8.8rem !important; }
  .gap-md-87 {
    gap: 8.7rem !important; }
  .gap-md-86 {
    gap: 8.6rem !important; }
  .gap-md-85 {
    gap: 8.5rem !important; }
  .gap-md-84 {
    gap: 8.4rem !important; }
  .gap-md-83 {
    gap: 8.3rem !important; }
  .gap-md-82 {
    gap: 8.2rem !important; }
  .gap-md-81 {
    gap: 8.1rem !important; }
  .gap-md-80 {
    gap: 8rem !important; }
  .gap-md-79 {
    gap: 7.9rem !important; }
  .gap-md-78 {
    gap: 7.8rem !important; }
  .gap-md-77 {
    gap: 7.7rem !important; }
  .gap-md-76 {
    gap: 7.6rem !important; }
  .gap-md-75 {
    gap: 7.5rem !important; }
  .gap-md-74 {
    gap: 7.4rem !important; }
  .gap-md-73 {
    gap: 7.3rem !important; }
  .gap-md-72 {
    gap: 7.2rem !important; }
  .gap-md-71 {
    gap: 7.1rem !important; }
  .gap-md-70 {
    gap: 7rem !important; }
  .gap-md-69 {
    gap: 6.9rem !important; }
  .gap-md-68 {
    gap: 6.8rem !important; }
  .gap-md-67 {
    gap: 6.7rem !important; }
  .gap-md-66 {
    gap: 6.6rem !important; }
  .gap-md-65 {
    gap: 6.5rem !important; }
  .gap-md-64 {
    gap: 6.4rem !important; }
  .gap-md-63 {
    gap: 6.3rem !important; }
  .gap-md-62 {
    gap: 6.2rem !important; }
  .gap-md-61 {
    gap: 6.1rem !important; }
  .gap-md-60 {
    gap: 6rem !important; }
  .gap-md-59 {
    gap: 5.9rem !important; }
  .gap-md-58 {
    gap: 5.8rem !important; }
  .gap-md-57 {
    gap: 5.7rem !important; }
  .gap-md-56 {
    gap: 5.6rem !important; }
  .gap-md-55 {
    gap: 5.5rem !important; }
  .gap-md-54 {
    gap: 5.4rem !important; }
  .gap-md-53 {
    gap: 5.3rem !important; }
  .gap-md-52 {
    gap: 5.2rem !important; }
  .gap-md-51 {
    gap: 5.1rem !important; }
  .gap-md-50 {
    gap: 5rem !important; }
  .gap-md-49 {
    gap: 4.9rem !important; }
  .gap-md-48 {
    gap: 4.8rem !important; }
  .gap-md-47 {
    gap: 4.7rem !important; }
  .gap-md-46 {
    gap: 4.6rem !important; }
  .gap-md-45 {
    gap: 4.5rem !important; }
  .gap-md-44 {
    gap: 4.4rem !important; }
  .gap-md-43 {
    gap: 4.3rem !important; }
  .gap-md-42 {
    gap: 4.2rem !important; }
  .gap-md-41 {
    gap: 4.1rem !important; }
  .gap-md-40 {
    gap: 4rem !important; }
  .gap-md-39 {
    gap: 3.9rem !important; }
  .gap-md-38 {
    gap: 3.8rem !important; }
  .gap-md-37 {
    gap: 3.7rem !important; }
  .gap-md-36 {
    gap: 3.6rem !important; }
  .gap-md-35 {
    gap: 3.5rem !important; }
  .gap-md-34 {
    gap: 3.4rem !important; }
  .gap-md-33 {
    gap: 3.3rem !important; }
  .gap-md-32 {
    gap: 3.2rem !important; }
  .gap-md-31 {
    gap: 3.1rem !important; }
  .gap-md-30 {
    gap: 3rem !important; }
  .gap-md-29 {
    gap: 2.9rem !important; }
  .gap-md-28 {
    gap: 2.8rem !important; }
  .gap-md-27 {
    gap: 2.7rem !important; }
  .gap-md-26 {
    gap: 2.6rem !important; }
  .gap-md-25 {
    gap: 2.5rem !important; }
  .gap-md-24 {
    gap: 2.4rem !important; }
  .gap-md-23 {
    gap: 2.3rem !important; }
  .gap-md-22 {
    gap: 2.2rem !important; }
  .gap-md-21 {
    gap: 2.1rem !important; }
  .gap-md-20 {
    gap: 2rem !important; }
  .gap-md-19 {
    gap: 1.9rem !important; }
  .gap-md-18 {
    gap: 1.8rem !important; }
  .gap-md-17 {
    gap: 1.7rem !important; }
  .gap-md-16 {
    gap: 1.6rem !important; }
  .gap-md-15 {
    gap: 1.5rem !important; }
  .gap-md-14 {
    gap: 1.4rem !important; }
  .gap-md-13 {
    gap: 1.3rem !important; }
  .gap-md-12 {
    gap: 1.2rem !important; }
  .gap-md-11 {
    gap: 1.1rem !important; }
  .gap-md-10 {
    gap: 1rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .gap-md-n80 {
    gap: -80px !important; }
  .gap-md-n100 {
    gap: -100px !important; }
  .gap-md-n150 {
    gap: -150px !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-150 {
    margin: 15rem !important; }
  .m-md-149 {
    margin: 14.9rem !important; }
  .m-md-148 {
    margin: 14.8rem !important; }
  .m-md-147 {
    margin: 14.7rem !important; }
  .m-md-146 {
    margin: 14.6rem !important; }
  .m-md-145 {
    margin: 14.5rem !important; }
  .m-md-144 {
    margin: 14.4rem !important; }
  .m-md-143 {
    margin: 14.3rem !important; }
  .m-md-142 {
    margin: 14.2rem !important; }
  .m-md-141 {
    margin: 14.1rem !important; }
  .m-md-140 {
    margin: 14rem !important; }
  .m-md-139 {
    margin: 13.9rem !important; }
  .m-md-138 {
    margin: 13.8rem !important; }
  .m-md-137 {
    margin: 13.7rem !important; }
  .m-md-136 {
    margin: 13.6rem !important; }
  .m-md-135 {
    margin: 13.5rem !important; }
  .m-md-134 {
    margin: 13.4rem !important; }
  .m-md-133 {
    margin: 13.3rem !important; }
  .m-md-132 {
    margin: 13.2rem !important; }
  .m-md-131 {
    margin: 13.1rem !important; }
  .m-md-130 {
    margin: 13rem !important; }
  .m-md-129 {
    margin: 12.9rem !important; }
  .m-md-128 {
    margin: 12.8rem !important; }
  .m-md-127 {
    margin: 12.7rem !important; }
  .m-md-126 {
    margin: 12.6rem !important; }
  .m-md-125 {
    margin: 12.5rem !important; }
  .m-md-124 {
    margin: 12.4rem !important; }
  .m-md-123 {
    margin: 12.3rem !important; }
  .m-md-122 {
    margin: 12.2rem !important; }
  .m-md-121 {
    margin: 12.1rem !important; }
  .m-md-120 {
    margin: 12rem !important; }
  .m-md-119 {
    margin: 11.9rem !important; }
  .m-md-118 {
    margin: 11.8rem !important; }
  .m-md-117 {
    margin: 11.7rem !important; }
  .m-md-116 {
    margin: 11.6rem !important; }
  .m-md-115 {
    margin: 11.5rem !important; }
  .m-md-114 {
    margin: 11.4rem !important; }
  .m-md-113 {
    margin: 11.3rem !important; }
  .m-md-112 {
    margin: 11.2rem !important; }
  .m-md-111 {
    margin: 11.1rem !important; }
  .m-md-110 {
    margin: 11rem !important; }
  .m-md-109 {
    margin: 10.9rem !important; }
  .m-md-108 {
    margin: 10.8rem !important; }
  .m-md-107 {
    margin: 10.7rem !important; }
  .m-md-106 {
    margin: 10.6rem !important; }
  .m-md-105 {
    margin: 10.5rem !important; }
  .m-md-104 {
    margin: 10.4rem !important; }
  .m-md-103 {
    margin: 10.3rem !important; }
  .m-md-102 {
    margin: 10.2rem !important; }
  .m-md-101 {
    margin: 10.1rem !important; }
  .m-md-100 {
    margin: 10rem !important; }
  .m-md-99 {
    margin: 9.9rem !important; }
  .m-md-98 {
    margin: 9.8rem !important; }
  .m-md-97 {
    margin: 9.7rem !important; }
  .m-md-96 {
    margin: 9.6rem !important; }
  .m-md-95 {
    margin: 9.5rem !important; }
  .m-md-94 {
    margin: 9.4rem !important; }
  .m-md-93 {
    margin: 9.3rem !important; }
  .m-md-92 {
    margin: 9.2rem !important; }
  .m-md-91 {
    margin: 9.1rem !important; }
  .m-md-90 {
    margin: 9rem !important; }
  .m-md-89 {
    margin: 8.9rem !important; }
  .m-md-88 {
    margin: 8.8rem !important; }
  .m-md-87 {
    margin: 8.7rem !important; }
  .m-md-86 {
    margin: 8.6rem !important; }
  .m-md-85 {
    margin: 8.5rem !important; }
  .m-md-84 {
    margin: 8.4rem !important; }
  .m-md-83 {
    margin: 8.3rem !important; }
  .m-md-82 {
    margin: 8.2rem !important; }
  .m-md-81 {
    margin: 8.1rem !important; }
  .m-md-80 {
    margin: 8rem !important; }
  .m-md-79 {
    margin: 7.9rem !important; }
  .m-md-78 {
    margin: 7.8rem !important; }
  .m-md-77 {
    margin: 7.7rem !important; }
  .m-md-76 {
    margin: 7.6rem !important; }
  .m-md-75 {
    margin: 7.5rem !important; }
  .m-md-74 {
    margin: 7.4rem !important; }
  .m-md-73 {
    margin: 7.3rem !important; }
  .m-md-72 {
    margin: 7.2rem !important; }
  .m-md-71 {
    margin: 7.1rem !important; }
  .m-md-70 {
    margin: 7rem !important; }
  .m-md-69 {
    margin: 6.9rem !important; }
  .m-md-68 {
    margin: 6.8rem !important; }
  .m-md-67 {
    margin: 6.7rem !important; }
  .m-md-66 {
    margin: 6.6rem !important; }
  .m-md-65 {
    margin: 6.5rem !important; }
  .m-md-64 {
    margin: 6.4rem !important; }
  .m-md-63 {
    margin: 6.3rem !important; }
  .m-md-62 {
    margin: 6.2rem !important; }
  .m-md-61 {
    margin: 6.1rem !important; }
  .m-md-60 {
    margin: 6rem !important; }
  .m-md-59 {
    margin: 5.9rem !important; }
  .m-md-58 {
    margin: 5.8rem !important; }
  .m-md-57 {
    margin: 5.7rem !important; }
  .m-md-56 {
    margin: 5.6rem !important; }
  .m-md-55 {
    margin: 5.5rem !important; }
  .m-md-54 {
    margin: 5.4rem !important; }
  .m-md-53 {
    margin: 5.3rem !important; }
  .m-md-52 {
    margin: 5.2rem !important; }
  .m-md-51 {
    margin: 5.1rem !important; }
  .m-md-50 {
    margin: 5rem !important; }
  .m-md-49 {
    margin: 4.9rem !important; }
  .m-md-48 {
    margin: 4.8rem !important; }
  .m-md-47 {
    margin: 4.7rem !important; }
  .m-md-46 {
    margin: 4.6rem !important; }
  .m-md-45 {
    margin: 4.5rem !important; }
  .m-md-44 {
    margin: 4.4rem !important; }
  .m-md-43 {
    margin: 4.3rem !important; }
  .m-md-42 {
    margin: 4.2rem !important; }
  .m-md-41 {
    margin: 4.1rem !important; }
  .m-md-40 {
    margin: 4rem !important; }
  .m-md-39 {
    margin: 3.9rem !important; }
  .m-md-38 {
    margin: 3.8rem !important; }
  .m-md-37 {
    margin: 3.7rem !important; }
  .m-md-36 {
    margin: 3.6rem !important; }
  .m-md-35 {
    margin: 3.5rem !important; }
  .m-md-34 {
    margin: 3.4rem !important; }
  .m-md-33 {
    margin: 3.3rem !important; }
  .m-md-32 {
    margin: 3.2rem !important; }
  .m-md-31 {
    margin: 3.1rem !important; }
  .m-md-30 {
    margin: 3rem !important; }
  .m-md-29 {
    margin: 2.9rem !important; }
  .m-md-28 {
    margin: 2.8rem !important; }
  .m-md-27 {
    margin: 2.7rem !important; }
  .m-md-26 {
    margin: 2.6rem !important; }
  .m-md-25 {
    margin: 2.5rem !important; }
  .m-md-24 {
    margin: 2.4rem !important; }
  .m-md-23 {
    margin: 2.3rem !important; }
  .m-md-22 {
    margin: 2.2rem !important; }
  .m-md-21 {
    margin: 2.1rem !important; }
  .m-md-20 {
    margin: 2rem !important; }
  .m-md-19 {
    margin: 1.9rem !important; }
  .m-md-18 {
    margin: 1.8rem !important; }
  .m-md-17 {
    margin: 1.7rem !important; }
  .m-md-16 {
    margin: 1.6rem !important; }
  .m-md-15 {
    margin: 1.5rem !important; }
  .m-md-14 {
    margin: 1.4rem !important; }
  .m-md-13 {
    margin: 1.3rem !important; }
  .m-md-12 {
    margin: 1.2rem !important; }
  .m-md-11 {
    margin: 1.1rem !important; }
  .m-md-10 {
    margin: 1rem !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-n80 {
    margin: -80px !important; }
  .m-md-n100 {
    margin: -100px !important; }
  .m-md-n150 {
    margin: -150px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-150 {
    margin-right: 15rem !important;
    margin-left: 15rem !important; }
  .mx-md-149 {
    margin-right: 14.9rem !important;
    margin-left: 14.9rem !important; }
  .mx-md-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-md-147 {
    margin-right: 14.7rem !important;
    margin-left: 14.7rem !important; }
  .mx-md-146 {
    margin-right: 14.6rem !important;
    margin-left: 14.6rem !important; }
  .mx-md-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-md-144 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important; }
  .mx-md-143 {
    margin-right: 14.3rem !important;
    margin-left: 14.3rem !important; }
  .mx-md-142 {
    margin-right: 14.2rem !important;
    margin-left: 14.2rem !important; }
  .mx-md-141 {
    margin-right: 14.1rem !important;
    margin-left: 14.1rem !important; }
  .mx-md-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-md-139 {
    margin-right: 13.9rem !important;
    margin-left: 13.9rem !important; }
  .mx-md-138 {
    margin-right: 13.8rem !important;
    margin-left: 13.8rem !important; }
  .mx-md-137 {
    margin-right: 13.7rem !important;
    margin-left: 13.7rem !important; }
  .mx-md-136 {
    margin-right: 13.6rem !important;
    margin-left: 13.6rem !important; }
  .mx-md-135 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important; }
  .mx-md-134 {
    margin-right: 13.4rem !important;
    margin-left: 13.4rem !important; }
  .mx-md-133 {
    margin-right: 13.3rem !important;
    margin-left: 13.3rem !important; }
  .mx-md-132 {
    margin-right: 13.2rem !important;
    margin-left: 13.2rem !important; }
  .mx-md-131 {
    margin-right: 13.1rem !important;
    margin-left: 13.1rem !important; }
  .mx-md-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-md-129 {
    margin-right: 12.9rem !important;
    margin-left: 12.9rem !important; }
  .mx-md-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-md-127 {
    margin-right: 12.7rem !important;
    margin-left: 12.7rem !important; }
  .mx-md-126 {
    margin-right: 12.6rem !important;
    margin-left: 12.6rem !important; }
  .mx-md-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-md-124 {
    margin-right: 12.4rem !important;
    margin-left: 12.4rem !important; }
  .mx-md-123 {
    margin-right: 12.3rem !important;
    margin-left: 12.3rem !important; }
  .mx-md-122 {
    margin-right: 12.2rem !important;
    margin-left: 12.2rem !important; }
  .mx-md-121 {
    margin-right: 12.1rem !important;
    margin-left: 12.1rem !important; }
  .mx-md-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-md-119 {
    margin-right: 11.9rem !important;
    margin-left: 11.9rem !important; }
  .mx-md-118 {
    margin-right: 11.8rem !important;
    margin-left: 11.8rem !important; }
  .mx-md-117 {
    margin-right: 11.7rem !important;
    margin-left: 11.7rem !important; }
  .mx-md-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-md-115 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-md-114 {
    margin-right: 11.4rem !important;
    margin-left: 11.4rem !important; }
  .mx-md-113 {
    margin-right: 11.3rem !important;
    margin-left: 11.3rem !important; }
  .mx-md-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-md-111 {
    margin-right: 11.1rem !important;
    margin-left: 11.1rem !important; }
  .mx-md-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-md-109 {
    margin-right: 10.9rem !important;
    margin-left: 10.9rem !important; }
  .mx-md-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-md-107 {
    margin-right: 10.7rem !important;
    margin-left: 10.7rem !important; }
  .mx-md-106 {
    margin-right: 10.6rem !important;
    margin-left: 10.6rem !important; }
  .mx-md-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-md-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-md-103 {
    margin-right: 10.3rem !important;
    margin-left: 10.3rem !important; }
  .mx-md-102 {
    margin-right: 10.2rem !important;
    margin-left: 10.2rem !important; }
  .mx-md-101 {
    margin-right: 10.1rem !important;
    margin-left: 10.1rem !important; }
  .mx-md-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-md-99 {
    margin-right: 9.9rem !important;
    margin-left: 9.9rem !important; }
  .mx-md-98 {
    margin-right: 9.8rem !important;
    margin-left: 9.8rem !important; }
  .mx-md-97 {
    margin-right: 9.7rem !important;
    margin-left: 9.7rem !important; }
  .mx-md-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-md-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-md-94 {
    margin-right: 9.4rem !important;
    margin-left: 9.4rem !important; }
  .mx-md-93 {
    margin-right: 9.3rem !important;
    margin-left: 9.3rem !important; }
  .mx-md-92 {
    margin-right: 9.2rem !important;
    margin-left: 9.2rem !important; }
  .mx-md-91 {
    margin-right: 9.1rem !important;
    margin-left: 9.1rem !important; }
  .mx-md-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-md-89 {
    margin-right: 8.9rem !important;
    margin-left: 8.9rem !important; }
  .mx-md-88 {
    margin-right: 8.8rem !important;
    margin-left: 8.8rem !important; }
  .mx-md-87 {
    margin-right: 8.7rem !important;
    margin-left: 8.7rem !important; }
  .mx-md-86 {
    margin-right: 8.6rem !important;
    margin-left: 8.6rem !important; }
  .mx-md-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-md-84 {
    margin-right: 8.4rem !important;
    margin-left: 8.4rem !important; }
  .mx-md-83 {
    margin-right: 8.3rem !important;
    margin-left: 8.3rem !important; }
  .mx-md-82 {
    margin-right: 8.2rem !important;
    margin-left: 8.2rem !important; }
  .mx-md-81 {
    margin-right: 8.1rem !important;
    margin-left: 8.1rem !important; }
  .mx-md-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-md-79 {
    margin-right: 7.9rem !important;
    margin-left: 7.9rem !important; }
  .mx-md-78 {
    margin-right: 7.8rem !important;
    margin-left: 7.8rem !important; }
  .mx-md-77 {
    margin-right: 7.7rem !important;
    margin-left: 7.7rem !important; }
  .mx-md-76 {
    margin-right: 7.6rem !important;
    margin-left: 7.6rem !important; }
  .mx-md-75 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-md-74 {
    margin-right: 7.4rem !important;
    margin-left: 7.4rem !important; }
  .mx-md-73 {
    margin-right: 7.3rem !important;
    margin-left: 7.3rem !important; }
  .mx-md-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-md-71 {
    margin-right: 7.1rem !important;
    margin-left: 7.1rem !important; }
  .mx-md-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-md-69 {
    margin-right: 6.9rem !important;
    margin-left: 6.9rem !important; }
  .mx-md-68 {
    margin-right: 6.8rem !important;
    margin-left: 6.8rem !important; }
  .mx-md-67 {
    margin-right: 6.7rem !important;
    margin-left: 6.7rem !important; }
  .mx-md-66 {
    margin-right: 6.6rem !important;
    margin-left: 6.6rem !important; }
  .mx-md-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-md-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-md-63 {
    margin-right: 6.3rem !important;
    margin-left: 6.3rem !important; }
  .mx-md-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-md-61 {
    margin-right: 6.1rem !important;
    margin-left: 6.1rem !important; }
  .mx-md-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-md-59 {
    margin-right: 5.9rem !important;
    margin-left: 5.9rem !important; }
  .mx-md-58 {
    margin-right: 5.8rem !important;
    margin-left: 5.8rem !important; }
  .mx-md-57 {
    margin-right: 5.7rem !important;
    margin-left: 5.7rem !important; }
  .mx-md-56 {
    margin-right: 5.6rem !important;
    margin-left: 5.6rem !important; }
  .mx-md-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-md-54 {
    margin-right: 5.4rem !important;
    margin-left: 5.4rem !important; }
  .mx-md-53 {
    margin-right: 5.3rem !important;
    margin-left: 5.3rem !important; }
  .mx-md-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-md-51 {
    margin-right: 5.1rem !important;
    margin-left: 5.1rem !important; }
  .mx-md-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-md-49 {
    margin-right: 4.9rem !important;
    margin-left: 4.9rem !important; }
  .mx-md-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-md-47 {
    margin-right: 4.7rem !important;
    margin-left: 4.7rem !important; }
  .mx-md-46 {
    margin-right: 4.6rem !important;
    margin-left: 4.6rem !important; }
  .mx-md-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-md-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-md-43 {
    margin-right: 4.3rem !important;
    margin-left: 4.3rem !important; }
  .mx-md-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-md-41 {
    margin-right: 4.1rem !important;
    margin-left: 4.1rem !important; }
  .mx-md-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-md-39 {
    margin-right: 3.9rem !important;
    margin-left: 3.9rem !important; }
  .mx-md-38 {
    margin-right: 3.8rem !important;
    margin-left: 3.8rem !important; }
  .mx-md-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-md-36 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important; }
  .mx-md-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-md-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-md-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-md-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-md-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-md-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-md-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-md-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-md-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-md-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-md-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-md-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-md-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-md-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-md-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-md-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-md-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-md-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-md-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-md-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-md-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-md-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-md-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-md-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important; }
  .mx-md-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important; }
  .mx-md-n150 {
    margin-right: -150px !important;
    margin-left: -150px !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-150 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important; }
  .my-md-149 {
    margin-top: 14.9rem !important;
    margin-bottom: 14.9rem !important; }
  .my-md-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-md-147 {
    margin-top: 14.7rem !important;
    margin-bottom: 14.7rem !important; }
  .my-md-146 {
    margin-top: 14.6rem !important;
    margin-bottom: 14.6rem !important; }
  .my-md-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-md-144 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important; }
  .my-md-143 {
    margin-top: 14.3rem !important;
    margin-bottom: 14.3rem !important; }
  .my-md-142 {
    margin-top: 14.2rem !important;
    margin-bottom: 14.2rem !important; }
  .my-md-141 {
    margin-top: 14.1rem !important;
    margin-bottom: 14.1rem !important; }
  .my-md-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-md-139 {
    margin-top: 13.9rem !important;
    margin-bottom: 13.9rem !important; }
  .my-md-138 {
    margin-top: 13.8rem !important;
    margin-bottom: 13.8rem !important; }
  .my-md-137 {
    margin-top: 13.7rem !important;
    margin-bottom: 13.7rem !important; }
  .my-md-136 {
    margin-top: 13.6rem !important;
    margin-bottom: 13.6rem !important; }
  .my-md-135 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important; }
  .my-md-134 {
    margin-top: 13.4rem !important;
    margin-bottom: 13.4rem !important; }
  .my-md-133 {
    margin-top: 13.3rem !important;
    margin-bottom: 13.3rem !important; }
  .my-md-132 {
    margin-top: 13.2rem !important;
    margin-bottom: 13.2rem !important; }
  .my-md-131 {
    margin-top: 13.1rem !important;
    margin-bottom: 13.1rem !important; }
  .my-md-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-md-129 {
    margin-top: 12.9rem !important;
    margin-bottom: 12.9rem !important; }
  .my-md-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-md-127 {
    margin-top: 12.7rem !important;
    margin-bottom: 12.7rem !important; }
  .my-md-126 {
    margin-top: 12.6rem !important;
    margin-bottom: 12.6rem !important; }
  .my-md-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-md-124 {
    margin-top: 12.4rem !important;
    margin-bottom: 12.4rem !important; }
  .my-md-123 {
    margin-top: 12.3rem !important;
    margin-bottom: 12.3rem !important; }
  .my-md-122 {
    margin-top: 12.2rem !important;
    margin-bottom: 12.2rem !important; }
  .my-md-121 {
    margin-top: 12.1rem !important;
    margin-bottom: 12.1rem !important; }
  .my-md-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-md-119 {
    margin-top: 11.9rem !important;
    margin-bottom: 11.9rem !important; }
  .my-md-118 {
    margin-top: 11.8rem !important;
    margin-bottom: 11.8rem !important; }
  .my-md-117 {
    margin-top: 11.7rem !important;
    margin-bottom: 11.7rem !important; }
  .my-md-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-md-115 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-md-114 {
    margin-top: 11.4rem !important;
    margin-bottom: 11.4rem !important; }
  .my-md-113 {
    margin-top: 11.3rem !important;
    margin-bottom: 11.3rem !important; }
  .my-md-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-md-111 {
    margin-top: 11.1rem !important;
    margin-bottom: 11.1rem !important; }
  .my-md-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-md-109 {
    margin-top: 10.9rem !important;
    margin-bottom: 10.9rem !important; }
  .my-md-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-md-107 {
    margin-top: 10.7rem !important;
    margin-bottom: 10.7rem !important; }
  .my-md-106 {
    margin-top: 10.6rem !important;
    margin-bottom: 10.6rem !important; }
  .my-md-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-md-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-md-103 {
    margin-top: 10.3rem !important;
    margin-bottom: 10.3rem !important; }
  .my-md-102 {
    margin-top: 10.2rem !important;
    margin-bottom: 10.2rem !important; }
  .my-md-101 {
    margin-top: 10.1rem !important;
    margin-bottom: 10.1rem !important; }
  .my-md-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-md-99 {
    margin-top: 9.9rem !important;
    margin-bottom: 9.9rem !important; }
  .my-md-98 {
    margin-top: 9.8rem !important;
    margin-bottom: 9.8rem !important; }
  .my-md-97 {
    margin-top: 9.7rem !important;
    margin-bottom: 9.7rem !important; }
  .my-md-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-md-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-md-94 {
    margin-top: 9.4rem !important;
    margin-bottom: 9.4rem !important; }
  .my-md-93 {
    margin-top: 9.3rem !important;
    margin-bottom: 9.3rem !important; }
  .my-md-92 {
    margin-top: 9.2rem !important;
    margin-bottom: 9.2rem !important; }
  .my-md-91 {
    margin-top: 9.1rem !important;
    margin-bottom: 9.1rem !important; }
  .my-md-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-md-89 {
    margin-top: 8.9rem !important;
    margin-bottom: 8.9rem !important; }
  .my-md-88 {
    margin-top: 8.8rem !important;
    margin-bottom: 8.8rem !important; }
  .my-md-87 {
    margin-top: 8.7rem !important;
    margin-bottom: 8.7rem !important; }
  .my-md-86 {
    margin-top: 8.6rem !important;
    margin-bottom: 8.6rem !important; }
  .my-md-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-md-84 {
    margin-top: 8.4rem !important;
    margin-bottom: 8.4rem !important; }
  .my-md-83 {
    margin-top: 8.3rem !important;
    margin-bottom: 8.3rem !important; }
  .my-md-82 {
    margin-top: 8.2rem !important;
    margin-bottom: 8.2rem !important; }
  .my-md-81 {
    margin-top: 8.1rem !important;
    margin-bottom: 8.1rem !important; }
  .my-md-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-md-79 {
    margin-top: 7.9rem !important;
    margin-bottom: 7.9rem !important; }
  .my-md-78 {
    margin-top: 7.8rem !important;
    margin-bottom: 7.8rem !important; }
  .my-md-77 {
    margin-top: 7.7rem !important;
    margin-bottom: 7.7rem !important; }
  .my-md-76 {
    margin-top: 7.6rem !important;
    margin-bottom: 7.6rem !important; }
  .my-md-75 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-md-74 {
    margin-top: 7.4rem !important;
    margin-bottom: 7.4rem !important; }
  .my-md-73 {
    margin-top: 7.3rem !important;
    margin-bottom: 7.3rem !important; }
  .my-md-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-md-71 {
    margin-top: 7.1rem !important;
    margin-bottom: 7.1rem !important; }
  .my-md-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-md-69 {
    margin-top: 6.9rem !important;
    margin-bottom: 6.9rem !important; }
  .my-md-68 {
    margin-top: 6.8rem !important;
    margin-bottom: 6.8rem !important; }
  .my-md-67 {
    margin-top: 6.7rem !important;
    margin-bottom: 6.7rem !important; }
  .my-md-66 {
    margin-top: 6.6rem !important;
    margin-bottom: 6.6rem !important; }
  .my-md-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-md-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-md-63 {
    margin-top: 6.3rem !important;
    margin-bottom: 6.3rem !important; }
  .my-md-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-md-61 {
    margin-top: 6.1rem !important;
    margin-bottom: 6.1rem !important; }
  .my-md-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-md-59 {
    margin-top: 5.9rem !important;
    margin-bottom: 5.9rem !important; }
  .my-md-58 {
    margin-top: 5.8rem !important;
    margin-bottom: 5.8rem !important; }
  .my-md-57 {
    margin-top: 5.7rem !important;
    margin-bottom: 5.7rem !important; }
  .my-md-56 {
    margin-top: 5.6rem !important;
    margin-bottom: 5.6rem !important; }
  .my-md-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-md-54 {
    margin-top: 5.4rem !important;
    margin-bottom: 5.4rem !important; }
  .my-md-53 {
    margin-top: 5.3rem !important;
    margin-bottom: 5.3rem !important; }
  .my-md-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-md-51 {
    margin-top: 5.1rem !important;
    margin-bottom: 5.1rem !important; }
  .my-md-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-md-49 {
    margin-top: 4.9rem !important;
    margin-bottom: 4.9rem !important; }
  .my-md-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-md-47 {
    margin-top: 4.7rem !important;
    margin-bottom: 4.7rem !important; }
  .my-md-46 {
    margin-top: 4.6rem !important;
    margin-bottom: 4.6rem !important; }
  .my-md-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-md-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-md-43 {
    margin-top: 4.3rem !important;
    margin-bottom: 4.3rem !important; }
  .my-md-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-md-41 {
    margin-top: 4.1rem !important;
    margin-bottom: 4.1rem !important; }
  .my-md-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-md-39 {
    margin-top: 3.9rem !important;
    margin-bottom: 3.9rem !important; }
  .my-md-38 {
    margin-top: 3.8rem !important;
    margin-bottom: 3.8rem !important; }
  .my-md-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-md-36 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important; }
  .my-md-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-md-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-md-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-md-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-md-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-md-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-md-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-md-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-md-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-md-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-md-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-md-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-md-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-md-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-md-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-md-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-md-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-md-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-md-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-md-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-md-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-md-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-md-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-md-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .my-md-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .my-md-n150 {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-150 {
    margin-top: 15rem !important; }
  .mt-md-149 {
    margin-top: 14.9rem !important; }
  .mt-md-148 {
    margin-top: 14.8rem !important; }
  .mt-md-147 {
    margin-top: 14.7rem !important; }
  .mt-md-146 {
    margin-top: 14.6rem !important; }
  .mt-md-145 {
    margin-top: 14.5rem !important; }
  .mt-md-144 {
    margin-top: 14.4rem !important; }
  .mt-md-143 {
    margin-top: 14.3rem !important; }
  .mt-md-142 {
    margin-top: 14.2rem !important; }
  .mt-md-141 {
    margin-top: 14.1rem !important; }
  .mt-md-140 {
    margin-top: 14rem !important; }
  .mt-md-139 {
    margin-top: 13.9rem !important; }
  .mt-md-138 {
    margin-top: 13.8rem !important; }
  .mt-md-137 {
    margin-top: 13.7rem !important; }
  .mt-md-136 {
    margin-top: 13.6rem !important; }
  .mt-md-135 {
    margin-top: 13.5rem !important; }
  .mt-md-134 {
    margin-top: 13.4rem !important; }
  .mt-md-133 {
    margin-top: 13.3rem !important; }
  .mt-md-132 {
    margin-top: 13.2rem !important; }
  .mt-md-131 {
    margin-top: 13.1rem !important; }
  .mt-md-130 {
    margin-top: 13rem !important; }
  .mt-md-129 {
    margin-top: 12.9rem !important; }
  .mt-md-128 {
    margin-top: 12.8rem !important; }
  .mt-md-127 {
    margin-top: 12.7rem !important; }
  .mt-md-126 {
    margin-top: 12.6rem !important; }
  .mt-md-125 {
    margin-top: 12.5rem !important; }
  .mt-md-124 {
    margin-top: 12.4rem !important; }
  .mt-md-123 {
    margin-top: 12.3rem !important; }
  .mt-md-122 {
    margin-top: 12.2rem !important; }
  .mt-md-121 {
    margin-top: 12.1rem !important; }
  .mt-md-120 {
    margin-top: 12rem !important; }
  .mt-md-119 {
    margin-top: 11.9rem !important; }
  .mt-md-118 {
    margin-top: 11.8rem !important; }
  .mt-md-117 {
    margin-top: 11.7rem !important; }
  .mt-md-116 {
    margin-top: 11.6rem !important; }
  .mt-md-115 {
    margin-top: 11.5rem !important; }
  .mt-md-114 {
    margin-top: 11.4rem !important; }
  .mt-md-113 {
    margin-top: 11.3rem !important; }
  .mt-md-112 {
    margin-top: 11.2rem !important; }
  .mt-md-111 {
    margin-top: 11.1rem !important; }
  .mt-md-110 {
    margin-top: 11rem !important; }
  .mt-md-109 {
    margin-top: 10.9rem !important; }
  .mt-md-108 {
    margin-top: 10.8rem !important; }
  .mt-md-107 {
    margin-top: 10.7rem !important; }
  .mt-md-106 {
    margin-top: 10.6rem !important; }
  .mt-md-105 {
    margin-top: 10.5rem !important; }
  .mt-md-104 {
    margin-top: 10.4rem !important; }
  .mt-md-103 {
    margin-top: 10.3rem !important; }
  .mt-md-102 {
    margin-top: 10.2rem !important; }
  .mt-md-101 {
    margin-top: 10.1rem !important; }
  .mt-md-100 {
    margin-top: 10rem !important; }
  .mt-md-99 {
    margin-top: 9.9rem !important; }
  .mt-md-98 {
    margin-top: 9.8rem !important; }
  .mt-md-97 {
    margin-top: 9.7rem !important; }
  .mt-md-96 {
    margin-top: 9.6rem !important; }
  .mt-md-95 {
    margin-top: 9.5rem !important; }
  .mt-md-94 {
    margin-top: 9.4rem !important; }
  .mt-md-93 {
    margin-top: 9.3rem !important; }
  .mt-md-92 {
    margin-top: 9.2rem !important; }
  .mt-md-91 {
    margin-top: 9.1rem !important; }
  .mt-md-90 {
    margin-top: 9rem !important; }
  .mt-md-89 {
    margin-top: 8.9rem !important; }
  .mt-md-88 {
    margin-top: 8.8rem !important; }
  .mt-md-87 {
    margin-top: 8.7rem !important; }
  .mt-md-86 {
    margin-top: 8.6rem !important; }
  .mt-md-85 {
    margin-top: 8.5rem !important; }
  .mt-md-84 {
    margin-top: 8.4rem !important; }
  .mt-md-83 {
    margin-top: 8.3rem !important; }
  .mt-md-82 {
    margin-top: 8.2rem !important; }
  .mt-md-81 {
    margin-top: 8.1rem !important; }
  .mt-md-80 {
    margin-top: 8rem !important; }
  .mt-md-79 {
    margin-top: 7.9rem !important; }
  .mt-md-78 {
    margin-top: 7.8rem !important; }
  .mt-md-77 {
    margin-top: 7.7rem !important; }
  .mt-md-76 {
    margin-top: 7.6rem !important; }
  .mt-md-75 {
    margin-top: 7.5rem !important; }
  .mt-md-74 {
    margin-top: 7.4rem !important; }
  .mt-md-73 {
    margin-top: 7.3rem !important; }
  .mt-md-72 {
    margin-top: 7.2rem !important; }
  .mt-md-71 {
    margin-top: 7.1rem !important; }
  .mt-md-70 {
    margin-top: 7rem !important; }
  .mt-md-69 {
    margin-top: 6.9rem !important; }
  .mt-md-68 {
    margin-top: 6.8rem !important; }
  .mt-md-67 {
    margin-top: 6.7rem !important; }
  .mt-md-66 {
    margin-top: 6.6rem !important; }
  .mt-md-65 {
    margin-top: 6.5rem !important; }
  .mt-md-64 {
    margin-top: 6.4rem !important; }
  .mt-md-63 {
    margin-top: 6.3rem !important; }
  .mt-md-62 {
    margin-top: 6.2rem !important; }
  .mt-md-61 {
    margin-top: 6.1rem !important; }
  .mt-md-60 {
    margin-top: 6rem !important; }
  .mt-md-59 {
    margin-top: 5.9rem !important; }
  .mt-md-58 {
    margin-top: 5.8rem !important; }
  .mt-md-57 {
    margin-top: 5.7rem !important; }
  .mt-md-56 {
    margin-top: 5.6rem !important; }
  .mt-md-55 {
    margin-top: 5.5rem !important; }
  .mt-md-54 {
    margin-top: 5.4rem !important; }
  .mt-md-53 {
    margin-top: 5.3rem !important; }
  .mt-md-52 {
    margin-top: 5.2rem !important; }
  .mt-md-51 {
    margin-top: 5.1rem !important; }
  .mt-md-50 {
    margin-top: 5rem !important; }
  .mt-md-49 {
    margin-top: 4.9rem !important; }
  .mt-md-48 {
    margin-top: 4.8rem !important; }
  .mt-md-47 {
    margin-top: 4.7rem !important; }
  .mt-md-46 {
    margin-top: 4.6rem !important; }
  .mt-md-45 {
    margin-top: 4.5rem !important; }
  .mt-md-44 {
    margin-top: 4.4rem !important; }
  .mt-md-43 {
    margin-top: 4.3rem !important; }
  .mt-md-42 {
    margin-top: 4.2rem !important; }
  .mt-md-41 {
    margin-top: 4.1rem !important; }
  .mt-md-40 {
    margin-top: 4rem !important; }
  .mt-md-39 {
    margin-top: 3.9rem !important; }
  .mt-md-38 {
    margin-top: 3.8rem !important; }
  .mt-md-37 {
    margin-top: 3.7rem !important; }
  .mt-md-36 {
    margin-top: 3.6rem !important; }
  .mt-md-35 {
    margin-top: 3.5rem !important; }
  .mt-md-34 {
    margin-top: 3.4rem !important; }
  .mt-md-33 {
    margin-top: 3.3rem !important; }
  .mt-md-32 {
    margin-top: 3.2rem !important; }
  .mt-md-31 {
    margin-top: 3.1rem !important; }
  .mt-md-30 {
    margin-top: 3rem !important; }
  .mt-md-29 {
    margin-top: 2.9rem !important; }
  .mt-md-28 {
    margin-top: 2.8rem !important; }
  .mt-md-27 {
    margin-top: 2.7rem !important; }
  .mt-md-26 {
    margin-top: 2.6rem !important; }
  .mt-md-25 {
    margin-top: 2.5rem !important; }
  .mt-md-24 {
    margin-top: 2.4rem !important; }
  .mt-md-23 {
    margin-top: 2.3rem !important; }
  .mt-md-22 {
    margin-top: 2.2rem !important; }
  .mt-md-21 {
    margin-top: 2.1rem !important; }
  .mt-md-20 {
    margin-top: 2rem !important; }
  .mt-md-19 {
    margin-top: 1.9rem !important; }
  .mt-md-18 {
    margin-top: 1.8rem !important; }
  .mt-md-17 {
    margin-top: 1.7rem !important; }
  .mt-md-16 {
    margin-top: 1.6rem !important; }
  .mt-md-15 {
    margin-top: 1.5rem !important; }
  .mt-md-14 {
    margin-top: 1.4rem !important; }
  .mt-md-13 {
    margin-top: 1.3rem !important; }
  .mt-md-12 {
    margin-top: 1.2rem !important; }
  .mt-md-11 {
    margin-top: 1.1rem !important; }
  .mt-md-10 {
    margin-top: 1rem !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-n80 {
    margin-top: -80px !important; }
  .mt-md-n100 {
    margin-top: -100px !important; }
  .mt-md-n150 {
    margin-top: -150px !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-150 {
    margin-right: 15rem !important; }
  .me-md-149 {
    margin-right: 14.9rem !important; }
  .me-md-148 {
    margin-right: 14.8rem !important; }
  .me-md-147 {
    margin-right: 14.7rem !important; }
  .me-md-146 {
    margin-right: 14.6rem !important; }
  .me-md-145 {
    margin-right: 14.5rem !important; }
  .me-md-144 {
    margin-right: 14.4rem !important; }
  .me-md-143 {
    margin-right: 14.3rem !important; }
  .me-md-142 {
    margin-right: 14.2rem !important; }
  .me-md-141 {
    margin-right: 14.1rem !important; }
  .me-md-140 {
    margin-right: 14rem !important; }
  .me-md-139 {
    margin-right: 13.9rem !important; }
  .me-md-138 {
    margin-right: 13.8rem !important; }
  .me-md-137 {
    margin-right: 13.7rem !important; }
  .me-md-136 {
    margin-right: 13.6rem !important; }
  .me-md-135 {
    margin-right: 13.5rem !important; }
  .me-md-134 {
    margin-right: 13.4rem !important; }
  .me-md-133 {
    margin-right: 13.3rem !important; }
  .me-md-132 {
    margin-right: 13.2rem !important; }
  .me-md-131 {
    margin-right: 13.1rem !important; }
  .me-md-130 {
    margin-right: 13rem !important; }
  .me-md-129 {
    margin-right: 12.9rem !important; }
  .me-md-128 {
    margin-right: 12.8rem !important; }
  .me-md-127 {
    margin-right: 12.7rem !important; }
  .me-md-126 {
    margin-right: 12.6rem !important; }
  .me-md-125 {
    margin-right: 12.5rem !important; }
  .me-md-124 {
    margin-right: 12.4rem !important; }
  .me-md-123 {
    margin-right: 12.3rem !important; }
  .me-md-122 {
    margin-right: 12.2rem !important; }
  .me-md-121 {
    margin-right: 12.1rem !important; }
  .me-md-120 {
    margin-right: 12rem !important; }
  .me-md-119 {
    margin-right: 11.9rem !important; }
  .me-md-118 {
    margin-right: 11.8rem !important; }
  .me-md-117 {
    margin-right: 11.7rem !important; }
  .me-md-116 {
    margin-right: 11.6rem !important; }
  .me-md-115 {
    margin-right: 11.5rem !important; }
  .me-md-114 {
    margin-right: 11.4rem !important; }
  .me-md-113 {
    margin-right: 11.3rem !important; }
  .me-md-112 {
    margin-right: 11.2rem !important; }
  .me-md-111 {
    margin-right: 11.1rem !important; }
  .me-md-110 {
    margin-right: 11rem !important; }
  .me-md-109 {
    margin-right: 10.9rem !important; }
  .me-md-108 {
    margin-right: 10.8rem !important; }
  .me-md-107 {
    margin-right: 10.7rem !important; }
  .me-md-106 {
    margin-right: 10.6rem !important; }
  .me-md-105 {
    margin-right: 10.5rem !important; }
  .me-md-104 {
    margin-right: 10.4rem !important; }
  .me-md-103 {
    margin-right: 10.3rem !important; }
  .me-md-102 {
    margin-right: 10.2rem !important; }
  .me-md-101 {
    margin-right: 10.1rem !important; }
  .me-md-100 {
    margin-right: 10rem !important; }
  .me-md-99 {
    margin-right: 9.9rem !important; }
  .me-md-98 {
    margin-right: 9.8rem !important; }
  .me-md-97 {
    margin-right: 9.7rem !important; }
  .me-md-96 {
    margin-right: 9.6rem !important; }
  .me-md-95 {
    margin-right: 9.5rem !important; }
  .me-md-94 {
    margin-right: 9.4rem !important; }
  .me-md-93 {
    margin-right: 9.3rem !important; }
  .me-md-92 {
    margin-right: 9.2rem !important; }
  .me-md-91 {
    margin-right: 9.1rem !important; }
  .me-md-90 {
    margin-right: 9rem !important; }
  .me-md-89 {
    margin-right: 8.9rem !important; }
  .me-md-88 {
    margin-right: 8.8rem !important; }
  .me-md-87 {
    margin-right: 8.7rem !important; }
  .me-md-86 {
    margin-right: 8.6rem !important; }
  .me-md-85 {
    margin-right: 8.5rem !important; }
  .me-md-84 {
    margin-right: 8.4rem !important; }
  .me-md-83 {
    margin-right: 8.3rem !important; }
  .me-md-82 {
    margin-right: 8.2rem !important; }
  .me-md-81 {
    margin-right: 8.1rem !important; }
  .me-md-80 {
    margin-right: 8rem !important; }
  .me-md-79 {
    margin-right: 7.9rem !important; }
  .me-md-78 {
    margin-right: 7.8rem !important; }
  .me-md-77 {
    margin-right: 7.7rem !important; }
  .me-md-76 {
    margin-right: 7.6rem !important; }
  .me-md-75 {
    margin-right: 7.5rem !important; }
  .me-md-74 {
    margin-right: 7.4rem !important; }
  .me-md-73 {
    margin-right: 7.3rem !important; }
  .me-md-72 {
    margin-right: 7.2rem !important; }
  .me-md-71 {
    margin-right: 7.1rem !important; }
  .me-md-70 {
    margin-right: 7rem !important; }
  .me-md-69 {
    margin-right: 6.9rem !important; }
  .me-md-68 {
    margin-right: 6.8rem !important; }
  .me-md-67 {
    margin-right: 6.7rem !important; }
  .me-md-66 {
    margin-right: 6.6rem !important; }
  .me-md-65 {
    margin-right: 6.5rem !important; }
  .me-md-64 {
    margin-right: 6.4rem !important; }
  .me-md-63 {
    margin-right: 6.3rem !important; }
  .me-md-62 {
    margin-right: 6.2rem !important; }
  .me-md-61 {
    margin-right: 6.1rem !important; }
  .me-md-60 {
    margin-right: 6rem !important; }
  .me-md-59 {
    margin-right: 5.9rem !important; }
  .me-md-58 {
    margin-right: 5.8rem !important; }
  .me-md-57 {
    margin-right: 5.7rem !important; }
  .me-md-56 {
    margin-right: 5.6rem !important; }
  .me-md-55 {
    margin-right: 5.5rem !important; }
  .me-md-54 {
    margin-right: 5.4rem !important; }
  .me-md-53 {
    margin-right: 5.3rem !important; }
  .me-md-52 {
    margin-right: 5.2rem !important; }
  .me-md-51 {
    margin-right: 5.1rem !important; }
  .me-md-50 {
    margin-right: 5rem !important; }
  .me-md-49 {
    margin-right: 4.9rem !important; }
  .me-md-48 {
    margin-right: 4.8rem !important; }
  .me-md-47 {
    margin-right: 4.7rem !important; }
  .me-md-46 {
    margin-right: 4.6rem !important; }
  .me-md-45 {
    margin-right: 4.5rem !important; }
  .me-md-44 {
    margin-right: 4.4rem !important; }
  .me-md-43 {
    margin-right: 4.3rem !important; }
  .me-md-42 {
    margin-right: 4.2rem !important; }
  .me-md-41 {
    margin-right: 4.1rem !important; }
  .me-md-40 {
    margin-right: 4rem !important; }
  .me-md-39 {
    margin-right: 3.9rem !important; }
  .me-md-38 {
    margin-right: 3.8rem !important; }
  .me-md-37 {
    margin-right: 3.7rem !important; }
  .me-md-36 {
    margin-right: 3.6rem !important; }
  .me-md-35 {
    margin-right: 3.5rem !important; }
  .me-md-34 {
    margin-right: 3.4rem !important; }
  .me-md-33 {
    margin-right: 3.3rem !important; }
  .me-md-32 {
    margin-right: 3.2rem !important; }
  .me-md-31 {
    margin-right: 3.1rem !important; }
  .me-md-30 {
    margin-right: 3rem !important; }
  .me-md-29 {
    margin-right: 2.9rem !important; }
  .me-md-28 {
    margin-right: 2.8rem !important; }
  .me-md-27 {
    margin-right: 2.7rem !important; }
  .me-md-26 {
    margin-right: 2.6rem !important; }
  .me-md-25 {
    margin-right: 2.5rem !important; }
  .me-md-24 {
    margin-right: 2.4rem !important; }
  .me-md-23 {
    margin-right: 2.3rem !important; }
  .me-md-22 {
    margin-right: 2.2rem !important; }
  .me-md-21 {
    margin-right: 2.1rem !important; }
  .me-md-20 {
    margin-right: 2rem !important; }
  .me-md-19 {
    margin-right: 1.9rem !important; }
  .me-md-18 {
    margin-right: 1.8rem !important; }
  .me-md-17 {
    margin-right: 1.7rem !important; }
  .me-md-16 {
    margin-right: 1.6rem !important; }
  .me-md-15 {
    margin-right: 1.5rem !important; }
  .me-md-14 {
    margin-right: 1.4rem !important; }
  .me-md-13 {
    margin-right: 1.3rem !important; }
  .me-md-12 {
    margin-right: 1.2rem !important; }
  .me-md-11 {
    margin-right: 1.1rem !important; }
  .me-md-10 {
    margin-right: 1rem !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-n80 {
    margin-right: -80px !important; }
  .me-md-n100 {
    margin-right: -100px !important; }
  .me-md-n150 {
    margin-right: -150px !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-150 {
    margin-bottom: 15rem !important; }
  .mb-md-149 {
    margin-bottom: 14.9rem !important; }
  .mb-md-148 {
    margin-bottom: 14.8rem !important; }
  .mb-md-147 {
    margin-bottom: 14.7rem !important; }
  .mb-md-146 {
    margin-bottom: 14.6rem !important; }
  .mb-md-145 {
    margin-bottom: 14.5rem !important; }
  .mb-md-144 {
    margin-bottom: 14.4rem !important; }
  .mb-md-143 {
    margin-bottom: 14.3rem !important; }
  .mb-md-142 {
    margin-bottom: 14.2rem !important; }
  .mb-md-141 {
    margin-bottom: 14.1rem !important; }
  .mb-md-140 {
    margin-bottom: 14rem !important; }
  .mb-md-139 {
    margin-bottom: 13.9rem !important; }
  .mb-md-138 {
    margin-bottom: 13.8rem !important; }
  .mb-md-137 {
    margin-bottom: 13.7rem !important; }
  .mb-md-136 {
    margin-bottom: 13.6rem !important; }
  .mb-md-135 {
    margin-bottom: 13.5rem !important; }
  .mb-md-134 {
    margin-bottom: 13.4rem !important; }
  .mb-md-133 {
    margin-bottom: 13.3rem !important; }
  .mb-md-132 {
    margin-bottom: 13.2rem !important; }
  .mb-md-131 {
    margin-bottom: 13.1rem !important; }
  .mb-md-130 {
    margin-bottom: 13rem !important; }
  .mb-md-129 {
    margin-bottom: 12.9rem !important; }
  .mb-md-128 {
    margin-bottom: 12.8rem !important; }
  .mb-md-127 {
    margin-bottom: 12.7rem !important; }
  .mb-md-126 {
    margin-bottom: 12.6rem !important; }
  .mb-md-125 {
    margin-bottom: 12.5rem !important; }
  .mb-md-124 {
    margin-bottom: 12.4rem !important; }
  .mb-md-123 {
    margin-bottom: 12.3rem !important; }
  .mb-md-122 {
    margin-bottom: 12.2rem !important; }
  .mb-md-121 {
    margin-bottom: 12.1rem !important; }
  .mb-md-120 {
    margin-bottom: 12rem !important; }
  .mb-md-119 {
    margin-bottom: 11.9rem !important; }
  .mb-md-118 {
    margin-bottom: 11.8rem !important; }
  .mb-md-117 {
    margin-bottom: 11.7rem !important; }
  .mb-md-116 {
    margin-bottom: 11.6rem !important; }
  .mb-md-115 {
    margin-bottom: 11.5rem !important; }
  .mb-md-114 {
    margin-bottom: 11.4rem !important; }
  .mb-md-113 {
    margin-bottom: 11.3rem !important; }
  .mb-md-112 {
    margin-bottom: 11.2rem !important; }
  .mb-md-111 {
    margin-bottom: 11.1rem !important; }
  .mb-md-110 {
    margin-bottom: 11rem !important; }
  .mb-md-109 {
    margin-bottom: 10.9rem !important; }
  .mb-md-108 {
    margin-bottom: 10.8rem !important; }
  .mb-md-107 {
    margin-bottom: 10.7rem !important; }
  .mb-md-106 {
    margin-bottom: 10.6rem !important; }
  .mb-md-105 {
    margin-bottom: 10.5rem !important; }
  .mb-md-104 {
    margin-bottom: 10.4rem !important; }
  .mb-md-103 {
    margin-bottom: 10.3rem !important; }
  .mb-md-102 {
    margin-bottom: 10.2rem !important; }
  .mb-md-101 {
    margin-bottom: 10.1rem !important; }
  .mb-md-100 {
    margin-bottom: 10rem !important; }
  .mb-md-99 {
    margin-bottom: 9.9rem !important; }
  .mb-md-98 {
    margin-bottom: 9.8rem !important; }
  .mb-md-97 {
    margin-bottom: 9.7rem !important; }
  .mb-md-96 {
    margin-bottom: 9.6rem !important; }
  .mb-md-95 {
    margin-bottom: 9.5rem !important; }
  .mb-md-94 {
    margin-bottom: 9.4rem !important; }
  .mb-md-93 {
    margin-bottom: 9.3rem !important; }
  .mb-md-92 {
    margin-bottom: 9.2rem !important; }
  .mb-md-91 {
    margin-bottom: 9.1rem !important; }
  .mb-md-90 {
    margin-bottom: 9rem !important; }
  .mb-md-89 {
    margin-bottom: 8.9rem !important; }
  .mb-md-88 {
    margin-bottom: 8.8rem !important; }
  .mb-md-87 {
    margin-bottom: 8.7rem !important; }
  .mb-md-86 {
    margin-bottom: 8.6rem !important; }
  .mb-md-85 {
    margin-bottom: 8.5rem !important; }
  .mb-md-84 {
    margin-bottom: 8.4rem !important; }
  .mb-md-83 {
    margin-bottom: 8.3rem !important; }
  .mb-md-82 {
    margin-bottom: 8.2rem !important; }
  .mb-md-81 {
    margin-bottom: 8.1rem !important; }
  .mb-md-80 {
    margin-bottom: 8rem !important; }
  .mb-md-79 {
    margin-bottom: 7.9rem !important; }
  .mb-md-78 {
    margin-bottom: 7.8rem !important; }
  .mb-md-77 {
    margin-bottom: 7.7rem !important; }
  .mb-md-76 {
    margin-bottom: 7.6rem !important; }
  .mb-md-75 {
    margin-bottom: 7.5rem !important; }
  .mb-md-74 {
    margin-bottom: 7.4rem !important; }
  .mb-md-73 {
    margin-bottom: 7.3rem !important; }
  .mb-md-72 {
    margin-bottom: 7.2rem !important; }
  .mb-md-71 {
    margin-bottom: 7.1rem !important; }
  .mb-md-70 {
    margin-bottom: 7rem !important; }
  .mb-md-69 {
    margin-bottom: 6.9rem !important; }
  .mb-md-68 {
    margin-bottom: 6.8rem !important; }
  .mb-md-67 {
    margin-bottom: 6.7rem !important; }
  .mb-md-66 {
    margin-bottom: 6.6rem !important; }
  .mb-md-65 {
    margin-bottom: 6.5rem !important; }
  .mb-md-64 {
    margin-bottom: 6.4rem !important; }
  .mb-md-63 {
    margin-bottom: 6.3rem !important; }
  .mb-md-62 {
    margin-bottom: 6.2rem !important; }
  .mb-md-61 {
    margin-bottom: 6.1rem !important; }
  .mb-md-60 {
    margin-bottom: 6rem !important; }
  .mb-md-59 {
    margin-bottom: 5.9rem !important; }
  .mb-md-58 {
    margin-bottom: 5.8rem !important; }
  .mb-md-57 {
    margin-bottom: 5.7rem !important; }
  .mb-md-56 {
    margin-bottom: 5.6rem !important; }
  .mb-md-55 {
    margin-bottom: 5.5rem !important; }
  .mb-md-54 {
    margin-bottom: 5.4rem !important; }
  .mb-md-53 {
    margin-bottom: 5.3rem !important; }
  .mb-md-52 {
    margin-bottom: 5.2rem !important; }
  .mb-md-51 {
    margin-bottom: 5.1rem !important; }
  .mb-md-50 {
    margin-bottom: 5rem !important; }
  .mb-md-49 {
    margin-bottom: 4.9rem !important; }
  .mb-md-48 {
    margin-bottom: 4.8rem !important; }
  .mb-md-47 {
    margin-bottom: 4.7rem !important; }
  .mb-md-46 {
    margin-bottom: 4.6rem !important; }
  .mb-md-45 {
    margin-bottom: 4.5rem !important; }
  .mb-md-44 {
    margin-bottom: 4.4rem !important; }
  .mb-md-43 {
    margin-bottom: 4.3rem !important; }
  .mb-md-42 {
    margin-bottom: 4.2rem !important; }
  .mb-md-41 {
    margin-bottom: 4.1rem !important; }
  .mb-md-40 {
    margin-bottom: 4rem !important; }
  .mb-md-39 {
    margin-bottom: 3.9rem !important; }
  .mb-md-38 {
    margin-bottom: 3.8rem !important; }
  .mb-md-37 {
    margin-bottom: 3.7rem !important; }
  .mb-md-36 {
    margin-bottom: 3.6rem !important; }
  .mb-md-35 {
    margin-bottom: 3.5rem !important; }
  .mb-md-34 {
    margin-bottom: 3.4rem !important; }
  .mb-md-33 {
    margin-bottom: 3.3rem !important; }
  .mb-md-32 {
    margin-bottom: 3.2rem !important; }
  .mb-md-31 {
    margin-bottom: 3.1rem !important; }
  .mb-md-30 {
    margin-bottom: 3rem !important; }
  .mb-md-29 {
    margin-bottom: 2.9rem !important; }
  .mb-md-28 {
    margin-bottom: 2.8rem !important; }
  .mb-md-27 {
    margin-bottom: 2.7rem !important; }
  .mb-md-26 {
    margin-bottom: 2.6rem !important; }
  .mb-md-25 {
    margin-bottom: 2.5rem !important; }
  .mb-md-24 {
    margin-bottom: 2.4rem !important; }
  .mb-md-23 {
    margin-bottom: 2.3rem !important; }
  .mb-md-22 {
    margin-bottom: 2.2rem !important; }
  .mb-md-21 {
    margin-bottom: 2.1rem !important; }
  .mb-md-20 {
    margin-bottom: 2rem !important; }
  .mb-md-19 {
    margin-bottom: 1.9rem !important; }
  .mb-md-18 {
    margin-bottom: 1.8rem !important; }
  .mb-md-17 {
    margin-bottom: 1.7rem !important; }
  .mb-md-16 {
    margin-bottom: 1.6rem !important; }
  .mb-md-15 {
    margin-bottom: 1.5rem !important; }
  .mb-md-14 {
    margin-bottom: 1.4rem !important; }
  .mb-md-13 {
    margin-bottom: 1.3rem !important; }
  .mb-md-12 {
    margin-bottom: 1.2rem !important; }
  .mb-md-11 {
    margin-bottom: 1.1rem !important; }
  .mb-md-10 {
    margin-bottom: 1rem !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-n80 {
    margin-bottom: -80px !important; }
  .mb-md-n100 {
    margin-bottom: -100px !important; }
  .mb-md-n150 {
    margin-bottom: -150px !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-150 {
    margin-left: 15rem !important; }
  .ms-md-149 {
    margin-left: 14.9rem !important; }
  .ms-md-148 {
    margin-left: 14.8rem !important; }
  .ms-md-147 {
    margin-left: 14.7rem !important; }
  .ms-md-146 {
    margin-left: 14.6rem !important; }
  .ms-md-145 {
    margin-left: 14.5rem !important; }
  .ms-md-144 {
    margin-left: 14.4rem !important; }
  .ms-md-143 {
    margin-left: 14.3rem !important; }
  .ms-md-142 {
    margin-left: 14.2rem !important; }
  .ms-md-141 {
    margin-left: 14.1rem !important; }
  .ms-md-140 {
    margin-left: 14rem !important; }
  .ms-md-139 {
    margin-left: 13.9rem !important; }
  .ms-md-138 {
    margin-left: 13.8rem !important; }
  .ms-md-137 {
    margin-left: 13.7rem !important; }
  .ms-md-136 {
    margin-left: 13.6rem !important; }
  .ms-md-135 {
    margin-left: 13.5rem !important; }
  .ms-md-134 {
    margin-left: 13.4rem !important; }
  .ms-md-133 {
    margin-left: 13.3rem !important; }
  .ms-md-132 {
    margin-left: 13.2rem !important; }
  .ms-md-131 {
    margin-left: 13.1rem !important; }
  .ms-md-130 {
    margin-left: 13rem !important; }
  .ms-md-129 {
    margin-left: 12.9rem !important; }
  .ms-md-128 {
    margin-left: 12.8rem !important; }
  .ms-md-127 {
    margin-left: 12.7rem !important; }
  .ms-md-126 {
    margin-left: 12.6rem !important; }
  .ms-md-125 {
    margin-left: 12.5rem !important; }
  .ms-md-124 {
    margin-left: 12.4rem !important; }
  .ms-md-123 {
    margin-left: 12.3rem !important; }
  .ms-md-122 {
    margin-left: 12.2rem !important; }
  .ms-md-121 {
    margin-left: 12.1rem !important; }
  .ms-md-120 {
    margin-left: 12rem !important; }
  .ms-md-119 {
    margin-left: 11.9rem !important; }
  .ms-md-118 {
    margin-left: 11.8rem !important; }
  .ms-md-117 {
    margin-left: 11.7rem !important; }
  .ms-md-116 {
    margin-left: 11.6rem !important; }
  .ms-md-115 {
    margin-left: 11.5rem !important; }
  .ms-md-114 {
    margin-left: 11.4rem !important; }
  .ms-md-113 {
    margin-left: 11.3rem !important; }
  .ms-md-112 {
    margin-left: 11.2rem !important; }
  .ms-md-111 {
    margin-left: 11.1rem !important; }
  .ms-md-110 {
    margin-left: 11rem !important; }
  .ms-md-109 {
    margin-left: 10.9rem !important; }
  .ms-md-108 {
    margin-left: 10.8rem !important; }
  .ms-md-107 {
    margin-left: 10.7rem !important; }
  .ms-md-106 {
    margin-left: 10.6rem !important; }
  .ms-md-105 {
    margin-left: 10.5rem !important; }
  .ms-md-104 {
    margin-left: 10.4rem !important; }
  .ms-md-103 {
    margin-left: 10.3rem !important; }
  .ms-md-102 {
    margin-left: 10.2rem !important; }
  .ms-md-101 {
    margin-left: 10.1rem !important; }
  .ms-md-100 {
    margin-left: 10rem !important; }
  .ms-md-99 {
    margin-left: 9.9rem !important; }
  .ms-md-98 {
    margin-left: 9.8rem !important; }
  .ms-md-97 {
    margin-left: 9.7rem !important; }
  .ms-md-96 {
    margin-left: 9.6rem !important; }
  .ms-md-95 {
    margin-left: 9.5rem !important; }
  .ms-md-94 {
    margin-left: 9.4rem !important; }
  .ms-md-93 {
    margin-left: 9.3rem !important; }
  .ms-md-92 {
    margin-left: 9.2rem !important; }
  .ms-md-91 {
    margin-left: 9.1rem !important; }
  .ms-md-90 {
    margin-left: 9rem !important; }
  .ms-md-89 {
    margin-left: 8.9rem !important; }
  .ms-md-88 {
    margin-left: 8.8rem !important; }
  .ms-md-87 {
    margin-left: 8.7rem !important; }
  .ms-md-86 {
    margin-left: 8.6rem !important; }
  .ms-md-85 {
    margin-left: 8.5rem !important; }
  .ms-md-84 {
    margin-left: 8.4rem !important; }
  .ms-md-83 {
    margin-left: 8.3rem !important; }
  .ms-md-82 {
    margin-left: 8.2rem !important; }
  .ms-md-81 {
    margin-left: 8.1rem !important; }
  .ms-md-80 {
    margin-left: 8rem !important; }
  .ms-md-79 {
    margin-left: 7.9rem !important; }
  .ms-md-78 {
    margin-left: 7.8rem !important; }
  .ms-md-77 {
    margin-left: 7.7rem !important; }
  .ms-md-76 {
    margin-left: 7.6rem !important; }
  .ms-md-75 {
    margin-left: 7.5rem !important; }
  .ms-md-74 {
    margin-left: 7.4rem !important; }
  .ms-md-73 {
    margin-left: 7.3rem !important; }
  .ms-md-72 {
    margin-left: 7.2rem !important; }
  .ms-md-71 {
    margin-left: 7.1rem !important; }
  .ms-md-70 {
    margin-left: 7rem !important; }
  .ms-md-69 {
    margin-left: 6.9rem !important; }
  .ms-md-68 {
    margin-left: 6.8rem !important; }
  .ms-md-67 {
    margin-left: 6.7rem !important; }
  .ms-md-66 {
    margin-left: 6.6rem !important; }
  .ms-md-65 {
    margin-left: 6.5rem !important; }
  .ms-md-64 {
    margin-left: 6.4rem !important; }
  .ms-md-63 {
    margin-left: 6.3rem !important; }
  .ms-md-62 {
    margin-left: 6.2rem !important; }
  .ms-md-61 {
    margin-left: 6.1rem !important; }
  .ms-md-60 {
    margin-left: 6rem !important; }
  .ms-md-59 {
    margin-left: 5.9rem !important; }
  .ms-md-58 {
    margin-left: 5.8rem !important; }
  .ms-md-57 {
    margin-left: 5.7rem !important; }
  .ms-md-56 {
    margin-left: 5.6rem !important; }
  .ms-md-55 {
    margin-left: 5.5rem !important; }
  .ms-md-54 {
    margin-left: 5.4rem !important; }
  .ms-md-53 {
    margin-left: 5.3rem !important; }
  .ms-md-52 {
    margin-left: 5.2rem !important; }
  .ms-md-51 {
    margin-left: 5.1rem !important; }
  .ms-md-50 {
    margin-left: 5rem !important; }
  .ms-md-49 {
    margin-left: 4.9rem !important; }
  .ms-md-48 {
    margin-left: 4.8rem !important; }
  .ms-md-47 {
    margin-left: 4.7rem !important; }
  .ms-md-46 {
    margin-left: 4.6rem !important; }
  .ms-md-45 {
    margin-left: 4.5rem !important; }
  .ms-md-44 {
    margin-left: 4.4rem !important; }
  .ms-md-43 {
    margin-left: 4.3rem !important; }
  .ms-md-42 {
    margin-left: 4.2rem !important; }
  .ms-md-41 {
    margin-left: 4.1rem !important; }
  .ms-md-40 {
    margin-left: 4rem !important; }
  .ms-md-39 {
    margin-left: 3.9rem !important; }
  .ms-md-38 {
    margin-left: 3.8rem !important; }
  .ms-md-37 {
    margin-left: 3.7rem !important; }
  .ms-md-36 {
    margin-left: 3.6rem !important; }
  .ms-md-35 {
    margin-left: 3.5rem !important; }
  .ms-md-34 {
    margin-left: 3.4rem !important; }
  .ms-md-33 {
    margin-left: 3.3rem !important; }
  .ms-md-32 {
    margin-left: 3.2rem !important; }
  .ms-md-31 {
    margin-left: 3.1rem !important; }
  .ms-md-30 {
    margin-left: 3rem !important; }
  .ms-md-29 {
    margin-left: 2.9rem !important; }
  .ms-md-28 {
    margin-left: 2.8rem !important; }
  .ms-md-27 {
    margin-left: 2.7rem !important; }
  .ms-md-26 {
    margin-left: 2.6rem !important; }
  .ms-md-25 {
    margin-left: 2.5rem !important; }
  .ms-md-24 {
    margin-left: 2.4rem !important; }
  .ms-md-23 {
    margin-left: 2.3rem !important; }
  .ms-md-22 {
    margin-left: 2.2rem !important; }
  .ms-md-21 {
    margin-left: 2.1rem !important; }
  .ms-md-20 {
    margin-left: 2rem !important; }
  .ms-md-19 {
    margin-left: 1.9rem !important; }
  .ms-md-18 {
    margin-left: 1.8rem !important; }
  .ms-md-17 {
    margin-left: 1.7rem !important; }
  .ms-md-16 {
    margin-left: 1.6rem !important; }
  .ms-md-15 {
    margin-left: 1.5rem !important; }
  .ms-md-14 {
    margin-left: 1.4rem !important; }
  .ms-md-13 {
    margin-left: 1.3rem !important; }
  .ms-md-12 {
    margin-left: 1.2rem !important; }
  .ms-md-11 {
    margin-left: 1.1rem !important; }
  .ms-md-10 {
    margin-left: 1rem !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-n80 {
    margin-left: -80px !important; }
  .ms-md-n100 {
    margin-left: -100px !important; }
  .ms-md-n150 {
    margin-left: -150px !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-150 {
    padding: 15rem !important; }
  .p-md-149 {
    padding: 14.9rem !important; }
  .p-md-148 {
    padding: 14.8rem !important; }
  .p-md-147 {
    padding: 14.7rem !important; }
  .p-md-146 {
    padding: 14.6rem !important; }
  .p-md-145 {
    padding: 14.5rem !important; }
  .p-md-144 {
    padding: 14.4rem !important; }
  .p-md-143 {
    padding: 14.3rem !important; }
  .p-md-142 {
    padding: 14.2rem !important; }
  .p-md-141 {
    padding: 14.1rem !important; }
  .p-md-140 {
    padding: 14rem !important; }
  .p-md-139 {
    padding: 13.9rem !important; }
  .p-md-138 {
    padding: 13.8rem !important; }
  .p-md-137 {
    padding: 13.7rem !important; }
  .p-md-136 {
    padding: 13.6rem !important; }
  .p-md-135 {
    padding: 13.5rem !important; }
  .p-md-134 {
    padding: 13.4rem !important; }
  .p-md-133 {
    padding: 13.3rem !important; }
  .p-md-132 {
    padding: 13.2rem !important; }
  .p-md-131 {
    padding: 13.1rem !important; }
  .p-md-130 {
    padding: 13rem !important; }
  .p-md-129 {
    padding: 12.9rem !important; }
  .p-md-128 {
    padding: 12.8rem !important; }
  .p-md-127 {
    padding: 12.7rem !important; }
  .p-md-126 {
    padding: 12.6rem !important; }
  .p-md-125 {
    padding: 12.5rem !important; }
  .p-md-124 {
    padding: 12.4rem !important; }
  .p-md-123 {
    padding: 12.3rem !important; }
  .p-md-122 {
    padding: 12.2rem !important; }
  .p-md-121 {
    padding: 12.1rem !important; }
  .p-md-120 {
    padding: 12rem !important; }
  .p-md-119 {
    padding: 11.9rem !important; }
  .p-md-118 {
    padding: 11.8rem !important; }
  .p-md-117 {
    padding: 11.7rem !important; }
  .p-md-116 {
    padding: 11.6rem !important; }
  .p-md-115 {
    padding: 11.5rem !important; }
  .p-md-114 {
    padding: 11.4rem !important; }
  .p-md-113 {
    padding: 11.3rem !important; }
  .p-md-112 {
    padding: 11.2rem !important; }
  .p-md-111 {
    padding: 11.1rem !important; }
  .p-md-110 {
    padding: 11rem !important; }
  .p-md-109 {
    padding: 10.9rem !important; }
  .p-md-108 {
    padding: 10.8rem !important; }
  .p-md-107 {
    padding: 10.7rem !important; }
  .p-md-106 {
    padding: 10.6rem !important; }
  .p-md-105 {
    padding: 10.5rem !important; }
  .p-md-104 {
    padding: 10.4rem !important; }
  .p-md-103 {
    padding: 10.3rem !important; }
  .p-md-102 {
    padding: 10.2rem !important; }
  .p-md-101 {
    padding: 10.1rem !important; }
  .p-md-100 {
    padding: 10rem !important; }
  .p-md-99 {
    padding: 9.9rem !important; }
  .p-md-98 {
    padding: 9.8rem !important; }
  .p-md-97 {
    padding: 9.7rem !important; }
  .p-md-96 {
    padding: 9.6rem !important; }
  .p-md-95 {
    padding: 9.5rem !important; }
  .p-md-94 {
    padding: 9.4rem !important; }
  .p-md-93 {
    padding: 9.3rem !important; }
  .p-md-92 {
    padding: 9.2rem !important; }
  .p-md-91 {
    padding: 9.1rem !important; }
  .p-md-90 {
    padding: 9rem !important; }
  .p-md-89 {
    padding: 8.9rem !important; }
  .p-md-88 {
    padding: 8.8rem !important; }
  .p-md-87 {
    padding: 8.7rem !important; }
  .p-md-86 {
    padding: 8.6rem !important; }
  .p-md-85 {
    padding: 8.5rem !important; }
  .p-md-84 {
    padding: 8.4rem !important; }
  .p-md-83 {
    padding: 8.3rem !important; }
  .p-md-82 {
    padding: 8.2rem !important; }
  .p-md-81 {
    padding: 8.1rem !important; }
  .p-md-80 {
    padding: 8rem !important; }
  .p-md-79 {
    padding: 7.9rem !important; }
  .p-md-78 {
    padding: 7.8rem !important; }
  .p-md-77 {
    padding: 7.7rem !important; }
  .p-md-76 {
    padding: 7.6rem !important; }
  .p-md-75 {
    padding: 7.5rem !important; }
  .p-md-74 {
    padding: 7.4rem !important; }
  .p-md-73 {
    padding: 7.3rem !important; }
  .p-md-72 {
    padding: 7.2rem !important; }
  .p-md-71 {
    padding: 7.1rem !important; }
  .p-md-70 {
    padding: 7rem !important; }
  .p-md-69 {
    padding: 6.9rem !important; }
  .p-md-68 {
    padding: 6.8rem !important; }
  .p-md-67 {
    padding: 6.7rem !important; }
  .p-md-66 {
    padding: 6.6rem !important; }
  .p-md-65 {
    padding: 6.5rem !important; }
  .p-md-64 {
    padding: 6.4rem !important; }
  .p-md-63 {
    padding: 6.3rem !important; }
  .p-md-62 {
    padding: 6.2rem !important; }
  .p-md-61 {
    padding: 6.1rem !important; }
  .p-md-60 {
    padding: 6rem !important; }
  .p-md-59 {
    padding: 5.9rem !important; }
  .p-md-58 {
    padding: 5.8rem !important; }
  .p-md-57 {
    padding: 5.7rem !important; }
  .p-md-56 {
    padding: 5.6rem !important; }
  .p-md-55 {
    padding: 5.5rem !important; }
  .p-md-54 {
    padding: 5.4rem !important; }
  .p-md-53 {
    padding: 5.3rem !important; }
  .p-md-52 {
    padding: 5.2rem !important; }
  .p-md-51 {
    padding: 5.1rem !important; }
  .p-md-50 {
    padding: 5rem !important; }
  .p-md-49 {
    padding: 4.9rem !important; }
  .p-md-48 {
    padding: 4.8rem !important; }
  .p-md-47 {
    padding: 4.7rem !important; }
  .p-md-46 {
    padding: 4.6rem !important; }
  .p-md-45 {
    padding: 4.5rem !important; }
  .p-md-44 {
    padding: 4.4rem !important; }
  .p-md-43 {
    padding: 4.3rem !important; }
  .p-md-42 {
    padding: 4.2rem !important; }
  .p-md-41 {
    padding: 4.1rem !important; }
  .p-md-40 {
    padding: 4rem !important; }
  .p-md-39 {
    padding: 3.9rem !important; }
  .p-md-38 {
    padding: 3.8rem !important; }
  .p-md-37 {
    padding: 3.7rem !important; }
  .p-md-36 {
    padding: 3.6rem !important; }
  .p-md-35 {
    padding: 3.5rem !important; }
  .p-md-34 {
    padding: 3.4rem !important; }
  .p-md-33 {
    padding: 3.3rem !important; }
  .p-md-32 {
    padding: 3.2rem !important; }
  .p-md-31 {
    padding: 3.1rem !important; }
  .p-md-30 {
    padding: 3rem !important; }
  .p-md-29 {
    padding: 2.9rem !important; }
  .p-md-28 {
    padding: 2.8rem !important; }
  .p-md-27 {
    padding: 2.7rem !important; }
  .p-md-26 {
    padding: 2.6rem !important; }
  .p-md-25 {
    padding: 2.5rem !important; }
  .p-md-24 {
    padding: 2.4rem !important; }
  .p-md-23 {
    padding: 2.3rem !important; }
  .p-md-22 {
    padding: 2.2rem !important; }
  .p-md-21 {
    padding: 2.1rem !important; }
  .p-md-20 {
    padding: 2rem !important; }
  .p-md-19 {
    padding: 1.9rem !important; }
  .p-md-18 {
    padding: 1.8rem !important; }
  .p-md-17 {
    padding: 1.7rem !important; }
  .p-md-16 {
    padding: 1.6rem !important; }
  .p-md-15 {
    padding: 1.5rem !important; }
  .p-md-14 {
    padding: 1.4rem !important; }
  .p-md-13 {
    padding: 1.3rem !important; }
  .p-md-12 {
    padding: 1.2rem !important; }
  .p-md-11 {
    padding: 1.1rem !important; }
  .p-md-10 {
    padding: 1rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .p-md-n80 {
    padding: -80px !important; }
  .p-md-n100 {
    padding: -100px !important; }
  .p-md-n150 {
    padding: -150px !important; }
  .px-md-150 {
    padding-right: 15rem !important;
    padding-left: 15rem !important; }
  .px-md-149 {
    padding-right: 14.9rem !important;
    padding-left: 14.9rem !important; }
  .px-md-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-md-147 {
    padding-right: 14.7rem !important;
    padding-left: 14.7rem !important; }
  .px-md-146 {
    padding-right: 14.6rem !important;
    padding-left: 14.6rem !important; }
  .px-md-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-md-144 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important; }
  .px-md-143 {
    padding-right: 14.3rem !important;
    padding-left: 14.3rem !important; }
  .px-md-142 {
    padding-right: 14.2rem !important;
    padding-left: 14.2rem !important; }
  .px-md-141 {
    padding-right: 14.1rem !important;
    padding-left: 14.1rem !important; }
  .px-md-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-md-139 {
    padding-right: 13.9rem !important;
    padding-left: 13.9rem !important; }
  .px-md-138 {
    padding-right: 13.8rem !important;
    padding-left: 13.8rem !important; }
  .px-md-137 {
    padding-right: 13.7rem !important;
    padding-left: 13.7rem !important; }
  .px-md-136 {
    padding-right: 13.6rem !important;
    padding-left: 13.6rem !important; }
  .px-md-135 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important; }
  .px-md-134 {
    padding-right: 13.4rem !important;
    padding-left: 13.4rem !important; }
  .px-md-133 {
    padding-right: 13.3rem !important;
    padding-left: 13.3rem !important; }
  .px-md-132 {
    padding-right: 13.2rem !important;
    padding-left: 13.2rem !important; }
  .px-md-131 {
    padding-right: 13.1rem !important;
    padding-left: 13.1rem !important; }
  .px-md-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-md-129 {
    padding-right: 12.9rem !important;
    padding-left: 12.9rem !important; }
  .px-md-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-md-127 {
    padding-right: 12.7rem !important;
    padding-left: 12.7rem !important; }
  .px-md-126 {
    padding-right: 12.6rem !important;
    padding-left: 12.6rem !important; }
  .px-md-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-md-124 {
    padding-right: 12.4rem !important;
    padding-left: 12.4rem !important; }
  .px-md-123 {
    padding-right: 12.3rem !important;
    padding-left: 12.3rem !important; }
  .px-md-122 {
    padding-right: 12.2rem !important;
    padding-left: 12.2rem !important; }
  .px-md-121 {
    padding-right: 12.1rem !important;
    padding-left: 12.1rem !important; }
  .px-md-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-md-119 {
    padding-right: 11.9rem !important;
    padding-left: 11.9rem !important; }
  .px-md-118 {
    padding-right: 11.8rem !important;
    padding-left: 11.8rem !important; }
  .px-md-117 {
    padding-right: 11.7rem !important;
    padding-left: 11.7rem !important; }
  .px-md-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-md-115 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .px-md-114 {
    padding-right: 11.4rem !important;
    padding-left: 11.4rem !important; }
  .px-md-113 {
    padding-right: 11.3rem !important;
    padding-left: 11.3rem !important; }
  .px-md-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .px-md-111 {
    padding-right: 11.1rem !important;
    padding-left: 11.1rem !important; }
  .px-md-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-md-109 {
    padding-right: 10.9rem !important;
    padding-left: 10.9rem !important; }
  .px-md-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-md-107 {
    padding-right: 10.7rem !important;
    padding-left: 10.7rem !important; }
  .px-md-106 {
    padding-right: 10.6rem !important;
    padding-left: 10.6rem !important; }
  .px-md-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-md-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-md-103 {
    padding-right: 10.3rem !important;
    padding-left: 10.3rem !important; }
  .px-md-102 {
    padding-right: 10.2rem !important;
    padding-left: 10.2rem !important; }
  .px-md-101 {
    padding-right: 10.1rem !important;
    padding-left: 10.1rem !important; }
  .px-md-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-md-99 {
    padding-right: 9.9rem !important;
    padding-left: 9.9rem !important; }
  .px-md-98 {
    padding-right: 9.8rem !important;
    padding-left: 9.8rem !important; }
  .px-md-97 {
    padding-right: 9.7rem !important;
    padding-left: 9.7rem !important; }
  .px-md-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-md-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-md-94 {
    padding-right: 9.4rem !important;
    padding-left: 9.4rem !important; }
  .px-md-93 {
    padding-right: 9.3rem !important;
    padding-left: 9.3rem !important; }
  .px-md-92 {
    padding-right: 9.2rem !important;
    padding-left: 9.2rem !important; }
  .px-md-91 {
    padding-right: 9.1rem !important;
    padding-left: 9.1rem !important; }
  .px-md-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-md-89 {
    padding-right: 8.9rem !important;
    padding-left: 8.9rem !important; }
  .px-md-88 {
    padding-right: 8.8rem !important;
    padding-left: 8.8rem !important; }
  .px-md-87 {
    padding-right: 8.7rem !important;
    padding-left: 8.7rem !important; }
  .px-md-86 {
    padding-right: 8.6rem !important;
    padding-left: 8.6rem !important; }
  .px-md-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-md-84 {
    padding-right: 8.4rem !important;
    padding-left: 8.4rem !important; }
  .px-md-83 {
    padding-right: 8.3rem !important;
    padding-left: 8.3rem !important; }
  .px-md-82 {
    padding-right: 8.2rem !important;
    padding-left: 8.2rem !important; }
  .px-md-81 {
    padding-right: 8.1rem !important;
    padding-left: 8.1rem !important; }
  .px-md-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-md-79 {
    padding-right: 7.9rem !important;
    padding-left: 7.9rem !important; }
  .px-md-78 {
    padding-right: 7.8rem !important;
    padding-left: 7.8rem !important; }
  .px-md-77 {
    padding-right: 7.7rem !important;
    padding-left: 7.7rem !important; }
  .px-md-76 {
    padding-right: 7.6rem !important;
    padding-left: 7.6rem !important; }
  .px-md-75 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-md-74 {
    padding-right: 7.4rem !important;
    padding-left: 7.4rem !important; }
  .px-md-73 {
    padding-right: 7.3rem !important;
    padding-left: 7.3rem !important; }
  .px-md-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-md-71 {
    padding-right: 7.1rem !important;
    padding-left: 7.1rem !important; }
  .px-md-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-md-69 {
    padding-right: 6.9rem !important;
    padding-left: 6.9rem !important; }
  .px-md-68 {
    padding-right: 6.8rem !important;
    padding-left: 6.8rem !important; }
  .px-md-67 {
    padding-right: 6.7rem !important;
    padding-left: 6.7rem !important; }
  .px-md-66 {
    padding-right: 6.6rem !important;
    padding-left: 6.6rem !important; }
  .px-md-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-md-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-md-63 {
    padding-right: 6.3rem !important;
    padding-left: 6.3rem !important; }
  .px-md-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-md-61 {
    padding-right: 6.1rem !important;
    padding-left: 6.1rem !important; }
  .px-md-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-md-59 {
    padding-right: 5.9rem !important;
    padding-left: 5.9rem !important; }
  .px-md-58 {
    padding-right: 5.8rem !important;
    padding-left: 5.8rem !important; }
  .px-md-57 {
    padding-right: 5.7rem !important;
    padding-left: 5.7rem !important; }
  .px-md-56 {
    padding-right: 5.6rem !important;
    padding-left: 5.6rem !important; }
  .px-md-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-md-54 {
    padding-right: 5.4rem !important;
    padding-left: 5.4rem !important; }
  .px-md-53 {
    padding-right: 5.3rem !important;
    padding-left: 5.3rem !important; }
  .px-md-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-md-51 {
    padding-right: 5.1rem !important;
    padding-left: 5.1rem !important; }
  .px-md-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-md-49 {
    padding-right: 4.9rem !important;
    padding-left: 4.9rem !important; }
  .px-md-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-md-47 {
    padding-right: 4.7rem !important;
    padding-left: 4.7rem !important; }
  .px-md-46 {
    padding-right: 4.6rem !important;
    padding-left: 4.6rem !important; }
  .px-md-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-md-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-md-43 {
    padding-right: 4.3rem !important;
    padding-left: 4.3rem !important; }
  .px-md-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-md-41 {
    padding-right: 4.1rem !important;
    padding-left: 4.1rem !important; }
  .px-md-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-md-39 {
    padding-right: 3.9rem !important;
    padding-left: 3.9rem !important; }
  .px-md-38 {
    padding-right: 3.8rem !important;
    padding-left: 3.8rem !important; }
  .px-md-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-md-36 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important; }
  .px-md-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-md-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-md-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-md-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-md-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-md-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-md-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-md-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-md-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-md-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-md-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-md-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-md-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-md-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-md-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-md-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-md-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-md-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-md-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-md-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-md-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-md-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-md-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-md-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-n80 {
    padding-right: -80px !important;
    padding-left: -80px !important; }
  .px-md-n100 {
    padding-right: -100px !important;
    padding-left: -100px !important; }
  .px-md-n150 {
    padding-right: -150px !important;
    padding-left: -150px !important; }
  .py-md-150 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important; }
  .py-md-149 {
    padding-top: 14.9rem !important;
    padding-bottom: 14.9rem !important; }
  .py-md-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-md-147 {
    padding-top: 14.7rem !important;
    padding-bottom: 14.7rem !important; }
  .py-md-146 {
    padding-top: 14.6rem !important;
    padding-bottom: 14.6rem !important; }
  .py-md-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-md-144 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important; }
  .py-md-143 {
    padding-top: 14.3rem !important;
    padding-bottom: 14.3rem !important; }
  .py-md-142 {
    padding-top: 14.2rem !important;
    padding-bottom: 14.2rem !important; }
  .py-md-141 {
    padding-top: 14.1rem !important;
    padding-bottom: 14.1rem !important; }
  .py-md-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-md-139 {
    padding-top: 13.9rem !important;
    padding-bottom: 13.9rem !important; }
  .py-md-138 {
    padding-top: 13.8rem !important;
    padding-bottom: 13.8rem !important; }
  .py-md-137 {
    padding-top: 13.7rem !important;
    padding-bottom: 13.7rem !important; }
  .py-md-136 {
    padding-top: 13.6rem !important;
    padding-bottom: 13.6rem !important; }
  .py-md-135 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important; }
  .py-md-134 {
    padding-top: 13.4rem !important;
    padding-bottom: 13.4rem !important; }
  .py-md-133 {
    padding-top: 13.3rem !important;
    padding-bottom: 13.3rem !important; }
  .py-md-132 {
    padding-top: 13.2rem !important;
    padding-bottom: 13.2rem !important; }
  .py-md-131 {
    padding-top: 13.1rem !important;
    padding-bottom: 13.1rem !important; }
  .py-md-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-md-129 {
    padding-top: 12.9rem !important;
    padding-bottom: 12.9rem !important; }
  .py-md-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-md-127 {
    padding-top: 12.7rem !important;
    padding-bottom: 12.7rem !important; }
  .py-md-126 {
    padding-top: 12.6rem !important;
    padding-bottom: 12.6rem !important; }
  .py-md-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-md-124 {
    padding-top: 12.4rem !important;
    padding-bottom: 12.4rem !important; }
  .py-md-123 {
    padding-top: 12.3rem !important;
    padding-bottom: 12.3rem !important; }
  .py-md-122 {
    padding-top: 12.2rem !important;
    padding-bottom: 12.2rem !important; }
  .py-md-121 {
    padding-top: 12.1rem !important;
    padding-bottom: 12.1rem !important; }
  .py-md-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-md-119 {
    padding-top: 11.9rem !important;
    padding-bottom: 11.9rem !important; }
  .py-md-118 {
    padding-top: 11.8rem !important;
    padding-bottom: 11.8rem !important; }
  .py-md-117 {
    padding-top: 11.7rem !important;
    padding-bottom: 11.7rem !important; }
  .py-md-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-md-115 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .py-md-114 {
    padding-top: 11.4rem !important;
    padding-bottom: 11.4rem !important; }
  .py-md-113 {
    padding-top: 11.3rem !important;
    padding-bottom: 11.3rem !important; }
  .py-md-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .py-md-111 {
    padding-top: 11.1rem !important;
    padding-bottom: 11.1rem !important; }
  .py-md-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-md-109 {
    padding-top: 10.9rem !important;
    padding-bottom: 10.9rem !important; }
  .py-md-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-md-107 {
    padding-top: 10.7rem !important;
    padding-bottom: 10.7rem !important; }
  .py-md-106 {
    padding-top: 10.6rem !important;
    padding-bottom: 10.6rem !important; }
  .py-md-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-md-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-md-103 {
    padding-top: 10.3rem !important;
    padding-bottom: 10.3rem !important; }
  .py-md-102 {
    padding-top: 10.2rem !important;
    padding-bottom: 10.2rem !important; }
  .py-md-101 {
    padding-top: 10.1rem !important;
    padding-bottom: 10.1rem !important; }
  .py-md-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-md-99 {
    padding-top: 9.9rem !important;
    padding-bottom: 9.9rem !important; }
  .py-md-98 {
    padding-top: 9.8rem !important;
    padding-bottom: 9.8rem !important; }
  .py-md-97 {
    padding-top: 9.7rem !important;
    padding-bottom: 9.7rem !important; }
  .py-md-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-md-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-md-94 {
    padding-top: 9.4rem !important;
    padding-bottom: 9.4rem !important; }
  .py-md-93 {
    padding-top: 9.3rem !important;
    padding-bottom: 9.3rem !important; }
  .py-md-92 {
    padding-top: 9.2rem !important;
    padding-bottom: 9.2rem !important; }
  .py-md-91 {
    padding-top: 9.1rem !important;
    padding-bottom: 9.1rem !important; }
  .py-md-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-md-89 {
    padding-top: 8.9rem !important;
    padding-bottom: 8.9rem !important; }
  .py-md-88 {
    padding-top: 8.8rem !important;
    padding-bottom: 8.8rem !important; }
  .py-md-87 {
    padding-top: 8.7rem !important;
    padding-bottom: 8.7rem !important; }
  .py-md-86 {
    padding-top: 8.6rem !important;
    padding-bottom: 8.6rem !important; }
  .py-md-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-md-84 {
    padding-top: 8.4rem !important;
    padding-bottom: 8.4rem !important; }
  .py-md-83 {
    padding-top: 8.3rem !important;
    padding-bottom: 8.3rem !important; }
  .py-md-82 {
    padding-top: 8.2rem !important;
    padding-bottom: 8.2rem !important; }
  .py-md-81 {
    padding-top: 8.1rem !important;
    padding-bottom: 8.1rem !important; }
  .py-md-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-md-79 {
    padding-top: 7.9rem !important;
    padding-bottom: 7.9rem !important; }
  .py-md-78 {
    padding-top: 7.8rem !important;
    padding-bottom: 7.8rem !important; }
  .py-md-77 {
    padding-top: 7.7rem !important;
    padding-bottom: 7.7rem !important; }
  .py-md-76 {
    padding-top: 7.6rem !important;
    padding-bottom: 7.6rem !important; }
  .py-md-75 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-md-74 {
    padding-top: 7.4rem !important;
    padding-bottom: 7.4rem !important; }
  .py-md-73 {
    padding-top: 7.3rem !important;
    padding-bottom: 7.3rem !important; }
  .py-md-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-md-71 {
    padding-top: 7.1rem !important;
    padding-bottom: 7.1rem !important; }
  .py-md-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-md-69 {
    padding-top: 6.9rem !important;
    padding-bottom: 6.9rem !important; }
  .py-md-68 {
    padding-top: 6.8rem !important;
    padding-bottom: 6.8rem !important; }
  .py-md-67 {
    padding-top: 6.7rem !important;
    padding-bottom: 6.7rem !important; }
  .py-md-66 {
    padding-top: 6.6rem !important;
    padding-bottom: 6.6rem !important; }
  .py-md-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-md-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-md-63 {
    padding-top: 6.3rem !important;
    padding-bottom: 6.3rem !important; }
  .py-md-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-md-61 {
    padding-top: 6.1rem !important;
    padding-bottom: 6.1rem !important; }
  .py-md-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-md-59 {
    padding-top: 5.9rem !important;
    padding-bottom: 5.9rem !important; }
  .py-md-58 {
    padding-top: 5.8rem !important;
    padding-bottom: 5.8rem !important; }
  .py-md-57 {
    padding-top: 5.7rem !important;
    padding-bottom: 5.7rem !important; }
  .py-md-56 {
    padding-top: 5.6rem !important;
    padding-bottom: 5.6rem !important; }
  .py-md-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-md-54 {
    padding-top: 5.4rem !important;
    padding-bottom: 5.4rem !important; }
  .py-md-53 {
    padding-top: 5.3rem !important;
    padding-bottom: 5.3rem !important; }
  .py-md-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-md-51 {
    padding-top: 5.1rem !important;
    padding-bottom: 5.1rem !important; }
  .py-md-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-md-49 {
    padding-top: 4.9rem !important;
    padding-bottom: 4.9rem !important; }
  .py-md-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-md-47 {
    padding-top: 4.7rem !important;
    padding-bottom: 4.7rem !important; }
  .py-md-46 {
    padding-top: 4.6rem !important;
    padding-bottom: 4.6rem !important; }
  .py-md-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-md-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-md-43 {
    padding-top: 4.3rem !important;
    padding-bottom: 4.3rem !important; }
  .py-md-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-md-41 {
    padding-top: 4.1rem !important;
    padding-bottom: 4.1rem !important; }
  .py-md-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-md-39 {
    padding-top: 3.9rem !important;
    padding-bottom: 3.9rem !important; }
  .py-md-38 {
    padding-top: 3.8rem !important;
    padding-bottom: 3.8rem !important; }
  .py-md-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-md-36 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important; }
  .py-md-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-md-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-md-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-md-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-md-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-md-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-md-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-md-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-md-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-md-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-md-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-md-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-md-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-md-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-md-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-md-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-md-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-md-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-md-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-md-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-md-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-md-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-md-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-md-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-n80 {
    padding-top: -80px !important;
    padding-bottom: -80px !important; }
  .py-md-n100 {
    padding-top: -100px !important;
    padding-bottom: -100px !important; }
  .py-md-n150 {
    padding-top: -150px !important;
    padding-bottom: -150px !important; }
  .pt-md-150 {
    padding-top: 15rem !important; }
  .pt-md-149 {
    padding-top: 14.9rem !important; }
  .pt-md-148 {
    padding-top: 14.8rem !important; }
  .pt-md-147 {
    padding-top: 14.7rem !important; }
  .pt-md-146 {
    padding-top: 14.6rem !important; }
  .pt-md-145 {
    padding-top: 14.5rem !important; }
  .pt-md-144 {
    padding-top: 14.4rem !important; }
  .pt-md-143 {
    padding-top: 14.3rem !important; }
  .pt-md-142 {
    padding-top: 14.2rem !important; }
  .pt-md-141 {
    padding-top: 14.1rem !important; }
  .pt-md-140 {
    padding-top: 14rem !important; }
  .pt-md-139 {
    padding-top: 13.9rem !important; }
  .pt-md-138 {
    padding-top: 13.8rem !important; }
  .pt-md-137 {
    padding-top: 13.7rem !important; }
  .pt-md-136 {
    padding-top: 13.6rem !important; }
  .pt-md-135 {
    padding-top: 13.5rem !important; }
  .pt-md-134 {
    padding-top: 13.4rem !important; }
  .pt-md-133 {
    padding-top: 13.3rem !important; }
  .pt-md-132 {
    padding-top: 13.2rem !important; }
  .pt-md-131 {
    padding-top: 13.1rem !important; }
  .pt-md-130 {
    padding-top: 13rem !important; }
  .pt-md-129 {
    padding-top: 12.9rem !important; }
  .pt-md-128 {
    padding-top: 12.8rem !important; }
  .pt-md-127 {
    padding-top: 12.7rem !important; }
  .pt-md-126 {
    padding-top: 12.6rem !important; }
  .pt-md-125 {
    padding-top: 12.5rem !important; }
  .pt-md-124 {
    padding-top: 12.4rem !important; }
  .pt-md-123 {
    padding-top: 12.3rem !important; }
  .pt-md-122 {
    padding-top: 12.2rem !important; }
  .pt-md-121 {
    padding-top: 12.1rem !important; }
  .pt-md-120 {
    padding-top: 12rem !important; }
  .pt-md-119 {
    padding-top: 11.9rem !important; }
  .pt-md-118 {
    padding-top: 11.8rem !important; }
  .pt-md-117 {
    padding-top: 11.7rem !important; }
  .pt-md-116 {
    padding-top: 11.6rem !important; }
  .pt-md-115 {
    padding-top: 11.5rem !important; }
  .pt-md-114 {
    padding-top: 11.4rem !important; }
  .pt-md-113 {
    padding-top: 11.3rem !important; }
  .pt-md-112 {
    padding-top: 11.2rem !important; }
  .pt-md-111 {
    padding-top: 11.1rem !important; }
  .pt-md-110 {
    padding-top: 11rem !important; }
  .pt-md-109 {
    padding-top: 10.9rem !important; }
  .pt-md-108 {
    padding-top: 10.8rem !important; }
  .pt-md-107 {
    padding-top: 10.7rem !important; }
  .pt-md-106 {
    padding-top: 10.6rem !important; }
  .pt-md-105 {
    padding-top: 10.5rem !important; }
  .pt-md-104 {
    padding-top: 10.4rem !important; }
  .pt-md-103 {
    padding-top: 10.3rem !important; }
  .pt-md-102 {
    padding-top: 10.2rem !important; }
  .pt-md-101 {
    padding-top: 10.1rem !important; }
  .pt-md-100 {
    padding-top: 10rem !important; }
  .pt-md-99 {
    padding-top: 9.9rem !important; }
  .pt-md-98 {
    padding-top: 9.8rem !important; }
  .pt-md-97 {
    padding-top: 9.7rem !important; }
  .pt-md-96 {
    padding-top: 9.6rem !important; }
  .pt-md-95 {
    padding-top: 9.5rem !important; }
  .pt-md-94 {
    padding-top: 9.4rem !important; }
  .pt-md-93 {
    padding-top: 9.3rem !important; }
  .pt-md-92 {
    padding-top: 9.2rem !important; }
  .pt-md-91 {
    padding-top: 9.1rem !important; }
  .pt-md-90 {
    padding-top: 9rem !important; }
  .pt-md-89 {
    padding-top: 8.9rem !important; }
  .pt-md-88 {
    padding-top: 8.8rem !important; }
  .pt-md-87 {
    padding-top: 8.7rem !important; }
  .pt-md-86 {
    padding-top: 8.6rem !important; }
  .pt-md-85 {
    padding-top: 8.5rem !important; }
  .pt-md-84 {
    padding-top: 8.4rem !important; }
  .pt-md-83 {
    padding-top: 8.3rem !important; }
  .pt-md-82 {
    padding-top: 8.2rem !important; }
  .pt-md-81 {
    padding-top: 8.1rem !important; }
  .pt-md-80 {
    padding-top: 8rem !important; }
  .pt-md-79 {
    padding-top: 7.9rem !important; }
  .pt-md-78 {
    padding-top: 7.8rem !important; }
  .pt-md-77 {
    padding-top: 7.7rem !important; }
  .pt-md-76 {
    padding-top: 7.6rem !important; }
  .pt-md-75 {
    padding-top: 7.5rem !important; }
  .pt-md-74 {
    padding-top: 7.4rem !important; }
  .pt-md-73 {
    padding-top: 7.3rem !important; }
  .pt-md-72 {
    padding-top: 7.2rem !important; }
  .pt-md-71 {
    padding-top: 7.1rem !important; }
  .pt-md-70 {
    padding-top: 7rem !important; }
  .pt-md-69 {
    padding-top: 6.9rem !important; }
  .pt-md-68 {
    padding-top: 6.8rem !important; }
  .pt-md-67 {
    padding-top: 6.7rem !important; }
  .pt-md-66 {
    padding-top: 6.6rem !important; }
  .pt-md-65 {
    padding-top: 6.5rem !important; }
  .pt-md-64 {
    padding-top: 6.4rem !important; }
  .pt-md-63 {
    padding-top: 6.3rem !important; }
  .pt-md-62 {
    padding-top: 6.2rem !important; }
  .pt-md-61 {
    padding-top: 6.1rem !important; }
  .pt-md-60 {
    padding-top: 6rem !important; }
  .pt-md-59 {
    padding-top: 5.9rem !important; }
  .pt-md-58 {
    padding-top: 5.8rem !important; }
  .pt-md-57 {
    padding-top: 5.7rem !important; }
  .pt-md-56 {
    padding-top: 5.6rem !important; }
  .pt-md-55 {
    padding-top: 5.5rem !important; }
  .pt-md-54 {
    padding-top: 5.4rem !important; }
  .pt-md-53 {
    padding-top: 5.3rem !important; }
  .pt-md-52 {
    padding-top: 5.2rem !important; }
  .pt-md-51 {
    padding-top: 5.1rem !important; }
  .pt-md-50 {
    padding-top: 5rem !important; }
  .pt-md-49 {
    padding-top: 4.9rem !important; }
  .pt-md-48 {
    padding-top: 4.8rem !important; }
  .pt-md-47 {
    padding-top: 4.7rem !important; }
  .pt-md-46 {
    padding-top: 4.6rem !important; }
  .pt-md-45 {
    padding-top: 4.5rem !important; }
  .pt-md-44 {
    padding-top: 4.4rem !important; }
  .pt-md-43 {
    padding-top: 4.3rem !important; }
  .pt-md-42 {
    padding-top: 4.2rem !important; }
  .pt-md-41 {
    padding-top: 4.1rem !important; }
  .pt-md-40 {
    padding-top: 4rem !important; }
  .pt-md-39 {
    padding-top: 3.9rem !important; }
  .pt-md-38 {
    padding-top: 3.8rem !important; }
  .pt-md-37 {
    padding-top: 3.7rem !important; }
  .pt-md-36 {
    padding-top: 3.6rem !important; }
  .pt-md-35 {
    padding-top: 3.5rem !important; }
  .pt-md-34 {
    padding-top: 3.4rem !important; }
  .pt-md-33 {
    padding-top: 3.3rem !important; }
  .pt-md-32 {
    padding-top: 3.2rem !important; }
  .pt-md-31 {
    padding-top: 3.1rem !important; }
  .pt-md-30 {
    padding-top: 3rem !important; }
  .pt-md-29 {
    padding-top: 2.9rem !important; }
  .pt-md-28 {
    padding-top: 2.8rem !important; }
  .pt-md-27 {
    padding-top: 2.7rem !important; }
  .pt-md-26 {
    padding-top: 2.6rem !important; }
  .pt-md-25 {
    padding-top: 2.5rem !important; }
  .pt-md-24 {
    padding-top: 2.4rem !important; }
  .pt-md-23 {
    padding-top: 2.3rem !important; }
  .pt-md-22 {
    padding-top: 2.2rem !important; }
  .pt-md-21 {
    padding-top: 2.1rem !important; }
  .pt-md-20 {
    padding-top: 2rem !important; }
  .pt-md-19 {
    padding-top: 1.9rem !important; }
  .pt-md-18 {
    padding-top: 1.8rem !important; }
  .pt-md-17 {
    padding-top: 1.7rem !important; }
  .pt-md-16 {
    padding-top: 1.6rem !important; }
  .pt-md-15 {
    padding-top: 1.5rem !important; }
  .pt-md-14 {
    padding-top: 1.4rem !important; }
  .pt-md-13 {
    padding-top: 1.3rem !important; }
  .pt-md-12 {
    padding-top: 1.2rem !important; }
  .pt-md-11 {
    padding-top: 1.1rem !important; }
  .pt-md-10 {
    padding-top: 1rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pt-md-n80 {
    padding-top: -80px !important; }
  .pt-md-n100 {
    padding-top: -100px !important; }
  .pt-md-n150 {
    padding-top: -150px !important; }
  .pe-md-150 {
    padding-right: 15rem !important; }
  .pe-md-149 {
    padding-right: 14.9rem !important; }
  .pe-md-148 {
    padding-right: 14.8rem !important; }
  .pe-md-147 {
    padding-right: 14.7rem !important; }
  .pe-md-146 {
    padding-right: 14.6rem !important; }
  .pe-md-145 {
    padding-right: 14.5rem !important; }
  .pe-md-144 {
    padding-right: 14.4rem !important; }
  .pe-md-143 {
    padding-right: 14.3rem !important; }
  .pe-md-142 {
    padding-right: 14.2rem !important; }
  .pe-md-141 {
    padding-right: 14.1rem !important; }
  .pe-md-140 {
    padding-right: 14rem !important; }
  .pe-md-139 {
    padding-right: 13.9rem !important; }
  .pe-md-138 {
    padding-right: 13.8rem !important; }
  .pe-md-137 {
    padding-right: 13.7rem !important; }
  .pe-md-136 {
    padding-right: 13.6rem !important; }
  .pe-md-135 {
    padding-right: 13.5rem !important; }
  .pe-md-134 {
    padding-right: 13.4rem !important; }
  .pe-md-133 {
    padding-right: 13.3rem !important; }
  .pe-md-132 {
    padding-right: 13.2rem !important; }
  .pe-md-131 {
    padding-right: 13.1rem !important; }
  .pe-md-130 {
    padding-right: 13rem !important; }
  .pe-md-129 {
    padding-right: 12.9rem !important; }
  .pe-md-128 {
    padding-right: 12.8rem !important; }
  .pe-md-127 {
    padding-right: 12.7rem !important; }
  .pe-md-126 {
    padding-right: 12.6rem !important; }
  .pe-md-125 {
    padding-right: 12.5rem !important; }
  .pe-md-124 {
    padding-right: 12.4rem !important; }
  .pe-md-123 {
    padding-right: 12.3rem !important; }
  .pe-md-122 {
    padding-right: 12.2rem !important; }
  .pe-md-121 {
    padding-right: 12.1rem !important; }
  .pe-md-120 {
    padding-right: 12rem !important; }
  .pe-md-119 {
    padding-right: 11.9rem !important; }
  .pe-md-118 {
    padding-right: 11.8rem !important; }
  .pe-md-117 {
    padding-right: 11.7rem !important; }
  .pe-md-116 {
    padding-right: 11.6rem !important; }
  .pe-md-115 {
    padding-right: 11.5rem !important; }
  .pe-md-114 {
    padding-right: 11.4rem !important; }
  .pe-md-113 {
    padding-right: 11.3rem !important; }
  .pe-md-112 {
    padding-right: 11.2rem !important; }
  .pe-md-111 {
    padding-right: 11.1rem !important; }
  .pe-md-110 {
    padding-right: 11rem !important; }
  .pe-md-109 {
    padding-right: 10.9rem !important; }
  .pe-md-108 {
    padding-right: 10.8rem !important; }
  .pe-md-107 {
    padding-right: 10.7rem !important; }
  .pe-md-106 {
    padding-right: 10.6rem !important; }
  .pe-md-105 {
    padding-right: 10.5rem !important; }
  .pe-md-104 {
    padding-right: 10.4rem !important; }
  .pe-md-103 {
    padding-right: 10.3rem !important; }
  .pe-md-102 {
    padding-right: 10.2rem !important; }
  .pe-md-101 {
    padding-right: 10.1rem !important; }
  .pe-md-100 {
    padding-right: 10rem !important; }
  .pe-md-99 {
    padding-right: 9.9rem !important; }
  .pe-md-98 {
    padding-right: 9.8rem !important; }
  .pe-md-97 {
    padding-right: 9.7rem !important; }
  .pe-md-96 {
    padding-right: 9.6rem !important; }
  .pe-md-95 {
    padding-right: 9.5rem !important; }
  .pe-md-94 {
    padding-right: 9.4rem !important; }
  .pe-md-93 {
    padding-right: 9.3rem !important; }
  .pe-md-92 {
    padding-right: 9.2rem !important; }
  .pe-md-91 {
    padding-right: 9.1rem !important; }
  .pe-md-90 {
    padding-right: 9rem !important; }
  .pe-md-89 {
    padding-right: 8.9rem !important; }
  .pe-md-88 {
    padding-right: 8.8rem !important; }
  .pe-md-87 {
    padding-right: 8.7rem !important; }
  .pe-md-86 {
    padding-right: 8.6rem !important; }
  .pe-md-85 {
    padding-right: 8.5rem !important; }
  .pe-md-84 {
    padding-right: 8.4rem !important; }
  .pe-md-83 {
    padding-right: 8.3rem !important; }
  .pe-md-82 {
    padding-right: 8.2rem !important; }
  .pe-md-81 {
    padding-right: 8.1rem !important; }
  .pe-md-80 {
    padding-right: 8rem !important; }
  .pe-md-79 {
    padding-right: 7.9rem !important; }
  .pe-md-78 {
    padding-right: 7.8rem !important; }
  .pe-md-77 {
    padding-right: 7.7rem !important; }
  .pe-md-76 {
    padding-right: 7.6rem !important; }
  .pe-md-75 {
    padding-right: 7.5rem !important; }
  .pe-md-74 {
    padding-right: 7.4rem !important; }
  .pe-md-73 {
    padding-right: 7.3rem !important; }
  .pe-md-72 {
    padding-right: 7.2rem !important; }
  .pe-md-71 {
    padding-right: 7.1rem !important; }
  .pe-md-70 {
    padding-right: 7rem !important; }
  .pe-md-69 {
    padding-right: 6.9rem !important; }
  .pe-md-68 {
    padding-right: 6.8rem !important; }
  .pe-md-67 {
    padding-right: 6.7rem !important; }
  .pe-md-66 {
    padding-right: 6.6rem !important; }
  .pe-md-65 {
    padding-right: 6.5rem !important; }
  .pe-md-64 {
    padding-right: 6.4rem !important; }
  .pe-md-63 {
    padding-right: 6.3rem !important; }
  .pe-md-62 {
    padding-right: 6.2rem !important; }
  .pe-md-61 {
    padding-right: 6.1rem !important; }
  .pe-md-60 {
    padding-right: 6rem !important; }
  .pe-md-59 {
    padding-right: 5.9rem !important; }
  .pe-md-58 {
    padding-right: 5.8rem !important; }
  .pe-md-57 {
    padding-right: 5.7rem !important; }
  .pe-md-56 {
    padding-right: 5.6rem !important; }
  .pe-md-55 {
    padding-right: 5.5rem !important; }
  .pe-md-54 {
    padding-right: 5.4rem !important; }
  .pe-md-53 {
    padding-right: 5.3rem !important; }
  .pe-md-52 {
    padding-right: 5.2rem !important; }
  .pe-md-51 {
    padding-right: 5.1rem !important; }
  .pe-md-50 {
    padding-right: 5rem !important; }
  .pe-md-49 {
    padding-right: 4.9rem !important; }
  .pe-md-48 {
    padding-right: 4.8rem !important; }
  .pe-md-47 {
    padding-right: 4.7rem !important; }
  .pe-md-46 {
    padding-right: 4.6rem !important; }
  .pe-md-45 {
    padding-right: 4.5rem !important; }
  .pe-md-44 {
    padding-right: 4.4rem !important; }
  .pe-md-43 {
    padding-right: 4.3rem !important; }
  .pe-md-42 {
    padding-right: 4.2rem !important; }
  .pe-md-41 {
    padding-right: 4.1rem !important; }
  .pe-md-40 {
    padding-right: 4rem !important; }
  .pe-md-39 {
    padding-right: 3.9rem !important; }
  .pe-md-38 {
    padding-right: 3.8rem !important; }
  .pe-md-37 {
    padding-right: 3.7rem !important; }
  .pe-md-36 {
    padding-right: 3.6rem !important; }
  .pe-md-35 {
    padding-right: 3.5rem !important; }
  .pe-md-34 {
    padding-right: 3.4rem !important; }
  .pe-md-33 {
    padding-right: 3.3rem !important; }
  .pe-md-32 {
    padding-right: 3.2rem !important; }
  .pe-md-31 {
    padding-right: 3.1rem !important; }
  .pe-md-30 {
    padding-right: 3rem !important; }
  .pe-md-29 {
    padding-right: 2.9rem !important; }
  .pe-md-28 {
    padding-right: 2.8rem !important; }
  .pe-md-27 {
    padding-right: 2.7rem !important; }
  .pe-md-26 {
    padding-right: 2.6rem !important; }
  .pe-md-25 {
    padding-right: 2.5rem !important; }
  .pe-md-24 {
    padding-right: 2.4rem !important; }
  .pe-md-23 {
    padding-right: 2.3rem !important; }
  .pe-md-22 {
    padding-right: 2.2rem !important; }
  .pe-md-21 {
    padding-right: 2.1rem !important; }
  .pe-md-20 {
    padding-right: 2rem !important; }
  .pe-md-19 {
    padding-right: 1.9rem !important; }
  .pe-md-18 {
    padding-right: 1.8rem !important; }
  .pe-md-17 {
    padding-right: 1.7rem !important; }
  .pe-md-16 {
    padding-right: 1.6rem !important; }
  .pe-md-15 {
    padding-right: 1.5rem !important; }
  .pe-md-14 {
    padding-right: 1.4rem !important; }
  .pe-md-13 {
    padding-right: 1.3rem !important; }
  .pe-md-12 {
    padding-right: 1.2rem !important; }
  .pe-md-11 {
    padding-right: 1.1rem !important; }
  .pe-md-10 {
    padding-right: 1rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pe-md-n80 {
    padding-right: -80px !important; }
  .pe-md-n100 {
    padding-right: -100px !important; }
  .pe-md-n150 {
    padding-right: -150px !important; }
  .pb-md-150 {
    padding-bottom: 15rem !important; }
  .pb-md-149 {
    padding-bottom: 14.9rem !important; }
  .pb-md-148 {
    padding-bottom: 14.8rem !important; }
  .pb-md-147 {
    padding-bottom: 14.7rem !important; }
  .pb-md-146 {
    padding-bottom: 14.6rem !important; }
  .pb-md-145 {
    padding-bottom: 14.5rem !important; }
  .pb-md-144 {
    padding-bottom: 14.4rem !important; }
  .pb-md-143 {
    padding-bottom: 14.3rem !important; }
  .pb-md-142 {
    padding-bottom: 14.2rem !important; }
  .pb-md-141 {
    padding-bottom: 14.1rem !important; }
  .pb-md-140 {
    padding-bottom: 14rem !important; }
  .pb-md-139 {
    padding-bottom: 13.9rem !important; }
  .pb-md-138 {
    padding-bottom: 13.8rem !important; }
  .pb-md-137 {
    padding-bottom: 13.7rem !important; }
  .pb-md-136 {
    padding-bottom: 13.6rem !important; }
  .pb-md-135 {
    padding-bottom: 13.5rem !important; }
  .pb-md-134 {
    padding-bottom: 13.4rem !important; }
  .pb-md-133 {
    padding-bottom: 13.3rem !important; }
  .pb-md-132 {
    padding-bottom: 13.2rem !important; }
  .pb-md-131 {
    padding-bottom: 13.1rem !important; }
  .pb-md-130 {
    padding-bottom: 13rem !important; }
  .pb-md-129 {
    padding-bottom: 12.9rem !important; }
  .pb-md-128 {
    padding-bottom: 12.8rem !important; }
  .pb-md-127 {
    padding-bottom: 12.7rem !important; }
  .pb-md-126 {
    padding-bottom: 12.6rem !important; }
  .pb-md-125 {
    padding-bottom: 12.5rem !important; }
  .pb-md-124 {
    padding-bottom: 12.4rem !important; }
  .pb-md-123 {
    padding-bottom: 12.3rem !important; }
  .pb-md-122 {
    padding-bottom: 12.2rem !important; }
  .pb-md-121 {
    padding-bottom: 12.1rem !important; }
  .pb-md-120 {
    padding-bottom: 12rem !important; }
  .pb-md-119 {
    padding-bottom: 11.9rem !important; }
  .pb-md-118 {
    padding-bottom: 11.8rem !important; }
  .pb-md-117 {
    padding-bottom: 11.7rem !important; }
  .pb-md-116 {
    padding-bottom: 11.6rem !important; }
  .pb-md-115 {
    padding-bottom: 11.5rem !important; }
  .pb-md-114 {
    padding-bottom: 11.4rem !important; }
  .pb-md-113 {
    padding-bottom: 11.3rem !important; }
  .pb-md-112 {
    padding-bottom: 11.2rem !important; }
  .pb-md-111 {
    padding-bottom: 11.1rem !important; }
  .pb-md-110 {
    padding-bottom: 11rem !important; }
  .pb-md-109 {
    padding-bottom: 10.9rem !important; }
  .pb-md-108 {
    padding-bottom: 10.8rem !important; }
  .pb-md-107 {
    padding-bottom: 10.7rem !important; }
  .pb-md-106 {
    padding-bottom: 10.6rem !important; }
  .pb-md-105 {
    padding-bottom: 10.5rem !important; }
  .pb-md-104 {
    padding-bottom: 10.4rem !important; }
  .pb-md-103 {
    padding-bottom: 10.3rem !important; }
  .pb-md-102 {
    padding-bottom: 10.2rem !important; }
  .pb-md-101 {
    padding-bottom: 10.1rem !important; }
  .pb-md-100 {
    padding-bottom: 10rem !important; }
  .pb-md-99 {
    padding-bottom: 9.9rem !important; }
  .pb-md-98 {
    padding-bottom: 9.8rem !important; }
  .pb-md-97 {
    padding-bottom: 9.7rem !important; }
  .pb-md-96 {
    padding-bottom: 9.6rem !important; }
  .pb-md-95 {
    padding-bottom: 9.5rem !important; }
  .pb-md-94 {
    padding-bottom: 9.4rem !important; }
  .pb-md-93 {
    padding-bottom: 9.3rem !important; }
  .pb-md-92 {
    padding-bottom: 9.2rem !important; }
  .pb-md-91 {
    padding-bottom: 9.1rem !important; }
  .pb-md-90 {
    padding-bottom: 9rem !important; }
  .pb-md-89 {
    padding-bottom: 8.9rem !important; }
  .pb-md-88 {
    padding-bottom: 8.8rem !important; }
  .pb-md-87 {
    padding-bottom: 8.7rem !important; }
  .pb-md-86 {
    padding-bottom: 8.6rem !important; }
  .pb-md-85 {
    padding-bottom: 8.5rem !important; }
  .pb-md-84 {
    padding-bottom: 8.4rem !important; }
  .pb-md-83 {
    padding-bottom: 8.3rem !important; }
  .pb-md-82 {
    padding-bottom: 8.2rem !important; }
  .pb-md-81 {
    padding-bottom: 8.1rem !important; }
  .pb-md-80 {
    padding-bottom: 8rem !important; }
  .pb-md-79 {
    padding-bottom: 7.9rem !important; }
  .pb-md-78 {
    padding-bottom: 7.8rem !important; }
  .pb-md-77 {
    padding-bottom: 7.7rem !important; }
  .pb-md-76 {
    padding-bottom: 7.6rem !important; }
  .pb-md-75 {
    padding-bottom: 7.5rem !important; }
  .pb-md-74 {
    padding-bottom: 7.4rem !important; }
  .pb-md-73 {
    padding-bottom: 7.3rem !important; }
  .pb-md-72 {
    padding-bottom: 7.2rem !important; }
  .pb-md-71 {
    padding-bottom: 7.1rem !important; }
  .pb-md-70 {
    padding-bottom: 7rem !important; }
  .pb-md-69 {
    padding-bottom: 6.9rem !important; }
  .pb-md-68 {
    padding-bottom: 6.8rem !important; }
  .pb-md-67 {
    padding-bottom: 6.7rem !important; }
  .pb-md-66 {
    padding-bottom: 6.6rem !important; }
  .pb-md-65 {
    padding-bottom: 6.5rem !important; }
  .pb-md-64 {
    padding-bottom: 6.4rem !important; }
  .pb-md-63 {
    padding-bottom: 6.3rem !important; }
  .pb-md-62 {
    padding-bottom: 6.2rem !important; }
  .pb-md-61 {
    padding-bottom: 6.1rem !important; }
  .pb-md-60 {
    padding-bottom: 6rem !important; }
  .pb-md-59 {
    padding-bottom: 5.9rem !important; }
  .pb-md-58 {
    padding-bottom: 5.8rem !important; }
  .pb-md-57 {
    padding-bottom: 5.7rem !important; }
  .pb-md-56 {
    padding-bottom: 5.6rem !important; }
  .pb-md-55 {
    padding-bottom: 5.5rem !important; }
  .pb-md-54 {
    padding-bottom: 5.4rem !important; }
  .pb-md-53 {
    padding-bottom: 5.3rem !important; }
  .pb-md-52 {
    padding-bottom: 5.2rem !important; }
  .pb-md-51 {
    padding-bottom: 5.1rem !important; }
  .pb-md-50 {
    padding-bottom: 5rem !important; }
  .pb-md-49 {
    padding-bottom: 4.9rem !important; }
  .pb-md-48 {
    padding-bottom: 4.8rem !important; }
  .pb-md-47 {
    padding-bottom: 4.7rem !important; }
  .pb-md-46 {
    padding-bottom: 4.6rem !important; }
  .pb-md-45 {
    padding-bottom: 4.5rem !important; }
  .pb-md-44 {
    padding-bottom: 4.4rem !important; }
  .pb-md-43 {
    padding-bottom: 4.3rem !important; }
  .pb-md-42 {
    padding-bottom: 4.2rem !important; }
  .pb-md-41 {
    padding-bottom: 4.1rem !important; }
  .pb-md-40 {
    padding-bottom: 4rem !important; }
  .pb-md-39 {
    padding-bottom: 3.9rem !important; }
  .pb-md-38 {
    padding-bottom: 3.8rem !important; }
  .pb-md-37 {
    padding-bottom: 3.7rem !important; }
  .pb-md-36 {
    padding-bottom: 3.6rem !important; }
  .pb-md-35 {
    padding-bottom: 3.5rem !important; }
  .pb-md-34 {
    padding-bottom: 3.4rem !important; }
  .pb-md-33 {
    padding-bottom: 3.3rem !important; }
  .pb-md-32 {
    padding-bottom: 3.2rem !important; }
  .pb-md-31 {
    padding-bottom: 3.1rem !important; }
  .pb-md-30 {
    padding-bottom: 3rem !important; }
  .pb-md-29 {
    padding-bottom: 2.9rem !important; }
  .pb-md-28 {
    padding-bottom: 2.8rem !important; }
  .pb-md-27 {
    padding-bottom: 2.7rem !important; }
  .pb-md-26 {
    padding-bottom: 2.6rem !important; }
  .pb-md-25 {
    padding-bottom: 2.5rem !important; }
  .pb-md-24 {
    padding-bottom: 2.4rem !important; }
  .pb-md-23 {
    padding-bottom: 2.3rem !important; }
  .pb-md-22 {
    padding-bottom: 2.2rem !important; }
  .pb-md-21 {
    padding-bottom: 2.1rem !important; }
  .pb-md-20 {
    padding-bottom: 2rem !important; }
  .pb-md-19 {
    padding-bottom: 1.9rem !important; }
  .pb-md-18 {
    padding-bottom: 1.8rem !important; }
  .pb-md-17 {
    padding-bottom: 1.7rem !important; }
  .pb-md-16 {
    padding-bottom: 1.6rem !important; }
  .pb-md-15 {
    padding-bottom: 1.5rem !important; }
  .pb-md-14 {
    padding-bottom: 1.4rem !important; }
  .pb-md-13 {
    padding-bottom: 1.3rem !important; }
  .pb-md-12 {
    padding-bottom: 1.2rem !important; }
  .pb-md-11 {
    padding-bottom: 1.1rem !important; }
  .pb-md-10 {
    padding-bottom: 1rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pb-md-n80 {
    padding-bottom: -80px !important; }
  .pb-md-n100 {
    padding-bottom: -100px !important; }
  .pb-md-n150 {
    padding-bottom: -150px !important; }
  .ps-md-150 {
    padding-left: 15rem !important; }
  .ps-md-149 {
    padding-left: 14.9rem !important; }
  .ps-md-148 {
    padding-left: 14.8rem !important; }
  .ps-md-147 {
    padding-left: 14.7rem !important; }
  .ps-md-146 {
    padding-left: 14.6rem !important; }
  .ps-md-145 {
    padding-left: 14.5rem !important; }
  .ps-md-144 {
    padding-left: 14.4rem !important; }
  .ps-md-143 {
    padding-left: 14.3rem !important; }
  .ps-md-142 {
    padding-left: 14.2rem !important; }
  .ps-md-141 {
    padding-left: 14.1rem !important; }
  .ps-md-140 {
    padding-left: 14rem !important; }
  .ps-md-139 {
    padding-left: 13.9rem !important; }
  .ps-md-138 {
    padding-left: 13.8rem !important; }
  .ps-md-137 {
    padding-left: 13.7rem !important; }
  .ps-md-136 {
    padding-left: 13.6rem !important; }
  .ps-md-135 {
    padding-left: 13.5rem !important; }
  .ps-md-134 {
    padding-left: 13.4rem !important; }
  .ps-md-133 {
    padding-left: 13.3rem !important; }
  .ps-md-132 {
    padding-left: 13.2rem !important; }
  .ps-md-131 {
    padding-left: 13.1rem !important; }
  .ps-md-130 {
    padding-left: 13rem !important; }
  .ps-md-129 {
    padding-left: 12.9rem !important; }
  .ps-md-128 {
    padding-left: 12.8rem !important; }
  .ps-md-127 {
    padding-left: 12.7rem !important; }
  .ps-md-126 {
    padding-left: 12.6rem !important; }
  .ps-md-125 {
    padding-left: 12.5rem !important; }
  .ps-md-124 {
    padding-left: 12.4rem !important; }
  .ps-md-123 {
    padding-left: 12.3rem !important; }
  .ps-md-122 {
    padding-left: 12.2rem !important; }
  .ps-md-121 {
    padding-left: 12.1rem !important; }
  .ps-md-120 {
    padding-left: 12rem !important; }
  .ps-md-119 {
    padding-left: 11.9rem !important; }
  .ps-md-118 {
    padding-left: 11.8rem !important; }
  .ps-md-117 {
    padding-left: 11.7rem !important; }
  .ps-md-116 {
    padding-left: 11.6rem !important; }
  .ps-md-115 {
    padding-left: 11.5rem !important; }
  .ps-md-114 {
    padding-left: 11.4rem !important; }
  .ps-md-113 {
    padding-left: 11.3rem !important; }
  .ps-md-112 {
    padding-left: 11.2rem !important; }
  .ps-md-111 {
    padding-left: 11.1rem !important; }
  .ps-md-110 {
    padding-left: 11rem !important; }
  .ps-md-109 {
    padding-left: 10.9rem !important; }
  .ps-md-108 {
    padding-left: 10.8rem !important; }
  .ps-md-107 {
    padding-left: 10.7rem !important; }
  .ps-md-106 {
    padding-left: 10.6rem !important; }
  .ps-md-105 {
    padding-left: 10.5rem !important; }
  .ps-md-104 {
    padding-left: 10.4rem !important; }
  .ps-md-103 {
    padding-left: 10.3rem !important; }
  .ps-md-102 {
    padding-left: 10.2rem !important; }
  .ps-md-101 {
    padding-left: 10.1rem !important; }
  .ps-md-100 {
    padding-left: 10rem !important; }
  .ps-md-99 {
    padding-left: 9.9rem !important; }
  .ps-md-98 {
    padding-left: 9.8rem !important; }
  .ps-md-97 {
    padding-left: 9.7rem !important; }
  .ps-md-96 {
    padding-left: 9.6rem !important; }
  .ps-md-95 {
    padding-left: 9.5rem !important; }
  .ps-md-94 {
    padding-left: 9.4rem !important; }
  .ps-md-93 {
    padding-left: 9.3rem !important; }
  .ps-md-92 {
    padding-left: 9.2rem !important; }
  .ps-md-91 {
    padding-left: 9.1rem !important; }
  .ps-md-90 {
    padding-left: 9rem !important; }
  .ps-md-89 {
    padding-left: 8.9rem !important; }
  .ps-md-88 {
    padding-left: 8.8rem !important; }
  .ps-md-87 {
    padding-left: 8.7rem !important; }
  .ps-md-86 {
    padding-left: 8.6rem !important; }
  .ps-md-85 {
    padding-left: 8.5rem !important; }
  .ps-md-84 {
    padding-left: 8.4rem !important; }
  .ps-md-83 {
    padding-left: 8.3rem !important; }
  .ps-md-82 {
    padding-left: 8.2rem !important; }
  .ps-md-81 {
    padding-left: 8.1rem !important; }
  .ps-md-80 {
    padding-left: 8rem !important; }
  .ps-md-79 {
    padding-left: 7.9rem !important; }
  .ps-md-78 {
    padding-left: 7.8rem !important; }
  .ps-md-77 {
    padding-left: 7.7rem !important; }
  .ps-md-76 {
    padding-left: 7.6rem !important; }
  .ps-md-75 {
    padding-left: 7.5rem !important; }
  .ps-md-74 {
    padding-left: 7.4rem !important; }
  .ps-md-73 {
    padding-left: 7.3rem !important; }
  .ps-md-72 {
    padding-left: 7.2rem !important; }
  .ps-md-71 {
    padding-left: 7.1rem !important; }
  .ps-md-70 {
    padding-left: 7rem !important; }
  .ps-md-69 {
    padding-left: 6.9rem !important; }
  .ps-md-68 {
    padding-left: 6.8rem !important; }
  .ps-md-67 {
    padding-left: 6.7rem !important; }
  .ps-md-66 {
    padding-left: 6.6rem !important; }
  .ps-md-65 {
    padding-left: 6.5rem !important; }
  .ps-md-64 {
    padding-left: 6.4rem !important; }
  .ps-md-63 {
    padding-left: 6.3rem !important; }
  .ps-md-62 {
    padding-left: 6.2rem !important; }
  .ps-md-61 {
    padding-left: 6.1rem !important; }
  .ps-md-60 {
    padding-left: 6rem !important; }
  .ps-md-59 {
    padding-left: 5.9rem !important; }
  .ps-md-58 {
    padding-left: 5.8rem !important; }
  .ps-md-57 {
    padding-left: 5.7rem !important; }
  .ps-md-56 {
    padding-left: 5.6rem !important; }
  .ps-md-55 {
    padding-left: 5.5rem !important; }
  .ps-md-54 {
    padding-left: 5.4rem !important; }
  .ps-md-53 {
    padding-left: 5.3rem !important; }
  .ps-md-52 {
    padding-left: 5.2rem !important; }
  .ps-md-51 {
    padding-left: 5.1rem !important; }
  .ps-md-50 {
    padding-left: 5rem !important; }
  .ps-md-49 {
    padding-left: 4.9rem !important; }
  .ps-md-48 {
    padding-left: 4.8rem !important; }
  .ps-md-47 {
    padding-left: 4.7rem !important; }
  .ps-md-46 {
    padding-left: 4.6rem !important; }
  .ps-md-45 {
    padding-left: 4.5rem !important; }
  .ps-md-44 {
    padding-left: 4.4rem !important; }
  .ps-md-43 {
    padding-left: 4.3rem !important; }
  .ps-md-42 {
    padding-left: 4.2rem !important; }
  .ps-md-41 {
    padding-left: 4.1rem !important; }
  .ps-md-40 {
    padding-left: 4rem !important; }
  .ps-md-39 {
    padding-left: 3.9rem !important; }
  .ps-md-38 {
    padding-left: 3.8rem !important; }
  .ps-md-37 {
    padding-left: 3.7rem !important; }
  .ps-md-36 {
    padding-left: 3.6rem !important; }
  .ps-md-35 {
    padding-left: 3.5rem !important; }
  .ps-md-34 {
    padding-left: 3.4rem !important; }
  .ps-md-33 {
    padding-left: 3.3rem !important; }
  .ps-md-32 {
    padding-left: 3.2rem !important; }
  .ps-md-31 {
    padding-left: 3.1rem !important; }
  .ps-md-30 {
    padding-left: 3rem !important; }
  .ps-md-29 {
    padding-left: 2.9rem !important; }
  .ps-md-28 {
    padding-left: 2.8rem !important; }
  .ps-md-27 {
    padding-left: 2.7rem !important; }
  .ps-md-26 {
    padding-left: 2.6rem !important; }
  .ps-md-25 {
    padding-left: 2.5rem !important; }
  .ps-md-24 {
    padding-left: 2.4rem !important; }
  .ps-md-23 {
    padding-left: 2.3rem !important; }
  .ps-md-22 {
    padding-left: 2.2rem !important; }
  .ps-md-21 {
    padding-left: 2.1rem !important; }
  .ps-md-20 {
    padding-left: 2rem !important; }
  .ps-md-19 {
    padding-left: 1.9rem !important; }
  .ps-md-18 {
    padding-left: 1.8rem !important; }
  .ps-md-17 {
    padding-left: 1.7rem !important; }
  .ps-md-16 {
    padding-left: 1.6rem !important; }
  .ps-md-15 {
    padding-left: 1.5rem !important; }
  .ps-md-14 {
    padding-left: 1.4rem !important; }
  .ps-md-13 {
    padding-left: 1.3rem !important; }
  .ps-md-12 {
    padding-left: 1.2rem !important; }
  .ps-md-11 {
    padding-left: 1.1rem !important; }
  .ps-md-10 {
    padding-left: 1rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .ps-md-n80 {
    padding-left: -80px !important; }
  .ps-md-n100 {
    padding-left: -100px !important; }
  .ps-md-n150 {
    padding-left: -150px !important; }
  .fs-md-45 {
    font-size: 4.5rem !important; }
  .fs-md-44 {
    font-size: 4.4rem !important; }
  .fs-md-43 {
    font-size: 4.3rem !important; }
  .fs-md-42 {
    font-size: 4.2rem !important; }
  .fs-md-41 {
    font-size: 4.1rem !important; }
  .fs-md-40 {
    font-size: 4rem !important; }
  .fs-md-39 {
    font-size: 3.9rem !important; }
  .fs-md-38 {
    font-size: 3.8rem !important; }
  .fs-md-37 {
    font-size: 3.7rem !important; }
  .fs-md-36 {
    font-size: 3.6rem !important; }
  .fs-md-35 {
    font-size: 3.5rem !important; }
  .fs-md-34 {
    font-size: 3.4rem !important; }
  .fs-md-33 {
    font-size: 3.3rem !important; }
  .fs-md-32 {
    font-size: 3.2rem !important; }
  .fs-md-31 {
    font-size: 3.1rem !important; }
  .fs-md-30 {
    font-size: 3rem !important; }
  .fs-md-29 {
    font-size: 2.9rem !important; }
  .fs-md-28 {
    font-size: 2.8rem !important; }
  .fs-md-27 {
    font-size: 2.7rem !important; }
  .fs-md-26 {
    font-size: 2.6rem !important; }
  .fs-md-25 {
    font-size: 2.5rem !important; }
  .fs-md-24 {
    font-size: 2.4rem !important; }
  .fs-md-23 {
    font-size: 2.3rem !important; }
  .fs-md-22 {
    font-size: 2.2rem !important; }
  .fs-md-21 {
    font-size: 2.1rem !important; }
  .fs-md-20 {
    font-size: 2rem !important; }
  .fs-md-19 {
    font-size: 1.9rem !important; }
  .fs-md-18 {
    font-size: 1.8rem !important; }
  .fs-md-17 {
    font-size: 1.7rem !important; }
  .fs-md-16 {
    font-size: 1.6rem !important; }
  .fs-md-15 {
    font-size: 1.5rem !important; }
  .fs-md-14 {
    font-size: 1.4rem !important; }
  .fs-md-13 {
    font-size: 1.3rem !important; }
  .fs-md-12 {
    font-size: 1.2rem !important; }
  .fs-md-11 {
    font-size: 1.1rem !important; }
  .fs-md-10 {
    font-size: 1rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .rounded-md {
    border-radius: 0.8rem !important; }
  .rounded-md-0 {
    border-radius: 0 !important; }
  .rounded-md-1 {
    border-radius: 0.4rem !important; }
  .rounded-md-2 {
    border-radius: 0.8rem !important; }
  .rounded-md-3 {
    border-radius: 1rem !important; }
  .rounded-md-circle {
    border-radius: 50% !important; }
  .z-index-md-1 {
    z-index: 1 !important; }
  .z-index-md-2 {
    z-index: 2 !important; }
  .z-index-md-3 {
    z-index: 3 !important; }
  .z-index-md-4 {
    z-index: 4 !important; }
  .z-index-md-5 {
    z-index: 5 !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .position-lg-static {
    position: static !important; }
  .position-lg-relative {
    position: relative !important; }
  .position-lg-absolute {
    position: absolute !important; }
  .position-lg-fixed {
    position: fixed !important; }
  .position-lg-sticky {
    position: sticky !important; }
  .w-lg-20 {
    width: 20% !important; }
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-45 {
    width: 45% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-55 {
    width: 55% !important; }
  .w-lg-60 {
    width: 60% !important; }
  .w-lg-65 {
    width: 65% !important; }
  .w-lg-70 {
    width: 70% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-80 {
    width: 80% !important; }
  .w-lg-90 {
    width: 90% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .w-lg-50px {
    width: 50px !important; }
  .w-lg-60px {
    width: 60px !important; }
  .w-lg-90px {
    width: 90px !important; }
  .w-lg-100px {
    width: 100px !important; }
  .w-lg-118px {
    width: 118px !important; }
  .w-lg-158px {
    width: 158px !important; }
  .w-lg-174px {
    width: 174px !important; }
  .w-lg-250px {
    width: 250px !important; }
  .h-lg-20 {
    height: 20% !important; }
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-45 {
    height: 45% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-55 {
    height: 55% !important; }
  .h-lg-60 {
    height: 60% !important; }
  .h-lg-65 {
    height: 65% !important; }
  .h-lg-70 {
    height: 70% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-80 {
    height: 80% !important; }
  .h-lg-90 {
    height: 90% !important; }
  .h-lg-100 {
    height: 100% !important; }
  .h-lg-auto {
    height: auto !important; }
  .h-lg-50px {
    height: 50px !important; }
  .h-lg-60px {
    height: 60px !important; }
  .h-lg-90px {
    height: 90px !important; }
  .h-lg-100px {
    height: 100px !important; }
  .h-lg-118px {
    height: 118px !important; }
  .h-lg-158px {
    height: 158px !important; }
  .h-lg-174px {
    height: 174px !important; }
  .h-lg-250px {
    height: 250px !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-150 {
    gap: 15rem !important; }
  .gap-lg-149 {
    gap: 14.9rem !important; }
  .gap-lg-148 {
    gap: 14.8rem !important; }
  .gap-lg-147 {
    gap: 14.7rem !important; }
  .gap-lg-146 {
    gap: 14.6rem !important; }
  .gap-lg-145 {
    gap: 14.5rem !important; }
  .gap-lg-144 {
    gap: 14.4rem !important; }
  .gap-lg-143 {
    gap: 14.3rem !important; }
  .gap-lg-142 {
    gap: 14.2rem !important; }
  .gap-lg-141 {
    gap: 14.1rem !important; }
  .gap-lg-140 {
    gap: 14rem !important; }
  .gap-lg-139 {
    gap: 13.9rem !important; }
  .gap-lg-138 {
    gap: 13.8rem !important; }
  .gap-lg-137 {
    gap: 13.7rem !important; }
  .gap-lg-136 {
    gap: 13.6rem !important; }
  .gap-lg-135 {
    gap: 13.5rem !important; }
  .gap-lg-134 {
    gap: 13.4rem !important; }
  .gap-lg-133 {
    gap: 13.3rem !important; }
  .gap-lg-132 {
    gap: 13.2rem !important; }
  .gap-lg-131 {
    gap: 13.1rem !important; }
  .gap-lg-130 {
    gap: 13rem !important; }
  .gap-lg-129 {
    gap: 12.9rem !important; }
  .gap-lg-128 {
    gap: 12.8rem !important; }
  .gap-lg-127 {
    gap: 12.7rem !important; }
  .gap-lg-126 {
    gap: 12.6rem !important; }
  .gap-lg-125 {
    gap: 12.5rem !important; }
  .gap-lg-124 {
    gap: 12.4rem !important; }
  .gap-lg-123 {
    gap: 12.3rem !important; }
  .gap-lg-122 {
    gap: 12.2rem !important; }
  .gap-lg-121 {
    gap: 12.1rem !important; }
  .gap-lg-120 {
    gap: 12rem !important; }
  .gap-lg-119 {
    gap: 11.9rem !important; }
  .gap-lg-118 {
    gap: 11.8rem !important; }
  .gap-lg-117 {
    gap: 11.7rem !important; }
  .gap-lg-116 {
    gap: 11.6rem !important; }
  .gap-lg-115 {
    gap: 11.5rem !important; }
  .gap-lg-114 {
    gap: 11.4rem !important; }
  .gap-lg-113 {
    gap: 11.3rem !important; }
  .gap-lg-112 {
    gap: 11.2rem !important; }
  .gap-lg-111 {
    gap: 11.1rem !important; }
  .gap-lg-110 {
    gap: 11rem !important; }
  .gap-lg-109 {
    gap: 10.9rem !important; }
  .gap-lg-108 {
    gap: 10.8rem !important; }
  .gap-lg-107 {
    gap: 10.7rem !important; }
  .gap-lg-106 {
    gap: 10.6rem !important; }
  .gap-lg-105 {
    gap: 10.5rem !important; }
  .gap-lg-104 {
    gap: 10.4rem !important; }
  .gap-lg-103 {
    gap: 10.3rem !important; }
  .gap-lg-102 {
    gap: 10.2rem !important; }
  .gap-lg-101 {
    gap: 10.1rem !important; }
  .gap-lg-100 {
    gap: 10rem !important; }
  .gap-lg-99 {
    gap: 9.9rem !important; }
  .gap-lg-98 {
    gap: 9.8rem !important; }
  .gap-lg-97 {
    gap: 9.7rem !important; }
  .gap-lg-96 {
    gap: 9.6rem !important; }
  .gap-lg-95 {
    gap: 9.5rem !important; }
  .gap-lg-94 {
    gap: 9.4rem !important; }
  .gap-lg-93 {
    gap: 9.3rem !important; }
  .gap-lg-92 {
    gap: 9.2rem !important; }
  .gap-lg-91 {
    gap: 9.1rem !important; }
  .gap-lg-90 {
    gap: 9rem !important; }
  .gap-lg-89 {
    gap: 8.9rem !important; }
  .gap-lg-88 {
    gap: 8.8rem !important; }
  .gap-lg-87 {
    gap: 8.7rem !important; }
  .gap-lg-86 {
    gap: 8.6rem !important; }
  .gap-lg-85 {
    gap: 8.5rem !important; }
  .gap-lg-84 {
    gap: 8.4rem !important; }
  .gap-lg-83 {
    gap: 8.3rem !important; }
  .gap-lg-82 {
    gap: 8.2rem !important; }
  .gap-lg-81 {
    gap: 8.1rem !important; }
  .gap-lg-80 {
    gap: 8rem !important; }
  .gap-lg-79 {
    gap: 7.9rem !important; }
  .gap-lg-78 {
    gap: 7.8rem !important; }
  .gap-lg-77 {
    gap: 7.7rem !important; }
  .gap-lg-76 {
    gap: 7.6rem !important; }
  .gap-lg-75 {
    gap: 7.5rem !important; }
  .gap-lg-74 {
    gap: 7.4rem !important; }
  .gap-lg-73 {
    gap: 7.3rem !important; }
  .gap-lg-72 {
    gap: 7.2rem !important; }
  .gap-lg-71 {
    gap: 7.1rem !important; }
  .gap-lg-70 {
    gap: 7rem !important; }
  .gap-lg-69 {
    gap: 6.9rem !important; }
  .gap-lg-68 {
    gap: 6.8rem !important; }
  .gap-lg-67 {
    gap: 6.7rem !important; }
  .gap-lg-66 {
    gap: 6.6rem !important; }
  .gap-lg-65 {
    gap: 6.5rem !important; }
  .gap-lg-64 {
    gap: 6.4rem !important; }
  .gap-lg-63 {
    gap: 6.3rem !important; }
  .gap-lg-62 {
    gap: 6.2rem !important; }
  .gap-lg-61 {
    gap: 6.1rem !important; }
  .gap-lg-60 {
    gap: 6rem !important; }
  .gap-lg-59 {
    gap: 5.9rem !important; }
  .gap-lg-58 {
    gap: 5.8rem !important; }
  .gap-lg-57 {
    gap: 5.7rem !important; }
  .gap-lg-56 {
    gap: 5.6rem !important; }
  .gap-lg-55 {
    gap: 5.5rem !important; }
  .gap-lg-54 {
    gap: 5.4rem !important; }
  .gap-lg-53 {
    gap: 5.3rem !important; }
  .gap-lg-52 {
    gap: 5.2rem !important; }
  .gap-lg-51 {
    gap: 5.1rem !important; }
  .gap-lg-50 {
    gap: 5rem !important; }
  .gap-lg-49 {
    gap: 4.9rem !important; }
  .gap-lg-48 {
    gap: 4.8rem !important; }
  .gap-lg-47 {
    gap: 4.7rem !important; }
  .gap-lg-46 {
    gap: 4.6rem !important; }
  .gap-lg-45 {
    gap: 4.5rem !important; }
  .gap-lg-44 {
    gap: 4.4rem !important; }
  .gap-lg-43 {
    gap: 4.3rem !important; }
  .gap-lg-42 {
    gap: 4.2rem !important; }
  .gap-lg-41 {
    gap: 4.1rem !important; }
  .gap-lg-40 {
    gap: 4rem !important; }
  .gap-lg-39 {
    gap: 3.9rem !important; }
  .gap-lg-38 {
    gap: 3.8rem !important; }
  .gap-lg-37 {
    gap: 3.7rem !important; }
  .gap-lg-36 {
    gap: 3.6rem !important; }
  .gap-lg-35 {
    gap: 3.5rem !important; }
  .gap-lg-34 {
    gap: 3.4rem !important; }
  .gap-lg-33 {
    gap: 3.3rem !important; }
  .gap-lg-32 {
    gap: 3.2rem !important; }
  .gap-lg-31 {
    gap: 3.1rem !important; }
  .gap-lg-30 {
    gap: 3rem !important; }
  .gap-lg-29 {
    gap: 2.9rem !important; }
  .gap-lg-28 {
    gap: 2.8rem !important; }
  .gap-lg-27 {
    gap: 2.7rem !important; }
  .gap-lg-26 {
    gap: 2.6rem !important; }
  .gap-lg-25 {
    gap: 2.5rem !important; }
  .gap-lg-24 {
    gap: 2.4rem !important; }
  .gap-lg-23 {
    gap: 2.3rem !important; }
  .gap-lg-22 {
    gap: 2.2rem !important; }
  .gap-lg-21 {
    gap: 2.1rem !important; }
  .gap-lg-20 {
    gap: 2rem !important; }
  .gap-lg-19 {
    gap: 1.9rem !important; }
  .gap-lg-18 {
    gap: 1.8rem !important; }
  .gap-lg-17 {
    gap: 1.7rem !important; }
  .gap-lg-16 {
    gap: 1.6rem !important; }
  .gap-lg-15 {
    gap: 1.5rem !important; }
  .gap-lg-14 {
    gap: 1.4rem !important; }
  .gap-lg-13 {
    gap: 1.3rem !important; }
  .gap-lg-12 {
    gap: 1.2rem !important; }
  .gap-lg-11 {
    gap: 1.1rem !important; }
  .gap-lg-10 {
    gap: 1rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .gap-lg-n80 {
    gap: -80px !important; }
  .gap-lg-n100 {
    gap: -100px !important; }
  .gap-lg-n150 {
    gap: -150px !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-150 {
    margin: 15rem !important; }
  .m-lg-149 {
    margin: 14.9rem !important; }
  .m-lg-148 {
    margin: 14.8rem !important; }
  .m-lg-147 {
    margin: 14.7rem !important; }
  .m-lg-146 {
    margin: 14.6rem !important; }
  .m-lg-145 {
    margin: 14.5rem !important; }
  .m-lg-144 {
    margin: 14.4rem !important; }
  .m-lg-143 {
    margin: 14.3rem !important; }
  .m-lg-142 {
    margin: 14.2rem !important; }
  .m-lg-141 {
    margin: 14.1rem !important; }
  .m-lg-140 {
    margin: 14rem !important; }
  .m-lg-139 {
    margin: 13.9rem !important; }
  .m-lg-138 {
    margin: 13.8rem !important; }
  .m-lg-137 {
    margin: 13.7rem !important; }
  .m-lg-136 {
    margin: 13.6rem !important; }
  .m-lg-135 {
    margin: 13.5rem !important; }
  .m-lg-134 {
    margin: 13.4rem !important; }
  .m-lg-133 {
    margin: 13.3rem !important; }
  .m-lg-132 {
    margin: 13.2rem !important; }
  .m-lg-131 {
    margin: 13.1rem !important; }
  .m-lg-130 {
    margin: 13rem !important; }
  .m-lg-129 {
    margin: 12.9rem !important; }
  .m-lg-128 {
    margin: 12.8rem !important; }
  .m-lg-127 {
    margin: 12.7rem !important; }
  .m-lg-126 {
    margin: 12.6rem !important; }
  .m-lg-125 {
    margin: 12.5rem !important; }
  .m-lg-124 {
    margin: 12.4rem !important; }
  .m-lg-123 {
    margin: 12.3rem !important; }
  .m-lg-122 {
    margin: 12.2rem !important; }
  .m-lg-121 {
    margin: 12.1rem !important; }
  .m-lg-120 {
    margin: 12rem !important; }
  .m-lg-119 {
    margin: 11.9rem !important; }
  .m-lg-118 {
    margin: 11.8rem !important; }
  .m-lg-117 {
    margin: 11.7rem !important; }
  .m-lg-116 {
    margin: 11.6rem !important; }
  .m-lg-115 {
    margin: 11.5rem !important; }
  .m-lg-114 {
    margin: 11.4rem !important; }
  .m-lg-113 {
    margin: 11.3rem !important; }
  .m-lg-112 {
    margin: 11.2rem !important; }
  .m-lg-111 {
    margin: 11.1rem !important; }
  .m-lg-110 {
    margin: 11rem !important; }
  .m-lg-109 {
    margin: 10.9rem !important; }
  .m-lg-108 {
    margin: 10.8rem !important; }
  .m-lg-107 {
    margin: 10.7rem !important; }
  .m-lg-106 {
    margin: 10.6rem !important; }
  .m-lg-105 {
    margin: 10.5rem !important; }
  .m-lg-104 {
    margin: 10.4rem !important; }
  .m-lg-103 {
    margin: 10.3rem !important; }
  .m-lg-102 {
    margin: 10.2rem !important; }
  .m-lg-101 {
    margin: 10.1rem !important; }
  .m-lg-100 {
    margin: 10rem !important; }
  .m-lg-99 {
    margin: 9.9rem !important; }
  .m-lg-98 {
    margin: 9.8rem !important; }
  .m-lg-97 {
    margin: 9.7rem !important; }
  .m-lg-96 {
    margin: 9.6rem !important; }
  .m-lg-95 {
    margin: 9.5rem !important; }
  .m-lg-94 {
    margin: 9.4rem !important; }
  .m-lg-93 {
    margin: 9.3rem !important; }
  .m-lg-92 {
    margin: 9.2rem !important; }
  .m-lg-91 {
    margin: 9.1rem !important; }
  .m-lg-90 {
    margin: 9rem !important; }
  .m-lg-89 {
    margin: 8.9rem !important; }
  .m-lg-88 {
    margin: 8.8rem !important; }
  .m-lg-87 {
    margin: 8.7rem !important; }
  .m-lg-86 {
    margin: 8.6rem !important; }
  .m-lg-85 {
    margin: 8.5rem !important; }
  .m-lg-84 {
    margin: 8.4rem !important; }
  .m-lg-83 {
    margin: 8.3rem !important; }
  .m-lg-82 {
    margin: 8.2rem !important; }
  .m-lg-81 {
    margin: 8.1rem !important; }
  .m-lg-80 {
    margin: 8rem !important; }
  .m-lg-79 {
    margin: 7.9rem !important; }
  .m-lg-78 {
    margin: 7.8rem !important; }
  .m-lg-77 {
    margin: 7.7rem !important; }
  .m-lg-76 {
    margin: 7.6rem !important; }
  .m-lg-75 {
    margin: 7.5rem !important; }
  .m-lg-74 {
    margin: 7.4rem !important; }
  .m-lg-73 {
    margin: 7.3rem !important; }
  .m-lg-72 {
    margin: 7.2rem !important; }
  .m-lg-71 {
    margin: 7.1rem !important; }
  .m-lg-70 {
    margin: 7rem !important; }
  .m-lg-69 {
    margin: 6.9rem !important; }
  .m-lg-68 {
    margin: 6.8rem !important; }
  .m-lg-67 {
    margin: 6.7rem !important; }
  .m-lg-66 {
    margin: 6.6rem !important; }
  .m-lg-65 {
    margin: 6.5rem !important; }
  .m-lg-64 {
    margin: 6.4rem !important; }
  .m-lg-63 {
    margin: 6.3rem !important; }
  .m-lg-62 {
    margin: 6.2rem !important; }
  .m-lg-61 {
    margin: 6.1rem !important; }
  .m-lg-60 {
    margin: 6rem !important; }
  .m-lg-59 {
    margin: 5.9rem !important; }
  .m-lg-58 {
    margin: 5.8rem !important; }
  .m-lg-57 {
    margin: 5.7rem !important; }
  .m-lg-56 {
    margin: 5.6rem !important; }
  .m-lg-55 {
    margin: 5.5rem !important; }
  .m-lg-54 {
    margin: 5.4rem !important; }
  .m-lg-53 {
    margin: 5.3rem !important; }
  .m-lg-52 {
    margin: 5.2rem !important; }
  .m-lg-51 {
    margin: 5.1rem !important; }
  .m-lg-50 {
    margin: 5rem !important; }
  .m-lg-49 {
    margin: 4.9rem !important; }
  .m-lg-48 {
    margin: 4.8rem !important; }
  .m-lg-47 {
    margin: 4.7rem !important; }
  .m-lg-46 {
    margin: 4.6rem !important; }
  .m-lg-45 {
    margin: 4.5rem !important; }
  .m-lg-44 {
    margin: 4.4rem !important; }
  .m-lg-43 {
    margin: 4.3rem !important; }
  .m-lg-42 {
    margin: 4.2rem !important; }
  .m-lg-41 {
    margin: 4.1rem !important; }
  .m-lg-40 {
    margin: 4rem !important; }
  .m-lg-39 {
    margin: 3.9rem !important; }
  .m-lg-38 {
    margin: 3.8rem !important; }
  .m-lg-37 {
    margin: 3.7rem !important; }
  .m-lg-36 {
    margin: 3.6rem !important; }
  .m-lg-35 {
    margin: 3.5rem !important; }
  .m-lg-34 {
    margin: 3.4rem !important; }
  .m-lg-33 {
    margin: 3.3rem !important; }
  .m-lg-32 {
    margin: 3.2rem !important; }
  .m-lg-31 {
    margin: 3.1rem !important; }
  .m-lg-30 {
    margin: 3rem !important; }
  .m-lg-29 {
    margin: 2.9rem !important; }
  .m-lg-28 {
    margin: 2.8rem !important; }
  .m-lg-27 {
    margin: 2.7rem !important; }
  .m-lg-26 {
    margin: 2.6rem !important; }
  .m-lg-25 {
    margin: 2.5rem !important; }
  .m-lg-24 {
    margin: 2.4rem !important; }
  .m-lg-23 {
    margin: 2.3rem !important; }
  .m-lg-22 {
    margin: 2.2rem !important; }
  .m-lg-21 {
    margin: 2.1rem !important; }
  .m-lg-20 {
    margin: 2rem !important; }
  .m-lg-19 {
    margin: 1.9rem !important; }
  .m-lg-18 {
    margin: 1.8rem !important; }
  .m-lg-17 {
    margin: 1.7rem !important; }
  .m-lg-16 {
    margin: 1.6rem !important; }
  .m-lg-15 {
    margin: 1.5rem !important; }
  .m-lg-14 {
    margin: 1.4rem !important; }
  .m-lg-13 {
    margin: 1.3rem !important; }
  .m-lg-12 {
    margin: 1.2rem !important; }
  .m-lg-11 {
    margin: 1.1rem !important; }
  .m-lg-10 {
    margin: 1rem !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-n80 {
    margin: -80px !important; }
  .m-lg-n100 {
    margin: -100px !important; }
  .m-lg-n150 {
    margin: -150px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-150 {
    margin-right: 15rem !important;
    margin-left: 15rem !important; }
  .mx-lg-149 {
    margin-right: 14.9rem !important;
    margin-left: 14.9rem !important; }
  .mx-lg-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-lg-147 {
    margin-right: 14.7rem !important;
    margin-left: 14.7rem !important; }
  .mx-lg-146 {
    margin-right: 14.6rem !important;
    margin-left: 14.6rem !important; }
  .mx-lg-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-lg-144 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important; }
  .mx-lg-143 {
    margin-right: 14.3rem !important;
    margin-left: 14.3rem !important; }
  .mx-lg-142 {
    margin-right: 14.2rem !important;
    margin-left: 14.2rem !important; }
  .mx-lg-141 {
    margin-right: 14.1rem !important;
    margin-left: 14.1rem !important; }
  .mx-lg-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-lg-139 {
    margin-right: 13.9rem !important;
    margin-left: 13.9rem !important; }
  .mx-lg-138 {
    margin-right: 13.8rem !important;
    margin-left: 13.8rem !important; }
  .mx-lg-137 {
    margin-right: 13.7rem !important;
    margin-left: 13.7rem !important; }
  .mx-lg-136 {
    margin-right: 13.6rem !important;
    margin-left: 13.6rem !important; }
  .mx-lg-135 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important; }
  .mx-lg-134 {
    margin-right: 13.4rem !important;
    margin-left: 13.4rem !important; }
  .mx-lg-133 {
    margin-right: 13.3rem !important;
    margin-left: 13.3rem !important; }
  .mx-lg-132 {
    margin-right: 13.2rem !important;
    margin-left: 13.2rem !important; }
  .mx-lg-131 {
    margin-right: 13.1rem !important;
    margin-left: 13.1rem !important; }
  .mx-lg-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-lg-129 {
    margin-right: 12.9rem !important;
    margin-left: 12.9rem !important; }
  .mx-lg-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-lg-127 {
    margin-right: 12.7rem !important;
    margin-left: 12.7rem !important; }
  .mx-lg-126 {
    margin-right: 12.6rem !important;
    margin-left: 12.6rem !important; }
  .mx-lg-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-lg-124 {
    margin-right: 12.4rem !important;
    margin-left: 12.4rem !important; }
  .mx-lg-123 {
    margin-right: 12.3rem !important;
    margin-left: 12.3rem !important; }
  .mx-lg-122 {
    margin-right: 12.2rem !important;
    margin-left: 12.2rem !important; }
  .mx-lg-121 {
    margin-right: 12.1rem !important;
    margin-left: 12.1rem !important; }
  .mx-lg-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-lg-119 {
    margin-right: 11.9rem !important;
    margin-left: 11.9rem !important; }
  .mx-lg-118 {
    margin-right: 11.8rem !important;
    margin-left: 11.8rem !important; }
  .mx-lg-117 {
    margin-right: 11.7rem !important;
    margin-left: 11.7rem !important; }
  .mx-lg-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-lg-115 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-lg-114 {
    margin-right: 11.4rem !important;
    margin-left: 11.4rem !important; }
  .mx-lg-113 {
    margin-right: 11.3rem !important;
    margin-left: 11.3rem !important; }
  .mx-lg-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-lg-111 {
    margin-right: 11.1rem !important;
    margin-left: 11.1rem !important; }
  .mx-lg-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-lg-109 {
    margin-right: 10.9rem !important;
    margin-left: 10.9rem !important; }
  .mx-lg-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-lg-107 {
    margin-right: 10.7rem !important;
    margin-left: 10.7rem !important; }
  .mx-lg-106 {
    margin-right: 10.6rem !important;
    margin-left: 10.6rem !important; }
  .mx-lg-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-lg-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-lg-103 {
    margin-right: 10.3rem !important;
    margin-left: 10.3rem !important; }
  .mx-lg-102 {
    margin-right: 10.2rem !important;
    margin-left: 10.2rem !important; }
  .mx-lg-101 {
    margin-right: 10.1rem !important;
    margin-left: 10.1rem !important; }
  .mx-lg-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-lg-99 {
    margin-right: 9.9rem !important;
    margin-left: 9.9rem !important; }
  .mx-lg-98 {
    margin-right: 9.8rem !important;
    margin-left: 9.8rem !important; }
  .mx-lg-97 {
    margin-right: 9.7rem !important;
    margin-left: 9.7rem !important; }
  .mx-lg-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-lg-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-lg-94 {
    margin-right: 9.4rem !important;
    margin-left: 9.4rem !important; }
  .mx-lg-93 {
    margin-right: 9.3rem !important;
    margin-left: 9.3rem !important; }
  .mx-lg-92 {
    margin-right: 9.2rem !important;
    margin-left: 9.2rem !important; }
  .mx-lg-91 {
    margin-right: 9.1rem !important;
    margin-left: 9.1rem !important; }
  .mx-lg-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-lg-89 {
    margin-right: 8.9rem !important;
    margin-left: 8.9rem !important; }
  .mx-lg-88 {
    margin-right: 8.8rem !important;
    margin-left: 8.8rem !important; }
  .mx-lg-87 {
    margin-right: 8.7rem !important;
    margin-left: 8.7rem !important; }
  .mx-lg-86 {
    margin-right: 8.6rem !important;
    margin-left: 8.6rem !important; }
  .mx-lg-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-lg-84 {
    margin-right: 8.4rem !important;
    margin-left: 8.4rem !important; }
  .mx-lg-83 {
    margin-right: 8.3rem !important;
    margin-left: 8.3rem !important; }
  .mx-lg-82 {
    margin-right: 8.2rem !important;
    margin-left: 8.2rem !important; }
  .mx-lg-81 {
    margin-right: 8.1rem !important;
    margin-left: 8.1rem !important; }
  .mx-lg-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-lg-79 {
    margin-right: 7.9rem !important;
    margin-left: 7.9rem !important; }
  .mx-lg-78 {
    margin-right: 7.8rem !important;
    margin-left: 7.8rem !important; }
  .mx-lg-77 {
    margin-right: 7.7rem !important;
    margin-left: 7.7rem !important; }
  .mx-lg-76 {
    margin-right: 7.6rem !important;
    margin-left: 7.6rem !important; }
  .mx-lg-75 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-lg-74 {
    margin-right: 7.4rem !important;
    margin-left: 7.4rem !important; }
  .mx-lg-73 {
    margin-right: 7.3rem !important;
    margin-left: 7.3rem !important; }
  .mx-lg-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-lg-71 {
    margin-right: 7.1rem !important;
    margin-left: 7.1rem !important; }
  .mx-lg-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-lg-69 {
    margin-right: 6.9rem !important;
    margin-left: 6.9rem !important; }
  .mx-lg-68 {
    margin-right: 6.8rem !important;
    margin-left: 6.8rem !important; }
  .mx-lg-67 {
    margin-right: 6.7rem !important;
    margin-left: 6.7rem !important; }
  .mx-lg-66 {
    margin-right: 6.6rem !important;
    margin-left: 6.6rem !important; }
  .mx-lg-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-lg-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-lg-63 {
    margin-right: 6.3rem !important;
    margin-left: 6.3rem !important; }
  .mx-lg-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-lg-61 {
    margin-right: 6.1rem !important;
    margin-left: 6.1rem !important; }
  .mx-lg-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-lg-59 {
    margin-right: 5.9rem !important;
    margin-left: 5.9rem !important; }
  .mx-lg-58 {
    margin-right: 5.8rem !important;
    margin-left: 5.8rem !important; }
  .mx-lg-57 {
    margin-right: 5.7rem !important;
    margin-left: 5.7rem !important; }
  .mx-lg-56 {
    margin-right: 5.6rem !important;
    margin-left: 5.6rem !important; }
  .mx-lg-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-lg-54 {
    margin-right: 5.4rem !important;
    margin-left: 5.4rem !important; }
  .mx-lg-53 {
    margin-right: 5.3rem !important;
    margin-left: 5.3rem !important; }
  .mx-lg-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-lg-51 {
    margin-right: 5.1rem !important;
    margin-left: 5.1rem !important; }
  .mx-lg-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-lg-49 {
    margin-right: 4.9rem !important;
    margin-left: 4.9rem !important; }
  .mx-lg-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-lg-47 {
    margin-right: 4.7rem !important;
    margin-left: 4.7rem !important; }
  .mx-lg-46 {
    margin-right: 4.6rem !important;
    margin-left: 4.6rem !important; }
  .mx-lg-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-lg-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-lg-43 {
    margin-right: 4.3rem !important;
    margin-left: 4.3rem !important; }
  .mx-lg-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-lg-41 {
    margin-right: 4.1rem !important;
    margin-left: 4.1rem !important; }
  .mx-lg-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-lg-39 {
    margin-right: 3.9rem !important;
    margin-left: 3.9rem !important; }
  .mx-lg-38 {
    margin-right: 3.8rem !important;
    margin-left: 3.8rem !important; }
  .mx-lg-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-lg-36 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important; }
  .mx-lg-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-lg-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-lg-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-lg-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-lg-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-lg-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-lg-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-lg-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-lg-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-lg-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-lg-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-lg-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-lg-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-lg-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-lg-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-lg-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-lg-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-lg-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-lg-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-lg-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-lg-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-lg-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-lg-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-lg-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important; }
  .mx-lg-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important; }
  .mx-lg-n150 {
    margin-right: -150px !important;
    margin-left: -150px !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-150 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important; }
  .my-lg-149 {
    margin-top: 14.9rem !important;
    margin-bottom: 14.9rem !important; }
  .my-lg-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-lg-147 {
    margin-top: 14.7rem !important;
    margin-bottom: 14.7rem !important; }
  .my-lg-146 {
    margin-top: 14.6rem !important;
    margin-bottom: 14.6rem !important; }
  .my-lg-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-lg-144 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important; }
  .my-lg-143 {
    margin-top: 14.3rem !important;
    margin-bottom: 14.3rem !important; }
  .my-lg-142 {
    margin-top: 14.2rem !important;
    margin-bottom: 14.2rem !important; }
  .my-lg-141 {
    margin-top: 14.1rem !important;
    margin-bottom: 14.1rem !important; }
  .my-lg-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-lg-139 {
    margin-top: 13.9rem !important;
    margin-bottom: 13.9rem !important; }
  .my-lg-138 {
    margin-top: 13.8rem !important;
    margin-bottom: 13.8rem !important; }
  .my-lg-137 {
    margin-top: 13.7rem !important;
    margin-bottom: 13.7rem !important; }
  .my-lg-136 {
    margin-top: 13.6rem !important;
    margin-bottom: 13.6rem !important; }
  .my-lg-135 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important; }
  .my-lg-134 {
    margin-top: 13.4rem !important;
    margin-bottom: 13.4rem !important; }
  .my-lg-133 {
    margin-top: 13.3rem !important;
    margin-bottom: 13.3rem !important; }
  .my-lg-132 {
    margin-top: 13.2rem !important;
    margin-bottom: 13.2rem !important; }
  .my-lg-131 {
    margin-top: 13.1rem !important;
    margin-bottom: 13.1rem !important; }
  .my-lg-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-lg-129 {
    margin-top: 12.9rem !important;
    margin-bottom: 12.9rem !important; }
  .my-lg-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-lg-127 {
    margin-top: 12.7rem !important;
    margin-bottom: 12.7rem !important; }
  .my-lg-126 {
    margin-top: 12.6rem !important;
    margin-bottom: 12.6rem !important; }
  .my-lg-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-lg-124 {
    margin-top: 12.4rem !important;
    margin-bottom: 12.4rem !important; }
  .my-lg-123 {
    margin-top: 12.3rem !important;
    margin-bottom: 12.3rem !important; }
  .my-lg-122 {
    margin-top: 12.2rem !important;
    margin-bottom: 12.2rem !important; }
  .my-lg-121 {
    margin-top: 12.1rem !important;
    margin-bottom: 12.1rem !important; }
  .my-lg-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-lg-119 {
    margin-top: 11.9rem !important;
    margin-bottom: 11.9rem !important; }
  .my-lg-118 {
    margin-top: 11.8rem !important;
    margin-bottom: 11.8rem !important; }
  .my-lg-117 {
    margin-top: 11.7rem !important;
    margin-bottom: 11.7rem !important; }
  .my-lg-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-lg-115 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-lg-114 {
    margin-top: 11.4rem !important;
    margin-bottom: 11.4rem !important; }
  .my-lg-113 {
    margin-top: 11.3rem !important;
    margin-bottom: 11.3rem !important; }
  .my-lg-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-lg-111 {
    margin-top: 11.1rem !important;
    margin-bottom: 11.1rem !important; }
  .my-lg-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-lg-109 {
    margin-top: 10.9rem !important;
    margin-bottom: 10.9rem !important; }
  .my-lg-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-lg-107 {
    margin-top: 10.7rem !important;
    margin-bottom: 10.7rem !important; }
  .my-lg-106 {
    margin-top: 10.6rem !important;
    margin-bottom: 10.6rem !important; }
  .my-lg-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-lg-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-lg-103 {
    margin-top: 10.3rem !important;
    margin-bottom: 10.3rem !important; }
  .my-lg-102 {
    margin-top: 10.2rem !important;
    margin-bottom: 10.2rem !important; }
  .my-lg-101 {
    margin-top: 10.1rem !important;
    margin-bottom: 10.1rem !important; }
  .my-lg-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-lg-99 {
    margin-top: 9.9rem !important;
    margin-bottom: 9.9rem !important; }
  .my-lg-98 {
    margin-top: 9.8rem !important;
    margin-bottom: 9.8rem !important; }
  .my-lg-97 {
    margin-top: 9.7rem !important;
    margin-bottom: 9.7rem !important; }
  .my-lg-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-lg-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-lg-94 {
    margin-top: 9.4rem !important;
    margin-bottom: 9.4rem !important; }
  .my-lg-93 {
    margin-top: 9.3rem !important;
    margin-bottom: 9.3rem !important; }
  .my-lg-92 {
    margin-top: 9.2rem !important;
    margin-bottom: 9.2rem !important; }
  .my-lg-91 {
    margin-top: 9.1rem !important;
    margin-bottom: 9.1rem !important; }
  .my-lg-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-lg-89 {
    margin-top: 8.9rem !important;
    margin-bottom: 8.9rem !important; }
  .my-lg-88 {
    margin-top: 8.8rem !important;
    margin-bottom: 8.8rem !important; }
  .my-lg-87 {
    margin-top: 8.7rem !important;
    margin-bottom: 8.7rem !important; }
  .my-lg-86 {
    margin-top: 8.6rem !important;
    margin-bottom: 8.6rem !important; }
  .my-lg-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-lg-84 {
    margin-top: 8.4rem !important;
    margin-bottom: 8.4rem !important; }
  .my-lg-83 {
    margin-top: 8.3rem !important;
    margin-bottom: 8.3rem !important; }
  .my-lg-82 {
    margin-top: 8.2rem !important;
    margin-bottom: 8.2rem !important; }
  .my-lg-81 {
    margin-top: 8.1rem !important;
    margin-bottom: 8.1rem !important; }
  .my-lg-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-lg-79 {
    margin-top: 7.9rem !important;
    margin-bottom: 7.9rem !important; }
  .my-lg-78 {
    margin-top: 7.8rem !important;
    margin-bottom: 7.8rem !important; }
  .my-lg-77 {
    margin-top: 7.7rem !important;
    margin-bottom: 7.7rem !important; }
  .my-lg-76 {
    margin-top: 7.6rem !important;
    margin-bottom: 7.6rem !important; }
  .my-lg-75 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-lg-74 {
    margin-top: 7.4rem !important;
    margin-bottom: 7.4rem !important; }
  .my-lg-73 {
    margin-top: 7.3rem !important;
    margin-bottom: 7.3rem !important; }
  .my-lg-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-lg-71 {
    margin-top: 7.1rem !important;
    margin-bottom: 7.1rem !important; }
  .my-lg-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-lg-69 {
    margin-top: 6.9rem !important;
    margin-bottom: 6.9rem !important; }
  .my-lg-68 {
    margin-top: 6.8rem !important;
    margin-bottom: 6.8rem !important; }
  .my-lg-67 {
    margin-top: 6.7rem !important;
    margin-bottom: 6.7rem !important; }
  .my-lg-66 {
    margin-top: 6.6rem !important;
    margin-bottom: 6.6rem !important; }
  .my-lg-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-lg-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-lg-63 {
    margin-top: 6.3rem !important;
    margin-bottom: 6.3rem !important; }
  .my-lg-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-lg-61 {
    margin-top: 6.1rem !important;
    margin-bottom: 6.1rem !important; }
  .my-lg-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-lg-59 {
    margin-top: 5.9rem !important;
    margin-bottom: 5.9rem !important; }
  .my-lg-58 {
    margin-top: 5.8rem !important;
    margin-bottom: 5.8rem !important; }
  .my-lg-57 {
    margin-top: 5.7rem !important;
    margin-bottom: 5.7rem !important; }
  .my-lg-56 {
    margin-top: 5.6rem !important;
    margin-bottom: 5.6rem !important; }
  .my-lg-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-lg-54 {
    margin-top: 5.4rem !important;
    margin-bottom: 5.4rem !important; }
  .my-lg-53 {
    margin-top: 5.3rem !important;
    margin-bottom: 5.3rem !important; }
  .my-lg-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-lg-51 {
    margin-top: 5.1rem !important;
    margin-bottom: 5.1rem !important; }
  .my-lg-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-lg-49 {
    margin-top: 4.9rem !important;
    margin-bottom: 4.9rem !important; }
  .my-lg-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-lg-47 {
    margin-top: 4.7rem !important;
    margin-bottom: 4.7rem !important; }
  .my-lg-46 {
    margin-top: 4.6rem !important;
    margin-bottom: 4.6rem !important; }
  .my-lg-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-lg-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-lg-43 {
    margin-top: 4.3rem !important;
    margin-bottom: 4.3rem !important; }
  .my-lg-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-lg-41 {
    margin-top: 4.1rem !important;
    margin-bottom: 4.1rem !important; }
  .my-lg-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-lg-39 {
    margin-top: 3.9rem !important;
    margin-bottom: 3.9rem !important; }
  .my-lg-38 {
    margin-top: 3.8rem !important;
    margin-bottom: 3.8rem !important; }
  .my-lg-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-lg-36 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important; }
  .my-lg-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-lg-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-lg-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-lg-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-lg-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-lg-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-lg-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-lg-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-lg-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-lg-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-lg-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-lg-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-lg-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-lg-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-lg-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-lg-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-lg-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-lg-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-lg-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-lg-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-lg-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-lg-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-lg-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-lg-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .my-lg-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .my-lg-n150 {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-150 {
    margin-top: 15rem !important; }
  .mt-lg-149 {
    margin-top: 14.9rem !important; }
  .mt-lg-148 {
    margin-top: 14.8rem !important; }
  .mt-lg-147 {
    margin-top: 14.7rem !important; }
  .mt-lg-146 {
    margin-top: 14.6rem !important; }
  .mt-lg-145 {
    margin-top: 14.5rem !important; }
  .mt-lg-144 {
    margin-top: 14.4rem !important; }
  .mt-lg-143 {
    margin-top: 14.3rem !important; }
  .mt-lg-142 {
    margin-top: 14.2rem !important; }
  .mt-lg-141 {
    margin-top: 14.1rem !important; }
  .mt-lg-140 {
    margin-top: 14rem !important; }
  .mt-lg-139 {
    margin-top: 13.9rem !important; }
  .mt-lg-138 {
    margin-top: 13.8rem !important; }
  .mt-lg-137 {
    margin-top: 13.7rem !important; }
  .mt-lg-136 {
    margin-top: 13.6rem !important; }
  .mt-lg-135 {
    margin-top: 13.5rem !important; }
  .mt-lg-134 {
    margin-top: 13.4rem !important; }
  .mt-lg-133 {
    margin-top: 13.3rem !important; }
  .mt-lg-132 {
    margin-top: 13.2rem !important; }
  .mt-lg-131 {
    margin-top: 13.1rem !important; }
  .mt-lg-130 {
    margin-top: 13rem !important; }
  .mt-lg-129 {
    margin-top: 12.9rem !important; }
  .mt-lg-128 {
    margin-top: 12.8rem !important; }
  .mt-lg-127 {
    margin-top: 12.7rem !important; }
  .mt-lg-126 {
    margin-top: 12.6rem !important; }
  .mt-lg-125 {
    margin-top: 12.5rem !important; }
  .mt-lg-124 {
    margin-top: 12.4rem !important; }
  .mt-lg-123 {
    margin-top: 12.3rem !important; }
  .mt-lg-122 {
    margin-top: 12.2rem !important; }
  .mt-lg-121 {
    margin-top: 12.1rem !important; }
  .mt-lg-120 {
    margin-top: 12rem !important; }
  .mt-lg-119 {
    margin-top: 11.9rem !important; }
  .mt-lg-118 {
    margin-top: 11.8rem !important; }
  .mt-lg-117 {
    margin-top: 11.7rem !important; }
  .mt-lg-116 {
    margin-top: 11.6rem !important; }
  .mt-lg-115 {
    margin-top: 11.5rem !important; }
  .mt-lg-114 {
    margin-top: 11.4rem !important; }
  .mt-lg-113 {
    margin-top: 11.3rem !important; }
  .mt-lg-112 {
    margin-top: 11.2rem !important; }
  .mt-lg-111 {
    margin-top: 11.1rem !important; }
  .mt-lg-110 {
    margin-top: 11rem !important; }
  .mt-lg-109 {
    margin-top: 10.9rem !important; }
  .mt-lg-108 {
    margin-top: 10.8rem !important; }
  .mt-lg-107 {
    margin-top: 10.7rem !important; }
  .mt-lg-106 {
    margin-top: 10.6rem !important; }
  .mt-lg-105 {
    margin-top: 10.5rem !important; }
  .mt-lg-104 {
    margin-top: 10.4rem !important; }
  .mt-lg-103 {
    margin-top: 10.3rem !important; }
  .mt-lg-102 {
    margin-top: 10.2rem !important; }
  .mt-lg-101 {
    margin-top: 10.1rem !important; }
  .mt-lg-100 {
    margin-top: 10rem !important; }
  .mt-lg-99 {
    margin-top: 9.9rem !important; }
  .mt-lg-98 {
    margin-top: 9.8rem !important; }
  .mt-lg-97 {
    margin-top: 9.7rem !important; }
  .mt-lg-96 {
    margin-top: 9.6rem !important; }
  .mt-lg-95 {
    margin-top: 9.5rem !important; }
  .mt-lg-94 {
    margin-top: 9.4rem !important; }
  .mt-lg-93 {
    margin-top: 9.3rem !important; }
  .mt-lg-92 {
    margin-top: 9.2rem !important; }
  .mt-lg-91 {
    margin-top: 9.1rem !important; }
  .mt-lg-90 {
    margin-top: 9rem !important; }
  .mt-lg-89 {
    margin-top: 8.9rem !important; }
  .mt-lg-88 {
    margin-top: 8.8rem !important; }
  .mt-lg-87 {
    margin-top: 8.7rem !important; }
  .mt-lg-86 {
    margin-top: 8.6rem !important; }
  .mt-lg-85 {
    margin-top: 8.5rem !important; }
  .mt-lg-84 {
    margin-top: 8.4rem !important; }
  .mt-lg-83 {
    margin-top: 8.3rem !important; }
  .mt-lg-82 {
    margin-top: 8.2rem !important; }
  .mt-lg-81 {
    margin-top: 8.1rem !important; }
  .mt-lg-80 {
    margin-top: 8rem !important; }
  .mt-lg-79 {
    margin-top: 7.9rem !important; }
  .mt-lg-78 {
    margin-top: 7.8rem !important; }
  .mt-lg-77 {
    margin-top: 7.7rem !important; }
  .mt-lg-76 {
    margin-top: 7.6rem !important; }
  .mt-lg-75 {
    margin-top: 7.5rem !important; }
  .mt-lg-74 {
    margin-top: 7.4rem !important; }
  .mt-lg-73 {
    margin-top: 7.3rem !important; }
  .mt-lg-72 {
    margin-top: 7.2rem !important; }
  .mt-lg-71 {
    margin-top: 7.1rem !important; }
  .mt-lg-70 {
    margin-top: 7rem !important; }
  .mt-lg-69 {
    margin-top: 6.9rem !important; }
  .mt-lg-68 {
    margin-top: 6.8rem !important; }
  .mt-lg-67 {
    margin-top: 6.7rem !important; }
  .mt-lg-66 {
    margin-top: 6.6rem !important; }
  .mt-lg-65 {
    margin-top: 6.5rem !important; }
  .mt-lg-64 {
    margin-top: 6.4rem !important; }
  .mt-lg-63 {
    margin-top: 6.3rem !important; }
  .mt-lg-62 {
    margin-top: 6.2rem !important; }
  .mt-lg-61 {
    margin-top: 6.1rem !important; }
  .mt-lg-60 {
    margin-top: 6rem !important; }
  .mt-lg-59 {
    margin-top: 5.9rem !important; }
  .mt-lg-58 {
    margin-top: 5.8rem !important; }
  .mt-lg-57 {
    margin-top: 5.7rem !important; }
  .mt-lg-56 {
    margin-top: 5.6rem !important; }
  .mt-lg-55 {
    margin-top: 5.5rem !important; }
  .mt-lg-54 {
    margin-top: 5.4rem !important; }
  .mt-lg-53 {
    margin-top: 5.3rem !important; }
  .mt-lg-52 {
    margin-top: 5.2rem !important; }
  .mt-lg-51 {
    margin-top: 5.1rem !important; }
  .mt-lg-50 {
    margin-top: 5rem !important; }
  .mt-lg-49 {
    margin-top: 4.9rem !important; }
  .mt-lg-48 {
    margin-top: 4.8rem !important; }
  .mt-lg-47 {
    margin-top: 4.7rem !important; }
  .mt-lg-46 {
    margin-top: 4.6rem !important; }
  .mt-lg-45 {
    margin-top: 4.5rem !important; }
  .mt-lg-44 {
    margin-top: 4.4rem !important; }
  .mt-lg-43 {
    margin-top: 4.3rem !important; }
  .mt-lg-42 {
    margin-top: 4.2rem !important; }
  .mt-lg-41 {
    margin-top: 4.1rem !important; }
  .mt-lg-40 {
    margin-top: 4rem !important; }
  .mt-lg-39 {
    margin-top: 3.9rem !important; }
  .mt-lg-38 {
    margin-top: 3.8rem !important; }
  .mt-lg-37 {
    margin-top: 3.7rem !important; }
  .mt-lg-36 {
    margin-top: 3.6rem !important; }
  .mt-lg-35 {
    margin-top: 3.5rem !important; }
  .mt-lg-34 {
    margin-top: 3.4rem !important; }
  .mt-lg-33 {
    margin-top: 3.3rem !important; }
  .mt-lg-32 {
    margin-top: 3.2rem !important; }
  .mt-lg-31 {
    margin-top: 3.1rem !important; }
  .mt-lg-30 {
    margin-top: 3rem !important; }
  .mt-lg-29 {
    margin-top: 2.9rem !important; }
  .mt-lg-28 {
    margin-top: 2.8rem !important; }
  .mt-lg-27 {
    margin-top: 2.7rem !important; }
  .mt-lg-26 {
    margin-top: 2.6rem !important; }
  .mt-lg-25 {
    margin-top: 2.5rem !important; }
  .mt-lg-24 {
    margin-top: 2.4rem !important; }
  .mt-lg-23 {
    margin-top: 2.3rem !important; }
  .mt-lg-22 {
    margin-top: 2.2rem !important; }
  .mt-lg-21 {
    margin-top: 2.1rem !important; }
  .mt-lg-20 {
    margin-top: 2rem !important; }
  .mt-lg-19 {
    margin-top: 1.9rem !important; }
  .mt-lg-18 {
    margin-top: 1.8rem !important; }
  .mt-lg-17 {
    margin-top: 1.7rem !important; }
  .mt-lg-16 {
    margin-top: 1.6rem !important; }
  .mt-lg-15 {
    margin-top: 1.5rem !important; }
  .mt-lg-14 {
    margin-top: 1.4rem !important; }
  .mt-lg-13 {
    margin-top: 1.3rem !important; }
  .mt-lg-12 {
    margin-top: 1.2rem !important; }
  .mt-lg-11 {
    margin-top: 1.1rem !important; }
  .mt-lg-10 {
    margin-top: 1rem !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-n80 {
    margin-top: -80px !important; }
  .mt-lg-n100 {
    margin-top: -100px !important; }
  .mt-lg-n150 {
    margin-top: -150px !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-150 {
    margin-right: 15rem !important; }
  .me-lg-149 {
    margin-right: 14.9rem !important; }
  .me-lg-148 {
    margin-right: 14.8rem !important; }
  .me-lg-147 {
    margin-right: 14.7rem !important; }
  .me-lg-146 {
    margin-right: 14.6rem !important; }
  .me-lg-145 {
    margin-right: 14.5rem !important; }
  .me-lg-144 {
    margin-right: 14.4rem !important; }
  .me-lg-143 {
    margin-right: 14.3rem !important; }
  .me-lg-142 {
    margin-right: 14.2rem !important; }
  .me-lg-141 {
    margin-right: 14.1rem !important; }
  .me-lg-140 {
    margin-right: 14rem !important; }
  .me-lg-139 {
    margin-right: 13.9rem !important; }
  .me-lg-138 {
    margin-right: 13.8rem !important; }
  .me-lg-137 {
    margin-right: 13.7rem !important; }
  .me-lg-136 {
    margin-right: 13.6rem !important; }
  .me-lg-135 {
    margin-right: 13.5rem !important; }
  .me-lg-134 {
    margin-right: 13.4rem !important; }
  .me-lg-133 {
    margin-right: 13.3rem !important; }
  .me-lg-132 {
    margin-right: 13.2rem !important; }
  .me-lg-131 {
    margin-right: 13.1rem !important; }
  .me-lg-130 {
    margin-right: 13rem !important; }
  .me-lg-129 {
    margin-right: 12.9rem !important; }
  .me-lg-128 {
    margin-right: 12.8rem !important; }
  .me-lg-127 {
    margin-right: 12.7rem !important; }
  .me-lg-126 {
    margin-right: 12.6rem !important; }
  .me-lg-125 {
    margin-right: 12.5rem !important; }
  .me-lg-124 {
    margin-right: 12.4rem !important; }
  .me-lg-123 {
    margin-right: 12.3rem !important; }
  .me-lg-122 {
    margin-right: 12.2rem !important; }
  .me-lg-121 {
    margin-right: 12.1rem !important; }
  .me-lg-120 {
    margin-right: 12rem !important; }
  .me-lg-119 {
    margin-right: 11.9rem !important; }
  .me-lg-118 {
    margin-right: 11.8rem !important; }
  .me-lg-117 {
    margin-right: 11.7rem !important; }
  .me-lg-116 {
    margin-right: 11.6rem !important; }
  .me-lg-115 {
    margin-right: 11.5rem !important; }
  .me-lg-114 {
    margin-right: 11.4rem !important; }
  .me-lg-113 {
    margin-right: 11.3rem !important; }
  .me-lg-112 {
    margin-right: 11.2rem !important; }
  .me-lg-111 {
    margin-right: 11.1rem !important; }
  .me-lg-110 {
    margin-right: 11rem !important; }
  .me-lg-109 {
    margin-right: 10.9rem !important; }
  .me-lg-108 {
    margin-right: 10.8rem !important; }
  .me-lg-107 {
    margin-right: 10.7rem !important; }
  .me-lg-106 {
    margin-right: 10.6rem !important; }
  .me-lg-105 {
    margin-right: 10.5rem !important; }
  .me-lg-104 {
    margin-right: 10.4rem !important; }
  .me-lg-103 {
    margin-right: 10.3rem !important; }
  .me-lg-102 {
    margin-right: 10.2rem !important; }
  .me-lg-101 {
    margin-right: 10.1rem !important; }
  .me-lg-100 {
    margin-right: 10rem !important; }
  .me-lg-99 {
    margin-right: 9.9rem !important; }
  .me-lg-98 {
    margin-right: 9.8rem !important; }
  .me-lg-97 {
    margin-right: 9.7rem !important; }
  .me-lg-96 {
    margin-right: 9.6rem !important; }
  .me-lg-95 {
    margin-right: 9.5rem !important; }
  .me-lg-94 {
    margin-right: 9.4rem !important; }
  .me-lg-93 {
    margin-right: 9.3rem !important; }
  .me-lg-92 {
    margin-right: 9.2rem !important; }
  .me-lg-91 {
    margin-right: 9.1rem !important; }
  .me-lg-90 {
    margin-right: 9rem !important; }
  .me-lg-89 {
    margin-right: 8.9rem !important; }
  .me-lg-88 {
    margin-right: 8.8rem !important; }
  .me-lg-87 {
    margin-right: 8.7rem !important; }
  .me-lg-86 {
    margin-right: 8.6rem !important; }
  .me-lg-85 {
    margin-right: 8.5rem !important; }
  .me-lg-84 {
    margin-right: 8.4rem !important; }
  .me-lg-83 {
    margin-right: 8.3rem !important; }
  .me-lg-82 {
    margin-right: 8.2rem !important; }
  .me-lg-81 {
    margin-right: 8.1rem !important; }
  .me-lg-80 {
    margin-right: 8rem !important; }
  .me-lg-79 {
    margin-right: 7.9rem !important; }
  .me-lg-78 {
    margin-right: 7.8rem !important; }
  .me-lg-77 {
    margin-right: 7.7rem !important; }
  .me-lg-76 {
    margin-right: 7.6rem !important; }
  .me-lg-75 {
    margin-right: 7.5rem !important; }
  .me-lg-74 {
    margin-right: 7.4rem !important; }
  .me-lg-73 {
    margin-right: 7.3rem !important; }
  .me-lg-72 {
    margin-right: 7.2rem !important; }
  .me-lg-71 {
    margin-right: 7.1rem !important; }
  .me-lg-70 {
    margin-right: 7rem !important; }
  .me-lg-69 {
    margin-right: 6.9rem !important; }
  .me-lg-68 {
    margin-right: 6.8rem !important; }
  .me-lg-67 {
    margin-right: 6.7rem !important; }
  .me-lg-66 {
    margin-right: 6.6rem !important; }
  .me-lg-65 {
    margin-right: 6.5rem !important; }
  .me-lg-64 {
    margin-right: 6.4rem !important; }
  .me-lg-63 {
    margin-right: 6.3rem !important; }
  .me-lg-62 {
    margin-right: 6.2rem !important; }
  .me-lg-61 {
    margin-right: 6.1rem !important; }
  .me-lg-60 {
    margin-right: 6rem !important; }
  .me-lg-59 {
    margin-right: 5.9rem !important; }
  .me-lg-58 {
    margin-right: 5.8rem !important; }
  .me-lg-57 {
    margin-right: 5.7rem !important; }
  .me-lg-56 {
    margin-right: 5.6rem !important; }
  .me-lg-55 {
    margin-right: 5.5rem !important; }
  .me-lg-54 {
    margin-right: 5.4rem !important; }
  .me-lg-53 {
    margin-right: 5.3rem !important; }
  .me-lg-52 {
    margin-right: 5.2rem !important; }
  .me-lg-51 {
    margin-right: 5.1rem !important; }
  .me-lg-50 {
    margin-right: 5rem !important; }
  .me-lg-49 {
    margin-right: 4.9rem !important; }
  .me-lg-48 {
    margin-right: 4.8rem !important; }
  .me-lg-47 {
    margin-right: 4.7rem !important; }
  .me-lg-46 {
    margin-right: 4.6rem !important; }
  .me-lg-45 {
    margin-right: 4.5rem !important; }
  .me-lg-44 {
    margin-right: 4.4rem !important; }
  .me-lg-43 {
    margin-right: 4.3rem !important; }
  .me-lg-42 {
    margin-right: 4.2rem !important; }
  .me-lg-41 {
    margin-right: 4.1rem !important; }
  .me-lg-40 {
    margin-right: 4rem !important; }
  .me-lg-39 {
    margin-right: 3.9rem !important; }
  .me-lg-38 {
    margin-right: 3.8rem !important; }
  .me-lg-37 {
    margin-right: 3.7rem !important; }
  .me-lg-36 {
    margin-right: 3.6rem !important; }
  .me-lg-35 {
    margin-right: 3.5rem !important; }
  .me-lg-34 {
    margin-right: 3.4rem !important; }
  .me-lg-33 {
    margin-right: 3.3rem !important; }
  .me-lg-32 {
    margin-right: 3.2rem !important; }
  .me-lg-31 {
    margin-right: 3.1rem !important; }
  .me-lg-30 {
    margin-right: 3rem !important; }
  .me-lg-29 {
    margin-right: 2.9rem !important; }
  .me-lg-28 {
    margin-right: 2.8rem !important; }
  .me-lg-27 {
    margin-right: 2.7rem !important; }
  .me-lg-26 {
    margin-right: 2.6rem !important; }
  .me-lg-25 {
    margin-right: 2.5rem !important; }
  .me-lg-24 {
    margin-right: 2.4rem !important; }
  .me-lg-23 {
    margin-right: 2.3rem !important; }
  .me-lg-22 {
    margin-right: 2.2rem !important; }
  .me-lg-21 {
    margin-right: 2.1rem !important; }
  .me-lg-20 {
    margin-right: 2rem !important; }
  .me-lg-19 {
    margin-right: 1.9rem !important; }
  .me-lg-18 {
    margin-right: 1.8rem !important; }
  .me-lg-17 {
    margin-right: 1.7rem !important; }
  .me-lg-16 {
    margin-right: 1.6rem !important; }
  .me-lg-15 {
    margin-right: 1.5rem !important; }
  .me-lg-14 {
    margin-right: 1.4rem !important; }
  .me-lg-13 {
    margin-right: 1.3rem !important; }
  .me-lg-12 {
    margin-right: 1.2rem !important; }
  .me-lg-11 {
    margin-right: 1.1rem !important; }
  .me-lg-10 {
    margin-right: 1rem !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-n80 {
    margin-right: -80px !important; }
  .me-lg-n100 {
    margin-right: -100px !important; }
  .me-lg-n150 {
    margin-right: -150px !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-150 {
    margin-bottom: 15rem !important; }
  .mb-lg-149 {
    margin-bottom: 14.9rem !important; }
  .mb-lg-148 {
    margin-bottom: 14.8rem !important; }
  .mb-lg-147 {
    margin-bottom: 14.7rem !important; }
  .mb-lg-146 {
    margin-bottom: 14.6rem !important; }
  .mb-lg-145 {
    margin-bottom: 14.5rem !important; }
  .mb-lg-144 {
    margin-bottom: 14.4rem !important; }
  .mb-lg-143 {
    margin-bottom: 14.3rem !important; }
  .mb-lg-142 {
    margin-bottom: 14.2rem !important; }
  .mb-lg-141 {
    margin-bottom: 14.1rem !important; }
  .mb-lg-140 {
    margin-bottom: 14rem !important; }
  .mb-lg-139 {
    margin-bottom: 13.9rem !important; }
  .mb-lg-138 {
    margin-bottom: 13.8rem !important; }
  .mb-lg-137 {
    margin-bottom: 13.7rem !important; }
  .mb-lg-136 {
    margin-bottom: 13.6rem !important; }
  .mb-lg-135 {
    margin-bottom: 13.5rem !important; }
  .mb-lg-134 {
    margin-bottom: 13.4rem !important; }
  .mb-lg-133 {
    margin-bottom: 13.3rem !important; }
  .mb-lg-132 {
    margin-bottom: 13.2rem !important; }
  .mb-lg-131 {
    margin-bottom: 13.1rem !important; }
  .mb-lg-130 {
    margin-bottom: 13rem !important; }
  .mb-lg-129 {
    margin-bottom: 12.9rem !important; }
  .mb-lg-128 {
    margin-bottom: 12.8rem !important; }
  .mb-lg-127 {
    margin-bottom: 12.7rem !important; }
  .mb-lg-126 {
    margin-bottom: 12.6rem !important; }
  .mb-lg-125 {
    margin-bottom: 12.5rem !important; }
  .mb-lg-124 {
    margin-bottom: 12.4rem !important; }
  .mb-lg-123 {
    margin-bottom: 12.3rem !important; }
  .mb-lg-122 {
    margin-bottom: 12.2rem !important; }
  .mb-lg-121 {
    margin-bottom: 12.1rem !important; }
  .mb-lg-120 {
    margin-bottom: 12rem !important; }
  .mb-lg-119 {
    margin-bottom: 11.9rem !important; }
  .mb-lg-118 {
    margin-bottom: 11.8rem !important; }
  .mb-lg-117 {
    margin-bottom: 11.7rem !important; }
  .mb-lg-116 {
    margin-bottom: 11.6rem !important; }
  .mb-lg-115 {
    margin-bottom: 11.5rem !important; }
  .mb-lg-114 {
    margin-bottom: 11.4rem !important; }
  .mb-lg-113 {
    margin-bottom: 11.3rem !important; }
  .mb-lg-112 {
    margin-bottom: 11.2rem !important; }
  .mb-lg-111 {
    margin-bottom: 11.1rem !important; }
  .mb-lg-110 {
    margin-bottom: 11rem !important; }
  .mb-lg-109 {
    margin-bottom: 10.9rem !important; }
  .mb-lg-108 {
    margin-bottom: 10.8rem !important; }
  .mb-lg-107 {
    margin-bottom: 10.7rem !important; }
  .mb-lg-106 {
    margin-bottom: 10.6rem !important; }
  .mb-lg-105 {
    margin-bottom: 10.5rem !important; }
  .mb-lg-104 {
    margin-bottom: 10.4rem !important; }
  .mb-lg-103 {
    margin-bottom: 10.3rem !important; }
  .mb-lg-102 {
    margin-bottom: 10.2rem !important; }
  .mb-lg-101 {
    margin-bottom: 10.1rem !important; }
  .mb-lg-100 {
    margin-bottom: 10rem !important; }
  .mb-lg-99 {
    margin-bottom: 9.9rem !important; }
  .mb-lg-98 {
    margin-bottom: 9.8rem !important; }
  .mb-lg-97 {
    margin-bottom: 9.7rem !important; }
  .mb-lg-96 {
    margin-bottom: 9.6rem !important; }
  .mb-lg-95 {
    margin-bottom: 9.5rem !important; }
  .mb-lg-94 {
    margin-bottom: 9.4rem !important; }
  .mb-lg-93 {
    margin-bottom: 9.3rem !important; }
  .mb-lg-92 {
    margin-bottom: 9.2rem !important; }
  .mb-lg-91 {
    margin-bottom: 9.1rem !important; }
  .mb-lg-90 {
    margin-bottom: 9rem !important; }
  .mb-lg-89 {
    margin-bottom: 8.9rem !important; }
  .mb-lg-88 {
    margin-bottom: 8.8rem !important; }
  .mb-lg-87 {
    margin-bottom: 8.7rem !important; }
  .mb-lg-86 {
    margin-bottom: 8.6rem !important; }
  .mb-lg-85 {
    margin-bottom: 8.5rem !important; }
  .mb-lg-84 {
    margin-bottom: 8.4rem !important; }
  .mb-lg-83 {
    margin-bottom: 8.3rem !important; }
  .mb-lg-82 {
    margin-bottom: 8.2rem !important; }
  .mb-lg-81 {
    margin-bottom: 8.1rem !important; }
  .mb-lg-80 {
    margin-bottom: 8rem !important; }
  .mb-lg-79 {
    margin-bottom: 7.9rem !important; }
  .mb-lg-78 {
    margin-bottom: 7.8rem !important; }
  .mb-lg-77 {
    margin-bottom: 7.7rem !important; }
  .mb-lg-76 {
    margin-bottom: 7.6rem !important; }
  .mb-lg-75 {
    margin-bottom: 7.5rem !important; }
  .mb-lg-74 {
    margin-bottom: 7.4rem !important; }
  .mb-lg-73 {
    margin-bottom: 7.3rem !important; }
  .mb-lg-72 {
    margin-bottom: 7.2rem !important; }
  .mb-lg-71 {
    margin-bottom: 7.1rem !important; }
  .mb-lg-70 {
    margin-bottom: 7rem !important; }
  .mb-lg-69 {
    margin-bottom: 6.9rem !important; }
  .mb-lg-68 {
    margin-bottom: 6.8rem !important; }
  .mb-lg-67 {
    margin-bottom: 6.7rem !important; }
  .mb-lg-66 {
    margin-bottom: 6.6rem !important; }
  .mb-lg-65 {
    margin-bottom: 6.5rem !important; }
  .mb-lg-64 {
    margin-bottom: 6.4rem !important; }
  .mb-lg-63 {
    margin-bottom: 6.3rem !important; }
  .mb-lg-62 {
    margin-bottom: 6.2rem !important; }
  .mb-lg-61 {
    margin-bottom: 6.1rem !important; }
  .mb-lg-60 {
    margin-bottom: 6rem !important; }
  .mb-lg-59 {
    margin-bottom: 5.9rem !important; }
  .mb-lg-58 {
    margin-bottom: 5.8rem !important; }
  .mb-lg-57 {
    margin-bottom: 5.7rem !important; }
  .mb-lg-56 {
    margin-bottom: 5.6rem !important; }
  .mb-lg-55 {
    margin-bottom: 5.5rem !important; }
  .mb-lg-54 {
    margin-bottom: 5.4rem !important; }
  .mb-lg-53 {
    margin-bottom: 5.3rem !important; }
  .mb-lg-52 {
    margin-bottom: 5.2rem !important; }
  .mb-lg-51 {
    margin-bottom: 5.1rem !important; }
  .mb-lg-50 {
    margin-bottom: 5rem !important; }
  .mb-lg-49 {
    margin-bottom: 4.9rem !important; }
  .mb-lg-48 {
    margin-bottom: 4.8rem !important; }
  .mb-lg-47 {
    margin-bottom: 4.7rem !important; }
  .mb-lg-46 {
    margin-bottom: 4.6rem !important; }
  .mb-lg-45 {
    margin-bottom: 4.5rem !important; }
  .mb-lg-44 {
    margin-bottom: 4.4rem !important; }
  .mb-lg-43 {
    margin-bottom: 4.3rem !important; }
  .mb-lg-42 {
    margin-bottom: 4.2rem !important; }
  .mb-lg-41 {
    margin-bottom: 4.1rem !important; }
  .mb-lg-40 {
    margin-bottom: 4rem !important; }
  .mb-lg-39 {
    margin-bottom: 3.9rem !important; }
  .mb-lg-38 {
    margin-bottom: 3.8rem !important; }
  .mb-lg-37 {
    margin-bottom: 3.7rem !important; }
  .mb-lg-36 {
    margin-bottom: 3.6rem !important; }
  .mb-lg-35 {
    margin-bottom: 3.5rem !important; }
  .mb-lg-34 {
    margin-bottom: 3.4rem !important; }
  .mb-lg-33 {
    margin-bottom: 3.3rem !important; }
  .mb-lg-32 {
    margin-bottom: 3.2rem !important; }
  .mb-lg-31 {
    margin-bottom: 3.1rem !important; }
  .mb-lg-30 {
    margin-bottom: 3rem !important; }
  .mb-lg-29 {
    margin-bottom: 2.9rem !important; }
  .mb-lg-28 {
    margin-bottom: 2.8rem !important; }
  .mb-lg-27 {
    margin-bottom: 2.7rem !important; }
  .mb-lg-26 {
    margin-bottom: 2.6rem !important; }
  .mb-lg-25 {
    margin-bottom: 2.5rem !important; }
  .mb-lg-24 {
    margin-bottom: 2.4rem !important; }
  .mb-lg-23 {
    margin-bottom: 2.3rem !important; }
  .mb-lg-22 {
    margin-bottom: 2.2rem !important; }
  .mb-lg-21 {
    margin-bottom: 2.1rem !important; }
  .mb-lg-20 {
    margin-bottom: 2rem !important; }
  .mb-lg-19 {
    margin-bottom: 1.9rem !important; }
  .mb-lg-18 {
    margin-bottom: 1.8rem !important; }
  .mb-lg-17 {
    margin-bottom: 1.7rem !important; }
  .mb-lg-16 {
    margin-bottom: 1.6rem !important; }
  .mb-lg-15 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-14 {
    margin-bottom: 1.4rem !important; }
  .mb-lg-13 {
    margin-bottom: 1.3rem !important; }
  .mb-lg-12 {
    margin-bottom: 1.2rem !important; }
  .mb-lg-11 {
    margin-bottom: 1.1rem !important; }
  .mb-lg-10 {
    margin-bottom: 1rem !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-n80 {
    margin-bottom: -80px !important; }
  .mb-lg-n100 {
    margin-bottom: -100px !important; }
  .mb-lg-n150 {
    margin-bottom: -150px !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-150 {
    margin-left: 15rem !important; }
  .ms-lg-149 {
    margin-left: 14.9rem !important; }
  .ms-lg-148 {
    margin-left: 14.8rem !important; }
  .ms-lg-147 {
    margin-left: 14.7rem !important; }
  .ms-lg-146 {
    margin-left: 14.6rem !important; }
  .ms-lg-145 {
    margin-left: 14.5rem !important; }
  .ms-lg-144 {
    margin-left: 14.4rem !important; }
  .ms-lg-143 {
    margin-left: 14.3rem !important; }
  .ms-lg-142 {
    margin-left: 14.2rem !important; }
  .ms-lg-141 {
    margin-left: 14.1rem !important; }
  .ms-lg-140 {
    margin-left: 14rem !important; }
  .ms-lg-139 {
    margin-left: 13.9rem !important; }
  .ms-lg-138 {
    margin-left: 13.8rem !important; }
  .ms-lg-137 {
    margin-left: 13.7rem !important; }
  .ms-lg-136 {
    margin-left: 13.6rem !important; }
  .ms-lg-135 {
    margin-left: 13.5rem !important; }
  .ms-lg-134 {
    margin-left: 13.4rem !important; }
  .ms-lg-133 {
    margin-left: 13.3rem !important; }
  .ms-lg-132 {
    margin-left: 13.2rem !important; }
  .ms-lg-131 {
    margin-left: 13.1rem !important; }
  .ms-lg-130 {
    margin-left: 13rem !important; }
  .ms-lg-129 {
    margin-left: 12.9rem !important; }
  .ms-lg-128 {
    margin-left: 12.8rem !important; }
  .ms-lg-127 {
    margin-left: 12.7rem !important; }
  .ms-lg-126 {
    margin-left: 12.6rem !important; }
  .ms-lg-125 {
    margin-left: 12.5rem !important; }
  .ms-lg-124 {
    margin-left: 12.4rem !important; }
  .ms-lg-123 {
    margin-left: 12.3rem !important; }
  .ms-lg-122 {
    margin-left: 12.2rem !important; }
  .ms-lg-121 {
    margin-left: 12.1rem !important; }
  .ms-lg-120 {
    margin-left: 12rem !important; }
  .ms-lg-119 {
    margin-left: 11.9rem !important; }
  .ms-lg-118 {
    margin-left: 11.8rem !important; }
  .ms-lg-117 {
    margin-left: 11.7rem !important; }
  .ms-lg-116 {
    margin-left: 11.6rem !important; }
  .ms-lg-115 {
    margin-left: 11.5rem !important; }
  .ms-lg-114 {
    margin-left: 11.4rem !important; }
  .ms-lg-113 {
    margin-left: 11.3rem !important; }
  .ms-lg-112 {
    margin-left: 11.2rem !important; }
  .ms-lg-111 {
    margin-left: 11.1rem !important; }
  .ms-lg-110 {
    margin-left: 11rem !important; }
  .ms-lg-109 {
    margin-left: 10.9rem !important; }
  .ms-lg-108 {
    margin-left: 10.8rem !important; }
  .ms-lg-107 {
    margin-left: 10.7rem !important; }
  .ms-lg-106 {
    margin-left: 10.6rem !important; }
  .ms-lg-105 {
    margin-left: 10.5rem !important; }
  .ms-lg-104 {
    margin-left: 10.4rem !important; }
  .ms-lg-103 {
    margin-left: 10.3rem !important; }
  .ms-lg-102 {
    margin-left: 10.2rem !important; }
  .ms-lg-101 {
    margin-left: 10.1rem !important; }
  .ms-lg-100 {
    margin-left: 10rem !important; }
  .ms-lg-99 {
    margin-left: 9.9rem !important; }
  .ms-lg-98 {
    margin-left: 9.8rem !important; }
  .ms-lg-97 {
    margin-left: 9.7rem !important; }
  .ms-lg-96 {
    margin-left: 9.6rem !important; }
  .ms-lg-95 {
    margin-left: 9.5rem !important; }
  .ms-lg-94 {
    margin-left: 9.4rem !important; }
  .ms-lg-93 {
    margin-left: 9.3rem !important; }
  .ms-lg-92 {
    margin-left: 9.2rem !important; }
  .ms-lg-91 {
    margin-left: 9.1rem !important; }
  .ms-lg-90 {
    margin-left: 9rem !important; }
  .ms-lg-89 {
    margin-left: 8.9rem !important; }
  .ms-lg-88 {
    margin-left: 8.8rem !important; }
  .ms-lg-87 {
    margin-left: 8.7rem !important; }
  .ms-lg-86 {
    margin-left: 8.6rem !important; }
  .ms-lg-85 {
    margin-left: 8.5rem !important; }
  .ms-lg-84 {
    margin-left: 8.4rem !important; }
  .ms-lg-83 {
    margin-left: 8.3rem !important; }
  .ms-lg-82 {
    margin-left: 8.2rem !important; }
  .ms-lg-81 {
    margin-left: 8.1rem !important; }
  .ms-lg-80 {
    margin-left: 8rem !important; }
  .ms-lg-79 {
    margin-left: 7.9rem !important; }
  .ms-lg-78 {
    margin-left: 7.8rem !important; }
  .ms-lg-77 {
    margin-left: 7.7rem !important; }
  .ms-lg-76 {
    margin-left: 7.6rem !important; }
  .ms-lg-75 {
    margin-left: 7.5rem !important; }
  .ms-lg-74 {
    margin-left: 7.4rem !important; }
  .ms-lg-73 {
    margin-left: 7.3rem !important; }
  .ms-lg-72 {
    margin-left: 7.2rem !important; }
  .ms-lg-71 {
    margin-left: 7.1rem !important; }
  .ms-lg-70 {
    margin-left: 7rem !important; }
  .ms-lg-69 {
    margin-left: 6.9rem !important; }
  .ms-lg-68 {
    margin-left: 6.8rem !important; }
  .ms-lg-67 {
    margin-left: 6.7rem !important; }
  .ms-lg-66 {
    margin-left: 6.6rem !important; }
  .ms-lg-65 {
    margin-left: 6.5rem !important; }
  .ms-lg-64 {
    margin-left: 6.4rem !important; }
  .ms-lg-63 {
    margin-left: 6.3rem !important; }
  .ms-lg-62 {
    margin-left: 6.2rem !important; }
  .ms-lg-61 {
    margin-left: 6.1rem !important; }
  .ms-lg-60 {
    margin-left: 6rem !important; }
  .ms-lg-59 {
    margin-left: 5.9rem !important; }
  .ms-lg-58 {
    margin-left: 5.8rem !important; }
  .ms-lg-57 {
    margin-left: 5.7rem !important; }
  .ms-lg-56 {
    margin-left: 5.6rem !important; }
  .ms-lg-55 {
    margin-left: 5.5rem !important; }
  .ms-lg-54 {
    margin-left: 5.4rem !important; }
  .ms-lg-53 {
    margin-left: 5.3rem !important; }
  .ms-lg-52 {
    margin-left: 5.2rem !important; }
  .ms-lg-51 {
    margin-left: 5.1rem !important; }
  .ms-lg-50 {
    margin-left: 5rem !important; }
  .ms-lg-49 {
    margin-left: 4.9rem !important; }
  .ms-lg-48 {
    margin-left: 4.8rem !important; }
  .ms-lg-47 {
    margin-left: 4.7rem !important; }
  .ms-lg-46 {
    margin-left: 4.6rem !important; }
  .ms-lg-45 {
    margin-left: 4.5rem !important; }
  .ms-lg-44 {
    margin-left: 4.4rem !important; }
  .ms-lg-43 {
    margin-left: 4.3rem !important; }
  .ms-lg-42 {
    margin-left: 4.2rem !important; }
  .ms-lg-41 {
    margin-left: 4.1rem !important; }
  .ms-lg-40 {
    margin-left: 4rem !important; }
  .ms-lg-39 {
    margin-left: 3.9rem !important; }
  .ms-lg-38 {
    margin-left: 3.8rem !important; }
  .ms-lg-37 {
    margin-left: 3.7rem !important; }
  .ms-lg-36 {
    margin-left: 3.6rem !important; }
  .ms-lg-35 {
    margin-left: 3.5rem !important; }
  .ms-lg-34 {
    margin-left: 3.4rem !important; }
  .ms-lg-33 {
    margin-left: 3.3rem !important; }
  .ms-lg-32 {
    margin-left: 3.2rem !important; }
  .ms-lg-31 {
    margin-left: 3.1rem !important; }
  .ms-lg-30 {
    margin-left: 3rem !important; }
  .ms-lg-29 {
    margin-left: 2.9rem !important; }
  .ms-lg-28 {
    margin-left: 2.8rem !important; }
  .ms-lg-27 {
    margin-left: 2.7rem !important; }
  .ms-lg-26 {
    margin-left: 2.6rem !important; }
  .ms-lg-25 {
    margin-left: 2.5rem !important; }
  .ms-lg-24 {
    margin-left: 2.4rem !important; }
  .ms-lg-23 {
    margin-left: 2.3rem !important; }
  .ms-lg-22 {
    margin-left: 2.2rem !important; }
  .ms-lg-21 {
    margin-left: 2.1rem !important; }
  .ms-lg-20 {
    margin-left: 2rem !important; }
  .ms-lg-19 {
    margin-left: 1.9rem !important; }
  .ms-lg-18 {
    margin-left: 1.8rem !important; }
  .ms-lg-17 {
    margin-left: 1.7rem !important; }
  .ms-lg-16 {
    margin-left: 1.6rem !important; }
  .ms-lg-15 {
    margin-left: 1.5rem !important; }
  .ms-lg-14 {
    margin-left: 1.4rem !important; }
  .ms-lg-13 {
    margin-left: 1.3rem !important; }
  .ms-lg-12 {
    margin-left: 1.2rem !important; }
  .ms-lg-11 {
    margin-left: 1.1rem !important; }
  .ms-lg-10 {
    margin-left: 1rem !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-n80 {
    margin-left: -80px !important; }
  .ms-lg-n100 {
    margin-left: -100px !important; }
  .ms-lg-n150 {
    margin-left: -150px !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-150 {
    padding: 15rem !important; }
  .p-lg-149 {
    padding: 14.9rem !important; }
  .p-lg-148 {
    padding: 14.8rem !important; }
  .p-lg-147 {
    padding: 14.7rem !important; }
  .p-lg-146 {
    padding: 14.6rem !important; }
  .p-lg-145 {
    padding: 14.5rem !important; }
  .p-lg-144 {
    padding: 14.4rem !important; }
  .p-lg-143 {
    padding: 14.3rem !important; }
  .p-lg-142 {
    padding: 14.2rem !important; }
  .p-lg-141 {
    padding: 14.1rem !important; }
  .p-lg-140 {
    padding: 14rem !important; }
  .p-lg-139 {
    padding: 13.9rem !important; }
  .p-lg-138 {
    padding: 13.8rem !important; }
  .p-lg-137 {
    padding: 13.7rem !important; }
  .p-lg-136 {
    padding: 13.6rem !important; }
  .p-lg-135 {
    padding: 13.5rem !important; }
  .p-lg-134 {
    padding: 13.4rem !important; }
  .p-lg-133 {
    padding: 13.3rem !important; }
  .p-lg-132 {
    padding: 13.2rem !important; }
  .p-lg-131 {
    padding: 13.1rem !important; }
  .p-lg-130 {
    padding: 13rem !important; }
  .p-lg-129 {
    padding: 12.9rem !important; }
  .p-lg-128 {
    padding: 12.8rem !important; }
  .p-lg-127 {
    padding: 12.7rem !important; }
  .p-lg-126 {
    padding: 12.6rem !important; }
  .p-lg-125 {
    padding: 12.5rem !important; }
  .p-lg-124 {
    padding: 12.4rem !important; }
  .p-lg-123 {
    padding: 12.3rem !important; }
  .p-lg-122 {
    padding: 12.2rem !important; }
  .p-lg-121 {
    padding: 12.1rem !important; }
  .p-lg-120 {
    padding: 12rem !important; }
  .p-lg-119 {
    padding: 11.9rem !important; }
  .p-lg-118 {
    padding: 11.8rem !important; }
  .p-lg-117 {
    padding: 11.7rem !important; }
  .p-lg-116 {
    padding: 11.6rem !important; }
  .p-lg-115 {
    padding: 11.5rem !important; }
  .p-lg-114 {
    padding: 11.4rem !important; }
  .p-lg-113 {
    padding: 11.3rem !important; }
  .p-lg-112 {
    padding: 11.2rem !important; }
  .p-lg-111 {
    padding: 11.1rem !important; }
  .p-lg-110 {
    padding: 11rem !important; }
  .p-lg-109 {
    padding: 10.9rem !important; }
  .p-lg-108 {
    padding: 10.8rem !important; }
  .p-lg-107 {
    padding: 10.7rem !important; }
  .p-lg-106 {
    padding: 10.6rem !important; }
  .p-lg-105 {
    padding: 10.5rem !important; }
  .p-lg-104 {
    padding: 10.4rem !important; }
  .p-lg-103 {
    padding: 10.3rem !important; }
  .p-lg-102 {
    padding: 10.2rem !important; }
  .p-lg-101 {
    padding: 10.1rem !important; }
  .p-lg-100 {
    padding: 10rem !important; }
  .p-lg-99 {
    padding: 9.9rem !important; }
  .p-lg-98 {
    padding: 9.8rem !important; }
  .p-lg-97 {
    padding: 9.7rem !important; }
  .p-lg-96 {
    padding: 9.6rem !important; }
  .p-lg-95 {
    padding: 9.5rem !important; }
  .p-lg-94 {
    padding: 9.4rem !important; }
  .p-lg-93 {
    padding: 9.3rem !important; }
  .p-lg-92 {
    padding: 9.2rem !important; }
  .p-lg-91 {
    padding: 9.1rem !important; }
  .p-lg-90 {
    padding: 9rem !important; }
  .p-lg-89 {
    padding: 8.9rem !important; }
  .p-lg-88 {
    padding: 8.8rem !important; }
  .p-lg-87 {
    padding: 8.7rem !important; }
  .p-lg-86 {
    padding: 8.6rem !important; }
  .p-lg-85 {
    padding: 8.5rem !important; }
  .p-lg-84 {
    padding: 8.4rem !important; }
  .p-lg-83 {
    padding: 8.3rem !important; }
  .p-lg-82 {
    padding: 8.2rem !important; }
  .p-lg-81 {
    padding: 8.1rem !important; }
  .p-lg-80 {
    padding: 8rem !important; }
  .p-lg-79 {
    padding: 7.9rem !important; }
  .p-lg-78 {
    padding: 7.8rem !important; }
  .p-lg-77 {
    padding: 7.7rem !important; }
  .p-lg-76 {
    padding: 7.6rem !important; }
  .p-lg-75 {
    padding: 7.5rem !important; }
  .p-lg-74 {
    padding: 7.4rem !important; }
  .p-lg-73 {
    padding: 7.3rem !important; }
  .p-lg-72 {
    padding: 7.2rem !important; }
  .p-lg-71 {
    padding: 7.1rem !important; }
  .p-lg-70 {
    padding: 7rem !important; }
  .p-lg-69 {
    padding: 6.9rem !important; }
  .p-lg-68 {
    padding: 6.8rem !important; }
  .p-lg-67 {
    padding: 6.7rem !important; }
  .p-lg-66 {
    padding: 6.6rem !important; }
  .p-lg-65 {
    padding: 6.5rem !important; }
  .p-lg-64 {
    padding: 6.4rem !important; }
  .p-lg-63 {
    padding: 6.3rem !important; }
  .p-lg-62 {
    padding: 6.2rem !important; }
  .p-lg-61 {
    padding: 6.1rem !important; }
  .p-lg-60 {
    padding: 6rem !important; }
  .p-lg-59 {
    padding: 5.9rem !important; }
  .p-lg-58 {
    padding: 5.8rem !important; }
  .p-lg-57 {
    padding: 5.7rem !important; }
  .p-lg-56 {
    padding: 5.6rem !important; }
  .p-lg-55 {
    padding: 5.5rem !important; }
  .p-lg-54 {
    padding: 5.4rem !important; }
  .p-lg-53 {
    padding: 5.3rem !important; }
  .p-lg-52 {
    padding: 5.2rem !important; }
  .p-lg-51 {
    padding: 5.1rem !important; }
  .p-lg-50 {
    padding: 5rem !important; }
  .p-lg-49 {
    padding: 4.9rem !important; }
  .p-lg-48 {
    padding: 4.8rem !important; }
  .p-lg-47 {
    padding: 4.7rem !important; }
  .p-lg-46 {
    padding: 4.6rem !important; }
  .p-lg-45 {
    padding: 4.5rem !important; }
  .p-lg-44 {
    padding: 4.4rem !important; }
  .p-lg-43 {
    padding: 4.3rem !important; }
  .p-lg-42 {
    padding: 4.2rem !important; }
  .p-lg-41 {
    padding: 4.1rem !important; }
  .p-lg-40 {
    padding: 4rem !important; }
  .p-lg-39 {
    padding: 3.9rem !important; }
  .p-lg-38 {
    padding: 3.8rem !important; }
  .p-lg-37 {
    padding: 3.7rem !important; }
  .p-lg-36 {
    padding: 3.6rem !important; }
  .p-lg-35 {
    padding: 3.5rem !important; }
  .p-lg-34 {
    padding: 3.4rem !important; }
  .p-lg-33 {
    padding: 3.3rem !important; }
  .p-lg-32 {
    padding: 3.2rem !important; }
  .p-lg-31 {
    padding: 3.1rem !important; }
  .p-lg-30 {
    padding: 3rem !important; }
  .p-lg-29 {
    padding: 2.9rem !important; }
  .p-lg-28 {
    padding: 2.8rem !important; }
  .p-lg-27 {
    padding: 2.7rem !important; }
  .p-lg-26 {
    padding: 2.6rem !important; }
  .p-lg-25 {
    padding: 2.5rem !important; }
  .p-lg-24 {
    padding: 2.4rem !important; }
  .p-lg-23 {
    padding: 2.3rem !important; }
  .p-lg-22 {
    padding: 2.2rem !important; }
  .p-lg-21 {
    padding: 2.1rem !important; }
  .p-lg-20 {
    padding: 2rem !important; }
  .p-lg-19 {
    padding: 1.9rem !important; }
  .p-lg-18 {
    padding: 1.8rem !important; }
  .p-lg-17 {
    padding: 1.7rem !important; }
  .p-lg-16 {
    padding: 1.6rem !important; }
  .p-lg-15 {
    padding: 1.5rem !important; }
  .p-lg-14 {
    padding: 1.4rem !important; }
  .p-lg-13 {
    padding: 1.3rem !important; }
  .p-lg-12 {
    padding: 1.2rem !important; }
  .p-lg-11 {
    padding: 1.1rem !important; }
  .p-lg-10 {
    padding: 1rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .p-lg-n80 {
    padding: -80px !important; }
  .p-lg-n100 {
    padding: -100px !important; }
  .p-lg-n150 {
    padding: -150px !important; }
  .px-lg-150 {
    padding-right: 15rem !important;
    padding-left: 15rem !important; }
  .px-lg-149 {
    padding-right: 14.9rem !important;
    padding-left: 14.9rem !important; }
  .px-lg-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-lg-147 {
    padding-right: 14.7rem !important;
    padding-left: 14.7rem !important; }
  .px-lg-146 {
    padding-right: 14.6rem !important;
    padding-left: 14.6rem !important; }
  .px-lg-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-lg-144 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important; }
  .px-lg-143 {
    padding-right: 14.3rem !important;
    padding-left: 14.3rem !important; }
  .px-lg-142 {
    padding-right: 14.2rem !important;
    padding-left: 14.2rem !important; }
  .px-lg-141 {
    padding-right: 14.1rem !important;
    padding-left: 14.1rem !important; }
  .px-lg-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-lg-139 {
    padding-right: 13.9rem !important;
    padding-left: 13.9rem !important; }
  .px-lg-138 {
    padding-right: 13.8rem !important;
    padding-left: 13.8rem !important; }
  .px-lg-137 {
    padding-right: 13.7rem !important;
    padding-left: 13.7rem !important; }
  .px-lg-136 {
    padding-right: 13.6rem !important;
    padding-left: 13.6rem !important; }
  .px-lg-135 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important; }
  .px-lg-134 {
    padding-right: 13.4rem !important;
    padding-left: 13.4rem !important; }
  .px-lg-133 {
    padding-right: 13.3rem !important;
    padding-left: 13.3rem !important; }
  .px-lg-132 {
    padding-right: 13.2rem !important;
    padding-left: 13.2rem !important; }
  .px-lg-131 {
    padding-right: 13.1rem !important;
    padding-left: 13.1rem !important; }
  .px-lg-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-lg-129 {
    padding-right: 12.9rem !important;
    padding-left: 12.9rem !important; }
  .px-lg-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-lg-127 {
    padding-right: 12.7rem !important;
    padding-left: 12.7rem !important; }
  .px-lg-126 {
    padding-right: 12.6rem !important;
    padding-left: 12.6rem !important; }
  .px-lg-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-lg-124 {
    padding-right: 12.4rem !important;
    padding-left: 12.4rem !important; }
  .px-lg-123 {
    padding-right: 12.3rem !important;
    padding-left: 12.3rem !important; }
  .px-lg-122 {
    padding-right: 12.2rem !important;
    padding-left: 12.2rem !important; }
  .px-lg-121 {
    padding-right: 12.1rem !important;
    padding-left: 12.1rem !important; }
  .px-lg-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-lg-119 {
    padding-right: 11.9rem !important;
    padding-left: 11.9rem !important; }
  .px-lg-118 {
    padding-right: 11.8rem !important;
    padding-left: 11.8rem !important; }
  .px-lg-117 {
    padding-right: 11.7rem !important;
    padding-left: 11.7rem !important; }
  .px-lg-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-lg-115 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .px-lg-114 {
    padding-right: 11.4rem !important;
    padding-left: 11.4rem !important; }
  .px-lg-113 {
    padding-right: 11.3rem !important;
    padding-left: 11.3rem !important; }
  .px-lg-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .px-lg-111 {
    padding-right: 11.1rem !important;
    padding-left: 11.1rem !important; }
  .px-lg-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-lg-109 {
    padding-right: 10.9rem !important;
    padding-left: 10.9rem !important; }
  .px-lg-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-lg-107 {
    padding-right: 10.7rem !important;
    padding-left: 10.7rem !important; }
  .px-lg-106 {
    padding-right: 10.6rem !important;
    padding-left: 10.6rem !important; }
  .px-lg-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-lg-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-lg-103 {
    padding-right: 10.3rem !important;
    padding-left: 10.3rem !important; }
  .px-lg-102 {
    padding-right: 10.2rem !important;
    padding-left: 10.2rem !important; }
  .px-lg-101 {
    padding-right: 10.1rem !important;
    padding-left: 10.1rem !important; }
  .px-lg-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-lg-99 {
    padding-right: 9.9rem !important;
    padding-left: 9.9rem !important; }
  .px-lg-98 {
    padding-right: 9.8rem !important;
    padding-left: 9.8rem !important; }
  .px-lg-97 {
    padding-right: 9.7rem !important;
    padding-left: 9.7rem !important; }
  .px-lg-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-lg-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-lg-94 {
    padding-right: 9.4rem !important;
    padding-left: 9.4rem !important; }
  .px-lg-93 {
    padding-right: 9.3rem !important;
    padding-left: 9.3rem !important; }
  .px-lg-92 {
    padding-right: 9.2rem !important;
    padding-left: 9.2rem !important; }
  .px-lg-91 {
    padding-right: 9.1rem !important;
    padding-left: 9.1rem !important; }
  .px-lg-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-lg-89 {
    padding-right: 8.9rem !important;
    padding-left: 8.9rem !important; }
  .px-lg-88 {
    padding-right: 8.8rem !important;
    padding-left: 8.8rem !important; }
  .px-lg-87 {
    padding-right: 8.7rem !important;
    padding-left: 8.7rem !important; }
  .px-lg-86 {
    padding-right: 8.6rem !important;
    padding-left: 8.6rem !important; }
  .px-lg-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-lg-84 {
    padding-right: 8.4rem !important;
    padding-left: 8.4rem !important; }
  .px-lg-83 {
    padding-right: 8.3rem !important;
    padding-left: 8.3rem !important; }
  .px-lg-82 {
    padding-right: 8.2rem !important;
    padding-left: 8.2rem !important; }
  .px-lg-81 {
    padding-right: 8.1rem !important;
    padding-left: 8.1rem !important; }
  .px-lg-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-lg-79 {
    padding-right: 7.9rem !important;
    padding-left: 7.9rem !important; }
  .px-lg-78 {
    padding-right: 7.8rem !important;
    padding-left: 7.8rem !important; }
  .px-lg-77 {
    padding-right: 7.7rem !important;
    padding-left: 7.7rem !important; }
  .px-lg-76 {
    padding-right: 7.6rem !important;
    padding-left: 7.6rem !important; }
  .px-lg-75 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-lg-74 {
    padding-right: 7.4rem !important;
    padding-left: 7.4rem !important; }
  .px-lg-73 {
    padding-right: 7.3rem !important;
    padding-left: 7.3rem !important; }
  .px-lg-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-lg-71 {
    padding-right: 7.1rem !important;
    padding-left: 7.1rem !important; }
  .px-lg-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-lg-69 {
    padding-right: 6.9rem !important;
    padding-left: 6.9rem !important; }
  .px-lg-68 {
    padding-right: 6.8rem !important;
    padding-left: 6.8rem !important; }
  .px-lg-67 {
    padding-right: 6.7rem !important;
    padding-left: 6.7rem !important; }
  .px-lg-66 {
    padding-right: 6.6rem !important;
    padding-left: 6.6rem !important; }
  .px-lg-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-lg-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-lg-63 {
    padding-right: 6.3rem !important;
    padding-left: 6.3rem !important; }
  .px-lg-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-lg-61 {
    padding-right: 6.1rem !important;
    padding-left: 6.1rem !important; }
  .px-lg-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-lg-59 {
    padding-right: 5.9rem !important;
    padding-left: 5.9rem !important; }
  .px-lg-58 {
    padding-right: 5.8rem !important;
    padding-left: 5.8rem !important; }
  .px-lg-57 {
    padding-right: 5.7rem !important;
    padding-left: 5.7rem !important; }
  .px-lg-56 {
    padding-right: 5.6rem !important;
    padding-left: 5.6rem !important; }
  .px-lg-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-lg-54 {
    padding-right: 5.4rem !important;
    padding-left: 5.4rem !important; }
  .px-lg-53 {
    padding-right: 5.3rem !important;
    padding-left: 5.3rem !important; }
  .px-lg-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-lg-51 {
    padding-right: 5.1rem !important;
    padding-left: 5.1rem !important; }
  .px-lg-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-lg-49 {
    padding-right: 4.9rem !important;
    padding-left: 4.9rem !important; }
  .px-lg-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-lg-47 {
    padding-right: 4.7rem !important;
    padding-left: 4.7rem !important; }
  .px-lg-46 {
    padding-right: 4.6rem !important;
    padding-left: 4.6rem !important; }
  .px-lg-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-lg-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-lg-43 {
    padding-right: 4.3rem !important;
    padding-left: 4.3rem !important; }
  .px-lg-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-lg-41 {
    padding-right: 4.1rem !important;
    padding-left: 4.1rem !important; }
  .px-lg-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-lg-39 {
    padding-right: 3.9rem !important;
    padding-left: 3.9rem !important; }
  .px-lg-38 {
    padding-right: 3.8rem !important;
    padding-left: 3.8rem !important; }
  .px-lg-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-lg-36 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important; }
  .px-lg-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-lg-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-lg-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-lg-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-lg-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-lg-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-lg-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-lg-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-lg-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-lg-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-lg-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-lg-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-lg-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-lg-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-lg-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-lg-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-lg-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-lg-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-lg-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-lg-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-lg-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-lg-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-lg-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-lg-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-n80 {
    padding-right: -80px !important;
    padding-left: -80px !important; }
  .px-lg-n100 {
    padding-right: -100px !important;
    padding-left: -100px !important; }
  .px-lg-n150 {
    padding-right: -150px !important;
    padding-left: -150px !important; }
  .py-lg-150 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important; }
  .py-lg-149 {
    padding-top: 14.9rem !important;
    padding-bottom: 14.9rem !important; }
  .py-lg-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-lg-147 {
    padding-top: 14.7rem !important;
    padding-bottom: 14.7rem !important; }
  .py-lg-146 {
    padding-top: 14.6rem !important;
    padding-bottom: 14.6rem !important; }
  .py-lg-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-lg-144 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important; }
  .py-lg-143 {
    padding-top: 14.3rem !important;
    padding-bottom: 14.3rem !important; }
  .py-lg-142 {
    padding-top: 14.2rem !important;
    padding-bottom: 14.2rem !important; }
  .py-lg-141 {
    padding-top: 14.1rem !important;
    padding-bottom: 14.1rem !important; }
  .py-lg-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-lg-139 {
    padding-top: 13.9rem !important;
    padding-bottom: 13.9rem !important; }
  .py-lg-138 {
    padding-top: 13.8rem !important;
    padding-bottom: 13.8rem !important; }
  .py-lg-137 {
    padding-top: 13.7rem !important;
    padding-bottom: 13.7rem !important; }
  .py-lg-136 {
    padding-top: 13.6rem !important;
    padding-bottom: 13.6rem !important; }
  .py-lg-135 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important; }
  .py-lg-134 {
    padding-top: 13.4rem !important;
    padding-bottom: 13.4rem !important; }
  .py-lg-133 {
    padding-top: 13.3rem !important;
    padding-bottom: 13.3rem !important; }
  .py-lg-132 {
    padding-top: 13.2rem !important;
    padding-bottom: 13.2rem !important; }
  .py-lg-131 {
    padding-top: 13.1rem !important;
    padding-bottom: 13.1rem !important; }
  .py-lg-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-lg-129 {
    padding-top: 12.9rem !important;
    padding-bottom: 12.9rem !important; }
  .py-lg-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-lg-127 {
    padding-top: 12.7rem !important;
    padding-bottom: 12.7rem !important; }
  .py-lg-126 {
    padding-top: 12.6rem !important;
    padding-bottom: 12.6rem !important; }
  .py-lg-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-lg-124 {
    padding-top: 12.4rem !important;
    padding-bottom: 12.4rem !important; }
  .py-lg-123 {
    padding-top: 12.3rem !important;
    padding-bottom: 12.3rem !important; }
  .py-lg-122 {
    padding-top: 12.2rem !important;
    padding-bottom: 12.2rem !important; }
  .py-lg-121 {
    padding-top: 12.1rem !important;
    padding-bottom: 12.1rem !important; }
  .py-lg-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-lg-119 {
    padding-top: 11.9rem !important;
    padding-bottom: 11.9rem !important; }
  .py-lg-118 {
    padding-top: 11.8rem !important;
    padding-bottom: 11.8rem !important; }
  .py-lg-117 {
    padding-top: 11.7rem !important;
    padding-bottom: 11.7rem !important; }
  .py-lg-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-lg-115 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .py-lg-114 {
    padding-top: 11.4rem !important;
    padding-bottom: 11.4rem !important; }
  .py-lg-113 {
    padding-top: 11.3rem !important;
    padding-bottom: 11.3rem !important; }
  .py-lg-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .py-lg-111 {
    padding-top: 11.1rem !important;
    padding-bottom: 11.1rem !important; }
  .py-lg-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-lg-109 {
    padding-top: 10.9rem !important;
    padding-bottom: 10.9rem !important; }
  .py-lg-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-lg-107 {
    padding-top: 10.7rem !important;
    padding-bottom: 10.7rem !important; }
  .py-lg-106 {
    padding-top: 10.6rem !important;
    padding-bottom: 10.6rem !important; }
  .py-lg-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-lg-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-lg-103 {
    padding-top: 10.3rem !important;
    padding-bottom: 10.3rem !important; }
  .py-lg-102 {
    padding-top: 10.2rem !important;
    padding-bottom: 10.2rem !important; }
  .py-lg-101 {
    padding-top: 10.1rem !important;
    padding-bottom: 10.1rem !important; }
  .py-lg-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-lg-99 {
    padding-top: 9.9rem !important;
    padding-bottom: 9.9rem !important; }
  .py-lg-98 {
    padding-top: 9.8rem !important;
    padding-bottom: 9.8rem !important; }
  .py-lg-97 {
    padding-top: 9.7rem !important;
    padding-bottom: 9.7rem !important; }
  .py-lg-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-lg-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-lg-94 {
    padding-top: 9.4rem !important;
    padding-bottom: 9.4rem !important; }
  .py-lg-93 {
    padding-top: 9.3rem !important;
    padding-bottom: 9.3rem !important; }
  .py-lg-92 {
    padding-top: 9.2rem !important;
    padding-bottom: 9.2rem !important; }
  .py-lg-91 {
    padding-top: 9.1rem !important;
    padding-bottom: 9.1rem !important; }
  .py-lg-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-lg-89 {
    padding-top: 8.9rem !important;
    padding-bottom: 8.9rem !important; }
  .py-lg-88 {
    padding-top: 8.8rem !important;
    padding-bottom: 8.8rem !important; }
  .py-lg-87 {
    padding-top: 8.7rem !important;
    padding-bottom: 8.7rem !important; }
  .py-lg-86 {
    padding-top: 8.6rem !important;
    padding-bottom: 8.6rem !important; }
  .py-lg-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-lg-84 {
    padding-top: 8.4rem !important;
    padding-bottom: 8.4rem !important; }
  .py-lg-83 {
    padding-top: 8.3rem !important;
    padding-bottom: 8.3rem !important; }
  .py-lg-82 {
    padding-top: 8.2rem !important;
    padding-bottom: 8.2rem !important; }
  .py-lg-81 {
    padding-top: 8.1rem !important;
    padding-bottom: 8.1rem !important; }
  .py-lg-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-lg-79 {
    padding-top: 7.9rem !important;
    padding-bottom: 7.9rem !important; }
  .py-lg-78 {
    padding-top: 7.8rem !important;
    padding-bottom: 7.8rem !important; }
  .py-lg-77 {
    padding-top: 7.7rem !important;
    padding-bottom: 7.7rem !important; }
  .py-lg-76 {
    padding-top: 7.6rem !important;
    padding-bottom: 7.6rem !important; }
  .py-lg-75 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-lg-74 {
    padding-top: 7.4rem !important;
    padding-bottom: 7.4rem !important; }
  .py-lg-73 {
    padding-top: 7.3rem !important;
    padding-bottom: 7.3rem !important; }
  .py-lg-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-lg-71 {
    padding-top: 7.1rem !important;
    padding-bottom: 7.1rem !important; }
  .py-lg-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-lg-69 {
    padding-top: 6.9rem !important;
    padding-bottom: 6.9rem !important; }
  .py-lg-68 {
    padding-top: 6.8rem !important;
    padding-bottom: 6.8rem !important; }
  .py-lg-67 {
    padding-top: 6.7rem !important;
    padding-bottom: 6.7rem !important; }
  .py-lg-66 {
    padding-top: 6.6rem !important;
    padding-bottom: 6.6rem !important; }
  .py-lg-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-lg-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-lg-63 {
    padding-top: 6.3rem !important;
    padding-bottom: 6.3rem !important; }
  .py-lg-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-lg-61 {
    padding-top: 6.1rem !important;
    padding-bottom: 6.1rem !important; }
  .py-lg-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-lg-59 {
    padding-top: 5.9rem !important;
    padding-bottom: 5.9rem !important; }
  .py-lg-58 {
    padding-top: 5.8rem !important;
    padding-bottom: 5.8rem !important; }
  .py-lg-57 {
    padding-top: 5.7rem !important;
    padding-bottom: 5.7rem !important; }
  .py-lg-56 {
    padding-top: 5.6rem !important;
    padding-bottom: 5.6rem !important; }
  .py-lg-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-lg-54 {
    padding-top: 5.4rem !important;
    padding-bottom: 5.4rem !important; }
  .py-lg-53 {
    padding-top: 5.3rem !important;
    padding-bottom: 5.3rem !important; }
  .py-lg-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-lg-51 {
    padding-top: 5.1rem !important;
    padding-bottom: 5.1rem !important; }
  .py-lg-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-lg-49 {
    padding-top: 4.9rem !important;
    padding-bottom: 4.9rem !important; }
  .py-lg-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-lg-47 {
    padding-top: 4.7rem !important;
    padding-bottom: 4.7rem !important; }
  .py-lg-46 {
    padding-top: 4.6rem !important;
    padding-bottom: 4.6rem !important; }
  .py-lg-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-lg-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-lg-43 {
    padding-top: 4.3rem !important;
    padding-bottom: 4.3rem !important; }
  .py-lg-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-lg-41 {
    padding-top: 4.1rem !important;
    padding-bottom: 4.1rem !important; }
  .py-lg-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-lg-39 {
    padding-top: 3.9rem !important;
    padding-bottom: 3.9rem !important; }
  .py-lg-38 {
    padding-top: 3.8rem !important;
    padding-bottom: 3.8rem !important; }
  .py-lg-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-lg-36 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important; }
  .py-lg-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-lg-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-lg-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-lg-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-lg-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-lg-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-lg-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-lg-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-lg-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-lg-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-lg-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-lg-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-lg-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-lg-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-lg-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-lg-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-lg-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-lg-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-lg-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-lg-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-lg-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-lg-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-lg-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-lg-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-n80 {
    padding-top: -80px !important;
    padding-bottom: -80px !important; }
  .py-lg-n100 {
    padding-top: -100px !important;
    padding-bottom: -100px !important; }
  .py-lg-n150 {
    padding-top: -150px !important;
    padding-bottom: -150px !important; }
  .pt-lg-150 {
    padding-top: 15rem !important; }
  .pt-lg-149 {
    padding-top: 14.9rem !important; }
  .pt-lg-148 {
    padding-top: 14.8rem !important; }
  .pt-lg-147 {
    padding-top: 14.7rem !important; }
  .pt-lg-146 {
    padding-top: 14.6rem !important; }
  .pt-lg-145 {
    padding-top: 14.5rem !important; }
  .pt-lg-144 {
    padding-top: 14.4rem !important; }
  .pt-lg-143 {
    padding-top: 14.3rem !important; }
  .pt-lg-142 {
    padding-top: 14.2rem !important; }
  .pt-lg-141 {
    padding-top: 14.1rem !important; }
  .pt-lg-140 {
    padding-top: 14rem !important; }
  .pt-lg-139 {
    padding-top: 13.9rem !important; }
  .pt-lg-138 {
    padding-top: 13.8rem !important; }
  .pt-lg-137 {
    padding-top: 13.7rem !important; }
  .pt-lg-136 {
    padding-top: 13.6rem !important; }
  .pt-lg-135 {
    padding-top: 13.5rem !important; }
  .pt-lg-134 {
    padding-top: 13.4rem !important; }
  .pt-lg-133 {
    padding-top: 13.3rem !important; }
  .pt-lg-132 {
    padding-top: 13.2rem !important; }
  .pt-lg-131 {
    padding-top: 13.1rem !important; }
  .pt-lg-130 {
    padding-top: 13rem !important; }
  .pt-lg-129 {
    padding-top: 12.9rem !important; }
  .pt-lg-128 {
    padding-top: 12.8rem !important; }
  .pt-lg-127 {
    padding-top: 12.7rem !important; }
  .pt-lg-126 {
    padding-top: 12.6rem !important; }
  .pt-lg-125 {
    padding-top: 12.5rem !important; }
  .pt-lg-124 {
    padding-top: 12.4rem !important; }
  .pt-lg-123 {
    padding-top: 12.3rem !important; }
  .pt-lg-122 {
    padding-top: 12.2rem !important; }
  .pt-lg-121 {
    padding-top: 12.1rem !important; }
  .pt-lg-120 {
    padding-top: 12rem !important; }
  .pt-lg-119 {
    padding-top: 11.9rem !important; }
  .pt-lg-118 {
    padding-top: 11.8rem !important; }
  .pt-lg-117 {
    padding-top: 11.7rem !important; }
  .pt-lg-116 {
    padding-top: 11.6rem !important; }
  .pt-lg-115 {
    padding-top: 11.5rem !important; }
  .pt-lg-114 {
    padding-top: 11.4rem !important; }
  .pt-lg-113 {
    padding-top: 11.3rem !important; }
  .pt-lg-112 {
    padding-top: 11.2rem !important; }
  .pt-lg-111 {
    padding-top: 11.1rem !important; }
  .pt-lg-110 {
    padding-top: 11rem !important; }
  .pt-lg-109 {
    padding-top: 10.9rem !important; }
  .pt-lg-108 {
    padding-top: 10.8rem !important; }
  .pt-lg-107 {
    padding-top: 10.7rem !important; }
  .pt-lg-106 {
    padding-top: 10.6rem !important; }
  .pt-lg-105 {
    padding-top: 10.5rem !important; }
  .pt-lg-104 {
    padding-top: 10.4rem !important; }
  .pt-lg-103 {
    padding-top: 10.3rem !important; }
  .pt-lg-102 {
    padding-top: 10.2rem !important; }
  .pt-lg-101 {
    padding-top: 10.1rem !important; }
  .pt-lg-100 {
    padding-top: 10rem !important; }
  .pt-lg-99 {
    padding-top: 9.9rem !important; }
  .pt-lg-98 {
    padding-top: 9.8rem !important; }
  .pt-lg-97 {
    padding-top: 9.7rem !important; }
  .pt-lg-96 {
    padding-top: 9.6rem !important; }
  .pt-lg-95 {
    padding-top: 9.5rem !important; }
  .pt-lg-94 {
    padding-top: 9.4rem !important; }
  .pt-lg-93 {
    padding-top: 9.3rem !important; }
  .pt-lg-92 {
    padding-top: 9.2rem !important; }
  .pt-lg-91 {
    padding-top: 9.1rem !important; }
  .pt-lg-90 {
    padding-top: 9rem !important; }
  .pt-lg-89 {
    padding-top: 8.9rem !important; }
  .pt-lg-88 {
    padding-top: 8.8rem !important; }
  .pt-lg-87 {
    padding-top: 8.7rem !important; }
  .pt-lg-86 {
    padding-top: 8.6rem !important; }
  .pt-lg-85 {
    padding-top: 8.5rem !important; }
  .pt-lg-84 {
    padding-top: 8.4rem !important; }
  .pt-lg-83 {
    padding-top: 8.3rem !important; }
  .pt-lg-82 {
    padding-top: 8.2rem !important; }
  .pt-lg-81 {
    padding-top: 8.1rem !important; }
  .pt-lg-80 {
    padding-top: 8rem !important; }
  .pt-lg-79 {
    padding-top: 7.9rem !important; }
  .pt-lg-78 {
    padding-top: 7.8rem !important; }
  .pt-lg-77 {
    padding-top: 7.7rem !important; }
  .pt-lg-76 {
    padding-top: 7.6rem !important; }
  .pt-lg-75 {
    padding-top: 7.5rem !important; }
  .pt-lg-74 {
    padding-top: 7.4rem !important; }
  .pt-lg-73 {
    padding-top: 7.3rem !important; }
  .pt-lg-72 {
    padding-top: 7.2rem !important; }
  .pt-lg-71 {
    padding-top: 7.1rem !important; }
  .pt-lg-70 {
    padding-top: 7rem !important; }
  .pt-lg-69 {
    padding-top: 6.9rem !important; }
  .pt-lg-68 {
    padding-top: 6.8rem !important; }
  .pt-lg-67 {
    padding-top: 6.7rem !important; }
  .pt-lg-66 {
    padding-top: 6.6rem !important; }
  .pt-lg-65 {
    padding-top: 6.5rem !important; }
  .pt-lg-64 {
    padding-top: 6.4rem !important; }
  .pt-lg-63 {
    padding-top: 6.3rem !important; }
  .pt-lg-62 {
    padding-top: 6.2rem !important; }
  .pt-lg-61 {
    padding-top: 6.1rem !important; }
  .pt-lg-60 {
    padding-top: 6rem !important; }
  .pt-lg-59 {
    padding-top: 5.9rem !important; }
  .pt-lg-58 {
    padding-top: 5.8rem !important; }
  .pt-lg-57 {
    padding-top: 5.7rem !important; }
  .pt-lg-56 {
    padding-top: 5.6rem !important; }
  .pt-lg-55 {
    padding-top: 5.5rem !important; }
  .pt-lg-54 {
    padding-top: 5.4rem !important; }
  .pt-lg-53 {
    padding-top: 5.3rem !important; }
  .pt-lg-52 {
    padding-top: 5.2rem !important; }
  .pt-lg-51 {
    padding-top: 5.1rem !important; }
  .pt-lg-50 {
    padding-top: 5rem !important; }
  .pt-lg-49 {
    padding-top: 4.9rem !important; }
  .pt-lg-48 {
    padding-top: 4.8rem !important; }
  .pt-lg-47 {
    padding-top: 4.7rem !important; }
  .pt-lg-46 {
    padding-top: 4.6rem !important; }
  .pt-lg-45 {
    padding-top: 4.5rem !important; }
  .pt-lg-44 {
    padding-top: 4.4rem !important; }
  .pt-lg-43 {
    padding-top: 4.3rem !important; }
  .pt-lg-42 {
    padding-top: 4.2rem !important; }
  .pt-lg-41 {
    padding-top: 4.1rem !important; }
  .pt-lg-40 {
    padding-top: 4rem !important; }
  .pt-lg-39 {
    padding-top: 3.9rem !important; }
  .pt-lg-38 {
    padding-top: 3.8rem !important; }
  .pt-lg-37 {
    padding-top: 3.7rem !important; }
  .pt-lg-36 {
    padding-top: 3.6rem !important; }
  .pt-lg-35 {
    padding-top: 3.5rem !important; }
  .pt-lg-34 {
    padding-top: 3.4rem !important; }
  .pt-lg-33 {
    padding-top: 3.3rem !important; }
  .pt-lg-32 {
    padding-top: 3.2rem !important; }
  .pt-lg-31 {
    padding-top: 3.1rem !important; }
  .pt-lg-30 {
    padding-top: 3rem !important; }
  .pt-lg-29 {
    padding-top: 2.9rem !important; }
  .pt-lg-28 {
    padding-top: 2.8rem !important; }
  .pt-lg-27 {
    padding-top: 2.7rem !important; }
  .pt-lg-26 {
    padding-top: 2.6rem !important; }
  .pt-lg-25 {
    padding-top: 2.5rem !important; }
  .pt-lg-24 {
    padding-top: 2.4rem !important; }
  .pt-lg-23 {
    padding-top: 2.3rem !important; }
  .pt-lg-22 {
    padding-top: 2.2rem !important; }
  .pt-lg-21 {
    padding-top: 2.1rem !important; }
  .pt-lg-20 {
    padding-top: 2rem !important; }
  .pt-lg-19 {
    padding-top: 1.9rem !important; }
  .pt-lg-18 {
    padding-top: 1.8rem !important; }
  .pt-lg-17 {
    padding-top: 1.7rem !important; }
  .pt-lg-16 {
    padding-top: 1.6rem !important; }
  .pt-lg-15 {
    padding-top: 1.5rem !important; }
  .pt-lg-14 {
    padding-top: 1.4rem !important; }
  .pt-lg-13 {
    padding-top: 1.3rem !important; }
  .pt-lg-12 {
    padding-top: 1.2rem !important; }
  .pt-lg-11 {
    padding-top: 1.1rem !important; }
  .pt-lg-10 {
    padding-top: 1rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pt-lg-n80 {
    padding-top: -80px !important; }
  .pt-lg-n100 {
    padding-top: -100px !important; }
  .pt-lg-n150 {
    padding-top: -150px !important; }
  .pe-lg-150 {
    padding-right: 15rem !important; }
  .pe-lg-149 {
    padding-right: 14.9rem !important; }
  .pe-lg-148 {
    padding-right: 14.8rem !important; }
  .pe-lg-147 {
    padding-right: 14.7rem !important; }
  .pe-lg-146 {
    padding-right: 14.6rem !important; }
  .pe-lg-145 {
    padding-right: 14.5rem !important; }
  .pe-lg-144 {
    padding-right: 14.4rem !important; }
  .pe-lg-143 {
    padding-right: 14.3rem !important; }
  .pe-lg-142 {
    padding-right: 14.2rem !important; }
  .pe-lg-141 {
    padding-right: 14.1rem !important; }
  .pe-lg-140 {
    padding-right: 14rem !important; }
  .pe-lg-139 {
    padding-right: 13.9rem !important; }
  .pe-lg-138 {
    padding-right: 13.8rem !important; }
  .pe-lg-137 {
    padding-right: 13.7rem !important; }
  .pe-lg-136 {
    padding-right: 13.6rem !important; }
  .pe-lg-135 {
    padding-right: 13.5rem !important; }
  .pe-lg-134 {
    padding-right: 13.4rem !important; }
  .pe-lg-133 {
    padding-right: 13.3rem !important; }
  .pe-lg-132 {
    padding-right: 13.2rem !important; }
  .pe-lg-131 {
    padding-right: 13.1rem !important; }
  .pe-lg-130 {
    padding-right: 13rem !important; }
  .pe-lg-129 {
    padding-right: 12.9rem !important; }
  .pe-lg-128 {
    padding-right: 12.8rem !important; }
  .pe-lg-127 {
    padding-right: 12.7rem !important; }
  .pe-lg-126 {
    padding-right: 12.6rem !important; }
  .pe-lg-125 {
    padding-right: 12.5rem !important; }
  .pe-lg-124 {
    padding-right: 12.4rem !important; }
  .pe-lg-123 {
    padding-right: 12.3rem !important; }
  .pe-lg-122 {
    padding-right: 12.2rem !important; }
  .pe-lg-121 {
    padding-right: 12.1rem !important; }
  .pe-lg-120 {
    padding-right: 12rem !important; }
  .pe-lg-119 {
    padding-right: 11.9rem !important; }
  .pe-lg-118 {
    padding-right: 11.8rem !important; }
  .pe-lg-117 {
    padding-right: 11.7rem !important; }
  .pe-lg-116 {
    padding-right: 11.6rem !important; }
  .pe-lg-115 {
    padding-right: 11.5rem !important; }
  .pe-lg-114 {
    padding-right: 11.4rem !important; }
  .pe-lg-113 {
    padding-right: 11.3rem !important; }
  .pe-lg-112 {
    padding-right: 11.2rem !important; }
  .pe-lg-111 {
    padding-right: 11.1rem !important; }
  .pe-lg-110 {
    padding-right: 11rem !important; }
  .pe-lg-109 {
    padding-right: 10.9rem !important; }
  .pe-lg-108 {
    padding-right: 10.8rem !important; }
  .pe-lg-107 {
    padding-right: 10.7rem !important; }
  .pe-lg-106 {
    padding-right: 10.6rem !important; }
  .pe-lg-105 {
    padding-right: 10.5rem !important; }
  .pe-lg-104 {
    padding-right: 10.4rem !important; }
  .pe-lg-103 {
    padding-right: 10.3rem !important; }
  .pe-lg-102 {
    padding-right: 10.2rem !important; }
  .pe-lg-101 {
    padding-right: 10.1rem !important; }
  .pe-lg-100 {
    padding-right: 10rem !important; }
  .pe-lg-99 {
    padding-right: 9.9rem !important; }
  .pe-lg-98 {
    padding-right: 9.8rem !important; }
  .pe-lg-97 {
    padding-right: 9.7rem !important; }
  .pe-lg-96 {
    padding-right: 9.6rem !important; }
  .pe-lg-95 {
    padding-right: 9.5rem !important; }
  .pe-lg-94 {
    padding-right: 9.4rem !important; }
  .pe-lg-93 {
    padding-right: 9.3rem !important; }
  .pe-lg-92 {
    padding-right: 9.2rem !important; }
  .pe-lg-91 {
    padding-right: 9.1rem !important; }
  .pe-lg-90 {
    padding-right: 9rem !important; }
  .pe-lg-89 {
    padding-right: 8.9rem !important; }
  .pe-lg-88 {
    padding-right: 8.8rem !important; }
  .pe-lg-87 {
    padding-right: 8.7rem !important; }
  .pe-lg-86 {
    padding-right: 8.6rem !important; }
  .pe-lg-85 {
    padding-right: 8.5rem !important; }
  .pe-lg-84 {
    padding-right: 8.4rem !important; }
  .pe-lg-83 {
    padding-right: 8.3rem !important; }
  .pe-lg-82 {
    padding-right: 8.2rem !important; }
  .pe-lg-81 {
    padding-right: 8.1rem !important; }
  .pe-lg-80 {
    padding-right: 8rem !important; }
  .pe-lg-79 {
    padding-right: 7.9rem !important; }
  .pe-lg-78 {
    padding-right: 7.8rem !important; }
  .pe-lg-77 {
    padding-right: 7.7rem !important; }
  .pe-lg-76 {
    padding-right: 7.6rem !important; }
  .pe-lg-75 {
    padding-right: 7.5rem !important; }
  .pe-lg-74 {
    padding-right: 7.4rem !important; }
  .pe-lg-73 {
    padding-right: 7.3rem !important; }
  .pe-lg-72 {
    padding-right: 7.2rem !important; }
  .pe-lg-71 {
    padding-right: 7.1rem !important; }
  .pe-lg-70 {
    padding-right: 7rem !important; }
  .pe-lg-69 {
    padding-right: 6.9rem !important; }
  .pe-lg-68 {
    padding-right: 6.8rem !important; }
  .pe-lg-67 {
    padding-right: 6.7rem !important; }
  .pe-lg-66 {
    padding-right: 6.6rem !important; }
  .pe-lg-65 {
    padding-right: 6.5rem !important; }
  .pe-lg-64 {
    padding-right: 6.4rem !important; }
  .pe-lg-63 {
    padding-right: 6.3rem !important; }
  .pe-lg-62 {
    padding-right: 6.2rem !important; }
  .pe-lg-61 {
    padding-right: 6.1rem !important; }
  .pe-lg-60 {
    padding-right: 6rem !important; }
  .pe-lg-59 {
    padding-right: 5.9rem !important; }
  .pe-lg-58 {
    padding-right: 5.8rem !important; }
  .pe-lg-57 {
    padding-right: 5.7rem !important; }
  .pe-lg-56 {
    padding-right: 5.6rem !important; }
  .pe-lg-55 {
    padding-right: 5.5rem !important; }
  .pe-lg-54 {
    padding-right: 5.4rem !important; }
  .pe-lg-53 {
    padding-right: 5.3rem !important; }
  .pe-lg-52 {
    padding-right: 5.2rem !important; }
  .pe-lg-51 {
    padding-right: 5.1rem !important; }
  .pe-lg-50 {
    padding-right: 5rem !important; }
  .pe-lg-49 {
    padding-right: 4.9rem !important; }
  .pe-lg-48 {
    padding-right: 4.8rem !important; }
  .pe-lg-47 {
    padding-right: 4.7rem !important; }
  .pe-lg-46 {
    padding-right: 4.6rem !important; }
  .pe-lg-45 {
    padding-right: 4.5rem !important; }
  .pe-lg-44 {
    padding-right: 4.4rem !important; }
  .pe-lg-43 {
    padding-right: 4.3rem !important; }
  .pe-lg-42 {
    padding-right: 4.2rem !important; }
  .pe-lg-41 {
    padding-right: 4.1rem !important; }
  .pe-lg-40 {
    padding-right: 4rem !important; }
  .pe-lg-39 {
    padding-right: 3.9rem !important; }
  .pe-lg-38 {
    padding-right: 3.8rem !important; }
  .pe-lg-37 {
    padding-right: 3.7rem !important; }
  .pe-lg-36 {
    padding-right: 3.6rem !important; }
  .pe-lg-35 {
    padding-right: 3.5rem !important; }
  .pe-lg-34 {
    padding-right: 3.4rem !important; }
  .pe-lg-33 {
    padding-right: 3.3rem !important; }
  .pe-lg-32 {
    padding-right: 3.2rem !important; }
  .pe-lg-31 {
    padding-right: 3.1rem !important; }
  .pe-lg-30 {
    padding-right: 3rem !important; }
  .pe-lg-29 {
    padding-right: 2.9rem !important; }
  .pe-lg-28 {
    padding-right: 2.8rem !important; }
  .pe-lg-27 {
    padding-right: 2.7rem !important; }
  .pe-lg-26 {
    padding-right: 2.6rem !important; }
  .pe-lg-25 {
    padding-right: 2.5rem !important; }
  .pe-lg-24 {
    padding-right: 2.4rem !important; }
  .pe-lg-23 {
    padding-right: 2.3rem !important; }
  .pe-lg-22 {
    padding-right: 2.2rem !important; }
  .pe-lg-21 {
    padding-right: 2.1rem !important; }
  .pe-lg-20 {
    padding-right: 2rem !important; }
  .pe-lg-19 {
    padding-right: 1.9rem !important; }
  .pe-lg-18 {
    padding-right: 1.8rem !important; }
  .pe-lg-17 {
    padding-right: 1.7rem !important; }
  .pe-lg-16 {
    padding-right: 1.6rem !important; }
  .pe-lg-15 {
    padding-right: 1.5rem !important; }
  .pe-lg-14 {
    padding-right: 1.4rem !important; }
  .pe-lg-13 {
    padding-right: 1.3rem !important; }
  .pe-lg-12 {
    padding-right: 1.2rem !important; }
  .pe-lg-11 {
    padding-right: 1.1rem !important; }
  .pe-lg-10 {
    padding-right: 1rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pe-lg-n80 {
    padding-right: -80px !important; }
  .pe-lg-n100 {
    padding-right: -100px !important; }
  .pe-lg-n150 {
    padding-right: -150px !important; }
  .pb-lg-150 {
    padding-bottom: 15rem !important; }
  .pb-lg-149 {
    padding-bottom: 14.9rem !important; }
  .pb-lg-148 {
    padding-bottom: 14.8rem !important; }
  .pb-lg-147 {
    padding-bottom: 14.7rem !important; }
  .pb-lg-146 {
    padding-bottom: 14.6rem !important; }
  .pb-lg-145 {
    padding-bottom: 14.5rem !important; }
  .pb-lg-144 {
    padding-bottom: 14.4rem !important; }
  .pb-lg-143 {
    padding-bottom: 14.3rem !important; }
  .pb-lg-142 {
    padding-bottom: 14.2rem !important; }
  .pb-lg-141 {
    padding-bottom: 14.1rem !important; }
  .pb-lg-140 {
    padding-bottom: 14rem !important; }
  .pb-lg-139 {
    padding-bottom: 13.9rem !important; }
  .pb-lg-138 {
    padding-bottom: 13.8rem !important; }
  .pb-lg-137 {
    padding-bottom: 13.7rem !important; }
  .pb-lg-136 {
    padding-bottom: 13.6rem !important; }
  .pb-lg-135 {
    padding-bottom: 13.5rem !important; }
  .pb-lg-134 {
    padding-bottom: 13.4rem !important; }
  .pb-lg-133 {
    padding-bottom: 13.3rem !important; }
  .pb-lg-132 {
    padding-bottom: 13.2rem !important; }
  .pb-lg-131 {
    padding-bottom: 13.1rem !important; }
  .pb-lg-130 {
    padding-bottom: 13rem !important; }
  .pb-lg-129 {
    padding-bottom: 12.9rem !important; }
  .pb-lg-128 {
    padding-bottom: 12.8rem !important; }
  .pb-lg-127 {
    padding-bottom: 12.7rem !important; }
  .pb-lg-126 {
    padding-bottom: 12.6rem !important; }
  .pb-lg-125 {
    padding-bottom: 12.5rem !important; }
  .pb-lg-124 {
    padding-bottom: 12.4rem !important; }
  .pb-lg-123 {
    padding-bottom: 12.3rem !important; }
  .pb-lg-122 {
    padding-bottom: 12.2rem !important; }
  .pb-lg-121 {
    padding-bottom: 12.1rem !important; }
  .pb-lg-120 {
    padding-bottom: 12rem !important; }
  .pb-lg-119 {
    padding-bottom: 11.9rem !important; }
  .pb-lg-118 {
    padding-bottom: 11.8rem !important; }
  .pb-lg-117 {
    padding-bottom: 11.7rem !important; }
  .pb-lg-116 {
    padding-bottom: 11.6rem !important; }
  .pb-lg-115 {
    padding-bottom: 11.5rem !important; }
  .pb-lg-114 {
    padding-bottom: 11.4rem !important; }
  .pb-lg-113 {
    padding-bottom: 11.3rem !important; }
  .pb-lg-112 {
    padding-bottom: 11.2rem !important; }
  .pb-lg-111 {
    padding-bottom: 11.1rem !important; }
  .pb-lg-110 {
    padding-bottom: 11rem !important; }
  .pb-lg-109 {
    padding-bottom: 10.9rem !important; }
  .pb-lg-108 {
    padding-bottom: 10.8rem !important; }
  .pb-lg-107 {
    padding-bottom: 10.7rem !important; }
  .pb-lg-106 {
    padding-bottom: 10.6rem !important; }
  .pb-lg-105 {
    padding-bottom: 10.5rem !important; }
  .pb-lg-104 {
    padding-bottom: 10.4rem !important; }
  .pb-lg-103 {
    padding-bottom: 10.3rem !important; }
  .pb-lg-102 {
    padding-bottom: 10.2rem !important; }
  .pb-lg-101 {
    padding-bottom: 10.1rem !important; }
  .pb-lg-100 {
    padding-bottom: 10rem !important; }
  .pb-lg-99 {
    padding-bottom: 9.9rem !important; }
  .pb-lg-98 {
    padding-bottom: 9.8rem !important; }
  .pb-lg-97 {
    padding-bottom: 9.7rem !important; }
  .pb-lg-96 {
    padding-bottom: 9.6rem !important; }
  .pb-lg-95 {
    padding-bottom: 9.5rem !important; }
  .pb-lg-94 {
    padding-bottom: 9.4rem !important; }
  .pb-lg-93 {
    padding-bottom: 9.3rem !important; }
  .pb-lg-92 {
    padding-bottom: 9.2rem !important; }
  .pb-lg-91 {
    padding-bottom: 9.1rem !important; }
  .pb-lg-90 {
    padding-bottom: 9rem !important; }
  .pb-lg-89 {
    padding-bottom: 8.9rem !important; }
  .pb-lg-88 {
    padding-bottom: 8.8rem !important; }
  .pb-lg-87 {
    padding-bottom: 8.7rem !important; }
  .pb-lg-86 {
    padding-bottom: 8.6rem !important; }
  .pb-lg-85 {
    padding-bottom: 8.5rem !important; }
  .pb-lg-84 {
    padding-bottom: 8.4rem !important; }
  .pb-lg-83 {
    padding-bottom: 8.3rem !important; }
  .pb-lg-82 {
    padding-bottom: 8.2rem !important; }
  .pb-lg-81 {
    padding-bottom: 8.1rem !important; }
  .pb-lg-80 {
    padding-bottom: 8rem !important; }
  .pb-lg-79 {
    padding-bottom: 7.9rem !important; }
  .pb-lg-78 {
    padding-bottom: 7.8rem !important; }
  .pb-lg-77 {
    padding-bottom: 7.7rem !important; }
  .pb-lg-76 {
    padding-bottom: 7.6rem !important; }
  .pb-lg-75 {
    padding-bottom: 7.5rem !important; }
  .pb-lg-74 {
    padding-bottom: 7.4rem !important; }
  .pb-lg-73 {
    padding-bottom: 7.3rem !important; }
  .pb-lg-72 {
    padding-bottom: 7.2rem !important; }
  .pb-lg-71 {
    padding-bottom: 7.1rem !important; }
  .pb-lg-70 {
    padding-bottom: 7rem !important; }
  .pb-lg-69 {
    padding-bottom: 6.9rem !important; }
  .pb-lg-68 {
    padding-bottom: 6.8rem !important; }
  .pb-lg-67 {
    padding-bottom: 6.7rem !important; }
  .pb-lg-66 {
    padding-bottom: 6.6rem !important; }
  .pb-lg-65 {
    padding-bottom: 6.5rem !important; }
  .pb-lg-64 {
    padding-bottom: 6.4rem !important; }
  .pb-lg-63 {
    padding-bottom: 6.3rem !important; }
  .pb-lg-62 {
    padding-bottom: 6.2rem !important; }
  .pb-lg-61 {
    padding-bottom: 6.1rem !important; }
  .pb-lg-60 {
    padding-bottom: 6rem !important; }
  .pb-lg-59 {
    padding-bottom: 5.9rem !important; }
  .pb-lg-58 {
    padding-bottom: 5.8rem !important; }
  .pb-lg-57 {
    padding-bottom: 5.7rem !important; }
  .pb-lg-56 {
    padding-bottom: 5.6rem !important; }
  .pb-lg-55 {
    padding-bottom: 5.5rem !important; }
  .pb-lg-54 {
    padding-bottom: 5.4rem !important; }
  .pb-lg-53 {
    padding-bottom: 5.3rem !important; }
  .pb-lg-52 {
    padding-bottom: 5.2rem !important; }
  .pb-lg-51 {
    padding-bottom: 5.1rem !important; }
  .pb-lg-50 {
    padding-bottom: 5rem !important; }
  .pb-lg-49 {
    padding-bottom: 4.9rem !important; }
  .pb-lg-48 {
    padding-bottom: 4.8rem !important; }
  .pb-lg-47 {
    padding-bottom: 4.7rem !important; }
  .pb-lg-46 {
    padding-bottom: 4.6rem !important; }
  .pb-lg-45 {
    padding-bottom: 4.5rem !important; }
  .pb-lg-44 {
    padding-bottom: 4.4rem !important; }
  .pb-lg-43 {
    padding-bottom: 4.3rem !important; }
  .pb-lg-42 {
    padding-bottom: 4.2rem !important; }
  .pb-lg-41 {
    padding-bottom: 4.1rem !important; }
  .pb-lg-40 {
    padding-bottom: 4rem !important; }
  .pb-lg-39 {
    padding-bottom: 3.9rem !important; }
  .pb-lg-38 {
    padding-bottom: 3.8rem !important; }
  .pb-lg-37 {
    padding-bottom: 3.7rem !important; }
  .pb-lg-36 {
    padding-bottom: 3.6rem !important; }
  .pb-lg-35 {
    padding-bottom: 3.5rem !important; }
  .pb-lg-34 {
    padding-bottom: 3.4rem !important; }
  .pb-lg-33 {
    padding-bottom: 3.3rem !important; }
  .pb-lg-32 {
    padding-bottom: 3.2rem !important; }
  .pb-lg-31 {
    padding-bottom: 3.1rem !important; }
  .pb-lg-30 {
    padding-bottom: 3rem !important; }
  .pb-lg-29 {
    padding-bottom: 2.9rem !important; }
  .pb-lg-28 {
    padding-bottom: 2.8rem !important; }
  .pb-lg-27 {
    padding-bottom: 2.7rem !important; }
  .pb-lg-26 {
    padding-bottom: 2.6rem !important; }
  .pb-lg-25 {
    padding-bottom: 2.5rem !important; }
  .pb-lg-24 {
    padding-bottom: 2.4rem !important; }
  .pb-lg-23 {
    padding-bottom: 2.3rem !important; }
  .pb-lg-22 {
    padding-bottom: 2.2rem !important; }
  .pb-lg-21 {
    padding-bottom: 2.1rem !important; }
  .pb-lg-20 {
    padding-bottom: 2rem !important; }
  .pb-lg-19 {
    padding-bottom: 1.9rem !important; }
  .pb-lg-18 {
    padding-bottom: 1.8rem !important; }
  .pb-lg-17 {
    padding-bottom: 1.7rem !important; }
  .pb-lg-16 {
    padding-bottom: 1.6rem !important; }
  .pb-lg-15 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-14 {
    padding-bottom: 1.4rem !important; }
  .pb-lg-13 {
    padding-bottom: 1.3rem !important; }
  .pb-lg-12 {
    padding-bottom: 1.2rem !important; }
  .pb-lg-11 {
    padding-bottom: 1.1rem !important; }
  .pb-lg-10 {
    padding-bottom: 1rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pb-lg-n80 {
    padding-bottom: -80px !important; }
  .pb-lg-n100 {
    padding-bottom: -100px !important; }
  .pb-lg-n150 {
    padding-bottom: -150px !important; }
  .ps-lg-150 {
    padding-left: 15rem !important; }
  .ps-lg-149 {
    padding-left: 14.9rem !important; }
  .ps-lg-148 {
    padding-left: 14.8rem !important; }
  .ps-lg-147 {
    padding-left: 14.7rem !important; }
  .ps-lg-146 {
    padding-left: 14.6rem !important; }
  .ps-lg-145 {
    padding-left: 14.5rem !important; }
  .ps-lg-144 {
    padding-left: 14.4rem !important; }
  .ps-lg-143 {
    padding-left: 14.3rem !important; }
  .ps-lg-142 {
    padding-left: 14.2rem !important; }
  .ps-lg-141 {
    padding-left: 14.1rem !important; }
  .ps-lg-140 {
    padding-left: 14rem !important; }
  .ps-lg-139 {
    padding-left: 13.9rem !important; }
  .ps-lg-138 {
    padding-left: 13.8rem !important; }
  .ps-lg-137 {
    padding-left: 13.7rem !important; }
  .ps-lg-136 {
    padding-left: 13.6rem !important; }
  .ps-lg-135 {
    padding-left: 13.5rem !important; }
  .ps-lg-134 {
    padding-left: 13.4rem !important; }
  .ps-lg-133 {
    padding-left: 13.3rem !important; }
  .ps-lg-132 {
    padding-left: 13.2rem !important; }
  .ps-lg-131 {
    padding-left: 13.1rem !important; }
  .ps-lg-130 {
    padding-left: 13rem !important; }
  .ps-lg-129 {
    padding-left: 12.9rem !important; }
  .ps-lg-128 {
    padding-left: 12.8rem !important; }
  .ps-lg-127 {
    padding-left: 12.7rem !important; }
  .ps-lg-126 {
    padding-left: 12.6rem !important; }
  .ps-lg-125 {
    padding-left: 12.5rem !important; }
  .ps-lg-124 {
    padding-left: 12.4rem !important; }
  .ps-lg-123 {
    padding-left: 12.3rem !important; }
  .ps-lg-122 {
    padding-left: 12.2rem !important; }
  .ps-lg-121 {
    padding-left: 12.1rem !important; }
  .ps-lg-120 {
    padding-left: 12rem !important; }
  .ps-lg-119 {
    padding-left: 11.9rem !important; }
  .ps-lg-118 {
    padding-left: 11.8rem !important; }
  .ps-lg-117 {
    padding-left: 11.7rem !important; }
  .ps-lg-116 {
    padding-left: 11.6rem !important; }
  .ps-lg-115 {
    padding-left: 11.5rem !important; }
  .ps-lg-114 {
    padding-left: 11.4rem !important; }
  .ps-lg-113 {
    padding-left: 11.3rem !important; }
  .ps-lg-112 {
    padding-left: 11.2rem !important; }
  .ps-lg-111 {
    padding-left: 11.1rem !important; }
  .ps-lg-110 {
    padding-left: 11rem !important; }
  .ps-lg-109 {
    padding-left: 10.9rem !important; }
  .ps-lg-108 {
    padding-left: 10.8rem !important; }
  .ps-lg-107 {
    padding-left: 10.7rem !important; }
  .ps-lg-106 {
    padding-left: 10.6rem !important; }
  .ps-lg-105 {
    padding-left: 10.5rem !important; }
  .ps-lg-104 {
    padding-left: 10.4rem !important; }
  .ps-lg-103 {
    padding-left: 10.3rem !important; }
  .ps-lg-102 {
    padding-left: 10.2rem !important; }
  .ps-lg-101 {
    padding-left: 10.1rem !important; }
  .ps-lg-100 {
    padding-left: 10rem !important; }
  .ps-lg-99 {
    padding-left: 9.9rem !important; }
  .ps-lg-98 {
    padding-left: 9.8rem !important; }
  .ps-lg-97 {
    padding-left: 9.7rem !important; }
  .ps-lg-96 {
    padding-left: 9.6rem !important; }
  .ps-lg-95 {
    padding-left: 9.5rem !important; }
  .ps-lg-94 {
    padding-left: 9.4rem !important; }
  .ps-lg-93 {
    padding-left: 9.3rem !important; }
  .ps-lg-92 {
    padding-left: 9.2rem !important; }
  .ps-lg-91 {
    padding-left: 9.1rem !important; }
  .ps-lg-90 {
    padding-left: 9rem !important; }
  .ps-lg-89 {
    padding-left: 8.9rem !important; }
  .ps-lg-88 {
    padding-left: 8.8rem !important; }
  .ps-lg-87 {
    padding-left: 8.7rem !important; }
  .ps-lg-86 {
    padding-left: 8.6rem !important; }
  .ps-lg-85 {
    padding-left: 8.5rem !important; }
  .ps-lg-84 {
    padding-left: 8.4rem !important; }
  .ps-lg-83 {
    padding-left: 8.3rem !important; }
  .ps-lg-82 {
    padding-left: 8.2rem !important; }
  .ps-lg-81 {
    padding-left: 8.1rem !important; }
  .ps-lg-80 {
    padding-left: 8rem !important; }
  .ps-lg-79 {
    padding-left: 7.9rem !important; }
  .ps-lg-78 {
    padding-left: 7.8rem !important; }
  .ps-lg-77 {
    padding-left: 7.7rem !important; }
  .ps-lg-76 {
    padding-left: 7.6rem !important; }
  .ps-lg-75 {
    padding-left: 7.5rem !important; }
  .ps-lg-74 {
    padding-left: 7.4rem !important; }
  .ps-lg-73 {
    padding-left: 7.3rem !important; }
  .ps-lg-72 {
    padding-left: 7.2rem !important; }
  .ps-lg-71 {
    padding-left: 7.1rem !important; }
  .ps-lg-70 {
    padding-left: 7rem !important; }
  .ps-lg-69 {
    padding-left: 6.9rem !important; }
  .ps-lg-68 {
    padding-left: 6.8rem !important; }
  .ps-lg-67 {
    padding-left: 6.7rem !important; }
  .ps-lg-66 {
    padding-left: 6.6rem !important; }
  .ps-lg-65 {
    padding-left: 6.5rem !important; }
  .ps-lg-64 {
    padding-left: 6.4rem !important; }
  .ps-lg-63 {
    padding-left: 6.3rem !important; }
  .ps-lg-62 {
    padding-left: 6.2rem !important; }
  .ps-lg-61 {
    padding-left: 6.1rem !important; }
  .ps-lg-60 {
    padding-left: 6rem !important; }
  .ps-lg-59 {
    padding-left: 5.9rem !important; }
  .ps-lg-58 {
    padding-left: 5.8rem !important; }
  .ps-lg-57 {
    padding-left: 5.7rem !important; }
  .ps-lg-56 {
    padding-left: 5.6rem !important; }
  .ps-lg-55 {
    padding-left: 5.5rem !important; }
  .ps-lg-54 {
    padding-left: 5.4rem !important; }
  .ps-lg-53 {
    padding-left: 5.3rem !important; }
  .ps-lg-52 {
    padding-left: 5.2rem !important; }
  .ps-lg-51 {
    padding-left: 5.1rem !important; }
  .ps-lg-50 {
    padding-left: 5rem !important; }
  .ps-lg-49 {
    padding-left: 4.9rem !important; }
  .ps-lg-48 {
    padding-left: 4.8rem !important; }
  .ps-lg-47 {
    padding-left: 4.7rem !important; }
  .ps-lg-46 {
    padding-left: 4.6rem !important; }
  .ps-lg-45 {
    padding-left: 4.5rem !important; }
  .ps-lg-44 {
    padding-left: 4.4rem !important; }
  .ps-lg-43 {
    padding-left: 4.3rem !important; }
  .ps-lg-42 {
    padding-left: 4.2rem !important; }
  .ps-lg-41 {
    padding-left: 4.1rem !important; }
  .ps-lg-40 {
    padding-left: 4rem !important; }
  .ps-lg-39 {
    padding-left: 3.9rem !important; }
  .ps-lg-38 {
    padding-left: 3.8rem !important; }
  .ps-lg-37 {
    padding-left: 3.7rem !important; }
  .ps-lg-36 {
    padding-left: 3.6rem !important; }
  .ps-lg-35 {
    padding-left: 3.5rem !important; }
  .ps-lg-34 {
    padding-left: 3.4rem !important; }
  .ps-lg-33 {
    padding-left: 3.3rem !important; }
  .ps-lg-32 {
    padding-left: 3.2rem !important; }
  .ps-lg-31 {
    padding-left: 3.1rem !important; }
  .ps-lg-30 {
    padding-left: 3rem !important; }
  .ps-lg-29 {
    padding-left: 2.9rem !important; }
  .ps-lg-28 {
    padding-left: 2.8rem !important; }
  .ps-lg-27 {
    padding-left: 2.7rem !important; }
  .ps-lg-26 {
    padding-left: 2.6rem !important; }
  .ps-lg-25 {
    padding-left: 2.5rem !important; }
  .ps-lg-24 {
    padding-left: 2.4rem !important; }
  .ps-lg-23 {
    padding-left: 2.3rem !important; }
  .ps-lg-22 {
    padding-left: 2.2rem !important; }
  .ps-lg-21 {
    padding-left: 2.1rem !important; }
  .ps-lg-20 {
    padding-left: 2rem !important; }
  .ps-lg-19 {
    padding-left: 1.9rem !important; }
  .ps-lg-18 {
    padding-left: 1.8rem !important; }
  .ps-lg-17 {
    padding-left: 1.7rem !important; }
  .ps-lg-16 {
    padding-left: 1.6rem !important; }
  .ps-lg-15 {
    padding-left: 1.5rem !important; }
  .ps-lg-14 {
    padding-left: 1.4rem !important; }
  .ps-lg-13 {
    padding-left: 1.3rem !important; }
  .ps-lg-12 {
    padding-left: 1.2rem !important; }
  .ps-lg-11 {
    padding-left: 1.1rem !important; }
  .ps-lg-10 {
    padding-left: 1rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .ps-lg-n80 {
    padding-left: -80px !important; }
  .ps-lg-n100 {
    padding-left: -100px !important; }
  .ps-lg-n150 {
    padding-left: -150px !important; }
  .fs-lg-45 {
    font-size: 4.5rem !important; }
  .fs-lg-44 {
    font-size: 4.4rem !important; }
  .fs-lg-43 {
    font-size: 4.3rem !important; }
  .fs-lg-42 {
    font-size: 4.2rem !important; }
  .fs-lg-41 {
    font-size: 4.1rem !important; }
  .fs-lg-40 {
    font-size: 4rem !important; }
  .fs-lg-39 {
    font-size: 3.9rem !important; }
  .fs-lg-38 {
    font-size: 3.8rem !important; }
  .fs-lg-37 {
    font-size: 3.7rem !important; }
  .fs-lg-36 {
    font-size: 3.6rem !important; }
  .fs-lg-35 {
    font-size: 3.5rem !important; }
  .fs-lg-34 {
    font-size: 3.4rem !important; }
  .fs-lg-33 {
    font-size: 3.3rem !important; }
  .fs-lg-32 {
    font-size: 3.2rem !important; }
  .fs-lg-31 {
    font-size: 3.1rem !important; }
  .fs-lg-30 {
    font-size: 3rem !important; }
  .fs-lg-29 {
    font-size: 2.9rem !important; }
  .fs-lg-28 {
    font-size: 2.8rem !important; }
  .fs-lg-27 {
    font-size: 2.7rem !important; }
  .fs-lg-26 {
    font-size: 2.6rem !important; }
  .fs-lg-25 {
    font-size: 2.5rem !important; }
  .fs-lg-24 {
    font-size: 2.4rem !important; }
  .fs-lg-23 {
    font-size: 2.3rem !important; }
  .fs-lg-22 {
    font-size: 2.2rem !important; }
  .fs-lg-21 {
    font-size: 2.1rem !important; }
  .fs-lg-20 {
    font-size: 2rem !important; }
  .fs-lg-19 {
    font-size: 1.9rem !important; }
  .fs-lg-18 {
    font-size: 1.8rem !important; }
  .fs-lg-17 {
    font-size: 1.7rem !important; }
  .fs-lg-16 {
    font-size: 1.6rem !important; }
  .fs-lg-15 {
    font-size: 1.5rem !important; }
  .fs-lg-14 {
    font-size: 1.4rem !important; }
  .fs-lg-13 {
    font-size: 1.3rem !important; }
  .fs-lg-12 {
    font-size: 1.2rem !important; }
  .fs-lg-11 {
    font-size: 1.1rem !important; }
  .fs-lg-10 {
    font-size: 1rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .rounded-lg {
    border-radius: 0.8rem !important; }
  .rounded-lg-0 {
    border-radius: 0 !important; }
  .rounded-lg-1 {
    border-radius: 0.4rem !important; }
  .rounded-lg-2 {
    border-radius: 0.8rem !important; }
  .rounded-lg-3 {
    border-radius: 1rem !important; }
  .rounded-lg-circle {
    border-radius: 50% !important; }
  .z-index-lg-1 {
    z-index: 1 !important; }
  .z-index-lg-2 {
    z-index: 2 !important; }
  .z-index-lg-3 {
    z-index: 3 !important; }
  .z-index-lg-4 {
    z-index: 4 !important; }
  .z-index-lg-5 {
    z-index: 5 !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .position-xl-static {
    position: static !important; }
  .position-xl-relative {
    position: relative !important; }
  .position-xl-absolute {
    position: absolute !important; }
  .position-xl-fixed {
    position: fixed !important; }
  .position-xl-sticky {
    position: sticky !important; }
  .w-xl-20 {
    width: 20% !important; }
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-45 {
    width: 45% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-55 {
    width: 55% !important; }
  .w-xl-60 {
    width: 60% !important; }
  .w-xl-65 {
    width: 65% !important; }
  .w-xl-70 {
    width: 70% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-80 {
    width: 80% !important; }
  .w-xl-90 {
    width: 90% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .w-xl-50px {
    width: 50px !important; }
  .w-xl-60px {
    width: 60px !important; }
  .w-xl-90px {
    width: 90px !important; }
  .w-xl-100px {
    width: 100px !important; }
  .w-xl-118px {
    width: 118px !important; }
  .w-xl-158px {
    width: 158px !important; }
  .w-xl-174px {
    width: 174px !important; }
  .w-xl-250px {
    width: 250px !important; }
  .h-xl-20 {
    height: 20% !important; }
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-45 {
    height: 45% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-55 {
    height: 55% !important; }
  .h-xl-60 {
    height: 60% !important; }
  .h-xl-65 {
    height: 65% !important; }
  .h-xl-70 {
    height: 70% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-80 {
    height: 80% !important; }
  .h-xl-90 {
    height: 90% !important; }
  .h-xl-100 {
    height: 100% !important; }
  .h-xl-auto {
    height: auto !important; }
  .h-xl-50px {
    height: 50px !important; }
  .h-xl-60px {
    height: 60px !important; }
  .h-xl-90px {
    height: 90px !important; }
  .h-xl-100px {
    height: 100px !important; }
  .h-xl-118px {
    height: 118px !important; }
  .h-xl-158px {
    height: 158px !important; }
  .h-xl-174px {
    height: 174px !important; }
  .h-xl-250px {
    height: 250px !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-150 {
    gap: 15rem !important; }
  .gap-xl-149 {
    gap: 14.9rem !important; }
  .gap-xl-148 {
    gap: 14.8rem !important; }
  .gap-xl-147 {
    gap: 14.7rem !important; }
  .gap-xl-146 {
    gap: 14.6rem !important; }
  .gap-xl-145 {
    gap: 14.5rem !important; }
  .gap-xl-144 {
    gap: 14.4rem !important; }
  .gap-xl-143 {
    gap: 14.3rem !important; }
  .gap-xl-142 {
    gap: 14.2rem !important; }
  .gap-xl-141 {
    gap: 14.1rem !important; }
  .gap-xl-140 {
    gap: 14rem !important; }
  .gap-xl-139 {
    gap: 13.9rem !important; }
  .gap-xl-138 {
    gap: 13.8rem !important; }
  .gap-xl-137 {
    gap: 13.7rem !important; }
  .gap-xl-136 {
    gap: 13.6rem !important; }
  .gap-xl-135 {
    gap: 13.5rem !important; }
  .gap-xl-134 {
    gap: 13.4rem !important; }
  .gap-xl-133 {
    gap: 13.3rem !important; }
  .gap-xl-132 {
    gap: 13.2rem !important; }
  .gap-xl-131 {
    gap: 13.1rem !important; }
  .gap-xl-130 {
    gap: 13rem !important; }
  .gap-xl-129 {
    gap: 12.9rem !important; }
  .gap-xl-128 {
    gap: 12.8rem !important; }
  .gap-xl-127 {
    gap: 12.7rem !important; }
  .gap-xl-126 {
    gap: 12.6rem !important; }
  .gap-xl-125 {
    gap: 12.5rem !important; }
  .gap-xl-124 {
    gap: 12.4rem !important; }
  .gap-xl-123 {
    gap: 12.3rem !important; }
  .gap-xl-122 {
    gap: 12.2rem !important; }
  .gap-xl-121 {
    gap: 12.1rem !important; }
  .gap-xl-120 {
    gap: 12rem !important; }
  .gap-xl-119 {
    gap: 11.9rem !important; }
  .gap-xl-118 {
    gap: 11.8rem !important; }
  .gap-xl-117 {
    gap: 11.7rem !important; }
  .gap-xl-116 {
    gap: 11.6rem !important; }
  .gap-xl-115 {
    gap: 11.5rem !important; }
  .gap-xl-114 {
    gap: 11.4rem !important; }
  .gap-xl-113 {
    gap: 11.3rem !important; }
  .gap-xl-112 {
    gap: 11.2rem !important; }
  .gap-xl-111 {
    gap: 11.1rem !important; }
  .gap-xl-110 {
    gap: 11rem !important; }
  .gap-xl-109 {
    gap: 10.9rem !important; }
  .gap-xl-108 {
    gap: 10.8rem !important; }
  .gap-xl-107 {
    gap: 10.7rem !important; }
  .gap-xl-106 {
    gap: 10.6rem !important; }
  .gap-xl-105 {
    gap: 10.5rem !important; }
  .gap-xl-104 {
    gap: 10.4rem !important; }
  .gap-xl-103 {
    gap: 10.3rem !important; }
  .gap-xl-102 {
    gap: 10.2rem !important; }
  .gap-xl-101 {
    gap: 10.1rem !important; }
  .gap-xl-100 {
    gap: 10rem !important; }
  .gap-xl-99 {
    gap: 9.9rem !important; }
  .gap-xl-98 {
    gap: 9.8rem !important; }
  .gap-xl-97 {
    gap: 9.7rem !important; }
  .gap-xl-96 {
    gap: 9.6rem !important; }
  .gap-xl-95 {
    gap: 9.5rem !important; }
  .gap-xl-94 {
    gap: 9.4rem !important; }
  .gap-xl-93 {
    gap: 9.3rem !important; }
  .gap-xl-92 {
    gap: 9.2rem !important; }
  .gap-xl-91 {
    gap: 9.1rem !important; }
  .gap-xl-90 {
    gap: 9rem !important; }
  .gap-xl-89 {
    gap: 8.9rem !important; }
  .gap-xl-88 {
    gap: 8.8rem !important; }
  .gap-xl-87 {
    gap: 8.7rem !important; }
  .gap-xl-86 {
    gap: 8.6rem !important; }
  .gap-xl-85 {
    gap: 8.5rem !important; }
  .gap-xl-84 {
    gap: 8.4rem !important; }
  .gap-xl-83 {
    gap: 8.3rem !important; }
  .gap-xl-82 {
    gap: 8.2rem !important; }
  .gap-xl-81 {
    gap: 8.1rem !important; }
  .gap-xl-80 {
    gap: 8rem !important; }
  .gap-xl-79 {
    gap: 7.9rem !important; }
  .gap-xl-78 {
    gap: 7.8rem !important; }
  .gap-xl-77 {
    gap: 7.7rem !important; }
  .gap-xl-76 {
    gap: 7.6rem !important; }
  .gap-xl-75 {
    gap: 7.5rem !important; }
  .gap-xl-74 {
    gap: 7.4rem !important; }
  .gap-xl-73 {
    gap: 7.3rem !important; }
  .gap-xl-72 {
    gap: 7.2rem !important; }
  .gap-xl-71 {
    gap: 7.1rem !important; }
  .gap-xl-70 {
    gap: 7rem !important; }
  .gap-xl-69 {
    gap: 6.9rem !important; }
  .gap-xl-68 {
    gap: 6.8rem !important; }
  .gap-xl-67 {
    gap: 6.7rem !important; }
  .gap-xl-66 {
    gap: 6.6rem !important; }
  .gap-xl-65 {
    gap: 6.5rem !important; }
  .gap-xl-64 {
    gap: 6.4rem !important; }
  .gap-xl-63 {
    gap: 6.3rem !important; }
  .gap-xl-62 {
    gap: 6.2rem !important; }
  .gap-xl-61 {
    gap: 6.1rem !important; }
  .gap-xl-60 {
    gap: 6rem !important; }
  .gap-xl-59 {
    gap: 5.9rem !important; }
  .gap-xl-58 {
    gap: 5.8rem !important; }
  .gap-xl-57 {
    gap: 5.7rem !important; }
  .gap-xl-56 {
    gap: 5.6rem !important; }
  .gap-xl-55 {
    gap: 5.5rem !important; }
  .gap-xl-54 {
    gap: 5.4rem !important; }
  .gap-xl-53 {
    gap: 5.3rem !important; }
  .gap-xl-52 {
    gap: 5.2rem !important; }
  .gap-xl-51 {
    gap: 5.1rem !important; }
  .gap-xl-50 {
    gap: 5rem !important; }
  .gap-xl-49 {
    gap: 4.9rem !important; }
  .gap-xl-48 {
    gap: 4.8rem !important; }
  .gap-xl-47 {
    gap: 4.7rem !important; }
  .gap-xl-46 {
    gap: 4.6rem !important; }
  .gap-xl-45 {
    gap: 4.5rem !important; }
  .gap-xl-44 {
    gap: 4.4rem !important; }
  .gap-xl-43 {
    gap: 4.3rem !important; }
  .gap-xl-42 {
    gap: 4.2rem !important; }
  .gap-xl-41 {
    gap: 4.1rem !important; }
  .gap-xl-40 {
    gap: 4rem !important; }
  .gap-xl-39 {
    gap: 3.9rem !important; }
  .gap-xl-38 {
    gap: 3.8rem !important; }
  .gap-xl-37 {
    gap: 3.7rem !important; }
  .gap-xl-36 {
    gap: 3.6rem !important; }
  .gap-xl-35 {
    gap: 3.5rem !important; }
  .gap-xl-34 {
    gap: 3.4rem !important; }
  .gap-xl-33 {
    gap: 3.3rem !important; }
  .gap-xl-32 {
    gap: 3.2rem !important; }
  .gap-xl-31 {
    gap: 3.1rem !important; }
  .gap-xl-30 {
    gap: 3rem !important; }
  .gap-xl-29 {
    gap: 2.9rem !important; }
  .gap-xl-28 {
    gap: 2.8rem !important; }
  .gap-xl-27 {
    gap: 2.7rem !important; }
  .gap-xl-26 {
    gap: 2.6rem !important; }
  .gap-xl-25 {
    gap: 2.5rem !important; }
  .gap-xl-24 {
    gap: 2.4rem !important; }
  .gap-xl-23 {
    gap: 2.3rem !important; }
  .gap-xl-22 {
    gap: 2.2rem !important; }
  .gap-xl-21 {
    gap: 2.1rem !important; }
  .gap-xl-20 {
    gap: 2rem !important; }
  .gap-xl-19 {
    gap: 1.9rem !important; }
  .gap-xl-18 {
    gap: 1.8rem !important; }
  .gap-xl-17 {
    gap: 1.7rem !important; }
  .gap-xl-16 {
    gap: 1.6rem !important; }
  .gap-xl-15 {
    gap: 1.5rem !important; }
  .gap-xl-14 {
    gap: 1.4rem !important; }
  .gap-xl-13 {
    gap: 1.3rem !important; }
  .gap-xl-12 {
    gap: 1.2rem !important; }
  .gap-xl-11 {
    gap: 1.1rem !important; }
  .gap-xl-10 {
    gap: 1rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .gap-xl-n80 {
    gap: -80px !important; }
  .gap-xl-n100 {
    gap: -100px !important; }
  .gap-xl-n150 {
    gap: -150px !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-150 {
    margin: 15rem !important; }
  .m-xl-149 {
    margin: 14.9rem !important; }
  .m-xl-148 {
    margin: 14.8rem !important; }
  .m-xl-147 {
    margin: 14.7rem !important; }
  .m-xl-146 {
    margin: 14.6rem !important; }
  .m-xl-145 {
    margin: 14.5rem !important; }
  .m-xl-144 {
    margin: 14.4rem !important; }
  .m-xl-143 {
    margin: 14.3rem !important; }
  .m-xl-142 {
    margin: 14.2rem !important; }
  .m-xl-141 {
    margin: 14.1rem !important; }
  .m-xl-140 {
    margin: 14rem !important; }
  .m-xl-139 {
    margin: 13.9rem !important; }
  .m-xl-138 {
    margin: 13.8rem !important; }
  .m-xl-137 {
    margin: 13.7rem !important; }
  .m-xl-136 {
    margin: 13.6rem !important; }
  .m-xl-135 {
    margin: 13.5rem !important; }
  .m-xl-134 {
    margin: 13.4rem !important; }
  .m-xl-133 {
    margin: 13.3rem !important; }
  .m-xl-132 {
    margin: 13.2rem !important; }
  .m-xl-131 {
    margin: 13.1rem !important; }
  .m-xl-130 {
    margin: 13rem !important; }
  .m-xl-129 {
    margin: 12.9rem !important; }
  .m-xl-128 {
    margin: 12.8rem !important; }
  .m-xl-127 {
    margin: 12.7rem !important; }
  .m-xl-126 {
    margin: 12.6rem !important; }
  .m-xl-125 {
    margin: 12.5rem !important; }
  .m-xl-124 {
    margin: 12.4rem !important; }
  .m-xl-123 {
    margin: 12.3rem !important; }
  .m-xl-122 {
    margin: 12.2rem !important; }
  .m-xl-121 {
    margin: 12.1rem !important; }
  .m-xl-120 {
    margin: 12rem !important; }
  .m-xl-119 {
    margin: 11.9rem !important; }
  .m-xl-118 {
    margin: 11.8rem !important; }
  .m-xl-117 {
    margin: 11.7rem !important; }
  .m-xl-116 {
    margin: 11.6rem !important; }
  .m-xl-115 {
    margin: 11.5rem !important; }
  .m-xl-114 {
    margin: 11.4rem !important; }
  .m-xl-113 {
    margin: 11.3rem !important; }
  .m-xl-112 {
    margin: 11.2rem !important; }
  .m-xl-111 {
    margin: 11.1rem !important; }
  .m-xl-110 {
    margin: 11rem !important; }
  .m-xl-109 {
    margin: 10.9rem !important; }
  .m-xl-108 {
    margin: 10.8rem !important; }
  .m-xl-107 {
    margin: 10.7rem !important; }
  .m-xl-106 {
    margin: 10.6rem !important; }
  .m-xl-105 {
    margin: 10.5rem !important; }
  .m-xl-104 {
    margin: 10.4rem !important; }
  .m-xl-103 {
    margin: 10.3rem !important; }
  .m-xl-102 {
    margin: 10.2rem !important; }
  .m-xl-101 {
    margin: 10.1rem !important; }
  .m-xl-100 {
    margin: 10rem !important; }
  .m-xl-99 {
    margin: 9.9rem !important; }
  .m-xl-98 {
    margin: 9.8rem !important; }
  .m-xl-97 {
    margin: 9.7rem !important; }
  .m-xl-96 {
    margin: 9.6rem !important; }
  .m-xl-95 {
    margin: 9.5rem !important; }
  .m-xl-94 {
    margin: 9.4rem !important; }
  .m-xl-93 {
    margin: 9.3rem !important; }
  .m-xl-92 {
    margin: 9.2rem !important; }
  .m-xl-91 {
    margin: 9.1rem !important; }
  .m-xl-90 {
    margin: 9rem !important; }
  .m-xl-89 {
    margin: 8.9rem !important; }
  .m-xl-88 {
    margin: 8.8rem !important; }
  .m-xl-87 {
    margin: 8.7rem !important; }
  .m-xl-86 {
    margin: 8.6rem !important; }
  .m-xl-85 {
    margin: 8.5rem !important; }
  .m-xl-84 {
    margin: 8.4rem !important; }
  .m-xl-83 {
    margin: 8.3rem !important; }
  .m-xl-82 {
    margin: 8.2rem !important; }
  .m-xl-81 {
    margin: 8.1rem !important; }
  .m-xl-80 {
    margin: 8rem !important; }
  .m-xl-79 {
    margin: 7.9rem !important; }
  .m-xl-78 {
    margin: 7.8rem !important; }
  .m-xl-77 {
    margin: 7.7rem !important; }
  .m-xl-76 {
    margin: 7.6rem !important; }
  .m-xl-75 {
    margin: 7.5rem !important; }
  .m-xl-74 {
    margin: 7.4rem !important; }
  .m-xl-73 {
    margin: 7.3rem !important; }
  .m-xl-72 {
    margin: 7.2rem !important; }
  .m-xl-71 {
    margin: 7.1rem !important; }
  .m-xl-70 {
    margin: 7rem !important; }
  .m-xl-69 {
    margin: 6.9rem !important; }
  .m-xl-68 {
    margin: 6.8rem !important; }
  .m-xl-67 {
    margin: 6.7rem !important; }
  .m-xl-66 {
    margin: 6.6rem !important; }
  .m-xl-65 {
    margin: 6.5rem !important; }
  .m-xl-64 {
    margin: 6.4rem !important; }
  .m-xl-63 {
    margin: 6.3rem !important; }
  .m-xl-62 {
    margin: 6.2rem !important; }
  .m-xl-61 {
    margin: 6.1rem !important; }
  .m-xl-60 {
    margin: 6rem !important; }
  .m-xl-59 {
    margin: 5.9rem !important; }
  .m-xl-58 {
    margin: 5.8rem !important; }
  .m-xl-57 {
    margin: 5.7rem !important; }
  .m-xl-56 {
    margin: 5.6rem !important; }
  .m-xl-55 {
    margin: 5.5rem !important; }
  .m-xl-54 {
    margin: 5.4rem !important; }
  .m-xl-53 {
    margin: 5.3rem !important; }
  .m-xl-52 {
    margin: 5.2rem !important; }
  .m-xl-51 {
    margin: 5.1rem !important; }
  .m-xl-50 {
    margin: 5rem !important; }
  .m-xl-49 {
    margin: 4.9rem !important; }
  .m-xl-48 {
    margin: 4.8rem !important; }
  .m-xl-47 {
    margin: 4.7rem !important; }
  .m-xl-46 {
    margin: 4.6rem !important; }
  .m-xl-45 {
    margin: 4.5rem !important; }
  .m-xl-44 {
    margin: 4.4rem !important; }
  .m-xl-43 {
    margin: 4.3rem !important; }
  .m-xl-42 {
    margin: 4.2rem !important; }
  .m-xl-41 {
    margin: 4.1rem !important; }
  .m-xl-40 {
    margin: 4rem !important; }
  .m-xl-39 {
    margin: 3.9rem !important; }
  .m-xl-38 {
    margin: 3.8rem !important; }
  .m-xl-37 {
    margin: 3.7rem !important; }
  .m-xl-36 {
    margin: 3.6rem !important; }
  .m-xl-35 {
    margin: 3.5rem !important; }
  .m-xl-34 {
    margin: 3.4rem !important; }
  .m-xl-33 {
    margin: 3.3rem !important; }
  .m-xl-32 {
    margin: 3.2rem !important; }
  .m-xl-31 {
    margin: 3.1rem !important; }
  .m-xl-30 {
    margin: 3rem !important; }
  .m-xl-29 {
    margin: 2.9rem !important; }
  .m-xl-28 {
    margin: 2.8rem !important; }
  .m-xl-27 {
    margin: 2.7rem !important; }
  .m-xl-26 {
    margin: 2.6rem !important; }
  .m-xl-25 {
    margin: 2.5rem !important; }
  .m-xl-24 {
    margin: 2.4rem !important; }
  .m-xl-23 {
    margin: 2.3rem !important; }
  .m-xl-22 {
    margin: 2.2rem !important; }
  .m-xl-21 {
    margin: 2.1rem !important; }
  .m-xl-20 {
    margin: 2rem !important; }
  .m-xl-19 {
    margin: 1.9rem !important; }
  .m-xl-18 {
    margin: 1.8rem !important; }
  .m-xl-17 {
    margin: 1.7rem !important; }
  .m-xl-16 {
    margin: 1.6rem !important; }
  .m-xl-15 {
    margin: 1.5rem !important; }
  .m-xl-14 {
    margin: 1.4rem !important; }
  .m-xl-13 {
    margin: 1.3rem !important; }
  .m-xl-12 {
    margin: 1.2rem !important; }
  .m-xl-11 {
    margin: 1.1rem !important; }
  .m-xl-10 {
    margin: 1rem !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-n80 {
    margin: -80px !important; }
  .m-xl-n100 {
    margin: -100px !important; }
  .m-xl-n150 {
    margin: -150px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-150 {
    margin-right: 15rem !important;
    margin-left: 15rem !important; }
  .mx-xl-149 {
    margin-right: 14.9rem !important;
    margin-left: 14.9rem !important; }
  .mx-xl-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-xl-147 {
    margin-right: 14.7rem !important;
    margin-left: 14.7rem !important; }
  .mx-xl-146 {
    margin-right: 14.6rem !important;
    margin-left: 14.6rem !important; }
  .mx-xl-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-xl-144 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important; }
  .mx-xl-143 {
    margin-right: 14.3rem !important;
    margin-left: 14.3rem !important; }
  .mx-xl-142 {
    margin-right: 14.2rem !important;
    margin-left: 14.2rem !important; }
  .mx-xl-141 {
    margin-right: 14.1rem !important;
    margin-left: 14.1rem !important; }
  .mx-xl-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-xl-139 {
    margin-right: 13.9rem !important;
    margin-left: 13.9rem !important; }
  .mx-xl-138 {
    margin-right: 13.8rem !important;
    margin-left: 13.8rem !important; }
  .mx-xl-137 {
    margin-right: 13.7rem !important;
    margin-left: 13.7rem !important; }
  .mx-xl-136 {
    margin-right: 13.6rem !important;
    margin-left: 13.6rem !important; }
  .mx-xl-135 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important; }
  .mx-xl-134 {
    margin-right: 13.4rem !important;
    margin-left: 13.4rem !important; }
  .mx-xl-133 {
    margin-right: 13.3rem !important;
    margin-left: 13.3rem !important; }
  .mx-xl-132 {
    margin-right: 13.2rem !important;
    margin-left: 13.2rem !important; }
  .mx-xl-131 {
    margin-right: 13.1rem !important;
    margin-left: 13.1rem !important; }
  .mx-xl-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-xl-129 {
    margin-right: 12.9rem !important;
    margin-left: 12.9rem !important; }
  .mx-xl-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-xl-127 {
    margin-right: 12.7rem !important;
    margin-left: 12.7rem !important; }
  .mx-xl-126 {
    margin-right: 12.6rem !important;
    margin-left: 12.6rem !important; }
  .mx-xl-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xl-124 {
    margin-right: 12.4rem !important;
    margin-left: 12.4rem !important; }
  .mx-xl-123 {
    margin-right: 12.3rem !important;
    margin-left: 12.3rem !important; }
  .mx-xl-122 {
    margin-right: 12.2rem !important;
    margin-left: 12.2rem !important; }
  .mx-xl-121 {
    margin-right: 12.1rem !important;
    margin-left: 12.1rem !important; }
  .mx-xl-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-xl-119 {
    margin-right: 11.9rem !important;
    margin-left: 11.9rem !important; }
  .mx-xl-118 {
    margin-right: 11.8rem !important;
    margin-left: 11.8rem !important; }
  .mx-xl-117 {
    margin-right: 11.7rem !important;
    margin-left: 11.7rem !important; }
  .mx-xl-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-xl-115 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-xl-114 {
    margin-right: 11.4rem !important;
    margin-left: 11.4rem !important; }
  .mx-xl-113 {
    margin-right: 11.3rem !important;
    margin-left: 11.3rem !important; }
  .mx-xl-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-xl-111 {
    margin-right: 11.1rem !important;
    margin-left: 11.1rem !important; }
  .mx-xl-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-xl-109 {
    margin-right: 10.9rem !important;
    margin-left: 10.9rem !important; }
  .mx-xl-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-xl-107 {
    margin-right: 10.7rem !important;
    margin-left: 10.7rem !important; }
  .mx-xl-106 {
    margin-right: 10.6rem !important;
    margin-left: 10.6rem !important; }
  .mx-xl-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-xl-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-xl-103 {
    margin-right: 10.3rem !important;
    margin-left: 10.3rem !important; }
  .mx-xl-102 {
    margin-right: 10.2rem !important;
    margin-left: 10.2rem !important; }
  .mx-xl-101 {
    margin-right: 10.1rem !important;
    margin-left: 10.1rem !important; }
  .mx-xl-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xl-99 {
    margin-right: 9.9rem !important;
    margin-left: 9.9rem !important; }
  .mx-xl-98 {
    margin-right: 9.8rem !important;
    margin-left: 9.8rem !important; }
  .mx-xl-97 {
    margin-right: 9.7rem !important;
    margin-left: 9.7rem !important; }
  .mx-xl-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-xl-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-xl-94 {
    margin-right: 9.4rem !important;
    margin-left: 9.4rem !important; }
  .mx-xl-93 {
    margin-right: 9.3rem !important;
    margin-left: 9.3rem !important; }
  .mx-xl-92 {
    margin-right: 9.2rem !important;
    margin-left: 9.2rem !important; }
  .mx-xl-91 {
    margin-right: 9.1rem !important;
    margin-left: 9.1rem !important; }
  .mx-xl-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xl-89 {
    margin-right: 8.9rem !important;
    margin-left: 8.9rem !important; }
  .mx-xl-88 {
    margin-right: 8.8rem !important;
    margin-left: 8.8rem !important; }
  .mx-xl-87 {
    margin-right: 8.7rem !important;
    margin-left: 8.7rem !important; }
  .mx-xl-86 {
    margin-right: 8.6rem !important;
    margin-left: 8.6rem !important; }
  .mx-xl-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-xl-84 {
    margin-right: 8.4rem !important;
    margin-left: 8.4rem !important; }
  .mx-xl-83 {
    margin-right: 8.3rem !important;
    margin-left: 8.3rem !important; }
  .mx-xl-82 {
    margin-right: 8.2rem !important;
    margin-left: 8.2rem !important; }
  .mx-xl-81 {
    margin-right: 8.1rem !important;
    margin-left: 8.1rem !important; }
  .mx-xl-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-xl-79 {
    margin-right: 7.9rem !important;
    margin-left: 7.9rem !important; }
  .mx-xl-78 {
    margin-right: 7.8rem !important;
    margin-left: 7.8rem !important; }
  .mx-xl-77 {
    margin-right: 7.7rem !important;
    margin-left: 7.7rem !important; }
  .mx-xl-76 {
    margin-right: 7.6rem !important;
    margin-left: 7.6rem !important; }
  .mx-xl-75 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xl-74 {
    margin-right: 7.4rem !important;
    margin-left: 7.4rem !important; }
  .mx-xl-73 {
    margin-right: 7.3rem !important;
    margin-left: 7.3rem !important; }
  .mx-xl-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-xl-71 {
    margin-right: 7.1rem !important;
    margin-left: 7.1rem !important; }
  .mx-xl-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-xl-69 {
    margin-right: 6.9rem !important;
    margin-left: 6.9rem !important; }
  .mx-xl-68 {
    margin-right: 6.8rem !important;
    margin-left: 6.8rem !important; }
  .mx-xl-67 {
    margin-right: 6.7rem !important;
    margin-left: 6.7rem !important; }
  .mx-xl-66 {
    margin-right: 6.6rem !important;
    margin-left: 6.6rem !important; }
  .mx-xl-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-xl-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-xl-63 {
    margin-right: 6.3rem !important;
    margin-left: 6.3rem !important; }
  .mx-xl-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-xl-61 {
    margin-right: 6.1rem !important;
    margin-left: 6.1rem !important; }
  .mx-xl-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xl-59 {
    margin-right: 5.9rem !important;
    margin-left: 5.9rem !important; }
  .mx-xl-58 {
    margin-right: 5.8rem !important;
    margin-left: 5.8rem !important; }
  .mx-xl-57 {
    margin-right: 5.7rem !important;
    margin-left: 5.7rem !important; }
  .mx-xl-56 {
    margin-right: 5.6rem !important;
    margin-left: 5.6rem !important; }
  .mx-xl-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-xl-54 {
    margin-right: 5.4rem !important;
    margin-left: 5.4rem !important; }
  .mx-xl-53 {
    margin-right: 5.3rem !important;
    margin-left: 5.3rem !important; }
  .mx-xl-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-xl-51 {
    margin-right: 5.1rem !important;
    margin-left: 5.1rem !important; }
  .mx-xl-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xl-49 {
    margin-right: 4.9rem !important;
    margin-left: 4.9rem !important; }
  .mx-xl-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-xl-47 {
    margin-right: 4.7rem !important;
    margin-left: 4.7rem !important; }
  .mx-xl-46 {
    margin-right: 4.6rem !important;
    margin-left: 4.6rem !important; }
  .mx-xl-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xl-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-xl-43 {
    margin-right: 4.3rem !important;
    margin-left: 4.3rem !important; }
  .mx-xl-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-xl-41 {
    margin-right: 4.1rem !important;
    margin-left: 4.1rem !important; }
  .mx-xl-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xl-39 {
    margin-right: 3.9rem !important;
    margin-left: 3.9rem !important; }
  .mx-xl-38 {
    margin-right: 3.8rem !important;
    margin-left: 3.8rem !important; }
  .mx-xl-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-xl-36 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important; }
  .mx-xl-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xl-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-xl-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-xl-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-xl-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-xl-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-xl-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-xl-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-xl-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-xl-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xl-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-xl-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-xl-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-xl-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-xl-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xl-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-xl-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-xl-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-xl-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-xl-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-xl-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-xl-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-xl-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-xl-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important; }
  .mx-xl-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important; }
  .mx-xl-n150 {
    margin-right: -150px !important;
    margin-left: -150px !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-150 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important; }
  .my-xl-149 {
    margin-top: 14.9rem !important;
    margin-bottom: 14.9rem !important; }
  .my-xl-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-xl-147 {
    margin-top: 14.7rem !important;
    margin-bottom: 14.7rem !important; }
  .my-xl-146 {
    margin-top: 14.6rem !important;
    margin-bottom: 14.6rem !important; }
  .my-xl-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-xl-144 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important; }
  .my-xl-143 {
    margin-top: 14.3rem !important;
    margin-bottom: 14.3rem !important; }
  .my-xl-142 {
    margin-top: 14.2rem !important;
    margin-bottom: 14.2rem !important; }
  .my-xl-141 {
    margin-top: 14.1rem !important;
    margin-bottom: 14.1rem !important; }
  .my-xl-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-xl-139 {
    margin-top: 13.9rem !important;
    margin-bottom: 13.9rem !important; }
  .my-xl-138 {
    margin-top: 13.8rem !important;
    margin-bottom: 13.8rem !important; }
  .my-xl-137 {
    margin-top: 13.7rem !important;
    margin-bottom: 13.7rem !important; }
  .my-xl-136 {
    margin-top: 13.6rem !important;
    margin-bottom: 13.6rem !important; }
  .my-xl-135 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important; }
  .my-xl-134 {
    margin-top: 13.4rem !important;
    margin-bottom: 13.4rem !important; }
  .my-xl-133 {
    margin-top: 13.3rem !important;
    margin-bottom: 13.3rem !important; }
  .my-xl-132 {
    margin-top: 13.2rem !important;
    margin-bottom: 13.2rem !important; }
  .my-xl-131 {
    margin-top: 13.1rem !important;
    margin-bottom: 13.1rem !important; }
  .my-xl-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-xl-129 {
    margin-top: 12.9rem !important;
    margin-bottom: 12.9rem !important; }
  .my-xl-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-xl-127 {
    margin-top: 12.7rem !important;
    margin-bottom: 12.7rem !important; }
  .my-xl-126 {
    margin-top: 12.6rem !important;
    margin-bottom: 12.6rem !important; }
  .my-xl-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xl-124 {
    margin-top: 12.4rem !important;
    margin-bottom: 12.4rem !important; }
  .my-xl-123 {
    margin-top: 12.3rem !important;
    margin-bottom: 12.3rem !important; }
  .my-xl-122 {
    margin-top: 12.2rem !important;
    margin-bottom: 12.2rem !important; }
  .my-xl-121 {
    margin-top: 12.1rem !important;
    margin-bottom: 12.1rem !important; }
  .my-xl-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-xl-119 {
    margin-top: 11.9rem !important;
    margin-bottom: 11.9rem !important; }
  .my-xl-118 {
    margin-top: 11.8rem !important;
    margin-bottom: 11.8rem !important; }
  .my-xl-117 {
    margin-top: 11.7rem !important;
    margin-bottom: 11.7rem !important; }
  .my-xl-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-xl-115 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-xl-114 {
    margin-top: 11.4rem !important;
    margin-bottom: 11.4rem !important; }
  .my-xl-113 {
    margin-top: 11.3rem !important;
    margin-bottom: 11.3rem !important; }
  .my-xl-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-xl-111 {
    margin-top: 11.1rem !important;
    margin-bottom: 11.1rem !important; }
  .my-xl-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-xl-109 {
    margin-top: 10.9rem !important;
    margin-bottom: 10.9rem !important; }
  .my-xl-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-xl-107 {
    margin-top: 10.7rem !important;
    margin-bottom: 10.7rem !important; }
  .my-xl-106 {
    margin-top: 10.6rem !important;
    margin-bottom: 10.6rem !important; }
  .my-xl-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-xl-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-xl-103 {
    margin-top: 10.3rem !important;
    margin-bottom: 10.3rem !important; }
  .my-xl-102 {
    margin-top: 10.2rem !important;
    margin-bottom: 10.2rem !important; }
  .my-xl-101 {
    margin-top: 10.1rem !important;
    margin-bottom: 10.1rem !important; }
  .my-xl-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xl-99 {
    margin-top: 9.9rem !important;
    margin-bottom: 9.9rem !important; }
  .my-xl-98 {
    margin-top: 9.8rem !important;
    margin-bottom: 9.8rem !important; }
  .my-xl-97 {
    margin-top: 9.7rem !important;
    margin-bottom: 9.7rem !important; }
  .my-xl-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-xl-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-xl-94 {
    margin-top: 9.4rem !important;
    margin-bottom: 9.4rem !important; }
  .my-xl-93 {
    margin-top: 9.3rem !important;
    margin-bottom: 9.3rem !important; }
  .my-xl-92 {
    margin-top: 9.2rem !important;
    margin-bottom: 9.2rem !important; }
  .my-xl-91 {
    margin-top: 9.1rem !important;
    margin-bottom: 9.1rem !important; }
  .my-xl-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xl-89 {
    margin-top: 8.9rem !important;
    margin-bottom: 8.9rem !important; }
  .my-xl-88 {
    margin-top: 8.8rem !important;
    margin-bottom: 8.8rem !important; }
  .my-xl-87 {
    margin-top: 8.7rem !important;
    margin-bottom: 8.7rem !important; }
  .my-xl-86 {
    margin-top: 8.6rem !important;
    margin-bottom: 8.6rem !important; }
  .my-xl-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-xl-84 {
    margin-top: 8.4rem !important;
    margin-bottom: 8.4rem !important; }
  .my-xl-83 {
    margin-top: 8.3rem !important;
    margin-bottom: 8.3rem !important; }
  .my-xl-82 {
    margin-top: 8.2rem !important;
    margin-bottom: 8.2rem !important; }
  .my-xl-81 {
    margin-top: 8.1rem !important;
    margin-bottom: 8.1rem !important; }
  .my-xl-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-xl-79 {
    margin-top: 7.9rem !important;
    margin-bottom: 7.9rem !important; }
  .my-xl-78 {
    margin-top: 7.8rem !important;
    margin-bottom: 7.8rem !important; }
  .my-xl-77 {
    margin-top: 7.7rem !important;
    margin-bottom: 7.7rem !important; }
  .my-xl-76 {
    margin-top: 7.6rem !important;
    margin-bottom: 7.6rem !important; }
  .my-xl-75 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xl-74 {
    margin-top: 7.4rem !important;
    margin-bottom: 7.4rem !important; }
  .my-xl-73 {
    margin-top: 7.3rem !important;
    margin-bottom: 7.3rem !important; }
  .my-xl-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-xl-71 {
    margin-top: 7.1rem !important;
    margin-bottom: 7.1rem !important; }
  .my-xl-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-xl-69 {
    margin-top: 6.9rem !important;
    margin-bottom: 6.9rem !important; }
  .my-xl-68 {
    margin-top: 6.8rem !important;
    margin-bottom: 6.8rem !important; }
  .my-xl-67 {
    margin-top: 6.7rem !important;
    margin-bottom: 6.7rem !important; }
  .my-xl-66 {
    margin-top: 6.6rem !important;
    margin-bottom: 6.6rem !important; }
  .my-xl-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-xl-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-xl-63 {
    margin-top: 6.3rem !important;
    margin-bottom: 6.3rem !important; }
  .my-xl-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-xl-61 {
    margin-top: 6.1rem !important;
    margin-bottom: 6.1rem !important; }
  .my-xl-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xl-59 {
    margin-top: 5.9rem !important;
    margin-bottom: 5.9rem !important; }
  .my-xl-58 {
    margin-top: 5.8rem !important;
    margin-bottom: 5.8rem !important; }
  .my-xl-57 {
    margin-top: 5.7rem !important;
    margin-bottom: 5.7rem !important; }
  .my-xl-56 {
    margin-top: 5.6rem !important;
    margin-bottom: 5.6rem !important; }
  .my-xl-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-xl-54 {
    margin-top: 5.4rem !important;
    margin-bottom: 5.4rem !important; }
  .my-xl-53 {
    margin-top: 5.3rem !important;
    margin-bottom: 5.3rem !important; }
  .my-xl-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-xl-51 {
    margin-top: 5.1rem !important;
    margin-bottom: 5.1rem !important; }
  .my-xl-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xl-49 {
    margin-top: 4.9rem !important;
    margin-bottom: 4.9rem !important; }
  .my-xl-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-xl-47 {
    margin-top: 4.7rem !important;
    margin-bottom: 4.7rem !important; }
  .my-xl-46 {
    margin-top: 4.6rem !important;
    margin-bottom: 4.6rem !important; }
  .my-xl-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xl-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-xl-43 {
    margin-top: 4.3rem !important;
    margin-bottom: 4.3rem !important; }
  .my-xl-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-xl-41 {
    margin-top: 4.1rem !important;
    margin-bottom: 4.1rem !important; }
  .my-xl-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xl-39 {
    margin-top: 3.9rem !important;
    margin-bottom: 3.9rem !important; }
  .my-xl-38 {
    margin-top: 3.8rem !important;
    margin-bottom: 3.8rem !important; }
  .my-xl-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-xl-36 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important; }
  .my-xl-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xl-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-xl-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-xl-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-xl-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-xl-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-xl-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-xl-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-xl-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-xl-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xl-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-xl-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-xl-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-xl-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-xl-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xl-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-xl-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-xl-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-xl-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-xl-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-xl-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-xl-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-xl-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-xl-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .my-xl-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .my-xl-n150 {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-150 {
    margin-top: 15rem !important; }
  .mt-xl-149 {
    margin-top: 14.9rem !important; }
  .mt-xl-148 {
    margin-top: 14.8rem !important; }
  .mt-xl-147 {
    margin-top: 14.7rem !important; }
  .mt-xl-146 {
    margin-top: 14.6rem !important; }
  .mt-xl-145 {
    margin-top: 14.5rem !important; }
  .mt-xl-144 {
    margin-top: 14.4rem !important; }
  .mt-xl-143 {
    margin-top: 14.3rem !important; }
  .mt-xl-142 {
    margin-top: 14.2rem !important; }
  .mt-xl-141 {
    margin-top: 14.1rem !important; }
  .mt-xl-140 {
    margin-top: 14rem !important; }
  .mt-xl-139 {
    margin-top: 13.9rem !important; }
  .mt-xl-138 {
    margin-top: 13.8rem !important; }
  .mt-xl-137 {
    margin-top: 13.7rem !important; }
  .mt-xl-136 {
    margin-top: 13.6rem !important; }
  .mt-xl-135 {
    margin-top: 13.5rem !important; }
  .mt-xl-134 {
    margin-top: 13.4rem !important; }
  .mt-xl-133 {
    margin-top: 13.3rem !important; }
  .mt-xl-132 {
    margin-top: 13.2rem !important; }
  .mt-xl-131 {
    margin-top: 13.1rem !important; }
  .mt-xl-130 {
    margin-top: 13rem !important; }
  .mt-xl-129 {
    margin-top: 12.9rem !important; }
  .mt-xl-128 {
    margin-top: 12.8rem !important; }
  .mt-xl-127 {
    margin-top: 12.7rem !important; }
  .mt-xl-126 {
    margin-top: 12.6rem !important; }
  .mt-xl-125 {
    margin-top: 12.5rem !important; }
  .mt-xl-124 {
    margin-top: 12.4rem !important; }
  .mt-xl-123 {
    margin-top: 12.3rem !important; }
  .mt-xl-122 {
    margin-top: 12.2rem !important; }
  .mt-xl-121 {
    margin-top: 12.1rem !important; }
  .mt-xl-120 {
    margin-top: 12rem !important; }
  .mt-xl-119 {
    margin-top: 11.9rem !important; }
  .mt-xl-118 {
    margin-top: 11.8rem !important; }
  .mt-xl-117 {
    margin-top: 11.7rem !important; }
  .mt-xl-116 {
    margin-top: 11.6rem !important; }
  .mt-xl-115 {
    margin-top: 11.5rem !important; }
  .mt-xl-114 {
    margin-top: 11.4rem !important; }
  .mt-xl-113 {
    margin-top: 11.3rem !important; }
  .mt-xl-112 {
    margin-top: 11.2rem !important; }
  .mt-xl-111 {
    margin-top: 11.1rem !important; }
  .mt-xl-110 {
    margin-top: 11rem !important; }
  .mt-xl-109 {
    margin-top: 10.9rem !important; }
  .mt-xl-108 {
    margin-top: 10.8rem !important; }
  .mt-xl-107 {
    margin-top: 10.7rem !important; }
  .mt-xl-106 {
    margin-top: 10.6rem !important; }
  .mt-xl-105 {
    margin-top: 10.5rem !important; }
  .mt-xl-104 {
    margin-top: 10.4rem !important; }
  .mt-xl-103 {
    margin-top: 10.3rem !important; }
  .mt-xl-102 {
    margin-top: 10.2rem !important; }
  .mt-xl-101 {
    margin-top: 10.1rem !important; }
  .mt-xl-100 {
    margin-top: 10rem !important; }
  .mt-xl-99 {
    margin-top: 9.9rem !important; }
  .mt-xl-98 {
    margin-top: 9.8rem !important; }
  .mt-xl-97 {
    margin-top: 9.7rem !important; }
  .mt-xl-96 {
    margin-top: 9.6rem !important; }
  .mt-xl-95 {
    margin-top: 9.5rem !important; }
  .mt-xl-94 {
    margin-top: 9.4rem !important; }
  .mt-xl-93 {
    margin-top: 9.3rem !important; }
  .mt-xl-92 {
    margin-top: 9.2rem !important; }
  .mt-xl-91 {
    margin-top: 9.1rem !important; }
  .mt-xl-90 {
    margin-top: 9rem !important; }
  .mt-xl-89 {
    margin-top: 8.9rem !important; }
  .mt-xl-88 {
    margin-top: 8.8rem !important; }
  .mt-xl-87 {
    margin-top: 8.7rem !important; }
  .mt-xl-86 {
    margin-top: 8.6rem !important; }
  .mt-xl-85 {
    margin-top: 8.5rem !important; }
  .mt-xl-84 {
    margin-top: 8.4rem !important; }
  .mt-xl-83 {
    margin-top: 8.3rem !important; }
  .mt-xl-82 {
    margin-top: 8.2rem !important; }
  .mt-xl-81 {
    margin-top: 8.1rem !important; }
  .mt-xl-80 {
    margin-top: 8rem !important; }
  .mt-xl-79 {
    margin-top: 7.9rem !important; }
  .mt-xl-78 {
    margin-top: 7.8rem !important; }
  .mt-xl-77 {
    margin-top: 7.7rem !important; }
  .mt-xl-76 {
    margin-top: 7.6rem !important; }
  .mt-xl-75 {
    margin-top: 7.5rem !important; }
  .mt-xl-74 {
    margin-top: 7.4rem !important; }
  .mt-xl-73 {
    margin-top: 7.3rem !important; }
  .mt-xl-72 {
    margin-top: 7.2rem !important; }
  .mt-xl-71 {
    margin-top: 7.1rem !important; }
  .mt-xl-70 {
    margin-top: 7rem !important; }
  .mt-xl-69 {
    margin-top: 6.9rem !important; }
  .mt-xl-68 {
    margin-top: 6.8rem !important; }
  .mt-xl-67 {
    margin-top: 6.7rem !important; }
  .mt-xl-66 {
    margin-top: 6.6rem !important; }
  .mt-xl-65 {
    margin-top: 6.5rem !important; }
  .mt-xl-64 {
    margin-top: 6.4rem !important; }
  .mt-xl-63 {
    margin-top: 6.3rem !important; }
  .mt-xl-62 {
    margin-top: 6.2rem !important; }
  .mt-xl-61 {
    margin-top: 6.1rem !important; }
  .mt-xl-60 {
    margin-top: 6rem !important; }
  .mt-xl-59 {
    margin-top: 5.9rem !important; }
  .mt-xl-58 {
    margin-top: 5.8rem !important; }
  .mt-xl-57 {
    margin-top: 5.7rem !important; }
  .mt-xl-56 {
    margin-top: 5.6rem !important; }
  .mt-xl-55 {
    margin-top: 5.5rem !important; }
  .mt-xl-54 {
    margin-top: 5.4rem !important; }
  .mt-xl-53 {
    margin-top: 5.3rem !important; }
  .mt-xl-52 {
    margin-top: 5.2rem !important; }
  .mt-xl-51 {
    margin-top: 5.1rem !important; }
  .mt-xl-50 {
    margin-top: 5rem !important; }
  .mt-xl-49 {
    margin-top: 4.9rem !important; }
  .mt-xl-48 {
    margin-top: 4.8rem !important; }
  .mt-xl-47 {
    margin-top: 4.7rem !important; }
  .mt-xl-46 {
    margin-top: 4.6rem !important; }
  .mt-xl-45 {
    margin-top: 4.5rem !important; }
  .mt-xl-44 {
    margin-top: 4.4rem !important; }
  .mt-xl-43 {
    margin-top: 4.3rem !important; }
  .mt-xl-42 {
    margin-top: 4.2rem !important; }
  .mt-xl-41 {
    margin-top: 4.1rem !important; }
  .mt-xl-40 {
    margin-top: 4rem !important; }
  .mt-xl-39 {
    margin-top: 3.9rem !important; }
  .mt-xl-38 {
    margin-top: 3.8rem !important; }
  .mt-xl-37 {
    margin-top: 3.7rem !important; }
  .mt-xl-36 {
    margin-top: 3.6rem !important; }
  .mt-xl-35 {
    margin-top: 3.5rem !important; }
  .mt-xl-34 {
    margin-top: 3.4rem !important; }
  .mt-xl-33 {
    margin-top: 3.3rem !important; }
  .mt-xl-32 {
    margin-top: 3.2rem !important; }
  .mt-xl-31 {
    margin-top: 3.1rem !important; }
  .mt-xl-30 {
    margin-top: 3rem !important; }
  .mt-xl-29 {
    margin-top: 2.9rem !important; }
  .mt-xl-28 {
    margin-top: 2.8rem !important; }
  .mt-xl-27 {
    margin-top: 2.7rem !important; }
  .mt-xl-26 {
    margin-top: 2.6rem !important; }
  .mt-xl-25 {
    margin-top: 2.5rem !important; }
  .mt-xl-24 {
    margin-top: 2.4rem !important; }
  .mt-xl-23 {
    margin-top: 2.3rem !important; }
  .mt-xl-22 {
    margin-top: 2.2rem !important; }
  .mt-xl-21 {
    margin-top: 2.1rem !important; }
  .mt-xl-20 {
    margin-top: 2rem !important; }
  .mt-xl-19 {
    margin-top: 1.9rem !important; }
  .mt-xl-18 {
    margin-top: 1.8rem !important; }
  .mt-xl-17 {
    margin-top: 1.7rem !important; }
  .mt-xl-16 {
    margin-top: 1.6rem !important; }
  .mt-xl-15 {
    margin-top: 1.5rem !important; }
  .mt-xl-14 {
    margin-top: 1.4rem !important; }
  .mt-xl-13 {
    margin-top: 1.3rem !important; }
  .mt-xl-12 {
    margin-top: 1.2rem !important; }
  .mt-xl-11 {
    margin-top: 1.1rem !important; }
  .mt-xl-10 {
    margin-top: 1rem !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-n80 {
    margin-top: -80px !important; }
  .mt-xl-n100 {
    margin-top: -100px !important; }
  .mt-xl-n150 {
    margin-top: -150px !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-150 {
    margin-right: 15rem !important; }
  .me-xl-149 {
    margin-right: 14.9rem !important; }
  .me-xl-148 {
    margin-right: 14.8rem !important; }
  .me-xl-147 {
    margin-right: 14.7rem !important; }
  .me-xl-146 {
    margin-right: 14.6rem !important; }
  .me-xl-145 {
    margin-right: 14.5rem !important; }
  .me-xl-144 {
    margin-right: 14.4rem !important; }
  .me-xl-143 {
    margin-right: 14.3rem !important; }
  .me-xl-142 {
    margin-right: 14.2rem !important; }
  .me-xl-141 {
    margin-right: 14.1rem !important; }
  .me-xl-140 {
    margin-right: 14rem !important; }
  .me-xl-139 {
    margin-right: 13.9rem !important; }
  .me-xl-138 {
    margin-right: 13.8rem !important; }
  .me-xl-137 {
    margin-right: 13.7rem !important; }
  .me-xl-136 {
    margin-right: 13.6rem !important; }
  .me-xl-135 {
    margin-right: 13.5rem !important; }
  .me-xl-134 {
    margin-right: 13.4rem !important; }
  .me-xl-133 {
    margin-right: 13.3rem !important; }
  .me-xl-132 {
    margin-right: 13.2rem !important; }
  .me-xl-131 {
    margin-right: 13.1rem !important; }
  .me-xl-130 {
    margin-right: 13rem !important; }
  .me-xl-129 {
    margin-right: 12.9rem !important; }
  .me-xl-128 {
    margin-right: 12.8rem !important; }
  .me-xl-127 {
    margin-right: 12.7rem !important; }
  .me-xl-126 {
    margin-right: 12.6rem !important; }
  .me-xl-125 {
    margin-right: 12.5rem !important; }
  .me-xl-124 {
    margin-right: 12.4rem !important; }
  .me-xl-123 {
    margin-right: 12.3rem !important; }
  .me-xl-122 {
    margin-right: 12.2rem !important; }
  .me-xl-121 {
    margin-right: 12.1rem !important; }
  .me-xl-120 {
    margin-right: 12rem !important; }
  .me-xl-119 {
    margin-right: 11.9rem !important; }
  .me-xl-118 {
    margin-right: 11.8rem !important; }
  .me-xl-117 {
    margin-right: 11.7rem !important; }
  .me-xl-116 {
    margin-right: 11.6rem !important; }
  .me-xl-115 {
    margin-right: 11.5rem !important; }
  .me-xl-114 {
    margin-right: 11.4rem !important; }
  .me-xl-113 {
    margin-right: 11.3rem !important; }
  .me-xl-112 {
    margin-right: 11.2rem !important; }
  .me-xl-111 {
    margin-right: 11.1rem !important; }
  .me-xl-110 {
    margin-right: 11rem !important; }
  .me-xl-109 {
    margin-right: 10.9rem !important; }
  .me-xl-108 {
    margin-right: 10.8rem !important; }
  .me-xl-107 {
    margin-right: 10.7rem !important; }
  .me-xl-106 {
    margin-right: 10.6rem !important; }
  .me-xl-105 {
    margin-right: 10.5rem !important; }
  .me-xl-104 {
    margin-right: 10.4rem !important; }
  .me-xl-103 {
    margin-right: 10.3rem !important; }
  .me-xl-102 {
    margin-right: 10.2rem !important; }
  .me-xl-101 {
    margin-right: 10.1rem !important; }
  .me-xl-100 {
    margin-right: 10rem !important; }
  .me-xl-99 {
    margin-right: 9.9rem !important; }
  .me-xl-98 {
    margin-right: 9.8rem !important; }
  .me-xl-97 {
    margin-right: 9.7rem !important; }
  .me-xl-96 {
    margin-right: 9.6rem !important; }
  .me-xl-95 {
    margin-right: 9.5rem !important; }
  .me-xl-94 {
    margin-right: 9.4rem !important; }
  .me-xl-93 {
    margin-right: 9.3rem !important; }
  .me-xl-92 {
    margin-right: 9.2rem !important; }
  .me-xl-91 {
    margin-right: 9.1rem !important; }
  .me-xl-90 {
    margin-right: 9rem !important; }
  .me-xl-89 {
    margin-right: 8.9rem !important; }
  .me-xl-88 {
    margin-right: 8.8rem !important; }
  .me-xl-87 {
    margin-right: 8.7rem !important; }
  .me-xl-86 {
    margin-right: 8.6rem !important; }
  .me-xl-85 {
    margin-right: 8.5rem !important; }
  .me-xl-84 {
    margin-right: 8.4rem !important; }
  .me-xl-83 {
    margin-right: 8.3rem !important; }
  .me-xl-82 {
    margin-right: 8.2rem !important; }
  .me-xl-81 {
    margin-right: 8.1rem !important; }
  .me-xl-80 {
    margin-right: 8rem !important; }
  .me-xl-79 {
    margin-right: 7.9rem !important; }
  .me-xl-78 {
    margin-right: 7.8rem !important; }
  .me-xl-77 {
    margin-right: 7.7rem !important; }
  .me-xl-76 {
    margin-right: 7.6rem !important; }
  .me-xl-75 {
    margin-right: 7.5rem !important; }
  .me-xl-74 {
    margin-right: 7.4rem !important; }
  .me-xl-73 {
    margin-right: 7.3rem !important; }
  .me-xl-72 {
    margin-right: 7.2rem !important; }
  .me-xl-71 {
    margin-right: 7.1rem !important; }
  .me-xl-70 {
    margin-right: 7rem !important; }
  .me-xl-69 {
    margin-right: 6.9rem !important; }
  .me-xl-68 {
    margin-right: 6.8rem !important; }
  .me-xl-67 {
    margin-right: 6.7rem !important; }
  .me-xl-66 {
    margin-right: 6.6rem !important; }
  .me-xl-65 {
    margin-right: 6.5rem !important; }
  .me-xl-64 {
    margin-right: 6.4rem !important; }
  .me-xl-63 {
    margin-right: 6.3rem !important; }
  .me-xl-62 {
    margin-right: 6.2rem !important; }
  .me-xl-61 {
    margin-right: 6.1rem !important; }
  .me-xl-60 {
    margin-right: 6rem !important; }
  .me-xl-59 {
    margin-right: 5.9rem !important; }
  .me-xl-58 {
    margin-right: 5.8rem !important; }
  .me-xl-57 {
    margin-right: 5.7rem !important; }
  .me-xl-56 {
    margin-right: 5.6rem !important; }
  .me-xl-55 {
    margin-right: 5.5rem !important; }
  .me-xl-54 {
    margin-right: 5.4rem !important; }
  .me-xl-53 {
    margin-right: 5.3rem !important; }
  .me-xl-52 {
    margin-right: 5.2rem !important; }
  .me-xl-51 {
    margin-right: 5.1rem !important; }
  .me-xl-50 {
    margin-right: 5rem !important; }
  .me-xl-49 {
    margin-right: 4.9rem !important; }
  .me-xl-48 {
    margin-right: 4.8rem !important; }
  .me-xl-47 {
    margin-right: 4.7rem !important; }
  .me-xl-46 {
    margin-right: 4.6rem !important; }
  .me-xl-45 {
    margin-right: 4.5rem !important; }
  .me-xl-44 {
    margin-right: 4.4rem !important; }
  .me-xl-43 {
    margin-right: 4.3rem !important; }
  .me-xl-42 {
    margin-right: 4.2rem !important; }
  .me-xl-41 {
    margin-right: 4.1rem !important; }
  .me-xl-40 {
    margin-right: 4rem !important; }
  .me-xl-39 {
    margin-right: 3.9rem !important; }
  .me-xl-38 {
    margin-right: 3.8rem !important; }
  .me-xl-37 {
    margin-right: 3.7rem !important; }
  .me-xl-36 {
    margin-right: 3.6rem !important; }
  .me-xl-35 {
    margin-right: 3.5rem !important; }
  .me-xl-34 {
    margin-right: 3.4rem !important; }
  .me-xl-33 {
    margin-right: 3.3rem !important; }
  .me-xl-32 {
    margin-right: 3.2rem !important; }
  .me-xl-31 {
    margin-right: 3.1rem !important; }
  .me-xl-30 {
    margin-right: 3rem !important; }
  .me-xl-29 {
    margin-right: 2.9rem !important; }
  .me-xl-28 {
    margin-right: 2.8rem !important; }
  .me-xl-27 {
    margin-right: 2.7rem !important; }
  .me-xl-26 {
    margin-right: 2.6rem !important; }
  .me-xl-25 {
    margin-right: 2.5rem !important; }
  .me-xl-24 {
    margin-right: 2.4rem !important; }
  .me-xl-23 {
    margin-right: 2.3rem !important; }
  .me-xl-22 {
    margin-right: 2.2rem !important; }
  .me-xl-21 {
    margin-right: 2.1rem !important; }
  .me-xl-20 {
    margin-right: 2rem !important; }
  .me-xl-19 {
    margin-right: 1.9rem !important; }
  .me-xl-18 {
    margin-right: 1.8rem !important; }
  .me-xl-17 {
    margin-right: 1.7rem !important; }
  .me-xl-16 {
    margin-right: 1.6rem !important; }
  .me-xl-15 {
    margin-right: 1.5rem !important; }
  .me-xl-14 {
    margin-right: 1.4rem !important; }
  .me-xl-13 {
    margin-right: 1.3rem !important; }
  .me-xl-12 {
    margin-right: 1.2rem !important; }
  .me-xl-11 {
    margin-right: 1.1rem !important; }
  .me-xl-10 {
    margin-right: 1rem !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-n80 {
    margin-right: -80px !important; }
  .me-xl-n100 {
    margin-right: -100px !important; }
  .me-xl-n150 {
    margin-right: -150px !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-150 {
    margin-bottom: 15rem !important; }
  .mb-xl-149 {
    margin-bottom: 14.9rem !important; }
  .mb-xl-148 {
    margin-bottom: 14.8rem !important; }
  .mb-xl-147 {
    margin-bottom: 14.7rem !important; }
  .mb-xl-146 {
    margin-bottom: 14.6rem !important; }
  .mb-xl-145 {
    margin-bottom: 14.5rem !important; }
  .mb-xl-144 {
    margin-bottom: 14.4rem !important; }
  .mb-xl-143 {
    margin-bottom: 14.3rem !important; }
  .mb-xl-142 {
    margin-bottom: 14.2rem !important; }
  .mb-xl-141 {
    margin-bottom: 14.1rem !important; }
  .mb-xl-140 {
    margin-bottom: 14rem !important; }
  .mb-xl-139 {
    margin-bottom: 13.9rem !important; }
  .mb-xl-138 {
    margin-bottom: 13.8rem !important; }
  .mb-xl-137 {
    margin-bottom: 13.7rem !important; }
  .mb-xl-136 {
    margin-bottom: 13.6rem !important; }
  .mb-xl-135 {
    margin-bottom: 13.5rem !important; }
  .mb-xl-134 {
    margin-bottom: 13.4rem !important; }
  .mb-xl-133 {
    margin-bottom: 13.3rem !important; }
  .mb-xl-132 {
    margin-bottom: 13.2rem !important; }
  .mb-xl-131 {
    margin-bottom: 13.1rem !important; }
  .mb-xl-130 {
    margin-bottom: 13rem !important; }
  .mb-xl-129 {
    margin-bottom: 12.9rem !important; }
  .mb-xl-128 {
    margin-bottom: 12.8rem !important; }
  .mb-xl-127 {
    margin-bottom: 12.7rem !important; }
  .mb-xl-126 {
    margin-bottom: 12.6rem !important; }
  .mb-xl-125 {
    margin-bottom: 12.5rem !important; }
  .mb-xl-124 {
    margin-bottom: 12.4rem !important; }
  .mb-xl-123 {
    margin-bottom: 12.3rem !important; }
  .mb-xl-122 {
    margin-bottom: 12.2rem !important; }
  .mb-xl-121 {
    margin-bottom: 12.1rem !important; }
  .mb-xl-120 {
    margin-bottom: 12rem !important; }
  .mb-xl-119 {
    margin-bottom: 11.9rem !important; }
  .mb-xl-118 {
    margin-bottom: 11.8rem !important; }
  .mb-xl-117 {
    margin-bottom: 11.7rem !important; }
  .mb-xl-116 {
    margin-bottom: 11.6rem !important; }
  .mb-xl-115 {
    margin-bottom: 11.5rem !important; }
  .mb-xl-114 {
    margin-bottom: 11.4rem !important; }
  .mb-xl-113 {
    margin-bottom: 11.3rem !important; }
  .mb-xl-112 {
    margin-bottom: 11.2rem !important; }
  .mb-xl-111 {
    margin-bottom: 11.1rem !important; }
  .mb-xl-110 {
    margin-bottom: 11rem !important; }
  .mb-xl-109 {
    margin-bottom: 10.9rem !important; }
  .mb-xl-108 {
    margin-bottom: 10.8rem !important; }
  .mb-xl-107 {
    margin-bottom: 10.7rem !important; }
  .mb-xl-106 {
    margin-bottom: 10.6rem !important; }
  .mb-xl-105 {
    margin-bottom: 10.5rem !important; }
  .mb-xl-104 {
    margin-bottom: 10.4rem !important; }
  .mb-xl-103 {
    margin-bottom: 10.3rem !important; }
  .mb-xl-102 {
    margin-bottom: 10.2rem !important; }
  .mb-xl-101 {
    margin-bottom: 10.1rem !important; }
  .mb-xl-100 {
    margin-bottom: 10rem !important; }
  .mb-xl-99 {
    margin-bottom: 9.9rem !important; }
  .mb-xl-98 {
    margin-bottom: 9.8rem !important; }
  .mb-xl-97 {
    margin-bottom: 9.7rem !important; }
  .mb-xl-96 {
    margin-bottom: 9.6rem !important; }
  .mb-xl-95 {
    margin-bottom: 9.5rem !important; }
  .mb-xl-94 {
    margin-bottom: 9.4rem !important; }
  .mb-xl-93 {
    margin-bottom: 9.3rem !important; }
  .mb-xl-92 {
    margin-bottom: 9.2rem !important; }
  .mb-xl-91 {
    margin-bottom: 9.1rem !important; }
  .mb-xl-90 {
    margin-bottom: 9rem !important; }
  .mb-xl-89 {
    margin-bottom: 8.9rem !important; }
  .mb-xl-88 {
    margin-bottom: 8.8rem !important; }
  .mb-xl-87 {
    margin-bottom: 8.7rem !important; }
  .mb-xl-86 {
    margin-bottom: 8.6rem !important; }
  .mb-xl-85 {
    margin-bottom: 8.5rem !important; }
  .mb-xl-84 {
    margin-bottom: 8.4rem !important; }
  .mb-xl-83 {
    margin-bottom: 8.3rem !important; }
  .mb-xl-82 {
    margin-bottom: 8.2rem !important; }
  .mb-xl-81 {
    margin-bottom: 8.1rem !important; }
  .mb-xl-80 {
    margin-bottom: 8rem !important; }
  .mb-xl-79 {
    margin-bottom: 7.9rem !important; }
  .mb-xl-78 {
    margin-bottom: 7.8rem !important; }
  .mb-xl-77 {
    margin-bottom: 7.7rem !important; }
  .mb-xl-76 {
    margin-bottom: 7.6rem !important; }
  .mb-xl-75 {
    margin-bottom: 7.5rem !important; }
  .mb-xl-74 {
    margin-bottom: 7.4rem !important; }
  .mb-xl-73 {
    margin-bottom: 7.3rem !important; }
  .mb-xl-72 {
    margin-bottom: 7.2rem !important; }
  .mb-xl-71 {
    margin-bottom: 7.1rem !important; }
  .mb-xl-70 {
    margin-bottom: 7rem !important; }
  .mb-xl-69 {
    margin-bottom: 6.9rem !important; }
  .mb-xl-68 {
    margin-bottom: 6.8rem !important; }
  .mb-xl-67 {
    margin-bottom: 6.7rem !important; }
  .mb-xl-66 {
    margin-bottom: 6.6rem !important; }
  .mb-xl-65 {
    margin-bottom: 6.5rem !important; }
  .mb-xl-64 {
    margin-bottom: 6.4rem !important; }
  .mb-xl-63 {
    margin-bottom: 6.3rem !important; }
  .mb-xl-62 {
    margin-bottom: 6.2rem !important; }
  .mb-xl-61 {
    margin-bottom: 6.1rem !important; }
  .mb-xl-60 {
    margin-bottom: 6rem !important; }
  .mb-xl-59 {
    margin-bottom: 5.9rem !important; }
  .mb-xl-58 {
    margin-bottom: 5.8rem !important; }
  .mb-xl-57 {
    margin-bottom: 5.7rem !important; }
  .mb-xl-56 {
    margin-bottom: 5.6rem !important; }
  .mb-xl-55 {
    margin-bottom: 5.5rem !important; }
  .mb-xl-54 {
    margin-bottom: 5.4rem !important; }
  .mb-xl-53 {
    margin-bottom: 5.3rem !important; }
  .mb-xl-52 {
    margin-bottom: 5.2rem !important; }
  .mb-xl-51 {
    margin-bottom: 5.1rem !important; }
  .mb-xl-50 {
    margin-bottom: 5rem !important; }
  .mb-xl-49 {
    margin-bottom: 4.9rem !important; }
  .mb-xl-48 {
    margin-bottom: 4.8rem !important; }
  .mb-xl-47 {
    margin-bottom: 4.7rem !important; }
  .mb-xl-46 {
    margin-bottom: 4.6rem !important; }
  .mb-xl-45 {
    margin-bottom: 4.5rem !important; }
  .mb-xl-44 {
    margin-bottom: 4.4rem !important; }
  .mb-xl-43 {
    margin-bottom: 4.3rem !important; }
  .mb-xl-42 {
    margin-bottom: 4.2rem !important; }
  .mb-xl-41 {
    margin-bottom: 4.1rem !important; }
  .mb-xl-40 {
    margin-bottom: 4rem !important; }
  .mb-xl-39 {
    margin-bottom: 3.9rem !important; }
  .mb-xl-38 {
    margin-bottom: 3.8rem !important; }
  .mb-xl-37 {
    margin-bottom: 3.7rem !important; }
  .mb-xl-36 {
    margin-bottom: 3.6rem !important; }
  .mb-xl-35 {
    margin-bottom: 3.5rem !important; }
  .mb-xl-34 {
    margin-bottom: 3.4rem !important; }
  .mb-xl-33 {
    margin-bottom: 3.3rem !important; }
  .mb-xl-32 {
    margin-bottom: 3.2rem !important; }
  .mb-xl-31 {
    margin-bottom: 3.1rem !important; }
  .mb-xl-30 {
    margin-bottom: 3rem !important; }
  .mb-xl-29 {
    margin-bottom: 2.9rem !important; }
  .mb-xl-28 {
    margin-bottom: 2.8rem !important; }
  .mb-xl-27 {
    margin-bottom: 2.7rem !important; }
  .mb-xl-26 {
    margin-bottom: 2.6rem !important; }
  .mb-xl-25 {
    margin-bottom: 2.5rem !important; }
  .mb-xl-24 {
    margin-bottom: 2.4rem !important; }
  .mb-xl-23 {
    margin-bottom: 2.3rem !important; }
  .mb-xl-22 {
    margin-bottom: 2.2rem !important; }
  .mb-xl-21 {
    margin-bottom: 2.1rem !important; }
  .mb-xl-20 {
    margin-bottom: 2rem !important; }
  .mb-xl-19 {
    margin-bottom: 1.9rem !important; }
  .mb-xl-18 {
    margin-bottom: 1.8rem !important; }
  .mb-xl-17 {
    margin-bottom: 1.7rem !important; }
  .mb-xl-16 {
    margin-bottom: 1.6rem !important; }
  .mb-xl-15 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-14 {
    margin-bottom: 1.4rem !important; }
  .mb-xl-13 {
    margin-bottom: 1.3rem !important; }
  .mb-xl-12 {
    margin-bottom: 1.2rem !important; }
  .mb-xl-11 {
    margin-bottom: 1.1rem !important; }
  .mb-xl-10 {
    margin-bottom: 1rem !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-n80 {
    margin-bottom: -80px !important; }
  .mb-xl-n100 {
    margin-bottom: -100px !important; }
  .mb-xl-n150 {
    margin-bottom: -150px !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-150 {
    margin-left: 15rem !important; }
  .ms-xl-149 {
    margin-left: 14.9rem !important; }
  .ms-xl-148 {
    margin-left: 14.8rem !important; }
  .ms-xl-147 {
    margin-left: 14.7rem !important; }
  .ms-xl-146 {
    margin-left: 14.6rem !important; }
  .ms-xl-145 {
    margin-left: 14.5rem !important; }
  .ms-xl-144 {
    margin-left: 14.4rem !important; }
  .ms-xl-143 {
    margin-left: 14.3rem !important; }
  .ms-xl-142 {
    margin-left: 14.2rem !important; }
  .ms-xl-141 {
    margin-left: 14.1rem !important; }
  .ms-xl-140 {
    margin-left: 14rem !important; }
  .ms-xl-139 {
    margin-left: 13.9rem !important; }
  .ms-xl-138 {
    margin-left: 13.8rem !important; }
  .ms-xl-137 {
    margin-left: 13.7rem !important; }
  .ms-xl-136 {
    margin-left: 13.6rem !important; }
  .ms-xl-135 {
    margin-left: 13.5rem !important; }
  .ms-xl-134 {
    margin-left: 13.4rem !important; }
  .ms-xl-133 {
    margin-left: 13.3rem !important; }
  .ms-xl-132 {
    margin-left: 13.2rem !important; }
  .ms-xl-131 {
    margin-left: 13.1rem !important; }
  .ms-xl-130 {
    margin-left: 13rem !important; }
  .ms-xl-129 {
    margin-left: 12.9rem !important; }
  .ms-xl-128 {
    margin-left: 12.8rem !important; }
  .ms-xl-127 {
    margin-left: 12.7rem !important; }
  .ms-xl-126 {
    margin-left: 12.6rem !important; }
  .ms-xl-125 {
    margin-left: 12.5rem !important; }
  .ms-xl-124 {
    margin-left: 12.4rem !important; }
  .ms-xl-123 {
    margin-left: 12.3rem !important; }
  .ms-xl-122 {
    margin-left: 12.2rem !important; }
  .ms-xl-121 {
    margin-left: 12.1rem !important; }
  .ms-xl-120 {
    margin-left: 12rem !important; }
  .ms-xl-119 {
    margin-left: 11.9rem !important; }
  .ms-xl-118 {
    margin-left: 11.8rem !important; }
  .ms-xl-117 {
    margin-left: 11.7rem !important; }
  .ms-xl-116 {
    margin-left: 11.6rem !important; }
  .ms-xl-115 {
    margin-left: 11.5rem !important; }
  .ms-xl-114 {
    margin-left: 11.4rem !important; }
  .ms-xl-113 {
    margin-left: 11.3rem !important; }
  .ms-xl-112 {
    margin-left: 11.2rem !important; }
  .ms-xl-111 {
    margin-left: 11.1rem !important; }
  .ms-xl-110 {
    margin-left: 11rem !important; }
  .ms-xl-109 {
    margin-left: 10.9rem !important; }
  .ms-xl-108 {
    margin-left: 10.8rem !important; }
  .ms-xl-107 {
    margin-left: 10.7rem !important; }
  .ms-xl-106 {
    margin-left: 10.6rem !important; }
  .ms-xl-105 {
    margin-left: 10.5rem !important; }
  .ms-xl-104 {
    margin-left: 10.4rem !important; }
  .ms-xl-103 {
    margin-left: 10.3rem !important; }
  .ms-xl-102 {
    margin-left: 10.2rem !important; }
  .ms-xl-101 {
    margin-left: 10.1rem !important; }
  .ms-xl-100 {
    margin-left: 10rem !important; }
  .ms-xl-99 {
    margin-left: 9.9rem !important; }
  .ms-xl-98 {
    margin-left: 9.8rem !important; }
  .ms-xl-97 {
    margin-left: 9.7rem !important; }
  .ms-xl-96 {
    margin-left: 9.6rem !important; }
  .ms-xl-95 {
    margin-left: 9.5rem !important; }
  .ms-xl-94 {
    margin-left: 9.4rem !important; }
  .ms-xl-93 {
    margin-left: 9.3rem !important; }
  .ms-xl-92 {
    margin-left: 9.2rem !important; }
  .ms-xl-91 {
    margin-left: 9.1rem !important; }
  .ms-xl-90 {
    margin-left: 9rem !important; }
  .ms-xl-89 {
    margin-left: 8.9rem !important; }
  .ms-xl-88 {
    margin-left: 8.8rem !important; }
  .ms-xl-87 {
    margin-left: 8.7rem !important; }
  .ms-xl-86 {
    margin-left: 8.6rem !important; }
  .ms-xl-85 {
    margin-left: 8.5rem !important; }
  .ms-xl-84 {
    margin-left: 8.4rem !important; }
  .ms-xl-83 {
    margin-left: 8.3rem !important; }
  .ms-xl-82 {
    margin-left: 8.2rem !important; }
  .ms-xl-81 {
    margin-left: 8.1rem !important; }
  .ms-xl-80 {
    margin-left: 8rem !important; }
  .ms-xl-79 {
    margin-left: 7.9rem !important; }
  .ms-xl-78 {
    margin-left: 7.8rem !important; }
  .ms-xl-77 {
    margin-left: 7.7rem !important; }
  .ms-xl-76 {
    margin-left: 7.6rem !important; }
  .ms-xl-75 {
    margin-left: 7.5rem !important; }
  .ms-xl-74 {
    margin-left: 7.4rem !important; }
  .ms-xl-73 {
    margin-left: 7.3rem !important; }
  .ms-xl-72 {
    margin-left: 7.2rem !important; }
  .ms-xl-71 {
    margin-left: 7.1rem !important; }
  .ms-xl-70 {
    margin-left: 7rem !important; }
  .ms-xl-69 {
    margin-left: 6.9rem !important; }
  .ms-xl-68 {
    margin-left: 6.8rem !important; }
  .ms-xl-67 {
    margin-left: 6.7rem !important; }
  .ms-xl-66 {
    margin-left: 6.6rem !important; }
  .ms-xl-65 {
    margin-left: 6.5rem !important; }
  .ms-xl-64 {
    margin-left: 6.4rem !important; }
  .ms-xl-63 {
    margin-left: 6.3rem !important; }
  .ms-xl-62 {
    margin-left: 6.2rem !important; }
  .ms-xl-61 {
    margin-left: 6.1rem !important; }
  .ms-xl-60 {
    margin-left: 6rem !important; }
  .ms-xl-59 {
    margin-left: 5.9rem !important; }
  .ms-xl-58 {
    margin-left: 5.8rem !important; }
  .ms-xl-57 {
    margin-left: 5.7rem !important; }
  .ms-xl-56 {
    margin-left: 5.6rem !important; }
  .ms-xl-55 {
    margin-left: 5.5rem !important; }
  .ms-xl-54 {
    margin-left: 5.4rem !important; }
  .ms-xl-53 {
    margin-left: 5.3rem !important; }
  .ms-xl-52 {
    margin-left: 5.2rem !important; }
  .ms-xl-51 {
    margin-left: 5.1rem !important; }
  .ms-xl-50 {
    margin-left: 5rem !important; }
  .ms-xl-49 {
    margin-left: 4.9rem !important; }
  .ms-xl-48 {
    margin-left: 4.8rem !important; }
  .ms-xl-47 {
    margin-left: 4.7rem !important; }
  .ms-xl-46 {
    margin-left: 4.6rem !important; }
  .ms-xl-45 {
    margin-left: 4.5rem !important; }
  .ms-xl-44 {
    margin-left: 4.4rem !important; }
  .ms-xl-43 {
    margin-left: 4.3rem !important; }
  .ms-xl-42 {
    margin-left: 4.2rem !important; }
  .ms-xl-41 {
    margin-left: 4.1rem !important; }
  .ms-xl-40 {
    margin-left: 4rem !important; }
  .ms-xl-39 {
    margin-left: 3.9rem !important; }
  .ms-xl-38 {
    margin-left: 3.8rem !important; }
  .ms-xl-37 {
    margin-left: 3.7rem !important; }
  .ms-xl-36 {
    margin-left: 3.6rem !important; }
  .ms-xl-35 {
    margin-left: 3.5rem !important; }
  .ms-xl-34 {
    margin-left: 3.4rem !important; }
  .ms-xl-33 {
    margin-left: 3.3rem !important; }
  .ms-xl-32 {
    margin-left: 3.2rem !important; }
  .ms-xl-31 {
    margin-left: 3.1rem !important; }
  .ms-xl-30 {
    margin-left: 3rem !important; }
  .ms-xl-29 {
    margin-left: 2.9rem !important; }
  .ms-xl-28 {
    margin-left: 2.8rem !important; }
  .ms-xl-27 {
    margin-left: 2.7rem !important; }
  .ms-xl-26 {
    margin-left: 2.6rem !important; }
  .ms-xl-25 {
    margin-left: 2.5rem !important; }
  .ms-xl-24 {
    margin-left: 2.4rem !important; }
  .ms-xl-23 {
    margin-left: 2.3rem !important; }
  .ms-xl-22 {
    margin-left: 2.2rem !important; }
  .ms-xl-21 {
    margin-left: 2.1rem !important; }
  .ms-xl-20 {
    margin-left: 2rem !important; }
  .ms-xl-19 {
    margin-left: 1.9rem !important; }
  .ms-xl-18 {
    margin-left: 1.8rem !important; }
  .ms-xl-17 {
    margin-left: 1.7rem !important; }
  .ms-xl-16 {
    margin-left: 1.6rem !important; }
  .ms-xl-15 {
    margin-left: 1.5rem !important; }
  .ms-xl-14 {
    margin-left: 1.4rem !important; }
  .ms-xl-13 {
    margin-left: 1.3rem !important; }
  .ms-xl-12 {
    margin-left: 1.2rem !important; }
  .ms-xl-11 {
    margin-left: 1.1rem !important; }
  .ms-xl-10 {
    margin-left: 1rem !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-n80 {
    margin-left: -80px !important; }
  .ms-xl-n100 {
    margin-left: -100px !important; }
  .ms-xl-n150 {
    margin-left: -150px !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-150 {
    padding: 15rem !important; }
  .p-xl-149 {
    padding: 14.9rem !important; }
  .p-xl-148 {
    padding: 14.8rem !important; }
  .p-xl-147 {
    padding: 14.7rem !important; }
  .p-xl-146 {
    padding: 14.6rem !important; }
  .p-xl-145 {
    padding: 14.5rem !important; }
  .p-xl-144 {
    padding: 14.4rem !important; }
  .p-xl-143 {
    padding: 14.3rem !important; }
  .p-xl-142 {
    padding: 14.2rem !important; }
  .p-xl-141 {
    padding: 14.1rem !important; }
  .p-xl-140 {
    padding: 14rem !important; }
  .p-xl-139 {
    padding: 13.9rem !important; }
  .p-xl-138 {
    padding: 13.8rem !important; }
  .p-xl-137 {
    padding: 13.7rem !important; }
  .p-xl-136 {
    padding: 13.6rem !important; }
  .p-xl-135 {
    padding: 13.5rem !important; }
  .p-xl-134 {
    padding: 13.4rem !important; }
  .p-xl-133 {
    padding: 13.3rem !important; }
  .p-xl-132 {
    padding: 13.2rem !important; }
  .p-xl-131 {
    padding: 13.1rem !important; }
  .p-xl-130 {
    padding: 13rem !important; }
  .p-xl-129 {
    padding: 12.9rem !important; }
  .p-xl-128 {
    padding: 12.8rem !important; }
  .p-xl-127 {
    padding: 12.7rem !important; }
  .p-xl-126 {
    padding: 12.6rem !important; }
  .p-xl-125 {
    padding: 12.5rem !important; }
  .p-xl-124 {
    padding: 12.4rem !important; }
  .p-xl-123 {
    padding: 12.3rem !important; }
  .p-xl-122 {
    padding: 12.2rem !important; }
  .p-xl-121 {
    padding: 12.1rem !important; }
  .p-xl-120 {
    padding: 12rem !important; }
  .p-xl-119 {
    padding: 11.9rem !important; }
  .p-xl-118 {
    padding: 11.8rem !important; }
  .p-xl-117 {
    padding: 11.7rem !important; }
  .p-xl-116 {
    padding: 11.6rem !important; }
  .p-xl-115 {
    padding: 11.5rem !important; }
  .p-xl-114 {
    padding: 11.4rem !important; }
  .p-xl-113 {
    padding: 11.3rem !important; }
  .p-xl-112 {
    padding: 11.2rem !important; }
  .p-xl-111 {
    padding: 11.1rem !important; }
  .p-xl-110 {
    padding: 11rem !important; }
  .p-xl-109 {
    padding: 10.9rem !important; }
  .p-xl-108 {
    padding: 10.8rem !important; }
  .p-xl-107 {
    padding: 10.7rem !important; }
  .p-xl-106 {
    padding: 10.6rem !important; }
  .p-xl-105 {
    padding: 10.5rem !important; }
  .p-xl-104 {
    padding: 10.4rem !important; }
  .p-xl-103 {
    padding: 10.3rem !important; }
  .p-xl-102 {
    padding: 10.2rem !important; }
  .p-xl-101 {
    padding: 10.1rem !important; }
  .p-xl-100 {
    padding: 10rem !important; }
  .p-xl-99 {
    padding: 9.9rem !important; }
  .p-xl-98 {
    padding: 9.8rem !important; }
  .p-xl-97 {
    padding: 9.7rem !important; }
  .p-xl-96 {
    padding: 9.6rem !important; }
  .p-xl-95 {
    padding: 9.5rem !important; }
  .p-xl-94 {
    padding: 9.4rem !important; }
  .p-xl-93 {
    padding: 9.3rem !important; }
  .p-xl-92 {
    padding: 9.2rem !important; }
  .p-xl-91 {
    padding: 9.1rem !important; }
  .p-xl-90 {
    padding: 9rem !important; }
  .p-xl-89 {
    padding: 8.9rem !important; }
  .p-xl-88 {
    padding: 8.8rem !important; }
  .p-xl-87 {
    padding: 8.7rem !important; }
  .p-xl-86 {
    padding: 8.6rem !important; }
  .p-xl-85 {
    padding: 8.5rem !important; }
  .p-xl-84 {
    padding: 8.4rem !important; }
  .p-xl-83 {
    padding: 8.3rem !important; }
  .p-xl-82 {
    padding: 8.2rem !important; }
  .p-xl-81 {
    padding: 8.1rem !important; }
  .p-xl-80 {
    padding: 8rem !important; }
  .p-xl-79 {
    padding: 7.9rem !important; }
  .p-xl-78 {
    padding: 7.8rem !important; }
  .p-xl-77 {
    padding: 7.7rem !important; }
  .p-xl-76 {
    padding: 7.6rem !important; }
  .p-xl-75 {
    padding: 7.5rem !important; }
  .p-xl-74 {
    padding: 7.4rem !important; }
  .p-xl-73 {
    padding: 7.3rem !important; }
  .p-xl-72 {
    padding: 7.2rem !important; }
  .p-xl-71 {
    padding: 7.1rem !important; }
  .p-xl-70 {
    padding: 7rem !important; }
  .p-xl-69 {
    padding: 6.9rem !important; }
  .p-xl-68 {
    padding: 6.8rem !important; }
  .p-xl-67 {
    padding: 6.7rem !important; }
  .p-xl-66 {
    padding: 6.6rem !important; }
  .p-xl-65 {
    padding: 6.5rem !important; }
  .p-xl-64 {
    padding: 6.4rem !important; }
  .p-xl-63 {
    padding: 6.3rem !important; }
  .p-xl-62 {
    padding: 6.2rem !important; }
  .p-xl-61 {
    padding: 6.1rem !important; }
  .p-xl-60 {
    padding: 6rem !important; }
  .p-xl-59 {
    padding: 5.9rem !important; }
  .p-xl-58 {
    padding: 5.8rem !important; }
  .p-xl-57 {
    padding: 5.7rem !important; }
  .p-xl-56 {
    padding: 5.6rem !important; }
  .p-xl-55 {
    padding: 5.5rem !important; }
  .p-xl-54 {
    padding: 5.4rem !important; }
  .p-xl-53 {
    padding: 5.3rem !important; }
  .p-xl-52 {
    padding: 5.2rem !important; }
  .p-xl-51 {
    padding: 5.1rem !important; }
  .p-xl-50 {
    padding: 5rem !important; }
  .p-xl-49 {
    padding: 4.9rem !important; }
  .p-xl-48 {
    padding: 4.8rem !important; }
  .p-xl-47 {
    padding: 4.7rem !important; }
  .p-xl-46 {
    padding: 4.6rem !important; }
  .p-xl-45 {
    padding: 4.5rem !important; }
  .p-xl-44 {
    padding: 4.4rem !important; }
  .p-xl-43 {
    padding: 4.3rem !important; }
  .p-xl-42 {
    padding: 4.2rem !important; }
  .p-xl-41 {
    padding: 4.1rem !important; }
  .p-xl-40 {
    padding: 4rem !important; }
  .p-xl-39 {
    padding: 3.9rem !important; }
  .p-xl-38 {
    padding: 3.8rem !important; }
  .p-xl-37 {
    padding: 3.7rem !important; }
  .p-xl-36 {
    padding: 3.6rem !important; }
  .p-xl-35 {
    padding: 3.5rem !important; }
  .p-xl-34 {
    padding: 3.4rem !important; }
  .p-xl-33 {
    padding: 3.3rem !important; }
  .p-xl-32 {
    padding: 3.2rem !important; }
  .p-xl-31 {
    padding: 3.1rem !important; }
  .p-xl-30 {
    padding: 3rem !important; }
  .p-xl-29 {
    padding: 2.9rem !important; }
  .p-xl-28 {
    padding: 2.8rem !important; }
  .p-xl-27 {
    padding: 2.7rem !important; }
  .p-xl-26 {
    padding: 2.6rem !important; }
  .p-xl-25 {
    padding: 2.5rem !important; }
  .p-xl-24 {
    padding: 2.4rem !important; }
  .p-xl-23 {
    padding: 2.3rem !important; }
  .p-xl-22 {
    padding: 2.2rem !important; }
  .p-xl-21 {
    padding: 2.1rem !important; }
  .p-xl-20 {
    padding: 2rem !important; }
  .p-xl-19 {
    padding: 1.9rem !important; }
  .p-xl-18 {
    padding: 1.8rem !important; }
  .p-xl-17 {
    padding: 1.7rem !important; }
  .p-xl-16 {
    padding: 1.6rem !important; }
  .p-xl-15 {
    padding: 1.5rem !important; }
  .p-xl-14 {
    padding: 1.4rem !important; }
  .p-xl-13 {
    padding: 1.3rem !important; }
  .p-xl-12 {
    padding: 1.2rem !important; }
  .p-xl-11 {
    padding: 1.1rem !important; }
  .p-xl-10 {
    padding: 1rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .p-xl-n80 {
    padding: -80px !important; }
  .p-xl-n100 {
    padding: -100px !important; }
  .p-xl-n150 {
    padding: -150px !important; }
  .px-xl-150 {
    padding-right: 15rem !important;
    padding-left: 15rem !important; }
  .px-xl-149 {
    padding-right: 14.9rem !important;
    padding-left: 14.9rem !important; }
  .px-xl-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-xl-147 {
    padding-right: 14.7rem !important;
    padding-left: 14.7rem !important; }
  .px-xl-146 {
    padding-right: 14.6rem !important;
    padding-left: 14.6rem !important; }
  .px-xl-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-xl-144 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important; }
  .px-xl-143 {
    padding-right: 14.3rem !important;
    padding-left: 14.3rem !important; }
  .px-xl-142 {
    padding-right: 14.2rem !important;
    padding-left: 14.2rem !important; }
  .px-xl-141 {
    padding-right: 14.1rem !important;
    padding-left: 14.1rem !important; }
  .px-xl-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-xl-139 {
    padding-right: 13.9rem !important;
    padding-left: 13.9rem !important; }
  .px-xl-138 {
    padding-right: 13.8rem !important;
    padding-left: 13.8rem !important; }
  .px-xl-137 {
    padding-right: 13.7rem !important;
    padding-left: 13.7rem !important; }
  .px-xl-136 {
    padding-right: 13.6rem !important;
    padding-left: 13.6rem !important; }
  .px-xl-135 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important; }
  .px-xl-134 {
    padding-right: 13.4rem !important;
    padding-left: 13.4rem !important; }
  .px-xl-133 {
    padding-right: 13.3rem !important;
    padding-left: 13.3rem !important; }
  .px-xl-132 {
    padding-right: 13.2rem !important;
    padding-left: 13.2rem !important; }
  .px-xl-131 {
    padding-right: 13.1rem !important;
    padding-left: 13.1rem !important; }
  .px-xl-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-xl-129 {
    padding-right: 12.9rem !important;
    padding-left: 12.9rem !important; }
  .px-xl-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-xl-127 {
    padding-right: 12.7rem !important;
    padding-left: 12.7rem !important; }
  .px-xl-126 {
    padding-right: 12.6rem !important;
    padding-left: 12.6rem !important; }
  .px-xl-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xl-124 {
    padding-right: 12.4rem !important;
    padding-left: 12.4rem !important; }
  .px-xl-123 {
    padding-right: 12.3rem !important;
    padding-left: 12.3rem !important; }
  .px-xl-122 {
    padding-right: 12.2rem !important;
    padding-left: 12.2rem !important; }
  .px-xl-121 {
    padding-right: 12.1rem !important;
    padding-left: 12.1rem !important; }
  .px-xl-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-xl-119 {
    padding-right: 11.9rem !important;
    padding-left: 11.9rem !important; }
  .px-xl-118 {
    padding-right: 11.8rem !important;
    padding-left: 11.8rem !important; }
  .px-xl-117 {
    padding-right: 11.7rem !important;
    padding-left: 11.7rem !important; }
  .px-xl-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-xl-115 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .px-xl-114 {
    padding-right: 11.4rem !important;
    padding-left: 11.4rem !important; }
  .px-xl-113 {
    padding-right: 11.3rem !important;
    padding-left: 11.3rem !important; }
  .px-xl-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .px-xl-111 {
    padding-right: 11.1rem !important;
    padding-left: 11.1rem !important; }
  .px-xl-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-xl-109 {
    padding-right: 10.9rem !important;
    padding-left: 10.9rem !important; }
  .px-xl-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-xl-107 {
    padding-right: 10.7rem !important;
    padding-left: 10.7rem !important; }
  .px-xl-106 {
    padding-right: 10.6rem !important;
    padding-left: 10.6rem !important; }
  .px-xl-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-xl-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-xl-103 {
    padding-right: 10.3rem !important;
    padding-left: 10.3rem !important; }
  .px-xl-102 {
    padding-right: 10.2rem !important;
    padding-left: 10.2rem !important; }
  .px-xl-101 {
    padding-right: 10.1rem !important;
    padding-left: 10.1rem !important; }
  .px-xl-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xl-99 {
    padding-right: 9.9rem !important;
    padding-left: 9.9rem !important; }
  .px-xl-98 {
    padding-right: 9.8rem !important;
    padding-left: 9.8rem !important; }
  .px-xl-97 {
    padding-right: 9.7rem !important;
    padding-left: 9.7rem !important; }
  .px-xl-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-xl-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-xl-94 {
    padding-right: 9.4rem !important;
    padding-left: 9.4rem !important; }
  .px-xl-93 {
    padding-right: 9.3rem !important;
    padding-left: 9.3rem !important; }
  .px-xl-92 {
    padding-right: 9.2rem !important;
    padding-left: 9.2rem !important; }
  .px-xl-91 {
    padding-right: 9.1rem !important;
    padding-left: 9.1rem !important; }
  .px-xl-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-xl-89 {
    padding-right: 8.9rem !important;
    padding-left: 8.9rem !important; }
  .px-xl-88 {
    padding-right: 8.8rem !important;
    padding-left: 8.8rem !important; }
  .px-xl-87 {
    padding-right: 8.7rem !important;
    padding-left: 8.7rem !important; }
  .px-xl-86 {
    padding-right: 8.6rem !important;
    padding-left: 8.6rem !important; }
  .px-xl-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-xl-84 {
    padding-right: 8.4rem !important;
    padding-left: 8.4rem !important; }
  .px-xl-83 {
    padding-right: 8.3rem !important;
    padding-left: 8.3rem !important; }
  .px-xl-82 {
    padding-right: 8.2rem !important;
    padding-left: 8.2rem !important; }
  .px-xl-81 {
    padding-right: 8.1rem !important;
    padding-left: 8.1rem !important; }
  .px-xl-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-xl-79 {
    padding-right: 7.9rem !important;
    padding-left: 7.9rem !important; }
  .px-xl-78 {
    padding-right: 7.8rem !important;
    padding-left: 7.8rem !important; }
  .px-xl-77 {
    padding-right: 7.7rem !important;
    padding-left: 7.7rem !important; }
  .px-xl-76 {
    padding-right: 7.6rem !important;
    padding-left: 7.6rem !important; }
  .px-xl-75 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xl-74 {
    padding-right: 7.4rem !important;
    padding-left: 7.4rem !important; }
  .px-xl-73 {
    padding-right: 7.3rem !important;
    padding-left: 7.3rem !important; }
  .px-xl-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-xl-71 {
    padding-right: 7.1rem !important;
    padding-left: 7.1rem !important; }
  .px-xl-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-xl-69 {
    padding-right: 6.9rem !important;
    padding-left: 6.9rem !important; }
  .px-xl-68 {
    padding-right: 6.8rem !important;
    padding-left: 6.8rem !important; }
  .px-xl-67 {
    padding-right: 6.7rem !important;
    padding-left: 6.7rem !important; }
  .px-xl-66 {
    padding-right: 6.6rem !important;
    padding-left: 6.6rem !important; }
  .px-xl-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-xl-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-xl-63 {
    padding-right: 6.3rem !important;
    padding-left: 6.3rem !important; }
  .px-xl-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-xl-61 {
    padding-right: 6.1rem !important;
    padding-left: 6.1rem !important; }
  .px-xl-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xl-59 {
    padding-right: 5.9rem !important;
    padding-left: 5.9rem !important; }
  .px-xl-58 {
    padding-right: 5.8rem !important;
    padding-left: 5.8rem !important; }
  .px-xl-57 {
    padding-right: 5.7rem !important;
    padding-left: 5.7rem !important; }
  .px-xl-56 {
    padding-right: 5.6rem !important;
    padding-left: 5.6rem !important; }
  .px-xl-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-xl-54 {
    padding-right: 5.4rem !important;
    padding-left: 5.4rem !important; }
  .px-xl-53 {
    padding-right: 5.3rem !important;
    padding-left: 5.3rem !important; }
  .px-xl-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-xl-51 {
    padding-right: 5.1rem !important;
    padding-left: 5.1rem !important; }
  .px-xl-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xl-49 {
    padding-right: 4.9rem !important;
    padding-left: 4.9rem !important; }
  .px-xl-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-xl-47 {
    padding-right: 4.7rem !important;
    padding-left: 4.7rem !important; }
  .px-xl-46 {
    padding-right: 4.6rem !important;
    padding-left: 4.6rem !important; }
  .px-xl-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-xl-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-xl-43 {
    padding-right: 4.3rem !important;
    padding-left: 4.3rem !important; }
  .px-xl-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-xl-41 {
    padding-right: 4.1rem !important;
    padding-left: 4.1rem !important; }
  .px-xl-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xl-39 {
    padding-right: 3.9rem !important;
    padding-left: 3.9rem !important; }
  .px-xl-38 {
    padding-right: 3.8rem !important;
    padding-left: 3.8rem !important; }
  .px-xl-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-xl-36 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important; }
  .px-xl-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xl-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-xl-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-xl-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-xl-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-xl-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-xl-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-xl-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-xl-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-xl-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xl-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-xl-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-xl-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-xl-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-xl-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xl-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-xl-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-xl-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-xl-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-xl-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-xl-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-xl-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-xl-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-xl-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-n80 {
    padding-right: -80px !important;
    padding-left: -80px !important; }
  .px-xl-n100 {
    padding-right: -100px !important;
    padding-left: -100px !important; }
  .px-xl-n150 {
    padding-right: -150px !important;
    padding-left: -150px !important; }
  .py-xl-150 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important; }
  .py-xl-149 {
    padding-top: 14.9rem !important;
    padding-bottom: 14.9rem !important; }
  .py-xl-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-xl-147 {
    padding-top: 14.7rem !important;
    padding-bottom: 14.7rem !important; }
  .py-xl-146 {
    padding-top: 14.6rem !important;
    padding-bottom: 14.6rem !important; }
  .py-xl-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-xl-144 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important; }
  .py-xl-143 {
    padding-top: 14.3rem !important;
    padding-bottom: 14.3rem !important; }
  .py-xl-142 {
    padding-top: 14.2rem !important;
    padding-bottom: 14.2rem !important; }
  .py-xl-141 {
    padding-top: 14.1rem !important;
    padding-bottom: 14.1rem !important; }
  .py-xl-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-xl-139 {
    padding-top: 13.9rem !important;
    padding-bottom: 13.9rem !important; }
  .py-xl-138 {
    padding-top: 13.8rem !important;
    padding-bottom: 13.8rem !important; }
  .py-xl-137 {
    padding-top: 13.7rem !important;
    padding-bottom: 13.7rem !important; }
  .py-xl-136 {
    padding-top: 13.6rem !important;
    padding-bottom: 13.6rem !important; }
  .py-xl-135 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important; }
  .py-xl-134 {
    padding-top: 13.4rem !important;
    padding-bottom: 13.4rem !important; }
  .py-xl-133 {
    padding-top: 13.3rem !important;
    padding-bottom: 13.3rem !important; }
  .py-xl-132 {
    padding-top: 13.2rem !important;
    padding-bottom: 13.2rem !important; }
  .py-xl-131 {
    padding-top: 13.1rem !important;
    padding-bottom: 13.1rem !important; }
  .py-xl-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-xl-129 {
    padding-top: 12.9rem !important;
    padding-bottom: 12.9rem !important; }
  .py-xl-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-xl-127 {
    padding-top: 12.7rem !important;
    padding-bottom: 12.7rem !important; }
  .py-xl-126 {
    padding-top: 12.6rem !important;
    padding-bottom: 12.6rem !important; }
  .py-xl-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xl-124 {
    padding-top: 12.4rem !important;
    padding-bottom: 12.4rem !important; }
  .py-xl-123 {
    padding-top: 12.3rem !important;
    padding-bottom: 12.3rem !important; }
  .py-xl-122 {
    padding-top: 12.2rem !important;
    padding-bottom: 12.2rem !important; }
  .py-xl-121 {
    padding-top: 12.1rem !important;
    padding-bottom: 12.1rem !important; }
  .py-xl-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-xl-119 {
    padding-top: 11.9rem !important;
    padding-bottom: 11.9rem !important; }
  .py-xl-118 {
    padding-top: 11.8rem !important;
    padding-bottom: 11.8rem !important; }
  .py-xl-117 {
    padding-top: 11.7rem !important;
    padding-bottom: 11.7rem !important; }
  .py-xl-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-xl-115 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .py-xl-114 {
    padding-top: 11.4rem !important;
    padding-bottom: 11.4rem !important; }
  .py-xl-113 {
    padding-top: 11.3rem !important;
    padding-bottom: 11.3rem !important; }
  .py-xl-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .py-xl-111 {
    padding-top: 11.1rem !important;
    padding-bottom: 11.1rem !important; }
  .py-xl-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-xl-109 {
    padding-top: 10.9rem !important;
    padding-bottom: 10.9rem !important; }
  .py-xl-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-xl-107 {
    padding-top: 10.7rem !important;
    padding-bottom: 10.7rem !important; }
  .py-xl-106 {
    padding-top: 10.6rem !important;
    padding-bottom: 10.6rem !important; }
  .py-xl-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-xl-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-xl-103 {
    padding-top: 10.3rem !important;
    padding-bottom: 10.3rem !important; }
  .py-xl-102 {
    padding-top: 10.2rem !important;
    padding-bottom: 10.2rem !important; }
  .py-xl-101 {
    padding-top: 10.1rem !important;
    padding-bottom: 10.1rem !important; }
  .py-xl-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xl-99 {
    padding-top: 9.9rem !important;
    padding-bottom: 9.9rem !important; }
  .py-xl-98 {
    padding-top: 9.8rem !important;
    padding-bottom: 9.8rem !important; }
  .py-xl-97 {
    padding-top: 9.7rem !important;
    padding-bottom: 9.7rem !important; }
  .py-xl-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-xl-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-xl-94 {
    padding-top: 9.4rem !important;
    padding-bottom: 9.4rem !important; }
  .py-xl-93 {
    padding-top: 9.3rem !important;
    padding-bottom: 9.3rem !important; }
  .py-xl-92 {
    padding-top: 9.2rem !important;
    padding-bottom: 9.2rem !important; }
  .py-xl-91 {
    padding-top: 9.1rem !important;
    padding-bottom: 9.1rem !important; }
  .py-xl-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-xl-89 {
    padding-top: 8.9rem !important;
    padding-bottom: 8.9rem !important; }
  .py-xl-88 {
    padding-top: 8.8rem !important;
    padding-bottom: 8.8rem !important; }
  .py-xl-87 {
    padding-top: 8.7rem !important;
    padding-bottom: 8.7rem !important; }
  .py-xl-86 {
    padding-top: 8.6rem !important;
    padding-bottom: 8.6rem !important; }
  .py-xl-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-xl-84 {
    padding-top: 8.4rem !important;
    padding-bottom: 8.4rem !important; }
  .py-xl-83 {
    padding-top: 8.3rem !important;
    padding-bottom: 8.3rem !important; }
  .py-xl-82 {
    padding-top: 8.2rem !important;
    padding-bottom: 8.2rem !important; }
  .py-xl-81 {
    padding-top: 8.1rem !important;
    padding-bottom: 8.1rem !important; }
  .py-xl-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-xl-79 {
    padding-top: 7.9rem !important;
    padding-bottom: 7.9rem !important; }
  .py-xl-78 {
    padding-top: 7.8rem !important;
    padding-bottom: 7.8rem !important; }
  .py-xl-77 {
    padding-top: 7.7rem !important;
    padding-bottom: 7.7rem !important; }
  .py-xl-76 {
    padding-top: 7.6rem !important;
    padding-bottom: 7.6rem !important; }
  .py-xl-75 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xl-74 {
    padding-top: 7.4rem !important;
    padding-bottom: 7.4rem !important; }
  .py-xl-73 {
    padding-top: 7.3rem !important;
    padding-bottom: 7.3rem !important; }
  .py-xl-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-xl-71 {
    padding-top: 7.1rem !important;
    padding-bottom: 7.1rem !important; }
  .py-xl-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-xl-69 {
    padding-top: 6.9rem !important;
    padding-bottom: 6.9rem !important; }
  .py-xl-68 {
    padding-top: 6.8rem !important;
    padding-bottom: 6.8rem !important; }
  .py-xl-67 {
    padding-top: 6.7rem !important;
    padding-bottom: 6.7rem !important; }
  .py-xl-66 {
    padding-top: 6.6rem !important;
    padding-bottom: 6.6rem !important; }
  .py-xl-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-xl-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-xl-63 {
    padding-top: 6.3rem !important;
    padding-bottom: 6.3rem !important; }
  .py-xl-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-xl-61 {
    padding-top: 6.1rem !important;
    padding-bottom: 6.1rem !important; }
  .py-xl-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xl-59 {
    padding-top: 5.9rem !important;
    padding-bottom: 5.9rem !important; }
  .py-xl-58 {
    padding-top: 5.8rem !important;
    padding-bottom: 5.8rem !important; }
  .py-xl-57 {
    padding-top: 5.7rem !important;
    padding-bottom: 5.7rem !important; }
  .py-xl-56 {
    padding-top: 5.6rem !important;
    padding-bottom: 5.6rem !important; }
  .py-xl-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-xl-54 {
    padding-top: 5.4rem !important;
    padding-bottom: 5.4rem !important; }
  .py-xl-53 {
    padding-top: 5.3rem !important;
    padding-bottom: 5.3rem !important; }
  .py-xl-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-xl-51 {
    padding-top: 5.1rem !important;
    padding-bottom: 5.1rem !important; }
  .py-xl-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xl-49 {
    padding-top: 4.9rem !important;
    padding-bottom: 4.9rem !important; }
  .py-xl-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-xl-47 {
    padding-top: 4.7rem !important;
    padding-bottom: 4.7rem !important; }
  .py-xl-46 {
    padding-top: 4.6rem !important;
    padding-bottom: 4.6rem !important; }
  .py-xl-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-xl-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-xl-43 {
    padding-top: 4.3rem !important;
    padding-bottom: 4.3rem !important; }
  .py-xl-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-xl-41 {
    padding-top: 4.1rem !important;
    padding-bottom: 4.1rem !important; }
  .py-xl-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xl-39 {
    padding-top: 3.9rem !important;
    padding-bottom: 3.9rem !important; }
  .py-xl-38 {
    padding-top: 3.8rem !important;
    padding-bottom: 3.8rem !important; }
  .py-xl-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-xl-36 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important; }
  .py-xl-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xl-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-xl-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-xl-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-xl-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-xl-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-xl-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-xl-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-xl-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-xl-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xl-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-xl-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-xl-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-xl-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-xl-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xl-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-xl-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-xl-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-xl-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-xl-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-xl-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-xl-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-xl-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-xl-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-n80 {
    padding-top: -80px !important;
    padding-bottom: -80px !important; }
  .py-xl-n100 {
    padding-top: -100px !important;
    padding-bottom: -100px !important; }
  .py-xl-n150 {
    padding-top: -150px !important;
    padding-bottom: -150px !important; }
  .pt-xl-150 {
    padding-top: 15rem !important; }
  .pt-xl-149 {
    padding-top: 14.9rem !important; }
  .pt-xl-148 {
    padding-top: 14.8rem !important; }
  .pt-xl-147 {
    padding-top: 14.7rem !important; }
  .pt-xl-146 {
    padding-top: 14.6rem !important; }
  .pt-xl-145 {
    padding-top: 14.5rem !important; }
  .pt-xl-144 {
    padding-top: 14.4rem !important; }
  .pt-xl-143 {
    padding-top: 14.3rem !important; }
  .pt-xl-142 {
    padding-top: 14.2rem !important; }
  .pt-xl-141 {
    padding-top: 14.1rem !important; }
  .pt-xl-140 {
    padding-top: 14rem !important; }
  .pt-xl-139 {
    padding-top: 13.9rem !important; }
  .pt-xl-138 {
    padding-top: 13.8rem !important; }
  .pt-xl-137 {
    padding-top: 13.7rem !important; }
  .pt-xl-136 {
    padding-top: 13.6rem !important; }
  .pt-xl-135 {
    padding-top: 13.5rem !important; }
  .pt-xl-134 {
    padding-top: 13.4rem !important; }
  .pt-xl-133 {
    padding-top: 13.3rem !important; }
  .pt-xl-132 {
    padding-top: 13.2rem !important; }
  .pt-xl-131 {
    padding-top: 13.1rem !important; }
  .pt-xl-130 {
    padding-top: 13rem !important; }
  .pt-xl-129 {
    padding-top: 12.9rem !important; }
  .pt-xl-128 {
    padding-top: 12.8rem !important; }
  .pt-xl-127 {
    padding-top: 12.7rem !important; }
  .pt-xl-126 {
    padding-top: 12.6rem !important; }
  .pt-xl-125 {
    padding-top: 12.5rem !important; }
  .pt-xl-124 {
    padding-top: 12.4rem !important; }
  .pt-xl-123 {
    padding-top: 12.3rem !important; }
  .pt-xl-122 {
    padding-top: 12.2rem !important; }
  .pt-xl-121 {
    padding-top: 12.1rem !important; }
  .pt-xl-120 {
    padding-top: 12rem !important; }
  .pt-xl-119 {
    padding-top: 11.9rem !important; }
  .pt-xl-118 {
    padding-top: 11.8rem !important; }
  .pt-xl-117 {
    padding-top: 11.7rem !important; }
  .pt-xl-116 {
    padding-top: 11.6rem !important; }
  .pt-xl-115 {
    padding-top: 11.5rem !important; }
  .pt-xl-114 {
    padding-top: 11.4rem !important; }
  .pt-xl-113 {
    padding-top: 11.3rem !important; }
  .pt-xl-112 {
    padding-top: 11.2rem !important; }
  .pt-xl-111 {
    padding-top: 11.1rem !important; }
  .pt-xl-110 {
    padding-top: 11rem !important; }
  .pt-xl-109 {
    padding-top: 10.9rem !important; }
  .pt-xl-108 {
    padding-top: 10.8rem !important; }
  .pt-xl-107 {
    padding-top: 10.7rem !important; }
  .pt-xl-106 {
    padding-top: 10.6rem !important; }
  .pt-xl-105 {
    padding-top: 10.5rem !important; }
  .pt-xl-104 {
    padding-top: 10.4rem !important; }
  .pt-xl-103 {
    padding-top: 10.3rem !important; }
  .pt-xl-102 {
    padding-top: 10.2rem !important; }
  .pt-xl-101 {
    padding-top: 10.1rem !important; }
  .pt-xl-100 {
    padding-top: 10rem !important; }
  .pt-xl-99 {
    padding-top: 9.9rem !important; }
  .pt-xl-98 {
    padding-top: 9.8rem !important; }
  .pt-xl-97 {
    padding-top: 9.7rem !important; }
  .pt-xl-96 {
    padding-top: 9.6rem !important; }
  .pt-xl-95 {
    padding-top: 9.5rem !important; }
  .pt-xl-94 {
    padding-top: 9.4rem !important; }
  .pt-xl-93 {
    padding-top: 9.3rem !important; }
  .pt-xl-92 {
    padding-top: 9.2rem !important; }
  .pt-xl-91 {
    padding-top: 9.1rem !important; }
  .pt-xl-90 {
    padding-top: 9rem !important; }
  .pt-xl-89 {
    padding-top: 8.9rem !important; }
  .pt-xl-88 {
    padding-top: 8.8rem !important; }
  .pt-xl-87 {
    padding-top: 8.7rem !important; }
  .pt-xl-86 {
    padding-top: 8.6rem !important; }
  .pt-xl-85 {
    padding-top: 8.5rem !important; }
  .pt-xl-84 {
    padding-top: 8.4rem !important; }
  .pt-xl-83 {
    padding-top: 8.3rem !important; }
  .pt-xl-82 {
    padding-top: 8.2rem !important; }
  .pt-xl-81 {
    padding-top: 8.1rem !important; }
  .pt-xl-80 {
    padding-top: 8rem !important; }
  .pt-xl-79 {
    padding-top: 7.9rem !important; }
  .pt-xl-78 {
    padding-top: 7.8rem !important; }
  .pt-xl-77 {
    padding-top: 7.7rem !important; }
  .pt-xl-76 {
    padding-top: 7.6rem !important; }
  .pt-xl-75 {
    padding-top: 7.5rem !important; }
  .pt-xl-74 {
    padding-top: 7.4rem !important; }
  .pt-xl-73 {
    padding-top: 7.3rem !important; }
  .pt-xl-72 {
    padding-top: 7.2rem !important; }
  .pt-xl-71 {
    padding-top: 7.1rem !important; }
  .pt-xl-70 {
    padding-top: 7rem !important; }
  .pt-xl-69 {
    padding-top: 6.9rem !important; }
  .pt-xl-68 {
    padding-top: 6.8rem !important; }
  .pt-xl-67 {
    padding-top: 6.7rem !important; }
  .pt-xl-66 {
    padding-top: 6.6rem !important; }
  .pt-xl-65 {
    padding-top: 6.5rem !important; }
  .pt-xl-64 {
    padding-top: 6.4rem !important; }
  .pt-xl-63 {
    padding-top: 6.3rem !important; }
  .pt-xl-62 {
    padding-top: 6.2rem !important; }
  .pt-xl-61 {
    padding-top: 6.1rem !important; }
  .pt-xl-60 {
    padding-top: 6rem !important; }
  .pt-xl-59 {
    padding-top: 5.9rem !important; }
  .pt-xl-58 {
    padding-top: 5.8rem !important; }
  .pt-xl-57 {
    padding-top: 5.7rem !important; }
  .pt-xl-56 {
    padding-top: 5.6rem !important; }
  .pt-xl-55 {
    padding-top: 5.5rem !important; }
  .pt-xl-54 {
    padding-top: 5.4rem !important; }
  .pt-xl-53 {
    padding-top: 5.3rem !important; }
  .pt-xl-52 {
    padding-top: 5.2rem !important; }
  .pt-xl-51 {
    padding-top: 5.1rem !important; }
  .pt-xl-50 {
    padding-top: 5rem !important; }
  .pt-xl-49 {
    padding-top: 4.9rem !important; }
  .pt-xl-48 {
    padding-top: 4.8rem !important; }
  .pt-xl-47 {
    padding-top: 4.7rem !important; }
  .pt-xl-46 {
    padding-top: 4.6rem !important; }
  .pt-xl-45 {
    padding-top: 4.5rem !important; }
  .pt-xl-44 {
    padding-top: 4.4rem !important; }
  .pt-xl-43 {
    padding-top: 4.3rem !important; }
  .pt-xl-42 {
    padding-top: 4.2rem !important; }
  .pt-xl-41 {
    padding-top: 4.1rem !important; }
  .pt-xl-40 {
    padding-top: 4rem !important; }
  .pt-xl-39 {
    padding-top: 3.9rem !important; }
  .pt-xl-38 {
    padding-top: 3.8rem !important; }
  .pt-xl-37 {
    padding-top: 3.7rem !important; }
  .pt-xl-36 {
    padding-top: 3.6rem !important; }
  .pt-xl-35 {
    padding-top: 3.5rem !important; }
  .pt-xl-34 {
    padding-top: 3.4rem !important; }
  .pt-xl-33 {
    padding-top: 3.3rem !important; }
  .pt-xl-32 {
    padding-top: 3.2rem !important; }
  .pt-xl-31 {
    padding-top: 3.1rem !important; }
  .pt-xl-30 {
    padding-top: 3rem !important; }
  .pt-xl-29 {
    padding-top: 2.9rem !important; }
  .pt-xl-28 {
    padding-top: 2.8rem !important; }
  .pt-xl-27 {
    padding-top: 2.7rem !important; }
  .pt-xl-26 {
    padding-top: 2.6rem !important; }
  .pt-xl-25 {
    padding-top: 2.5rem !important; }
  .pt-xl-24 {
    padding-top: 2.4rem !important; }
  .pt-xl-23 {
    padding-top: 2.3rem !important; }
  .pt-xl-22 {
    padding-top: 2.2rem !important; }
  .pt-xl-21 {
    padding-top: 2.1rem !important; }
  .pt-xl-20 {
    padding-top: 2rem !important; }
  .pt-xl-19 {
    padding-top: 1.9rem !important; }
  .pt-xl-18 {
    padding-top: 1.8rem !important; }
  .pt-xl-17 {
    padding-top: 1.7rem !important; }
  .pt-xl-16 {
    padding-top: 1.6rem !important; }
  .pt-xl-15 {
    padding-top: 1.5rem !important; }
  .pt-xl-14 {
    padding-top: 1.4rem !important; }
  .pt-xl-13 {
    padding-top: 1.3rem !important; }
  .pt-xl-12 {
    padding-top: 1.2rem !important; }
  .pt-xl-11 {
    padding-top: 1.1rem !important; }
  .pt-xl-10 {
    padding-top: 1rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pt-xl-n80 {
    padding-top: -80px !important; }
  .pt-xl-n100 {
    padding-top: -100px !important; }
  .pt-xl-n150 {
    padding-top: -150px !important; }
  .pe-xl-150 {
    padding-right: 15rem !important; }
  .pe-xl-149 {
    padding-right: 14.9rem !important; }
  .pe-xl-148 {
    padding-right: 14.8rem !important; }
  .pe-xl-147 {
    padding-right: 14.7rem !important; }
  .pe-xl-146 {
    padding-right: 14.6rem !important; }
  .pe-xl-145 {
    padding-right: 14.5rem !important; }
  .pe-xl-144 {
    padding-right: 14.4rem !important; }
  .pe-xl-143 {
    padding-right: 14.3rem !important; }
  .pe-xl-142 {
    padding-right: 14.2rem !important; }
  .pe-xl-141 {
    padding-right: 14.1rem !important; }
  .pe-xl-140 {
    padding-right: 14rem !important; }
  .pe-xl-139 {
    padding-right: 13.9rem !important; }
  .pe-xl-138 {
    padding-right: 13.8rem !important; }
  .pe-xl-137 {
    padding-right: 13.7rem !important; }
  .pe-xl-136 {
    padding-right: 13.6rem !important; }
  .pe-xl-135 {
    padding-right: 13.5rem !important; }
  .pe-xl-134 {
    padding-right: 13.4rem !important; }
  .pe-xl-133 {
    padding-right: 13.3rem !important; }
  .pe-xl-132 {
    padding-right: 13.2rem !important; }
  .pe-xl-131 {
    padding-right: 13.1rem !important; }
  .pe-xl-130 {
    padding-right: 13rem !important; }
  .pe-xl-129 {
    padding-right: 12.9rem !important; }
  .pe-xl-128 {
    padding-right: 12.8rem !important; }
  .pe-xl-127 {
    padding-right: 12.7rem !important; }
  .pe-xl-126 {
    padding-right: 12.6rem !important; }
  .pe-xl-125 {
    padding-right: 12.5rem !important; }
  .pe-xl-124 {
    padding-right: 12.4rem !important; }
  .pe-xl-123 {
    padding-right: 12.3rem !important; }
  .pe-xl-122 {
    padding-right: 12.2rem !important; }
  .pe-xl-121 {
    padding-right: 12.1rem !important; }
  .pe-xl-120 {
    padding-right: 12rem !important; }
  .pe-xl-119 {
    padding-right: 11.9rem !important; }
  .pe-xl-118 {
    padding-right: 11.8rem !important; }
  .pe-xl-117 {
    padding-right: 11.7rem !important; }
  .pe-xl-116 {
    padding-right: 11.6rem !important; }
  .pe-xl-115 {
    padding-right: 11.5rem !important; }
  .pe-xl-114 {
    padding-right: 11.4rem !important; }
  .pe-xl-113 {
    padding-right: 11.3rem !important; }
  .pe-xl-112 {
    padding-right: 11.2rem !important; }
  .pe-xl-111 {
    padding-right: 11.1rem !important; }
  .pe-xl-110 {
    padding-right: 11rem !important; }
  .pe-xl-109 {
    padding-right: 10.9rem !important; }
  .pe-xl-108 {
    padding-right: 10.8rem !important; }
  .pe-xl-107 {
    padding-right: 10.7rem !important; }
  .pe-xl-106 {
    padding-right: 10.6rem !important; }
  .pe-xl-105 {
    padding-right: 10.5rem !important; }
  .pe-xl-104 {
    padding-right: 10.4rem !important; }
  .pe-xl-103 {
    padding-right: 10.3rem !important; }
  .pe-xl-102 {
    padding-right: 10.2rem !important; }
  .pe-xl-101 {
    padding-right: 10.1rem !important; }
  .pe-xl-100 {
    padding-right: 10rem !important; }
  .pe-xl-99 {
    padding-right: 9.9rem !important; }
  .pe-xl-98 {
    padding-right: 9.8rem !important; }
  .pe-xl-97 {
    padding-right: 9.7rem !important; }
  .pe-xl-96 {
    padding-right: 9.6rem !important; }
  .pe-xl-95 {
    padding-right: 9.5rem !important; }
  .pe-xl-94 {
    padding-right: 9.4rem !important; }
  .pe-xl-93 {
    padding-right: 9.3rem !important; }
  .pe-xl-92 {
    padding-right: 9.2rem !important; }
  .pe-xl-91 {
    padding-right: 9.1rem !important; }
  .pe-xl-90 {
    padding-right: 9rem !important; }
  .pe-xl-89 {
    padding-right: 8.9rem !important; }
  .pe-xl-88 {
    padding-right: 8.8rem !important; }
  .pe-xl-87 {
    padding-right: 8.7rem !important; }
  .pe-xl-86 {
    padding-right: 8.6rem !important; }
  .pe-xl-85 {
    padding-right: 8.5rem !important; }
  .pe-xl-84 {
    padding-right: 8.4rem !important; }
  .pe-xl-83 {
    padding-right: 8.3rem !important; }
  .pe-xl-82 {
    padding-right: 8.2rem !important; }
  .pe-xl-81 {
    padding-right: 8.1rem !important; }
  .pe-xl-80 {
    padding-right: 8rem !important; }
  .pe-xl-79 {
    padding-right: 7.9rem !important; }
  .pe-xl-78 {
    padding-right: 7.8rem !important; }
  .pe-xl-77 {
    padding-right: 7.7rem !important; }
  .pe-xl-76 {
    padding-right: 7.6rem !important; }
  .pe-xl-75 {
    padding-right: 7.5rem !important; }
  .pe-xl-74 {
    padding-right: 7.4rem !important; }
  .pe-xl-73 {
    padding-right: 7.3rem !important; }
  .pe-xl-72 {
    padding-right: 7.2rem !important; }
  .pe-xl-71 {
    padding-right: 7.1rem !important; }
  .pe-xl-70 {
    padding-right: 7rem !important; }
  .pe-xl-69 {
    padding-right: 6.9rem !important; }
  .pe-xl-68 {
    padding-right: 6.8rem !important; }
  .pe-xl-67 {
    padding-right: 6.7rem !important; }
  .pe-xl-66 {
    padding-right: 6.6rem !important; }
  .pe-xl-65 {
    padding-right: 6.5rem !important; }
  .pe-xl-64 {
    padding-right: 6.4rem !important; }
  .pe-xl-63 {
    padding-right: 6.3rem !important; }
  .pe-xl-62 {
    padding-right: 6.2rem !important; }
  .pe-xl-61 {
    padding-right: 6.1rem !important; }
  .pe-xl-60 {
    padding-right: 6rem !important; }
  .pe-xl-59 {
    padding-right: 5.9rem !important; }
  .pe-xl-58 {
    padding-right: 5.8rem !important; }
  .pe-xl-57 {
    padding-right: 5.7rem !important; }
  .pe-xl-56 {
    padding-right: 5.6rem !important; }
  .pe-xl-55 {
    padding-right: 5.5rem !important; }
  .pe-xl-54 {
    padding-right: 5.4rem !important; }
  .pe-xl-53 {
    padding-right: 5.3rem !important; }
  .pe-xl-52 {
    padding-right: 5.2rem !important; }
  .pe-xl-51 {
    padding-right: 5.1rem !important; }
  .pe-xl-50 {
    padding-right: 5rem !important; }
  .pe-xl-49 {
    padding-right: 4.9rem !important; }
  .pe-xl-48 {
    padding-right: 4.8rem !important; }
  .pe-xl-47 {
    padding-right: 4.7rem !important; }
  .pe-xl-46 {
    padding-right: 4.6rem !important; }
  .pe-xl-45 {
    padding-right: 4.5rem !important; }
  .pe-xl-44 {
    padding-right: 4.4rem !important; }
  .pe-xl-43 {
    padding-right: 4.3rem !important; }
  .pe-xl-42 {
    padding-right: 4.2rem !important; }
  .pe-xl-41 {
    padding-right: 4.1rem !important; }
  .pe-xl-40 {
    padding-right: 4rem !important; }
  .pe-xl-39 {
    padding-right: 3.9rem !important; }
  .pe-xl-38 {
    padding-right: 3.8rem !important; }
  .pe-xl-37 {
    padding-right: 3.7rem !important; }
  .pe-xl-36 {
    padding-right: 3.6rem !important; }
  .pe-xl-35 {
    padding-right: 3.5rem !important; }
  .pe-xl-34 {
    padding-right: 3.4rem !important; }
  .pe-xl-33 {
    padding-right: 3.3rem !important; }
  .pe-xl-32 {
    padding-right: 3.2rem !important; }
  .pe-xl-31 {
    padding-right: 3.1rem !important; }
  .pe-xl-30 {
    padding-right: 3rem !important; }
  .pe-xl-29 {
    padding-right: 2.9rem !important; }
  .pe-xl-28 {
    padding-right: 2.8rem !important; }
  .pe-xl-27 {
    padding-right: 2.7rem !important; }
  .pe-xl-26 {
    padding-right: 2.6rem !important; }
  .pe-xl-25 {
    padding-right: 2.5rem !important; }
  .pe-xl-24 {
    padding-right: 2.4rem !important; }
  .pe-xl-23 {
    padding-right: 2.3rem !important; }
  .pe-xl-22 {
    padding-right: 2.2rem !important; }
  .pe-xl-21 {
    padding-right: 2.1rem !important; }
  .pe-xl-20 {
    padding-right: 2rem !important; }
  .pe-xl-19 {
    padding-right: 1.9rem !important; }
  .pe-xl-18 {
    padding-right: 1.8rem !important; }
  .pe-xl-17 {
    padding-right: 1.7rem !important; }
  .pe-xl-16 {
    padding-right: 1.6rem !important; }
  .pe-xl-15 {
    padding-right: 1.5rem !important; }
  .pe-xl-14 {
    padding-right: 1.4rem !important; }
  .pe-xl-13 {
    padding-right: 1.3rem !important; }
  .pe-xl-12 {
    padding-right: 1.2rem !important; }
  .pe-xl-11 {
    padding-right: 1.1rem !important; }
  .pe-xl-10 {
    padding-right: 1rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pe-xl-n80 {
    padding-right: -80px !important; }
  .pe-xl-n100 {
    padding-right: -100px !important; }
  .pe-xl-n150 {
    padding-right: -150px !important; }
  .pb-xl-150 {
    padding-bottom: 15rem !important; }
  .pb-xl-149 {
    padding-bottom: 14.9rem !important; }
  .pb-xl-148 {
    padding-bottom: 14.8rem !important; }
  .pb-xl-147 {
    padding-bottom: 14.7rem !important; }
  .pb-xl-146 {
    padding-bottom: 14.6rem !important; }
  .pb-xl-145 {
    padding-bottom: 14.5rem !important; }
  .pb-xl-144 {
    padding-bottom: 14.4rem !important; }
  .pb-xl-143 {
    padding-bottom: 14.3rem !important; }
  .pb-xl-142 {
    padding-bottom: 14.2rem !important; }
  .pb-xl-141 {
    padding-bottom: 14.1rem !important; }
  .pb-xl-140 {
    padding-bottom: 14rem !important; }
  .pb-xl-139 {
    padding-bottom: 13.9rem !important; }
  .pb-xl-138 {
    padding-bottom: 13.8rem !important; }
  .pb-xl-137 {
    padding-bottom: 13.7rem !important; }
  .pb-xl-136 {
    padding-bottom: 13.6rem !important; }
  .pb-xl-135 {
    padding-bottom: 13.5rem !important; }
  .pb-xl-134 {
    padding-bottom: 13.4rem !important; }
  .pb-xl-133 {
    padding-bottom: 13.3rem !important; }
  .pb-xl-132 {
    padding-bottom: 13.2rem !important; }
  .pb-xl-131 {
    padding-bottom: 13.1rem !important; }
  .pb-xl-130 {
    padding-bottom: 13rem !important; }
  .pb-xl-129 {
    padding-bottom: 12.9rem !important; }
  .pb-xl-128 {
    padding-bottom: 12.8rem !important; }
  .pb-xl-127 {
    padding-bottom: 12.7rem !important; }
  .pb-xl-126 {
    padding-bottom: 12.6rem !important; }
  .pb-xl-125 {
    padding-bottom: 12.5rem !important; }
  .pb-xl-124 {
    padding-bottom: 12.4rem !important; }
  .pb-xl-123 {
    padding-bottom: 12.3rem !important; }
  .pb-xl-122 {
    padding-bottom: 12.2rem !important; }
  .pb-xl-121 {
    padding-bottom: 12.1rem !important; }
  .pb-xl-120 {
    padding-bottom: 12rem !important; }
  .pb-xl-119 {
    padding-bottom: 11.9rem !important; }
  .pb-xl-118 {
    padding-bottom: 11.8rem !important; }
  .pb-xl-117 {
    padding-bottom: 11.7rem !important; }
  .pb-xl-116 {
    padding-bottom: 11.6rem !important; }
  .pb-xl-115 {
    padding-bottom: 11.5rem !important; }
  .pb-xl-114 {
    padding-bottom: 11.4rem !important; }
  .pb-xl-113 {
    padding-bottom: 11.3rem !important; }
  .pb-xl-112 {
    padding-bottom: 11.2rem !important; }
  .pb-xl-111 {
    padding-bottom: 11.1rem !important; }
  .pb-xl-110 {
    padding-bottom: 11rem !important; }
  .pb-xl-109 {
    padding-bottom: 10.9rem !important; }
  .pb-xl-108 {
    padding-bottom: 10.8rem !important; }
  .pb-xl-107 {
    padding-bottom: 10.7rem !important; }
  .pb-xl-106 {
    padding-bottom: 10.6rem !important; }
  .pb-xl-105 {
    padding-bottom: 10.5rem !important; }
  .pb-xl-104 {
    padding-bottom: 10.4rem !important; }
  .pb-xl-103 {
    padding-bottom: 10.3rem !important; }
  .pb-xl-102 {
    padding-bottom: 10.2rem !important; }
  .pb-xl-101 {
    padding-bottom: 10.1rem !important; }
  .pb-xl-100 {
    padding-bottom: 10rem !important; }
  .pb-xl-99 {
    padding-bottom: 9.9rem !important; }
  .pb-xl-98 {
    padding-bottom: 9.8rem !important; }
  .pb-xl-97 {
    padding-bottom: 9.7rem !important; }
  .pb-xl-96 {
    padding-bottom: 9.6rem !important; }
  .pb-xl-95 {
    padding-bottom: 9.5rem !important; }
  .pb-xl-94 {
    padding-bottom: 9.4rem !important; }
  .pb-xl-93 {
    padding-bottom: 9.3rem !important; }
  .pb-xl-92 {
    padding-bottom: 9.2rem !important; }
  .pb-xl-91 {
    padding-bottom: 9.1rem !important; }
  .pb-xl-90 {
    padding-bottom: 9rem !important; }
  .pb-xl-89 {
    padding-bottom: 8.9rem !important; }
  .pb-xl-88 {
    padding-bottom: 8.8rem !important; }
  .pb-xl-87 {
    padding-bottom: 8.7rem !important; }
  .pb-xl-86 {
    padding-bottom: 8.6rem !important; }
  .pb-xl-85 {
    padding-bottom: 8.5rem !important; }
  .pb-xl-84 {
    padding-bottom: 8.4rem !important; }
  .pb-xl-83 {
    padding-bottom: 8.3rem !important; }
  .pb-xl-82 {
    padding-bottom: 8.2rem !important; }
  .pb-xl-81 {
    padding-bottom: 8.1rem !important; }
  .pb-xl-80 {
    padding-bottom: 8rem !important; }
  .pb-xl-79 {
    padding-bottom: 7.9rem !important; }
  .pb-xl-78 {
    padding-bottom: 7.8rem !important; }
  .pb-xl-77 {
    padding-bottom: 7.7rem !important; }
  .pb-xl-76 {
    padding-bottom: 7.6rem !important; }
  .pb-xl-75 {
    padding-bottom: 7.5rem !important; }
  .pb-xl-74 {
    padding-bottom: 7.4rem !important; }
  .pb-xl-73 {
    padding-bottom: 7.3rem !important; }
  .pb-xl-72 {
    padding-bottom: 7.2rem !important; }
  .pb-xl-71 {
    padding-bottom: 7.1rem !important; }
  .pb-xl-70 {
    padding-bottom: 7rem !important; }
  .pb-xl-69 {
    padding-bottom: 6.9rem !important; }
  .pb-xl-68 {
    padding-bottom: 6.8rem !important; }
  .pb-xl-67 {
    padding-bottom: 6.7rem !important; }
  .pb-xl-66 {
    padding-bottom: 6.6rem !important; }
  .pb-xl-65 {
    padding-bottom: 6.5rem !important; }
  .pb-xl-64 {
    padding-bottom: 6.4rem !important; }
  .pb-xl-63 {
    padding-bottom: 6.3rem !important; }
  .pb-xl-62 {
    padding-bottom: 6.2rem !important; }
  .pb-xl-61 {
    padding-bottom: 6.1rem !important; }
  .pb-xl-60 {
    padding-bottom: 6rem !important; }
  .pb-xl-59 {
    padding-bottom: 5.9rem !important; }
  .pb-xl-58 {
    padding-bottom: 5.8rem !important; }
  .pb-xl-57 {
    padding-bottom: 5.7rem !important; }
  .pb-xl-56 {
    padding-bottom: 5.6rem !important; }
  .pb-xl-55 {
    padding-bottom: 5.5rem !important; }
  .pb-xl-54 {
    padding-bottom: 5.4rem !important; }
  .pb-xl-53 {
    padding-bottom: 5.3rem !important; }
  .pb-xl-52 {
    padding-bottom: 5.2rem !important; }
  .pb-xl-51 {
    padding-bottom: 5.1rem !important; }
  .pb-xl-50 {
    padding-bottom: 5rem !important; }
  .pb-xl-49 {
    padding-bottom: 4.9rem !important; }
  .pb-xl-48 {
    padding-bottom: 4.8rem !important; }
  .pb-xl-47 {
    padding-bottom: 4.7rem !important; }
  .pb-xl-46 {
    padding-bottom: 4.6rem !important; }
  .pb-xl-45 {
    padding-bottom: 4.5rem !important; }
  .pb-xl-44 {
    padding-bottom: 4.4rem !important; }
  .pb-xl-43 {
    padding-bottom: 4.3rem !important; }
  .pb-xl-42 {
    padding-bottom: 4.2rem !important; }
  .pb-xl-41 {
    padding-bottom: 4.1rem !important; }
  .pb-xl-40 {
    padding-bottom: 4rem !important; }
  .pb-xl-39 {
    padding-bottom: 3.9rem !important; }
  .pb-xl-38 {
    padding-bottom: 3.8rem !important; }
  .pb-xl-37 {
    padding-bottom: 3.7rem !important; }
  .pb-xl-36 {
    padding-bottom: 3.6rem !important; }
  .pb-xl-35 {
    padding-bottom: 3.5rem !important; }
  .pb-xl-34 {
    padding-bottom: 3.4rem !important; }
  .pb-xl-33 {
    padding-bottom: 3.3rem !important; }
  .pb-xl-32 {
    padding-bottom: 3.2rem !important; }
  .pb-xl-31 {
    padding-bottom: 3.1rem !important; }
  .pb-xl-30 {
    padding-bottom: 3rem !important; }
  .pb-xl-29 {
    padding-bottom: 2.9rem !important; }
  .pb-xl-28 {
    padding-bottom: 2.8rem !important; }
  .pb-xl-27 {
    padding-bottom: 2.7rem !important; }
  .pb-xl-26 {
    padding-bottom: 2.6rem !important; }
  .pb-xl-25 {
    padding-bottom: 2.5rem !important; }
  .pb-xl-24 {
    padding-bottom: 2.4rem !important; }
  .pb-xl-23 {
    padding-bottom: 2.3rem !important; }
  .pb-xl-22 {
    padding-bottom: 2.2rem !important; }
  .pb-xl-21 {
    padding-bottom: 2.1rem !important; }
  .pb-xl-20 {
    padding-bottom: 2rem !important; }
  .pb-xl-19 {
    padding-bottom: 1.9rem !important; }
  .pb-xl-18 {
    padding-bottom: 1.8rem !important; }
  .pb-xl-17 {
    padding-bottom: 1.7rem !important; }
  .pb-xl-16 {
    padding-bottom: 1.6rem !important; }
  .pb-xl-15 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-14 {
    padding-bottom: 1.4rem !important; }
  .pb-xl-13 {
    padding-bottom: 1.3rem !important; }
  .pb-xl-12 {
    padding-bottom: 1.2rem !important; }
  .pb-xl-11 {
    padding-bottom: 1.1rem !important; }
  .pb-xl-10 {
    padding-bottom: 1rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pb-xl-n80 {
    padding-bottom: -80px !important; }
  .pb-xl-n100 {
    padding-bottom: -100px !important; }
  .pb-xl-n150 {
    padding-bottom: -150px !important; }
  .ps-xl-150 {
    padding-left: 15rem !important; }
  .ps-xl-149 {
    padding-left: 14.9rem !important; }
  .ps-xl-148 {
    padding-left: 14.8rem !important; }
  .ps-xl-147 {
    padding-left: 14.7rem !important; }
  .ps-xl-146 {
    padding-left: 14.6rem !important; }
  .ps-xl-145 {
    padding-left: 14.5rem !important; }
  .ps-xl-144 {
    padding-left: 14.4rem !important; }
  .ps-xl-143 {
    padding-left: 14.3rem !important; }
  .ps-xl-142 {
    padding-left: 14.2rem !important; }
  .ps-xl-141 {
    padding-left: 14.1rem !important; }
  .ps-xl-140 {
    padding-left: 14rem !important; }
  .ps-xl-139 {
    padding-left: 13.9rem !important; }
  .ps-xl-138 {
    padding-left: 13.8rem !important; }
  .ps-xl-137 {
    padding-left: 13.7rem !important; }
  .ps-xl-136 {
    padding-left: 13.6rem !important; }
  .ps-xl-135 {
    padding-left: 13.5rem !important; }
  .ps-xl-134 {
    padding-left: 13.4rem !important; }
  .ps-xl-133 {
    padding-left: 13.3rem !important; }
  .ps-xl-132 {
    padding-left: 13.2rem !important; }
  .ps-xl-131 {
    padding-left: 13.1rem !important; }
  .ps-xl-130 {
    padding-left: 13rem !important; }
  .ps-xl-129 {
    padding-left: 12.9rem !important; }
  .ps-xl-128 {
    padding-left: 12.8rem !important; }
  .ps-xl-127 {
    padding-left: 12.7rem !important; }
  .ps-xl-126 {
    padding-left: 12.6rem !important; }
  .ps-xl-125 {
    padding-left: 12.5rem !important; }
  .ps-xl-124 {
    padding-left: 12.4rem !important; }
  .ps-xl-123 {
    padding-left: 12.3rem !important; }
  .ps-xl-122 {
    padding-left: 12.2rem !important; }
  .ps-xl-121 {
    padding-left: 12.1rem !important; }
  .ps-xl-120 {
    padding-left: 12rem !important; }
  .ps-xl-119 {
    padding-left: 11.9rem !important; }
  .ps-xl-118 {
    padding-left: 11.8rem !important; }
  .ps-xl-117 {
    padding-left: 11.7rem !important; }
  .ps-xl-116 {
    padding-left: 11.6rem !important; }
  .ps-xl-115 {
    padding-left: 11.5rem !important; }
  .ps-xl-114 {
    padding-left: 11.4rem !important; }
  .ps-xl-113 {
    padding-left: 11.3rem !important; }
  .ps-xl-112 {
    padding-left: 11.2rem !important; }
  .ps-xl-111 {
    padding-left: 11.1rem !important; }
  .ps-xl-110 {
    padding-left: 11rem !important; }
  .ps-xl-109 {
    padding-left: 10.9rem !important; }
  .ps-xl-108 {
    padding-left: 10.8rem !important; }
  .ps-xl-107 {
    padding-left: 10.7rem !important; }
  .ps-xl-106 {
    padding-left: 10.6rem !important; }
  .ps-xl-105 {
    padding-left: 10.5rem !important; }
  .ps-xl-104 {
    padding-left: 10.4rem !important; }
  .ps-xl-103 {
    padding-left: 10.3rem !important; }
  .ps-xl-102 {
    padding-left: 10.2rem !important; }
  .ps-xl-101 {
    padding-left: 10.1rem !important; }
  .ps-xl-100 {
    padding-left: 10rem !important; }
  .ps-xl-99 {
    padding-left: 9.9rem !important; }
  .ps-xl-98 {
    padding-left: 9.8rem !important; }
  .ps-xl-97 {
    padding-left: 9.7rem !important; }
  .ps-xl-96 {
    padding-left: 9.6rem !important; }
  .ps-xl-95 {
    padding-left: 9.5rem !important; }
  .ps-xl-94 {
    padding-left: 9.4rem !important; }
  .ps-xl-93 {
    padding-left: 9.3rem !important; }
  .ps-xl-92 {
    padding-left: 9.2rem !important; }
  .ps-xl-91 {
    padding-left: 9.1rem !important; }
  .ps-xl-90 {
    padding-left: 9rem !important; }
  .ps-xl-89 {
    padding-left: 8.9rem !important; }
  .ps-xl-88 {
    padding-left: 8.8rem !important; }
  .ps-xl-87 {
    padding-left: 8.7rem !important; }
  .ps-xl-86 {
    padding-left: 8.6rem !important; }
  .ps-xl-85 {
    padding-left: 8.5rem !important; }
  .ps-xl-84 {
    padding-left: 8.4rem !important; }
  .ps-xl-83 {
    padding-left: 8.3rem !important; }
  .ps-xl-82 {
    padding-left: 8.2rem !important; }
  .ps-xl-81 {
    padding-left: 8.1rem !important; }
  .ps-xl-80 {
    padding-left: 8rem !important; }
  .ps-xl-79 {
    padding-left: 7.9rem !important; }
  .ps-xl-78 {
    padding-left: 7.8rem !important; }
  .ps-xl-77 {
    padding-left: 7.7rem !important; }
  .ps-xl-76 {
    padding-left: 7.6rem !important; }
  .ps-xl-75 {
    padding-left: 7.5rem !important; }
  .ps-xl-74 {
    padding-left: 7.4rem !important; }
  .ps-xl-73 {
    padding-left: 7.3rem !important; }
  .ps-xl-72 {
    padding-left: 7.2rem !important; }
  .ps-xl-71 {
    padding-left: 7.1rem !important; }
  .ps-xl-70 {
    padding-left: 7rem !important; }
  .ps-xl-69 {
    padding-left: 6.9rem !important; }
  .ps-xl-68 {
    padding-left: 6.8rem !important; }
  .ps-xl-67 {
    padding-left: 6.7rem !important; }
  .ps-xl-66 {
    padding-left: 6.6rem !important; }
  .ps-xl-65 {
    padding-left: 6.5rem !important; }
  .ps-xl-64 {
    padding-left: 6.4rem !important; }
  .ps-xl-63 {
    padding-left: 6.3rem !important; }
  .ps-xl-62 {
    padding-left: 6.2rem !important; }
  .ps-xl-61 {
    padding-left: 6.1rem !important; }
  .ps-xl-60 {
    padding-left: 6rem !important; }
  .ps-xl-59 {
    padding-left: 5.9rem !important; }
  .ps-xl-58 {
    padding-left: 5.8rem !important; }
  .ps-xl-57 {
    padding-left: 5.7rem !important; }
  .ps-xl-56 {
    padding-left: 5.6rem !important; }
  .ps-xl-55 {
    padding-left: 5.5rem !important; }
  .ps-xl-54 {
    padding-left: 5.4rem !important; }
  .ps-xl-53 {
    padding-left: 5.3rem !important; }
  .ps-xl-52 {
    padding-left: 5.2rem !important; }
  .ps-xl-51 {
    padding-left: 5.1rem !important; }
  .ps-xl-50 {
    padding-left: 5rem !important; }
  .ps-xl-49 {
    padding-left: 4.9rem !important; }
  .ps-xl-48 {
    padding-left: 4.8rem !important; }
  .ps-xl-47 {
    padding-left: 4.7rem !important; }
  .ps-xl-46 {
    padding-left: 4.6rem !important; }
  .ps-xl-45 {
    padding-left: 4.5rem !important; }
  .ps-xl-44 {
    padding-left: 4.4rem !important; }
  .ps-xl-43 {
    padding-left: 4.3rem !important; }
  .ps-xl-42 {
    padding-left: 4.2rem !important; }
  .ps-xl-41 {
    padding-left: 4.1rem !important; }
  .ps-xl-40 {
    padding-left: 4rem !important; }
  .ps-xl-39 {
    padding-left: 3.9rem !important; }
  .ps-xl-38 {
    padding-left: 3.8rem !important; }
  .ps-xl-37 {
    padding-left: 3.7rem !important; }
  .ps-xl-36 {
    padding-left: 3.6rem !important; }
  .ps-xl-35 {
    padding-left: 3.5rem !important; }
  .ps-xl-34 {
    padding-left: 3.4rem !important; }
  .ps-xl-33 {
    padding-left: 3.3rem !important; }
  .ps-xl-32 {
    padding-left: 3.2rem !important; }
  .ps-xl-31 {
    padding-left: 3.1rem !important; }
  .ps-xl-30 {
    padding-left: 3rem !important; }
  .ps-xl-29 {
    padding-left: 2.9rem !important; }
  .ps-xl-28 {
    padding-left: 2.8rem !important; }
  .ps-xl-27 {
    padding-left: 2.7rem !important; }
  .ps-xl-26 {
    padding-left: 2.6rem !important; }
  .ps-xl-25 {
    padding-left: 2.5rem !important; }
  .ps-xl-24 {
    padding-left: 2.4rem !important; }
  .ps-xl-23 {
    padding-left: 2.3rem !important; }
  .ps-xl-22 {
    padding-left: 2.2rem !important; }
  .ps-xl-21 {
    padding-left: 2.1rem !important; }
  .ps-xl-20 {
    padding-left: 2rem !important; }
  .ps-xl-19 {
    padding-left: 1.9rem !important; }
  .ps-xl-18 {
    padding-left: 1.8rem !important; }
  .ps-xl-17 {
    padding-left: 1.7rem !important; }
  .ps-xl-16 {
    padding-left: 1.6rem !important; }
  .ps-xl-15 {
    padding-left: 1.5rem !important; }
  .ps-xl-14 {
    padding-left: 1.4rem !important; }
  .ps-xl-13 {
    padding-left: 1.3rem !important; }
  .ps-xl-12 {
    padding-left: 1.2rem !important; }
  .ps-xl-11 {
    padding-left: 1.1rem !important; }
  .ps-xl-10 {
    padding-left: 1rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .ps-xl-n80 {
    padding-left: -80px !important; }
  .ps-xl-n100 {
    padding-left: -100px !important; }
  .ps-xl-n150 {
    padding-left: -150px !important; }
  .fs-xl-45 {
    font-size: 4.5rem !important; }
  .fs-xl-44 {
    font-size: 4.4rem !important; }
  .fs-xl-43 {
    font-size: 4.3rem !important; }
  .fs-xl-42 {
    font-size: 4.2rem !important; }
  .fs-xl-41 {
    font-size: 4.1rem !important; }
  .fs-xl-40 {
    font-size: 4rem !important; }
  .fs-xl-39 {
    font-size: 3.9rem !important; }
  .fs-xl-38 {
    font-size: 3.8rem !important; }
  .fs-xl-37 {
    font-size: 3.7rem !important; }
  .fs-xl-36 {
    font-size: 3.6rem !important; }
  .fs-xl-35 {
    font-size: 3.5rem !important; }
  .fs-xl-34 {
    font-size: 3.4rem !important; }
  .fs-xl-33 {
    font-size: 3.3rem !important; }
  .fs-xl-32 {
    font-size: 3.2rem !important; }
  .fs-xl-31 {
    font-size: 3.1rem !important; }
  .fs-xl-30 {
    font-size: 3rem !important; }
  .fs-xl-29 {
    font-size: 2.9rem !important; }
  .fs-xl-28 {
    font-size: 2.8rem !important; }
  .fs-xl-27 {
    font-size: 2.7rem !important; }
  .fs-xl-26 {
    font-size: 2.6rem !important; }
  .fs-xl-25 {
    font-size: 2.5rem !important; }
  .fs-xl-24 {
    font-size: 2.4rem !important; }
  .fs-xl-23 {
    font-size: 2.3rem !important; }
  .fs-xl-22 {
    font-size: 2.2rem !important; }
  .fs-xl-21 {
    font-size: 2.1rem !important; }
  .fs-xl-20 {
    font-size: 2rem !important; }
  .fs-xl-19 {
    font-size: 1.9rem !important; }
  .fs-xl-18 {
    font-size: 1.8rem !important; }
  .fs-xl-17 {
    font-size: 1.7rem !important; }
  .fs-xl-16 {
    font-size: 1.6rem !important; }
  .fs-xl-15 {
    font-size: 1.5rem !important; }
  .fs-xl-14 {
    font-size: 1.4rem !important; }
  .fs-xl-13 {
    font-size: 1.3rem !important; }
  .fs-xl-12 {
    font-size: 1.2rem !important; }
  .fs-xl-11 {
    font-size: 1.1rem !important; }
  .fs-xl-10 {
    font-size: 1rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .rounded-xl {
    border-radius: 0.8rem !important; }
  .rounded-xl-0 {
    border-radius: 0 !important; }
  .rounded-xl-1 {
    border-radius: 0.4rem !important; }
  .rounded-xl-2 {
    border-radius: 0.8rem !important; }
  .rounded-xl-3 {
    border-radius: 1rem !important; }
  .rounded-xl-circle {
    border-radius: 50% !important; }
  .z-index-xl-1 {
    z-index: 1 !important; }
  .z-index-xl-2 {
    z-index: 2 !important; }
  .z-index-xl-3 {
    z-index: 3 !important; }
  .z-index-xl-4 {
    z-index: 4 !important; }
  .z-index-xl-5 {
    z-index: 5 !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .position-xxl-static {
    position: static !important; }
  .position-xxl-relative {
    position: relative !important; }
  .position-xxl-absolute {
    position: absolute !important; }
  .position-xxl-fixed {
    position: fixed !important; }
  .position-xxl-sticky {
    position: sticky !important; }
  .w-xxl-20 {
    width: 20% !important; }
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-45 {
    width: 45% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-55 {
    width: 55% !important; }
  .w-xxl-60 {
    width: 60% !important; }
  .w-xxl-65 {
    width: 65% !important; }
  .w-xxl-70 {
    width: 70% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-80 {
    width: 80% !important; }
  .w-xxl-90 {
    width: 90% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .w-xxl-auto {
    width: auto !important; }
  .w-xxl-50px {
    width: 50px !important; }
  .w-xxl-60px {
    width: 60px !important; }
  .w-xxl-90px {
    width: 90px !important; }
  .w-xxl-100px {
    width: 100px !important; }
  .w-xxl-118px {
    width: 118px !important; }
  .w-xxl-158px {
    width: 158px !important; }
  .w-xxl-174px {
    width: 174px !important; }
  .w-xxl-250px {
    width: 250px !important; }
  .h-xxl-20 {
    height: 20% !important; }
  .h-xxl-25 {
    height: 25% !important; }
  .h-xxl-45 {
    height: 45% !important; }
  .h-xxl-50 {
    height: 50% !important; }
  .h-xxl-55 {
    height: 55% !important; }
  .h-xxl-60 {
    height: 60% !important; }
  .h-xxl-65 {
    height: 65% !important; }
  .h-xxl-70 {
    height: 70% !important; }
  .h-xxl-75 {
    height: 75% !important; }
  .h-xxl-80 {
    height: 80% !important; }
  .h-xxl-90 {
    height: 90% !important; }
  .h-xxl-100 {
    height: 100% !important; }
  .h-xxl-auto {
    height: auto !important; }
  .h-xxl-50px {
    height: 50px !important; }
  .h-xxl-60px {
    height: 60px !important; }
  .h-xxl-90px {
    height: 90px !important; }
  .h-xxl-100px {
    height: 100px !important; }
  .h-xxl-118px {
    height: 118px !important; }
  .h-xxl-158px {
    height: 158px !important; }
  .h-xxl-174px {
    height: 174px !important; }
  .h-xxl-250px {
    height: 250px !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-150 {
    gap: 15rem !important; }
  .gap-xxl-149 {
    gap: 14.9rem !important; }
  .gap-xxl-148 {
    gap: 14.8rem !important; }
  .gap-xxl-147 {
    gap: 14.7rem !important; }
  .gap-xxl-146 {
    gap: 14.6rem !important; }
  .gap-xxl-145 {
    gap: 14.5rem !important; }
  .gap-xxl-144 {
    gap: 14.4rem !important; }
  .gap-xxl-143 {
    gap: 14.3rem !important; }
  .gap-xxl-142 {
    gap: 14.2rem !important; }
  .gap-xxl-141 {
    gap: 14.1rem !important; }
  .gap-xxl-140 {
    gap: 14rem !important; }
  .gap-xxl-139 {
    gap: 13.9rem !important; }
  .gap-xxl-138 {
    gap: 13.8rem !important; }
  .gap-xxl-137 {
    gap: 13.7rem !important; }
  .gap-xxl-136 {
    gap: 13.6rem !important; }
  .gap-xxl-135 {
    gap: 13.5rem !important; }
  .gap-xxl-134 {
    gap: 13.4rem !important; }
  .gap-xxl-133 {
    gap: 13.3rem !important; }
  .gap-xxl-132 {
    gap: 13.2rem !important; }
  .gap-xxl-131 {
    gap: 13.1rem !important; }
  .gap-xxl-130 {
    gap: 13rem !important; }
  .gap-xxl-129 {
    gap: 12.9rem !important; }
  .gap-xxl-128 {
    gap: 12.8rem !important; }
  .gap-xxl-127 {
    gap: 12.7rem !important; }
  .gap-xxl-126 {
    gap: 12.6rem !important; }
  .gap-xxl-125 {
    gap: 12.5rem !important; }
  .gap-xxl-124 {
    gap: 12.4rem !important; }
  .gap-xxl-123 {
    gap: 12.3rem !important; }
  .gap-xxl-122 {
    gap: 12.2rem !important; }
  .gap-xxl-121 {
    gap: 12.1rem !important; }
  .gap-xxl-120 {
    gap: 12rem !important; }
  .gap-xxl-119 {
    gap: 11.9rem !important; }
  .gap-xxl-118 {
    gap: 11.8rem !important; }
  .gap-xxl-117 {
    gap: 11.7rem !important; }
  .gap-xxl-116 {
    gap: 11.6rem !important; }
  .gap-xxl-115 {
    gap: 11.5rem !important; }
  .gap-xxl-114 {
    gap: 11.4rem !important; }
  .gap-xxl-113 {
    gap: 11.3rem !important; }
  .gap-xxl-112 {
    gap: 11.2rem !important; }
  .gap-xxl-111 {
    gap: 11.1rem !important; }
  .gap-xxl-110 {
    gap: 11rem !important; }
  .gap-xxl-109 {
    gap: 10.9rem !important; }
  .gap-xxl-108 {
    gap: 10.8rem !important; }
  .gap-xxl-107 {
    gap: 10.7rem !important; }
  .gap-xxl-106 {
    gap: 10.6rem !important; }
  .gap-xxl-105 {
    gap: 10.5rem !important; }
  .gap-xxl-104 {
    gap: 10.4rem !important; }
  .gap-xxl-103 {
    gap: 10.3rem !important; }
  .gap-xxl-102 {
    gap: 10.2rem !important; }
  .gap-xxl-101 {
    gap: 10.1rem !important; }
  .gap-xxl-100 {
    gap: 10rem !important; }
  .gap-xxl-99 {
    gap: 9.9rem !important; }
  .gap-xxl-98 {
    gap: 9.8rem !important; }
  .gap-xxl-97 {
    gap: 9.7rem !important; }
  .gap-xxl-96 {
    gap: 9.6rem !important; }
  .gap-xxl-95 {
    gap: 9.5rem !important; }
  .gap-xxl-94 {
    gap: 9.4rem !important; }
  .gap-xxl-93 {
    gap: 9.3rem !important; }
  .gap-xxl-92 {
    gap: 9.2rem !important; }
  .gap-xxl-91 {
    gap: 9.1rem !important; }
  .gap-xxl-90 {
    gap: 9rem !important; }
  .gap-xxl-89 {
    gap: 8.9rem !important; }
  .gap-xxl-88 {
    gap: 8.8rem !important; }
  .gap-xxl-87 {
    gap: 8.7rem !important; }
  .gap-xxl-86 {
    gap: 8.6rem !important; }
  .gap-xxl-85 {
    gap: 8.5rem !important; }
  .gap-xxl-84 {
    gap: 8.4rem !important; }
  .gap-xxl-83 {
    gap: 8.3rem !important; }
  .gap-xxl-82 {
    gap: 8.2rem !important; }
  .gap-xxl-81 {
    gap: 8.1rem !important; }
  .gap-xxl-80 {
    gap: 8rem !important; }
  .gap-xxl-79 {
    gap: 7.9rem !important; }
  .gap-xxl-78 {
    gap: 7.8rem !important; }
  .gap-xxl-77 {
    gap: 7.7rem !important; }
  .gap-xxl-76 {
    gap: 7.6rem !important; }
  .gap-xxl-75 {
    gap: 7.5rem !important; }
  .gap-xxl-74 {
    gap: 7.4rem !important; }
  .gap-xxl-73 {
    gap: 7.3rem !important; }
  .gap-xxl-72 {
    gap: 7.2rem !important; }
  .gap-xxl-71 {
    gap: 7.1rem !important; }
  .gap-xxl-70 {
    gap: 7rem !important; }
  .gap-xxl-69 {
    gap: 6.9rem !important; }
  .gap-xxl-68 {
    gap: 6.8rem !important; }
  .gap-xxl-67 {
    gap: 6.7rem !important; }
  .gap-xxl-66 {
    gap: 6.6rem !important; }
  .gap-xxl-65 {
    gap: 6.5rem !important; }
  .gap-xxl-64 {
    gap: 6.4rem !important; }
  .gap-xxl-63 {
    gap: 6.3rem !important; }
  .gap-xxl-62 {
    gap: 6.2rem !important; }
  .gap-xxl-61 {
    gap: 6.1rem !important; }
  .gap-xxl-60 {
    gap: 6rem !important; }
  .gap-xxl-59 {
    gap: 5.9rem !important; }
  .gap-xxl-58 {
    gap: 5.8rem !important; }
  .gap-xxl-57 {
    gap: 5.7rem !important; }
  .gap-xxl-56 {
    gap: 5.6rem !important; }
  .gap-xxl-55 {
    gap: 5.5rem !important; }
  .gap-xxl-54 {
    gap: 5.4rem !important; }
  .gap-xxl-53 {
    gap: 5.3rem !important; }
  .gap-xxl-52 {
    gap: 5.2rem !important; }
  .gap-xxl-51 {
    gap: 5.1rem !important; }
  .gap-xxl-50 {
    gap: 5rem !important; }
  .gap-xxl-49 {
    gap: 4.9rem !important; }
  .gap-xxl-48 {
    gap: 4.8rem !important; }
  .gap-xxl-47 {
    gap: 4.7rem !important; }
  .gap-xxl-46 {
    gap: 4.6rem !important; }
  .gap-xxl-45 {
    gap: 4.5rem !important; }
  .gap-xxl-44 {
    gap: 4.4rem !important; }
  .gap-xxl-43 {
    gap: 4.3rem !important; }
  .gap-xxl-42 {
    gap: 4.2rem !important; }
  .gap-xxl-41 {
    gap: 4.1rem !important; }
  .gap-xxl-40 {
    gap: 4rem !important; }
  .gap-xxl-39 {
    gap: 3.9rem !important; }
  .gap-xxl-38 {
    gap: 3.8rem !important; }
  .gap-xxl-37 {
    gap: 3.7rem !important; }
  .gap-xxl-36 {
    gap: 3.6rem !important; }
  .gap-xxl-35 {
    gap: 3.5rem !important; }
  .gap-xxl-34 {
    gap: 3.4rem !important; }
  .gap-xxl-33 {
    gap: 3.3rem !important; }
  .gap-xxl-32 {
    gap: 3.2rem !important; }
  .gap-xxl-31 {
    gap: 3.1rem !important; }
  .gap-xxl-30 {
    gap: 3rem !important; }
  .gap-xxl-29 {
    gap: 2.9rem !important; }
  .gap-xxl-28 {
    gap: 2.8rem !important; }
  .gap-xxl-27 {
    gap: 2.7rem !important; }
  .gap-xxl-26 {
    gap: 2.6rem !important; }
  .gap-xxl-25 {
    gap: 2.5rem !important; }
  .gap-xxl-24 {
    gap: 2.4rem !important; }
  .gap-xxl-23 {
    gap: 2.3rem !important; }
  .gap-xxl-22 {
    gap: 2.2rem !important; }
  .gap-xxl-21 {
    gap: 2.1rem !important; }
  .gap-xxl-20 {
    gap: 2rem !important; }
  .gap-xxl-19 {
    gap: 1.9rem !important; }
  .gap-xxl-18 {
    gap: 1.8rem !important; }
  .gap-xxl-17 {
    gap: 1.7rem !important; }
  .gap-xxl-16 {
    gap: 1.6rem !important; }
  .gap-xxl-15 {
    gap: 1.5rem !important; }
  .gap-xxl-14 {
    gap: 1.4rem !important; }
  .gap-xxl-13 {
    gap: 1.3rem !important; }
  .gap-xxl-12 {
    gap: 1.2rem !important; }
  .gap-xxl-11 {
    gap: 1.1rem !important; }
  .gap-xxl-10 {
    gap: 1rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .gap-xxl-n80 {
    gap: -80px !important; }
  .gap-xxl-n100 {
    gap: -100px !important; }
  .gap-xxl-n150 {
    gap: -150px !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-150 {
    margin: 15rem !important; }
  .m-xxl-149 {
    margin: 14.9rem !important; }
  .m-xxl-148 {
    margin: 14.8rem !important; }
  .m-xxl-147 {
    margin: 14.7rem !important; }
  .m-xxl-146 {
    margin: 14.6rem !important; }
  .m-xxl-145 {
    margin: 14.5rem !important; }
  .m-xxl-144 {
    margin: 14.4rem !important; }
  .m-xxl-143 {
    margin: 14.3rem !important; }
  .m-xxl-142 {
    margin: 14.2rem !important; }
  .m-xxl-141 {
    margin: 14.1rem !important; }
  .m-xxl-140 {
    margin: 14rem !important; }
  .m-xxl-139 {
    margin: 13.9rem !important; }
  .m-xxl-138 {
    margin: 13.8rem !important; }
  .m-xxl-137 {
    margin: 13.7rem !important; }
  .m-xxl-136 {
    margin: 13.6rem !important; }
  .m-xxl-135 {
    margin: 13.5rem !important; }
  .m-xxl-134 {
    margin: 13.4rem !important; }
  .m-xxl-133 {
    margin: 13.3rem !important; }
  .m-xxl-132 {
    margin: 13.2rem !important; }
  .m-xxl-131 {
    margin: 13.1rem !important; }
  .m-xxl-130 {
    margin: 13rem !important; }
  .m-xxl-129 {
    margin: 12.9rem !important; }
  .m-xxl-128 {
    margin: 12.8rem !important; }
  .m-xxl-127 {
    margin: 12.7rem !important; }
  .m-xxl-126 {
    margin: 12.6rem !important; }
  .m-xxl-125 {
    margin: 12.5rem !important; }
  .m-xxl-124 {
    margin: 12.4rem !important; }
  .m-xxl-123 {
    margin: 12.3rem !important; }
  .m-xxl-122 {
    margin: 12.2rem !important; }
  .m-xxl-121 {
    margin: 12.1rem !important; }
  .m-xxl-120 {
    margin: 12rem !important; }
  .m-xxl-119 {
    margin: 11.9rem !important; }
  .m-xxl-118 {
    margin: 11.8rem !important; }
  .m-xxl-117 {
    margin: 11.7rem !important; }
  .m-xxl-116 {
    margin: 11.6rem !important; }
  .m-xxl-115 {
    margin: 11.5rem !important; }
  .m-xxl-114 {
    margin: 11.4rem !important; }
  .m-xxl-113 {
    margin: 11.3rem !important; }
  .m-xxl-112 {
    margin: 11.2rem !important; }
  .m-xxl-111 {
    margin: 11.1rem !important; }
  .m-xxl-110 {
    margin: 11rem !important; }
  .m-xxl-109 {
    margin: 10.9rem !important; }
  .m-xxl-108 {
    margin: 10.8rem !important; }
  .m-xxl-107 {
    margin: 10.7rem !important; }
  .m-xxl-106 {
    margin: 10.6rem !important; }
  .m-xxl-105 {
    margin: 10.5rem !important; }
  .m-xxl-104 {
    margin: 10.4rem !important; }
  .m-xxl-103 {
    margin: 10.3rem !important; }
  .m-xxl-102 {
    margin: 10.2rem !important; }
  .m-xxl-101 {
    margin: 10.1rem !important; }
  .m-xxl-100 {
    margin: 10rem !important; }
  .m-xxl-99 {
    margin: 9.9rem !important; }
  .m-xxl-98 {
    margin: 9.8rem !important; }
  .m-xxl-97 {
    margin: 9.7rem !important; }
  .m-xxl-96 {
    margin: 9.6rem !important; }
  .m-xxl-95 {
    margin: 9.5rem !important; }
  .m-xxl-94 {
    margin: 9.4rem !important; }
  .m-xxl-93 {
    margin: 9.3rem !important; }
  .m-xxl-92 {
    margin: 9.2rem !important; }
  .m-xxl-91 {
    margin: 9.1rem !important; }
  .m-xxl-90 {
    margin: 9rem !important; }
  .m-xxl-89 {
    margin: 8.9rem !important; }
  .m-xxl-88 {
    margin: 8.8rem !important; }
  .m-xxl-87 {
    margin: 8.7rem !important; }
  .m-xxl-86 {
    margin: 8.6rem !important; }
  .m-xxl-85 {
    margin: 8.5rem !important; }
  .m-xxl-84 {
    margin: 8.4rem !important; }
  .m-xxl-83 {
    margin: 8.3rem !important; }
  .m-xxl-82 {
    margin: 8.2rem !important; }
  .m-xxl-81 {
    margin: 8.1rem !important; }
  .m-xxl-80 {
    margin: 8rem !important; }
  .m-xxl-79 {
    margin: 7.9rem !important; }
  .m-xxl-78 {
    margin: 7.8rem !important; }
  .m-xxl-77 {
    margin: 7.7rem !important; }
  .m-xxl-76 {
    margin: 7.6rem !important; }
  .m-xxl-75 {
    margin: 7.5rem !important; }
  .m-xxl-74 {
    margin: 7.4rem !important; }
  .m-xxl-73 {
    margin: 7.3rem !important; }
  .m-xxl-72 {
    margin: 7.2rem !important; }
  .m-xxl-71 {
    margin: 7.1rem !important; }
  .m-xxl-70 {
    margin: 7rem !important; }
  .m-xxl-69 {
    margin: 6.9rem !important; }
  .m-xxl-68 {
    margin: 6.8rem !important; }
  .m-xxl-67 {
    margin: 6.7rem !important; }
  .m-xxl-66 {
    margin: 6.6rem !important; }
  .m-xxl-65 {
    margin: 6.5rem !important; }
  .m-xxl-64 {
    margin: 6.4rem !important; }
  .m-xxl-63 {
    margin: 6.3rem !important; }
  .m-xxl-62 {
    margin: 6.2rem !important; }
  .m-xxl-61 {
    margin: 6.1rem !important; }
  .m-xxl-60 {
    margin: 6rem !important; }
  .m-xxl-59 {
    margin: 5.9rem !important; }
  .m-xxl-58 {
    margin: 5.8rem !important; }
  .m-xxl-57 {
    margin: 5.7rem !important; }
  .m-xxl-56 {
    margin: 5.6rem !important; }
  .m-xxl-55 {
    margin: 5.5rem !important; }
  .m-xxl-54 {
    margin: 5.4rem !important; }
  .m-xxl-53 {
    margin: 5.3rem !important; }
  .m-xxl-52 {
    margin: 5.2rem !important; }
  .m-xxl-51 {
    margin: 5.1rem !important; }
  .m-xxl-50 {
    margin: 5rem !important; }
  .m-xxl-49 {
    margin: 4.9rem !important; }
  .m-xxl-48 {
    margin: 4.8rem !important; }
  .m-xxl-47 {
    margin: 4.7rem !important; }
  .m-xxl-46 {
    margin: 4.6rem !important; }
  .m-xxl-45 {
    margin: 4.5rem !important; }
  .m-xxl-44 {
    margin: 4.4rem !important; }
  .m-xxl-43 {
    margin: 4.3rem !important; }
  .m-xxl-42 {
    margin: 4.2rem !important; }
  .m-xxl-41 {
    margin: 4.1rem !important; }
  .m-xxl-40 {
    margin: 4rem !important; }
  .m-xxl-39 {
    margin: 3.9rem !important; }
  .m-xxl-38 {
    margin: 3.8rem !important; }
  .m-xxl-37 {
    margin: 3.7rem !important; }
  .m-xxl-36 {
    margin: 3.6rem !important; }
  .m-xxl-35 {
    margin: 3.5rem !important; }
  .m-xxl-34 {
    margin: 3.4rem !important; }
  .m-xxl-33 {
    margin: 3.3rem !important; }
  .m-xxl-32 {
    margin: 3.2rem !important; }
  .m-xxl-31 {
    margin: 3.1rem !important; }
  .m-xxl-30 {
    margin: 3rem !important; }
  .m-xxl-29 {
    margin: 2.9rem !important; }
  .m-xxl-28 {
    margin: 2.8rem !important; }
  .m-xxl-27 {
    margin: 2.7rem !important; }
  .m-xxl-26 {
    margin: 2.6rem !important; }
  .m-xxl-25 {
    margin: 2.5rem !important; }
  .m-xxl-24 {
    margin: 2.4rem !important; }
  .m-xxl-23 {
    margin: 2.3rem !important; }
  .m-xxl-22 {
    margin: 2.2rem !important; }
  .m-xxl-21 {
    margin: 2.1rem !important; }
  .m-xxl-20 {
    margin: 2rem !important; }
  .m-xxl-19 {
    margin: 1.9rem !important; }
  .m-xxl-18 {
    margin: 1.8rem !important; }
  .m-xxl-17 {
    margin: 1.7rem !important; }
  .m-xxl-16 {
    margin: 1.6rem !important; }
  .m-xxl-15 {
    margin: 1.5rem !important; }
  .m-xxl-14 {
    margin: 1.4rem !important; }
  .m-xxl-13 {
    margin: 1.3rem !important; }
  .m-xxl-12 {
    margin: 1.2rem !important; }
  .m-xxl-11 {
    margin: 1.1rem !important; }
  .m-xxl-10 {
    margin: 1rem !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-n80 {
    margin: -80px !important; }
  .m-xxl-n100 {
    margin: -100px !important; }
  .m-xxl-n150 {
    margin: -150px !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-150 {
    margin-right: 15rem !important;
    margin-left: 15rem !important; }
  .mx-xxl-149 {
    margin-right: 14.9rem !important;
    margin-left: 14.9rem !important; }
  .mx-xxl-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-xxl-147 {
    margin-right: 14.7rem !important;
    margin-left: 14.7rem !important; }
  .mx-xxl-146 {
    margin-right: 14.6rem !important;
    margin-left: 14.6rem !important; }
  .mx-xxl-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-xxl-144 {
    margin-right: 14.4rem !important;
    margin-left: 14.4rem !important; }
  .mx-xxl-143 {
    margin-right: 14.3rem !important;
    margin-left: 14.3rem !important; }
  .mx-xxl-142 {
    margin-right: 14.2rem !important;
    margin-left: 14.2rem !important; }
  .mx-xxl-141 {
    margin-right: 14.1rem !important;
    margin-left: 14.1rem !important; }
  .mx-xxl-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-xxl-139 {
    margin-right: 13.9rem !important;
    margin-left: 13.9rem !important; }
  .mx-xxl-138 {
    margin-right: 13.8rem !important;
    margin-left: 13.8rem !important; }
  .mx-xxl-137 {
    margin-right: 13.7rem !important;
    margin-left: 13.7rem !important; }
  .mx-xxl-136 {
    margin-right: 13.6rem !important;
    margin-left: 13.6rem !important; }
  .mx-xxl-135 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important; }
  .mx-xxl-134 {
    margin-right: 13.4rem !important;
    margin-left: 13.4rem !important; }
  .mx-xxl-133 {
    margin-right: 13.3rem !important;
    margin-left: 13.3rem !important; }
  .mx-xxl-132 {
    margin-right: 13.2rem !important;
    margin-left: 13.2rem !important; }
  .mx-xxl-131 {
    margin-right: 13.1rem !important;
    margin-left: 13.1rem !important; }
  .mx-xxl-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-xxl-129 {
    margin-right: 12.9rem !important;
    margin-left: 12.9rem !important; }
  .mx-xxl-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-xxl-127 {
    margin-right: 12.7rem !important;
    margin-left: 12.7rem !important; }
  .mx-xxl-126 {
    margin-right: 12.6rem !important;
    margin-left: 12.6rem !important; }
  .mx-xxl-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xxl-124 {
    margin-right: 12.4rem !important;
    margin-left: 12.4rem !important; }
  .mx-xxl-123 {
    margin-right: 12.3rem !important;
    margin-left: 12.3rem !important; }
  .mx-xxl-122 {
    margin-right: 12.2rem !important;
    margin-left: 12.2rem !important; }
  .mx-xxl-121 {
    margin-right: 12.1rem !important;
    margin-left: 12.1rem !important; }
  .mx-xxl-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-xxl-119 {
    margin-right: 11.9rem !important;
    margin-left: 11.9rem !important; }
  .mx-xxl-118 {
    margin-right: 11.8rem !important;
    margin-left: 11.8rem !important; }
  .mx-xxl-117 {
    margin-right: 11.7rem !important;
    margin-left: 11.7rem !important; }
  .mx-xxl-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-xxl-115 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-xxl-114 {
    margin-right: 11.4rem !important;
    margin-left: 11.4rem !important; }
  .mx-xxl-113 {
    margin-right: 11.3rem !important;
    margin-left: 11.3rem !important; }
  .mx-xxl-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-xxl-111 {
    margin-right: 11.1rem !important;
    margin-left: 11.1rem !important; }
  .mx-xxl-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-xxl-109 {
    margin-right: 10.9rem !important;
    margin-left: 10.9rem !important; }
  .mx-xxl-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-xxl-107 {
    margin-right: 10.7rem !important;
    margin-left: 10.7rem !important; }
  .mx-xxl-106 {
    margin-right: 10.6rem !important;
    margin-left: 10.6rem !important; }
  .mx-xxl-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-xxl-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-xxl-103 {
    margin-right: 10.3rem !important;
    margin-left: 10.3rem !important; }
  .mx-xxl-102 {
    margin-right: 10.2rem !important;
    margin-left: 10.2rem !important; }
  .mx-xxl-101 {
    margin-right: 10.1rem !important;
    margin-left: 10.1rem !important; }
  .mx-xxl-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xxl-99 {
    margin-right: 9.9rem !important;
    margin-left: 9.9rem !important; }
  .mx-xxl-98 {
    margin-right: 9.8rem !important;
    margin-left: 9.8rem !important; }
  .mx-xxl-97 {
    margin-right: 9.7rem !important;
    margin-left: 9.7rem !important; }
  .mx-xxl-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-xxl-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-xxl-94 {
    margin-right: 9.4rem !important;
    margin-left: 9.4rem !important; }
  .mx-xxl-93 {
    margin-right: 9.3rem !important;
    margin-left: 9.3rem !important; }
  .mx-xxl-92 {
    margin-right: 9.2rem !important;
    margin-left: 9.2rem !important; }
  .mx-xxl-91 {
    margin-right: 9.1rem !important;
    margin-left: 9.1rem !important; }
  .mx-xxl-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xxl-89 {
    margin-right: 8.9rem !important;
    margin-left: 8.9rem !important; }
  .mx-xxl-88 {
    margin-right: 8.8rem !important;
    margin-left: 8.8rem !important; }
  .mx-xxl-87 {
    margin-right: 8.7rem !important;
    margin-left: 8.7rem !important; }
  .mx-xxl-86 {
    margin-right: 8.6rem !important;
    margin-left: 8.6rem !important; }
  .mx-xxl-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-xxl-84 {
    margin-right: 8.4rem !important;
    margin-left: 8.4rem !important; }
  .mx-xxl-83 {
    margin-right: 8.3rem !important;
    margin-left: 8.3rem !important; }
  .mx-xxl-82 {
    margin-right: 8.2rem !important;
    margin-left: 8.2rem !important; }
  .mx-xxl-81 {
    margin-right: 8.1rem !important;
    margin-left: 8.1rem !important; }
  .mx-xxl-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-xxl-79 {
    margin-right: 7.9rem !important;
    margin-left: 7.9rem !important; }
  .mx-xxl-78 {
    margin-right: 7.8rem !important;
    margin-left: 7.8rem !important; }
  .mx-xxl-77 {
    margin-right: 7.7rem !important;
    margin-left: 7.7rem !important; }
  .mx-xxl-76 {
    margin-right: 7.6rem !important;
    margin-left: 7.6rem !important; }
  .mx-xxl-75 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xxl-74 {
    margin-right: 7.4rem !important;
    margin-left: 7.4rem !important; }
  .mx-xxl-73 {
    margin-right: 7.3rem !important;
    margin-left: 7.3rem !important; }
  .mx-xxl-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-xxl-71 {
    margin-right: 7.1rem !important;
    margin-left: 7.1rem !important; }
  .mx-xxl-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-xxl-69 {
    margin-right: 6.9rem !important;
    margin-left: 6.9rem !important; }
  .mx-xxl-68 {
    margin-right: 6.8rem !important;
    margin-left: 6.8rem !important; }
  .mx-xxl-67 {
    margin-right: 6.7rem !important;
    margin-left: 6.7rem !important; }
  .mx-xxl-66 {
    margin-right: 6.6rem !important;
    margin-left: 6.6rem !important; }
  .mx-xxl-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-xxl-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-xxl-63 {
    margin-right: 6.3rem !important;
    margin-left: 6.3rem !important; }
  .mx-xxl-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-xxl-61 {
    margin-right: 6.1rem !important;
    margin-left: 6.1rem !important; }
  .mx-xxl-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxl-59 {
    margin-right: 5.9rem !important;
    margin-left: 5.9rem !important; }
  .mx-xxl-58 {
    margin-right: 5.8rem !important;
    margin-left: 5.8rem !important; }
  .mx-xxl-57 {
    margin-right: 5.7rem !important;
    margin-left: 5.7rem !important; }
  .mx-xxl-56 {
    margin-right: 5.6rem !important;
    margin-left: 5.6rem !important; }
  .mx-xxl-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-xxl-54 {
    margin-right: 5.4rem !important;
    margin-left: 5.4rem !important; }
  .mx-xxl-53 {
    margin-right: 5.3rem !important;
    margin-left: 5.3rem !important; }
  .mx-xxl-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-xxl-51 {
    margin-right: 5.1rem !important;
    margin-left: 5.1rem !important; }
  .mx-xxl-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxl-49 {
    margin-right: 4.9rem !important;
    margin-left: 4.9rem !important; }
  .mx-xxl-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-xxl-47 {
    margin-right: 4.7rem !important;
    margin-left: 4.7rem !important; }
  .mx-xxl-46 {
    margin-right: 4.6rem !important;
    margin-left: 4.6rem !important; }
  .mx-xxl-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xxl-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-xxl-43 {
    margin-right: 4.3rem !important;
    margin-left: 4.3rem !important; }
  .mx-xxl-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-xxl-41 {
    margin-right: 4.1rem !important;
    margin-left: 4.1rem !important; }
  .mx-xxl-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xxl-39 {
    margin-right: 3.9rem !important;
    margin-left: 3.9rem !important; }
  .mx-xxl-38 {
    margin-right: 3.8rem !important;
    margin-left: 3.8rem !important; }
  .mx-xxl-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-xxl-36 {
    margin-right: 3.6rem !important;
    margin-left: 3.6rem !important; }
  .mx-xxl-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xxl-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-xxl-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-xxl-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-xxl-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-xxl-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-xxl-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-xxl-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-xxl-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-xxl-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxl-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-xxl-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-xxl-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-xxl-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-xxl-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xxl-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-xxl-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-xxl-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-xxl-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-xxl-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-xxl-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-xxl-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-xxl-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-xxl-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-n80 {
    margin-right: -80px !important;
    margin-left: -80px !important; }
  .mx-xxl-n100 {
    margin-right: -100px !important;
    margin-left: -100px !important; }
  .mx-xxl-n150 {
    margin-right: -150px !important;
    margin-left: -150px !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-150 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important; }
  .my-xxl-149 {
    margin-top: 14.9rem !important;
    margin-bottom: 14.9rem !important; }
  .my-xxl-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-xxl-147 {
    margin-top: 14.7rem !important;
    margin-bottom: 14.7rem !important; }
  .my-xxl-146 {
    margin-top: 14.6rem !important;
    margin-bottom: 14.6rem !important; }
  .my-xxl-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-xxl-144 {
    margin-top: 14.4rem !important;
    margin-bottom: 14.4rem !important; }
  .my-xxl-143 {
    margin-top: 14.3rem !important;
    margin-bottom: 14.3rem !important; }
  .my-xxl-142 {
    margin-top: 14.2rem !important;
    margin-bottom: 14.2rem !important; }
  .my-xxl-141 {
    margin-top: 14.1rem !important;
    margin-bottom: 14.1rem !important; }
  .my-xxl-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-xxl-139 {
    margin-top: 13.9rem !important;
    margin-bottom: 13.9rem !important; }
  .my-xxl-138 {
    margin-top: 13.8rem !important;
    margin-bottom: 13.8rem !important; }
  .my-xxl-137 {
    margin-top: 13.7rem !important;
    margin-bottom: 13.7rem !important; }
  .my-xxl-136 {
    margin-top: 13.6rem !important;
    margin-bottom: 13.6rem !important; }
  .my-xxl-135 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important; }
  .my-xxl-134 {
    margin-top: 13.4rem !important;
    margin-bottom: 13.4rem !important; }
  .my-xxl-133 {
    margin-top: 13.3rem !important;
    margin-bottom: 13.3rem !important; }
  .my-xxl-132 {
    margin-top: 13.2rem !important;
    margin-bottom: 13.2rem !important; }
  .my-xxl-131 {
    margin-top: 13.1rem !important;
    margin-bottom: 13.1rem !important; }
  .my-xxl-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-xxl-129 {
    margin-top: 12.9rem !important;
    margin-bottom: 12.9rem !important; }
  .my-xxl-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-xxl-127 {
    margin-top: 12.7rem !important;
    margin-bottom: 12.7rem !important; }
  .my-xxl-126 {
    margin-top: 12.6rem !important;
    margin-bottom: 12.6rem !important; }
  .my-xxl-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xxl-124 {
    margin-top: 12.4rem !important;
    margin-bottom: 12.4rem !important; }
  .my-xxl-123 {
    margin-top: 12.3rem !important;
    margin-bottom: 12.3rem !important; }
  .my-xxl-122 {
    margin-top: 12.2rem !important;
    margin-bottom: 12.2rem !important; }
  .my-xxl-121 {
    margin-top: 12.1rem !important;
    margin-bottom: 12.1rem !important; }
  .my-xxl-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-xxl-119 {
    margin-top: 11.9rem !important;
    margin-bottom: 11.9rem !important; }
  .my-xxl-118 {
    margin-top: 11.8rem !important;
    margin-bottom: 11.8rem !important; }
  .my-xxl-117 {
    margin-top: 11.7rem !important;
    margin-bottom: 11.7rem !important; }
  .my-xxl-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-xxl-115 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-xxl-114 {
    margin-top: 11.4rem !important;
    margin-bottom: 11.4rem !important; }
  .my-xxl-113 {
    margin-top: 11.3rem !important;
    margin-bottom: 11.3rem !important; }
  .my-xxl-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-xxl-111 {
    margin-top: 11.1rem !important;
    margin-bottom: 11.1rem !important; }
  .my-xxl-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-xxl-109 {
    margin-top: 10.9rem !important;
    margin-bottom: 10.9rem !important; }
  .my-xxl-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-xxl-107 {
    margin-top: 10.7rem !important;
    margin-bottom: 10.7rem !important; }
  .my-xxl-106 {
    margin-top: 10.6rem !important;
    margin-bottom: 10.6rem !important; }
  .my-xxl-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-xxl-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-xxl-103 {
    margin-top: 10.3rem !important;
    margin-bottom: 10.3rem !important; }
  .my-xxl-102 {
    margin-top: 10.2rem !important;
    margin-bottom: 10.2rem !important; }
  .my-xxl-101 {
    margin-top: 10.1rem !important;
    margin-bottom: 10.1rem !important; }
  .my-xxl-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xxl-99 {
    margin-top: 9.9rem !important;
    margin-bottom: 9.9rem !important; }
  .my-xxl-98 {
    margin-top: 9.8rem !important;
    margin-bottom: 9.8rem !important; }
  .my-xxl-97 {
    margin-top: 9.7rem !important;
    margin-bottom: 9.7rem !important; }
  .my-xxl-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-xxl-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-xxl-94 {
    margin-top: 9.4rem !important;
    margin-bottom: 9.4rem !important; }
  .my-xxl-93 {
    margin-top: 9.3rem !important;
    margin-bottom: 9.3rem !important; }
  .my-xxl-92 {
    margin-top: 9.2rem !important;
    margin-bottom: 9.2rem !important; }
  .my-xxl-91 {
    margin-top: 9.1rem !important;
    margin-bottom: 9.1rem !important; }
  .my-xxl-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xxl-89 {
    margin-top: 8.9rem !important;
    margin-bottom: 8.9rem !important; }
  .my-xxl-88 {
    margin-top: 8.8rem !important;
    margin-bottom: 8.8rem !important; }
  .my-xxl-87 {
    margin-top: 8.7rem !important;
    margin-bottom: 8.7rem !important; }
  .my-xxl-86 {
    margin-top: 8.6rem !important;
    margin-bottom: 8.6rem !important; }
  .my-xxl-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-xxl-84 {
    margin-top: 8.4rem !important;
    margin-bottom: 8.4rem !important; }
  .my-xxl-83 {
    margin-top: 8.3rem !important;
    margin-bottom: 8.3rem !important; }
  .my-xxl-82 {
    margin-top: 8.2rem !important;
    margin-bottom: 8.2rem !important; }
  .my-xxl-81 {
    margin-top: 8.1rem !important;
    margin-bottom: 8.1rem !important; }
  .my-xxl-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-xxl-79 {
    margin-top: 7.9rem !important;
    margin-bottom: 7.9rem !important; }
  .my-xxl-78 {
    margin-top: 7.8rem !important;
    margin-bottom: 7.8rem !important; }
  .my-xxl-77 {
    margin-top: 7.7rem !important;
    margin-bottom: 7.7rem !important; }
  .my-xxl-76 {
    margin-top: 7.6rem !important;
    margin-bottom: 7.6rem !important; }
  .my-xxl-75 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xxl-74 {
    margin-top: 7.4rem !important;
    margin-bottom: 7.4rem !important; }
  .my-xxl-73 {
    margin-top: 7.3rem !important;
    margin-bottom: 7.3rem !important; }
  .my-xxl-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-xxl-71 {
    margin-top: 7.1rem !important;
    margin-bottom: 7.1rem !important; }
  .my-xxl-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-xxl-69 {
    margin-top: 6.9rem !important;
    margin-bottom: 6.9rem !important; }
  .my-xxl-68 {
    margin-top: 6.8rem !important;
    margin-bottom: 6.8rem !important; }
  .my-xxl-67 {
    margin-top: 6.7rem !important;
    margin-bottom: 6.7rem !important; }
  .my-xxl-66 {
    margin-top: 6.6rem !important;
    margin-bottom: 6.6rem !important; }
  .my-xxl-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-xxl-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-xxl-63 {
    margin-top: 6.3rem !important;
    margin-bottom: 6.3rem !important; }
  .my-xxl-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-xxl-61 {
    margin-top: 6.1rem !important;
    margin-bottom: 6.1rem !important; }
  .my-xxl-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxl-59 {
    margin-top: 5.9rem !important;
    margin-bottom: 5.9rem !important; }
  .my-xxl-58 {
    margin-top: 5.8rem !important;
    margin-bottom: 5.8rem !important; }
  .my-xxl-57 {
    margin-top: 5.7rem !important;
    margin-bottom: 5.7rem !important; }
  .my-xxl-56 {
    margin-top: 5.6rem !important;
    margin-bottom: 5.6rem !important; }
  .my-xxl-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-xxl-54 {
    margin-top: 5.4rem !important;
    margin-bottom: 5.4rem !important; }
  .my-xxl-53 {
    margin-top: 5.3rem !important;
    margin-bottom: 5.3rem !important; }
  .my-xxl-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-xxl-51 {
    margin-top: 5.1rem !important;
    margin-bottom: 5.1rem !important; }
  .my-xxl-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxl-49 {
    margin-top: 4.9rem !important;
    margin-bottom: 4.9rem !important; }
  .my-xxl-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-xxl-47 {
    margin-top: 4.7rem !important;
    margin-bottom: 4.7rem !important; }
  .my-xxl-46 {
    margin-top: 4.6rem !important;
    margin-bottom: 4.6rem !important; }
  .my-xxl-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xxl-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-xxl-43 {
    margin-top: 4.3rem !important;
    margin-bottom: 4.3rem !important; }
  .my-xxl-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-xxl-41 {
    margin-top: 4.1rem !important;
    margin-bottom: 4.1rem !important; }
  .my-xxl-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xxl-39 {
    margin-top: 3.9rem !important;
    margin-bottom: 3.9rem !important; }
  .my-xxl-38 {
    margin-top: 3.8rem !important;
    margin-bottom: 3.8rem !important; }
  .my-xxl-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-xxl-36 {
    margin-top: 3.6rem !important;
    margin-bottom: 3.6rem !important; }
  .my-xxl-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xxl-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-xxl-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-xxl-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-xxl-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-xxl-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-xxl-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-xxl-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-xxl-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-xxl-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxl-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-xxl-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-xxl-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-xxl-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-xxl-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xxl-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-xxl-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-xxl-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-xxl-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-xxl-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-xxl-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-xxl-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-xxl-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-xxl-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-n80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .my-xxl-n100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .my-xxl-n150 {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-150 {
    margin-top: 15rem !important; }
  .mt-xxl-149 {
    margin-top: 14.9rem !important; }
  .mt-xxl-148 {
    margin-top: 14.8rem !important; }
  .mt-xxl-147 {
    margin-top: 14.7rem !important; }
  .mt-xxl-146 {
    margin-top: 14.6rem !important; }
  .mt-xxl-145 {
    margin-top: 14.5rem !important; }
  .mt-xxl-144 {
    margin-top: 14.4rem !important; }
  .mt-xxl-143 {
    margin-top: 14.3rem !important; }
  .mt-xxl-142 {
    margin-top: 14.2rem !important; }
  .mt-xxl-141 {
    margin-top: 14.1rem !important; }
  .mt-xxl-140 {
    margin-top: 14rem !important; }
  .mt-xxl-139 {
    margin-top: 13.9rem !important; }
  .mt-xxl-138 {
    margin-top: 13.8rem !important; }
  .mt-xxl-137 {
    margin-top: 13.7rem !important; }
  .mt-xxl-136 {
    margin-top: 13.6rem !important; }
  .mt-xxl-135 {
    margin-top: 13.5rem !important; }
  .mt-xxl-134 {
    margin-top: 13.4rem !important; }
  .mt-xxl-133 {
    margin-top: 13.3rem !important; }
  .mt-xxl-132 {
    margin-top: 13.2rem !important; }
  .mt-xxl-131 {
    margin-top: 13.1rem !important; }
  .mt-xxl-130 {
    margin-top: 13rem !important; }
  .mt-xxl-129 {
    margin-top: 12.9rem !important; }
  .mt-xxl-128 {
    margin-top: 12.8rem !important; }
  .mt-xxl-127 {
    margin-top: 12.7rem !important; }
  .mt-xxl-126 {
    margin-top: 12.6rem !important; }
  .mt-xxl-125 {
    margin-top: 12.5rem !important; }
  .mt-xxl-124 {
    margin-top: 12.4rem !important; }
  .mt-xxl-123 {
    margin-top: 12.3rem !important; }
  .mt-xxl-122 {
    margin-top: 12.2rem !important; }
  .mt-xxl-121 {
    margin-top: 12.1rem !important; }
  .mt-xxl-120 {
    margin-top: 12rem !important; }
  .mt-xxl-119 {
    margin-top: 11.9rem !important; }
  .mt-xxl-118 {
    margin-top: 11.8rem !important; }
  .mt-xxl-117 {
    margin-top: 11.7rem !important; }
  .mt-xxl-116 {
    margin-top: 11.6rem !important; }
  .mt-xxl-115 {
    margin-top: 11.5rem !important; }
  .mt-xxl-114 {
    margin-top: 11.4rem !important; }
  .mt-xxl-113 {
    margin-top: 11.3rem !important; }
  .mt-xxl-112 {
    margin-top: 11.2rem !important; }
  .mt-xxl-111 {
    margin-top: 11.1rem !important; }
  .mt-xxl-110 {
    margin-top: 11rem !important; }
  .mt-xxl-109 {
    margin-top: 10.9rem !important; }
  .mt-xxl-108 {
    margin-top: 10.8rem !important; }
  .mt-xxl-107 {
    margin-top: 10.7rem !important; }
  .mt-xxl-106 {
    margin-top: 10.6rem !important; }
  .mt-xxl-105 {
    margin-top: 10.5rem !important; }
  .mt-xxl-104 {
    margin-top: 10.4rem !important; }
  .mt-xxl-103 {
    margin-top: 10.3rem !important; }
  .mt-xxl-102 {
    margin-top: 10.2rem !important; }
  .mt-xxl-101 {
    margin-top: 10.1rem !important; }
  .mt-xxl-100 {
    margin-top: 10rem !important; }
  .mt-xxl-99 {
    margin-top: 9.9rem !important; }
  .mt-xxl-98 {
    margin-top: 9.8rem !important; }
  .mt-xxl-97 {
    margin-top: 9.7rem !important; }
  .mt-xxl-96 {
    margin-top: 9.6rem !important; }
  .mt-xxl-95 {
    margin-top: 9.5rem !important; }
  .mt-xxl-94 {
    margin-top: 9.4rem !important; }
  .mt-xxl-93 {
    margin-top: 9.3rem !important; }
  .mt-xxl-92 {
    margin-top: 9.2rem !important; }
  .mt-xxl-91 {
    margin-top: 9.1rem !important; }
  .mt-xxl-90 {
    margin-top: 9rem !important; }
  .mt-xxl-89 {
    margin-top: 8.9rem !important; }
  .mt-xxl-88 {
    margin-top: 8.8rem !important; }
  .mt-xxl-87 {
    margin-top: 8.7rem !important; }
  .mt-xxl-86 {
    margin-top: 8.6rem !important; }
  .mt-xxl-85 {
    margin-top: 8.5rem !important; }
  .mt-xxl-84 {
    margin-top: 8.4rem !important; }
  .mt-xxl-83 {
    margin-top: 8.3rem !important; }
  .mt-xxl-82 {
    margin-top: 8.2rem !important; }
  .mt-xxl-81 {
    margin-top: 8.1rem !important; }
  .mt-xxl-80 {
    margin-top: 8rem !important; }
  .mt-xxl-79 {
    margin-top: 7.9rem !important; }
  .mt-xxl-78 {
    margin-top: 7.8rem !important; }
  .mt-xxl-77 {
    margin-top: 7.7rem !important; }
  .mt-xxl-76 {
    margin-top: 7.6rem !important; }
  .mt-xxl-75 {
    margin-top: 7.5rem !important; }
  .mt-xxl-74 {
    margin-top: 7.4rem !important; }
  .mt-xxl-73 {
    margin-top: 7.3rem !important; }
  .mt-xxl-72 {
    margin-top: 7.2rem !important; }
  .mt-xxl-71 {
    margin-top: 7.1rem !important; }
  .mt-xxl-70 {
    margin-top: 7rem !important; }
  .mt-xxl-69 {
    margin-top: 6.9rem !important; }
  .mt-xxl-68 {
    margin-top: 6.8rem !important; }
  .mt-xxl-67 {
    margin-top: 6.7rem !important; }
  .mt-xxl-66 {
    margin-top: 6.6rem !important; }
  .mt-xxl-65 {
    margin-top: 6.5rem !important; }
  .mt-xxl-64 {
    margin-top: 6.4rem !important; }
  .mt-xxl-63 {
    margin-top: 6.3rem !important; }
  .mt-xxl-62 {
    margin-top: 6.2rem !important; }
  .mt-xxl-61 {
    margin-top: 6.1rem !important; }
  .mt-xxl-60 {
    margin-top: 6rem !important; }
  .mt-xxl-59 {
    margin-top: 5.9rem !important; }
  .mt-xxl-58 {
    margin-top: 5.8rem !important; }
  .mt-xxl-57 {
    margin-top: 5.7rem !important; }
  .mt-xxl-56 {
    margin-top: 5.6rem !important; }
  .mt-xxl-55 {
    margin-top: 5.5rem !important; }
  .mt-xxl-54 {
    margin-top: 5.4rem !important; }
  .mt-xxl-53 {
    margin-top: 5.3rem !important; }
  .mt-xxl-52 {
    margin-top: 5.2rem !important; }
  .mt-xxl-51 {
    margin-top: 5.1rem !important; }
  .mt-xxl-50 {
    margin-top: 5rem !important; }
  .mt-xxl-49 {
    margin-top: 4.9rem !important; }
  .mt-xxl-48 {
    margin-top: 4.8rem !important; }
  .mt-xxl-47 {
    margin-top: 4.7rem !important; }
  .mt-xxl-46 {
    margin-top: 4.6rem !important; }
  .mt-xxl-45 {
    margin-top: 4.5rem !important; }
  .mt-xxl-44 {
    margin-top: 4.4rem !important; }
  .mt-xxl-43 {
    margin-top: 4.3rem !important; }
  .mt-xxl-42 {
    margin-top: 4.2rem !important; }
  .mt-xxl-41 {
    margin-top: 4.1rem !important; }
  .mt-xxl-40 {
    margin-top: 4rem !important; }
  .mt-xxl-39 {
    margin-top: 3.9rem !important; }
  .mt-xxl-38 {
    margin-top: 3.8rem !important; }
  .mt-xxl-37 {
    margin-top: 3.7rem !important; }
  .mt-xxl-36 {
    margin-top: 3.6rem !important; }
  .mt-xxl-35 {
    margin-top: 3.5rem !important; }
  .mt-xxl-34 {
    margin-top: 3.4rem !important; }
  .mt-xxl-33 {
    margin-top: 3.3rem !important; }
  .mt-xxl-32 {
    margin-top: 3.2rem !important; }
  .mt-xxl-31 {
    margin-top: 3.1rem !important; }
  .mt-xxl-30 {
    margin-top: 3rem !important; }
  .mt-xxl-29 {
    margin-top: 2.9rem !important; }
  .mt-xxl-28 {
    margin-top: 2.8rem !important; }
  .mt-xxl-27 {
    margin-top: 2.7rem !important; }
  .mt-xxl-26 {
    margin-top: 2.6rem !important; }
  .mt-xxl-25 {
    margin-top: 2.5rem !important; }
  .mt-xxl-24 {
    margin-top: 2.4rem !important; }
  .mt-xxl-23 {
    margin-top: 2.3rem !important; }
  .mt-xxl-22 {
    margin-top: 2.2rem !important; }
  .mt-xxl-21 {
    margin-top: 2.1rem !important; }
  .mt-xxl-20 {
    margin-top: 2rem !important; }
  .mt-xxl-19 {
    margin-top: 1.9rem !important; }
  .mt-xxl-18 {
    margin-top: 1.8rem !important; }
  .mt-xxl-17 {
    margin-top: 1.7rem !important; }
  .mt-xxl-16 {
    margin-top: 1.6rem !important; }
  .mt-xxl-15 {
    margin-top: 1.5rem !important; }
  .mt-xxl-14 {
    margin-top: 1.4rem !important; }
  .mt-xxl-13 {
    margin-top: 1.3rem !important; }
  .mt-xxl-12 {
    margin-top: 1.2rem !important; }
  .mt-xxl-11 {
    margin-top: 1.1rem !important; }
  .mt-xxl-10 {
    margin-top: 1rem !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-n80 {
    margin-top: -80px !important; }
  .mt-xxl-n100 {
    margin-top: -100px !important; }
  .mt-xxl-n150 {
    margin-top: -150px !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-150 {
    margin-right: 15rem !important; }
  .me-xxl-149 {
    margin-right: 14.9rem !important; }
  .me-xxl-148 {
    margin-right: 14.8rem !important; }
  .me-xxl-147 {
    margin-right: 14.7rem !important; }
  .me-xxl-146 {
    margin-right: 14.6rem !important; }
  .me-xxl-145 {
    margin-right: 14.5rem !important; }
  .me-xxl-144 {
    margin-right: 14.4rem !important; }
  .me-xxl-143 {
    margin-right: 14.3rem !important; }
  .me-xxl-142 {
    margin-right: 14.2rem !important; }
  .me-xxl-141 {
    margin-right: 14.1rem !important; }
  .me-xxl-140 {
    margin-right: 14rem !important; }
  .me-xxl-139 {
    margin-right: 13.9rem !important; }
  .me-xxl-138 {
    margin-right: 13.8rem !important; }
  .me-xxl-137 {
    margin-right: 13.7rem !important; }
  .me-xxl-136 {
    margin-right: 13.6rem !important; }
  .me-xxl-135 {
    margin-right: 13.5rem !important; }
  .me-xxl-134 {
    margin-right: 13.4rem !important; }
  .me-xxl-133 {
    margin-right: 13.3rem !important; }
  .me-xxl-132 {
    margin-right: 13.2rem !important; }
  .me-xxl-131 {
    margin-right: 13.1rem !important; }
  .me-xxl-130 {
    margin-right: 13rem !important; }
  .me-xxl-129 {
    margin-right: 12.9rem !important; }
  .me-xxl-128 {
    margin-right: 12.8rem !important; }
  .me-xxl-127 {
    margin-right: 12.7rem !important; }
  .me-xxl-126 {
    margin-right: 12.6rem !important; }
  .me-xxl-125 {
    margin-right: 12.5rem !important; }
  .me-xxl-124 {
    margin-right: 12.4rem !important; }
  .me-xxl-123 {
    margin-right: 12.3rem !important; }
  .me-xxl-122 {
    margin-right: 12.2rem !important; }
  .me-xxl-121 {
    margin-right: 12.1rem !important; }
  .me-xxl-120 {
    margin-right: 12rem !important; }
  .me-xxl-119 {
    margin-right: 11.9rem !important; }
  .me-xxl-118 {
    margin-right: 11.8rem !important; }
  .me-xxl-117 {
    margin-right: 11.7rem !important; }
  .me-xxl-116 {
    margin-right: 11.6rem !important; }
  .me-xxl-115 {
    margin-right: 11.5rem !important; }
  .me-xxl-114 {
    margin-right: 11.4rem !important; }
  .me-xxl-113 {
    margin-right: 11.3rem !important; }
  .me-xxl-112 {
    margin-right: 11.2rem !important; }
  .me-xxl-111 {
    margin-right: 11.1rem !important; }
  .me-xxl-110 {
    margin-right: 11rem !important; }
  .me-xxl-109 {
    margin-right: 10.9rem !important; }
  .me-xxl-108 {
    margin-right: 10.8rem !important; }
  .me-xxl-107 {
    margin-right: 10.7rem !important; }
  .me-xxl-106 {
    margin-right: 10.6rem !important; }
  .me-xxl-105 {
    margin-right: 10.5rem !important; }
  .me-xxl-104 {
    margin-right: 10.4rem !important; }
  .me-xxl-103 {
    margin-right: 10.3rem !important; }
  .me-xxl-102 {
    margin-right: 10.2rem !important; }
  .me-xxl-101 {
    margin-right: 10.1rem !important; }
  .me-xxl-100 {
    margin-right: 10rem !important; }
  .me-xxl-99 {
    margin-right: 9.9rem !important; }
  .me-xxl-98 {
    margin-right: 9.8rem !important; }
  .me-xxl-97 {
    margin-right: 9.7rem !important; }
  .me-xxl-96 {
    margin-right: 9.6rem !important; }
  .me-xxl-95 {
    margin-right: 9.5rem !important; }
  .me-xxl-94 {
    margin-right: 9.4rem !important; }
  .me-xxl-93 {
    margin-right: 9.3rem !important; }
  .me-xxl-92 {
    margin-right: 9.2rem !important; }
  .me-xxl-91 {
    margin-right: 9.1rem !important; }
  .me-xxl-90 {
    margin-right: 9rem !important; }
  .me-xxl-89 {
    margin-right: 8.9rem !important; }
  .me-xxl-88 {
    margin-right: 8.8rem !important; }
  .me-xxl-87 {
    margin-right: 8.7rem !important; }
  .me-xxl-86 {
    margin-right: 8.6rem !important; }
  .me-xxl-85 {
    margin-right: 8.5rem !important; }
  .me-xxl-84 {
    margin-right: 8.4rem !important; }
  .me-xxl-83 {
    margin-right: 8.3rem !important; }
  .me-xxl-82 {
    margin-right: 8.2rem !important; }
  .me-xxl-81 {
    margin-right: 8.1rem !important; }
  .me-xxl-80 {
    margin-right: 8rem !important; }
  .me-xxl-79 {
    margin-right: 7.9rem !important; }
  .me-xxl-78 {
    margin-right: 7.8rem !important; }
  .me-xxl-77 {
    margin-right: 7.7rem !important; }
  .me-xxl-76 {
    margin-right: 7.6rem !important; }
  .me-xxl-75 {
    margin-right: 7.5rem !important; }
  .me-xxl-74 {
    margin-right: 7.4rem !important; }
  .me-xxl-73 {
    margin-right: 7.3rem !important; }
  .me-xxl-72 {
    margin-right: 7.2rem !important; }
  .me-xxl-71 {
    margin-right: 7.1rem !important; }
  .me-xxl-70 {
    margin-right: 7rem !important; }
  .me-xxl-69 {
    margin-right: 6.9rem !important; }
  .me-xxl-68 {
    margin-right: 6.8rem !important; }
  .me-xxl-67 {
    margin-right: 6.7rem !important; }
  .me-xxl-66 {
    margin-right: 6.6rem !important; }
  .me-xxl-65 {
    margin-right: 6.5rem !important; }
  .me-xxl-64 {
    margin-right: 6.4rem !important; }
  .me-xxl-63 {
    margin-right: 6.3rem !important; }
  .me-xxl-62 {
    margin-right: 6.2rem !important; }
  .me-xxl-61 {
    margin-right: 6.1rem !important; }
  .me-xxl-60 {
    margin-right: 6rem !important; }
  .me-xxl-59 {
    margin-right: 5.9rem !important; }
  .me-xxl-58 {
    margin-right: 5.8rem !important; }
  .me-xxl-57 {
    margin-right: 5.7rem !important; }
  .me-xxl-56 {
    margin-right: 5.6rem !important; }
  .me-xxl-55 {
    margin-right: 5.5rem !important; }
  .me-xxl-54 {
    margin-right: 5.4rem !important; }
  .me-xxl-53 {
    margin-right: 5.3rem !important; }
  .me-xxl-52 {
    margin-right: 5.2rem !important; }
  .me-xxl-51 {
    margin-right: 5.1rem !important; }
  .me-xxl-50 {
    margin-right: 5rem !important; }
  .me-xxl-49 {
    margin-right: 4.9rem !important; }
  .me-xxl-48 {
    margin-right: 4.8rem !important; }
  .me-xxl-47 {
    margin-right: 4.7rem !important; }
  .me-xxl-46 {
    margin-right: 4.6rem !important; }
  .me-xxl-45 {
    margin-right: 4.5rem !important; }
  .me-xxl-44 {
    margin-right: 4.4rem !important; }
  .me-xxl-43 {
    margin-right: 4.3rem !important; }
  .me-xxl-42 {
    margin-right: 4.2rem !important; }
  .me-xxl-41 {
    margin-right: 4.1rem !important; }
  .me-xxl-40 {
    margin-right: 4rem !important; }
  .me-xxl-39 {
    margin-right: 3.9rem !important; }
  .me-xxl-38 {
    margin-right: 3.8rem !important; }
  .me-xxl-37 {
    margin-right: 3.7rem !important; }
  .me-xxl-36 {
    margin-right: 3.6rem !important; }
  .me-xxl-35 {
    margin-right: 3.5rem !important; }
  .me-xxl-34 {
    margin-right: 3.4rem !important; }
  .me-xxl-33 {
    margin-right: 3.3rem !important; }
  .me-xxl-32 {
    margin-right: 3.2rem !important; }
  .me-xxl-31 {
    margin-right: 3.1rem !important; }
  .me-xxl-30 {
    margin-right: 3rem !important; }
  .me-xxl-29 {
    margin-right: 2.9rem !important; }
  .me-xxl-28 {
    margin-right: 2.8rem !important; }
  .me-xxl-27 {
    margin-right: 2.7rem !important; }
  .me-xxl-26 {
    margin-right: 2.6rem !important; }
  .me-xxl-25 {
    margin-right: 2.5rem !important; }
  .me-xxl-24 {
    margin-right: 2.4rem !important; }
  .me-xxl-23 {
    margin-right: 2.3rem !important; }
  .me-xxl-22 {
    margin-right: 2.2rem !important; }
  .me-xxl-21 {
    margin-right: 2.1rem !important; }
  .me-xxl-20 {
    margin-right: 2rem !important; }
  .me-xxl-19 {
    margin-right: 1.9rem !important; }
  .me-xxl-18 {
    margin-right: 1.8rem !important; }
  .me-xxl-17 {
    margin-right: 1.7rem !important; }
  .me-xxl-16 {
    margin-right: 1.6rem !important; }
  .me-xxl-15 {
    margin-right: 1.5rem !important; }
  .me-xxl-14 {
    margin-right: 1.4rem !important; }
  .me-xxl-13 {
    margin-right: 1.3rem !important; }
  .me-xxl-12 {
    margin-right: 1.2rem !important; }
  .me-xxl-11 {
    margin-right: 1.1rem !important; }
  .me-xxl-10 {
    margin-right: 1rem !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-n80 {
    margin-right: -80px !important; }
  .me-xxl-n100 {
    margin-right: -100px !important; }
  .me-xxl-n150 {
    margin-right: -150px !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-150 {
    margin-bottom: 15rem !important; }
  .mb-xxl-149 {
    margin-bottom: 14.9rem !important; }
  .mb-xxl-148 {
    margin-bottom: 14.8rem !important; }
  .mb-xxl-147 {
    margin-bottom: 14.7rem !important; }
  .mb-xxl-146 {
    margin-bottom: 14.6rem !important; }
  .mb-xxl-145 {
    margin-bottom: 14.5rem !important; }
  .mb-xxl-144 {
    margin-bottom: 14.4rem !important; }
  .mb-xxl-143 {
    margin-bottom: 14.3rem !important; }
  .mb-xxl-142 {
    margin-bottom: 14.2rem !important; }
  .mb-xxl-141 {
    margin-bottom: 14.1rem !important; }
  .mb-xxl-140 {
    margin-bottom: 14rem !important; }
  .mb-xxl-139 {
    margin-bottom: 13.9rem !important; }
  .mb-xxl-138 {
    margin-bottom: 13.8rem !important; }
  .mb-xxl-137 {
    margin-bottom: 13.7rem !important; }
  .mb-xxl-136 {
    margin-bottom: 13.6rem !important; }
  .mb-xxl-135 {
    margin-bottom: 13.5rem !important; }
  .mb-xxl-134 {
    margin-bottom: 13.4rem !important; }
  .mb-xxl-133 {
    margin-bottom: 13.3rem !important; }
  .mb-xxl-132 {
    margin-bottom: 13.2rem !important; }
  .mb-xxl-131 {
    margin-bottom: 13.1rem !important; }
  .mb-xxl-130 {
    margin-bottom: 13rem !important; }
  .mb-xxl-129 {
    margin-bottom: 12.9rem !important; }
  .mb-xxl-128 {
    margin-bottom: 12.8rem !important; }
  .mb-xxl-127 {
    margin-bottom: 12.7rem !important; }
  .mb-xxl-126 {
    margin-bottom: 12.6rem !important; }
  .mb-xxl-125 {
    margin-bottom: 12.5rem !important; }
  .mb-xxl-124 {
    margin-bottom: 12.4rem !important; }
  .mb-xxl-123 {
    margin-bottom: 12.3rem !important; }
  .mb-xxl-122 {
    margin-bottom: 12.2rem !important; }
  .mb-xxl-121 {
    margin-bottom: 12.1rem !important; }
  .mb-xxl-120 {
    margin-bottom: 12rem !important; }
  .mb-xxl-119 {
    margin-bottom: 11.9rem !important; }
  .mb-xxl-118 {
    margin-bottom: 11.8rem !important; }
  .mb-xxl-117 {
    margin-bottom: 11.7rem !important; }
  .mb-xxl-116 {
    margin-bottom: 11.6rem !important; }
  .mb-xxl-115 {
    margin-bottom: 11.5rem !important; }
  .mb-xxl-114 {
    margin-bottom: 11.4rem !important; }
  .mb-xxl-113 {
    margin-bottom: 11.3rem !important; }
  .mb-xxl-112 {
    margin-bottom: 11.2rem !important; }
  .mb-xxl-111 {
    margin-bottom: 11.1rem !important; }
  .mb-xxl-110 {
    margin-bottom: 11rem !important; }
  .mb-xxl-109 {
    margin-bottom: 10.9rem !important; }
  .mb-xxl-108 {
    margin-bottom: 10.8rem !important; }
  .mb-xxl-107 {
    margin-bottom: 10.7rem !important; }
  .mb-xxl-106 {
    margin-bottom: 10.6rem !important; }
  .mb-xxl-105 {
    margin-bottom: 10.5rem !important; }
  .mb-xxl-104 {
    margin-bottom: 10.4rem !important; }
  .mb-xxl-103 {
    margin-bottom: 10.3rem !important; }
  .mb-xxl-102 {
    margin-bottom: 10.2rem !important; }
  .mb-xxl-101 {
    margin-bottom: 10.1rem !important; }
  .mb-xxl-100 {
    margin-bottom: 10rem !important; }
  .mb-xxl-99 {
    margin-bottom: 9.9rem !important; }
  .mb-xxl-98 {
    margin-bottom: 9.8rem !important; }
  .mb-xxl-97 {
    margin-bottom: 9.7rem !important; }
  .mb-xxl-96 {
    margin-bottom: 9.6rem !important; }
  .mb-xxl-95 {
    margin-bottom: 9.5rem !important; }
  .mb-xxl-94 {
    margin-bottom: 9.4rem !important; }
  .mb-xxl-93 {
    margin-bottom: 9.3rem !important; }
  .mb-xxl-92 {
    margin-bottom: 9.2rem !important; }
  .mb-xxl-91 {
    margin-bottom: 9.1rem !important; }
  .mb-xxl-90 {
    margin-bottom: 9rem !important; }
  .mb-xxl-89 {
    margin-bottom: 8.9rem !important; }
  .mb-xxl-88 {
    margin-bottom: 8.8rem !important; }
  .mb-xxl-87 {
    margin-bottom: 8.7rem !important; }
  .mb-xxl-86 {
    margin-bottom: 8.6rem !important; }
  .mb-xxl-85 {
    margin-bottom: 8.5rem !important; }
  .mb-xxl-84 {
    margin-bottom: 8.4rem !important; }
  .mb-xxl-83 {
    margin-bottom: 8.3rem !important; }
  .mb-xxl-82 {
    margin-bottom: 8.2rem !important; }
  .mb-xxl-81 {
    margin-bottom: 8.1rem !important; }
  .mb-xxl-80 {
    margin-bottom: 8rem !important; }
  .mb-xxl-79 {
    margin-bottom: 7.9rem !important; }
  .mb-xxl-78 {
    margin-bottom: 7.8rem !important; }
  .mb-xxl-77 {
    margin-bottom: 7.7rem !important; }
  .mb-xxl-76 {
    margin-bottom: 7.6rem !important; }
  .mb-xxl-75 {
    margin-bottom: 7.5rem !important; }
  .mb-xxl-74 {
    margin-bottom: 7.4rem !important; }
  .mb-xxl-73 {
    margin-bottom: 7.3rem !important; }
  .mb-xxl-72 {
    margin-bottom: 7.2rem !important; }
  .mb-xxl-71 {
    margin-bottom: 7.1rem !important; }
  .mb-xxl-70 {
    margin-bottom: 7rem !important; }
  .mb-xxl-69 {
    margin-bottom: 6.9rem !important; }
  .mb-xxl-68 {
    margin-bottom: 6.8rem !important; }
  .mb-xxl-67 {
    margin-bottom: 6.7rem !important; }
  .mb-xxl-66 {
    margin-bottom: 6.6rem !important; }
  .mb-xxl-65 {
    margin-bottom: 6.5rem !important; }
  .mb-xxl-64 {
    margin-bottom: 6.4rem !important; }
  .mb-xxl-63 {
    margin-bottom: 6.3rem !important; }
  .mb-xxl-62 {
    margin-bottom: 6.2rem !important; }
  .mb-xxl-61 {
    margin-bottom: 6.1rem !important; }
  .mb-xxl-60 {
    margin-bottom: 6rem !important; }
  .mb-xxl-59 {
    margin-bottom: 5.9rem !important; }
  .mb-xxl-58 {
    margin-bottom: 5.8rem !important; }
  .mb-xxl-57 {
    margin-bottom: 5.7rem !important; }
  .mb-xxl-56 {
    margin-bottom: 5.6rem !important; }
  .mb-xxl-55 {
    margin-bottom: 5.5rem !important; }
  .mb-xxl-54 {
    margin-bottom: 5.4rem !important; }
  .mb-xxl-53 {
    margin-bottom: 5.3rem !important; }
  .mb-xxl-52 {
    margin-bottom: 5.2rem !important; }
  .mb-xxl-51 {
    margin-bottom: 5.1rem !important; }
  .mb-xxl-50 {
    margin-bottom: 5rem !important; }
  .mb-xxl-49 {
    margin-bottom: 4.9rem !important; }
  .mb-xxl-48 {
    margin-bottom: 4.8rem !important; }
  .mb-xxl-47 {
    margin-bottom: 4.7rem !important; }
  .mb-xxl-46 {
    margin-bottom: 4.6rem !important; }
  .mb-xxl-45 {
    margin-bottom: 4.5rem !important; }
  .mb-xxl-44 {
    margin-bottom: 4.4rem !important; }
  .mb-xxl-43 {
    margin-bottom: 4.3rem !important; }
  .mb-xxl-42 {
    margin-bottom: 4.2rem !important; }
  .mb-xxl-41 {
    margin-bottom: 4.1rem !important; }
  .mb-xxl-40 {
    margin-bottom: 4rem !important; }
  .mb-xxl-39 {
    margin-bottom: 3.9rem !important; }
  .mb-xxl-38 {
    margin-bottom: 3.8rem !important; }
  .mb-xxl-37 {
    margin-bottom: 3.7rem !important; }
  .mb-xxl-36 {
    margin-bottom: 3.6rem !important; }
  .mb-xxl-35 {
    margin-bottom: 3.5rem !important; }
  .mb-xxl-34 {
    margin-bottom: 3.4rem !important; }
  .mb-xxl-33 {
    margin-bottom: 3.3rem !important; }
  .mb-xxl-32 {
    margin-bottom: 3.2rem !important; }
  .mb-xxl-31 {
    margin-bottom: 3.1rem !important; }
  .mb-xxl-30 {
    margin-bottom: 3rem !important; }
  .mb-xxl-29 {
    margin-bottom: 2.9rem !important; }
  .mb-xxl-28 {
    margin-bottom: 2.8rem !important; }
  .mb-xxl-27 {
    margin-bottom: 2.7rem !important; }
  .mb-xxl-26 {
    margin-bottom: 2.6rem !important; }
  .mb-xxl-25 {
    margin-bottom: 2.5rem !important; }
  .mb-xxl-24 {
    margin-bottom: 2.4rem !important; }
  .mb-xxl-23 {
    margin-bottom: 2.3rem !important; }
  .mb-xxl-22 {
    margin-bottom: 2.2rem !important; }
  .mb-xxl-21 {
    margin-bottom: 2.1rem !important; }
  .mb-xxl-20 {
    margin-bottom: 2rem !important; }
  .mb-xxl-19 {
    margin-bottom: 1.9rem !important; }
  .mb-xxl-18 {
    margin-bottom: 1.8rem !important; }
  .mb-xxl-17 {
    margin-bottom: 1.7rem !important; }
  .mb-xxl-16 {
    margin-bottom: 1.6rem !important; }
  .mb-xxl-15 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-14 {
    margin-bottom: 1.4rem !important; }
  .mb-xxl-13 {
    margin-bottom: 1.3rem !important; }
  .mb-xxl-12 {
    margin-bottom: 1.2rem !important; }
  .mb-xxl-11 {
    margin-bottom: 1.1rem !important; }
  .mb-xxl-10 {
    margin-bottom: 1rem !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-n80 {
    margin-bottom: -80px !important; }
  .mb-xxl-n100 {
    margin-bottom: -100px !important; }
  .mb-xxl-n150 {
    margin-bottom: -150px !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-150 {
    margin-left: 15rem !important; }
  .ms-xxl-149 {
    margin-left: 14.9rem !important; }
  .ms-xxl-148 {
    margin-left: 14.8rem !important; }
  .ms-xxl-147 {
    margin-left: 14.7rem !important; }
  .ms-xxl-146 {
    margin-left: 14.6rem !important; }
  .ms-xxl-145 {
    margin-left: 14.5rem !important; }
  .ms-xxl-144 {
    margin-left: 14.4rem !important; }
  .ms-xxl-143 {
    margin-left: 14.3rem !important; }
  .ms-xxl-142 {
    margin-left: 14.2rem !important; }
  .ms-xxl-141 {
    margin-left: 14.1rem !important; }
  .ms-xxl-140 {
    margin-left: 14rem !important; }
  .ms-xxl-139 {
    margin-left: 13.9rem !important; }
  .ms-xxl-138 {
    margin-left: 13.8rem !important; }
  .ms-xxl-137 {
    margin-left: 13.7rem !important; }
  .ms-xxl-136 {
    margin-left: 13.6rem !important; }
  .ms-xxl-135 {
    margin-left: 13.5rem !important; }
  .ms-xxl-134 {
    margin-left: 13.4rem !important; }
  .ms-xxl-133 {
    margin-left: 13.3rem !important; }
  .ms-xxl-132 {
    margin-left: 13.2rem !important; }
  .ms-xxl-131 {
    margin-left: 13.1rem !important; }
  .ms-xxl-130 {
    margin-left: 13rem !important; }
  .ms-xxl-129 {
    margin-left: 12.9rem !important; }
  .ms-xxl-128 {
    margin-left: 12.8rem !important; }
  .ms-xxl-127 {
    margin-left: 12.7rem !important; }
  .ms-xxl-126 {
    margin-left: 12.6rem !important; }
  .ms-xxl-125 {
    margin-left: 12.5rem !important; }
  .ms-xxl-124 {
    margin-left: 12.4rem !important; }
  .ms-xxl-123 {
    margin-left: 12.3rem !important; }
  .ms-xxl-122 {
    margin-left: 12.2rem !important; }
  .ms-xxl-121 {
    margin-left: 12.1rem !important; }
  .ms-xxl-120 {
    margin-left: 12rem !important; }
  .ms-xxl-119 {
    margin-left: 11.9rem !important; }
  .ms-xxl-118 {
    margin-left: 11.8rem !important; }
  .ms-xxl-117 {
    margin-left: 11.7rem !important; }
  .ms-xxl-116 {
    margin-left: 11.6rem !important; }
  .ms-xxl-115 {
    margin-left: 11.5rem !important; }
  .ms-xxl-114 {
    margin-left: 11.4rem !important; }
  .ms-xxl-113 {
    margin-left: 11.3rem !important; }
  .ms-xxl-112 {
    margin-left: 11.2rem !important; }
  .ms-xxl-111 {
    margin-left: 11.1rem !important; }
  .ms-xxl-110 {
    margin-left: 11rem !important; }
  .ms-xxl-109 {
    margin-left: 10.9rem !important; }
  .ms-xxl-108 {
    margin-left: 10.8rem !important; }
  .ms-xxl-107 {
    margin-left: 10.7rem !important; }
  .ms-xxl-106 {
    margin-left: 10.6rem !important; }
  .ms-xxl-105 {
    margin-left: 10.5rem !important; }
  .ms-xxl-104 {
    margin-left: 10.4rem !important; }
  .ms-xxl-103 {
    margin-left: 10.3rem !important; }
  .ms-xxl-102 {
    margin-left: 10.2rem !important; }
  .ms-xxl-101 {
    margin-left: 10.1rem !important; }
  .ms-xxl-100 {
    margin-left: 10rem !important; }
  .ms-xxl-99 {
    margin-left: 9.9rem !important; }
  .ms-xxl-98 {
    margin-left: 9.8rem !important; }
  .ms-xxl-97 {
    margin-left: 9.7rem !important; }
  .ms-xxl-96 {
    margin-left: 9.6rem !important; }
  .ms-xxl-95 {
    margin-left: 9.5rem !important; }
  .ms-xxl-94 {
    margin-left: 9.4rem !important; }
  .ms-xxl-93 {
    margin-left: 9.3rem !important; }
  .ms-xxl-92 {
    margin-left: 9.2rem !important; }
  .ms-xxl-91 {
    margin-left: 9.1rem !important; }
  .ms-xxl-90 {
    margin-left: 9rem !important; }
  .ms-xxl-89 {
    margin-left: 8.9rem !important; }
  .ms-xxl-88 {
    margin-left: 8.8rem !important; }
  .ms-xxl-87 {
    margin-left: 8.7rem !important; }
  .ms-xxl-86 {
    margin-left: 8.6rem !important; }
  .ms-xxl-85 {
    margin-left: 8.5rem !important; }
  .ms-xxl-84 {
    margin-left: 8.4rem !important; }
  .ms-xxl-83 {
    margin-left: 8.3rem !important; }
  .ms-xxl-82 {
    margin-left: 8.2rem !important; }
  .ms-xxl-81 {
    margin-left: 8.1rem !important; }
  .ms-xxl-80 {
    margin-left: 8rem !important; }
  .ms-xxl-79 {
    margin-left: 7.9rem !important; }
  .ms-xxl-78 {
    margin-left: 7.8rem !important; }
  .ms-xxl-77 {
    margin-left: 7.7rem !important; }
  .ms-xxl-76 {
    margin-left: 7.6rem !important; }
  .ms-xxl-75 {
    margin-left: 7.5rem !important; }
  .ms-xxl-74 {
    margin-left: 7.4rem !important; }
  .ms-xxl-73 {
    margin-left: 7.3rem !important; }
  .ms-xxl-72 {
    margin-left: 7.2rem !important; }
  .ms-xxl-71 {
    margin-left: 7.1rem !important; }
  .ms-xxl-70 {
    margin-left: 7rem !important; }
  .ms-xxl-69 {
    margin-left: 6.9rem !important; }
  .ms-xxl-68 {
    margin-left: 6.8rem !important; }
  .ms-xxl-67 {
    margin-left: 6.7rem !important; }
  .ms-xxl-66 {
    margin-left: 6.6rem !important; }
  .ms-xxl-65 {
    margin-left: 6.5rem !important; }
  .ms-xxl-64 {
    margin-left: 6.4rem !important; }
  .ms-xxl-63 {
    margin-left: 6.3rem !important; }
  .ms-xxl-62 {
    margin-left: 6.2rem !important; }
  .ms-xxl-61 {
    margin-left: 6.1rem !important; }
  .ms-xxl-60 {
    margin-left: 6rem !important; }
  .ms-xxl-59 {
    margin-left: 5.9rem !important; }
  .ms-xxl-58 {
    margin-left: 5.8rem !important; }
  .ms-xxl-57 {
    margin-left: 5.7rem !important; }
  .ms-xxl-56 {
    margin-left: 5.6rem !important; }
  .ms-xxl-55 {
    margin-left: 5.5rem !important; }
  .ms-xxl-54 {
    margin-left: 5.4rem !important; }
  .ms-xxl-53 {
    margin-left: 5.3rem !important; }
  .ms-xxl-52 {
    margin-left: 5.2rem !important; }
  .ms-xxl-51 {
    margin-left: 5.1rem !important; }
  .ms-xxl-50 {
    margin-left: 5rem !important; }
  .ms-xxl-49 {
    margin-left: 4.9rem !important; }
  .ms-xxl-48 {
    margin-left: 4.8rem !important; }
  .ms-xxl-47 {
    margin-left: 4.7rem !important; }
  .ms-xxl-46 {
    margin-left: 4.6rem !important; }
  .ms-xxl-45 {
    margin-left: 4.5rem !important; }
  .ms-xxl-44 {
    margin-left: 4.4rem !important; }
  .ms-xxl-43 {
    margin-left: 4.3rem !important; }
  .ms-xxl-42 {
    margin-left: 4.2rem !important; }
  .ms-xxl-41 {
    margin-left: 4.1rem !important; }
  .ms-xxl-40 {
    margin-left: 4rem !important; }
  .ms-xxl-39 {
    margin-left: 3.9rem !important; }
  .ms-xxl-38 {
    margin-left: 3.8rem !important; }
  .ms-xxl-37 {
    margin-left: 3.7rem !important; }
  .ms-xxl-36 {
    margin-left: 3.6rem !important; }
  .ms-xxl-35 {
    margin-left: 3.5rem !important; }
  .ms-xxl-34 {
    margin-left: 3.4rem !important; }
  .ms-xxl-33 {
    margin-left: 3.3rem !important; }
  .ms-xxl-32 {
    margin-left: 3.2rem !important; }
  .ms-xxl-31 {
    margin-left: 3.1rem !important; }
  .ms-xxl-30 {
    margin-left: 3rem !important; }
  .ms-xxl-29 {
    margin-left: 2.9rem !important; }
  .ms-xxl-28 {
    margin-left: 2.8rem !important; }
  .ms-xxl-27 {
    margin-left: 2.7rem !important; }
  .ms-xxl-26 {
    margin-left: 2.6rem !important; }
  .ms-xxl-25 {
    margin-left: 2.5rem !important; }
  .ms-xxl-24 {
    margin-left: 2.4rem !important; }
  .ms-xxl-23 {
    margin-left: 2.3rem !important; }
  .ms-xxl-22 {
    margin-left: 2.2rem !important; }
  .ms-xxl-21 {
    margin-left: 2.1rem !important; }
  .ms-xxl-20 {
    margin-left: 2rem !important; }
  .ms-xxl-19 {
    margin-left: 1.9rem !important; }
  .ms-xxl-18 {
    margin-left: 1.8rem !important; }
  .ms-xxl-17 {
    margin-left: 1.7rem !important; }
  .ms-xxl-16 {
    margin-left: 1.6rem !important; }
  .ms-xxl-15 {
    margin-left: 1.5rem !important; }
  .ms-xxl-14 {
    margin-left: 1.4rem !important; }
  .ms-xxl-13 {
    margin-left: 1.3rem !important; }
  .ms-xxl-12 {
    margin-left: 1.2rem !important; }
  .ms-xxl-11 {
    margin-left: 1.1rem !important; }
  .ms-xxl-10 {
    margin-left: 1rem !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-n80 {
    margin-left: -80px !important; }
  .ms-xxl-n100 {
    margin-left: -100px !important; }
  .ms-xxl-n150 {
    margin-left: -150px !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-150 {
    padding: 15rem !important; }
  .p-xxl-149 {
    padding: 14.9rem !important; }
  .p-xxl-148 {
    padding: 14.8rem !important; }
  .p-xxl-147 {
    padding: 14.7rem !important; }
  .p-xxl-146 {
    padding: 14.6rem !important; }
  .p-xxl-145 {
    padding: 14.5rem !important; }
  .p-xxl-144 {
    padding: 14.4rem !important; }
  .p-xxl-143 {
    padding: 14.3rem !important; }
  .p-xxl-142 {
    padding: 14.2rem !important; }
  .p-xxl-141 {
    padding: 14.1rem !important; }
  .p-xxl-140 {
    padding: 14rem !important; }
  .p-xxl-139 {
    padding: 13.9rem !important; }
  .p-xxl-138 {
    padding: 13.8rem !important; }
  .p-xxl-137 {
    padding: 13.7rem !important; }
  .p-xxl-136 {
    padding: 13.6rem !important; }
  .p-xxl-135 {
    padding: 13.5rem !important; }
  .p-xxl-134 {
    padding: 13.4rem !important; }
  .p-xxl-133 {
    padding: 13.3rem !important; }
  .p-xxl-132 {
    padding: 13.2rem !important; }
  .p-xxl-131 {
    padding: 13.1rem !important; }
  .p-xxl-130 {
    padding: 13rem !important; }
  .p-xxl-129 {
    padding: 12.9rem !important; }
  .p-xxl-128 {
    padding: 12.8rem !important; }
  .p-xxl-127 {
    padding: 12.7rem !important; }
  .p-xxl-126 {
    padding: 12.6rem !important; }
  .p-xxl-125 {
    padding: 12.5rem !important; }
  .p-xxl-124 {
    padding: 12.4rem !important; }
  .p-xxl-123 {
    padding: 12.3rem !important; }
  .p-xxl-122 {
    padding: 12.2rem !important; }
  .p-xxl-121 {
    padding: 12.1rem !important; }
  .p-xxl-120 {
    padding: 12rem !important; }
  .p-xxl-119 {
    padding: 11.9rem !important; }
  .p-xxl-118 {
    padding: 11.8rem !important; }
  .p-xxl-117 {
    padding: 11.7rem !important; }
  .p-xxl-116 {
    padding: 11.6rem !important; }
  .p-xxl-115 {
    padding: 11.5rem !important; }
  .p-xxl-114 {
    padding: 11.4rem !important; }
  .p-xxl-113 {
    padding: 11.3rem !important; }
  .p-xxl-112 {
    padding: 11.2rem !important; }
  .p-xxl-111 {
    padding: 11.1rem !important; }
  .p-xxl-110 {
    padding: 11rem !important; }
  .p-xxl-109 {
    padding: 10.9rem !important; }
  .p-xxl-108 {
    padding: 10.8rem !important; }
  .p-xxl-107 {
    padding: 10.7rem !important; }
  .p-xxl-106 {
    padding: 10.6rem !important; }
  .p-xxl-105 {
    padding: 10.5rem !important; }
  .p-xxl-104 {
    padding: 10.4rem !important; }
  .p-xxl-103 {
    padding: 10.3rem !important; }
  .p-xxl-102 {
    padding: 10.2rem !important; }
  .p-xxl-101 {
    padding: 10.1rem !important; }
  .p-xxl-100 {
    padding: 10rem !important; }
  .p-xxl-99 {
    padding: 9.9rem !important; }
  .p-xxl-98 {
    padding: 9.8rem !important; }
  .p-xxl-97 {
    padding: 9.7rem !important; }
  .p-xxl-96 {
    padding: 9.6rem !important; }
  .p-xxl-95 {
    padding: 9.5rem !important; }
  .p-xxl-94 {
    padding: 9.4rem !important; }
  .p-xxl-93 {
    padding: 9.3rem !important; }
  .p-xxl-92 {
    padding: 9.2rem !important; }
  .p-xxl-91 {
    padding: 9.1rem !important; }
  .p-xxl-90 {
    padding: 9rem !important; }
  .p-xxl-89 {
    padding: 8.9rem !important; }
  .p-xxl-88 {
    padding: 8.8rem !important; }
  .p-xxl-87 {
    padding: 8.7rem !important; }
  .p-xxl-86 {
    padding: 8.6rem !important; }
  .p-xxl-85 {
    padding: 8.5rem !important; }
  .p-xxl-84 {
    padding: 8.4rem !important; }
  .p-xxl-83 {
    padding: 8.3rem !important; }
  .p-xxl-82 {
    padding: 8.2rem !important; }
  .p-xxl-81 {
    padding: 8.1rem !important; }
  .p-xxl-80 {
    padding: 8rem !important; }
  .p-xxl-79 {
    padding: 7.9rem !important; }
  .p-xxl-78 {
    padding: 7.8rem !important; }
  .p-xxl-77 {
    padding: 7.7rem !important; }
  .p-xxl-76 {
    padding: 7.6rem !important; }
  .p-xxl-75 {
    padding: 7.5rem !important; }
  .p-xxl-74 {
    padding: 7.4rem !important; }
  .p-xxl-73 {
    padding: 7.3rem !important; }
  .p-xxl-72 {
    padding: 7.2rem !important; }
  .p-xxl-71 {
    padding: 7.1rem !important; }
  .p-xxl-70 {
    padding: 7rem !important; }
  .p-xxl-69 {
    padding: 6.9rem !important; }
  .p-xxl-68 {
    padding: 6.8rem !important; }
  .p-xxl-67 {
    padding: 6.7rem !important; }
  .p-xxl-66 {
    padding: 6.6rem !important; }
  .p-xxl-65 {
    padding: 6.5rem !important; }
  .p-xxl-64 {
    padding: 6.4rem !important; }
  .p-xxl-63 {
    padding: 6.3rem !important; }
  .p-xxl-62 {
    padding: 6.2rem !important; }
  .p-xxl-61 {
    padding: 6.1rem !important; }
  .p-xxl-60 {
    padding: 6rem !important; }
  .p-xxl-59 {
    padding: 5.9rem !important; }
  .p-xxl-58 {
    padding: 5.8rem !important; }
  .p-xxl-57 {
    padding: 5.7rem !important; }
  .p-xxl-56 {
    padding: 5.6rem !important; }
  .p-xxl-55 {
    padding: 5.5rem !important; }
  .p-xxl-54 {
    padding: 5.4rem !important; }
  .p-xxl-53 {
    padding: 5.3rem !important; }
  .p-xxl-52 {
    padding: 5.2rem !important; }
  .p-xxl-51 {
    padding: 5.1rem !important; }
  .p-xxl-50 {
    padding: 5rem !important; }
  .p-xxl-49 {
    padding: 4.9rem !important; }
  .p-xxl-48 {
    padding: 4.8rem !important; }
  .p-xxl-47 {
    padding: 4.7rem !important; }
  .p-xxl-46 {
    padding: 4.6rem !important; }
  .p-xxl-45 {
    padding: 4.5rem !important; }
  .p-xxl-44 {
    padding: 4.4rem !important; }
  .p-xxl-43 {
    padding: 4.3rem !important; }
  .p-xxl-42 {
    padding: 4.2rem !important; }
  .p-xxl-41 {
    padding: 4.1rem !important; }
  .p-xxl-40 {
    padding: 4rem !important; }
  .p-xxl-39 {
    padding: 3.9rem !important; }
  .p-xxl-38 {
    padding: 3.8rem !important; }
  .p-xxl-37 {
    padding: 3.7rem !important; }
  .p-xxl-36 {
    padding: 3.6rem !important; }
  .p-xxl-35 {
    padding: 3.5rem !important; }
  .p-xxl-34 {
    padding: 3.4rem !important; }
  .p-xxl-33 {
    padding: 3.3rem !important; }
  .p-xxl-32 {
    padding: 3.2rem !important; }
  .p-xxl-31 {
    padding: 3.1rem !important; }
  .p-xxl-30 {
    padding: 3rem !important; }
  .p-xxl-29 {
    padding: 2.9rem !important; }
  .p-xxl-28 {
    padding: 2.8rem !important; }
  .p-xxl-27 {
    padding: 2.7rem !important; }
  .p-xxl-26 {
    padding: 2.6rem !important; }
  .p-xxl-25 {
    padding: 2.5rem !important; }
  .p-xxl-24 {
    padding: 2.4rem !important; }
  .p-xxl-23 {
    padding: 2.3rem !important; }
  .p-xxl-22 {
    padding: 2.2rem !important; }
  .p-xxl-21 {
    padding: 2.1rem !important; }
  .p-xxl-20 {
    padding: 2rem !important; }
  .p-xxl-19 {
    padding: 1.9rem !important; }
  .p-xxl-18 {
    padding: 1.8rem !important; }
  .p-xxl-17 {
    padding: 1.7rem !important; }
  .p-xxl-16 {
    padding: 1.6rem !important; }
  .p-xxl-15 {
    padding: 1.5rem !important; }
  .p-xxl-14 {
    padding: 1.4rem !important; }
  .p-xxl-13 {
    padding: 1.3rem !important; }
  .p-xxl-12 {
    padding: 1.2rem !important; }
  .p-xxl-11 {
    padding: 1.1rem !important; }
  .p-xxl-10 {
    padding: 1rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .p-xxl-n80 {
    padding: -80px !important; }
  .p-xxl-n100 {
    padding: -100px !important; }
  .p-xxl-n150 {
    padding: -150px !important; }
  .px-xxl-150 {
    padding-right: 15rem !important;
    padding-left: 15rem !important; }
  .px-xxl-149 {
    padding-right: 14.9rem !important;
    padding-left: 14.9rem !important; }
  .px-xxl-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-xxl-147 {
    padding-right: 14.7rem !important;
    padding-left: 14.7rem !important; }
  .px-xxl-146 {
    padding-right: 14.6rem !important;
    padding-left: 14.6rem !important; }
  .px-xxl-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-xxl-144 {
    padding-right: 14.4rem !important;
    padding-left: 14.4rem !important; }
  .px-xxl-143 {
    padding-right: 14.3rem !important;
    padding-left: 14.3rem !important; }
  .px-xxl-142 {
    padding-right: 14.2rem !important;
    padding-left: 14.2rem !important; }
  .px-xxl-141 {
    padding-right: 14.1rem !important;
    padding-left: 14.1rem !important; }
  .px-xxl-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-xxl-139 {
    padding-right: 13.9rem !important;
    padding-left: 13.9rem !important; }
  .px-xxl-138 {
    padding-right: 13.8rem !important;
    padding-left: 13.8rem !important; }
  .px-xxl-137 {
    padding-right: 13.7rem !important;
    padding-left: 13.7rem !important; }
  .px-xxl-136 {
    padding-right: 13.6rem !important;
    padding-left: 13.6rem !important; }
  .px-xxl-135 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important; }
  .px-xxl-134 {
    padding-right: 13.4rem !important;
    padding-left: 13.4rem !important; }
  .px-xxl-133 {
    padding-right: 13.3rem !important;
    padding-left: 13.3rem !important; }
  .px-xxl-132 {
    padding-right: 13.2rem !important;
    padding-left: 13.2rem !important; }
  .px-xxl-131 {
    padding-right: 13.1rem !important;
    padding-left: 13.1rem !important; }
  .px-xxl-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-xxl-129 {
    padding-right: 12.9rem !important;
    padding-left: 12.9rem !important; }
  .px-xxl-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-xxl-127 {
    padding-right: 12.7rem !important;
    padding-left: 12.7rem !important; }
  .px-xxl-126 {
    padding-right: 12.6rem !important;
    padding-left: 12.6rem !important; }
  .px-xxl-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xxl-124 {
    padding-right: 12.4rem !important;
    padding-left: 12.4rem !important; }
  .px-xxl-123 {
    padding-right: 12.3rem !important;
    padding-left: 12.3rem !important; }
  .px-xxl-122 {
    padding-right: 12.2rem !important;
    padding-left: 12.2rem !important; }
  .px-xxl-121 {
    padding-right: 12.1rem !important;
    padding-left: 12.1rem !important; }
  .px-xxl-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-xxl-119 {
    padding-right: 11.9rem !important;
    padding-left: 11.9rem !important; }
  .px-xxl-118 {
    padding-right: 11.8rem !important;
    padding-left: 11.8rem !important; }
  .px-xxl-117 {
    padding-right: 11.7rem !important;
    padding-left: 11.7rem !important; }
  .px-xxl-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-xxl-115 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .px-xxl-114 {
    padding-right: 11.4rem !important;
    padding-left: 11.4rem !important; }
  .px-xxl-113 {
    padding-right: 11.3rem !important;
    padding-left: 11.3rem !important; }
  .px-xxl-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .px-xxl-111 {
    padding-right: 11.1rem !important;
    padding-left: 11.1rem !important; }
  .px-xxl-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-xxl-109 {
    padding-right: 10.9rem !important;
    padding-left: 10.9rem !important; }
  .px-xxl-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-xxl-107 {
    padding-right: 10.7rem !important;
    padding-left: 10.7rem !important; }
  .px-xxl-106 {
    padding-right: 10.6rem !important;
    padding-left: 10.6rem !important; }
  .px-xxl-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-xxl-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-xxl-103 {
    padding-right: 10.3rem !important;
    padding-left: 10.3rem !important; }
  .px-xxl-102 {
    padding-right: 10.2rem !important;
    padding-left: 10.2rem !important; }
  .px-xxl-101 {
    padding-right: 10.1rem !important;
    padding-left: 10.1rem !important; }
  .px-xxl-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xxl-99 {
    padding-right: 9.9rem !important;
    padding-left: 9.9rem !important; }
  .px-xxl-98 {
    padding-right: 9.8rem !important;
    padding-left: 9.8rem !important; }
  .px-xxl-97 {
    padding-right: 9.7rem !important;
    padding-left: 9.7rem !important; }
  .px-xxl-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-xxl-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-xxl-94 {
    padding-right: 9.4rem !important;
    padding-left: 9.4rem !important; }
  .px-xxl-93 {
    padding-right: 9.3rem !important;
    padding-left: 9.3rem !important; }
  .px-xxl-92 {
    padding-right: 9.2rem !important;
    padding-left: 9.2rem !important; }
  .px-xxl-91 {
    padding-right: 9.1rem !important;
    padding-left: 9.1rem !important; }
  .px-xxl-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-xxl-89 {
    padding-right: 8.9rem !important;
    padding-left: 8.9rem !important; }
  .px-xxl-88 {
    padding-right: 8.8rem !important;
    padding-left: 8.8rem !important; }
  .px-xxl-87 {
    padding-right: 8.7rem !important;
    padding-left: 8.7rem !important; }
  .px-xxl-86 {
    padding-right: 8.6rem !important;
    padding-left: 8.6rem !important; }
  .px-xxl-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-xxl-84 {
    padding-right: 8.4rem !important;
    padding-left: 8.4rem !important; }
  .px-xxl-83 {
    padding-right: 8.3rem !important;
    padding-left: 8.3rem !important; }
  .px-xxl-82 {
    padding-right: 8.2rem !important;
    padding-left: 8.2rem !important; }
  .px-xxl-81 {
    padding-right: 8.1rem !important;
    padding-left: 8.1rem !important; }
  .px-xxl-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-xxl-79 {
    padding-right: 7.9rem !important;
    padding-left: 7.9rem !important; }
  .px-xxl-78 {
    padding-right: 7.8rem !important;
    padding-left: 7.8rem !important; }
  .px-xxl-77 {
    padding-right: 7.7rem !important;
    padding-left: 7.7rem !important; }
  .px-xxl-76 {
    padding-right: 7.6rem !important;
    padding-left: 7.6rem !important; }
  .px-xxl-75 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xxl-74 {
    padding-right: 7.4rem !important;
    padding-left: 7.4rem !important; }
  .px-xxl-73 {
    padding-right: 7.3rem !important;
    padding-left: 7.3rem !important; }
  .px-xxl-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-xxl-71 {
    padding-right: 7.1rem !important;
    padding-left: 7.1rem !important; }
  .px-xxl-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-xxl-69 {
    padding-right: 6.9rem !important;
    padding-left: 6.9rem !important; }
  .px-xxl-68 {
    padding-right: 6.8rem !important;
    padding-left: 6.8rem !important; }
  .px-xxl-67 {
    padding-right: 6.7rem !important;
    padding-left: 6.7rem !important; }
  .px-xxl-66 {
    padding-right: 6.6rem !important;
    padding-left: 6.6rem !important; }
  .px-xxl-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-xxl-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-xxl-63 {
    padding-right: 6.3rem !important;
    padding-left: 6.3rem !important; }
  .px-xxl-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-xxl-61 {
    padding-right: 6.1rem !important;
    padding-left: 6.1rem !important; }
  .px-xxl-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xxl-59 {
    padding-right: 5.9rem !important;
    padding-left: 5.9rem !important; }
  .px-xxl-58 {
    padding-right: 5.8rem !important;
    padding-left: 5.8rem !important; }
  .px-xxl-57 {
    padding-right: 5.7rem !important;
    padding-left: 5.7rem !important; }
  .px-xxl-56 {
    padding-right: 5.6rem !important;
    padding-left: 5.6rem !important; }
  .px-xxl-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-xxl-54 {
    padding-right: 5.4rem !important;
    padding-left: 5.4rem !important; }
  .px-xxl-53 {
    padding-right: 5.3rem !important;
    padding-left: 5.3rem !important; }
  .px-xxl-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-xxl-51 {
    padding-right: 5.1rem !important;
    padding-left: 5.1rem !important; }
  .px-xxl-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxl-49 {
    padding-right: 4.9rem !important;
    padding-left: 4.9rem !important; }
  .px-xxl-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-xxl-47 {
    padding-right: 4.7rem !important;
    padding-left: 4.7rem !important; }
  .px-xxl-46 {
    padding-right: 4.6rem !important;
    padding-left: 4.6rem !important; }
  .px-xxl-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-xxl-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-xxl-43 {
    padding-right: 4.3rem !important;
    padding-left: 4.3rem !important; }
  .px-xxl-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-xxl-41 {
    padding-right: 4.1rem !important;
    padding-left: 4.1rem !important; }
  .px-xxl-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xxl-39 {
    padding-right: 3.9rem !important;
    padding-left: 3.9rem !important; }
  .px-xxl-38 {
    padding-right: 3.8rem !important;
    padding-left: 3.8rem !important; }
  .px-xxl-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-xxl-36 {
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important; }
  .px-xxl-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xxl-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-xxl-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-xxl-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-xxl-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-xxl-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-xxl-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-xxl-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-xxl-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-xxl-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxl-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-xxl-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-xxl-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-xxl-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-xxl-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xxl-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-xxl-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-xxl-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-xxl-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-xxl-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-xxl-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-xxl-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-xxl-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-xxl-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-n80 {
    padding-right: -80px !important;
    padding-left: -80px !important; }
  .px-xxl-n100 {
    padding-right: -100px !important;
    padding-left: -100px !important; }
  .px-xxl-n150 {
    padding-right: -150px !important;
    padding-left: -150px !important; }
  .py-xxl-150 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important; }
  .py-xxl-149 {
    padding-top: 14.9rem !important;
    padding-bottom: 14.9rem !important; }
  .py-xxl-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-xxl-147 {
    padding-top: 14.7rem !important;
    padding-bottom: 14.7rem !important; }
  .py-xxl-146 {
    padding-top: 14.6rem !important;
    padding-bottom: 14.6rem !important; }
  .py-xxl-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-xxl-144 {
    padding-top: 14.4rem !important;
    padding-bottom: 14.4rem !important; }
  .py-xxl-143 {
    padding-top: 14.3rem !important;
    padding-bottom: 14.3rem !important; }
  .py-xxl-142 {
    padding-top: 14.2rem !important;
    padding-bottom: 14.2rem !important; }
  .py-xxl-141 {
    padding-top: 14.1rem !important;
    padding-bottom: 14.1rem !important; }
  .py-xxl-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-xxl-139 {
    padding-top: 13.9rem !important;
    padding-bottom: 13.9rem !important; }
  .py-xxl-138 {
    padding-top: 13.8rem !important;
    padding-bottom: 13.8rem !important; }
  .py-xxl-137 {
    padding-top: 13.7rem !important;
    padding-bottom: 13.7rem !important; }
  .py-xxl-136 {
    padding-top: 13.6rem !important;
    padding-bottom: 13.6rem !important; }
  .py-xxl-135 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important; }
  .py-xxl-134 {
    padding-top: 13.4rem !important;
    padding-bottom: 13.4rem !important; }
  .py-xxl-133 {
    padding-top: 13.3rem !important;
    padding-bottom: 13.3rem !important; }
  .py-xxl-132 {
    padding-top: 13.2rem !important;
    padding-bottom: 13.2rem !important; }
  .py-xxl-131 {
    padding-top: 13.1rem !important;
    padding-bottom: 13.1rem !important; }
  .py-xxl-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-xxl-129 {
    padding-top: 12.9rem !important;
    padding-bottom: 12.9rem !important; }
  .py-xxl-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-xxl-127 {
    padding-top: 12.7rem !important;
    padding-bottom: 12.7rem !important; }
  .py-xxl-126 {
    padding-top: 12.6rem !important;
    padding-bottom: 12.6rem !important; }
  .py-xxl-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xxl-124 {
    padding-top: 12.4rem !important;
    padding-bottom: 12.4rem !important; }
  .py-xxl-123 {
    padding-top: 12.3rem !important;
    padding-bottom: 12.3rem !important; }
  .py-xxl-122 {
    padding-top: 12.2rem !important;
    padding-bottom: 12.2rem !important; }
  .py-xxl-121 {
    padding-top: 12.1rem !important;
    padding-bottom: 12.1rem !important; }
  .py-xxl-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-xxl-119 {
    padding-top: 11.9rem !important;
    padding-bottom: 11.9rem !important; }
  .py-xxl-118 {
    padding-top: 11.8rem !important;
    padding-bottom: 11.8rem !important; }
  .py-xxl-117 {
    padding-top: 11.7rem !important;
    padding-bottom: 11.7rem !important; }
  .py-xxl-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-xxl-115 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .py-xxl-114 {
    padding-top: 11.4rem !important;
    padding-bottom: 11.4rem !important; }
  .py-xxl-113 {
    padding-top: 11.3rem !important;
    padding-bottom: 11.3rem !important; }
  .py-xxl-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .py-xxl-111 {
    padding-top: 11.1rem !important;
    padding-bottom: 11.1rem !important; }
  .py-xxl-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-xxl-109 {
    padding-top: 10.9rem !important;
    padding-bottom: 10.9rem !important; }
  .py-xxl-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-xxl-107 {
    padding-top: 10.7rem !important;
    padding-bottom: 10.7rem !important; }
  .py-xxl-106 {
    padding-top: 10.6rem !important;
    padding-bottom: 10.6rem !important; }
  .py-xxl-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-xxl-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-xxl-103 {
    padding-top: 10.3rem !important;
    padding-bottom: 10.3rem !important; }
  .py-xxl-102 {
    padding-top: 10.2rem !important;
    padding-bottom: 10.2rem !important; }
  .py-xxl-101 {
    padding-top: 10.1rem !important;
    padding-bottom: 10.1rem !important; }
  .py-xxl-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xxl-99 {
    padding-top: 9.9rem !important;
    padding-bottom: 9.9rem !important; }
  .py-xxl-98 {
    padding-top: 9.8rem !important;
    padding-bottom: 9.8rem !important; }
  .py-xxl-97 {
    padding-top: 9.7rem !important;
    padding-bottom: 9.7rem !important; }
  .py-xxl-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-xxl-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-xxl-94 {
    padding-top: 9.4rem !important;
    padding-bottom: 9.4rem !important; }
  .py-xxl-93 {
    padding-top: 9.3rem !important;
    padding-bottom: 9.3rem !important; }
  .py-xxl-92 {
    padding-top: 9.2rem !important;
    padding-bottom: 9.2rem !important; }
  .py-xxl-91 {
    padding-top: 9.1rem !important;
    padding-bottom: 9.1rem !important; }
  .py-xxl-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-xxl-89 {
    padding-top: 8.9rem !important;
    padding-bottom: 8.9rem !important; }
  .py-xxl-88 {
    padding-top: 8.8rem !important;
    padding-bottom: 8.8rem !important; }
  .py-xxl-87 {
    padding-top: 8.7rem !important;
    padding-bottom: 8.7rem !important; }
  .py-xxl-86 {
    padding-top: 8.6rem !important;
    padding-bottom: 8.6rem !important; }
  .py-xxl-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-xxl-84 {
    padding-top: 8.4rem !important;
    padding-bottom: 8.4rem !important; }
  .py-xxl-83 {
    padding-top: 8.3rem !important;
    padding-bottom: 8.3rem !important; }
  .py-xxl-82 {
    padding-top: 8.2rem !important;
    padding-bottom: 8.2rem !important; }
  .py-xxl-81 {
    padding-top: 8.1rem !important;
    padding-bottom: 8.1rem !important; }
  .py-xxl-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-xxl-79 {
    padding-top: 7.9rem !important;
    padding-bottom: 7.9rem !important; }
  .py-xxl-78 {
    padding-top: 7.8rem !important;
    padding-bottom: 7.8rem !important; }
  .py-xxl-77 {
    padding-top: 7.7rem !important;
    padding-bottom: 7.7rem !important; }
  .py-xxl-76 {
    padding-top: 7.6rem !important;
    padding-bottom: 7.6rem !important; }
  .py-xxl-75 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xxl-74 {
    padding-top: 7.4rem !important;
    padding-bottom: 7.4rem !important; }
  .py-xxl-73 {
    padding-top: 7.3rem !important;
    padding-bottom: 7.3rem !important; }
  .py-xxl-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-xxl-71 {
    padding-top: 7.1rem !important;
    padding-bottom: 7.1rem !important; }
  .py-xxl-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-xxl-69 {
    padding-top: 6.9rem !important;
    padding-bottom: 6.9rem !important; }
  .py-xxl-68 {
    padding-top: 6.8rem !important;
    padding-bottom: 6.8rem !important; }
  .py-xxl-67 {
    padding-top: 6.7rem !important;
    padding-bottom: 6.7rem !important; }
  .py-xxl-66 {
    padding-top: 6.6rem !important;
    padding-bottom: 6.6rem !important; }
  .py-xxl-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-xxl-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-xxl-63 {
    padding-top: 6.3rem !important;
    padding-bottom: 6.3rem !important; }
  .py-xxl-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-xxl-61 {
    padding-top: 6.1rem !important;
    padding-bottom: 6.1rem !important; }
  .py-xxl-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xxl-59 {
    padding-top: 5.9rem !important;
    padding-bottom: 5.9rem !important; }
  .py-xxl-58 {
    padding-top: 5.8rem !important;
    padding-bottom: 5.8rem !important; }
  .py-xxl-57 {
    padding-top: 5.7rem !important;
    padding-bottom: 5.7rem !important; }
  .py-xxl-56 {
    padding-top: 5.6rem !important;
    padding-bottom: 5.6rem !important; }
  .py-xxl-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-xxl-54 {
    padding-top: 5.4rem !important;
    padding-bottom: 5.4rem !important; }
  .py-xxl-53 {
    padding-top: 5.3rem !important;
    padding-bottom: 5.3rem !important; }
  .py-xxl-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-xxl-51 {
    padding-top: 5.1rem !important;
    padding-bottom: 5.1rem !important; }
  .py-xxl-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxl-49 {
    padding-top: 4.9rem !important;
    padding-bottom: 4.9rem !important; }
  .py-xxl-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-xxl-47 {
    padding-top: 4.7rem !important;
    padding-bottom: 4.7rem !important; }
  .py-xxl-46 {
    padding-top: 4.6rem !important;
    padding-bottom: 4.6rem !important; }
  .py-xxl-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-xxl-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-xxl-43 {
    padding-top: 4.3rem !important;
    padding-bottom: 4.3rem !important; }
  .py-xxl-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-xxl-41 {
    padding-top: 4.1rem !important;
    padding-bottom: 4.1rem !important; }
  .py-xxl-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xxl-39 {
    padding-top: 3.9rem !important;
    padding-bottom: 3.9rem !important; }
  .py-xxl-38 {
    padding-top: 3.8rem !important;
    padding-bottom: 3.8rem !important; }
  .py-xxl-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-xxl-36 {
    padding-top: 3.6rem !important;
    padding-bottom: 3.6rem !important; }
  .py-xxl-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xxl-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-xxl-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-xxl-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-xxl-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-xxl-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-xxl-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-xxl-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-xxl-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-xxl-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxl-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-xxl-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-xxl-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-xxl-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-xxl-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xxl-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-xxl-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-xxl-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-xxl-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-xxl-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-xxl-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-xxl-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-xxl-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-xxl-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-n80 {
    padding-top: -80px !important;
    padding-bottom: -80px !important; }
  .py-xxl-n100 {
    padding-top: -100px !important;
    padding-bottom: -100px !important; }
  .py-xxl-n150 {
    padding-top: -150px !important;
    padding-bottom: -150px !important; }
  .pt-xxl-150 {
    padding-top: 15rem !important; }
  .pt-xxl-149 {
    padding-top: 14.9rem !important; }
  .pt-xxl-148 {
    padding-top: 14.8rem !important; }
  .pt-xxl-147 {
    padding-top: 14.7rem !important; }
  .pt-xxl-146 {
    padding-top: 14.6rem !important; }
  .pt-xxl-145 {
    padding-top: 14.5rem !important; }
  .pt-xxl-144 {
    padding-top: 14.4rem !important; }
  .pt-xxl-143 {
    padding-top: 14.3rem !important; }
  .pt-xxl-142 {
    padding-top: 14.2rem !important; }
  .pt-xxl-141 {
    padding-top: 14.1rem !important; }
  .pt-xxl-140 {
    padding-top: 14rem !important; }
  .pt-xxl-139 {
    padding-top: 13.9rem !important; }
  .pt-xxl-138 {
    padding-top: 13.8rem !important; }
  .pt-xxl-137 {
    padding-top: 13.7rem !important; }
  .pt-xxl-136 {
    padding-top: 13.6rem !important; }
  .pt-xxl-135 {
    padding-top: 13.5rem !important; }
  .pt-xxl-134 {
    padding-top: 13.4rem !important; }
  .pt-xxl-133 {
    padding-top: 13.3rem !important; }
  .pt-xxl-132 {
    padding-top: 13.2rem !important; }
  .pt-xxl-131 {
    padding-top: 13.1rem !important; }
  .pt-xxl-130 {
    padding-top: 13rem !important; }
  .pt-xxl-129 {
    padding-top: 12.9rem !important; }
  .pt-xxl-128 {
    padding-top: 12.8rem !important; }
  .pt-xxl-127 {
    padding-top: 12.7rem !important; }
  .pt-xxl-126 {
    padding-top: 12.6rem !important; }
  .pt-xxl-125 {
    padding-top: 12.5rem !important; }
  .pt-xxl-124 {
    padding-top: 12.4rem !important; }
  .pt-xxl-123 {
    padding-top: 12.3rem !important; }
  .pt-xxl-122 {
    padding-top: 12.2rem !important; }
  .pt-xxl-121 {
    padding-top: 12.1rem !important; }
  .pt-xxl-120 {
    padding-top: 12rem !important; }
  .pt-xxl-119 {
    padding-top: 11.9rem !important; }
  .pt-xxl-118 {
    padding-top: 11.8rem !important; }
  .pt-xxl-117 {
    padding-top: 11.7rem !important; }
  .pt-xxl-116 {
    padding-top: 11.6rem !important; }
  .pt-xxl-115 {
    padding-top: 11.5rem !important; }
  .pt-xxl-114 {
    padding-top: 11.4rem !important; }
  .pt-xxl-113 {
    padding-top: 11.3rem !important; }
  .pt-xxl-112 {
    padding-top: 11.2rem !important; }
  .pt-xxl-111 {
    padding-top: 11.1rem !important; }
  .pt-xxl-110 {
    padding-top: 11rem !important; }
  .pt-xxl-109 {
    padding-top: 10.9rem !important; }
  .pt-xxl-108 {
    padding-top: 10.8rem !important; }
  .pt-xxl-107 {
    padding-top: 10.7rem !important; }
  .pt-xxl-106 {
    padding-top: 10.6rem !important; }
  .pt-xxl-105 {
    padding-top: 10.5rem !important; }
  .pt-xxl-104 {
    padding-top: 10.4rem !important; }
  .pt-xxl-103 {
    padding-top: 10.3rem !important; }
  .pt-xxl-102 {
    padding-top: 10.2rem !important; }
  .pt-xxl-101 {
    padding-top: 10.1rem !important; }
  .pt-xxl-100 {
    padding-top: 10rem !important; }
  .pt-xxl-99 {
    padding-top: 9.9rem !important; }
  .pt-xxl-98 {
    padding-top: 9.8rem !important; }
  .pt-xxl-97 {
    padding-top: 9.7rem !important; }
  .pt-xxl-96 {
    padding-top: 9.6rem !important; }
  .pt-xxl-95 {
    padding-top: 9.5rem !important; }
  .pt-xxl-94 {
    padding-top: 9.4rem !important; }
  .pt-xxl-93 {
    padding-top: 9.3rem !important; }
  .pt-xxl-92 {
    padding-top: 9.2rem !important; }
  .pt-xxl-91 {
    padding-top: 9.1rem !important; }
  .pt-xxl-90 {
    padding-top: 9rem !important; }
  .pt-xxl-89 {
    padding-top: 8.9rem !important; }
  .pt-xxl-88 {
    padding-top: 8.8rem !important; }
  .pt-xxl-87 {
    padding-top: 8.7rem !important; }
  .pt-xxl-86 {
    padding-top: 8.6rem !important; }
  .pt-xxl-85 {
    padding-top: 8.5rem !important; }
  .pt-xxl-84 {
    padding-top: 8.4rem !important; }
  .pt-xxl-83 {
    padding-top: 8.3rem !important; }
  .pt-xxl-82 {
    padding-top: 8.2rem !important; }
  .pt-xxl-81 {
    padding-top: 8.1rem !important; }
  .pt-xxl-80 {
    padding-top: 8rem !important; }
  .pt-xxl-79 {
    padding-top: 7.9rem !important; }
  .pt-xxl-78 {
    padding-top: 7.8rem !important; }
  .pt-xxl-77 {
    padding-top: 7.7rem !important; }
  .pt-xxl-76 {
    padding-top: 7.6rem !important; }
  .pt-xxl-75 {
    padding-top: 7.5rem !important; }
  .pt-xxl-74 {
    padding-top: 7.4rem !important; }
  .pt-xxl-73 {
    padding-top: 7.3rem !important; }
  .pt-xxl-72 {
    padding-top: 7.2rem !important; }
  .pt-xxl-71 {
    padding-top: 7.1rem !important; }
  .pt-xxl-70 {
    padding-top: 7rem !important; }
  .pt-xxl-69 {
    padding-top: 6.9rem !important; }
  .pt-xxl-68 {
    padding-top: 6.8rem !important; }
  .pt-xxl-67 {
    padding-top: 6.7rem !important; }
  .pt-xxl-66 {
    padding-top: 6.6rem !important; }
  .pt-xxl-65 {
    padding-top: 6.5rem !important; }
  .pt-xxl-64 {
    padding-top: 6.4rem !important; }
  .pt-xxl-63 {
    padding-top: 6.3rem !important; }
  .pt-xxl-62 {
    padding-top: 6.2rem !important; }
  .pt-xxl-61 {
    padding-top: 6.1rem !important; }
  .pt-xxl-60 {
    padding-top: 6rem !important; }
  .pt-xxl-59 {
    padding-top: 5.9rem !important; }
  .pt-xxl-58 {
    padding-top: 5.8rem !important; }
  .pt-xxl-57 {
    padding-top: 5.7rem !important; }
  .pt-xxl-56 {
    padding-top: 5.6rem !important; }
  .pt-xxl-55 {
    padding-top: 5.5rem !important; }
  .pt-xxl-54 {
    padding-top: 5.4rem !important; }
  .pt-xxl-53 {
    padding-top: 5.3rem !important; }
  .pt-xxl-52 {
    padding-top: 5.2rem !important; }
  .pt-xxl-51 {
    padding-top: 5.1rem !important; }
  .pt-xxl-50 {
    padding-top: 5rem !important; }
  .pt-xxl-49 {
    padding-top: 4.9rem !important; }
  .pt-xxl-48 {
    padding-top: 4.8rem !important; }
  .pt-xxl-47 {
    padding-top: 4.7rem !important; }
  .pt-xxl-46 {
    padding-top: 4.6rem !important; }
  .pt-xxl-45 {
    padding-top: 4.5rem !important; }
  .pt-xxl-44 {
    padding-top: 4.4rem !important; }
  .pt-xxl-43 {
    padding-top: 4.3rem !important; }
  .pt-xxl-42 {
    padding-top: 4.2rem !important; }
  .pt-xxl-41 {
    padding-top: 4.1rem !important; }
  .pt-xxl-40 {
    padding-top: 4rem !important; }
  .pt-xxl-39 {
    padding-top: 3.9rem !important; }
  .pt-xxl-38 {
    padding-top: 3.8rem !important; }
  .pt-xxl-37 {
    padding-top: 3.7rem !important; }
  .pt-xxl-36 {
    padding-top: 3.6rem !important; }
  .pt-xxl-35 {
    padding-top: 3.5rem !important; }
  .pt-xxl-34 {
    padding-top: 3.4rem !important; }
  .pt-xxl-33 {
    padding-top: 3.3rem !important; }
  .pt-xxl-32 {
    padding-top: 3.2rem !important; }
  .pt-xxl-31 {
    padding-top: 3.1rem !important; }
  .pt-xxl-30 {
    padding-top: 3rem !important; }
  .pt-xxl-29 {
    padding-top: 2.9rem !important; }
  .pt-xxl-28 {
    padding-top: 2.8rem !important; }
  .pt-xxl-27 {
    padding-top: 2.7rem !important; }
  .pt-xxl-26 {
    padding-top: 2.6rem !important; }
  .pt-xxl-25 {
    padding-top: 2.5rem !important; }
  .pt-xxl-24 {
    padding-top: 2.4rem !important; }
  .pt-xxl-23 {
    padding-top: 2.3rem !important; }
  .pt-xxl-22 {
    padding-top: 2.2rem !important; }
  .pt-xxl-21 {
    padding-top: 2.1rem !important; }
  .pt-xxl-20 {
    padding-top: 2rem !important; }
  .pt-xxl-19 {
    padding-top: 1.9rem !important; }
  .pt-xxl-18 {
    padding-top: 1.8rem !important; }
  .pt-xxl-17 {
    padding-top: 1.7rem !important; }
  .pt-xxl-16 {
    padding-top: 1.6rem !important; }
  .pt-xxl-15 {
    padding-top: 1.5rem !important; }
  .pt-xxl-14 {
    padding-top: 1.4rem !important; }
  .pt-xxl-13 {
    padding-top: 1.3rem !important; }
  .pt-xxl-12 {
    padding-top: 1.2rem !important; }
  .pt-xxl-11 {
    padding-top: 1.1rem !important; }
  .pt-xxl-10 {
    padding-top: 1rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pt-xxl-n80 {
    padding-top: -80px !important; }
  .pt-xxl-n100 {
    padding-top: -100px !important; }
  .pt-xxl-n150 {
    padding-top: -150px !important; }
  .pe-xxl-150 {
    padding-right: 15rem !important; }
  .pe-xxl-149 {
    padding-right: 14.9rem !important; }
  .pe-xxl-148 {
    padding-right: 14.8rem !important; }
  .pe-xxl-147 {
    padding-right: 14.7rem !important; }
  .pe-xxl-146 {
    padding-right: 14.6rem !important; }
  .pe-xxl-145 {
    padding-right: 14.5rem !important; }
  .pe-xxl-144 {
    padding-right: 14.4rem !important; }
  .pe-xxl-143 {
    padding-right: 14.3rem !important; }
  .pe-xxl-142 {
    padding-right: 14.2rem !important; }
  .pe-xxl-141 {
    padding-right: 14.1rem !important; }
  .pe-xxl-140 {
    padding-right: 14rem !important; }
  .pe-xxl-139 {
    padding-right: 13.9rem !important; }
  .pe-xxl-138 {
    padding-right: 13.8rem !important; }
  .pe-xxl-137 {
    padding-right: 13.7rem !important; }
  .pe-xxl-136 {
    padding-right: 13.6rem !important; }
  .pe-xxl-135 {
    padding-right: 13.5rem !important; }
  .pe-xxl-134 {
    padding-right: 13.4rem !important; }
  .pe-xxl-133 {
    padding-right: 13.3rem !important; }
  .pe-xxl-132 {
    padding-right: 13.2rem !important; }
  .pe-xxl-131 {
    padding-right: 13.1rem !important; }
  .pe-xxl-130 {
    padding-right: 13rem !important; }
  .pe-xxl-129 {
    padding-right: 12.9rem !important; }
  .pe-xxl-128 {
    padding-right: 12.8rem !important; }
  .pe-xxl-127 {
    padding-right: 12.7rem !important; }
  .pe-xxl-126 {
    padding-right: 12.6rem !important; }
  .pe-xxl-125 {
    padding-right: 12.5rem !important; }
  .pe-xxl-124 {
    padding-right: 12.4rem !important; }
  .pe-xxl-123 {
    padding-right: 12.3rem !important; }
  .pe-xxl-122 {
    padding-right: 12.2rem !important; }
  .pe-xxl-121 {
    padding-right: 12.1rem !important; }
  .pe-xxl-120 {
    padding-right: 12rem !important; }
  .pe-xxl-119 {
    padding-right: 11.9rem !important; }
  .pe-xxl-118 {
    padding-right: 11.8rem !important; }
  .pe-xxl-117 {
    padding-right: 11.7rem !important; }
  .pe-xxl-116 {
    padding-right: 11.6rem !important; }
  .pe-xxl-115 {
    padding-right: 11.5rem !important; }
  .pe-xxl-114 {
    padding-right: 11.4rem !important; }
  .pe-xxl-113 {
    padding-right: 11.3rem !important; }
  .pe-xxl-112 {
    padding-right: 11.2rem !important; }
  .pe-xxl-111 {
    padding-right: 11.1rem !important; }
  .pe-xxl-110 {
    padding-right: 11rem !important; }
  .pe-xxl-109 {
    padding-right: 10.9rem !important; }
  .pe-xxl-108 {
    padding-right: 10.8rem !important; }
  .pe-xxl-107 {
    padding-right: 10.7rem !important; }
  .pe-xxl-106 {
    padding-right: 10.6rem !important; }
  .pe-xxl-105 {
    padding-right: 10.5rem !important; }
  .pe-xxl-104 {
    padding-right: 10.4rem !important; }
  .pe-xxl-103 {
    padding-right: 10.3rem !important; }
  .pe-xxl-102 {
    padding-right: 10.2rem !important; }
  .pe-xxl-101 {
    padding-right: 10.1rem !important; }
  .pe-xxl-100 {
    padding-right: 10rem !important; }
  .pe-xxl-99 {
    padding-right: 9.9rem !important; }
  .pe-xxl-98 {
    padding-right: 9.8rem !important; }
  .pe-xxl-97 {
    padding-right: 9.7rem !important; }
  .pe-xxl-96 {
    padding-right: 9.6rem !important; }
  .pe-xxl-95 {
    padding-right: 9.5rem !important; }
  .pe-xxl-94 {
    padding-right: 9.4rem !important; }
  .pe-xxl-93 {
    padding-right: 9.3rem !important; }
  .pe-xxl-92 {
    padding-right: 9.2rem !important; }
  .pe-xxl-91 {
    padding-right: 9.1rem !important; }
  .pe-xxl-90 {
    padding-right: 9rem !important; }
  .pe-xxl-89 {
    padding-right: 8.9rem !important; }
  .pe-xxl-88 {
    padding-right: 8.8rem !important; }
  .pe-xxl-87 {
    padding-right: 8.7rem !important; }
  .pe-xxl-86 {
    padding-right: 8.6rem !important; }
  .pe-xxl-85 {
    padding-right: 8.5rem !important; }
  .pe-xxl-84 {
    padding-right: 8.4rem !important; }
  .pe-xxl-83 {
    padding-right: 8.3rem !important; }
  .pe-xxl-82 {
    padding-right: 8.2rem !important; }
  .pe-xxl-81 {
    padding-right: 8.1rem !important; }
  .pe-xxl-80 {
    padding-right: 8rem !important; }
  .pe-xxl-79 {
    padding-right: 7.9rem !important; }
  .pe-xxl-78 {
    padding-right: 7.8rem !important; }
  .pe-xxl-77 {
    padding-right: 7.7rem !important; }
  .pe-xxl-76 {
    padding-right: 7.6rem !important; }
  .pe-xxl-75 {
    padding-right: 7.5rem !important; }
  .pe-xxl-74 {
    padding-right: 7.4rem !important; }
  .pe-xxl-73 {
    padding-right: 7.3rem !important; }
  .pe-xxl-72 {
    padding-right: 7.2rem !important; }
  .pe-xxl-71 {
    padding-right: 7.1rem !important; }
  .pe-xxl-70 {
    padding-right: 7rem !important; }
  .pe-xxl-69 {
    padding-right: 6.9rem !important; }
  .pe-xxl-68 {
    padding-right: 6.8rem !important; }
  .pe-xxl-67 {
    padding-right: 6.7rem !important; }
  .pe-xxl-66 {
    padding-right: 6.6rem !important; }
  .pe-xxl-65 {
    padding-right: 6.5rem !important; }
  .pe-xxl-64 {
    padding-right: 6.4rem !important; }
  .pe-xxl-63 {
    padding-right: 6.3rem !important; }
  .pe-xxl-62 {
    padding-right: 6.2rem !important; }
  .pe-xxl-61 {
    padding-right: 6.1rem !important; }
  .pe-xxl-60 {
    padding-right: 6rem !important; }
  .pe-xxl-59 {
    padding-right: 5.9rem !important; }
  .pe-xxl-58 {
    padding-right: 5.8rem !important; }
  .pe-xxl-57 {
    padding-right: 5.7rem !important; }
  .pe-xxl-56 {
    padding-right: 5.6rem !important; }
  .pe-xxl-55 {
    padding-right: 5.5rem !important; }
  .pe-xxl-54 {
    padding-right: 5.4rem !important; }
  .pe-xxl-53 {
    padding-right: 5.3rem !important; }
  .pe-xxl-52 {
    padding-right: 5.2rem !important; }
  .pe-xxl-51 {
    padding-right: 5.1rem !important; }
  .pe-xxl-50 {
    padding-right: 5rem !important; }
  .pe-xxl-49 {
    padding-right: 4.9rem !important; }
  .pe-xxl-48 {
    padding-right: 4.8rem !important; }
  .pe-xxl-47 {
    padding-right: 4.7rem !important; }
  .pe-xxl-46 {
    padding-right: 4.6rem !important; }
  .pe-xxl-45 {
    padding-right: 4.5rem !important; }
  .pe-xxl-44 {
    padding-right: 4.4rem !important; }
  .pe-xxl-43 {
    padding-right: 4.3rem !important; }
  .pe-xxl-42 {
    padding-right: 4.2rem !important; }
  .pe-xxl-41 {
    padding-right: 4.1rem !important; }
  .pe-xxl-40 {
    padding-right: 4rem !important; }
  .pe-xxl-39 {
    padding-right: 3.9rem !important; }
  .pe-xxl-38 {
    padding-right: 3.8rem !important; }
  .pe-xxl-37 {
    padding-right: 3.7rem !important; }
  .pe-xxl-36 {
    padding-right: 3.6rem !important; }
  .pe-xxl-35 {
    padding-right: 3.5rem !important; }
  .pe-xxl-34 {
    padding-right: 3.4rem !important; }
  .pe-xxl-33 {
    padding-right: 3.3rem !important; }
  .pe-xxl-32 {
    padding-right: 3.2rem !important; }
  .pe-xxl-31 {
    padding-right: 3.1rem !important; }
  .pe-xxl-30 {
    padding-right: 3rem !important; }
  .pe-xxl-29 {
    padding-right: 2.9rem !important; }
  .pe-xxl-28 {
    padding-right: 2.8rem !important; }
  .pe-xxl-27 {
    padding-right: 2.7rem !important; }
  .pe-xxl-26 {
    padding-right: 2.6rem !important; }
  .pe-xxl-25 {
    padding-right: 2.5rem !important; }
  .pe-xxl-24 {
    padding-right: 2.4rem !important; }
  .pe-xxl-23 {
    padding-right: 2.3rem !important; }
  .pe-xxl-22 {
    padding-right: 2.2rem !important; }
  .pe-xxl-21 {
    padding-right: 2.1rem !important; }
  .pe-xxl-20 {
    padding-right: 2rem !important; }
  .pe-xxl-19 {
    padding-right: 1.9rem !important; }
  .pe-xxl-18 {
    padding-right: 1.8rem !important; }
  .pe-xxl-17 {
    padding-right: 1.7rem !important; }
  .pe-xxl-16 {
    padding-right: 1.6rem !important; }
  .pe-xxl-15 {
    padding-right: 1.5rem !important; }
  .pe-xxl-14 {
    padding-right: 1.4rem !important; }
  .pe-xxl-13 {
    padding-right: 1.3rem !important; }
  .pe-xxl-12 {
    padding-right: 1.2rem !important; }
  .pe-xxl-11 {
    padding-right: 1.1rem !important; }
  .pe-xxl-10 {
    padding-right: 1rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pe-xxl-n80 {
    padding-right: -80px !important; }
  .pe-xxl-n100 {
    padding-right: -100px !important; }
  .pe-xxl-n150 {
    padding-right: -150px !important; }
  .pb-xxl-150 {
    padding-bottom: 15rem !important; }
  .pb-xxl-149 {
    padding-bottom: 14.9rem !important; }
  .pb-xxl-148 {
    padding-bottom: 14.8rem !important; }
  .pb-xxl-147 {
    padding-bottom: 14.7rem !important; }
  .pb-xxl-146 {
    padding-bottom: 14.6rem !important; }
  .pb-xxl-145 {
    padding-bottom: 14.5rem !important; }
  .pb-xxl-144 {
    padding-bottom: 14.4rem !important; }
  .pb-xxl-143 {
    padding-bottom: 14.3rem !important; }
  .pb-xxl-142 {
    padding-bottom: 14.2rem !important; }
  .pb-xxl-141 {
    padding-bottom: 14.1rem !important; }
  .pb-xxl-140 {
    padding-bottom: 14rem !important; }
  .pb-xxl-139 {
    padding-bottom: 13.9rem !important; }
  .pb-xxl-138 {
    padding-bottom: 13.8rem !important; }
  .pb-xxl-137 {
    padding-bottom: 13.7rem !important; }
  .pb-xxl-136 {
    padding-bottom: 13.6rem !important; }
  .pb-xxl-135 {
    padding-bottom: 13.5rem !important; }
  .pb-xxl-134 {
    padding-bottom: 13.4rem !important; }
  .pb-xxl-133 {
    padding-bottom: 13.3rem !important; }
  .pb-xxl-132 {
    padding-bottom: 13.2rem !important; }
  .pb-xxl-131 {
    padding-bottom: 13.1rem !important; }
  .pb-xxl-130 {
    padding-bottom: 13rem !important; }
  .pb-xxl-129 {
    padding-bottom: 12.9rem !important; }
  .pb-xxl-128 {
    padding-bottom: 12.8rem !important; }
  .pb-xxl-127 {
    padding-bottom: 12.7rem !important; }
  .pb-xxl-126 {
    padding-bottom: 12.6rem !important; }
  .pb-xxl-125 {
    padding-bottom: 12.5rem !important; }
  .pb-xxl-124 {
    padding-bottom: 12.4rem !important; }
  .pb-xxl-123 {
    padding-bottom: 12.3rem !important; }
  .pb-xxl-122 {
    padding-bottom: 12.2rem !important; }
  .pb-xxl-121 {
    padding-bottom: 12.1rem !important; }
  .pb-xxl-120 {
    padding-bottom: 12rem !important; }
  .pb-xxl-119 {
    padding-bottom: 11.9rem !important; }
  .pb-xxl-118 {
    padding-bottom: 11.8rem !important; }
  .pb-xxl-117 {
    padding-bottom: 11.7rem !important; }
  .pb-xxl-116 {
    padding-bottom: 11.6rem !important; }
  .pb-xxl-115 {
    padding-bottom: 11.5rem !important; }
  .pb-xxl-114 {
    padding-bottom: 11.4rem !important; }
  .pb-xxl-113 {
    padding-bottom: 11.3rem !important; }
  .pb-xxl-112 {
    padding-bottom: 11.2rem !important; }
  .pb-xxl-111 {
    padding-bottom: 11.1rem !important; }
  .pb-xxl-110 {
    padding-bottom: 11rem !important; }
  .pb-xxl-109 {
    padding-bottom: 10.9rem !important; }
  .pb-xxl-108 {
    padding-bottom: 10.8rem !important; }
  .pb-xxl-107 {
    padding-bottom: 10.7rem !important; }
  .pb-xxl-106 {
    padding-bottom: 10.6rem !important; }
  .pb-xxl-105 {
    padding-bottom: 10.5rem !important; }
  .pb-xxl-104 {
    padding-bottom: 10.4rem !important; }
  .pb-xxl-103 {
    padding-bottom: 10.3rem !important; }
  .pb-xxl-102 {
    padding-bottom: 10.2rem !important; }
  .pb-xxl-101 {
    padding-bottom: 10.1rem !important; }
  .pb-xxl-100 {
    padding-bottom: 10rem !important; }
  .pb-xxl-99 {
    padding-bottom: 9.9rem !important; }
  .pb-xxl-98 {
    padding-bottom: 9.8rem !important; }
  .pb-xxl-97 {
    padding-bottom: 9.7rem !important; }
  .pb-xxl-96 {
    padding-bottom: 9.6rem !important; }
  .pb-xxl-95 {
    padding-bottom: 9.5rem !important; }
  .pb-xxl-94 {
    padding-bottom: 9.4rem !important; }
  .pb-xxl-93 {
    padding-bottom: 9.3rem !important; }
  .pb-xxl-92 {
    padding-bottom: 9.2rem !important; }
  .pb-xxl-91 {
    padding-bottom: 9.1rem !important; }
  .pb-xxl-90 {
    padding-bottom: 9rem !important; }
  .pb-xxl-89 {
    padding-bottom: 8.9rem !important; }
  .pb-xxl-88 {
    padding-bottom: 8.8rem !important; }
  .pb-xxl-87 {
    padding-bottom: 8.7rem !important; }
  .pb-xxl-86 {
    padding-bottom: 8.6rem !important; }
  .pb-xxl-85 {
    padding-bottom: 8.5rem !important; }
  .pb-xxl-84 {
    padding-bottom: 8.4rem !important; }
  .pb-xxl-83 {
    padding-bottom: 8.3rem !important; }
  .pb-xxl-82 {
    padding-bottom: 8.2rem !important; }
  .pb-xxl-81 {
    padding-bottom: 8.1rem !important; }
  .pb-xxl-80 {
    padding-bottom: 8rem !important; }
  .pb-xxl-79 {
    padding-bottom: 7.9rem !important; }
  .pb-xxl-78 {
    padding-bottom: 7.8rem !important; }
  .pb-xxl-77 {
    padding-bottom: 7.7rem !important; }
  .pb-xxl-76 {
    padding-bottom: 7.6rem !important; }
  .pb-xxl-75 {
    padding-bottom: 7.5rem !important; }
  .pb-xxl-74 {
    padding-bottom: 7.4rem !important; }
  .pb-xxl-73 {
    padding-bottom: 7.3rem !important; }
  .pb-xxl-72 {
    padding-bottom: 7.2rem !important; }
  .pb-xxl-71 {
    padding-bottom: 7.1rem !important; }
  .pb-xxl-70 {
    padding-bottom: 7rem !important; }
  .pb-xxl-69 {
    padding-bottom: 6.9rem !important; }
  .pb-xxl-68 {
    padding-bottom: 6.8rem !important; }
  .pb-xxl-67 {
    padding-bottom: 6.7rem !important; }
  .pb-xxl-66 {
    padding-bottom: 6.6rem !important; }
  .pb-xxl-65 {
    padding-bottom: 6.5rem !important; }
  .pb-xxl-64 {
    padding-bottom: 6.4rem !important; }
  .pb-xxl-63 {
    padding-bottom: 6.3rem !important; }
  .pb-xxl-62 {
    padding-bottom: 6.2rem !important; }
  .pb-xxl-61 {
    padding-bottom: 6.1rem !important; }
  .pb-xxl-60 {
    padding-bottom: 6rem !important; }
  .pb-xxl-59 {
    padding-bottom: 5.9rem !important; }
  .pb-xxl-58 {
    padding-bottom: 5.8rem !important; }
  .pb-xxl-57 {
    padding-bottom: 5.7rem !important; }
  .pb-xxl-56 {
    padding-bottom: 5.6rem !important; }
  .pb-xxl-55 {
    padding-bottom: 5.5rem !important; }
  .pb-xxl-54 {
    padding-bottom: 5.4rem !important; }
  .pb-xxl-53 {
    padding-bottom: 5.3rem !important; }
  .pb-xxl-52 {
    padding-bottom: 5.2rem !important; }
  .pb-xxl-51 {
    padding-bottom: 5.1rem !important; }
  .pb-xxl-50 {
    padding-bottom: 5rem !important; }
  .pb-xxl-49 {
    padding-bottom: 4.9rem !important; }
  .pb-xxl-48 {
    padding-bottom: 4.8rem !important; }
  .pb-xxl-47 {
    padding-bottom: 4.7rem !important; }
  .pb-xxl-46 {
    padding-bottom: 4.6rem !important; }
  .pb-xxl-45 {
    padding-bottom: 4.5rem !important; }
  .pb-xxl-44 {
    padding-bottom: 4.4rem !important; }
  .pb-xxl-43 {
    padding-bottom: 4.3rem !important; }
  .pb-xxl-42 {
    padding-bottom: 4.2rem !important; }
  .pb-xxl-41 {
    padding-bottom: 4.1rem !important; }
  .pb-xxl-40 {
    padding-bottom: 4rem !important; }
  .pb-xxl-39 {
    padding-bottom: 3.9rem !important; }
  .pb-xxl-38 {
    padding-bottom: 3.8rem !important; }
  .pb-xxl-37 {
    padding-bottom: 3.7rem !important; }
  .pb-xxl-36 {
    padding-bottom: 3.6rem !important; }
  .pb-xxl-35 {
    padding-bottom: 3.5rem !important; }
  .pb-xxl-34 {
    padding-bottom: 3.4rem !important; }
  .pb-xxl-33 {
    padding-bottom: 3.3rem !important; }
  .pb-xxl-32 {
    padding-bottom: 3.2rem !important; }
  .pb-xxl-31 {
    padding-bottom: 3.1rem !important; }
  .pb-xxl-30 {
    padding-bottom: 3rem !important; }
  .pb-xxl-29 {
    padding-bottom: 2.9rem !important; }
  .pb-xxl-28 {
    padding-bottom: 2.8rem !important; }
  .pb-xxl-27 {
    padding-bottom: 2.7rem !important; }
  .pb-xxl-26 {
    padding-bottom: 2.6rem !important; }
  .pb-xxl-25 {
    padding-bottom: 2.5rem !important; }
  .pb-xxl-24 {
    padding-bottom: 2.4rem !important; }
  .pb-xxl-23 {
    padding-bottom: 2.3rem !important; }
  .pb-xxl-22 {
    padding-bottom: 2.2rem !important; }
  .pb-xxl-21 {
    padding-bottom: 2.1rem !important; }
  .pb-xxl-20 {
    padding-bottom: 2rem !important; }
  .pb-xxl-19 {
    padding-bottom: 1.9rem !important; }
  .pb-xxl-18 {
    padding-bottom: 1.8rem !important; }
  .pb-xxl-17 {
    padding-bottom: 1.7rem !important; }
  .pb-xxl-16 {
    padding-bottom: 1.6rem !important; }
  .pb-xxl-15 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-14 {
    padding-bottom: 1.4rem !important; }
  .pb-xxl-13 {
    padding-bottom: 1.3rem !important; }
  .pb-xxl-12 {
    padding-bottom: 1.2rem !important; }
  .pb-xxl-11 {
    padding-bottom: 1.1rem !important; }
  .pb-xxl-10 {
    padding-bottom: 1rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .pb-xxl-n80 {
    padding-bottom: -80px !important; }
  .pb-xxl-n100 {
    padding-bottom: -100px !important; }
  .pb-xxl-n150 {
    padding-bottom: -150px !important; }
  .ps-xxl-150 {
    padding-left: 15rem !important; }
  .ps-xxl-149 {
    padding-left: 14.9rem !important; }
  .ps-xxl-148 {
    padding-left: 14.8rem !important; }
  .ps-xxl-147 {
    padding-left: 14.7rem !important; }
  .ps-xxl-146 {
    padding-left: 14.6rem !important; }
  .ps-xxl-145 {
    padding-left: 14.5rem !important; }
  .ps-xxl-144 {
    padding-left: 14.4rem !important; }
  .ps-xxl-143 {
    padding-left: 14.3rem !important; }
  .ps-xxl-142 {
    padding-left: 14.2rem !important; }
  .ps-xxl-141 {
    padding-left: 14.1rem !important; }
  .ps-xxl-140 {
    padding-left: 14rem !important; }
  .ps-xxl-139 {
    padding-left: 13.9rem !important; }
  .ps-xxl-138 {
    padding-left: 13.8rem !important; }
  .ps-xxl-137 {
    padding-left: 13.7rem !important; }
  .ps-xxl-136 {
    padding-left: 13.6rem !important; }
  .ps-xxl-135 {
    padding-left: 13.5rem !important; }
  .ps-xxl-134 {
    padding-left: 13.4rem !important; }
  .ps-xxl-133 {
    padding-left: 13.3rem !important; }
  .ps-xxl-132 {
    padding-left: 13.2rem !important; }
  .ps-xxl-131 {
    padding-left: 13.1rem !important; }
  .ps-xxl-130 {
    padding-left: 13rem !important; }
  .ps-xxl-129 {
    padding-left: 12.9rem !important; }
  .ps-xxl-128 {
    padding-left: 12.8rem !important; }
  .ps-xxl-127 {
    padding-left: 12.7rem !important; }
  .ps-xxl-126 {
    padding-left: 12.6rem !important; }
  .ps-xxl-125 {
    padding-left: 12.5rem !important; }
  .ps-xxl-124 {
    padding-left: 12.4rem !important; }
  .ps-xxl-123 {
    padding-left: 12.3rem !important; }
  .ps-xxl-122 {
    padding-left: 12.2rem !important; }
  .ps-xxl-121 {
    padding-left: 12.1rem !important; }
  .ps-xxl-120 {
    padding-left: 12rem !important; }
  .ps-xxl-119 {
    padding-left: 11.9rem !important; }
  .ps-xxl-118 {
    padding-left: 11.8rem !important; }
  .ps-xxl-117 {
    padding-left: 11.7rem !important; }
  .ps-xxl-116 {
    padding-left: 11.6rem !important; }
  .ps-xxl-115 {
    padding-left: 11.5rem !important; }
  .ps-xxl-114 {
    padding-left: 11.4rem !important; }
  .ps-xxl-113 {
    padding-left: 11.3rem !important; }
  .ps-xxl-112 {
    padding-left: 11.2rem !important; }
  .ps-xxl-111 {
    padding-left: 11.1rem !important; }
  .ps-xxl-110 {
    padding-left: 11rem !important; }
  .ps-xxl-109 {
    padding-left: 10.9rem !important; }
  .ps-xxl-108 {
    padding-left: 10.8rem !important; }
  .ps-xxl-107 {
    padding-left: 10.7rem !important; }
  .ps-xxl-106 {
    padding-left: 10.6rem !important; }
  .ps-xxl-105 {
    padding-left: 10.5rem !important; }
  .ps-xxl-104 {
    padding-left: 10.4rem !important; }
  .ps-xxl-103 {
    padding-left: 10.3rem !important; }
  .ps-xxl-102 {
    padding-left: 10.2rem !important; }
  .ps-xxl-101 {
    padding-left: 10.1rem !important; }
  .ps-xxl-100 {
    padding-left: 10rem !important; }
  .ps-xxl-99 {
    padding-left: 9.9rem !important; }
  .ps-xxl-98 {
    padding-left: 9.8rem !important; }
  .ps-xxl-97 {
    padding-left: 9.7rem !important; }
  .ps-xxl-96 {
    padding-left: 9.6rem !important; }
  .ps-xxl-95 {
    padding-left: 9.5rem !important; }
  .ps-xxl-94 {
    padding-left: 9.4rem !important; }
  .ps-xxl-93 {
    padding-left: 9.3rem !important; }
  .ps-xxl-92 {
    padding-left: 9.2rem !important; }
  .ps-xxl-91 {
    padding-left: 9.1rem !important; }
  .ps-xxl-90 {
    padding-left: 9rem !important; }
  .ps-xxl-89 {
    padding-left: 8.9rem !important; }
  .ps-xxl-88 {
    padding-left: 8.8rem !important; }
  .ps-xxl-87 {
    padding-left: 8.7rem !important; }
  .ps-xxl-86 {
    padding-left: 8.6rem !important; }
  .ps-xxl-85 {
    padding-left: 8.5rem !important; }
  .ps-xxl-84 {
    padding-left: 8.4rem !important; }
  .ps-xxl-83 {
    padding-left: 8.3rem !important; }
  .ps-xxl-82 {
    padding-left: 8.2rem !important; }
  .ps-xxl-81 {
    padding-left: 8.1rem !important; }
  .ps-xxl-80 {
    padding-left: 8rem !important; }
  .ps-xxl-79 {
    padding-left: 7.9rem !important; }
  .ps-xxl-78 {
    padding-left: 7.8rem !important; }
  .ps-xxl-77 {
    padding-left: 7.7rem !important; }
  .ps-xxl-76 {
    padding-left: 7.6rem !important; }
  .ps-xxl-75 {
    padding-left: 7.5rem !important; }
  .ps-xxl-74 {
    padding-left: 7.4rem !important; }
  .ps-xxl-73 {
    padding-left: 7.3rem !important; }
  .ps-xxl-72 {
    padding-left: 7.2rem !important; }
  .ps-xxl-71 {
    padding-left: 7.1rem !important; }
  .ps-xxl-70 {
    padding-left: 7rem !important; }
  .ps-xxl-69 {
    padding-left: 6.9rem !important; }
  .ps-xxl-68 {
    padding-left: 6.8rem !important; }
  .ps-xxl-67 {
    padding-left: 6.7rem !important; }
  .ps-xxl-66 {
    padding-left: 6.6rem !important; }
  .ps-xxl-65 {
    padding-left: 6.5rem !important; }
  .ps-xxl-64 {
    padding-left: 6.4rem !important; }
  .ps-xxl-63 {
    padding-left: 6.3rem !important; }
  .ps-xxl-62 {
    padding-left: 6.2rem !important; }
  .ps-xxl-61 {
    padding-left: 6.1rem !important; }
  .ps-xxl-60 {
    padding-left: 6rem !important; }
  .ps-xxl-59 {
    padding-left: 5.9rem !important; }
  .ps-xxl-58 {
    padding-left: 5.8rem !important; }
  .ps-xxl-57 {
    padding-left: 5.7rem !important; }
  .ps-xxl-56 {
    padding-left: 5.6rem !important; }
  .ps-xxl-55 {
    padding-left: 5.5rem !important; }
  .ps-xxl-54 {
    padding-left: 5.4rem !important; }
  .ps-xxl-53 {
    padding-left: 5.3rem !important; }
  .ps-xxl-52 {
    padding-left: 5.2rem !important; }
  .ps-xxl-51 {
    padding-left: 5.1rem !important; }
  .ps-xxl-50 {
    padding-left: 5rem !important; }
  .ps-xxl-49 {
    padding-left: 4.9rem !important; }
  .ps-xxl-48 {
    padding-left: 4.8rem !important; }
  .ps-xxl-47 {
    padding-left: 4.7rem !important; }
  .ps-xxl-46 {
    padding-left: 4.6rem !important; }
  .ps-xxl-45 {
    padding-left: 4.5rem !important; }
  .ps-xxl-44 {
    padding-left: 4.4rem !important; }
  .ps-xxl-43 {
    padding-left: 4.3rem !important; }
  .ps-xxl-42 {
    padding-left: 4.2rem !important; }
  .ps-xxl-41 {
    padding-left: 4.1rem !important; }
  .ps-xxl-40 {
    padding-left: 4rem !important; }
  .ps-xxl-39 {
    padding-left: 3.9rem !important; }
  .ps-xxl-38 {
    padding-left: 3.8rem !important; }
  .ps-xxl-37 {
    padding-left: 3.7rem !important; }
  .ps-xxl-36 {
    padding-left: 3.6rem !important; }
  .ps-xxl-35 {
    padding-left: 3.5rem !important; }
  .ps-xxl-34 {
    padding-left: 3.4rem !important; }
  .ps-xxl-33 {
    padding-left: 3.3rem !important; }
  .ps-xxl-32 {
    padding-left: 3.2rem !important; }
  .ps-xxl-31 {
    padding-left: 3.1rem !important; }
  .ps-xxl-30 {
    padding-left: 3rem !important; }
  .ps-xxl-29 {
    padding-left: 2.9rem !important; }
  .ps-xxl-28 {
    padding-left: 2.8rem !important; }
  .ps-xxl-27 {
    padding-left: 2.7rem !important; }
  .ps-xxl-26 {
    padding-left: 2.6rem !important; }
  .ps-xxl-25 {
    padding-left: 2.5rem !important; }
  .ps-xxl-24 {
    padding-left: 2.4rem !important; }
  .ps-xxl-23 {
    padding-left: 2.3rem !important; }
  .ps-xxl-22 {
    padding-left: 2.2rem !important; }
  .ps-xxl-21 {
    padding-left: 2.1rem !important; }
  .ps-xxl-20 {
    padding-left: 2rem !important; }
  .ps-xxl-19 {
    padding-left: 1.9rem !important; }
  .ps-xxl-18 {
    padding-left: 1.8rem !important; }
  .ps-xxl-17 {
    padding-left: 1.7rem !important; }
  .ps-xxl-16 {
    padding-left: 1.6rem !important; }
  .ps-xxl-15 {
    padding-left: 1.5rem !important; }
  .ps-xxl-14 {
    padding-left: 1.4rem !important; }
  .ps-xxl-13 {
    padding-left: 1.3rem !important; }
  .ps-xxl-12 {
    padding-left: 1.2rem !important; }
  .ps-xxl-11 {
    padding-left: 1.1rem !important; }
  .ps-xxl-10 {
    padding-left: 1rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .ps-xxl-n80 {
    padding-left: -80px !important; }
  .ps-xxl-n100 {
    padding-left: -100px !important; }
  .ps-xxl-n150 {
    padding-left: -150px !important; }
  .fs-xxl-45 {
    font-size: 4.5rem !important; }
  .fs-xxl-44 {
    font-size: 4.4rem !important; }
  .fs-xxl-43 {
    font-size: 4.3rem !important; }
  .fs-xxl-42 {
    font-size: 4.2rem !important; }
  .fs-xxl-41 {
    font-size: 4.1rem !important; }
  .fs-xxl-40 {
    font-size: 4rem !important; }
  .fs-xxl-39 {
    font-size: 3.9rem !important; }
  .fs-xxl-38 {
    font-size: 3.8rem !important; }
  .fs-xxl-37 {
    font-size: 3.7rem !important; }
  .fs-xxl-36 {
    font-size: 3.6rem !important; }
  .fs-xxl-35 {
    font-size: 3.5rem !important; }
  .fs-xxl-34 {
    font-size: 3.4rem !important; }
  .fs-xxl-33 {
    font-size: 3.3rem !important; }
  .fs-xxl-32 {
    font-size: 3.2rem !important; }
  .fs-xxl-31 {
    font-size: 3.1rem !important; }
  .fs-xxl-30 {
    font-size: 3rem !important; }
  .fs-xxl-29 {
    font-size: 2.9rem !important; }
  .fs-xxl-28 {
    font-size: 2.8rem !important; }
  .fs-xxl-27 {
    font-size: 2.7rem !important; }
  .fs-xxl-26 {
    font-size: 2.6rem !important; }
  .fs-xxl-25 {
    font-size: 2.5rem !important; }
  .fs-xxl-24 {
    font-size: 2.4rem !important; }
  .fs-xxl-23 {
    font-size: 2.3rem !important; }
  .fs-xxl-22 {
    font-size: 2.2rem !important; }
  .fs-xxl-21 {
    font-size: 2.1rem !important; }
  .fs-xxl-20 {
    font-size: 2rem !important; }
  .fs-xxl-19 {
    font-size: 1.9rem !important; }
  .fs-xxl-18 {
    font-size: 1.8rem !important; }
  .fs-xxl-17 {
    font-size: 1.7rem !important; }
  .fs-xxl-16 {
    font-size: 1.6rem !important; }
  .fs-xxl-15 {
    font-size: 1.5rem !important; }
  .fs-xxl-14 {
    font-size: 1.4rem !important; }
  .fs-xxl-13 {
    font-size: 1.3rem !important; }
  .fs-xxl-12 {
    font-size: 1.2rem !important; }
  .fs-xxl-11 {
    font-size: 1.1rem !important; }
  .fs-xxl-10 {
    font-size: 1rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; }
  .rounded-xxl {
    border-radius: 0.8rem !important; }
  .rounded-xxl-0 {
    border-radius: 0 !important; }
  .rounded-xxl-1 {
    border-radius: 0.4rem !important; }
  .rounded-xxl-2 {
    border-radius: 0.8rem !important; }
  .rounded-xxl-3 {
    border-radius: 1rem !important; }
  .rounded-xxl-circle {
    border-radius: 50% !important; }
  .z-index-xxl-1 {
    z-index: 1 !important; }
  .z-index-xxl-2 {
    z-index: 2 !important; }
  .z-index-xxl-3 {
    z-index: 3 !important; }
  .z-index-xxl-4 {
    z-index: 4 !important; }
  .z-index-xxl-5 {
    z-index: 5 !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

.g-10,
.gx-10 {
  --bs-gutter-x: 1rem; }

.g-10,
.gy-10 {
  --bs-gutter-y: 1rem; }

.g-20,
.gx-20 {
  --bs-gutter-x: 2rem; }

.g-20,
.gy-20 {
  --bs-gutter-y: 2rem; }

.g-50,
.gx-50 {
  --bs-gutter-x: 5rem; }

.g-50,
.gy-50 {
  --bs-gutter-y: 5rem; }

.g-60,
.gx-60 {
  --bs-gutter-x: 6rem; }

.g-60,
.gy-60 {
  --bs-gutter-y: 6rem; }

.g-80,
.gx-80 {
  --bs-gutter-x: 8rem; }

.g-80,
.gy-80 {
  --bs-gutter-y: 8rem; }

.g-85,
.gx-85 {
  --bs-gutter-x: 8.5rem; }

.g-85,
.gy-85 {
  --bs-gutter-y: 8.5rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 1rem; }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 1rem; }
  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 2rem; }
  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 2rem; }
  .g-sm-50,
  .gx-sm-50 {
    --bs-gutter-x: 5rem; }
  .g-sm-50,
  .gy-sm-50 {
    --bs-gutter-y: 5rem; }
  .g-sm-60,
  .gx-sm-60 {
    --bs-gutter-x: 6rem; }
  .g-sm-60,
  .gy-sm-60 {
    --bs-gutter-y: 6rem; }
  .g-sm-80,
  .gx-sm-80 {
    --bs-gutter-x: 8rem; }
  .g-sm-80,
  .gy-sm-80 {
    --bs-gutter-y: 8rem; }
  .g-sm-85,
  .gx-sm-85 {
    --bs-gutter-x: 8.5rem; }
  .g-sm-85,
  .gy-sm-85 {
    --bs-gutter-y: 8.5rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 1rem; }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 1rem; }
  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 2rem; }
  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 2rem; }
  .g-md-50,
  .gx-md-50 {
    --bs-gutter-x: 5rem; }
  .g-md-50,
  .gy-md-50 {
    --bs-gutter-y: 5rem; }
  .g-md-60,
  .gx-md-60 {
    --bs-gutter-x: 6rem; }
  .g-md-60,
  .gy-md-60 {
    --bs-gutter-y: 6rem; }
  .g-md-80,
  .gx-md-80 {
    --bs-gutter-x: 8rem; }
  .g-md-80,
  .gy-md-80 {
    --bs-gutter-y: 8rem; }
  .g-md-85,
  .gx-md-85 {
    --bs-gutter-x: 8.5rem; }
  .g-md-85,
  .gy-md-85 {
    --bs-gutter-y: 8.5rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 1rem; }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 1rem; }
  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 2rem; }
  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 2rem; }
  .g-lg-50,
  .gx-lg-50 {
    --bs-gutter-x: 5rem; }
  .g-lg-50,
  .gy-lg-50 {
    --bs-gutter-y: 5rem; }
  .g-lg-60,
  .gx-lg-60 {
    --bs-gutter-x: 6rem; }
  .g-lg-60,
  .gy-lg-60 {
    --bs-gutter-y: 6rem; }
  .g-lg-80,
  .gx-lg-80 {
    --bs-gutter-x: 8rem; }
  .g-lg-80,
  .gy-lg-80 {
    --bs-gutter-y: 8rem; }
  .g-lg-85,
  .gx-lg-85 {
    --bs-gutter-x: 8.5rem; }
  .g-lg-85,
  .gy-lg-85 {
    --bs-gutter-y: 8.5rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 1rem; }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 1rem; }
  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 2rem; }
  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 2rem; }
  .g-xl-50,
  .gx-xl-50 {
    --bs-gutter-x: 5rem; }
  .g-xl-50,
  .gy-xl-50 {
    --bs-gutter-y: 5rem; }
  .g-xl-60,
  .gx-xl-60 {
    --bs-gutter-x: 6rem; }
  .g-xl-60,
  .gy-xl-60 {
    --bs-gutter-y: 6rem; }
  .g-xl-80,
  .gx-xl-80 {
    --bs-gutter-x: 8rem; }
  .g-xl-80,
  .gy-xl-80 {
    --bs-gutter-y: 8rem; }
  .g-xl-85,
  .gx-xl-85 {
    --bs-gutter-x: 8.5rem; }
  .g-xl-85,
  .gy-xl-85 {
    --bs-gutter-y: 8.5rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 1rem; }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 1rem; }
  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 2rem; }
  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 2rem; }
  .g-xxl-50,
  .gx-xxl-50 {
    --bs-gutter-x: 5rem; }
  .g-xxl-50,
  .gy-xxl-50 {
    --bs-gutter-y: 5rem; }
  .g-xxl-60,
  .gx-xxl-60 {
    --bs-gutter-x: 6rem; }
  .g-xxl-60,
  .gy-xxl-60 {
    --bs-gutter-y: 6rem; }
  .g-xxl-80,
  .gx-xxl-80 {
    --bs-gutter-x: 8rem; }
  .g-xxl-80,
  .gy-xxl-80 {
    --bs-gutter-y: 8rem; }
  .g-xxl-85,
  .gx-xxl-85 {
    --bs-gutter-x: 8.5rem; }
  .g-xxl-85,
  .gy-xxl-85 {
    --bs-gutter-y: 8.5rem; } }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.8rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #475001;
  background-color: #b1c903;
  border-color: #b1c903; }
  .alert-primary .alert-link {
    color: #394001; }

.alert-secondary {
  color: #adbbbd;
  background-color: #315659;
  border-color: #315659; }
  .alert-secondary .alert-link {
    color: #8a9697; }

.alert-gray-100 {
  color: #616161;
  background-color: #f2f2f2;
  border-color: #f2f2f2; }
  .alert-gray-100 .alert-link {
    color: #4e4e4e; }

.alert-success {
  color: #10431c;
  background-color: #28a745;
  border-color: #28a745; }
  .alert-success .alert-link {
    color: #0d3616; }

.alert-info {
  color: #28465a;
  background-color: #65afe2;
  border-color: #65afe2; }
  .alert-info .alert-link {
    color: #203848; }

.alert-warning {
  color: #664d03;
  background-color: #ffc107;
  border-color: #ffc107; }
  .alert-warning .alert-link {
    color: #523e02; }

.alert-danger {
  color: #f1aeb5;
  background-color: #dc3545;
  border-color: #dc3545; }
  .alert-danger .alert-link {
    color: #c18b91; }

.alert-light {
  color: #616161;
  background-color: #f2f2f2;
  border-color: #f2f2f2; }
  .alert-light .alert-link {
    color: #4e4e4e; }

.alert-dark {
  color: #adadad;
  background-color: #333333;
  border-color: #333333; }
  .alert-dark .alert-link {
    color: #8a8a8a; }

.alert-mobile {
  color: #595a5c;
  background-color: #dee2e6;
  border-color: #dee2e6; }
  .alert-mobile .alert-link {
    color: #47484a; }

.alert-body {
  color: #666666;
  background-color: white;
  border-color: white; }
  .alert-body .alert-link {
    color: #525252; }

.alert-prefooter {
  color: #a8acad;
  background-color: #253031;
  border-color: #253031; }
  .alert-prefooter .alert-link {
    color: #868a8a; }

.alert-footer {
  color: #adbbbd;
  background-color: #315659;
  border-color: #315659; }
  .alert-footer .alert-link {
    color: #8a9697; }

.alert-error {
  color: #595a5c;
  background-color: #dee2e6;
  border-color: #dee2e6; }
  .alert-error .alert-link {
    color: #47484a; }

.alert-white {
  color: #666666;
  background-color: white;
  border-color: white; }
  .alert-white .alert-link {
    color: #525252; }

.alert-orange {
  color: #64310e;
  background-color: #f97b24;
  border-color: #f97b24; }
  .alert-orange .alert-link {
    color: #50270b; }

.alert-bleu {
  color: #074163;
  background-color: #11a2f8;
  border-color: #11a2f8; }
  .alert-bleu .alert-link {
    color: #06344f; }

.alert-gray900 {
  color: #9e9e9e;
  background-color: #0c0c0c;
  border-color: #0c0c0c; }
  .alert-gray900 .alert-link {
    color: #7e7e7e; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.8rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0C0C0C;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.8rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #0C0C0C;
    background-color: #fff;
    border-color: #d8e481;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(177, 201, 3, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #0C0C0C;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #0C0C0C;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #0C0C0C;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.4rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.8rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.8rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0C0C0C;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.8rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #d8e481;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(177, 201, 3, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #0C0C0C; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.4rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #d8e481;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(177, 201, 3, 0.25); }
  .form-check-input:checked {
    background-color: #B1C903;
    border-color: #B1C903; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #B1C903;
    border-color: #B1C903;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23d8e481'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(177, 201, 3, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(177, 201, 3, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #B1C903;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #e8efb3; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #B1C903;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #e8efb3; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0C0C0C;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.8rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.4rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.8rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #28a745; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #28a745; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.8rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #333333;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #000; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.8rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #B1C903; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.8rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #0C0C0C;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.8rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #0C0C0C; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(177, 201, 3, 0.25); }
  .btn-check:checked + .btn,
  .btn-check:active + .btn, .btn:active, .btn.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn-check:checked + .btn:focus,
    .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
      box-shadow: 0 0 0 0.25rem rgba(177, 201, 3, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
    box-shadow: none; }

.btn-primary {
  color: #000;
  background-color: #B1C903;
  border-color: #B1C903;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-primary:hover {
    color: #000;
    background-color: #bdd129;
    border-color: #b9ce1c; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #000;
    background-color: #bdd129;
    border-color: #b9ce1c;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(150, 171, 3, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #000;
    background-color: #c1d435;
    border-color: #b9ce1c; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(150, 171, 3, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #000;
    background-color: #B1C903;
    border-color: #B1C903; }

.btn-secondary {
  color: #fff;
  background-color: #315659;
  border-color: #315659;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-secondary:hover {
    color: #fff;
    background-color: #2a494c;
    border-color: #274547; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #2a494c;
    border-color: #274547;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(80, 111, 114, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #274547;
    border-color: #254143; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(80, 111, 114, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #315659;
    border-color: #315659; }

.btn-gray-100 {
  color: #000;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-gray-100:hover {
    color: #000;
    background-color: #f4f4f4;
    border-color: #f3f3f3; }
  .btn-check:focus + .btn-gray-100, .btn-gray-100:focus {
    color: #000;
    background-color: #f4f4f4;
    border-color: #f3f3f3;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(206, 206, 206, 0.5); }
  .btn-check:checked + .btn-gray-100,
  .btn-check:active + .btn-gray-100, .btn-gray-100:active, .btn-gray-100.active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #000;
    background-color: whitesmoke;
    border-color: #f3f3f3; }
    .btn-check:checked + .btn-gray-100:focus,
    .btn-check:active + .btn-gray-100:focus, .btn-gray-100:active:focus, .btn-gray-100.active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(206, 206, 206, 0.5); }
  .btn-gray-100:disabled, .btn-gray-100.disabled {
    color: #000;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }

.btn-success {
  color: #000;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-success:hover {
    color: #000;
    background-color: #48b461;
    border-color: #3eb058; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #000;
    background-color: #48b461;
    border-color: #3eb058;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(34, 142, 59, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #000;
    background-color: #53b96a;
    border-color: #3eb058; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(34, 142, 59, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #000;
    background-color: #28a745;
    border-color: #28a745; }

.btn-info {
  color: #000;
  background-color: #65afe2;
  border-color: #65afe2;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-info:hover {
    color: #000;
    background-color: #7cbbe6;
    border-color: #74b7e5; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #7cbbe6;
    border-color: #74b7e5;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(86, 149, 192, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #84bfe8;
    border-color: #74b7e5; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(86, 149, 192, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #65afe2;
    border-color: #65afe2; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-light:hover {
    color: #000;
    background-color: #f4f4f4;
    border-color: #f3f3f3; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f4f4f4;
    border-color: #f3f3f3;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(206, 206, 206, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: whitesmoke;
    border-color: #f3f3f3; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(206, 206, 206, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }

.btn-dark {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-dark:hover {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #292929; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #292929;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(82, 82, 82, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #292929;
    border-color: #262626; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(82, 82, 82, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }

.btn-mobile {
  color: #000;
  background-color: #dee2e6;
  border-color: #dee2e6;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-mobile:hover {
    color: #000;
    background-color: #e3e6ea;
    border-color: #e1e5e9; }
  .btn-check:focus + .btn-mobile, .btn-mobile:focus {
    color: #000;
    background-color: #e3e6ea;
    border-color: #e1e5e9;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(189, 192, 196, 0.5); }
  .btn-check:checked + .btn-mobile,
  .btn-check:active + .btn-mobile, .btn-mobile:active, .btn-mobile.active,
  .show > .btn-mobile.dropdown-toggle {
    color: #000;
    background-color: #e5e8eb;
    border-color: #e1e5e9; }
    .btn-check:checked + .btn-mobile:focus,
    .btn-check:active + .btn-mobile:focus, .btn-mobile:active:focus, .btn-mobile.active:focus,
    .show > .btn-mobile.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(189, 192, 196, 0.5); }
  .btn-mobile:disabled, .btn-mobile.disabled {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }

.btn-body {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-body:hover {
    color: #000;
    background-color: white;
    border-color: white; }
  .btn-check:focus + .btn-body, .btn-body:focus {
    color: #000;
    background-color: white;
    border-color: white;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(217, 217, 217, 0.5); }
  .btn-check:checked + .btn-body,
  .btn-check:active + .btn-body, .btn-body:active, .btn-body.active,
  .show > .btn-body.dropdown-toggle {
    color: #000;
    background-color: white;
    border-color: white; }
    .btn-check:checked + .btn-body:focus,
    .btn-check:active + .btn-body:focus, .btn-body:active:focus, .btn-body.active:focus,
    .show > .btn-body.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 217, 217, 0.5); }
  .btn-body:disabled, .btn-body.disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff; }

.btn-prefooter {
  color: #fff;
  background-color: #253031;
  border-color: #253031;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-prefooter:hover {
    color: #fff;
    background-color: #1f292a;
    border-color: #1e2627; }
  .btn-check:focus + .btn-prefooter, .btn-prefooter:focus {
    color: #fff;
    background-color: #1f292a;
    border-color: #1e2627;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(70, 79, 80, 0.5); }
  .btn-check:checked + .btn-prefooter,
  .btn-check:active + .btn-prefooter, .btn-prefooter:active, .btn-prefooter.active,
  .show > .btn-prefooter.dropdown-toggle {
    color: #fff;
    background-color: #1e2627;
    border-color: #1c2425; }
    .btn-check:checked + .btn-prefooter:focus,
    .btn-check:active + .btn-prefooter:focus, .btn-prefooter:active:focus, .btn-prefooter.active:focus,
    .show > .btn-prefooter.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(70, 79, 80, 0.5); }
  .btn-prefooter:disabled, .btn-prefooter.disabled {
    color: #fff;
    background-color: #253031;
    border-color: #253031; }

.btn-footer {
  color: #fff;
  background-color: #315659;
  border-color: #315659;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-footer:hover {
    color: #fff;
    background-color: #2a494c;
    border-color: #274547; }
  .btn-check:focus + .btn-footer, .btn-footer:focus {
    color: #fff;
    background-color: #2a494c;
    border-color: #274547;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(80, 111, 114, 0.5); }
  .btn-check:checked + .btn-footer,
  .btn-check:active + .btn-footer, .btn-footer:active, .btn-footer.active,
  .show > .btn-footer.dropdown-toggle {
    color: #fff;
    background-color: #274547;
    border-color: #254143; }
    .btn-check:checked + .btn-footer:focus,
    .btn-check:active + .btn-footer:focus, .btn-footer:active:focus, .btn-footer.active:focus,
    .show > .btn-footer.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(80, 111, 114, 0.5); }
  .btn-footer:disabled, .btn-footer.disabled {
    color: #fff;
    background-color: #315659;
    border-color: #315659; }

.btn-error {
  color: #000;
  background-color: #dee2e6;
  border-color: #dee2e6;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-error:hover {
    color: #000;
    background-color: #e3e6ea;
    border-color: #e1e5e9; }
  .btn-check:focus + .btn-error, .btn-error:focus {
    color: #000;
    background-color: #e3e6ea;
    border-color: #e1e5e9;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(189, 192, 196, 0.5); }
  .btn-check:checked + .btn-error,
  .btn-check:active + .btn-error, .btn-error:active, .btn-error.active,
  .show > .btn-error.dropdown-toggle {
    color: #000;
    background-color: #e5e8eb;
    border-color: #e1e5e9; }
    .btn-check:checked + .btn-error:focus,
    .btn-check:active + .btn-error:focus, .btn-error:active:focus, .btn-error.active:focus,
    .show > .btn-error.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(189, 192, 196, 0.5); }
  .btn-error:disabled, .btn-error.disabled {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }

.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-white:hover {
    color: #000;
    background-color: white;
    border-color: white; }
  .btn-check:focus + .btn-white, .btn-white:focus {
    color: #000;
    background-color: white;
    border-color: white;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(217, 217, 217, 0.5); }
  .btn-check:checked + .btn-white,
  .btn-check:active + .btn-white, .btn-white:active, .btn-white.active,
  .show > .btn-white.dropdown-toggle {
    color: #000;
    background-color: white;
    border-color: white; }
    .btn-check:checked + .btn-white:focus,
    .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 217, 217, 0.5); }
  .btn-white:disabled, .btn-white.disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff; }

.btn-orange {
  color: #000;
  background-color: #F97B24;
  border-color: #F97B24;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-orange:hover {
    color: #000;
    background-color: #fa8f45;
    border-color: #fa883a; }
  .btn-check:focus + .btn-orange, .btn-orange:focus {
    color: #000;
    background-color: #fa8f45;
    border-color: #fa883a;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(212, 105, 31, 0.5); }
  .btn-check:checked + .btn-orange,
  .btn-check:active + .btn-orange, .btn-orange:active, .btn-orange.active,
  .show > .btn-orange.dropdown-toggle {
    color: #000;
    background-color: #fa9550;
    border-color: #fa883a; }
    .btn-check:checked + .btn-orange:focus,
    .btn-check:active + .btn-orange:focus, .btn-orange:active:focus, .btn-orange.active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(212, 105, 31, 0.5); }
  .btn-orange:disabled, .btn-orange.disabled {
    color: #000;
    background-color: #F97B24;
    border-color: #F97B24; }

.btn-bleu {
  color: #000;
  background-color: #11A2F8;
  border-color: #11A2F8;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-bleu:hover {
    color: #000;
    background-color: #35b0f9;
    border-color: #29abf9; }
  .btn-check:focus + .btn-bleu, .btn-bleu:focus {
    color: #000;
    background-color: #35b0f9;
    border-color: #29abf9;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(14, 138, 211, 0.5); }
  .btn-check:checked + .btn-bleu,
  .btn-check:active + .btn-bleu, .btn-bleu:active, .btn-bleu.active,
  .show > .btn-bleu.dropdown-toggle {
    color: #000;
    background-color: #41b5f9;
    border-color: #29abf9; }
    .btn-check:checked + .btn-bleu:focus,
    .btn-check:active + .btn-bleu:focus, .btn-bleu:active:focus, .btn-bleu.active:focus,
    .show > .btn-bleu.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(14, 138, 211, 0.5); }
  .btn-bleu:disabled, .btn-bleu.disabled {
    color: #000;
    background-color: #11A2F8;
    border-color: #11A2F8; }

.btn-gray900 {
  color: #fff;
  background-color: #0C0C0C;
  border-color: #0C0C0C;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-gray900:hover {
    color: #fff;
    background-color: #0a0a0a;
    border-color: #0a0a0a; }
  .btn-check:focus + .btn-gray900, .btn-gray900:focus {
    color: #fff;
    background-color: #0a0a0a;
    border-color: #0a0a0a;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(48, 48, 48, 0.5); }
  .btn-check:checked + .btn-gray900,
  .btn-check:active + .btn-gray900, .btn-gray900:active, .btn-gray900.active,
  .show > .btn-gray900.dropdown-toggle {
    color: #fff;
    background-color: #0a0a0a;
    border-color: #090909; }
    .btn-check:checked + .btn-gray900:focus,
    .btn-check:active + .btn-gray900:focus, .btn-gray900:active:focus, .btn-gray900.active:focus,
    .show > .btn-gray900.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(48, 48, 48, 0.5); }
  .btn-gray900:disabled, .btn-gray900.disabled {
    color: #fff;
    background-color: #0C0C0C;
    border-color: #0C0C0C; }

.btn-outline-primary {
  color: #B1C903;
  border-color: #B1C903; }
  .btn-outline-primary:hover {
    color: #000;
    background-color: #B1C903;
    border-color: #B1C903; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(177, 201, 3, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #000;
    background-color: #B1C903;
    border-color: #B1C903; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(177, 201, 3, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #B1C903;
    background-color: transparent; }

.btn-outline-secondary {
  color: #315659;
  border-color: #315659; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #315659;
    border-color: #315659; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(49, 86, 89, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #315659;
    border-color: #315659; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(49, 86, 89, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #315659;
    background-color: transparent; }

.btn-outline-gray-100 {
  color: #F2F2F2;
  border-color: #F2F2F2; }
  .btn-outline-gray-100:hover {
    color: #000;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }
  .btn-check:focus + .btn-outline-gray-100, .btn-outline-gray-100:focus {
    box-shadow: 0 0 0 0.25rem rgba(242, 242, 242, 0.5); }
  .btn-check:checked + .btn-outline-gray-100,
  .btn-check:active + .btn-outline-gray-100, .btn-outline-gray-100:active, .btn-outline-gray-100.active, .btn-outline-gray-100.dropdown-toggle.show {
    color: #000;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }
    .btn-check:checked + .btn-outline-gray-100:focus,
    .btn-check:active + .btn-outline-gray-100:focus, .btn-outline-gray-100:active:focus, .btn-outline-gray-100.active:focus, .btn-outline-gray-100.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(242, 242, 242, 0.5); }
  .btn-outline-gray-100:disabled, .btn-outline-gray-100.disabled {
    color: #F2F2F2;
    background-color: transparent; }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #000;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #28a745;
    background-color: transparent; }

.btn-outline-info {
  color: #65afe2;
  border-color: #65afe2; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #65afe2;
    border-color: #65afe2; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(101, 175, 226, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #65afe2;
    border-color: #65afe2; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(101, 175, 226, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #65afe2;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #F2F2F2;
  border-color: #F2F2F2; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(242, 242, 242, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(242, 242, 242, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #F2F2F2;
    background-color: transparent; }

.btn-outline-dark {
  color: #333333;
  border-color: #333333; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(51, 51, 51, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(51, 51, 51, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #333333;
    background-color: transparent; }

.btn-outline-mobile {
  color: #dee2e6;
  border-color: #dee2e6; }
  .btn-outline-mobile:hover {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-check:focus + .btn-outline-mobile, .btn-outline-mobile:focus {
    box-shadow: 0 0 0 0.25rem rgba(222, 226, 230, 0.5); }
  .btn-check:checked + .btn-outline-mobile,
  .btn-check:active + .btn-outline-mobile, .btn-outline-mobile:active, .btn-outline-mobile.active, .btn-outline-mobile.dropdown-toggle.show {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }
    .btn-check:checked + .btn-outline-mobile:focus,
    .btn-check:active + .btn-outline-mobile:focus, .btn-outline-mobile:active:focus, .btn-outline-mobile.active:focus, .btn-outline-mobile.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(222, 226, 230, 0.5); }
  .btn-outline-mobile:disabled, .btn-outline-mobile.disabled {
    color: #dee2e6;
    background-color: transparent; }

.btn-outline-body {
  color: #fff;
  border-color: #fff; }
  .btn-outline-body:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff; }
  .btn-check:focus + .btn-outline-body, .btn-outline-body:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-check:checked + .btn-outline-body,
  .btn-check:active + .btn-outline-body, .btn-outline-body:active, .btn-outline-body.active, .btn-outline-body.dropdown-toggle.show {
    color: #000;
    background-color: #fff;
    border-color: #fff; }
    .btn-check:checked + .btn-outline-body:focus,
    .btn-check:active + .btn-outline-body:focus, .btn-outline-body:active:focus, .btn-outline-body.active:focus, .btn-outline-body.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-outline-body:disabled, .btn-outline-body.disabled {
    color: #fff;
    background-color: transparent; }

.btn-outline-prefooter {
  color: #253031;
  border-color: #253031; }
  .btn-outline-prefooter:hover {
    color: #fff;
    background-color: #253031;
    border-color: #253031; }
  .btn-check:focus + .btn-outline-prefooter, .btn-outline-prefooter:focus {
    box-shadow: 0 0 0 0.25rem rgba(37, 48, 49, 0.5); }
  .btn-check:checked + .btn-outline-prefooter,
  .btn-check:active + .btn-outline-prefooter, .btn-outline-prefooter:active, .btn-outline-prefooter.active, .btn-outline-prefooter.dropdown-toggle.show {
    color: #fff;
    background-color: #253031;
    border-color: #253031; }
    .btn-check:checked + .btn-outline-prefooter:focus,
    .btn-check:active + .btn-outline-prefooter:focus, .btn-outline-prefooter:active:focus, .btn-outline-prefooter.active:focus, .btn-outline-prefooter.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(37, 48, 49, 0.5); }
  .btn-outline-prefooter:disabled, .btn-outline-prefooter.disabled {
    color: #253031;
    background-color: transparent; }

.btn-outline-footer {
  color: #315659;
  border-color: #315659; }
  .btn-outline-footer:hover {
    color: #fff;
    background-color: #315659;
    border-color: #315659; }
  .btn-check:focus + .btn-outline-footer, .btn-outline-footer:focus {
    box-shadow: 0 0 0 0.25rem rgba(49, 86, 89, 0.5); }
  .btn-check:checked + .btn-outline-footer,
  .btn-check:active + .btn-outline-footer, .btn-outline-footer:active, .btn-outline-footer.active, .btn-outline-footer.dropdown-toggle.show {
    color: #fff;
    background-color: #315659;
    border-color: #315659; }
    .btn-check:checked + .btn-outline-footer:focus,
    .btn-check:active + .btn-outline-footer:focus, .btn-outline-footer:active:focus, .btn-outline-footer.active:focus, .btn-outline-footer.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(49, 86, 89, 0.5); }
  .btn-outline-footer:disabled, .btn-outline-footer.disabled {
    color: #315659;
    background-color: transparent; }

.btn-outline-error {
  color: #dee2e6;
  border-color: #dee2e6; }
  .btn-outline-error:hover {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-check:focus + .btn-outline-error, .btn-outline-error:focus {
    box-shadow: 0 0 0 0.25rem rgba(222, 226, 230, 0.5); }
  .btn-check:checked + .btn-outline-error,
  .btn-check:active + .btn-outline-error, .btn-outline-error:active, .btn-outline-error.active, .btn-outline-error.dropdown-toggle.show {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }
    .btn-check:checked + .btn-outline-error:focus,
    .btn-check:active + .btn-outline-error:focus, .btn-outline-error:active:focus, .btn-outline-error.active:focus, .btn-outline-error.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(222, 226, 230, 0.5); }
  .btn-outline-error:disabled, .btn-outline-error.disabled {
    color: #dee2e6;
    background-color: transparent; }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff; }
  .btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-check:checked + .btn-outline-white,
  .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
    color: #000;
    background-color: #fff;
    border-color: #fff; }
    .btn-check:checked + .btn-outline-white:focus,
    .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white:disabled, .btn-outline-white.disabled {
    color: #fff;
    background-color: transparent; }

.btn-outline-orange {
  color: #F97B24;
  border-color: #F97B24; }
  .btn-outline-orange:hover {
    color: #000;
    background-color: #F97B24;
    border-color: #F97B24; }
  .btn-check:focus + .btn-outline-orange, .btn-outline-orange:focus {
    box-shadow: 0 0 0 0.25rem rgba(249, 123, 36, 0.5); }
  .btn-check:checked + .btn-outline-orange,
  .btn-check:active + .btn-outline-orange, .btn-outline-orange:active, .btn-outline-orange.active, .btn-outline-orange.dropdown-toggle.show {
    color: #000;
    background-color: #F97B24;
    border-color: #F97B24; }
    .btn-check:checked + .btn-outline-orange:focus,
    .btn-check:active + .btn-outline-orange:focus, .btn-outline-orange:active:focus, .btn-outline-orange.active:focus, .btn-outline-orange.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(249, 123, 36, 0.5); }
  .btn-outline-orange:disabled, .btn-outline-orange.disabled {
    color: #F97B24;
    background-color: transparent; }

.btn-outline-bleu {
  color: #11A2F8;
  border-color: #11A2F8; }
  .btn-outline-bleu:hover {
    color: #000;
    background-color: #11A2F8;
    border-color: #11A2F8; }
  .btn-check:focus + .btn-outline-bleu, .btn-outline-bleu:focus {
    box-shadow: 0 0 0 0.25rem rgba(17, 162, 248, 0.5); }
  .btn-check:checked + .btn-outline-bleu,
  .btn-check:active + .btn-outline-bleu, .btn-outline-bleu:active, .btn-outline-bleu.active, .btn-outline-bleu.dropdown-toggle.show {
    color: #000;
    background-color: #11A2F8;
    border-color: #11A2F8; }
    .btn-check:checked + .btn-outline-bleu:focus,
    .btn-check:active + .btn-outline-bleu:focus, .btn-outline-bleu:active:focus, .btn-outline-bleu.active:focus, .btn-outline-bleu.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(17, 162, 248, 0.5); }
  .btn-outline-bleu:disabled, .btn-outline-bleu.disabled {
    color: #11A2F8;
    background-color: transparent; }

.btn-outline-gray900 {
  color: #0C0C0C;
  border-color: #0C0C0C; }
  .btn-outline-gray900:hover {
    color: #fff;
    background-color: #0C0C0C;
    border-color: #0C0C0C; }
  .btn-check:focus + .btn-outline-gray900, .btn-outline-gray900:focus {
    box-shadow: 0 0 0 0.25rem rgba(12, 12, 12, 0.5); }
  .btn-check:checked + .btn-outline-gray900,
  .btn-check:active + .btn-outline-gray900, .btn-outline-gray900:active, .btn-outline-gray900.active, .btn-outline-gray900.dropdown-toggle.show {
    color: #fff;
    background-color: #0C0C0C;
    border-color: #0C0C0C; }
    .btn-check:checked + .btn-outline-gray900:focus,
    .btn-check:active + .btn-outline-gray900:focus, .btn-outline-gray900:active:focus, .btn-outline-gray900.active:focus, .btn-outline-gray900.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(12, 12, 12, 0.5); }
  .btn-outline-gray900:disabled, .btn-outline-gray900.disabled {
    color: #0C0C0C;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #B1C903;
  text-decoration: underline; }
  .btn-link:hover {
    color: #8ea102; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.4rem; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.8rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 20px 30px; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 30px; }

.card-header {
  padding: 10px 30px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 0.8rem 0.8rem 0 0; }

.card-footer {
  padding: 10px 30px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 0.8rem 0.8rem; }

.card-header-tabs {
  margin-right: -15px;
  margin-bottom: -10px;
  margin-left: -15px;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -15px;
  margin-left: -15px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0.8rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem; }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.8rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.8rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #F2F2F2; }
  .list-group-item-action:active {
    color: #0C0C0C;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #0C0C0C;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #B1C903;
    border-color: #B1C903; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.8rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.8rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.8rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.8rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.8rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.8rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.8rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #475001;
  background-color: #eff4cd; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #475001;
    background-color: #d7dcb9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #475001;
    border-color: #475001; }

.list-group-item-secondary {
  color: #1d3435;
  background-color: #d6ddde; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #1d3435;
    background-color: #c1c7c8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #1d3435;
    border-color: #1d3435; }

.list-group-item-gray-100 {
  color: #616161;
  background-color: #fcfcfc; }
  .list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
    color: #616161;
    background-color: #e3e3e3; }
  .list-group-item-gray-100.list-group-item-action.active {
    color: #fff;
    background-color: #616161;
    border-color: #616161; }

.list-group-item-success {
  color: #186429;
  background-color: #d4edda; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #186429;
    background-color: #bfd5c4; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #186429;
    border-color: #186429; }

.list-group-item-info {
  color: #3d6988;
  background-color: #e0eff9; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #3d6988;
    background-color: #cad7e0; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #3d6988;
    border-color: #3d6988; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #616161;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #616161;
    background-color: #e3e3e3; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #616161;
    border-color: #616161; }

.list-group-item-dark {
  color: #1f1f1f;
  background-color: #d6d6d6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1f1f1f;
    background-color: #c1c1c1; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #1f1f1f; }

.list-group-item-mobile {
  color: #595a5c;
  background-color: #f8f9fa; }
  .list-group-item-mobile.list-group-item-action:hover, .list-group-item-mobile.list-group-item-action:focus {
    color: #595a5c;
    background-color: #dfe0e1; }
  .list-group-item-mobile.list-group-item-action.active {
    color: #fff;
    background-color: #595a5c;
    border-color: #595a5c; }

.list-group-item-body {
  color: #666666;
  background-color: white; }
  .list-group-item-body.list-group-item-action:hover, .list-group-item-body.list-group-item-action:focus {
    color: #666666;
    background-color: #e6e6e6; }
  .list-group-item-body.list-group-item-action.active {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }

.list-group-item-prefooter {
  color: #161d1d;
  background-color: #d3d6d6; }
  .list-group-item-prefooter.list-group-item-action:hover, .list-group-item-prefooter.list-group-item-action:focus {
    color: #161d1d;
    background-color: #bec1c1; }
  .list-group-item-prefooter.list-group-item-action.active {
    color: #fff;
    background-color: #161d1d;
    border-color: #161d1d; }

.list-group-item-footer {
  color: #1d3435;
  background-color: #d6ddde; }
  .list-group-item-footer.list-group-item-action:hover, .list-group-item-footer.list-group-item-action:focus {
    color: #1d3435;
    background-color: #c1c7c8; }
  .list-group-item-footer.list-group-item-action.active {
    color: #fff;
    background-color: #1d3435;
    border-color: #1d3435; }

.list-group-item-error {
  color: #595a5c;
  background-color: #f8f9fa; }
  .list-group-item-error.list-group-item-action:hover, .list-group-item-error.list-group-item-action:focus {
    color: #595a5c;
    background-color: #dfe0e1; }
  .list-group-item-error.list-group-item-action.active {
    color: #fff;
    background-color: #595a5c;
    border-color: #595a5c; }

.list-group-item-white {
  color: #666666;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #666666;
    background-color: #e6e6e6; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }

.list-group-item-orange {
  color: #954a16;
  background-color: #fee5d3; }
  .list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
    color: #954a16;
    background-color: #e5cebe; }
  .list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #954a16;
    border-color: #954a16; }

.list-group-item-bleu {
  color: #0a6195;
  background-color: #cfecfe; }
  .list-group-item-bleu.list-group-item-action:hover, .list-group-item-bleu.list-group-item-action:focus {
    color: #0a6195;
    background-color: #bad4e5; }
  .list-group-item-bleu.list-group-item-action.active {
    color: #fff;
    background-color: #0a6195;
    border-color: #0a6195; }

.list-group-item-gray900 {
  color: #070707;
  background-color: #cecece; }
  .list-group-item-gray900.list-group-item-action:hover, .list-group-item-gray900.list-group-item-action:focus {
    color: #070707;
    background-color: #b9b9b9; }
  .list-group-item-gray900.list-group-item-action.active {
    color: #fff;
    background-color: #070707;
    border-color: #070707; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.8rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(177, 201, 3, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.8rem; }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 0.75rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.8rem - 1px);
  border-top-right-radius: calc(0.8rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1.6rem;
  color: #0C0C0C;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.12); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #0C0C0C;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #0b0b0b;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #B1C903; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #0C0C0C; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #333333;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #B1C903; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #B1C903;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #8ea102;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #8ea102;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(177, 201, 3, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #B1C903;
  border-color: #B1C903; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem; }

.link-primary {
  color: #B1C903; }
  .link-primary:hover, .link-primary:focus {
    color: #c1d435; }

.link-secondary {
  color: #315659; }
  .link-secondary:hover, .link-secondary:focus {
    color: #274547; }

.link-gray-100 {
  color: #F2F2F2; }
  .link-gray-100:hover, .link-gray-100:focus {
    color: whitesmoke; }

.link-success {
  color: #28a745; }
  .link-success:hover, .link-success:focus {
    color: #53b96a; }

.link-info {
  color: #65afe2; }
  .link-info:hover, .link-info:focus {
    color: #84bfe8; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37; }

.link-light {
  color: #F2F2F2; }
  .link-light:hover, .link-light:focus {
    color: whitesmoke; }

.link-dark {
  color: #333333; }
  .link-dark:hover, .link-dark:focus {
    color: #292929; }

.link-mobile {
  color: #dee2e6; }
  .link-mobile:hover, .link-mobile:focus {
    color: #e5e8eb; }

.link-body {
  color: #fff; }
  .link-body:hover, .link-body:focus {
    color: white; }

.link-prefooter {
  color: #253031; }
  .link-prefooter:hover, .link-prefooter:focus {
    color: #1e2627; }

.link-footer {
  color: #315659; }
  .link-footer:hover, .link-footer:focus {
    color: #274547; }

.link-error {
  color: #dee2e6; }
  .link-error:hover, .link-error:focus {
    color: #e5e8eb; }

.link-white {
  color: #fff; }
  .link-white:hover, .link-white:focus {
    color: white; }

.link-orange {
  color: #F97B24; }
  .link-orange:hover, .link-orange:focus {
    color: #fa9550; }

.link-bleu {
  color: #11A2F8; }
  .link-bleu:hover, .link-bleu:focus {
    color: #41b5f9; }

.link-gray900 {
  color: #0C0C0C; }
  .link-gray900:hover, .link-gray900:focus {
    color: #0a0a0a; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

/* ====== obligatoire
*/
/* ====== optionnel
*/
/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0; }

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor; }

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor; }

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0); }

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle; }

/* Style modifiers
 ========================================================================== */
/*
 * Link
 */
.uk-icon-link {
  color: #999; }

.uk-icon-link:hover,
.uk-icon-link:focus {
  color: #666;
  outline: none; }

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959; }

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover + Focus */
.uk-icon-button:hover,
.uk-icon-button:focus {
  background-color: #ebebeb;
  color: #666;
  outline: none; }

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666; }

/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0; }

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0; }

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px; }

.uk-grid > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px; }
  .uk-grid > * {
    padding-left: 40px; }
  /* Vertical */
  .uk-grid + .uk-grid,
  .uk-grid > .uk-grid-margin,
  * + .uk-grid-margin {
    margin-top: 40px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -3rem; }

.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 3rem; }

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 3rem; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -30px; }

.uk-grid-medium > *,
.uk-grid-column-medium > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid + .uk-grid-row-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-row-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 30px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-large,
.uk-grid-column-large {
  margin-left: -8.5rem; }

.uk-grid-large > *,
.uk-grid-column-large > * {
  padding-left: 8.5rem; }

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 6rem; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large,
  .uk-grid-column-large {
    margin-left: -8.5rem; }
  .uk-grid-large > *,
  .uk-grid-column-large > * {
    padding-left: 8.5rem; }
  /* Vertical */
  .uk-grid + .uk-grid-large,
  .uk-grid + .uk-grid-row-large,
  .uk-grid-large > .uk-grid-margin,
  .uk-grid-row-large > .uk-grid-margin,
  * + .uk-grid-margin-large {
    margin-top: 6rem; } }

/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0; }

.uk-grid-collapse > *,
.uk-grid-column-collapse > * {
  padding-left: 0; }

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid + .uk-grid-row-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 0; }

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative; }

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5; }

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -60px; }

.uk-grid-divider > * {
  padding-left: 60px; }

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px; }
  .uk-grid-divider > * {
    padding-left: 80px; }
  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px; }
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -6rem; }

.uk-grid-divider.uk-grid-small > *,
.uk-grid-divider.uk-grid-column-small > * {
  padding-left: 6rem; }

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
  left: 3rem; }

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 6rem; }

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -3rem;
  left: 6rem; }

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
  top: -3rem; }

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
  left: 6rem; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -60px; }

.uk-grid-divider.uk-grid-medium > *,
.uk-grid-divider.uk-grid-column-medium > * {
  padding-left: 60px; }

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px; }

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
  left: 60px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -17rem; }

.uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
  padding-left: 17rem; }

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
  left: 8.5rem; }

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 12rem; }

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -6rem;
  left: 17rem; }

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
  top: -6rem; }

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
  left: 17rem; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large,
  .uk-grid-divider.uk-grid-column-large {
    margin-left: -17rem; }
  .uk-grid-divider.uk-grid-large > *,
  .uk-grid-divider.uk-grid-column-large > * {
    padding-left: 17rem; }
  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
  .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 8.5rem; }
  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 12rem; }
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -6rem;
    left: 17rem; }
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -6rem; }
  .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 17rem; } }

/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap; }

.uk-grid-match > * > :not([class*='uk-width']),
.uk-grid-item-match > :not([class*='uk-width']) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto; }

/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999; }

/* Hover + Focus */
.uk-close:hover,
.uk-close:focus {
  color: #666;
  outline: none; }

/* ========================================================================
   Component: Totop
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-totop {
  padding: 5px;
  color: #999; }

/* Hover + Focus */
.uk-totop:hover,
.uk-totop:focus {
  color: #666;
  outline: none; }

/* OnClick */
.uk-totop:active {
  color: #333; }

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666; }

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px; }

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0; }

/*
 * Hover + Focus
 */
/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: #d8eafc;
  color: #1e87f0; }

/*
 * Success
 */
.uk-alert-success {
  background: #edfbf6;
  color: #32d296; }

/*
 * Warning
 */
.uk-alert-warning {
  background: #fef5ee;
  color: #faa05a; }

/*
 * Danger
 */
.uk-alert-danger {
  background: #fef4f6;
  color: #f0506e; }

/* ========================================================================
   Component: Badge
 ========================================================================== */
/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */
.uk-badge {
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  padding: 0 5px;
  border-radius: 0.8rem;
  vertical-align: middle;
  /* 1 */
  background: #1e87f0;
  color: #fff;
  font-size: 0.75em;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/*
 * Required for `a`
 */
.uk-badge:hover,
.uk-badge:focus {
  color: #fff;
  text-decoration: none;
  outline: none; }

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 30px 30px; }

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0; }

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8); }

/*
 * Primary
 */
.uk-overlay-primary {
  background: rgba(73, 80, 87, 0.6); }

/* ========================================================================
   Component: Tab
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center text if a width is set
 * 2. Style
 */
.uk-tab > * > a {
  /* 1 */
  display: block;
  text-align: center;
  /* 2 */
  padding: 5px 10px;
  color: #999; }

/* Hover + Focus */
.uk-tab > * > a:hover,
.uk-tab > * > a:focus {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-tab > .uk-active > a {
  color: #333; }

/* Disabled */
.uk-tab > .uk-disabled > a {
  color: #999; }

/* Position modifier
 ========================================================================== */
/*
 * Bottom
 */
/*
 * Left + Right
 * 1. Reset Gutter
 */
.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  /* 1 */
  margin-left: 0; }

/* 1 */
.uk-tab-left > *,
.uk-tab-right > * {
  padding-left: 0; }

.uk-tab-left > * > a {
  text-align: left; }

.uk-tab-right > * > a {
  text-align: left; }

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102, 102, 102, 0.5); }

/* Hover + Focus */
.uk-slidenav:hover,
.uk-slidenav:focus {
  color: rgba(102, 102, 102, 0.9);
  outline: none; }

/* OnClick */
.uk-slidenav:active {
  color: rgba(102, 102, 102, 0.5); }

/* Icon modifier
 ========================================================================== */
/*
 * Previous
 */
/*
 * Next
 */
/* Size modifier
 ========================================================================== */
.uk-slidenav-large {
  padding: 10px 10px; }

/* Container
 ========================================================================== */
.uk-slidenav-container {
  display: flex; }

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-dotnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -12px; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
.uk-dotnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 12px; }

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(102, 102, 102, 0.2);
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap; }

/* Hover + Focus */
.uk-dotnav > * > :hover,
.uk-dotnav > * > :focus {
  background-color: rgba(102, 102, 102, 0.6);
  outline: none; }

/* OnClick */
.uk-dotnav > * > :active {
  background-color: rgba(102, 102, 102, 0.2); }

/* Active */
.uk-dotnav > .uk-active > * {
  background-color: rgba(102, 102, 102, 0.6); }

/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-dotnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px; }

/* 2 */
.uk-dotnav-vertical > * {
  padding-left: 0;
  padding-top: 12px; }

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none; }

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px; }

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333; }

/* Hover + Focus */
.uk-accordion-title:hover,
.uk-accordion-title:focus {
  color: #666;
  text-decoration: none;
  outline: none; }

/* Content
 ========================================================================== */
.uk-accordion-content {
  display: flow-root;
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  min-width: 10rem;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #0C0C0C; }

/* Show */
.uk-dropdown.uk-open {
  display: block; }

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-dropdown-nav {
  white-space: nowrap; }

/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #999; }

/* Hover + Focus + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li > a:focus,
.uk-dropdown-nav > li.uk-active > a {
  color: #666; }

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #999; }

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub a:focus,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #666; }

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*='uk-dropdown-top'] {
  margin-top: -10px; }

[class*='uk-dropdown-bottom'] {
  margin-top: 10px; }

[class*='uk-dropdown-left'] {
  margin-left: -10px; }

[class*='uk-dropdown-right'] {
  margin-left: 10px; }

/* Grid modifiers
 ========================================================================== */
.uk-dropdown-stack .uk-dropdown-grid > * {
  width: 100% !important; }

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden; }

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. Fix `max-width: 100%` not working in combination with flex and responsive images in IE11
 *    `!important` needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: calc(100% - 0.01px) !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform; }

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0); }

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px; }

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none; }

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0); }

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 30px 30px; }

.uk-modal-header {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8; }

.uk-modal-footer {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8; }

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0; }

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.5; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*='uk-modal-close-'] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px; }

/*
 * Remove margin from adjacent element
 */
[class*='uk-modal-close-']:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white; }

.uk-modal-close-outside:hover {
  color: #fff; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%); } }

/*
 * Full
 */
/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom; }

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden; }

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 *    Using `vh` and `vw` to make responsive image work in IE11
 * 6. Suppress outline on focus
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity; }

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh; }

/* 6 */
.uk-lightbox-items > :focus {
  outline: none; }

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto; }

.uk-lightbox-items > .uk-active {
  display: flex; }

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7); }

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7); }

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7); }

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff; }

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover + Focus */
.uk-lightbox-button:hover,
.uk-lightbox-button:focus {
  color: #fff; }

/* OnClick */
/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none; }

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%; }

/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none; }

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 * 5. Disable horizontal panning gestures in IE11 and Edge
 * 6. Suppress outline on focus
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity;
  /* 5 */
  touch-action: pan-y; }

/* 6 */
.uk-slideshow-items > :focus {
  outline: none; }

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none; }

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333; }

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999; }

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5; }

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5; }

.uk-text-default {
  font-size: 16px;
  line-height: 1.5; }

/* Weight modifier
 ========================================================================== */
.uk-text-light {
  font-weight: 300; }

.uk-text-normal {
  font-weight: 400; }

.uk-text-bold {
  font-weight: 700; }

.uk-text-lighter {
  font-weight: lighter; }

.uk-text-bolder {
  font-weight: bolder; }

/* Style modifier
 ========================================================================== */
.uk-text-italic {
  font-style: italic; }

/* Transform modifier
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important; }

.uk-text-uppercase {
  text-transform: uppercase !important; }

.uk-text-lowercase {
  text-transform: lowercase !important; }

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important; }

.uk-text-emphasis {
  color: #333 !important; }

.uk-text-primary {
  color: #1e87f0 !important; }

.uk-text-secondary {
  color: #222 !important; }

.uk-text-success {
  color: #32d296 !important; }

.uk-text-warning {
  color: #faa05a !important; }

.uk-text-danger {
  color: #f0506e !important; }

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in Chrome, Firefox, Safari, Edge and Opera
 *    Default color is set to transparent
 * 2. Container fits the text
 * 3. Fallback color for IE11
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  /* 2 */
  display: inline-block;
  /* 3 */
  color: #1e87f0 !important; }

@supports (-webkit-background-clip: text) {
  .uk-text-background {
    background-color: #1e87f0;
    color: transparent !important; } }

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important; }

.uk-text-right {
  text-align: right !important; }

.uk-text-center {
  text-align: center !important; }

.uk-text-justify {
  text-align: justify !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important; }
  .uk-text-right\@s {
    text-align: right !important; }
  .uk-text-center\@s {
    text-align: center !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important; }
  .uk-text-right\@m {
    text-align: right !important; }
  .uk-text-center\@m {
    text-align: center !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important; }
  .uk-text-right\@l {
    text-align: right !important; }
  .uk-text-center\@l {
    text-align: center !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important; }
  .uk-text-right\@xl {
    text-align: right !important; }
  .uk-text-center\@xl {
    text-align: center !important; } }

/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important; }

.uk-text-middle {
  vertical-align: middle !important; }

.uk-text-bottom {
  vertical-align: bottom !important; }

.uk-text-baseline {
  vertical-align: baseline !important; }

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap; }

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0; }

/*
 * 1. Wrap long words onto the next line and break them if they are too long to fit
 * 2. Legacy `word-wrap` as fallback for `overflow-wrap`
 * 3. Fix `overflow-wrap` which doesn't work with table cells in Chrome, Opera, IE11 and Edge
 *    Must use `break-all` to support IE11 and Edge
 * Note: Not using `hyphens: auto;` because it hyphenates text even if not needed
 */
.uk-text-break {
  /* 1 */
  overflow-wrap: break-word;
  /* 2 */
  word-wrap: break-word; }

/* 3 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-all; }

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Container
 ========================================================================== */
/*
 * Clip child elements
 */
.uk-slider-container {
  overflow: hidden; }

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */
.uk-slider-container-offset {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px; }

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative; }

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none; }

.uk-slider-items.uk-grid {
  flex-wrap: nowrap; }

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 3. Create position context
 * 4. Disable horizontal panning gestures in IE11 and Edge
 * 5. Suppress outline on focus
 */
.uk-slider-items > * {
  /* 1 */
  flex: none;
  max-width: 100%;
  /* 3 */
  position: relative;
  /* 4 */
  touch-action: pan-y; }

/* 5 */
.uk-slider-items > :focus {
  outline: none; }

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  z-index: 980;
  box-sizing: border-box;
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/*
 * Faster animations
 */
.uk-sticky[class*='uk-animation-'] {
  animation-duration: 0.2s; }

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s; }

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto; }

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #222;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 40px 40px; } }

/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px; } }

/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0; }

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0; }

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out; }

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right; }

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out; }

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0; }

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0; }

.uk-open > .uk-offcanvas-reveal {
  width: 270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px; } }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 20px;
  padding: 5px; }

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none; }

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear; }

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1; }

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden; }

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%; }

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px; }

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px; }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px; } }

/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none; }

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px; }

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px; }

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px; }

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px; }

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer; }

* + .uk-notification-message {
  margin-top: 10px; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px; }

.uk-notification-message:hover .uk-notification-close {
  display: block; }

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0; }

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296; }

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a; }

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e; }

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  /* 5 */
  background: #666;
  border-radius: 0.8rem;
  color: #fff;
  font-size: 0.875rem; }

/* Show */
.uk-tooltip.uk-active {
  display: block; }

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*='uk-tooltip-top'] {
  margin-top: 0; }

[class*='uk-tooltip-bottom'] {
  margin-top: 0; }

[class*='uk-tooltip-left'] {
  margin-left: 0; }

[class*='uk-tooltip-right'] {
  margin-left: 0; }

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*='uk-animation-'] {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: both; }

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear; }

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade-scale-02; }

.uk-animation-scale-down {
  animation-name: uk-fade-scale-18; }

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade-top; }

.uk-animation-slide-bottom {
  animation-name: uk-fade-bottom; }

.uk-animation-slide-left {
  animation-name: uk-fade-left; }

.uk-animation-slide-right {
  animation-name: uk-fade-right; }

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade-top-small; }

.uk-animation-slide-bottom-small {
  animation-name: uk-fade-bottom-small; }

.uk-animation-slide-left-small {
  animation-name: uk-fade-left-small; }

.uk-animation-slide-right-small {
  animation-name: uk-fade-right-small; }

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade-top-medium; }

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade-bottom-medium; }

.uk-animation-slide-left-medium {
  animation-name: uk-fade-left-medium; }

.uk-animation-slide-right-medium {
  animation-name: uk-fade-right-medium; }

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-scale-kenburns;
  animation-duration: 15s; }

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake; }

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  stroke-dasharray: var(--uk-animation-stroke);
  animation-duration: 2s; }

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in; }

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s; }

/* Toggle (Hover + Focus)
========================================================================== */
/*
 * The toggle is triggered on touch devices using `:focus` and tabindex
 */
.uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
  animation-name: none; }

/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-animation-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/*
 * Remove outline for `tabindex`
 */
.uk-animation-toggle:focus {
  outline: none; }

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
 * Slide Top
 */
@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Bottom
 */
@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Left
 */
@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Right
 */
@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Top Small
 */
@keyframes uk-fade-top-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Bottom Small
 */
@keyframes uk-fade-bottom-small {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Left Small
 */
@keyframes uk-fade-left-small {
  0% {
    opacity: 0;
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Right Small
 */
@keyframes uk-fade-right-small {
  0% {
    opacity: 0;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Top Medium
 */
@keyframes uk-fade-top-medium {
  0% {
    opacity: 0;
    transform: translateY(-50px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Bottom Medium
 */
@keyframes uk-fade-bottom-medium {
  0% {
    opacity: 0;
    transform: translateY(50px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Left Medium
 */
@keyframes uk-fade-left-medium {
  0% {
    opacity: 0;
    transform: translateX(-50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Right Medium
 */
@keyframes uk-fade-right-medium {
  0% {
    opacity: 0;
    transform: translateX(50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Scale Up
 */
@keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2); }
  100% {
    opacity: 1;
    transform: scale(1); } }

/*
 * Scale Down
 */
@keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8); }
  100% {
    opacity: 1;
    transform: scale(1); } }

/*
 * Kenburns
 */
@keyframes uk-scale-kenburns {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0); }
  10% {
    transform: translateX(-9px); }
  20% {
    transform: translateX(8px); }
  30% {
    transform: translateX(-7px); }
  40% {
    transform: translateX(6px); }
  50% {
    transform: translateX(-5px); }
  60% {
    transform: translateX(4px); }
  70% {
    transform: translateX(-3px); }
  80% {
    transform: translateX(2px); }
  90% {
    transform: translateX(-1px); } }

/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke); }
  100% {
    stroke-dashoffset: 0; } }

/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*='uk-child-width'] > * {
  box-sizing: border-box;
  width: 100%; }

.uk-child-width-1-2 > * {
  width: 50%; }

.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001); }

.uk-child-width-1-4 > * {
  width: 25%; }

.uk-child-width-1-5 > * {
  width: 20%; }

.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001); }

.uk-child-width-auto > * {
  width: auto; }

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*='uk-width']) {
  flex: 1;
  /* 1 */
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%; }
  .uk-child-width-1-2\@s > * {
    width: 50%; }
  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@s > * {
    width: 25%; }
  .uk-child-width-1-5\@s > * {
    width: 20%; }
  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@s > * {
    width: auto; }
  .uk-child-width-expand\@s > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%; }
  .uk-child-width-1-2\@m > * {
    width: 50%; }
  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@m > * {
    width: 25%; }
  .uk-child-width-1-5\@m > * {
    width: 20%; }
  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@m > * {
    width: auto; }
  .uk-child-width-expand\@m > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%; }
  .uk-child-width-1-2\@l > * {
    width: 50%; }
  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@l > * {
    width: 25%; }
  .uk-child-width-1-5\@l > * {
    width: 20%; }
  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@l > * {
    width: auto; }
  .uk-child-width-expand\@l > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%; }
  .uk-child-width-1-2\@xl > * {
    width: 50%; }
  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@xl > * {
    width: 25%; }
  .uk-child-width-1-5\@xl > * {
    width: 20%; }
  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@xl > * {
    width: auto; }
  .uk-child-width-expand\@xl > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%; }

/* Halves */
.uk-width-1-2 {
  width: 50%; }

/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001); }

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001); }

/* Quarters */
.uk-width-1-4 {
  width: 25%; }

.uk-width-3-4 {
  width: 75%; }

/* Fifths */
.uk-width-1-5 {
  width: 20%; }

.uk-width-2-5 {
  width: 40%; }

.uk-width-3-5 {
  width: 60%; }

.uk-width-4-5 {
  width: 80%; }

/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001); }

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001); }

/* Pixel */
.uk-width-small {
  width: 150px; }

.uk-width-medium {
  width: 300px; }

.uk-width-large {
  width: 450px; }

.uk-width-xlarge {
  width: 600px; }

.uk-width-2xlarge {
  width: 750px; }

/* Auto */
.uk-width-auto {
  width: auto; }

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@s {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%; }
  .uk-width-3-4\@s {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%; }
  .uk-width-2-5\@s {
    width: 40%; }
  .uk-width-3-5\@s {
    width: 60%; }
  .uk-width-4-5\@s {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@s {
    width: 150px; }
  .uk-width-medium\@s {
    width: 300px; }
  .uk-width-large\@s {
    width: 450px; }
  .uk-width-xlarge\@s {
    width: 600px; }
  .uk-width-2xlarge\@s {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@s {
    width: auto; }
  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@m {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%; }
  .uk-width-3-4\@m {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%; }
  .uk-width-2-5\@m {
    width: 40%; }
  .uk-width-3-5\@m {
    width: 60%; }
  .uk-width-4-5\@m {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@m {
    width: 150px; }
  .uk-width-medium\@m {
    width: 300px; }
  .uk-width-large\@m {
    width: 450px; }
  .uk-width-xlarge\@m {
    width: 600px; }
  .uk-width-2xlarge\@m {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@m {
    width: auto; }
  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@l {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%; }
  .uk-width-3-4\@l {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%; }
  .uk-width-2-5\@l {
    width: 40%; }
  .uk-width-3-5\@l {
    width: 60%; }
  .uk-width-4-5\@l {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@l {
    width: 150px; }
  .uk-width-medium\@l {
    width: 300px; }
  .uk-width-large\@l {
    width: 450px; }
  .uk-width-xlarge\@l {
    width: 600px; }
  .uk-width-2xlarge\@l {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@l {
    width: auto; }
  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%; }
  .uk-width-3-4\@xl {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%; }
  .uk-width-2-5\@xl {
    width: 40%; }
  .uk-width-3-5\@xl {
    width: 60%; }
  .uk-width-4-5\@xl {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@xl {
    width: 150px; }
  .uk-width-medium\@xl {
    width: 300px; }
  .uk-width-large\@xl {
    width: 450px; }
  .uk-width-xlarge\@xl {
    width: 600px; }
  .uk-width-2xlarge\@xl {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@xl {
    width: auto; }
  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px; } }

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*='uk-height'] {
  box-sizing: border-box; }

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%; }

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh; }

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px; }

.uk-height-medium {
  height: 300px; }

.uk-height-large {
  height: 450px; }

.uk-height-max-small {
  max-height: 150px; }

.uk-height-max-medium {
  max-height: 300px; }

.uk-height-max-large {
  max-height: 450px; }

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Toggle (Hover + Focus)
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-transition-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/*
 * Remove outline for `tabindex`
 */
.uk-transition-toggle:focus {
  outline: none; }

/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 *
 * Note: Transitions don't work with `uk-postion-center-*` classes because they also use `transform`,
 *       therefore it's recommended to use an extra `div` for the transition.
 */
.uk-transition-fade,
[class*='uk-transition-scale'],
[class*='uk-transition-slide'] {
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0; }

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1; }

/*
 * Scale
 */
.uk-transition-scale-up {
  transform: scale(1, 1); }

.uk-transition-scale-down {
  transform: scale(1.03, 1.03); }

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-active.uk-active .uk-transition-scale-up {
  opacity: 1;
  transform: scale(1.03, 1.03); }

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-active.uk-active .uk-transition-scale-down {
  opacity: 1;
  transform: scale(1, 1); }

/*
 * Slide
 */
.uk-transition-slide-top {
  transform: translateY(-100%); }

.uk-transition-slide-bottom {
  transform: translateY(100%); }

.uk-transition-slide-left {
  transform: translateX(-100%); }

.uk-transition-slide-right {
  transform: translateX(100%); }

.uk-transition-slide-top-small {
  transform: translateY(-10px); }

.uk-transition-slide-bottom-small {
  transform: translateY(10px); }

.uk-transition-slide-left-small {
  transform: translateX(-10px); }

.uk-transition-slide-right-small {
  transform: translateX(10px); }

.uk-transition-slide-top-medium {
  transform: translateY(-50px); }

.uk-transition-slide-bottom-medium {
  transform: translateY(50px); }

.uk-transition-slide-left-medium {
  transform: translateX(-50px); }

.uk-transition-slide-right-medium {
  transform: translateX(50px); }

/* Show */
.uk-transition-toggle:hover [class*='uk-transition-slide'],
.uk-transition-toggle:focus [class*='uk-transition-slide'],
.uk-transition-active.uk-active [class*='uk-transition-slide'] {
  opacity: 1;
  transform: translate(0, 0); }

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1; }

/* Duration modifiers
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s; }

/* ========================================================================
   Component: Position
 ========================================================================== */
/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow if `max-width: 100%` is used inside position container.
 */
[class*='uk-position-top'],
[class*='uk-position-bottom'],
[class*='uk-position-left'],
[class*='uk-position-right'],
[class*='uk-position-center'] {
  position: absolute !important;
  /* 1 */
  max-width: 100%; }

/* Edges
 ========================================================================== */
/* Don't use `width: 100%` because it is wrong if the parent has padding. */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0; }

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0; }

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0; }

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0; }

/* Corners
 ========================================================================== */
.uk-position-top-left {
  top: 0;
  left: 0; }

.uk-position-top-right {
  top: 0;
  right: 0; }

.uk-position-bottom-left {
  bottom: 0;
  left: 0; }

.uk-position-bottom-right {
  bottom: 0;
  right: 0; }

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 */
.uk-position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box; }

/* Vertical */
[class*='uk-position-center-left'],
[class*='uk-position-center-right'] {
  top: 50%;
  transform: translateY(-50%); }

.uk-position-center-left {
  left: 0; }

.uk-position-center-right {
  right: 0; }

.uk-position-center-left-out {
  right: 100%;
  width: max-content; }

.uk-position-center-right-out {
  left: 100%;
  width: max-content; }

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: 50%;
  transform: translateX(-50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box; }

.uk-position-top-center {
  top: 0; }

.uk-position-bottom-center {
  bottom: 0; }

/* Cover
 ========================================================================== */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

/* Utility
 ========================================================================== */
.uk-position-relative {
  position: relative !important; }

.uk-position-absolute {
  position: absolute !important; }

.uk-position-fixed {
  position: fixed !important; }

.uk-position-z-index {
  z-index: 1; }

/* Margin modifier
 ========================================================================== */
/*
 * Small
 */
.uk-position-small {
  max-width: calc(100% - (15px * 2));
  margin: 15px; }

.uk-position-small.uk-position-center {
  transform: translate(-50%, -50%) translate(-15px, -15px); }

.uk-position-small[class*='uk-position-center-left'],
.uk-position-small[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-15px); }

.uk-position-small.uk-position-top-center,
.uk-position-small.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-15px); }

/*
 * Medium
 */
.uk-position-medium {
  max-width: calc(100% - (30px * 2));
  margin: 30px; }

.uk-position-medium.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px); }

.uk-position-medium[class*='uk-position-center-left'],
.uk-position-medium[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-30px); }

.uk-position-medium.uk-position-top-center,
.uk-position-medium.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px); }

/*
 * Large
 */
.uk-position-large {
  max-width: calc(100% - (30px * 2));
  margin: 30px; }

.uk-position-large.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px); }

.uk-position-large[class*='uk-position-center-left'],
.uk-position-large[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-30px); }

.uk-position-large.uk-position-top-center,
.uk-position-large.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px); }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    max-width: calc(100% - (50px * 2));
    margin: 50px; }
  .uk-position-large.uk-position-center {
    transform: translate(-50%, -50%) translate(-50px, -50px); }
  .uk-position-large[class*='uk-position-center-left'],
  .uk-position-large[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-50px); }
  .uk-position-large.uk-position-top-center,
  .uk-position-large.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-50px); } }

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex; }

.uk-flex-inline {
  display: inline-flex; }

/*
 * Remove pseudo elements created by micro clearfix as precaution
 */
.uk-flex::before,
.uk-flex::after,
.uk-flex-inline::before,
.uk-flex-inline::after {
  display: none; }

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start; }

.uk-flex-center {
  justify-content: center; }

.uk-flex-right {
  justify-content: flex-end; }

.uk-flex-between {
  justify-content: space-between; }

.uk-flex-around {
  justify-content: space-around; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start; }
  .uk-flex-center\@s {
    justify-content: center; }
  .uk-flex-right\@s {
    justify-content: flex-end; }
  .uk-flex-between\@s {
    justify-content: space-between; }
  .uk-flex-around\@s {
    justify-content: space-around; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start; }
  .uk-flex-center\@m {
    justify-content: center; }
  .uk-flex-right\@m {
    justify-content: flex-end; }
  .uk-flex-between\@m {
    justify-content: space-between; }
  .uk-flex-around\@m {
    justify-content: space-around; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start; }
  .uk-flex-center\@l {
    justify-content: center; }
  .uk-flex-right\@l {
    justify-content: flex-end; }
  .uk-flex-between\@l {
    justify-content: space-between; }
  .uk-flex-around\@l {
    justify-content: space-around; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start; }
  .uk-flex-center\@xl {
    justify-content: center; }
  .uk-flex-right\@xl {
    justify-content: flex-end; }
  .uk-flex-between\@xl {
    justify-content: space-between; }
  .uk-flex-around\@xl {
    justify-content: space-around; } }

/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch; }

.uk-flex-top {
  align-items: flex-start; }

.uk-flex-middle {
  align-items: center; }

.uk-flex-bottom {
  align-items: flex-end; }

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row; }

.uk-flex-row-reverse {
  flex-direction: row-reverse; }

.uk-flex-column {
  flex-direction: column; }

.uk-flex-column-reverse {
  flex-direction: column-reverse; }

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap; }

.uk-flex-wrap {
  flex-wrap: wrap; }

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch; }

.uk-flex-wrap-top {
  align-content: flex-start; }

.uk-flex-wrap-middle {
  align-content: center; }

.uk-flex-wrap-bottom {
  align-content: flex-end; }

.uk-flex-wrap-between {
  align-content: space-between; }

.uk-flex-wrap-around {
  align-content: space-around; }

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1; }

.uk-flex-last {
  order: 99; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1; }
  .uk-flex-last\@s {
    order: 99; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1; }
  .uk-flex-last\@m {
    order: 99; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1; }
  .uk-flex-last\@l {
    order: 99; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1; }
  .uk-flex-last\@xl {
    order: 99; } }

/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none; }

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto; }

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1; }

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #fff; }

.uk-background-muted {
  background-color: #f8f8f8; }

.uk-background-primary {
  background-color: #1e87f0; }

.uk-background-secondary {
  background-color: #222; }

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain,
.uk-background-width-1-1,
.uk-background-height-1-1 {
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.uk-background-cover {
  background-size: cover; }

.uk-background-contain {
  background-size: contain; }

.uk-background-width-1-1 {
  background-size: 100%; }

.uk-background-height-1-1 {
  background-size: auto 100%; }

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0; }

.uk-background-top-center {
  background-position: 50% 0; }

.uk-background-top-right {
  background-position: 100% 0; }

.uk-background-center-left {
  background-position: 0 50%; }

.uk-background-center-center {
  background-position: 50% 50%; }

.uk-background-center-right {
  background-position: 100% 50%; }

.uk-background-bottom-left {
  background-position: 0 100%; }

.uk-background-bottom-center {
  background-position: 50% 100%; }

.uk-background-bottom-right {
  background-position: 100% 100%; }

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat; }

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden; }

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll; } }

/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important; } }

/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important; } }

/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important; } }

/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important; } }

/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply; }

.uk-background-blend-screen {
  background-blend-mode: screen; }

.uk-background-blend-overlay {
  background-blend-mode: overlay; }

.uk-background-blend-darken {
  background-blend-mode: darken; }

.uk-background-blend-lighten {
  background-blend-mode: lighten; }

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge; }

.uk-background-blend-color-burn {
  background-blend-mode: color-burn; }

.uk-background-blend-hard-light {
  background-blend-mode: hard-light; }

.uk-background-blend-soft-light {
  background-blend-mode: soft-light; }

.uk-background-blend-difference {
  background-blend-mode: difference; }

.uk-background-blend-exclusion {
  background-blend-mode: exclusion; }

.uk-background-blend-hue {
  background-blend-mode: hue; }

.uk-background-blend-saturation {
  background-blend-mode: saturation; }

.uk-background-blend-color {
  background-blend-mode: color; }

.uk-background-blend-luminosity {
  background-blend-mode: luminosity; }

/*
 * Plugin: Web Slide Navigation System
 * Demo Link: http://webslidemenu.uxwing.com/
 * Author: UXWing
 * License: http://codecanyon.net/licenses/standard
*/
/*--------------------------------------------------------------------------
[Table of contents]

01 Base CSS
02 Desktop CSS
03 Tab CSS
04 Mobile CSS
05 Additional Themes (Optionnal)
--------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------
|  02 Desktop CSS
--------------------------------------------------------------------------*/
.wsmobileheader {
  display: none; }

#overlapblackbg {
  display: none; }

.wsmenu-list > li {
  position: relative; }
  .wsmenu-list > li:hover .wsmenu-submenu {
    opacity: 1;
    display: block; }
  .wsmenu-list > li .wsmenu-submenu-sub {
    position: absolute;
    left: 100%;
    top: 0;
    opacity: 0; }
    .wsmenu-list > li .wsmenu-submenu-sub li:hover .wsmenu-submenu-sub-sub {
      position: absolute;
      left: 100%;
      top: 0;
      opacity: 1; }
  .wsmenu-list > li .wsmenu-submenu li:hover .wsmenu-submenu-sub {
    opacity: 1;
    display: block; }

.wsmenu-submenu {
  position: absolute;
  z-index: 1000;
  opacity: 0; }

.wsmenu-submenu li {
  position: relative;
  display: block; }

.wsmenu-click {
  display: none; }

.wsmenu-click02 {
  display: none; }

/*--------------------------------------------------------------------------
|  04 Mobile CSS
--------------------------------------------------------------------------*/
@media (max-width: 991.98px) {
  .wsoffcanvasopener .wsmenu {
    left: 0px;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-color: #315659; }
  .wsmenucontainer.wsoffcanvasopener {
    display: block;
    margin-left: 240px; }
  .wsmobileheader {
    display: block !important;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .wsmenu-list > li:hover > a {
    background-color: rgba(0, 0, 0, 0.08) !important;
    text-decoration: none; }
  .hometext {
    display: inline-block !important; }
  .megacollink {
    width: 96% !important;
    margin: 0% 2% !important; }
  .megacolimage {
    width: 90% !important;
    margin: 0% 5% !important; }
  .typographylinks {
    width: 98% !important;
    margin: 0% 1% !important; }
  .typographydiv {
    width: 86% !important;
    margin: 0% 7% !important; }
  .mainmapdiv {
    width: 90% !important;
    margin: 0% 5% !important; }
  #overlapblackbg {
    left: 0;
    z-index: 102;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.45);
    cursor: pointer; }
  .wsoffcanvasopener #overlapblackbg {
    display: block !important; }
  .wsmenu-list li:hover .wsmenu-submenu {
    display: none; }
  .wsmenu-list li:hover .wsmenu-submenu .wsmenu-submenu-sub {
    display: none; }
  .wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub li:hover .wsmenu-submenu-sub-sub {
    display: none;
    list-style: none;
    padding: 2px;
    border: solid 1px #eeeeee;
    background-color: #fff; }
  .wsmenu-list li:first-child a {
    -webkit-border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px; }
  .wsmenu-list li:last-child a {
    -webkit-border-radius: 0px 4px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    border-right: 0px solid; }
  .innerpnd {
    padding: 0px !important; }
  .typography-text {
    padding: 10px 0px; }
  .wsmenucontainer {
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .wsmenu-click {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    width: 40px; }
    .wsmenu-click::after {
      content: url("../images/chevron-white.svg");
      margin-left: .5rem; }
  .wsmenu-click i {
    display: block;
    height: 100%;
    width: 25px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.25);
    float: right; }
  .wsmenu-click02 {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    width: 40px; }
    .wsmenu-click02::after {
      content: "";
      border-top: .4rem solid;
      border-right: .4rem solid transparent;
      border-bottom: 0;
      border-left: .4rem solid transparent;
      position: absolute;
      left: 45%;
      top: 15px; }
  .wsmenu-click02 i {
    display: block;
    height: 100%;
    width: 25px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.25);
    float: right; }
  .ws-activearrow > i {
    transform: rotate(180deg); }
  .wsmenu-rotate {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  .wsmenu-submenu-sub {
    width: 100% !important;
    position: static !important;
    left: 100% !important;
    top: 0 !important;
    display: none;
    margin: 0px !important;
    padding: 0px !important;
    border: solid 0px !important;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important; }
  .wsmenu-submenu-sub li {
    margin: 0px 0px 0px 0px !important;
    padding: 0px;
    position: relative; }
  .wsmenu-submenu-sub a {
    display: block;
    padding: 10px 25px 10px 25px;
    border-bottom: solid 1px #495057;
    font-weight: normal; }
  .wsmenu-submenu-sub li a.active {
    color: #000 !important; }
  .wsmenu-submenu-sub li:hover > a {
    background-color: #333333 !important;
    color: #fff; }
  .wsmenu-list > li > a .fa {
    margin-right: 6px; }
  .wsmenu-submenu-sub-sub {
    width: 100% !important;
    position: static !important;
    left: 100% !important;
    top: 0 !important;
    display: none;
    margin: 0px !important;
    padding: 0px !important;
    border: solid 0px !important;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important; }
  .wsmenu-submenu-sub-sub li {
    margin: 0px 0px 0px 0px !important; }
  .wsmenu-submenu-sub-sub a {
    display: block;
    color: #000;
    padding: 10px 25px;
    background: #000 !important;
    border-bottom: solid 1px #495057;
    font-weight: normal; }
  .wsmenu-submenu-sub-sub li a.active {
    color: #000 !important; }
  .wsmenu-submenu-sub-sub li:hover > a {
    background-color: #606060 !important;
    color: #fff; }
  .wsmenu {
    position: relative; }
  .wsmenu .wsmenu-list {
    height: 100%;
    overflow-y: auto;
    display: block !important; }
  .wsmenu .wsmenu-list > li {
    width: 100%;
    display: block;
    float: none;
    border-right: none;
    background-color: transparent;
    position: relative;
    white-space: inherit; }
  .wsmenu .wsmenu-list li a .arrow:after {
    display: none !important; }
  .wsmenu .wsmenu-list li ul li a .fa.fa-caret-right {
    font-size: 12px !important;
    color: #495057; }
  .mobile-sub .wsmenu-submenu {
    transform: none !important;
    opacity: 1 !important;
    display: none;
    position: relative !important;
    top: 0px;
    visibility: visible !important; }
  .mobile-sub .wsmenu-submenu li a {
    line-height: 20px;
    height: 36px;
    background-color: #fff !important;
    font-size: 1.6rem;
    padding: 8px 0px 8px 18px;
    color: #495057; }
  .mobile-sub .wsmenu-submenu li a:hover {
    background-color: #fff !important;
    color: #6c757d;
    text-decoration: underline; }
  .mobile-sub .wsmenu-submenu li:hover > a {
    background-color: #fff !important;
    color: #6c757d; }
  .mobile-sub .wsmenu-submenu li .wsmenu-submenu-sub li a {
    line-height: 20px;
    height: 36px;
    background-color: #fff !important;
    border-bottom: none;
    padding-left: 28px; }
  .mobile-sub .wsmenu-submenu li .wsmenu-submenu-sub li .wsmenu-submenu-sub-sub li a {
    line-height: 20px;
    height: 36px;
    background-color: #fff !important;
    border-bottom: none !important;
    padding-left: 38px;
    color: #495057; }
  .wsmenu-list .megamenu {
    background-color: #fff;
    color: #6c757d;
    display: none;
    position: relative !important;
    top: 0px;
    padding: 0px;
    border: solid 0px;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important; }
  .wsmenu-list li:hover .megamenu {
    display: none;
    position: relative !important;
    top: 0px; }
  .wsmenu {
    width: 280px;
    left: -280px;
    height: 100%;
    position: fixed;
    top: 0;
    margin: 0;
    border-radius: 0px;
    z-index: 103;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border: none !important;
    background-color: #fff; }
  .animated-arrow {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 102;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .wsoffcanvasopener .animated-arrow {
    left: 270px; }
  .animated-arrow {
    cursor: pointer;
    padding: 13px 35px 16px 0px;
    margin: 4px 0 0 15px; }
  .wsmenucontainer:not(.wsoffcanvasopener) .animated-arrow {
    margin-top: 2rem; }
  .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
    cursor: pointer;
    height: 3px;
    width: 23px;
    background: #c9c9c9;
    position: absolute;
    display: block;
    content: ''; }
  .animated-arrow span:before {
    top: -7px; }
  .animated-arrow span:after {
    bottom: -7px; }
  .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
    transition: all 500ms ease-in-out; }
  .wsoffcanvasopener .animated-arrow span {
    background-color: transparent; }
  .wsoffcanvasopener .animated-arrow span:before, .animated-arrow.active span:after {
    top: 7px; }
  .wsoffcanvasopener .animated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 0px; }
  .wsoffcanvasopener .animated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .wsmenu-list li > .wsmenu-submenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important; }
  .wsmenu-list li:hover > .wsmenu-submenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important; }
  .wsmenu-submenu li > .wsmenu-submenu-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important; }
  .wsmenu-submenu li:hover > .wsmenu-submenu-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important; }
  .wsmenu-submenu-sub li > .wsmenu-submenu-sub-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important; }
  .wsmenu-submenu-sub li:hover > .wsmenu-submenu-sub-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important; } }

/* déclaration d'une fonction

@mixin truc($variable){
	color:$variable;
}

////* appel de la fonction

.class{
	@include truc(#000);
}

/* déclaration d'une procédure

%ma-fonction{
	color:#000;
}

////* appel de la procédure

.class{
	@extend %ma-fonction;
	border:0;
}


// fonctions déjà existantes

 - lighten($color, $amount) : Makes a color lighter.
 - darken($color, $amount) : Makes a color darker.
 - saturate($color, $amount) : Makes a color more saturated.
 - desaturate($color, $amount) : Makes a color less saturated.

Le tout sur ce site : http://sass-lang.com/documentation/Sass/Script/Functions.html

*/
/* ========= mixin ico utilisé pour faire fonctionner l'icons generated dans le 4
*/
/* ========= appel des fonts
*/
/* =======

  css à placer sur les balises html générale avant de rentrer dans le détail du dossier 5.layout et 6.blocs

======= */
html {
  font-size: .625rem; }

body {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  color: #333333;
  line-height: 28px; }

.body {
  overflow: hidden; }

sup {
  font-size: 60%; }

.invalid {
  color: #dc3545;
  font-weight: bold; }

input.invalid {
  border: 1px solid #dc3545; }

table {
  width: 100%;
  margin-bottom: 1rem; }
  table th,
  table td {
    padding: 0 1rem; }
  table thead tr {
    background: rgba(177, 201, 3, 0.3); }
  table tbody tr {
    background: rgba(177, 201, 3, 0.1); }

a {
  text-decoration: none; }

img,
svg {
  max-width: 100%; }

a img {
  border: 0; }

a:hover img, a:focus img, a:active img {
  opacity: 0.8; }

.item-page img {
  border-radius: 0.8rem; }

.img-slider-client {
  height: 130px; }
  @media (max-width: 767.98px) {
    .img-slider-client {
      height: 220px; } }

[class^="ico-"],
[class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icons, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1.5;
  color: inherit;
  text-transform: none;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  [class^="ico-"]::before,
  [class*=" ico-"]::before {
    display: inline-block;
    font-family: icons, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  [class^="ico-"]:hover,
  [class*=" ico-"]:hover {
    text-decoration: none; }

@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("eot"), url("../fonts/icons.woff2") format("woff2"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg"); }

.ico:before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none; }

.ico-chevron:before {
  content: "\E001"; }

.ico-ico-2500-utilisateurs:before {
  content: "\E002"; }

.ico-ico-accompagnement-permanent:before {
  content: "\E003"; }

.ico-ico-assurer-compatibilite:before {
  content: "\E004"; }

.ico-ico-assurer-conformite-tachygraphe:before {
  content: "\E005"; }

.ico-ico-assurer-securite-chauffeurs:before {
  content: "\E006"; }

.ico-ico-augmenter-productivite:before {
  content: "\E007"; }

.ico-ico-augmenter-securite-conducteur:before {
  content: "\E008"; }

.ico-ico-car:before {
  content: "\E009"; }

.ico-ico-certification-iso:before {
  content: "\E00A"; }

.ico-ico-communication-transparente:before {
  content: "\E00B"; }

.ico-ico-conduite-eco:before {
  content: "\E00C"; }

.ico-ico-consacrer-moins-temps:before {
  content: "\E00D"; }

.ico-ico-controler-deplacements:before {
  content: "\E00E"; }

.ico-ico-creer-planification:before {
  content: "\E00F"; }

.ico-ico-diminuer-consommation-depenses:before {
  content: "\E010"; }

.ico-ico-eviter-vols-materiels:before {
  content: "\E011"; }

.ico-ico-fb:before {
  content: "\E012"; }

.ico-ico-file:before {
  content: "\E013"; }

.ico-ico-gearbox:before {
  content: "\E014"; }

.ico-ico-gerer-flotte-poids-lourds:before {
  content: "\E015"; }

.ico-ico-gerer-utilisations-actifs:before {
  content: "\E016"; }

.ico-ico-home:before {
  content: "\E017"; }

.ico-ico-identification-condu:before {
  content: "\E018"; }

.ico-ico-linkedin:before {
  content: "\E019"; }

.ico-ico-localiser-actifs:before {
  content: "\E01A"; }

.ico-ico-maintenir-materiel-etat:before {
  content: "\E01B"; }

.ico-ico-map:before {
  content: "\E01C"; }

.ico-ico-optimiser-satisfaction:before {
  content: "\E01D"; }

.ico-ico-prix:before {
  content: "\E01E"; }

.ico-ico-rd-innovante:before {
  content: "\E01F"; }

.ico-ico-reduire-couts:before {
  content: "\E020"; }

.ico-ico-reduire-impact-envi:before {
  content: "\E021"; }

.ico-ico-report:before {
  content: "\E022"; }

.ico-ico-secure:before {
  content: "\E023"; }

.ico-ico-service-client:before {
  content: "\E024"; }

.ico-ico-solutions-metiers-adaptees:before {
  content: "\E025"; }

.ico-ico-truck:before {
  content: "\E026"; }

.ico-ico-users:before {
  content: "\E027"; }

.ico-logo-skazy:before {
  content: "\E028"; }

h1, .h1,
.h1 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  font-size: 4.2rem;
  color: #B1C903; }

h2, .h2,
.h2 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-size: 4.2rem;
  color: #315659; }

h1, .h1,
.h1, h2, .h2,
.h2 {
  line-height: 56px;
  margin-bottom: 2rem; }
  @media (max-width: 991.98px) {
    h1, .h1,
    .h1, h2, .h2,
    .h2 {
      font-size: 3rem;
      line-height: 45px; } }

h3, .h3,
.h3 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  color: #333333;
  margin: 2rem 0; }

h4, .h4,
.h4 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  color: #495057;
  margin: 1.5rem 0; }

.b-mea__header {
  max-width: 900px;
  margin: auto; }
  .b-mea__header p {
    font-size: 2rem; }

.breadcrumb {
  padding: 1rem 0; }
  .breadcrumb-item {
    position: relative;
    padding-left: 2.5rem; }
    .breadcrumb-item:before {
      position: absolute;
      left: 0;
      top: 2px;
      content: url("../images/ico-home.svg"); }
    .breadcrumb-item + .breadcrumb-item {
      padding-left: 2.5rem; }
      .breadcrumb-item + .breadcrumb-item:before {
        position: absolute;
        content: url("../images/chevron.svg");
        left: 5px;
        padding: 0;
        transform: rotate(270deg);
        float: unset; }
  .breadcrumb-item,
  .breadcrumb a {
    color: #333333;
    font-size: 1.6rem; }
    .breadcrumb-item.active,
    .breadcrumb a.active {
      font-weight: 700;
      color: #B1C903; }

.card-ul-child .card-body ul {
  margin: 5px 0 0;
  padding-left: 15px; }
  .card-ul-child .card-body ul li {
    margin: 10px 0 0;
    position: relative; }
    .card-ul-child .card-body ul li:before {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      left: -15px;
      top: 9px;
      background-color: #B1C903;
      border-radius: 10px; }

.custom_contact ul {
  list-style-type: none;
  padding: 0; }
  .custom_contact ul li {
    padding-left: 3rem;
    position: relative; }
    .custom_contact ul li:before {
      content: '';
      background-size: cover;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 5px;
      left: 0; }
    .custom_contact ul li.location:before {
      background-image: url("../images/location.svg"); }
    .custom_contact ul li.phone:before {
      background-image: url("../images/phone.svg"); }
    .custom_contact ul li.fax:before {
      background-image: url("../images/fax.svg"); }
    .custom_contact ul li.mobile:before {
      background-image: url("../images/mobile.svg"); }
    .custom_contact ul li.mail:before {
      background-image: url("../images/mail.svg"); }

.pre-footer {
  padding: 2.5rem 0; }

.footer {
  padding-top: 4rem;
  padding-bottom: 3rem; }
  @media (max-width: 991.98px) {
    .footer {
      padding-top: 3rem;
      padding-bottom: 2rem; } }
  .footer__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 30px 0; }
    @media (max-width: 991.98px) {
      .footer__container {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: 20px 0; } }
  .footer .brand-logo {
    margin: unset; }

#header-top {
  background: #333333;
  color: #fff;
  font-size: 1.5rem;
  padding: .7rem 0; }
  @media (max-width: 991.98px) {
    #header-top {
      display: none; } }

.header.bg-white .wsmenucontainer:not(.wsoffcanvasopener) .menu-home .brand-logo,
.header.bg-white .wsmenucontainer:not(.wsoffcanvasopener) .menu-home .brand-logo.active {
  background: center/cover no-repeat url("../images/logo-color.png"); }
  .header.bg-white .wsmenucontainer:not(.wsoffcanvasopener) .menu-home .brand-logo img,
  .header.bg-white .wsmenucontainer:not(.wsoffcanvasopener) .menu-home .brand-logo.active img {
    opacity: 0; }

.header.bg-white .site-logo img.brand-logo {
  display: none; }

.header.bg-white .site-logo img.brand-logo-color {
  display: block; }

.header .site-logo {
  text-align: center; }
  .header .site-logo img {
    margin: auto;
    max-width: 140px; }
    .header .site-logo img.brand-logo {
      display: block; }
    .header .site-logo img.brand-logo-color {
      display: none; }

.menu-principal .brand-logo {
  display: block;
  margin: auto; }
  .menu-principal .brand-logo img {
    max-width: 140px; }

.inner-page .header {
  background: #fff;
  border-bottom: 1px solid #F2F2F2; }
  .inner-page .header .wsmenucontainer:not(.wsoffcanvasopener) .menu-home .brand-logo {
    background: center/cover no-repeat url("../images/logo-color.png"); }
    .inner-page .header .wsmenucontainer:not(.wsoffcanvasopener) .menu-home .brand-logo img {
      opacity: 0 !important; }
  .inner-page .header .wsmenucontainer:not(.wsoffcanvasopener) .menu-principal .menu > .nav-item {
    color: #333333; }
    .inner-page .header .wsmenucontainer:not(.wsoffcanvasopener) .menu-principal .menu > .nav-item .dropdown-menu .current a {
      color: #B1C903 !important; }
    .inner-page .header .wsmenucontainer:not(.wsoffcanvasopener) .menu-principal .menu > .nav-item a {
      color: #333333 !important; }
  .inner-page .header .site-logo img.brand-logo {
    display: none; }
  .inner-page .header .site-logo img.brand-logo-color {
    display: block; }

.homepage .bg-white.header {
  border-bottom: 1px solid #F2F2F2; }

.homepage .bg-white .wsmenucontainer:not(.wsoffcanvasopener) .menu-principal .menu > .nav-item {
  color: #333333; }
  .homepage .bg-white .wsmenucontainer:not(.wsoffcanvasopener) .menu-principal .menu > .nav-item a {
    color: #333333 !important; }
  .homepage .bg-white .wsmenucontainer:not(.wsoffcanvasopener) .menu-principal .menu > .nav-item.deeper::after {
    content: url("../images/chevron.svg"); }

.homepage .menu-principal .menu > .nav-item {
  color: #fff !important; }
  .homepage .menu-principal .menu > .nav-item > a {
    color: #fff !important; }
  .homepage .menu-principal .menu > .nav-item.deeper::after {
    content: url("../images/chevron-white.svg"); }
  .homepage .menu-principal .menu > .nav-item:not(.deeper) .nav-link {
    color: inherit; }
    .homepage .menu-principal .menu > .nav-item:not(.deeper) .nav-link:hover {
      color: #B1C903; }

@media (max-width: 575.98px) {
  .item-page .page-header {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 992px) {
  .header .menu-principal .menu-contact a,
  .header.bg-white .menu-principal .menu-contact a {
    color: #000; } }

@media (min-width: 992px) {
  .homepage .menu-principal .menu-contact a {
    color: #fff; } }

.header .wsoffcanvasopener .menu-principal .menu .nav-item, .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item {
  color: #fff; }
  .header .wsoffcanvasopener .menu-principal .menu .nav-item.current, .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item.current {
    color: #B1C903; }
  .header .wsoffcanvasopener .menu-principal .menu .nav-item .nav-link,
  .header .wsoffcanvasopener .menu-principal .menu .nav-item a, .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item .nav-link,
  .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item a {
    color: inherit; }
    .header .wsoffcanvasopener .menu-principal .menu .nav-item .nav-link.active,
    .header .wsoffcanvasopener .menu-principal .menu .nav-item a.active, .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item .nav-link.active,
    .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item a.active {
      color: #B1C903; }
  .header .wsoffcanvasopener .menu-principal .menu .nav-item.deeper::after, .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item.deeper::after {
    display: none; }
  .header .wsoffcanvasopener .menu-principal .menu .nav-item.deeper .dropdown-menu, .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item.deeper .dropdown-menu {
    background: unset;
    box-shadow: none; }
    .header .wsoffcanvasopener .menu-principal .menu .nav-item.deeper .dropdown-menu .nav-item,
    .header .wsoffcanvasopener .menu-principal .menu .nav-item.deeper .dropdown-menu .nav-link,
    .header .wsoffcanvasopener .menu-principal .menu .nav-item.deeper .dropdown-menu .active, .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item.deeper .dropdown-menu .nav-item,
    .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item.deeper .dropdown-menu .nav-link,
    .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item.deeper .dropdown-menu .active {
      background: unset !important;
      text-decoration: none; }
  .header .wsoffcanvasopener .menu-principal .menu .nav-item:hover a, .bg-white .wsoffcanvasopener .menu-principal .menu .nav-item:hover a {
    background: unset !important; }

.header .wsoffcanvasopener .menu-principal .menu > .nav-item, .bg-white .wsoffcanvasopener .menu-principal .menu > .nav-item {
  padding: .5rem 2rem; }
  .header .wsoffcanvasopener .menu-principal .menu > .nav-item .nav-link, .bg-white .wsoffcanvasopener .menu-principal .menu > .nav-item .nav-link {
    padding: .5rem 0; }

.header .wsoffcanvasopener .wsmenu-click, .bg-white .wsoffcanvasopener .wsmenu-click {
  width: 100%;
  position: absolute; }
  .header .wsoffcanvasopener .wsmenu-click:after, .bg-white .wsoffcanvasopener .wsmenu-click:after {
    position: absolute;
    top: 7px;
    right: 20px;
    transform: rotate(-90deg);
    margin: 0;
    transition: all 0.4s ease-in-out; }
  .header .wsoffcanvasopener .wsmenu-click.ws-activearrow:after, .bg-white .wsoffcanvasopener .wsmenu-click.ws-activearrow:after {
    transform: unset; }

.menu-principal .menu {
  align-items: center;
  gap: 3rem;
  font-weight: 600;
  font-size: 1.6rem; }
  @media (max-width: 1199.98px) {
    .menu-principal .menu {
      gap: 1rem; } }
  .menu-principal .menu .dropdown-menu {
    min-width: 300px;
    padding: 1.5rem 2rem; }
  .menu-principal .menu-home .active {
    background: none; }
  @media (min-width: 992px) {
    .menu-principal .menu-contact {
      text-align: right; }
      .menu-principal .menu-contact a {
        font-size: 2rem;
        font-weight: 400;
        padding: 0; } }
  .menu-principal .menu .nav-link.active, .menu-principal .menu .nav-link:hover {
    color: #B1C903;
    background: none; }
  .menu-principal .menu .nav-item {
    cursor: pointer; }
  @media (min-width: 992px) {
    .menu-principal .menu .deeper::after {
      content: url("../images/chevron.svg");
      margin-left: .5rem; } }

.menu-social {
  gap: 15px; }
  .menu-social .picto-social {
    width: 17px; }

.menu-secondaire {
  gap: 20px; }
  .menu-secondaire .acces-link:hover, .menu-secondaire .acces-link:hover:before {
    color: #B1C903 !important; }
  .menu-secondaire .acces-link:before {
    content: 'Accéder à ';
    color: #fff; }
  .menu-secondaire li a {
    color: #fff; }
    .menu-secondaire li a:hover {
      color: #B1C903; }
  .menu-secondaire li.active a, .menu-secondaire li.current a {
    color: #B1C903; }

.menu-social-footer {
  justify-content: center;
  gap: 30px; }
  .menu-social-footer .picto-social {
    width: 28px; }

.menu-footer, .menu-footer-principal {
  color: #fff; }
  .menu-footer a, .menu-footer-principal a {
    font-size: 1.5rem !important;
    color: #fff; }
  .menu-footer .deeper .nav-child, .menu-footer-principal .deeper .nav-child {
    margin-top: 1rem;
    list-style-type: none;
    padding: 0; }
    .menu-footer .deeper .nav-child li, .menu-footer-principal .deeper .nav-child li {
      padding: 0; }
    .menu-footer .deeper .nav-child a, .menu-footer-principal .deeper .nav-child a {
      color: rgba(255, 255, 255, 0.5); }
      .menu-footer .deeper .nav-child a:hover, .menu-footer-principal .deeper .nav-child a:hover {
        color: #fff; }

.menu-footer {
  grid-area: 2 / 2 / 3 / 5;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 991.98px) {
    .menu-footer {
      grid-area: auto;
      gap: 10px;
      flex-direction: column; } }
  .menu-footer-principal {
    display: grid;
    grid-area: 1 / 1 / 2 / 5;
    grid-template-columns: repeat(4, 1fr); }
    @media (max-width: 991.98px) {
      .menu-footer-principal {
        display: flex;
        justify-content: center; }
        .menu-footer-principal li:not(:first-child) {
          display: none; } }
    .menu-footer-principal > li:nth-last-child(-n+6) {
      display: none; }
    .menu-footer-principal .menu-home .brand-logo {
      max-width: 140px; }

.form-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }

.formError {
  font-size: 1.4rem; }

.rsform-block-envoyer label {
  display: none; }

.rsfp_thankyou_popup_inner {
  border-radius: 0.8rem; }

.rsfp_thankyou_popup_close_btn {
  top: -2px;
  right: 7px; }

.btn-back-to-top {
  bottom: 15%;
  right: 20px;
  border-radius: 0.8rem; }
  .btn-back-to-top > svg {
    display: none; }

.bg-donuts {
  background-image: url("../images/donut.svg");
  background-repeat: no-repeat;
  background-position: top right; }

@media (min-width: 1450px) {
  .bg-mask-vague {
    mask: url("../images/mask-rounded-bottom.svg") no-repeat left bottom;
    -webkit-mask: url("../images/mask-rounded-bottom.svg") no-repeat left bottom;
    mask-size: 100%; } }

@media (min-width: 768px) {
  .bg-dots {
    overflow-y: hidden; }
    .bg-dots::before {
      position: absolute;
      width: 628px;
      height: 268px;
      content: "";
      background-image: url("../images/points.svg");
      background-repeat: no-repeat;
      background-position: center; }
    .bg-dots-l::before {
      top: 220px;
      left: -200px; } }
    @media (min-width: 768px) and (min-width: 992px) {
      .bg-dots-l::before {
        top: -100px;
        left: -400px; } }

@media (min-width: 768px) {
    .bg-dots-r::before {
      bottom: -30px;
      right: -40px; } }
    @media (min-width: 768px) and (min-width: 992px) {
      .bg-dots-r::before {
        bottom: 20px;
        right: -500px; } }

/*
  Bootstrap a déjà créé ces class :

  - fw-bold // 700
  - fw-bolder / bolder
  - fw-normal // 400
  - fw-light // 300
  - fw-lighter // lighter
  - fst-italic // font-style italic
  - fst-normal // font-style normal
 */
.fw-normal {
  font-weight: 400; }

.fw-semi-bold {
  font-weight: 600; }

.fw-bold {
  font-weight: 700; }

.rounded-ovale {
  border-radius: 61% 39% 50% 50% / 60% 42% 58% 40%; }

.vw-full {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw; }

.p-last-child-0 p:last-child {
  margin-bottom: 0; }

.a-text-white a {
  color: #fff; }

/* =========  reprise des couleurs des boutons de base ici
*/
/* =========  mise en forme des nav
*/
.nav .nav-link {
  line-height: 20px;
  border-radius: 0;
  padding: .5rem 0; }

/* =========  mise en forme des forms
*/
.form-control {
  font-size: 1.6rem; }

/* =========  mise en forme des boutons bootstrap
*/
.btn {
  font-size: 1.6rem;
  font-weight: 600; }
  .btn:not(.btn-back-to-top) {
    padding: 1rem 3rem; }

.btn-primary {
  color: #fff;
  background-color: #B1C903;
  border-color: #B1C903;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2); }
  .btn-primary:hover {
    color: #fff;
    background-color: #6f7e02;
    border-color: #6f7e02; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #6f7e02;
    border-color: #6f7e02;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(189, 209, 41, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #000;
    background-color: #8ea102;
    border-color: #859702; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(189, 209, 41, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #000;
    background-color: #B1C903;
    border-color: #B1C903; }

.btn-white {
  color: #333333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2);
  box-shadow: 0 10px 20px 0 rgba(51, 51, 51, 0.2); }
  .btn-white:hover {
    color: #000;
    background-color: #d9d9d9;
    border-color: #d9d9d9; }
  .btn-check:focus + .btn-white, .btn-white:focus {
    color: #000;
    background-color: #d9d9d9;
    border-color: #d9d9d9;
    box-shadow: 0 10px 20px 0 rgba(177, 201, 3, 0.2), 0 0 0 0.25rem rgba(224, 224, 224, 0.5); }
  .btn-check:checked + .btn-white,
  .btn-check:active + .btn-white, .btn-white:active, .btn-white.active,
  .show > .btn-white.dropdown-toggle {
    color: #000;
    background-color: white;
    border-color: white; }
    .btn-check:checked + .btn-white:focus,
    .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(224, 224, 224, 0.5); }
  .btn-white:disabled, .btn-white.disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff; }

.list-group .list-group-item {
  background: none; }

.alert {
  color: #fff; }

.card {
  border-radius: 0.8rem; }

.tarteaucitronAlertBigTop {
  left: 50% !important; }
