/*
 * Plugin: Web Slide Navigation System
 * Demo Link: http://webslidemenu.uxwing.com/
 * Author: UXWing
 * License: http://codecanyon.net/licenses/standard
*/

/*--------------------------------------------------------------------------
[Table of contents]

01 Base CSS
02 Desktop CSS
03 Tab CSS
04 Mobile CSS
05 Additional Themes (Optionnal)
--------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------
|  02 Desktop CSS
--------------------------------------------------------------------------*/

.wsmobileheader {
  display: none;
}

#overlapblackbg {
  display: none;
}

.wsmenu-list {
  & > li {
	  position: relative;
	  &:hover{
		  .wsmenu-submenu {
			  opacity: 1;
			  display: block;
		  }
	  }
	  .wsmenu-submenu{
		  &-sub {
			  position: absolute;
			  left: 100%;
			  top: 0;
			  opacity: 0;
			  li:hover{
				  .wsmenu-submenu-sub-sub{
					  position: absolute;
					  left: 100%;
					  top: 0;
					  opacity: 1;
				  }
			  }
		  }
		  li:hover{
			  .wsmenu-submenu-sub {
				  opacity: 1;
				  display: block;
			  }
		  }
	  }
  }
}


.wsmenu-submenu {
  position: absolute;
  //top: 48px;
  z-index: 1000;
  opacity: 0;
}

.wsmenu-submenu li {
  position: relative;
  display: block;
}

.wsmenu-click {
  display: none;
}

.wsmenu-click02 {
  display: none;
}

/*--------------------------------------------------------------------------
|  04 Mobile CSS
--------------------------------------------------------------------------*/

@include media-breakpoint-down(lg) {
  .wsoffcanvasopener .wsmenu {
	left: 0px;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
    background-color: $color-green-2;
  }
  .wsmenucontainer.wsoffcanvasopener {
	display: block;
	margin-left: 240px;
  }
  .wsmobileheader {
	display: block !important;
	position: relative;
	top: 0;
	right: 0;
	left: 0;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
  }
  .wsmenu-list > li:hover > a {
	background-color: rgba(0, 0, 0, 0.08) !important;
	text-decoration: none;
  }
  .hometext {
	display: inline-block !important;
  }
  .megacollink {
	width: 96% !important;
	margin: 0% 2% !important;
  }
  .megacolimage {
	width: 90% !important;
	margin: 0% 5% !important;
  }
  .typographylinks {
	width: 98% !important;
	margin: 0% 1% !important;
  }
  .typographydiv {
	width: 86% !important;
	margin: 0% 7% !important;
  }
  .mainmapdiv {
	width: 90% !important;
	margin: 0% 5% !important;
  }
  #overlapblackbg {
	left: 0;
	z-index: 102;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	display: none;
	background-color: rgba(0, 0, 0, 0.45);
	cursor: pointer;
  }
  .wsoffcanvasopener #overlapblackbg {
	display: block !important;
  }
  .wsmenu-list li:hover .wsmenu-submenu {
	display: none;
  }
  .wsmenu-list li:hover .wsmenu-submenu .wsmenu-submenu-sub {
	display: none;
  }
  .wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub li:hover .wsmenu-submenu-sub-sub {
	display: none;
	list-style: none;
	padding: 2px;
	border: solid 1px #eeeeee;
	background-color: #fff;
  }
  .wsmenu-list li:first-child a {
	-webkit-border-radius: 0px 0px 0px 0px;
	-moz-border-radius: 0px 0px 0px 0px;
	border-radius: 0px 0px 0px 0px;
  }
  .wsmenu-list li:last-child a {
	-webkit-border-radius: 0px 4px 0px 0px;
	-moz-border-radius: 0px 0px 0px 0px;
	border-radius: 0px 0px 0px 0px;
	border-right: 0px solid;
  }
  .innerpnd {
	padding: 0px !important;
  }
  .typography-text {
	padding: 10px 0px;
  }
  .wsmenucontainer {
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
  }
  .wsmenu-click {
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	cursor: pointer;
	width: 40px;
	&::after {
		content: url("../images/chevron-white.svg");
		margin-left: .5rem;
	}
  }
  .wsmenu-click i {
	display: block;
	height: 100%;
	width: 25px;
	background-size: 25px;
	font-size: 21px;
	color: rgba(0, 0, 0, 0.25);
	float: right;
  }
  .wsmenu-click02 {
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	cursor: pointer;
	width: 40px;
	&::after {
	  content: "";
	  border-top: .4rem solid;
	  border-right: .4rem solid transparent;
	  border-bottom: 0;
	  border-left: .4rem solid transparent;
	  position: absolute;
	  left: 45%;
	  top: 15px;
	}
  }
  .wsmenu-click02 i {
	display: block;
	height: 100%;
	width: 25px;
	background-size: 25px;
	font-size: 21px;
	color: rgba(0, 0, 0, 0.25);
	float: right;
  }
  .ws-activearrow > i {
	transform: rotate(180deg);
  }
  .wsmenu-rotate {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
  }
  .wsmenu-submenu-sub {
	width: 100% !important;
	position: static !important;
	left: 100% !important;
	top: 0 !important;
	display: none;
	margin: 0px !important;
	padding: 0px !important;
	border: solid 0px !important;
	transform: none !important;
	opacity: 1 !important;
	visibility: visible !important;
  }
  .wsmenu-submenu-sub li {
	margin: 0px 0px 0px 0px !important;
	padding: 0px;
	position: relative;
  }
  .wsmenu-submenu-sub a {
	display: block;
	padding: 10px 25px 10px 25px;
	border-bottom: solid 1px $gray-700;
	font-weight: normal;
  }
  .wsmenu-submenu-sub li a.active {
	color: #000 !important;
  }
  .wsmenu-submenu-sub li:hover > a {
	background-color: #333333 !important;
	color: #fff;
  }
  .wsmenu-list > li > a .fa {
	margin-right: 6px;
  }
  .wsmenu-submenu-sub-sub {
	width: 100% !important;
	position: static !important;
	left: 100% !important;
	top: 0 !important;
	display: none;
	margin: 0px !important;
	padding: 0px !important;
	border: solid 0px !important;
	transform: none !important;
	opacity: 1 !important;
	visibility: visible !important;
  }
  .wsmenu-submenu-sub-sub li {
	margin: 0px 0px 0px 0px !important;
  }
  .wsmenu-submenu-sub-sub a {
	display: block;
	color: #000;
	padding: 10px 25px;
	background: #000 !important;
	border-bottom: solid 1px $gray-700;
	font-weight: normal;
  }
  .wsmenu-submenu-sub-sub li a.active {
	color: #000 !important;
  }
  .wsmenu-submenu-sub-sub li:hover > a {
	background-color: #606060 !important;
	color: #fff;
  }
  .wsmenu {
	position: relative;
  }
  .wsmenu .wsmenu-list {
	height: 100%;
	overflow-y: auto;
	display: block !important;
  }
  .wsmenu .wsmenu-list > li {
	width: 100%;
	display: block;
	float: none;
	border-right: none;
	background-color: transparent;
	position: relative;
	white-space: inherit;
  }

  .wsmenu .wsmenu-list li a .arrow:after {
	display: none !important;
  }
  .wsmenu .wsmenu-list li ul li a .fa.fa-caret-right {
	font-size: 12px !important;
	color: $gray-700;
  }
  .mobile-sub .wsmenu-submenu {
	transform: none !important;
	opacity: 1 !important;
	display: none;
	position: relative !important;
	top: 0px;
	visibility: visible !important;
  }
  .mobile-sub .wsmenu-submenu li a {
	line-height: 20px;
	height: 36px;
	background-color: $white !important;
	font-size: map-get($font-sizes,16);
	padding: 8px 0px 8px 18px;
	color: $gray-700;
  }
  .mobile-sub .wsmenu-submenu li a:hover {
	background-color: $white !important;
	color: $gray-600;
	text-decoration: underline;
  }
  .mobile-sub .wsmenu-submenu li:hover > a {
	background-color: $white !important;
	color: $gray-600;
  }
  .mobile-sub .wsmenu-submenu li .wsmenu-submenu-sub li a {
	line-height: 20px;
	height: 36px;
	background-color: $white !important;
	border-bottom: none;
	padding-left: 28px;
  }
  .mobile-sub .wsmenu-submenu li .wsmenu-submenu-sub li .wsmenu-submenu-sub-sub li a {
	line-height: 20px;
	height: 36px;
	background-color: $white !important;
	border-bottom: none !important;
	padding-left: 38px;
	color: $gray-700;
  }
  .wsmenu-list .megamenu {
	background-color: $white;
	color: $gray-600;
	display: none;
	position: relative !important;
	top: 0px;
	padding: 0px;
	border: solid 0px;
	transform: none !important;
	opacity: 1 !important;
	visibility: visible !important;
  }
  .wsmenu-list li:hover .megamenu {
	display: none;
	position: relative !important;
	top: 0px;
  }
  .wsmenu {
	width: 280px;
	left: -280px;
	height: 100%;
	position: fixed;
	top: 0;
	margin: 0;
	border-radius: 0px;
	z-index: 103;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	border: none !important;
    background-color: $white;
  }
  .animated-arrow {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 102;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
  }
  .wsoffcanvasopener .animated-arrow {
	left: 270px;
  }
  .animated-arrow {
	cursor: pointer;
	padding: 13px 35px 16px 0px;
	margin: 4px 0 0 15px;
  }
	.wsmenucontainer:not(.wsoffcanvasopener) {
		.animated-arrow {
			margin-top: 2rem;
		}
	}
	.animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
	cursor: pointer;
	height: 3px;
	width: 23px;
	background: #c9c9c9;
	position: absolute;
	display: block;
	content: '';
  }
  .animated-arrow span:before {
	top: -7px;
  }
  .animated-arrow span:after {
	bottom: -7px;
  }
  .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
	transition: all 500ms ease-in-out;
  }
  .wsoffcanvasopener .animated-arrow span {
	background-color: transparent;
  }
  .wsoffcanvasopener .animated-arrow span:before, .animated-arrow.active span:after {
	top: 7px;
  }
  .wsoffcanvasopener .animated-arrow span:before {
	transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	bottom: 0px;
  }
  .wsoffcanvasopener .animated-arrow span:after {
	transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
  }
  .wsmenu-list li > .wsmenu-submenu {
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	visibility: visible !important;
  }
  .wsmenu-list li:hover > .wsmenu-submenu {
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	visibility: visible !important;
  }
  .wsmenu-submenu li > .wsmenu-submenu-sub {
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	visibility: visible !important;
  }
  .wsmenu-submenu li:hover > .wsmenu-submenu-sub {
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	visibility: visible !important;
	opacity: 1 !important;
  }
  .wsmenu-submenu-sub li > .wsmenu-submenu-sub-sub {
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	visibility: visible !important;
	opacity: 1 !important;
  }
  .wsmenu-submenu-sub li:hover > .wsmenu-submenu-sub-sub {
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	-o-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
	visibility: visible !important;
	opacity: 1 !important;
  }
}
