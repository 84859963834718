.header,
.header.bg-white {
  .menu-principal {
    .menu-contact {
      @include media-breakpoint-up(lg) {
        a {
          color: $black;
        }
      }
    }
  }
}
.homepage {
  .menu-principal {
    .menu-contact {
      @include media-breakpoint-up(lg) {
        a {
          color: $white;
        }
      }
    }
  }
}

.header, .bg-white {
  .wsoffcanvasopener {
    .menu-principal {
      .menu {
        .nav-item {
          color: $white;
          &.current {
            color: $primary;
          }
          .nav-link,
          a {
            color: inherit;
            &.active {
              color: $primary;
            }
          }
          &.deeper {
            &::after {
              display: none;
            }
            .dropdown-menu {
              background: unset;
              box-shadow: none;
              .nav-item,
              .nav-link,
              .active {
                background: unset !important;
                text-decoration: none;
              }
            }
          }
          &:hover a {
            background: unset !important;
          }
        }
        > .nav-item {
          padding: .5rem 2rem;
          .nav-link {
            padding: .5rem 0;
          }
        }
      }
    }
    .wsmenu-click {
      width: 100%;
      position: absolute;
      &:after {
        position: absolute;
        top: 7px;
        right: 20px;
        transform: rotate(-90deg);
        margin: 0;
        transition: all 0.4s ease-in-out;
      }
      &.ws-activearrow:after {
        transform: unset;
      }
    }
  }
}

.menu {
  &-principal {
    .menu {
      align-items: center;
      gap: 3rem;
      @include media-breakpoint-down(xl) {
        gap: 1rem;
      }
      font-weight: $font-weight-semi-bold;
      font-size: map-get($font-sizes,16);
      .dropdown-menu {
        min-width: 300px;
        padding: 1.5rem 2rem;

      }
      &-home {
        .active {
          background: none;
        }
      }
      &-contact {
        @include media-breakpoint-up(lg) {
          text-align: right;
          a {
            font-size: map-get($font-sizes, 20);
            font-weight: $font-weight-normal;
            padding: 0;
          }
        }
      }
      .nav-link {
        &.active,
        &:hover {
          color: $primary;
          background: none;
        }
      }
      .nav-item {
        cursor: pointer;
      }
      @include media-breakpoint-up(lg) {
        .deeper {
          &::after {
            content: url("../images/chevron.svg");
            margin-left: .5rem;
          }
        }
      }
    }
  }

  &-social {
    gap: 15px;
    .picto-social {
      width: 17px;
    }
  }

  &-secondaire {
    gap: 20px;
    .acces-link {
      &:hover,
      &:hover:before {
        color: $primary !important;
      }
      &:before {
        content: 'Accéder à ';
        color: $white;
      }
    }
    li {
      a {
        color: $white;
        &:hover {
          color: $primary;
        }
      }
      &.active,
      &.current {
        a {
          color: $primary;
        }
      }
    }
  }

  &-social-footer {
    justify-content: center;
    gap: 30px;
    .picto-social {
      width: 28px;
    }
  }

  &-footer,
  &-footer-principal {
    color: $white;
    a {
      font-size: map-get($font-sizes,15) !important;
      color: $white;
    }
    .deeper {
      .nav-child {
        margin-top: 1rem;
        list-style-type: none;
        padding: 0;
        li {
          padding: 0;
        }
        a {
          color: rgba($white, .5);
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  &-footer {
    grid-area: 2 / 2 / 3 / 5;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(lg) {
      grid-area: auto;
      gap: 10px;
      flex-direction: column;
    }
    &-principal {
      display: grid;
      grid-area: 1 / 1 / 2 / 5;
      grid-template-columns: repeat(4, 1fr);
      @include media-breakpoint-down(lg) {
        display: flex;
        justify-content: center;
        li:not(:first-child) {
          display: none;
        }
      }
      > li {
        &:nth-last-child(-n+6) {
          display: none;
        }
      }
      .menu-home {
        .brand-logo {
          max-width: 140px;
        }
      }
    }
  }
}
