// Variable GEOCAL
$color-green: #B1C903;
$color-green-1: #6C7B00;
$color-green-2: #315659;
$color-gray-dark: #253031;

$color-orange: #F97B24;
$color-bleu: #11A2F8;

// variables de bootstrap par défault
$white:    #fff;
$gray-100: #F2F2F2;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #333333;
$gray-900: #0C0C0C;
$black:    #000;

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #65afe2;

$primary:       $color-green;
$secondary:     $color-green-2;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$body:          $white;
$pre-footer:   $color-gray-dark;
$footer:        $secondary;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "gray-100":  $gray-100,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "mobile":     $gray-300,
    "body":       $body,
    "prefooter":  $pre-footer,
    "footer":     $footer,
    "error":      $gray-300,
    "white":      $white,
    "orange":     $color-orange,
    "bleu":       $color-bleu,
    "gray900":    $gray-900,
    //"new-color":  $new-color, // permet de créer des class bootstrap complète de type btn-new-color, bg-new-color, text-new-color etc...
  ),
  $theme-colors
);

/* ======= uikit overlay couleur
*/

$overlay-primary-background: rgba($gray-700, 0.6);

/* ======= couleur des liens par défault
*/

$nav-link-color: $gray-800;
$nav-link-hover-color: $black;
