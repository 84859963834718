/* =======

  css à placer sur les balises html générale avant de rentrer dans le détail du dossier 5.layout et 6.blocs

======= */

html {
  font-size: .625rem; // 1rem = 10px with this line (easier calcul)
}

body {
  @include font($font-01, map-get($font-sizes,18), $font-weight-normal, $gray-800);
  line-height: 28px;
}

.body{
  overflow: hidden;
}

sup {
	font-size: 60%;
}

ul {
  li{

  }
}

.invalid {
    color: $red;
    font-weight: bold;
}

input.invalid {
    border: 1px solid $red;
}

table{
  width: 100%;
  margin-bottom: 1rem;
  th,
  td {
    padding: 0 1rem;
  }
  thead{
    tr{
      background: rgba($primary, .3);
      th{

      }
      td{

      }
    }
  }
  tbody{
    tr{
      background: rgba($primary, .1);
      th{

      }
      td{

      }
    }
  }
  tfooter{
    tr{
      th{

      }
      td{

      }
    }
  }
}


a{
  text-decoration: none;
}
