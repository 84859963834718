.btn{
  &-back-to-top {
	bottom: 15%;
	right: 20px;
    border-radius: $border-radius;
    > svg {
      display: none;
    }
  }
}
