.custom_contact {
  ul {
    list-style-type: none;
    padding: 0;
    li {
      padding-left: 3rem;
      position: relative;
      &:before {
        content: '';
        background-size: cover;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px;
        left: 0;
      }
      &.location:before {
        background-image: url('../images/location.svg');
      }
      &.phone:before {
        background-image: url('../images/phone.svg');
      }
      &.fax:before {
        background-image: url('../images/fax.svg');
      }
      &.mobile:before {
        background-image: url('../images/mobile.svg');
      }
      &.mail:before {
        background-image: url('../images/mail.svg');
      }
    }
  }
}
