// card amélioré
.form-row{
  @include make-row();
}
.formError {
  font-size: map-get($font-sizes,14);
}
.rsform-block-envoyer{
  label{
    display: none;
  }
}
.rsfp_thankyou_popup {
  &_inner {
    border-radius: $border-radius;
  }
  &_close_btn {
    top: -2px;
    right: 7px;
  }
}
