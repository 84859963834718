// backgroudn personnalisés

.bg-donuts{
  background-image: url('../images/donut.svg');
  background-repeat: no-repeat;
  background-position: top right;
}

.bg-mask-vague{
  @media (min-width: 1450px) {
    mask:url('../images/mask-rounded-bottom.svg') no-repeat left bottom;
    -webkit-mask:url('../images/mask-rounded-bottom.svg') no-repeat left bottom;
    mask-size: 100%;
  }
}

.bg-dots {
  @include media-breakpoint-up(md) {
    overflow-y: hidden;
    &::before {
      position: absolute;
      width: 628px;
      height: 268px;
      content: "";
      background-image: url('../images/points.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
    &-l::before {
      @include media-breakpoint-up(lg) {
        top: -100px;
        left: -400px;
      }
      top: 220px;
      left: -200px;
    }
    &-r::before {
      @include media-breakpoint-up(lg) {
        bottom: 20px;
        right: -500px;
      }
      bottom: -30px;
      right: -40px;
    }

  }
}
